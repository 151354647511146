import { Link, useHistory, useLocation } from "react-router-dom";
import { Nav, Row, Col, Form, Button, InputGroup, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PageHeader from "../../components/pageheader/PageHeader";
//import yellow_z_logo from '../../images/yellow_z_logo.svg';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import React from "react";
import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import StringHelper from "../../helpers/StringHelper";
import SaleStatus from "../../enums/SaleStatus";
import SaleStockType from "../../enums/SaleStockType";
import FormatDate from "../../helpers/FormatDate";
import QueryString from "../../helpers/querystring";
import ChildSaleType from "../../enums/ChildSaleType";

//Query for sales
interface ISaleQueryResult {
    sales: {
        totalCount: number;
        items: Array<ISale>;
    };
}

interface ITabCountQueryResult {
    tabDraft: ITabCount;
    tabStock: ITabCount;
    tabDropShip: ITabCount;
    tabBackordered: ITabCount;
    tabFulfilling: ITabCount;
    tabDropShipping: ITabCount;
    tabShipped: ITabCount;
    tabDelivered: ITabCount;
    tabVoid: ITabCount;
}

interface ITabCount {
    totalCount: number;
}

//query for salesChannel
interface ISalesChannelQueryResult {
    salesChannels: {
        items: Array<ISalesChannel>;
    };
}

interface ICreatePurchaseOrdersFromSalesResult {
    sale: {
        createPurchaseOrdersFromSales: number;
    };
}

interface ICreatePurchaseOrdersFromSalesVariables {
    supplierId: string | null;
}

//query for shippingServices
interface IShippingServiceQueryResult {
    shippingServices: {
        items: Array<IShippingService>;
    };
}
interface IWarehouseQuery {
    warehouses: {
        items: Array<IWarehouse>;
    };
}
//interfaces for all the queries
interface ISale {
    id: string;
    salesChannelId: string | null;
    status: SaleStatus;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    subtotal: number;
    tax: number;
    customerReference: string | null;
    shippingAddress: {
        firstName: string | null;
        lastName: string | null;
    };
    salesChannel: {
        id: string;
        name: string;
    };
    shippingService: {
        name: string;
        id: string;
    };
    dateShipped: string | null;
    dateDelivered: string | null;
    dateVoided: string | null;
    total: number;
    customerNotes: string | null;
    internalNotes: string | null;
    dropShip: boolean;
    childSaleType: ChildSaleType | null;
}
interface IWarehouse {
    id: string;
    name: string;
}

interface ISalesChannel {
    id: string;
    name: string;
    active: boolean;
}

interface IShippingService {
    id: string;
    name: string;
    active: boolean;
    shippingCarrierId: string;
}

//modal interface for adding
interface ISaleAdd {
    id: string;
    salesChannelId: string | null;
    status: SaleStatus;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    subtotal: number;
    tax: number;
    customerReference: string | null;
    total: number;
    billingAddress: {
        id: string;
    } | null;
    shippingAddress: {
        id: string;
    } | null;
    customerNotes: string | null;
    internalNotes: string | null;
    dropShip: boolean;
    shippingServiceId: string | null;
}

//model for original modal
interface ISaleModel {
    salesChannelId: string | null;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    customerReference: string | null;
    shippingServiceId: string | null;
    customerNotes: string | null;
    internalNotes: string | null;
    dropShip: boolean;
}

interface ISuppliersQueryResult {
    suppliers: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            contacts: Array<{
                id: string;
                name: string;
            }>;
            addresses: Array<{
                sortOrder: number;
                address: {
                    id: string;
                    city: string | null;
                    state: string | null;
                };
            }>;
        }>;
    };
}

interface IAddVariables {
    value: ISaleAdd;
}

interface IAddResult {
    sale: {
        add: ISaleAdd;
    };
}

const TabsCountQuery = `
{
  tabDraft:sales(status: DRAFT) {
    totalCount
  }
  tabStock:sales(status: AUTHORIZED, stockType: STOCK) {
    totalCount
  }
  tabDropShip:sales(status: AUTHORIZED, stockType: DROP_SHIP) {
    totalCount
  }
  tabBackordered:sales(status: AUTHORIZED, stockType: BACKORDERED) {
    totalCount
  }
  tabFulfilling:sales(status: FULFILLING) {
    totalCount
  }
  tabDropShipping:sales(status: DROP_SHIPPING) {
    totalCount
  }
  tabShipped:sales(status:COMPLETED) {
    totalCount
  }
  tabDelivered:sales(status: DELIVERED) {
    totalCount
  }
  tabVoid:sales(status: VOID) {
    totalCount
  }
}`;

//Queries and Mutations
const SalesQuery = `
query ($after: String, $search: String, $status: SaleStatus, $stockType: SaleStockType, $sortOrder: SalesSortOrder!) {
  sales (after: $after, search: $search, status: $status, stockType: $stockType, sortOrder: $sortOrder) {
    totalCount
    items {
      id
      salesChannelId
      salesChannelReference
      customerReference
      shippingServiceId
      shipBy
      orderDate
      status
      subtotal
      internalNotes
      customerNotes
      total
      dropShip
      dateShipped
      dateDelivered
      dateVoided
      shippingAddress {
        firstName
        lastName
      }
      salesChannel {
        id
        name
      }
      shippingService {
        id
        name
      }
      warehouse {
        id
        name
      }
      childSaleType
    }
  }
}
`;

interface ISaleQueryVariables {
    after: string | null;
    status: string | null;
    search: string | null;
    stockType: SaleStockType | null;
    sortOrder: SalesSortOrder;
}

//warehouse query
const WarehousesQuery = `
{
  warehouses {
    items {
      id
      name
    }
  }
}`;
const SalesChannelQuery = `
{
salesChannels {
    items {
        id
        name
        active
    }
}
}
`;

const ShippingServiceQuery = `
{
shippingServices {
    items {
        id
        name
        active
        shippingCarrierId
    }
}
}
`;

const SuppliersQuery = `
{
  suppliers {
    items {
      id
      name
      active
      contacts {
        id
        name
      }
      addresses {
        sortOrder
        address {
          id
          city
          state
          countryId
        }
      }
    }
  }
}
`;

const SalesMutation = `
mutation ($value: SaleInput!) {
    sale{
        add (value: $value) {
            id
            salesChannelId
            salesChannelReference
            customerReference
            shippingServiceId
            shipBy
            orderDate
            subtotal
            tax
            internalNotes
            customerNotes
            status
            stockType
            total
            dropShip
            childSaleType
        }
    }
}
`;

const CreatePurchaseOrdersFromSalesMutation = `
mutation ($supplierId: ID) {
  sale {
    createPurchaseOrdersFromSales(supplierId: $supplierId)
  }
}`;

const enum SalesSortOrder {
    IdAscending = "ID_ASCENDING",
    IdDescending = "ID_DESCENDING",
    OrderDateAscending = "ORDER_DATE_ASCENDING",
    OrderDateDescending = "ORDER_DATE_DESCENDING",
    SalesChannelNameAscending = "SALES_CHANNEL_NAME_ASCENDING",
    SalesChannelNameDescending = "SALES_CHANNEL_NAME_DESCENDING",
    SalesChannelReferenceAscending = "SALES_CHANNEL_REFERENCE_ASCENDING",
    SalesChannelReferenceDescending = "SALES_CHANNEL_REFERENCE_DESCENDING",
    ShipByAscending = "SHIP_BY_ASCENDING",
    ShipByDescending = "SHIP_BY_DESCENDING",
    ShipToAscending = "SHIP_TO_ASCENDING",
    ShipToDescending = "SHIP_TO_DESCENDING",
    ShippingMethodAscending = "SHIPPING_METHOD_ASCENDING",
    ShippingMethodDescending = "SHIPPING_METHOD_DESCENDING",
    ShippedDateAscending = "SHIPPED_DATE_ASCENDING",
    ShippedDateDescending = "SHIPPED_DATE_DESCENDING",
    DeliveredDateAscending = "DELIVERED_DATE_ASCENDING",
    DeliveredDateDescending = "DELIVERED_DATE_DESCENDING",
    VoidedDateAscending = "VOIDED_DATE_ASCENDING",
    VoidedDateDescending = "VOIDED_DATE_DESCENDING",
    StatusAscending = "STATUS_ASCENDING",
    StatusDescending = "STATUS_DESCENDING",
    TotalAscending = "TOTAL_ASCENDING",
    TotalDescending = "TOTAL_DESCENDING",
}

const SalesIndex = () => {
    //=== set up queries and mutations ===
    //TabCountQuery
    const { data: dataTabCounts } = useQuery<ITabCountQueryResult>(TabsCountQuery, {
        fetchPolicy: "cache-and-network",
    });
    //SalesChannelQuery
    const {
        data: dataSalesChannel,
        error: errorSalesChannel,
        refetch: refetchSalesChannel,
    } = useQuery<ISalesChannelQueryResult, {}>(SalesChannelQuery, { fetchPolicy: "cache-and-network" });
    //shippingServices
    const {
        data: dataShippingService,
        error: errorShippingService,
        refetch: refetchShippingService,
    } = useQuery<IShippingServiceQueryResult, {}>(ShippingServiceQuery, { fetchPolicy: "cache-and-network" });
    //Warehouses
    const {
        data: warehouseData,
        error: warehouseError,
        refetch: warehouseRefetch,
    } = useQuery<IWarehouseQuery, {}>(WarehousesQuery, { fetchPolicy: "cache-and-network" });
    //Suppliers
    const {
        data: suppliersData,
        error: suppliersError,
        refetch: suppliersRefetch,
    } = useQuery<ISuppliersQueryResult, {}>(SuppliersQuery, { fetchPolicy: "cache-and-network" });

    //=== set up state variables ===
    const [modal, setModal] = React.useState(false);
    const [createPoModal, setCreatePoModal] = React.useState(false);
    const [runAdd] = useMutation<IAddResult, IAddVariables>(SalesMutation);
    const [saving, setSaving] = React.useState(false);
    const [runCreatePurchaseOrdersFromSales] = useMutation<ICreatePurchaseOrdersFromSalesResult, ICreatePurchaseOrdersFromSalesVariables>(
        CreatePurchaseOrdersFromSalesMutation
    );
    const location = useLocation();
    const history = useHistory();
    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
    const search = new QueryString(location.search).getString("search");
    const [searchInput, setSearchInput] = React.useState(search || "");

    //GraphQL handle
    const graphQLContext = React.useContext(GraphQLContext);

    // the getrows function must be within a callback that has no dependencies, or the grid will not work properly
    // for this reason, we need to move location.pathname and search into a ref
    const locationRef = React.useRef({ pathname: location.pathname, search });
    locationRef.current = { pathname: location.pathname, search };
    const lastRunRef = React.useRef({ pathname: location.pathname, search });
    const [searchTotalCount, setSearchTotalCount] = React.useState<number | undefined>(undefined);
    const loadingGridId = React.useRef(0);
    const getRowsFunc = React.useCallback(
        (params: IGetRowsParams) => {
            const id = loadingGridId.current + 1;
            loadingGridId.current = id;
            lastRunRef.current = locationRef.current;
            setSearchTotalCount(undefined);
            let status: SaleStatus | null = null;
            let stockType: SaleStockType | null = null;
            let sortOrder: SalesSortOrder = SalesSortOrder.IdAscending; // default sort for this tab
            switch (locationRef.current.pathname) {
                case "/sales/draft":
                    status = SaleStatus.Draft;
                    sortOrder = SalesSortOrder.IdAscending;
                    break;
                case "/sales":
                    status = SaleStatus.Authorized;
                    stockType = SaleStockType.Stock;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/stock":
                    status = SaleStatus.Authorized;
                    stockType = SaleStockType.Stock;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/dropship":
                    status = SaleStatus.Authorized;
                    stockType = SaleStockType.DropShip;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/backordered":
                    status = SaleStatus.Authorized;
                    stockType = SaleStockType.Backordered;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/fulfilling":
                    status = SaleStatus.Fulfilling;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/dropshipping":
                    status = SaleStatus.DropShipping;
                    sortOrder = SalesSortOrder.ShipByAscending;
                    break;
                case "/sales/shipped":
                    status = SaleStatus.Shipped;
                    sortOrder = SalesSortOrder.ShippedDateDescending;
                    break;
                case "/sales/delivered":
                    status = SaleStatus.Delivered;
                    sortOrder = SalesSortOrder.DeliveredDateDescending;
                    break;
                case "/sales/void":
                    status = SaleStatus.Void;
                    sortOrder = SalesSortOrder.VoidedDateDescending;
                    break;
                case "/sales/searchresults":
                    status = null;
                    sortOrder = SalesSortOrder.OrderDateDescending;
                    break;
            }
            let isAscending = true;
            if (params.sortModel.length > 0) {
                if (params.sortModel[0].sort === "asc") {
                    isAscending = true;
                } else if (params.sortModel[0].sort === "desc") {
                    isAscending = false;
                }
                switch (params.sortModel[0].colId) {
                    case "salesChannel":
                        sortOrder = isAscending ? SalesSortOrder.SalesChannelNameAscending : SalesSortOrder.SalesChannelNameDescending;
                        break;
                    case "status":
                        sortOrder = isAscending ? SalesSortOrder.StatusAscending : SalesSortOrder.StatusDescending;
                        break;
                    case "orderNum":
                        sortOrder = isAscending ? SalesSortOrder.IdAscending : SalesSortOrder.IdDescending;
                        break;
                    case "orderDate":
                        sortOrder = isAscending ? SalesSortOrder.OrderDateAscending : SalesSortOrder.OrderDateDescending;
                        break;
                    case "salesChannelReference":
                        sortOrder = isAscending
                            ? SalesSortOrder.SalesChannelReferenceAscending
                            : SalesSortOrder.SalesChannelReferenceDescending;
                        break;
                    case "shipBy":
                        sortOrder = isAscending ? SalesSortOrder.ShipByAscending : SalesSortOrder.ShipByDescending;
                        break;
                    case "to":
                        sortOrder = isAscending ? SalesSortOrder.ShipToAscending : SalesSortOrder.ShipToDescending;
                        break;
                    case "shipMethod":
                        sortOrder = isAscending ? SalesSortOrder.ShippingMethodAscending : SalesSortOrder.ShippingMethodDescending;
                        break;
                    case "dateShipped":
                        sortOrder = isAscending ? SalesSortOrder.ShippedDateAscending : SalesSortOrder.ShippedDateDescending;
                        break;
                    case "dateDelivered":
                        sortOrder = isAscending ? SalesSortOrder.DeliveredDateAscending : SalesSortOrder.DeliveredDateDescending;
                        break;
                    case "dateVoided":
                        sortOrder = isAscending ? SalesSortOrder.VoidedDateAscending : SalesSortOrder.VoidedDateDescending;
                        break;
                    case "total":
                        sortOrder = isAscending ? SalesSortOrder.TotalAscending : SalesSortOrder.TotalDescending;
                        break;
                    case "id":
                        sortOrder = isAscending ? SalesSortOrder.IdAscending : SalesSortOrder.IdDescending;
                        break;
                }
            }

            let after = params.startRow === 0 ? null : params.startRow.toString();
            let ret = graphQLContext.client.ExecuteQueryRaw<ISaleQueryResult, ISaleQueryVariables>({
                query: SalesQuery,
                variables: {
                    after: after,
                    status: status,
                    search: locationRef.current.search || null,
                    stockType: stockType,
                    sortOrder: sortOrder,
                },
            });

            ret.result.then(
                //success
                (result) => {
                    //failure
                    if (result.errors) {
                        console.log("Error fetching data: 1", result.errors);
                        return;
                    }
                    //update total count
                    if (loadingGridId.current === id) {
                        setSearchTotalCount(result.data?.sales.totalCount ?? 0);
                    }
                    //feed data into AG Grid
                    params.successCallback(result.data!.sales.items, result.data!.sales.totalCount);
                },
                //failure
                (result) => {
                    params.failCallback();
                    console.log("Error fetching data: 2", result);
                }
            );
        },
        [graphQLContext.client]
    ); //note: including graphQLContext.client because it will never change anyway

    React.useEffect(() => {
        // when pressing back or doing history.push, this will trigger the grid to refresh
        if (lastRunRef.current.pathname !== location.pathname || lastRunRef.current.search !== search) gridApi?.onFilterChanged();
    }, [location.pathname, search, gridApi]);

    // memoizing the agGrid prevents it from blinking, but it needs to refresh if the columns change (based on location.pathname)
    const agGrid = React.useMemo(() => {
        //AG Grid column structure
        let columns: ColDef<ISale, any>[] = [
            {
                field: "salesChannel",
                flex: 1,
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return params.value ? (
                        <>{params?.data?.salesChannel?.name}</>
                    ) : (
                        <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />
                    );
                },
            },
            {
                field: "status",
                flex: 1,
            },
            {
                field: "orderNum" as any,
                headerName: "Order #",
                flex: 1,
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    if (params.data) {
                        const link = `/sales/${params.data.id}`;
                        return <Link to={link}>{"SO-" + params.data.id}</Link>;
                    } else {
                        return <></>;
                    }
                },
            },
            {
                field: "orderDate",
                headerName: "Order Date",
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return (
                        <>
                            {" "}
                            {StringHelper.IsNullOrWhitespace(params?.data?.orderDate)
                                ? null
                                : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params!.data!.orderDate)}
                        </>
                    );
                },
            },
            {
                field: "shipBy",
                headerName: "Ship By",
                width: 115,
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return (
                        <>
                            {" "}
                            {StringHelper.IsNullOrWhitespace(params?.data?.shipBy)
                                ? null
                                : FormatDate.ConvertDateToLocalDateMMDDYYYY(params!.data!.shipBy)}
                        </>
                    );
                },
            },
            {
                field: "salesChannelReference",
                headerName: "Sales Channel Ref",
                flex: 1.5,
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    if (params.data) {
                        return (
                            <>
                                {params.data.salesChannelReference}
                                {params.data.childSaleType === ChildSaleType.Reship ? (
                                    <span className="bi bi-bootstrap-reboot ms-2"></span>
                                ) : params.data.childSaleType === ChildSaleType.Incomplete ? (
                                    <span className="bi bi-truck ms-2"></span>
                                ) : null}
                            </>
                        );
                    } else {
                        return <></>;
                    }
                },
            },
            {
                field: "to" as any,
                flex: 1,
                headerName: "To",
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return (
                        <>
                            {params?.data?.shippingAddress?.firstName} {params?.data?.shippingAddress?.lastName}
                        </>
                    );
                },
            },
            {
                field: "shipMethod" as any,
                flex: 1,
                headerName: "Ship Method",
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return <>{params?.data?.shippingService?.name}</>;
                },
            },
            {
                field: "total",
                flex: 1,
                headerName: "Total",
                cellRenderer: (params: ICellRendererParams<ISale>) => {
                    return <>${params?.data?.total.toFixed(2)}</>;
                },
            },
        ];

        const index = columns.findIndex((x) => x.headerName === "Order Date");

        if (location.pathname === "/sales/delivered") {
            columns = columns.filter((x) => x.headerName !== "Ship By");
            columns.splice(index + 1, 0, {
                field: "dateDelivered",
                flex: 1,
                headerName: "Delivered On",
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <>
                            {" "}
                            {StringHelper.IsNullOrWhitespace(params?.data?.dateDelivered)
                                ? null
                                : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params!.data!.dateDelivered)}
                        </>
                    );
                },
            });
        }

        if (location.pathname === "/sales/shipped") {
            columns = columns.filter((x) => x.headerName !== "Ship By");
            columns.splice(index + 1, 0, {
                field: "dateShipped",
                flex: 1,
                headerName: "Shipped On",
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <>
                            {" "}
                            {StringHelper.IsNullOrWhitespace(params?.data?.dateShipped)
                                ? null
                                : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params!.data!.dateShipped)}
                        </>
                    );
                },
            });
        }

        if (location.pathname === "/sales/void") {
            columns = columns.filter((x) => x.headerName !== "Ship By");
            columns.splice(index + 1, 0, {
                field: "dateVoided",
                flex: 1,
                headerName: "Voided On",
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <>
                            {" "}
                            {StringHelper.IsNullOrWhitespace(params?.data?.dateVoided)
                                ? null
                                : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params!.data!.dateVoided)}
                        </>
                    );
                },
            });
        }

        const onGridReady = (params: GridReadyEvent) => {
            setGridApi(params.api);
            let dataSource: IDatasource = {
                getRows: getRowsFunc,
            };
            params.api.setDatasource(dataSource);
        };

        return (
            <AgGridReact
                columnDefs={columns}
                defaultColDef={{
                    suppressMovable: true,
                    resizable: true,
                    minWidth: 110,
                    sortable: true,
                }}
                components={{
                    loadingRenderer: function (params: ICellRendererParams) {
                        if (params.value !== undefined) {
                            return params.value;
                        } else {
                            return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                        }
                    },
                }}
                onGridReady={onGridReady}
                animateRows={true}
                domLayout="autoHeight"
                rowModelType={"infinite"}
                paginationPageSize={30}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
                pagination={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
            ></AgGridReact>
        );
    }, [location.pathname, getRowsFunc]);

    //display message if failed to retrieve data
    if (errorSalesChannel) return <ErrorDisplay onClick={refetchSalesChannel}>{errorSalesChannel.message}</ErrorDisplay>;
    if (errorShippingService) return <ErrorDisplay onClick={refetchShippingService}>{errorShippingService.message}</ErrorDisplay>;
    if (warehouseError) return <ErrorDisplay onClick={warehouseRefetch}>{warehouseError.message}</ErrorDisplay>;
    if (suppliersError) return <ErrorDisplay onClick={suppliersRefetch}>{suppliersError.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!dataSalesChannel || !dataShippingService || !warehouseData || !suppliersData) return <Loading />;
    const activeSalesChannels = dataSalesChannel.salesChannels.items.filter((x) => x.active === true) || "";
    //filter out active shipping services
    const activeShippingServices = dataShippingService.shippingServices.items.filter((x) => x.active === true) || "";
    const onHideModal = () => setModal(false);
    const onShowModal = () => setModal(true);
    const onHideCreatePoModal = () => {
        setCreatePoModal(false);
    };
    const onShowCreatePoModal = () => {
        setCreatePoModal(true);
    };

    const originalCreatePoModel: ICreatePurchaseOrdersFromSalesVariables = {
        supplierId: "",
    };

    //setting all data to empty for the original modal
    const originalModel: ISaleModel = {
        salesChannelId: "",
        orderDate: "",
        shipBy: "",
        salesChannelReference: "",
        customerReference: "",
        customerNotes: "",
        shippingServiceId: "",
        internalNotes: "",
        dropShip: false,
    };

    const onSaveChanges = (value: ISaleModel) => {
        //set form controls
        setSaving(true);
        //start add operation via graphql mutation
        runAdd({
            variables: {
                value: {
                    id: "0",
                    salesChannelId: StringHelper.IsNullOrWhitespace(value.salesChannelId) ? null : value.salesChannelId,
                    salesChannelReference: StringHelper.IsNullOrWhitespace(value.salesChannelReference)
                        ? null
                        : value.salesChannelReference,
                    customerReference: StringHelper.IsNullOrWhitespace(value.customerReference) ? null : value.customerReference,
                    shipBy: StringHelper.IsNullOrWhitespace(value.shipBy) ? null : FormatDate.ConvertToDateType(value.shipBy),
                    status: SaleStatus.Draft,
                    subtotal: 0,
                    tax: 0,
                    total: 0,
                    billingAddress: null,
                    shippingAddress: null,
                    shippingServiceId: StringHelper.IsNullOrWhitespace(value.shippingServiceId) ? null : value.shippingServiceId,
                    orderDate: StringHelper.IsNullOrWhitespace(value.orderDate) ? null : FormatDate.ConvertDateToUTC(value.orderDate),
                    dropShip: false,
                    customerNotes: StringHelper.IsNullOrWhitespace(value.customerNotes) ? null : value.customerNotes,
                    internalNotes: StringHelper.IsNullOrWhitespace(value.internalNotes) ? null : value.internalNotes,
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                //set path to new sale ID that is created
                let path = `/sales/` + ret.data.sale.add.id + `/edit`;
                //push ID to history to redirect to new sale page
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding sales", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const onCreatePurchaseOrders = (value: ICreatePurchaseOrdersFromSalesVariables) => {
        setSaving(false);
        runCreatePurchaseOrdersFromSales({
            variables: {
                supplierId: value.supplierId || null,
            },
        }).then(
            (ret) => {
                alert(`Created ${ret.data.sale.createPurchaseOrdersFromSales} purchase orders`);
                onHideCreatePoModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error creating purchase orders from sales", err);
                alert(err.message);
            }
        );
    };

    const suppliers = suppliersData.suppliers.items.filter((x) => x.active).sort((x, y) => (x.name > y.name ? 1 : -1));

    const formatTabCount = (value: ITabCount | undefined) => (value ? ` (${value.totalCount})` : "");

    const onSubmitSearchForm = (event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        history.push({
            pathname: "/sales/searchresults",
            search: "search=" + encodeURIComponent(searchInput),
        });
    };

    return (
        <>
            <PageHeader>Sales</PageHeader>
            <Row>
                <Col>
                    <Form.Label>Search</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md="3">
                    <Form onSubmit={onSubmitSearchForm}>
                        <InputGroup>
                            <Form.Control
                                id="searchValue"
                                type="text"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <Button type="submit" variant="primary">
                                Search
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md="3">
                    <Button onClick={onShowModal} variant="white">
                        Add sale order
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="3">
                    <Button onClick={onShowCreatePoModal} variant="primary">
                        Create purchase orders
                    </Button>
                </Col>
            </Row>
            <Nav variant="tabs" activeKey={location.pathname} className="mb-3 mt-3">
                <Nav.Item>
                    <LinkContainer to="/sales/draft" exact>
                        <Nav.Link>Draft ({dataTabCounts?.tabDraft.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales" exact>
                        <Nav.Link>Stock{formatTabCount(dataTabCounts?.tabStock)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/dropship" exact>
                        <Nav.Link>Drop Ship{formatTabCount(dataTabCounts?.tabDropShip)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/backordered" exact>
                        <Nav.Link>Backordered{formatTabCount(dataTabCounts?.tabBackordered)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/fulfilling" exact>
                        <Nav.Link>Fulfilling{formatTabCount(dataTabCounts?.tabFulfilling)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/dropshipping" exact>
                        <Nav.Link>Drop Shipping{formatTabCount(dataTabCounts?.tabDropShipping)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/shipped" exact>
                        <Nav.Link>Shipped{formatTabCount(dataTabCounts?.tabShipped)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/delivered" exact>
                        <Nav.Link>Delivered{formatTabCount(dataTabCounts?.tabDelivered)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/sales/void" exact>
                        <Nav.Link>Void{formatTabCount(dataTabCounts?.tabVoid)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                {search ? (
                    <Nav.Item>
                        <LinkContainer to="/sales/searchresults" exact>
                            <Nav.Link>
                                Search Results
                                {searchTotalCount === undefined ? null : ` (${searchTotalCount})`}
                            </Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                ) : null}
            </Nav>

            <div className="ag-theme-alpine mt-3">{agGrid}</div>

            <Modal show={modal} onHide={onHideModal} size={"lg" as any}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Sales Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Group as={Row} className="mb-3" controlId="modalSalesChannelId">
                                <VLabel title="Sales Channel" valueName="salesChannelId" column sm={3} />
                                <Col sm={9}>
                                    <VSelect valueName="salesChannelId">
                                        <option value=""></option>
                                        {activeSalesChannels.map((salesChannels) => (
                                            <option key={`sales-${salesChannels.id}`} value={salesChannels.id}>
                                                {salesChannels.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalSalesChannelReference">
                                <VLabel title="Sales Channel Ref" valueName="salesChannelReference" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="salesChannelReference" type="text" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalOrderDate">
                                <VLabel title="Order Date" valueName="orderDate" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="orderDate" type="datetime-local" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalShipBy">
                                <VLabel title="Ship By" valueName="shipBy" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="shipBy" type="date" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalShippingServiceId">
                                <VLabel title="Shipping Method" valueName="shippingServiceId" column sm={3} />
                                <Col sm={9}>
                                    <VSelect valueName="shippingServiceId">
                                        <option value=""></option>
                                        {activeShippingServices.map((shippingService) => (
                                            <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                                {shippingService.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Col>
                            </Form.Group>
                            {/*<Form.Group as={Row} className="mb-3" controlId="modalWarehouseId">*/}
                            {/*    <VLabel title="Warehouse" valueName="warehouseId" column sm={3} />*/}
                            {/*    <Col sm={9}>*/}
                            {/*        <VSelect valueName="warehouseId" required>*/}
                            {/*            */}
                            {/*Query from unitOfMeasures by id to get value and display by name*/}
                            {/*            <option value=""></option>*/}
                            {/*            {*/}
                            {/*                warehouseData.warehouses.items.map(warehouse =>*/}
                            {/*                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*        </VSelect>*/}
                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                            <Form.Group as={Row} className="mb-3" controlId="modalCustomerReference">
                                <VLabel title="Customer Ref" valueName="customerReference" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="customerReference" type="text" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalCustomerNotes">
                                <VLabel title="Customer Notes" valueName="customerNotes" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="customerNotes" type="text" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="modalInternalNotes">
                                <VLabel title="Notes" valueName="internalNotes" column sm={3} />
                                <Col sm={9}>
                                    <VControl rows={3} type="textarea" valueName="internalNotes" />
                                </Col>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save Changes
                        </Button>
                        <Button variant="secondary" onClick={onHideModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
            <Modal show={createPoModal} onHide={onHideCreatePoModal}>
                <VForm onSubmit={onCreatePurchaseOrders} initialValue={originalCreatePoModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Purchase Orders - Drop Ship</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4">
                                    <VLabel valueName="supplierId">Supplier (Empty to create ZPOs for all suppliers)</VLabel>
                                    <VSelect valueName="supplierId">
                                        <option value=""></option>
                                        {suppliers.map((supplier) => (
                                            <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Run
                        </Button>
                        <Button variant="secondary" onClick={onHideCreatePoModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SalesIndex;
