import { useQuery } from "@shane32/graphql";
import FormatDate from "../helpers/FormatDate";
import { useSettingsNumberValue } from "./useSettingsValue";

const SalesQuery = `
query ($status: SaleStatus, $shipBy: DateOnly) {
    sales (status: $status, shipBy: $shipBy) {
        totalCount
    }
}
`;

interface ISaleBase {
    sales: ISaleData;
}
interface ISaleData {
    totalCount: number;
}

interface IWarehouseMetrics {
    data?: {
        estFullfillmentTime?: string;
        needPickedCount?: number;
        needPackedCount?: number;
        pickTime?: number;
        packTime?: number;
        workers?: number;
        lunchDuration?: number;
        lunchStartHour?: number;
        startHour?: number;
    };
    error?: string;
    loading: boolean;
}

const useWarehouseMetrics = (): IWarehouseMetrics => {
    const { data: authorisedSalesData, loading: lAuth } = useQuery<ISaleBase, {}>(SalesQuery, {
        variables: { status: "AUTHORIZED", shipBy: FormatDate.ConvertDateToLocalDatePicker(new Date()) },
        fetchPolicy: "no-cache",
    });
    const { data: fufilledSalesData, loading: lFul } = useQuery<ISaleBase, {}>(SalesQuery, {
        variables: { status: "FULFILLING", shipBy: FormatDate.ConvertDateToLocalDatePicker(new Date()) },
        fetchPolicy: "no-cache",
    });

    const { settingsValue: pickTime, loading: lPick } = useSettingsNumberValue(process.env.REACT_APP_SETTING_PICK_TIME ?? "");
    const { settingsValue: packTime, loading: lPack } = useSettingsNumberValue(process.env.REACT_APP_SETTING_PACK_TIME ?? "");
    const { settingsValue: workers, loading: lWorkers } = useSettingsNumberValue(process.env.REACT_APP_SETTING_WAREHOUSE_WORKER ?? "");
    const { settingsValue: lunchDurationMinutes, loading: lLunchDurationMinutes } = useSettingsNumberValue(
        process.env.REACT_APP_SETTING_WAREHOUSE_LUNCH_DURATION_MINUTES ?? ""
    );
    const { settingsValue: startHour, loading: lstartHour } = useSettingsNumberValue(
        process.env.REACT_APP_SETTING_WAREHOUSE_START_TIME_HOUR ?? ""
    );
    const { settingsValue: lunchStartHour, loading: llunchStartHour } = useSettingsNumberValue(
        process.env.REACT_APP_SETTING_WAREHOUSE_LUNCH_TIME_HOUR ?? ""
    );

    const needPickCount = authorisedSalesData?.sales.totalCount ?? 0;
    const needPackedCount = fufilledSalesData?.sales.totalCount ?? 0;

    if (lAuth || lFul || lPick || lPack || lWorkers || lstartHour || llunchStartHour || lLunchDurationMinutes) {
        return { loading: true };
    }

    if (!pickTime || !packTime || !workers || !startHour || !lunchStartHour || !lunchDurationMinutes) {
        return {
            error: `failed to load settings
            ${process.env.REACT_APP_SETTING_PICK_TIME}
            ${process.env.REACT_APP_SETTING_PACK_TIME}
            ${process.env.REACT_APP_SETTING_WAREHOUSE_WORKER}`,
            loading: false,
        };
    }

    const minutesLeft = (needPickCount * (pickTime + packTime) + needPackedCount * packTime) / workers;
    var estDone = new Date();
    const now = new Date();
    estDone.setMinutes(estDone.getMinutes() + minutesLeft);

    //if before start time add the difference
    if (now.getHours() < startHour) {
        const diffHours = startHour - now.getHours();
        const diffMinutes = diffHours * 60 - now.getMinutes();
        estDone.setMinutes(estDone.getMinutes() + diffMinutes);
    }

    //if after lunch add 30 minutes
    const nowBeforeLunchHour = now.getHours() < lunchStartHour;
    const nowDuringLunchTime = now.getHours() === lunchStartHour && now.getMinutes() < lunchDurationMinutes;

    if (estDone.getHours() >= lunchStartHour && (needPackedCount > 0 || needPickCount > 0)) {
        if (nowDuringLunchTime) {
            //adds only the remaining lunch time
            estDone.setMinutes(estDone.getMinutes() + lunchDurationMinutes - now.getMinutes());
        } else if (nowBeforeLunchHour) {
            //add full lunch time
            estDone.setMinutes(estDone.getMinutes() + lunchDurationMinutes);
        }
    }
    return {
        data: {
            estFullfillmentTime: FormatDate.ConvertDateTimeTo12HrLocalDateTime(estDone),
            needPickedCount: needPickCount,
            needPackedCount: needPackedCount,
            pickTime: pickTime,
            packTime: packTime,
            workers: workers,
            lunchDuration: lunchDurationMinutes,
            startHour: startHour,
            lunchStartHour: lunchStartHour,
        },
        loading: false,
    };
};

export default useWarehouseMetrics;
