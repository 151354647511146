import { Route, Switch } from "react-router-dom";
import AddOrPrintBarcodeContainer from "./AddOrPrintBarcodeContainer";
import StockTake from "./StockTake";
import StockTakeScanBin from "./StockTakeScanBin";
import StockTakeSearch from "./StockTakeSearch";

const StockTakeController = () => {
    return (
        <Switch>
            <Route exact path={`/mobile/stocktake/stocktakesearch`} component={StockTakeSearch} />
            <Route exact path={`/mobile/stocktake/stocktakescanbin/:productId`} component={StockTakeScanBin} />
            <Route exact path={`/mobile/stocktake/stocktake/:locationId`} component={StockTake} />
            <Route exact path={`/mobile/stocktake/stocktake/:locationId/:productId`} component={AddOrPrintBarcodeContainer} />
        </Switch>
    );
};

export default StockTakeController;
