import { createElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import PurchaseInvoiceStatus from "../../../../enums/PurchaseInvoiceStatus";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useScanner from "../../hooks/useScanner";
//import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import PurchaseInvoiceSearch, { ISearchComponentProps, PurchaseInvoiceSearchComponentType } from "../_shared/PurchaseInvoiceSearch";
import SelectionType from "../../../../enums/SelectionType";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import GroupBy from "../../../../helpers/GroupBy";
import useLocationInputModal from "../../hooks/useLocationInputModal";
import { useActionBarContext } from "../../contexts/ActionBarContext/ActionBarContext";
import useDatePickerModal from "../../hooks/useDatePickerModal";

const productsQuery = `
products {
    stockOnHand
    product {
      id
      sku
    }
  }
`;

const purchaseInvoiceQueryBase = `
purchaseInvoice(id: $purchaseInvoiceId) {
    id
    status
    invoiceNumber
    invoiceDate
    arrivalDate
    subtotal
    tax
    total
    purchaseOrderId
    paymentTermId
    lineItems {
      id
      purchaseOrderLineItemId
      expectedQuantity
      actualQuantity
      unitPrice
      discount
      comment
      total
      purchaseOrderLineItem {
        id
        partNumber
        supplierSku
        product {
          id
          sku
          description
          barcodes {
            barcode
          }
        }
      }
    }
  }
  location(id: $locationId) {
    id
    name
    ${productsQuery}
    pickZone {
      id
      isCart
      locations {
        id
        name
        ${productsQuery}
      }
    }
  }
`;

const purchaseInvoiceQuery = `
query ($purchaseInvoiceId: ID!, $locationId: ID!) {
  ${purchaseInvoiceQueryBase}
}
`;

interface IProduct {
    stockOnHand: number;
    product: {
        id: string;
        sku: string;
    };
}

interface ILocation {
    id: string;
    name: string;
    products: Array<IProduct>;
}

interface IPurchaseInvoiceQueryResult {
    purchaseInvoice: IPurchaseInvoice;
    location: {
        id: string;
        name: string;
        products: Array<IProduct>;
        pickZone: {
            id: string;
            isCart: boolean;
            locations: Array<ILocation>;
        };
    } | null;
}

interface IPurchaseInvoice {
    id: string;
    status: PurchaseInvoiceStatus;
    invoiceNumber: string;
    arrivalDate: string | null;
    invoiceDate: string;
    subtotal: number;
    tax: number;
    total: number;
    purchaseOrderId: string;
    paymentTermId: string;
    lineItems: IPurchaseInvoiceLineItem[];
}

interface IPurchaseInvoiceLineItem {
    expectedQuantity: number;
    actualQuantity: number;
    purchaseOrderLineItem: IPurchaseOrderLineItem;
}

interface IPurchaseOrderLineItem {
    id: string;
    partNumber: string | null;
    supplierSku: string | null;
    product: {
        id: string;
        sku: string;
        description: string | null;
        barcodes: Array<{
            barcode: string;
        }>;
    } | null;
}

interface IPurchaseInvoiceQueryVariables {
    purchaseInvoiceId: string;
    locationId: string;
}

const moveFromPurchaseInvoiceMutation = `
mutation ($purchaseInvoiceId: ID!, $productId: ID!, $toLocationId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!, $locationId: ID!) {
  inventory {
    moveFromPurchaseInvoice(purchaseInvoiceId: $purchaseInvoiceId, productId: $productId, locationId: $toLocationId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
  }
  query {
    ${purchaseInvoiceQueryBase}
  }
}
`;

interface IMoveFromPurchaseInvoiceMutationResult {
    query: IPurchaseInvoiceQueryResult;
}

interface IMoveFromPurchaseInvoiceMutationVariables extends IPurchaseInvoiceQueryVariables {
    productId: string;
    toLocationId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

const editPurchaseInvoiceMutation = `
mutation ($original: PurchaseInvoiceInput!, $modified: PurchaseInvoiceInput!, $purchaseInvoiceId: ID!, $locationId: ID!) {
  purchaseInvoice {
    edit(original: $original, modified: $modified) {
        id
        invoiceNumber
        invoiceDate
        arrivalDate
        status
        subtotal
        tax
        total
        purchaseOrderId
        paymentTermId
    }
  }
  query {
    ${purchaseInvoiceQueryBase}
  }
}
`;

interface IEditPurchaseInvoiceMutationResult {
    query: IPurchaseInvoiceQueryResult;
}

interface IEditPurchaseInvoiceMutationVariables {
    original: IEditPurchaseInvoiceInput;
    modified: IEditPurchaseInvoiceInput;
    purchaseInvoiceId: string;
    locationId: string;
}

interface IEditPurchaseInvoiceInput {
    id: string;
    purchaseOrderId: string;
    invoiceNumber: string;
    invoiceDate: string;
    arrivalDate: string | null;
    status: PurchaseInvoiceStatus;
    subtotal: number;
    tax: number;
    total: number;
    paymentTermId: string;
}

//const completeInvoiceMutation = `
//mutation ($original: PurchaseOrderInput!, $modified: PurchaseOrderInput!) {
//  purchaseOrder {
//    edit(original: $original, modified: $modified) {
//      invoices {
//        id
//        status
//        invoiceNumber
//        invoiceDate
//        subtotal
//        tax
//        total
//        paymentTermId
//      }
//    }
//  }
//}
//`;

//interface ICompleteInvoicePurchaseOrderBase extends IPurchaseOrder { };
//interface ICompleteInvoicePurchaseOrder extends StrictOmit<ICompleteInvoicePurchaseOrderBase, "invoices"> {
//    invoices: ICompleteInvoicePurchaseInvoice[]
//};

//interface ICompleteInvoicePurchaseInvoiceBase extends IPurchaseInvoice { };
//interface ICompleteInvoicePurchaseInvoice extends StrictOmit<ICompleteInvoicePurchaseInvoiceBase, "lineItems"> { };

//interface ICompleteInvoiceMutationVariables {
//    original: ICompleteInvoicePurchaseOrder;
//    modified: ICompleteInvoicePurchaseOrder;
//};

//interface ICompleteInvoiceMutationResult {
//    purchaseOrder: {
//        edit: {
//            invoices: IPurchaseInvoice[];
//        };
//    };
//};

interface IRouteParams {
    purchaseOrderId: string;
    purchaseInvoiceId: string;
}

const ScanBarcode = () => {
    const actionBarContext = useActionBarContext();
    const history = useHistory();
    const params = useParams<IRouteParams>();
    const { search, pathname } = useLocation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [numberInHand, setNumberInHand] = useState(0);
    const [lastScannedLocationId, setLastScannedLocationId] = useState("0");
    //const [autoStow, setAutoStow] = useState(false);
    //const { selectModal, showSelectModal } = useSelectModal();
    const { datePickerModal, showDatePickerModal } = useDatePickerModal();
    const { confirmModal, showConfirmModal } = useConfirm();
    const { locationInputModal, showLocationInputModal, open: isLocationInputModalOpen } = useLocationInputModal();
    const { multiMatchSelectorModal, showMultiMatchSelectorModal } = useMultiMatchSelectorModal();
    const [manualLoading, setManualLoading] = useState(false);
    const [productSelectionType, setProductSelectionType] = useState(SelectionType.None);
    const [searchComponentValues, setSearchComponentValues] = useState<ISearchComponentProps>({
        showSearchComponent: false,
        searchValue: "",
    });
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runMoveFromPurchaseInvoiceMutation] = useMutation<
        IMoveFromPurchaseInvoiceMutationResult,
        IMoveFromPurchaseInvoiceMutationVariables
    >(moveFromPurchaseInvoiceMutation);
    const [runEditPurchaseInvoiceMutation] = useMutation<IEditPurchaseInvoiceMutationResult, IEditPurchaseInvoiceMutationVariables>(
        editPurchaseInvoiceMutation
    );
    //const [runCompleteInvoiceMutation] = useMutation<ICompleteInvoiceMutationResult, ICompleteInvoiceMutationVariables>(completeInvoiceMutation);
    const { loading, data, refetch, error } = useQuery<IPurchaseInvoiceQueryResult, IPurchaseInvoiceQueryVariables>(purchaseInvoiceQuery, {
        fetchPolicy: "no-cache",
        variables: {
            purchaseInvoiceId: params.purchaseInvoiceId,
            locationId: lastScannedLocationId,
        },
    });
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const onScan = async (scannerValue: string) => {
        if (loading || manualLoading || !data || !staticPurchaseInvoice || !scannerValue || isLocationInputModalOpen) return;
        const switchProduct = async (productId: string) => {
            const purchaseOrderLineItem = staticPurchaseInvoice.lineItems
                .map((pili) => pili.purchaseOrderLineItem)
                .find((poli) => poli.product?.id === productId);
            if (purchaseOrderLineItem) {
                setSearchComponentValues({ ...searchComponentValues, showSearchComponent: false });
                if (purchaseOrderLineItem.product?.id === activeItem?.purchaseOrderLineItem?.product?.id) {
                    // if (autoStow) {
                    //     await stow(SelectionType.Scanned, undefined, undefined, undefined, 1);
                    // } else {
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    increment();
                    // }
                } else {
                    if (!purchaseOrderLineItem.product)
                        return alert(`Could not find a product id for purchase order line item id ${purchaseOrderLineItem.id}`);
                    //if (!purchaseOrderLineItem.partNumber) return alert(`Could not find a part number for purchase order line item id ${purchaseOrderLineItem.id}`);
                    const confirmed =
                        numberInHand < 1
                            ? true
                            : await showConfirmModal("You have items in hand, do you want to put them back?", "Put Items Back?", {
                                  confirm: "Yes",
                                  cancel: "No",
                              });
                    if (!confirmed) return;
                    setProductSelectionType(SelectionType.Scanned);
                    let newURLSP = new URLSearchParams();
                    newURLSP.append("partId", purchaseOrderLineItem.product.id);
                    if (supplierId) {
                        newURLSP.append("supplierId", supplierId);
                    }
                    history.replace(`${pathname}?${newURLSP.toString()}`);
                    //if (autoStow) await stow(SelectionType.Scanned, undefined, undefined, purchaseOrderLineItem.product.id, 1);
                    //else {
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    setNumberInHand(1);
                    //}
                }
            } else {
                //reaching this point means that there was a product in the db that matched a product we scanned, but it is not something that looks to be in the active purchase order invoice,
                //so we need to redirect to the search page to decide what we want to do with the product
                playNegativeTone();
                redirectToSearch(scannerValue);
            }
        };
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            //check if there are matches for products and locations from the scanned value
            if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                await showConfirmModal(
                    formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                    "Multiple Matches In Database",
                    { confirm: "Confirm" },
                    true
                );
            } else if (barcodeProducts.length > 0) {
                //check to see how many matches there are
                if (barcodeProducts.length === 1) {
                    //only match for a product. get product object and continue like normal.
                    //verify that the scanned product matches something we want on the active purchase order invoice.
                    await switchProduct(barcodeProducts[0].id);
                } else {
                    const selectedProduct = await showMultiMatchSelectorModal(
                        "Select Correct Product SKU",
                        barcodeProducts.map((x) => x.id),
                        MultiMatchDataType.Product
                    );
                    if (!selectedProduct) return;
                    //find the purchase order line item that matches the selected product id
                    await switchProduct(selectedProduct.id);
                }
            } else if (barcodeLocations.length > 0) {
                //check to see how many matches there are
                if (barcodeLocations.length === 1) {
                    await stow(productSelectionType, { id: barcodeLocations[0].id, name: scannerValue }, SelectionType.Scanned);
                } else {
                    const selectedLocation = await showMultiMatchSelectorModal(
                        "Select Correct Location",
                        barcodeLocations.map((x) => x.id),
                        MultiMatchDataType.Location
                    );
                    if (!selectedLocation) return;
                    await stow(productSelectionType, { id: selectedLocation.id, name: selectedLocation.name }, SelectionType.Searched);
                }
            } else {
                //reaching here means there are no matches and we need to navigate to the search page
                playNegativeTone();
                if (numberInHand > 0) return;
                redirectToSearch(scannerValue);
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    //this gets called when "addToHand" is in the query string. this increments the amount in hand by one and replaces the current url.
    useEffectOnce(() => {
        if (addToHand) {
            if (activeItem) {
                setNumberInHand(1);
                urlSearchParams.delete("addToHand");
                history.replace(`${history.location.pathname}?${urlSearchParams.toString()}`);
            }
        }
    });

    useEffect(() => {
        (async () => {
            if (data && !data.purchaseInvoice?.arrivalDate && !loading && !manualLoading) {
                // display modal that lets the user select a date for when the invoice arrived
                const value = await showDatePickerModal("Enter Invoice Arrival Date");
                if (!value) return;
                await editPurchaseInvoiceAsync(data, value);
            }
        })();
    }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

    const urlSearchParams = new URLSearchParams(search);
    const partIdFromUrlSearchParams = urlSearchParams.get("partId")?.trim();
    const addToHand = urlSearchParams.get("addToHand")?.trim();
    const supplierId = urlSearchParams.get("supplierId")?.trim();

    /**
     * Represents a purchase order invoice. Instantiated as "const" so it should never change.
     * */
    const staticPurchaseInvoice = data?.purchaseInvoice;

    let purchaseInvoiceLineItemsGrouped = GroupBy(
        [...(staticPurchaseInvoice?.lineItems ?? [])],
        (x) => x.purchaseOrderLineItem.product?.id ?? ""
    );
    const matchingPurchaseInvoiceLineItems = purchaseInvoiceLineItemsGrouped.get(partIdFromUrlSearchParams ?? "");
    let activeItem: IPurchaseInvoiceLineItem | undefined = undefined;
    if (matchingPurchaseInvoiceLineItems) {
        //from here we need to choose which purchase order line item we want to use as the active item. we will choose the one with the highest expected quantity.
        //if the actual quantity is equal to the expected quantity, then we will move on to the next purchase order line item where the actual quantity is less than the expected quantity.
        let selectedPurchaseOrderLineItem: IPurchaseOrderLineItem | undefined = undefined;
        for (let purchaseInvoiceLineItem of matchingPurchaseInvoiceLineItems) {
            if (purchaseInvoiceLineItem.expectedQuantity > purchaseInvoiceLineItem.actualQuantity) {
                selectedPurchaseOrderLineItem = purchaseInvoiceLineItem.purchaseOrderLineItem;
                break;
            }
        }
        //if all of the purchase order line items have their respective matching purchase invoice line items with the same actual and expected quantity, then we will choose the first one.
        if (!selectedPurchaseOrderLineItem) selectedPurchaseOrderLineItem = matchingPurchaseInvoiceLineItems[0].purchaseOrderLineItem;
        activeItem = {
            actualQuantity: matchingPurchaseInvoiceLineItems.reduce((accumulator, value) => accumulator + value.actualQuantity, 0),
            expectedQuantity: matchingPurchaseInvoiceLineItems.reduce((accumulator, value) => accumulator + value.expectedQuantity, 0),
            purchaseOrderLineItem: selectedPurchaseOrderLineItem,
        };
    }

    const increment = useCallback(() => {
        setNumberInHand((prevNumberInHand) => {
            if (!activeItem) return prevNumberInHand;
            return Math.min(prevNumberInHand + 1, activeItem.expectedQuantity - activeItem.actualQuantity);
        });
    }, [activeItem]);

    const decrement = useCallback(() => {
        setNumberInHand((prevNumberInHand) => {
            if (prevNumberInHand < 1) return 0;
            return prevNumberInHand - 1;
        });
    }, []);

    const max = useCallback(() => {
        if (!activeItem) return;
        setNumberInHand(activeItem.expectedQuantity - activeItem.actualQuantity);
    }, [activeItem]);

    const redirectToSearch = (value: string) => {
        if (!value) return;
        //history.push(`/mobile/intake/${params.cartId}/${params.intakeType}/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${value}/search?partNumber=${activeItem?.purchaseOrderLineItem.partNumber ?? ""}`);
        setSearchComponentValues({ showSearchComponent: true, searchValue: value });
    };

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for purchase invoice failed"}</ErrorDisplay>;

    if (
        !loading &&
        ((staticPurchaseInvoice && staticPurchaseInvoice.status !== PurchaseInvoiceStatus.Authorized) || !staticPurchaseInvoice)
    ) {
        return <Redirect to={`/mobile/intake/${params.purchaseOrderId}/selectinvoice`} />;
    }

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/intake/selectpurchaseorder`} />;

    if (!params.purchaseInvoiceId) return <Redirect to={`/mobile/intake/${params.purchaseOrderId}/selectinvoice`} />;

    //unaltered map of all products on the invoice by product id
    const invoiceProductsMap = GroupBy(staticPurchaseInvoice?.lineItems ?? [], (pili) => pili.purchaseOrderLineItem.product?.id ?? "");

    if (activeItem) {
        purchaseInvoiceLineItemsGrouped.delete(activeItem.purchaseOrderLineItem.product?.id ?? "");
    }

    const lastScannedLocations = (() => {
        switch (data?.location?.pickZone?.isCart) {
            case true:
                return data.location.pickZone.locations;
            case false:
                return [data.location];
            default:
                return [];
        }
    })();
    /** The display name of the location where a product is going to be stowed */
    let cartLocationText = "Any";
    /** The amount of products in all locations, that match the active product */
    // const matchingStockInLocation =
    //     data?.pickZone.locations
    //         .flatMap((locations) => locations.products)
    //         ?.filter((product) => product.productId === activeItem?.purchaseOrderLineItem.product?.id)
    //         .reduce((accumulator, value) => accumulator + value.stockOnHand, 0) ?? 0;

    const next = () => {};

    const go = () => {
        if (!inputRef || !inputRef.current || !inputRef.current.value) return;
        const value = inputRef.current.value.trim();
        if (!value) return;
        setSearchComponentValues({ showSearchComponent: true, searchValue: value });
    };

    const stow = async (
        productSelectionType: SelectionType,
        toLocation?: { id: string; name: string },
        locationSelectionType?: SelectionType,
        productId?: string,
        quantity?: number
    ) => {
        if (data && !data.purchaseInvoice.arrivalDate) {
            const value = await showDatePickerModal("Enter Invoice Arrival Date");
            if (!value) return;
            await editPurchaseInvoiceAsync(data, value);
        }

        try {
            if (!data?.purchaseInvoice) return alert("No purchase invoice!");
            if (loading || manualLoading) return alert("There is already a running process!");
            if (!activeItem) return alert("No active item!");
            if (quantity && (quantity < 1 || quantity > activeItem.expectedQuantity - activeItem.actualQuantity))
                return alert("Invalid quantity!");
            if (numberInHand < 1) {
                playNegativeTone();
                return alert("There are no items in hand.");
            }
            setManualLoading(true);
            let selectedLocation: { id: string; name: string } | undefined = toLocation;
            if (!selectedLocation) {
                if (!activeItem.purchaseOrderLineItem.product?.id) return alert("Could not find a product id for the active item.");
                let locationName = (
                    await showLocationInputModal({
                        modalTitle: "Enter a Location Name",
                        productId: activeItem.purchaseOrderLineItem.product.id,
                        sku: activeItem.purchaseOrderLineItem.product.sku,
                    })
                )?.trim();
                if (!locationName) return;
                const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: true } });
                const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
                if (barcodeLocations.length > 0) {
                    if (barcodeLocations.length > 1) {
                        const value = await showMultiMatchSelectorModal(
                            "Select Correct Location",
                            barcodeLocations.map((x) => x.id),
                            MultiMatchDataType.Location
                        );
                        if (!value) return;
                        selectedLocation = { id: value.id, name: value.name };
                    } else selectedLocation = { id: barcodeLocations[0].id, name: locationName };
                } else {
                    return playNegativeTone();
                }
            }

            if (!selectedLocation || !activeItem?.purchaseOrderLineItem.product?.id) return;

            const qty = quantity ?? numberInHand;

            const res = await runMoveFromPurchaseInvoiceMutation({
                variables: {
                    toLocationId: selectedLocation.id,
                    productId: productId ?? activeItem.purchaseOrderLineItem.product.id,
                    purchaseInvoiceId: params.purchaseInvoiceId,
                    quantity: qty,
                    locationSelectionType: !locationSelectionType ? SelectionType.None : locationSelectionType,
                    productSelectionType: productSelectionType,
                    locationId: selectedLocation.id,
                },
            });
            const resData = res.data;
            //data.pickZone = resData.query.pickZone;
            data.purchaseInvoice = resData.query.purchaseInvoice;
            data.location = resData.query.location;
            playPositiveTone();
            setProductSelectionType(SelectionType.None);
            setLastScannedLocationId(selectedLocation.id);
            actionBarContext.setActionBarContent(`Transfered ${qty} to ${selectedLocation.name}`);
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setNumberInHand(0);
            setManualLoading(false);
        }
    };

    // const verifySetAutoStow = async () => {
    //     const confirmed =
    //         numberInHand < 1
    //             ? true
    //             : await showConfirmModal("You have items in hand, do you want to put them back?", "Put Items Back?", {
    //                 confirm: "Yes",
    //                 cancel: "No",
    //             });
    //     if (!confirmed) return;
    //     setNumberInHand(0);
    //     setAutoStow(!autoStow);
    // };

    const done = async () => {
        if (numberInHand > 0)
            await showConfirmModal(
                "There are items in your hand. Please put them back or intake them first.",
                "Items Still In Hand",
                undefined,
                true
            );
        else {
            var r = await showConfirmModal("Are you sure you want to return to the app start page?", undefined, {
                confirm: "Yes",
                cancel: "No",
            });
            if (r) {
                history.replace("/mobile");
            }
        }
    };

    const addOrPrintBarcode = () => {
        if (!activeItem || !activeItem.purchaseOrderLineItem.product?.id) return;
        let newURLSP = new URLSearchParams([["partId", activeItem.purchaseOrderLineItem.product.id]]);
        if (supplierId) {
            newURLSP.append("supplierId", supplierId);
        }
        history.push(
            `/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${
                activeItem.purchaseOrderLineItem.product.id
            }/addorprintbarcode?${newURLSP.toString()}`
        );
    };

    const editPurchaseInvoiceAsync = async (purchaseInvoiceQueryResult: IPurchaseInvoiceQueryResult, arrivalDate: string) => {
        try {
            setManualLoading(true);
            // run mutation to set the arrival date
            const original: IEditPurchaseInvoiceInput = {
                arrivalDate: purchaseInvoiceQueryResult.purchaseInvoice.arrivalDate,
                id: purchaseInvoiceQueryResult.purchaseInvoice.id,
                invoiceDate: purchaseInvoiceQueryResult.purchaseInvoice.invoiceDate,
                invoiceNumber: purchaseInvoiceQueryResult.purchaseInvoice.invoiceNumber,
                status: purchaseInvoiceQueryResult.purchaseInvoice.status,
                subtotal: purchaseInvoiceQueryResult.purchaseInvoice.subtotal,
                paymentTermId: purchaseInvoiceQueryResult.purchaseInvoice.paymentTermId,
                tax: purchaseInvoiceQueryResult.purchaseInvoice.tax,
                total: purchaseInvoiceQueryResult.purchaseInvoice.total,
                purchaseOrderId: purchaseInvoiceQueryResult.purchaseInvoice.purchaseOrderId,
            };
            const modified: IEditPurchaseInvoiceInput = {
                ...original,
                arrivalDate: arrivalDate,
            };
            const resultData = (
                await runEditPurchaseInvoiceMutation({
                    variables: {
                        original: original,
                        modified: modified,
                        purchaseInvoiceId: params.purchaseInvoiceId,
                        locationId: lastScannedLocationId,
                    },
                })
            ).data;
            purchaseInvoiceQueryResult.purchaseInvoice = resultData.query.purchaseInvoice;
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    //const invoiceComplete = async () => {
    //    if (loading || manualLoading || !data || !staticPurchaseOrderInvoice) return;
    //    try {
    //        setManualLoading(true);
    //        const confirmed = await showConfirmModal("Are you sure you want to complete this invoice?", "Complete Invoice?", { confirm: "Yes", cancel: "No" });
    //        if (!confirmed) return;

    //        const po = data.purchaseOrder;

    //        const invoice: ICompleteInvoicePurchaseInvoice = {
    //            id: staticPurchaseOrderInvoice.id,
    //            invoiceDate: new Date(staticPurchaseOrderInvoice.invoiceDate).toISOString().slice(0, 10),
    //            invoiceNumber: staticPurchaseOrderInvoice.invoiceNumber,
    //            paymentTermId: staticPurchaseOrderInvoice.paymentTermId,
    //            status: staticPurchaseOrderInvoice.status,
    //            subtotal: staticPurchaseOrderInvoice.subtotal,
    //            tax: staticPurchaseOrderInvoice.tax,
    //            total: staticPurchaseOrderInvoice.total,
    //        };

    //        const original: ICompleteInvoicePurchaseOrder = {
    //            billingAddress: po.billingAddress,
    //            blindReceipt: po.blindReceipt,
    //            id: po.id,
    //            shippingAddress: po.shippingAddress,
    //            status: po.status,
    //            subtotal: po.subtotal,
    //            supplierId: po.supplierId,
    //            tax: po.tax,
    //            total: po.total,
    //            invoices: [ {...invoice} ],
    //        };

    //        const modified: ICompleteInvoicePurchaseOrder = {
    //            ...original,
    //            invoices: [{ ...invoice, status: PurchaseInvoiceStatus.Completed }],
    //        };

    //        const res = await runCompleteInvoiceMutation({ variables: { original: original, modified: modified } });
    //        const resData = res.data;
    //        if (!resData) return alert("An error occurred while trying to complete the invoice.");
    //        if (resData.purchaseOrder.edit.invoices.find(invoice => invoice.id === params.purchaseInvoiceId)?.status === PurchaseInvoiceStatus.Completed) {
    //            history.replace(`/mobile/intake/${params.cartId}/${params.intakeType}/${params.purchaseOrderId}/selectinvoice`);
    //        }
    //    }
    //    catch (error: any) {
    //        alert((error as GraphQLError)?.message ?? "Unknown error");
    //    }
    //    finally {
    //        setManualLoading(false);
    //    }
    //};

    const setActivePartId = async (partId: string | undefined) => {
        const confirmed =
            numberInHand < 1
                ? true
                : await showConfirmModal("You have items in hand, do you want to put them back?", "Put Items Back?", {
                      confirm: "Yes",
                      cancel: "No",
                  });
        if (!confirmed) return;
        setProductSelectionType(SelectionType.Selected);
        setNumberInHand(0);
        let newURLSP = new URLSearchParams();
        if (!partId) return;
        newURLSP.append("partId", partId);
        if (supplierId) {
            newURLSP.append("supplierId", supplierId);
        }
        history.replace(`${pathname}?${newURLSP.toString()}`);
    };

    const activeItemGroup = invoiceProductsMap.get(activeItem?.purchaseOrderLineItem?.product?.id ?? "") ?? [];

    if (searchComponentValues.showSearchComponent)
        return (
            <PurchaseInvoiceSearch
                searchComponentProps={searchComponentValues}
                setSearchComponentProps={setSearchComponentValues}
                componentType={PurchaseInvoiceSearchComponentType.Intake}
            />
        );
    else
        return (
            <>
                {loading || manualLoading ? <MobileLoading fullscreen /> : <></>}

                <PrimaryHeader
                    Title={`Invoice ${staticPurchaseInvoice?.invoiceNumber ?? ""}`}
                    IncludeBackButton
                    BackButtonText="< Select Invoice"
                    CustomBackButtonPath={`/mobile/intake/${params.purchaseOrderId}/selectinvoice?${
                        supplierId ? `supplierId=${supplierId}` : ""
                    }`}
                    IncludeHomeButton
                />

                <SecondaryHeader Title="Scan Barcode" NextButtonHandler={next} />

                <div style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                    <input
                        ref={inputRef}
                        onFocus={(e) => e.currentTarget.select()}
                        style={{
                            padding: 10,
                            alignSelf: "stretch",
                            height: 40,
                            borderRadius: 4,
                            border: "1px solid #798394",
                            marginRight: 10,
                            width: "100%",
                        }}
                        placeholder="Search by Part Number..."
                    />
                    <button
                        style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                        onClick={go}
                    >
                        <b>GO</b>
                    </button>
                </div>

                <MobileTable isActiveTable>
                    <thead>
                        <tr>
                            <td>Shipment</td>
                            <td>Hand</td>
                            <td>Bin</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{!activeItem ? "-" : activeItem.expectedQuantity - activeItem.actualQuantity - numberInHand}</td>
                            <td>{!activeItem ? "-" : numberInHand}</td>
                            <td>{!activeItem ? "-" : "-"}</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#CC0A3B" }}>{!activeItem ? "-" : staticPurchaseInvoice?.invoiceNumber ?? ""}</td>
                            <td>{!activeItem ? "-" : activeItem.purchaseOrderLineItem.partNumber ?? "(No part number Provided)"}</td>
                            <td>{!activeItem ? "-" : cartLocationText}</td>
                        </tr>
                    </tbody>
                </MobileTable>

                <div style={{ padding: "10px 10px 0 10px" }}>
                    <ActiveProductImages sku={activeItem?.purchaseOrderLineItem?.product?.sku} />
                </div>

                {/* category */}
                <div style={{ display: "flex", justifyContent: "space-between", fontSize: 12, margin: "5px 10px 0px 10px" }}>
                    <div>
                        Category: <b>{activeItem?.purchaseOrderLineItem.product?.description ?? "(None)"}</b>
                    </div>
                    {activeItemGroup.length > 1 && (
                        <div style={{ color: "red" }}>There are {activeItemGroup.length} line items of this product!</div>
                    )}
                </div>

                <MobileButtonContainer>
                    {/* buttons 1 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            <MobileButton
                                disabled={!activeItem /*|| autoStow*/}
                                onClick={() => stow(productSelectionType)}
                            >{`Stow (${numberInHand}) to Bin ${cartLocationText}`}</MobileButton>
                        </MobileButtonCol>
                        <MobileButtonCol>
                            <MobileButton disabled={!activeItem /*|| autoStow*/} allowLongPress={true} onClick={decrement}>
                                -
                            </MobileButton>
                            <MobileButton disabled={!activeItem /*|| autoStow*/} allowLongPress={true} onClick={increment}>
                                +
                            </MobileButton>
                            <MobileButton onClick={max}>Max</MobileButton>
                        </MobileButtonCol>
                    </MobileButtonRow>

                    {/* buttons 2 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            {/* <MobileButton disabled={!activeItem || locationsOnCart.length > 1} onClick={verifySetAutoStow}> */}
                            <MobileButton disabled>Auto Stow (Off)</MobileButton>
                            <MobileButton disabled>Lost Bin</MobileButton>
                            <MobileButton onClick={async () => await done()}>Done</MobileButton>
                        </MobileButtonCol>
                    </MobileButtonRow>

                    {/* buttons 3 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            <MobileButton disabled={!activeItem} onClick={addOrPrintBarcode}>
                                Add Or Print Barcode
                            </MobileButton>
                        </MobileButtonCol>
                        {/*<MobileButtonCol>*/}
                        {/*    <MobileButton onClick={invoiceComplete}>Invoice Complete</MobileButton>*/}
                        {/*</MobileButtonCol>*/}
                    </MobileButtonRow>
                </MobileButtonContainer>

                {/*Remaining Items On Invoice*/}
                {purchaseInvoiceLineItemsGrouped.size > 0 && (
                    <div>
                        <MobileTableTitle>Remaining Items On Invoice</MobileTableTitle>

                        <MobileTable>
                            <tbody>
                                {Array.from(purchaseInvoiceLineItemsGrouped)
                                    .sort((a, b) =>
                                        (a[1].find((x) => x)?.purchaseOrderLineItem.partNumber ?? "").localeCompare(
                                            b[1].find((x) => x)?.purchaseOrderLineItem.partNumber ?? "",
                                            "en",
                                            { numeric: true }
                                        )
                                    )
                                    .map(([key, values], i) => {
                                        return (
                                            <tr key={i.toString() + "a"} onClick={() => setActivePartId(key)}>
                                                <td>{staticPurchaseInvoice?.invoiceNumber ?? ""}</td>
                                                <td>
                                                    {values.find((x) => x)?.purchaseOrderLineItem.partNumber ?? "(No part number Found)"}
                                                </td>
                                                <td>
                                                    {values.reduce((acc, val) => acc + val.expectedQuantity, 0) -
                                                        values.reduce((acc, val) => acc + val.actualQuantity, 0)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </MobileTable>
                    </div>
                )}

                {/*Items On Cart*/}
                {lastScannedLocations.length > 0 && (
                    <div>
                        <MobileTableTitle>Last Scanned Location</MobileTableTitle>

                        <MobileTable>
                            <tbody>
                                {lastScannedLocations.map((location, i) => {
                                    return location.products.map((product) => {
                                        return (
                                            <tr key={product.product.id + location.id}>
                                                <td>{location.name}</td>
                                                <td>{product.product.sku ?? "(No product SKU found)"}</td>
                                                <td>{product.stockOnHand}</td>
                                            </tr>
                                        );
                                    });
                                })}
                            </tbody>
                        </MobileTable>
                    </div>
                )}

                {/* {createElement(selectModal)} */}

                {createElement(confirmModal)}

                {createElement(multiMatchSelectorModal)}

                {createElement(locationInputModal)}

                {createElement(datePickerModal)}
            </>
        );
};

export default ScanBarcode;
