import { Redirect, useHistory, useLocation } from "react-router-dom";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";

const AddOrPrintBarcodeContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const urlSearchParamsProductId = urlSearchParams.get("productId");
    const urlSearchParamsSupplierId = urlSearchParams.get("supplierId");

    if (!urlSearchParamsProductId || !urlSearchParamsSupplierId) {
        return <Redirect to="/mobile/blindintake/selectsupplier" />;
    }

    const backButtonPath = `/mobile/blindintake/intakeproduct?${urlSearchParams.toString()}`;

    return (
        <>
            <PrimaryHeader
                Title={`Add or Print Barcode`}
                IncludeBackButton
                BackButtonText="< Intake Product"
                CustomBackButtonPath={backButtonPath}
                IncludeHomeButton
            />

            <SecondaryHeader Title="Scan Barcode" NextButtonHandler={() => void 0} />

            <AddOrPrintBarcode productId={urlSearchParamsProductId} onBarcodeSaveAction={() => history.replace(backButtonPath)} />
        </>
    );
};

export default AddOrPrintBarcodeContainer;
