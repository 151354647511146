import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { VButton, VCheck, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IQueryResult {
    paymentTerms: {
        items: Array<IPaymentTerm>;
    };
}

interface IPaymentTerm {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface IPaymentTermModel {
    id: string;
    name: string;
    sortOrder: string;
    active: boolean;
}

interface IEditResult {
    paymentTerm: {
        edit: IPaymentTerm;
    };
}

interface IEditVariables {
    original: IPaymentTerm;
    modified: IPaymentTerm;
}

interface IAddResult {
    paymentTerm: {
        add: IPaymentTerm;
    };
}

interface IAddVariables {
    value: IPaymentTerm;
}

interface IDeleteResult {
    paymentTerm: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IPaymentTerm;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortPaymentTerm(a: IPaymentTerm, b: IPaymentTerm) {
    return a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : a.name > b.name ? 1 : -1;
}

const PaymentTermIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //payment list
    const {
        data,
        error,
        refetch: runRefetchList,
    } = useQuery<IQueryResult, {}>("{ paymentTerms { items { id name sortOrder active } } }", { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(
        "mutation ($original: PaymentTermInput!, $modified: PaymentTermInput!) { paymentTerm { edit (original: $original, modified: $modified) { id name sortOrder active } } }"
    );
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(
        "mutation ($value: PaymentTermInput!) { paymentTerm { add (value: $value) { id name sortOrder active } } }"
    );
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { paymentTerm { delete (id: $id) } }");

    //=== set up local functions ===

    //run when the edit button is pressed
    const onShowEditModal = (value: IPaymentTerm) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: IPaymentTermModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        name: modified.name,
                        sortOrder: parseFloat(modified.sortOrder),
                        active: modified.active,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.paymentTerm.add;
                    if (data) data.paymentTerms.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding PaymentType", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: modal.original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        name: modified.name,
                        sortOrder: parseFloat(modified.sortOrder),
                        active: modified.active,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    if (data && modal.original) {
                        const newValue = ret.data.paymentTerm.edit;
                        const originalId = modal.original.id;
                        const oldIndex = data.paymentTerms.items.findIndex((x) => x.id === originalId);
                        if (oldIndex >= 0) data.paymentTerms.items[oldIndex] = newValue;
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing PaymentType", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this payment term?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                if (data) {
                    //delete the entry from the local list
                    const oldIndex = data.paymentTerms.items.findIndex((x) => x.id === id);
                    if (oldIndex >= 0) {
                        data.paymentTerms.items.splice(oldIndex, 1);
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                }
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting PaymentType", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IPaymentTermModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              sortOrder: modal.original.sortOrder + "",
              active: modal.original.active,
          }
        : {
              id: "0",
              name: "",
              sortOrder: "",
              active: true,
          };

    //this code displays a card and is nearly the same for active and inactive
    const card = (active: boolean) => {
        // search data where active or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        const paymentTerms = data!.paymentTerms.items.filter((paymentTerm) => paymentTerm.active === active).sort(sortPaymentTerm);

        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Payment Terms</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Payment Term</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentTerms.map((paymentTerm) => (
                                <tr key={paymentTerm.id}>
                                    <td>{paymentTerm.name}</td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditModal(paymentTerm)}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //=== display page ===

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={runRefetchList}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //render
    return (
        <>
            <PageHeader>Payment Terms</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new payment term
                </Button>
            </p>

            {/* Active Payment Terms */}
            {card(true)}

            {/* Inactive Payment Terms */}
            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Payment Term</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="sortOrder">Sort Order</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" valueName="sortOrder" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default PaymentTermIndex;
