import * as React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IPickZoneQueryResult {
    warehouse: {
        pickZones: Array<IPickZone>;
    };
}

interface IStationSetsQueryResult {
    stationSets: {
        items: Array<IStationSet>;
    };
}

interface IStationSet {
    id: string;
    name: string;
    stations: Array<IStation>;
}

interface IStation {
    id: string;
    type: string;
    targetCarts: number;
    maxCarts: number;
}

interface IPickZone {
    id: string;
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: boolean;
    priority: number;
    isCart: boolean;
    stationId: string | null;
}
interface IPickZoneModel {
    id: string;
    name: string;
    warehouseId: string;
    active: boolean;
    pickable: boolean;
    priority: string;
    isCart: boolean;
    stationId: string | null;
}

interface IAddResult {
    pickZone: {
        add: IPickZone;
    };
}

interface IAddVariables {
    value: IPickZone;
}

//define modal state & default state
interface IModal {
    show: boolean;
}

const hiddenModal: IModal = {
    show: false,
};

const PickZoneQuery = `
query ($warehouseId: ID!) {
  warehouse(id: $warehouseId) {
    pickZones {
      id
      warehouseId
      name
      active
      pickable
      priority
      isCart
      stationId
    }
  }
}
`;

const StationSetsQuery = `
query ($warehouseId: ID) {
  stationSets (warehouseId: $warehouseId) {
    items {
      id
      name
      stations {
        id
        type
        targetCarts
        maxCarts
      }
    }
  }
}
`;

const runAddMutation = `
mutation ($value: PickZoneInput!) {
    pickZone {
        add(value: $value) {
            id
            warehouseId
            name
            active
            pickable
            priority
            isCart
            stationId
        }
    }
}

`;

const WarehousePickZones = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id: warehouseId } = useParams<{ id: string }>();

    const { data, error, refetch } = useQuery<IPickZoneQueryResult, { warehouseId: string }>(PickZoneQuery, {
        variables: { warehouseId: warehouseId },
        fetchPolicy: "no-cache",
    });
    const {
        data: stationSetData,
        error: stationSetError,
        refetch: stationSetRefetch,
    } = useQuery<IStationSetsQueryResult, { warehouseId: string }>(StationSetsQuery, {
        variables: { warehouseId: warehouseId },
        fetchPolicy: "no-cache",
    });
    const [runAdd] = useMutation<IAddResult, IAddVariables>(runAddMutation);
    const history = useHistory();

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (stationSetError) return <ErrorDisplay onClick={stationSetRefetch}>{stationSetError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data || !stationSetData) return <Loading />;

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const originalModel: IPickZoneModel = {
        id: "0",
        name: "",
        active: false,
        isCart: false,
        pickable: false,
        priority: "0",
        warehouseId: warehouseId,
        stationId: null,
    };

    const onSaveChanges = (modified: IPickZoneModel) => {
        //=== ADD ===
        //disable form controls
        setSaving(true);
        //start add operation via graphql mutation
        runAdd({
            variables: {
                value: {
                    id: "0",
                    name: modified.name,
                    active: modified.active,
                    isCart: modified.isCart,
                    pickable: modified.pickable,
                    priority: parseFloat(modified.priority),
                    warehouseId: warehouseId,
                    stationId: modified.stationId || null,
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                if (data && ret.data) {
                    var newValue = ret.data.pickZone.add;
                    data.warehouse.pickZones.push(newValue);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding Pick Zone", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const navTo = (e: React.MouseEvent, pickZoneId: string) => {
        if (e.currentTarget === e.target)
            history.push("/warehouses/" + encodeURIComponent(warehouseId) + "/pickzones/" + encodeURIComponent(pickZoneId));
    };

    const card = (active: boolean) => {
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Pick Zones</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Pick Zone</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.warehouse.pickZones
                                ?.filter((x) => x.active === active && x.warehouseId === warehouseId)
                                .map((pickZone) => (
                                    <tr key={pickZone.id}>
                                        <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, pickZone.id)}>
                                            <Link to={`/warehouses/${warehouseId}/pickzones/${pickZone.id}`}>{pickZone.name}</Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };
    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add New Pick Zone
                </Button>
            </p>

            {card(true)}

            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Pick Zone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="priority">Priority</VLabel>
                                    <VControl type="text" required valueName="priority" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="">
                                    <VLabel valueName="stationId">Station</VLabel>
                                    <VSelect valueName="stationId">
                                        <option value=""></option>
                                        {stationSetData.stationSets.items.map((stationSet) => {
                                            return stationSet.stations.map((station) => (
                                                <option value={station.id}>{stationSet.name + "-" + station.type}</option>
                                            ));
                                        })}
                                    </VSelect>
                                </Form.Group>
                                <div className="d-flex">
                                    <Form.Group className="mb-3 me-4">
                                        <VCheck label="Active" valueName="active" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 me-4">
                                        <VCheck label="Cart" valueName="isCart" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <VCheck label="Pickable" valueName="pickable" />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default WarehousePickZones;
