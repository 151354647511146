import { AuthContext } from "@zboxglobal/zboxauth";
import { useContext } from "react";
import { ApiBaseUrl, GraphQlBaseUrl } from "../globalVariables";

export enum ExportType {
    Excel = "Excel",
    CSV = "CSV",
}

export function useSpreadSheetQuery<T>(query: string) {
    const auth = useContext(AuthContext);
    const isExcel = true;
    return (variables: T, fileName?: string, exportType?: ExportType) => {
        return auth.client.UpdateToken().then(() => {
            const token = auth.client.GetAccessToken();
            const accept =
                ExportType.Excel === exportType || !exportType
                    ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    : "text/csv";
            const url = ApiBaseUrl + GraphQlBaseUrl;
            const payload = {
                query: query,
                variables: variables,
            };
            fetch(url, {
                method: "POST",
                headers: {
                    // eslint-disable-next-line
                    Authorization: "Bearer " + token,
                    // eslint-disable-next-line
                    Accept: accept,
                    // eslint-disable-next-line
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }).then(
                (ret) => {
                    if (ret.status === 200) {
                        return ret.blob().then(
                            (file) => {
                                if (file.type !== accept) {
                                    alert("Unexpected file type: " + file.type);
                                } else {
                                    var fileURL = URL.createObjectURL(file);
                                    var fileLink = document.createElement(`a`);
                                    fileLink.href = fileURL;
                                    fileLink.download = (fileName ? fileName : "Download") + "." + (isExcel ? "xlsx" : "csv");
                                    fileLink.click();
                                    fileLink.remove();
                                    window.URL.revokeObjectURL(fileURL);
                                }
                            },
                            (err) => {
                                console.log("Error downloading file", err);
                                alert("Error downloading file");
                            }
                        );
                    } else {
                        return ret.json().then(
                            (json) => {
                                if (json.errors && json.errors[0] && json.errors[0].message) {
                                    alert(json.errors[0].message);
                                } else {
                                    alert("Unknown error downloading file");
                                    console.log("Error downloading file", json);
                                }
                            },
                            (err) => {
                                alert("Unknown error downloading file");
                                console.log("Error downloading file", err);
                            }
                        );
                    }
                },
                (err) => {
                    alert("Unknown error downloading file");
                    console.log("Error downloading file", err);
                }
            );
        });
    };
}
