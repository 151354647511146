import Loading from "../../../../components/loading/Loading";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";

const authorizationUrlQuery = "{ integrations { ebay { authorizationUrl } } }";

interface IAuthorizationQuery {
    integrations: {
        ebay: {
            authorizationUrl: string;
        };
    };
}

const EbayIndex = () => {
    const { data, error, refetch } = useQuery<IAuthorizationQuery, {}>(authorizationUrlQuery, { fetchPolicy: "no-cache" });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!data) return <Loading />;

    return (
        <>
            <h5>Connect to Ebay</h5>
            <a target="_blank" rel="noopener noreferrer" href={data.integrations.ebay.authorizationUrl}>
                Connect to Ebay
            </a>
        </>
    );
};

export default EbayIndex;
