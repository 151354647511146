enum SelectionType {
    /** Indicates that the product or location was indirectly selected, requiring no input */
    None = "NONE",
    /** Indicates that the user scanned a barcode to find the product or location */
    Scanned = "SCANNED",
    /** Indicates that the user used a search box to find the product or location */
    Searched = "SEARCHED",
    /** Indicates that the user selected the product or location off of a list */
    Selected = "SELECTED",
}

export default SelectionType;
