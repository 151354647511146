interface IProps {
    /** The amount of space (in "px") between each child component */
    gap?: number;
    padding?: number;
    children: React.ReactNode;
}

const MobileButtonContainer = ({ children, gap, padding }: IProps) => {
    return <div style={{ display: "flex", flexDirection: "column", gap: gap ?? 10, padding: padding ?? 10 }}>{children}</div>;
};

export default MobileButtonContainer;
