export const imageQuery = `
query ($sku: String!) {
  part(sku: $sku) {
    primaryMedia {
        fullscreen: photoUri
        original: thumbnailUri(size: 500)
        thumbnail: thumbnailUri(size: 100)
    }
  }
}
`;

export interface IImageQueryResult {
    part: {
        primaryMedia: {
            fullscreen: string;
            original: string;
            thumbnail: string;
        } | null;
    };
}

export interface IImageQueryVariables {
    sku: string;
}
