import { useCallback, useContext, useEffect, useState } from "react";
import { GraphQLContext, GraphQLError } from "@shane32/graphql";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import SelectionType from "../../../enums/SelectionType";
import StrictOmit from "../../../types/StrictOmit";
import MobileLoading from "../components/MobileLoading";

interface IStockTakeMutationVariables {
    locationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

interface IStockTakeMutationResponse<TResponse> {
    inventory: {
        stockTake: string;
    };
    query: TResponse;
}

interface IPropsBase extends IStockTakeMutationVariables {
    productSku: string;
    locationName: string;
    quantityInBin: number;
}

interface IProps extends StrictOmit<IPropsBase, "quantity"> {}

type ResolveType<T> = (value: T | undefined | PromiseLike<T | undefined>) => void;

/**
 * A hook used to upload attachments to many different types of entities in Hive.
 * @type \<T\> - The type of the data returned by the `postStockTakeQuery` argument.
 * @param props The productId, locationId, and SKU amount to which the stock take will be applied.
 * @param postStockTakeQuery The query to run after the stock amount has been changed. All necessary data must be set in the query before hand (i.e. the query must be ready to run as is.).
 * @returns
 */
const useLostSkuModal = () => {
    const graphQLContext = useContext(GraphQLContext);
    const resolveRef = useRef<ResolveType<any>>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState(false);
    const [isStockTakeLoading, setIsStockTakeLoading] = useState(false);
    const [showLostSkuModalProps, setShowLostSkuModalProps] = useState<{ props: IProps; postStockTakeQuery: string }>();

    useEffect(() => {
        if (inputRef?.current && open) inputRef.current.focus();
    }, [open]);

    const showLostSkuModal = useCallback(<T,>(props: IProps, postStockTakeQuery: string) => {
        setShowLostSkuModalProps({ props: props, postStockTakeQuery: postStockTakeQuery });
        return new Promise<T | undefined>((resolve) => {
            resolveRef.current = resolve;
            setOpen(true);
        });
    }, []);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const stockTake = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setIsStockTakeLoading(true);
            if (!showLostSkuModalProps) throw new Error("Lost SKU modal props not set.");
            if (!resolveRef.current) throw new Error("Resolve function not set.");
            if (!inputRef.current) throw new Error("Input ref not set.");
            if (!inputRef.current.value) throw new Error("Input value not set.");
            if (!showLostSkuModalProps.props.productSku) throw new Error("Product SKU not set.");
            if (showLostSkuModalProps.props.quantityInBin < 1)
                throw new Error("Unable to change product quantity in bin if stock in bin is already '0'.");
            const quantity = inputRef.current.value.trim();
            if (isNaN(+quantity)) throw new Error("Quantity must be a number.");
            var response = await graphQLContext.client.ExecuteQueryRaw<IStockTakeMutationResponse<any>, IStockTakeMutationVariables>({
                query: `
                mutation ($locationId: ID!, $productId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!) {
                    inventory {
                        stockTake(locationId: $locationId, productId: $productId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
                    }
                    query {
                        ${showLostSkuModalProps.postStockTakeQuery}
                    }
                }`,
                variables: {
                    locationId: showLostSkuModalProps.props.locationId,
                    productId: showLostSkuModalProps.props.productId,
                    quantity: +quantity,
                    locationSelectionType: showLostSkuModalProps.props.locationSelectionType,
                    productSelectionType: showLostSkuModalProps.props.productSelectionType,
                },
            }).result;
            resolveRef.current?.(response.data?.query);
            setOpen(false);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "An unknown error occurred.");
        } finally {
            setIsStockTakeLoading(false);
        }
    };

    const onKeyDownInputTypeNumberHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const acceptedKeys = ["Backspace", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Delete"];
        if (isNaN(+e.key) && acceptedKeys.indexOf(e.key) < 0) {
            e.preventDefault();
        }
    };

    const lostSkuModal = (
        <Modal show={open} animation={false} style={{ zIndex: 1000000 }} onBackdropClick={() => setOpen(false)}>
            <form onSubmit={(e) => stockTake(e)} style={{ position: "relative" }}>
                {!isStockTakeLoading ? (
                    <></>
                ) : (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                            backgroundColor: "rgba(0,0,0,0.1)",
                        }}
                    >
                        <MobileLoading />
                    </div>
                )}
                <Modal.Header closeButton>
                    <span style={{ fontWeight: "bold", fontSize: 18 }}>Lost SKU</span>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <div style={{ color: "red" }}>
                        How many of SKU '{showLostSkuModalProps?.props?.productSku}' are{" "}
                        <i>
                            <b>actually</b>
                        </i>{" "}
                        in '{showLostSkuModalProps?.props?.locationName}'?
                    </div>
                    <input
                        onKeyDown={(e) => onKeyDownInputTypeNumberHandler(e)}
                        ref={inputRef}
                        type="number"
                        min={0}
                        defaultValue={showLostSkuModalProps?.props?.quantityInBin}
                        style={{
                            width: "100%",
                            height: 40,
                            fontSize: 18,
                            textAlign: "center",
                            borderRadius: 5,
                            border: "1px solid #ced4da",
                        }}
                    />
                </Modal.Body>
                <Modal.Footer className="flex-nowrap">
                    <button disabled={isStockTakeLoading} type="submit" className="btn btn-primary" style={{ width: "50%" }}>
                        <b>Confirm</b>
                    </button>
                    <button disabled={isStockTakeLoading} className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                        <b>Cancel</b>
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );

    return {
        isStockTakeLoading,
        lostSkuModal,
        showLostSkuModal,
    } as const;
};

export default useLostSkuModal;
