import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import PageHeader from "../../../components/pageheader/PageHeader";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@shane32/graphql";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import PackageType from "../../../enums/PackageType";
import StringHelper from "../../../helpers/StringHelper";

interface IQueryResult {
    packages: {
        items: Array<IPackage>;
    };
}

interface IDeleteResult {
    package: {
        delete: string;
    };
}

interface IAddPackageResult {
    package: {
        add: IPackage;
    };
}

export interface IPackage {
    id: string;
    barcode: string | null;
    length: number;
    width: number;
    height: number;
    weight: number;
    type: string;
    active: boolean;
}

const InnerQuery = `
    id
    barcode
    length
    width
    height
    weight
    type
    active
`;
const PackageQuery = `
    query {
        packages {
            items {
                ${InnerQuery}
            }
        }
    }
`;

const addPackageMutation = `
    mutation($value: PackageInput!){
        package {
            add(value:$value) {
                ${InnerQuery}
            }
        }
    }
`;

// const addFileMutation = `
// mutation($file: String!){
//     packaging {
//         addfile(file: $file)
//     }
// }`;

export interface IPackageModel {
    id: string;
    barcode: string | null;
    length: string;
    width: string;
    height: string;
    weight: string;
    type: string;
    active: boolean;
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IPackage;
}

const hiddenModal: IModal = {
    show: false,
};

function sortPackage(a: IPackage, b: IPackage) {
    if (a.length * a.width * a.height > b.length * b.width * b.height) return 1;
    else if (a.length * a.width * a.height < b.length * b.width * b.height) return -1;
    else {
        if (a.type > b.type) return 1;
        else if (a.type < b.type) return -1;
        else {
            if (a.id > b.id) return 1;
            else return -1;
        }
    }
}

const PackageIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    //const [fileModal, showFileModal] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState(false);
    //const [packagingFile, setPackagingFile] = React.useState<Blob | null>(null);

    const { loading, error, data, refetch } = useQuery<IQueryResult, {}>(PackageQuery);

    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { package { delete (id: $id) } }");
    const [runAddPackage] = useMutation<IAddPackageResult, { value: IPackage }>(addPackageMutation);
    //const [runAddFile] = useMutation<IAddPackagingResult, { file: string }>(addFileMutation);

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    // const onShowFileModal = () => {
    //     showFileModal(true);
    // };

    // const onHideFileModal = () => {
    //     setPackagingFile(null);
    //     showFileModal(false);
    // };

    // const convertBase64 = async (file: Blob) => {
    //     return new Promise<string>((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);
    //         fileReader.onload = () => {
    //             if (fileReader.result) {
    //                 //remove the metadata at the beginning
    //                 let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
    //                 if (encoded.length % 4 > 0) {
    //                     encoded += "=".repeat(4 - (encoded.length % 4));
    //                 }
    //                 resolve(encoded);
    //             }
    //         };
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    const onSaveChanges = (newPackage: IPackageModel) => {
        //Validate the values are valid
        var allDims = new Array<string>(newPackage.length, newPackage.width, newPackage.height, newPackage.weight);
        var correct = true;
        allDims.forEach((dim) => {
            if (parseInt(dim) && parseInt(dim) < 0) {
                alert("Dimensions must all be positive numbers.");
                correct = false;
                return;
            }
        });
        if (correct) {
            //Send the new package to HiveServer
            setSaving(true);
            var packageObj: IPackage = {
                id: newPackage.id,
                barcode: newPackage.barcode,
                length: parseFloat(newPackage.length),
                width: parseFloat(newPackage.width),
                height: parseFloat(newPackage.height),
                weight: parseFloat(newPackage.weight),
                type: newPackage.type,
                active: newPackage.active,
            };
            runAddPackage({ variables: { value: packageObj } }).then(
                () => {
                    if (data) {
                        //Add the entry to the local list
                        data.packages.items = data.packages.items.concat(packageObj);
                        setSaving(false);
                        setModal(hiddenModal);
                        refetch();
                    }
                },
                (err) => {
                    setSaving(false);
                    console.error("Error adding the package", err);
                    alert(err.message);
                }
            );
        }
    };

    // const onSaveFileChanges = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     //Send the new package to HiveServer
    //     setSaving(true);
    //     convertBase64(packagingFile!).then((ret) => {
    //         runAddFile({ variables: { file: ret } }).then(
    //             () => {
    //                 if (data) {
    //                     setSaving(false);
    //                     showFileModal(false);
    //                 }
    //             },
    //             (err) => {
    //                 setSaving(false);
    //                 console.error("Error adding packages", err);
    //                 alert(err.message);
    //             }
    //         );
    //     });
    // };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Package?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                if (data) {
                    //delete the entry from the local list
                    const newList = data.packages.items.filter((x) => x.id !== id);
                    data.packages.items = newList;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                }
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Package", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const history = useHistory();
    const navTo = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        if (e.currentTarget === e.target) history.push("/setup/packages/" + encodeURIComponent(id));
    };

    // const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     e.preventDefault();
    //     if (e.currentTarget.files && e.currentTarget.files[0]) {
    //         setPackagingFile(e.currentTarget.files[0]);
    //     }
    // };

    //this code displays a card and is nearly the same for active and inactive
    const card = (active: boolean) => {
        const packagesForCard = data!.packages.items.filter((x) => x.active === active).sort(sortPackage);
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"}</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Package</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packagesForCard.map((x) => (
                                <tr key={x.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, x.id)}>
                                        <Link to={`/setup/packages/${x.id}`}>
                                            {x.length}x{x.width}x{x.height} - {x.type}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (loading || !data) return <Loading />;

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: IPackageModel = modal.original
        ? {
              id: modal.original.id,
              barcode: modal.original.barcode,
              length: modal.original.length.toString(),
              width: modal.original.width.toString(),
              height: modal.original.height.toString(),
              weight: modal.original.weight.toString(),
              type: modal.original.type,
              active: modal.original.active,
          }
        : {
              id: "0",
              barcode: "",
              length: "",
              width: "",
              height: "",
              weight: "",
              type: "",
              active: true,
          };

    return (
        <>
            <PageHeader>Packages</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal} style={{ marginRight: 10 }}>
                    Add new package
                </Button>
                {/* <Button variant="white" onClick={onShowFileModal}>
                    Bulk add new package
                </Button> */}
            </p>
            {/* set up cards for active and inactive */}
            {card(true)}
            {card(false)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Package</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicBarcode">
                                    <VLabel valueName="barcode">Barcode</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" valueName="barcode" />
                                </Form.Group>
                                <Form.Group as={Row} className="mb-4">
                                    <VLabel title="Dims (in.)" valueName="length,width,height" column sm={3} />
                                    <Col sm={9}>
                                        <Row>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <div className="ms-2" style={{ marginRight: 5 }}>
                                                    L
                                                </div>
                                                <VControl valueName="length" />
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <div className="ms-2" style={{ marginRight: 5 }}>
                                                    W
                                                </div>
                                                <VControl valueName="width" />
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-center">
                                                <div className="ms-2" style={{ marginRight: 5 }}>
                                                    H
                                                </div>
                                                <VControl valueName="height" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Row>
                                        <VLabel title="Weight (lb.)" valueName="weight" column sm={3} />
                                        <Col sm={9}>
                                            <VControl type="text" valueName="weight" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <VLabel valueName="type" title="Package Type" />
                                    <VSelect valueName="type">
                                        <option value="">Select Package Type</option>
                                        {Object.values(PackageType)
                                            .filter((x) => x.toUpperCase() !== "PREPACKAGED")
                                            .map((x, index) => (
                                                <option value={x.toUpperCase()} key={index}>
                                                    {StringHelper.SnakeCaseToTitleCase(x)}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
            {/* <Modal show={fileModal} onHide={onHideFileModal}>
                <Form onSubmit={onSaveFileChanges}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk add packaging</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group>
                                    <Form.Label>Bulk Sku File</Form.Label>
                                    <Form.Control type="file" accept=".xls, .xlsx" required onChange={onChangeImportFile} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes *
                        <Button type="submit" variant="primary" disabled={saving || packagingFile === null}>
                            Save
                        </Button>
                        <Button type="button" variant="white" onClick={onHideFileModal} className="me-auto">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </>
    );
};

export default PackageIndex;
