import { GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { GraphQLContext } from "@shane32/graphql";

interface IProduct {
    id: string;
    description: string | null;
    sku: string;
    supplierSku: string;
    type: string;
    partNumber: [
        {
            partNumber: string;
            sortOrder: number;
        }
    ];
}

interface IProducts {
    products: {
        totalCount: number;
        items: Array<IProduct>;
    };
}

//Create the query to get all the products by supplier
const ProductsBySupplierQuery = `
query ($after: String, $supplierId: ID, $sortOrder: ProductsSortOrder!) {
    products(first: 100, after: $after, supplierId: $supplierId, sortOrder: $sortOrder) {
        totalCount
        items {
            id
            description
            sku
            supplierSku
            type
            partNumbers {
                partNumber
                sortOrder
            }
        }
    }
}
`;

interface IProductsVariables {
    after: string | null;
    supplierId: string;
    sortOrder: string | null;
}

const SupplierProducts = () => {
    //GraphQL handle
    const graphQLContext = React.useContext(GraphQLContext);
    const { id } = useParams<{ id: string }>();
    //called when AG Grid renders
    const onGridReady = (params: GridReadyEvent) => {
        //used by AG Grid as a server handle to lazy load data
        let dataSource: IDatasource = {
            //called whenever AG Grid needs more data
            getRows: (params: IGetRowsParams) => {
                //construct the sortOrder string so it matches an enum on the server
                let sortOrder = null;
                if (params.sortModel.length > 0) {
                    if (params.sortModel[0].sort === "asc") {
                        sortOrder = "_ASCENDING";
                    } else if (params.sortModel[0].sort === "desc") {
                        sortOrder = "_DESCENDING";
                    }

                    switch (params.sortModel[0].colId) {
                        case "description":
                            sortOrder = "DESCRIPTION" + sortOrder;
                            break;
                        case "sku":
                            sortOrder = "SKU" + sortOrder;
                            break;
                        case "supplierSku":
                            sortOrder = "SUPPLIER_SKU" + sortOrder;
                            break;
                        case "type":
                            sortOrder = "TYPE" + sortOrder;
                            break;
                    }
                }
                //default
                else {
                    sortOrder = "ID_ASCENDING";
                }
                //ask for 100 rows after this index
                let after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                //query the server for data
                const ret = graphQLContext.client.ExecuteQueryRaw<IProducts, IProductsVariables>({
                    query: ProductsBySupplierQuery,
                    variables: {
                        after: after,
                        supplierId: id,
                        sortOrder: sortOrder,
                    },
                });

                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors) {
                            console.log("Error fetching data: 1", result.errors);
                            return;
                        }
                        //feed data into AG Grid
                        params.successCallback(result.data!.products.items, result.data!.products.totalCount);
                    },
                    //failure
                    (result) => {
                        params.failCallback();
                        console.log("Error fetching data: 2", result);
                    }
                );
            },
        };
        //bind the server handle ('dataSource') to AG Grid
        params.api.setDatasource(dataSource);
    };
    //AG Grid column structure
    const columns = [
        {
            field: "description",
            headerName: "Description",
            flex: 1.5,
            cellRenderer: "loadingRenderer",
        },
        {
            field: "sku",
            headerName: "SKU",
            minWidth: 160,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data) {
                    const link = `/products/${params.data.id}`;
                    if (params.data.sku && params.data.sku !== "") {
                        return <Link to={link}>{params.data.sku || ""}</Link>;
                    } else {
                        return <Link to={link}>{params.data.productId}</Link>;
                    }
                } else return <></>;
            },
        },
        {
            field: "supplierSku",
            headerName: "Supplier Sku",
        },
        {
            field: "type",
            minWidth: 165,
        },
    ];

    return (
        <>
            <h2>Products</h2>
            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={10}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                ></AgGridReact>
            </div>
        </>
    );
};

export default SupplierProducts;
