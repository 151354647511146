import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { VButton, VCheck, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IHiveLink {
    id: string;
    name: string;
    guid: string;
    active: boolean;
}

interface IHiveLinksQueryResult {
    hiveLinks: {
        items: IHiveLink[];
    };
}

interface IHiveLinkModel {
    id: string;
    name: string;
    active: boolean;
}

interface IEditResult {
    hiveLink: {
        edit: IHiveLink;
    };
}

interface IEditVariables {
    original: IHiveLinkModel;
    modified: IHiveLinkModel;
}

interface IAddResult {
    hiveLink: {
        add: IHiveLink;
    };
}

interface IAddVariables {
    value: IHiveLinkModel;
}

interface IDeleteResult {
    hiveLink: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IHiveLink;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortHiveLinks(a: IHiveLink, b: IHiveLink) {
    return a.name.localeCompare(b.name);
}

const HiveLinksQuery = `
{
  hiveLinks {
    items {
      id
      name
      guid
      active
    }
  }
}
`;

const AddMutation = `
mutation ($value: HiveLinkInput!) {
  hiveLink {
    add(value: $value) {
      id
      name
      guid
      active
    }
  }
}
`;

const EditMutation = `
mutation ($original: HiveLinkInput!, $modified: HiveLinkInput!) {
  hiveLink {
    edit(original: $original, modified: $modified) {
      id
      name
      guid
      active
    }
  }
}
`;

const DeleteMutation = `
mutation ($id: ID!) {
  hiveLink {
    delete(id: $id)
  }
}
`;

const HiveLinksIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);

    //=== set up queries and mutations ===
    //queries
    const { data, error, refetch } = useQuery<IHiveLinksQueryResult>(HiveLinksQuery, { fetchPolicy: "no-cache" });

    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data) return <Loading />;

    const onShowEditModal = (value: IHiveLink) => {
        setModal({
            show: true,
            original: value,
        });
    };
    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({
            show: true,
        });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };
    const originalModel: IHiveLinkModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              active: modal.original.active,
          }
        : {
              id: "",
              name: "",
              active: true,
          };

    const onSaveChanges = (modified: IHiveLinkModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: { ...modified, id: "0" },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.hiveLink.add;
                    data.hiveLinks.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding HiveLink", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: {
                        id: modal.original.id,
                        name: modal.original.name,
                        active: modal.original.active,
                    },
                    //pass in modified data
                    modified: modified,
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.hiveLink.edit;
                    const oldIndex = data.hiveLinks.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.hiveLinks.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing HiveLink", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this HiveLink?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.hiveLinks.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.hiveLinks.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting HiveLink", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const hiveLinks = data.hiveLinks.items.sort(sortHiveLinks);
    const card = (active: boolean) => {
        return (
            <Card className="border-primary">
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} HiveLinks</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Guid</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {hiveLinks
                                .filter((x) => x.active === active)
                                .map((hiveLink) => {
                                    return (
                                        <tr key={hiveLink.id}>
                                            <td>{hiveLink.name}</td>
                                            <td>{hiveLink.guid}</td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    variant="white"
                                                    className="ms-4"
                                                    style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                    onClick={() => onShowEditModal(hiveLink)}
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <PageHeader>HiveLinks</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new HiveLink
                </Button>
            </p>
            {card(true)}
            {card(false)}
            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} HiveLink</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formDesc">
                                    <VLabel valueName="name" title="Name" />
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formHorizontalCheck">
                                    <VCheck valueName="active" label="Active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className={modal.original ? undefined : "me-auto"}>
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default HiveLinksIndex;
