import { GraphQLContext } from "@shane32/graphql";
import { ColDef, GridApi, ICellRendererParams, IDatasource } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { VForm } from "@shane32/vform";
import PageHeader from "../../../components/pageheader/PageHeader";
import LogLevel from "../../../enums/LogLevel";
import FormatDate from "../../../helpers/FormatDate";

interface ILogQueryResult {
    logs: {
        totalCount: number;
        items: Array<ILog>;
    };
}

interface ILogQueryVariables {
    first: number;
    after?: string | null;
    logLevels?: string[] | null;
    active?: boolean | null;
    eventSources?: string[] | null;
    eventIds?: string[] | null;
}

interface ILog {
    id: string;
    logLevel: LogLevel;
    createdAt: string;
    message: string;
    detailsJson: string;
    user: IUser;
    active: boolean;
    eventSource: string | null;
    eventId: string | null;
}

interface IUser {
    id: string;
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
}

interface IModal {
    show: boolean;
    original?: ILog;
}

const hiddenModal: IModal = {
    show: false,
};

const LogQuery = `
query($first: Int!, $after: String, $logLevels: [LogLevel!], $active: Boolean, $eventSources: [String!], $eventIds: [ID!]) {
    logs(first: $first, after: $after, logLevels: $logLevels, active: $active, eventSources: $eventSources, eventIds: $eventIds) {
      totalCount
      items {
        id
        logLevel
        createdAt
        message
        detailsJson
        user {
          id
          firstName
          lastName
          emailAddress
        }
        active
        eventSource
        eventId
      }
    }
  }
`;

const ClearActiveMutation = `
mutation ($ids: [ID!]!) {
  log {
    setActive (ids: $ids, value: false)
  }
}
`;

interface ILogLevelFilter {
    name: string;
    enabled: boolean;
}

const LogIndex = () => {
    const [gridApi, setGridApi] = useState<GridApi<ILog> | null>(null);
    const [viewModal, setViewModal] = React.useState<IModal>(hiddenModal);
    const onShowModal = (value: ILog) => {
        setViewModal({
            show: true,
            original: value,
        });
    };
    const onCloseViewModal = () => setViewModal(hiddenModal);
    const graphQLContext = React.useContext(GraphQLContext);
    const [active, setActive] = useState(false);
    const [specificEvent, setSpecificEvent] = useState<{ eventSource: string; eventId: string | null } | null>(null);
    const [logLevelFilters, setLogLevelFilters] = useState<ILogLevelFilter[]>(
        Object.values<string>(LogLevel).map((x) => {
            return {
                enabled: false,
                name: x,
            };
        })
    );

    useEffect(() => {
        if (gridApi) {
            gridApi.showLoadingOverlay();
            const datasource: IDatasource = {
                getRows(params) {
                    //ask for 100 rows after this index
                    const after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                    let variables: ILogQueryVariables = {
                        after: after,
                        first: 100,
                        active: active || null,
                    };
                    if (logLevelFilters.some((x) => x.enabled === true)) {
                        variables.logLevels = logLevelFilters.filter((x) => x.enabled === true).map((x) => x.name);
                    }
                    if (specificEvent) {
                        variables.logLevels = null;
                        variables.eventSources = [specificEvent.eventSource];
                        if (specificEvent.eventId) variables.eventIds = [specificEvent.eventId];
                    }
                    graphQLContext.client
                        .ExecuteQueryRaw<ILogQueryResult, ILogQueryVariables>({
                            query: LogQuery,
                            variables: variables,
                        })
                        .result.then(
                            (response) => {
                                gridApi.hideOverlay();
                                if ((response.errors ?? []).length > 1) {
                                    console.error("Error fetching data: 1", response.errors);
                                    return;
                                }
                                params.successCallback(response.data?.logs?.items ?? [], response.data?.logs?.totalCount ?? 0);
                            },
                            (error) => {
                                gridApi.hideOverlay();
                                params.failCallback();
                                console.error("Error fetching data: 2", error);
                            }
                        );
                },
            };
            gridApi.setDatasource(datasource);
        }
    }, [gridApi, graphQLContext.client, logLevelFilters, active, specificEvent]);

    const clearActive = async (ids: string[]) => {
        const queryRet = await graphQLContext.client.ExecuteQueryRaw({
            query: ClearActiveMutation,
            variables: {
                ids: ids,
            },
        }).result;
        if (queryRet.errors) {
            console.error("Error clearing active flag", ids, queryRet);
            alert("Could not clear active flag");
            return;
        }
        gridApi?.purgeInfiniteCache();
    };

    //AG Grid column structure
    const columns: ColDef<ILog, any>[] = [
        {
            field: "createdAt",
            maxWidth: 195,
            filter: true,
            sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                return <>{!params.data?.createdAt ? null : FormatDate.ConvertDateTimeTo12HrLocalDateTime(params.data.createdAt)}</>;
            },
        },
        {
            field: "user",
            maxWidth: 185,
            filter: true,
            sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                if (params.data?.user?.firstName && params.data?.user?.lastName) {
                    return <span>{params.data.user.firstName + " " + params.data.user.lastName}</span>;
                } else {
                    return <span>N/A</span>;
                }
            },
        },
        {
            field: "message",
            flex: 1,
            filter: true,
            sortable: true,
            resizable: true,
        },
        {
            field: "logLevel",
            maxWidth: 120,
            filter: true,
            sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                if (!params.data) return null;
                const onClickAlert = async () => {
                    if (params.data && window.confirm("Clear this error?")) {
                        clearActive([params.data.id]);
                    }
                };
                const alert = params.data.active ? (
                    <>
                        <i className="bi bi-exclamation-triangle-fill" onClick={onClickAlert} style={{ cursor: "pointer" }}></i>&nbsp;
                    </>
                ) : null;
                return (
                    <>
                        {alert}
                        {params.data.logLevel}
                    </>
                );
            },
        },
        {
            field: "detailsJson",
            headerName: "Details",
            maxWidth: 120,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                return (
                    <Button
                        size="sm"
                        variant="white"
                        className="mt-2"
                        style={{ float: "left" }}
                        onClick={() => {
                            if (params.data) onShowModal(params.data);
                        }}
                    >
                        View
                    </Button>
                );
            },
        },
        {
            field: "eventSource",
            headerName: "Event Source",
            maxWidth: 240,
            sortable: false,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                const onClick = () => {
                    if (params.data && params.data.eventSource) {
                        setSpecificEvent({ eventSource: params.data.eventSource, eventId: null });
                    }
                    return false;
                };
                return params.data && params.data.eventSource ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href="#" onClick={onClick}>
                        {params.data.eventSource}
                    </a>
                ) : null;
            },
        },
        {
            field: "eventId",
            headerName: "Event Id",
            maxWidth: 120,
            sortable: false,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<ILog>) => {
                const onClick = () => {
                    if (params.data && params.data.eventSource && params.data.eventId) {
                        setSpecificEvent({ eventSource: params.data.eventSource, eventId: params.data.eventId });
                    }
                    return false;
                };
                return params.data && params.data.eventId ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href="#" onClick={onClick}>
                        {params.data.eventId}
                    </a>
                ) : null;
            },
        },
    ];

    const originalModel: ILog = {
        id: viewModal.original?.id ?? "0",
        logLevel: viewModal.original?.logLevel ?? LogLevel.Info,
        createdAt: viewModal.original?.createdAt ?? "",
        detailsJson: viewModal.original?.detailsJson ?? "",
        message: viewModal.original?.message ?? "",
        user: {
            id: viewModal.original?.user?.id ?? "0",
            firstName: viewModal.original?.user?.firstName ?? "",
            lastName: viewModal.original?.user?.lastName ?? "",
            emailAddress: viewModal.original?.user?.emailAddress ?? "",
        },
        active: viewModal.original?.active ?? false,
        eventSource: viewModal.original?.eventSource ?? "",
        eventId: viewModal.original?.eventId ?? "",
    };

    const selectedRawJson = originalModel.detailsJson ?? JSON.stringify({ fullMessage: originalModel.message });
    let parsedJson: any | null;
    try {
        parsedJson = JSON.parse(selectedRawJson);
    } catch (e) {
        console.error("error parsing json", e);
        parsedJson = null;
    }

    let rows: { title: string; value: string }[];
    if (parsedJson == null) {
        rows = [];
    } else {
        rows = Object.keys(parsedJson).map((key) => ({
            title: key,
            value: parsedJson[key]?.toString() === parsedJson[key] ? parsedJson[key] : JSON.stringify(parsedJson[key]),
        }));
    }

    const onClearActive = async () => {
        if (gridApi) {
            const ids = gridApi
                .getRenderedNodes()
                .filter((row) => !!row.data && row.data.active)
                .map((row) => row.data!.id);
            if (ids.length) {
                if (!window.confirm("Clear active flag on all displayed events?")) return;
                clearActive(ids);
            }
        }
    };

    return (
        <>
            <PageHeader>Error Logs</PageHeader>
            <Row className="mb-3">
                <div>Log Level: </div>
                <Col xs={"12"} className="d-flex" style={{ gap: 10 }}>
                    {specificEvent ? (
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setSpecificEvent(null);
                                }}
                            >
                                Clear event filter
                            </button>
                        </div>
                    ) : (
                        logLevelFilters.map((logLevelFilter) => (
                            <div>
                                <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={logLevelFilter.enabled}
                                    id={"filter-" + logLevelFilter.name}
                                    onChange={() => {
                                        let matchingFilter = logLevelFilters.find((x) => x.name === logLevelFilter.name);
                                        matchingFilter!.enabled = !matchingFilter!.enabled;
                                        setLogLevelFilters([...logLevelFilters]);
                                    }}
                                />
                                <label htmlFor={"filter-" + logLevelFilter.name}>{logLevelFilter.name}</label>
                            </div>
                        ))
                    )}
                    <div>
                        <input
                            className="me-2"
                            type="checkbox"
                            checked={active}
                            id="filter-active"
                            onChange={() => {
                                setActive(!active);
                            }}
                        />
                        <label htmlFor="filter-active">Active</label>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={onClearActive}>
                            Clear active
                        </button>
                    </div>
                </Col>
            </Row>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Logs</Card.Header>
                <Card.Body>
                    <div className="ag-theme-alpine mt-3">
                        <AgGridReact
                            columnDefs={[...columns]}
                            pagination={true}
                            paginationPageSize={100}
                            domLayout="autoHeight"
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            onGridReady={(params) => setGridApi(params.api)}
                            rowModelType="infinite"
                            loadingOverlayComponentParams={{ loadingMessage: "Loading" }}
                        ></AgGridReact>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={viewModal.show} onHide={onCloseViewModal} size="xl">
                <VForm initialValue={originalModel}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" readOnly value={originalModel.message} rows={5} />
                        </Form.Group>
                        {rows.map((row, index) => (
                            <Form.Group key={index} className="mb-2">
                                <Form.Label>{row.title}</Form.Label>
                                <Form.Control as="textarea" readOnly value={row.value} rows={5} />
                            </Form.Group>
                        ))}
                        {selectedRawJson ? (
                            <Form.Group>
                                <Form.Label>Raw JSON</Form.Label>
                                <Form.Control as="textarea" readOnly value={selectedRawJson} rows={5} />
                            </Form.Group>
                        ) : null}
                    </Modal.Body>
                </VForm>
            </Modal>
        </>
    );
};

export default LogIndex;
