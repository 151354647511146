import { Route, Switch } from "react-router-dom";
import StationSetType from "../../../../enums/StationSetTypes";
import StationType from "../../../../enums/StationTypes";
import ReturnCart, { ERole } from "../_shared/ReturnCart";
import ScanBinController from "./ScanBinController";
import ScanCart from "./ScanCart";
import SelectList from "./SelectList";

const PickController = () => {
    return (
        <Switch>
            <Route exact path={`/mobile/pick/selectlist`}>
                <SelectList />
            </Route>
            <Route exact path={`/mobile/pick/scancart`}>
                <ScanCart />
            </Route>
            <Route path={`/mobile/pick/scanbin`}>
                <ScanBinController />
            </Route>
            <Route exact path={`/mobile/pick/:cartId/returncart`}>
                <ReturnCart stationSetType={StationSetType.Pack} stationType={StationType.Input} role={ERole.Pick} />
            </Route>
        </Switch>
    );
};

export default PickController;
