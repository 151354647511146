import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

type ResolveType = (value: string | undefined | PromiseLike<string | undefined>) => void;

const useInputModal = () => {
    const resolveRef = useRef<ResolveType>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [previousValue, setPreviousValue] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("");
    const [infoText, setInfoText] = useState<string>(""); //text to display above the input field

    const showInputModal = useCallback((modalTitle?: string, passedInValue?: string, infoText?: string) => {
        if (passedInValue) setPreviousValue(passedInValue);
        if (modalTitle) setModalTitle(modalTitle);
        if (infoText) setInfoText(infoText);
        return new Promise<string | undefined>((resolve) => {
            resolveRef.current = resolve;
            setOpen(true);
        });
    }, []);

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = previousValue;
    }, [open, previousValue]);

    useEffect(() => {
        if (inputRef?.current && open) inputRef.current.focus();
    }, [open]);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef && resolveRef.current && inputRef && inputRef.current) {
            const value = inputRef.current.value;
            if (!value) return alert("Please enter a value!");
            resolveRef.current(value);
            setPreviousValue(value);
            setOpen(false);
        }
    };

    function inputModal() {
        return (
            <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{modalTitle}</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column">
                    {infoText && <div>{infoText}</div>}
                    <input
                        ref={inputRef}
                        style={{
                            padding: 10,
                            alignSelf: "stretch",
                            height: 40,
                            borderRadius: 4,
                            border: "1px solid #798394",
                            marginRight: 10,
                            width: "100%",
                        }}
                    />
                </Modal.Body>

                <Modal.Footer className="flex-nowrap">
                    <button className="btn btn-primary" style={{ width: "50%" }} onClick={() => confirm()}>
                        <b>Confirm</b>
                    </button>
                    <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                        <b>Cancel</b>
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    return { inputModal, showInputModal, open };
};

export default useInputModal;
