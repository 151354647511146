/**
 * Takes in a string and makes the first letter in a string upper case.
 * The second optional argument lets you split the string on specific characters. The resulting output will have each strings first letter as upper case, separated by spaces.
 * *****Numbers at the start of the string are viewed as a valid character to set as upper case, so all subsequent characters in the string will be lower case.
 */
const caseFormatter = (string?: string | null, separators?: string[]) => {
    if (!string) return;
    var strArr: string[] = [];
    [" "].concat(separators ?? []).reduceRight((accumulator, currentValue) => {
        let res: string[] = accumulator.split(currentValue).filter((x) => x);
        strArr = res;
        return res.join(" ");
    }, string);
    return strArr.map((x) => x[0].toUpperCase() + x.slice(1).toLocaleLowerCase()).join(" ");
};

export default caseFormatter;
