import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { CSSProperties, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ErrorDisplay from "../../../../../components/misc/ErrorDisplay";
import SaleFulfillmentStatus from "../../../../../enums/SaleFulfillmentStatus";
import MobileLoading from "../../../components/MobileLoading";
import PrimaryHeader from "../../../components/PrimaryHeader";
import MobileRadio from "../../../components/radios/MobileRadio";
import MobileRadioContainer from "../../../components/radios/MobileRadioContainer";
import SecondaryHeader from "../../../components/SecondaryHeader";
import useScanner from "../../../hooks/useScanner";
import { forgeURLSearchParams } from "../../pack/ScanSku";
import { useSettingsValue } from "../../../../../hooks/useSettingsValue";

const findOpenSaleFulfillmentsQuery = `
query ($sku: String!, $warehouseId: ID!) {
  product(sku: $sku) {
    id
    sku
    openSaleFulfillmentLineItems {
      saleFulfillment {
        id
        saleId
        status
        lineItems {
          packageQuantity
          saleLineItem { 
            product {
              id
              sku
            } 
          }
        }
      }
    }
  }
  mobile {
    findStations(warehouseId: $warehouseId, stationSetType: PACK, stationType: INPUT) {
      id
      stationSet {
        name
      }
    }
  }
}
`;

interface IFindOpenSaleFulfillmentsQueryResult {
    product: {
        id: string;
        sku: string;
        openSaleFulfillmentLineItems: Array<{
            saleFulfillment: {
                id: string;
                saleId: string;
                status: SaleFulfillmentStatus;
                lineItems: Array<{
                    packageQuantity: number;
                    saleLineItem: {
                        product: {
                            id: string;
                            sku: string;
                        };
                    };
                }>;
            };
        }>;
    } | null;
    mobile: {
        findStations: Array<{
            id: string;
            stationSet: {
                name: string;
            };
        }>;
    } | null;
}

interface IFindOpenSaleFulfillmentsQueryVariables {
    sku: string;
    warehouseId: string;
}

const getOpenSaleFulfillmentStateQuery = `
query ($warehouseId:ID!, $saleFulfillmentId:ID!) {
  mobile {
    getOpenSaleFulfillmentState(warehouseId: $warehouseId, saleFulfillmentId: $saleFulfillmentId){
      doItemsRemain
      stationId
      saleId
    }
  }
}
`;

interface IOpenSaleFulfillmentStateResult {
    mobile: {
        getOpenSaleFulfillmentState: {
            doItemsRemain: boolean;
            stationId: string | null;
            saleId: string;
        };
    };
}

interface IOpenSaleFulfillmentStateVariables {
    warehouseId: string;
    saleFulfillmentId: string;
}

interface ISelectedSaleFulfillment {
    saleFulfillmentId: string;
    saleId: string;
    productId: string;
}

const FindOpenSaleFulfillments = () => {
    const history = useHistory();
    const inputRef = useRef<HTMLInputElement>(null);
    const searchComponentRef = useRef<HTMLDivElement>(null);
    const [searchInput, setSearchInput] = useState("");
    const [selectedSaleFulfillment, setSelectedSaleFulfillment] = useState<ISelectedSaleFulfillment>();
    const [showModal, setShowModal] = useState(false);
    const [selectedStationId, setSelectedStationId] = useState("");
    const [openSaleFulfillmentStateLoading, setOpenSaleFulfillmentStateLoading] = useState(false);
    const [runGetOpenSaleFulfillmentMutation] = useMutation<IOpenSaleFulfillmentStateResult, IOpenSaleFulfillmentStateVariables>(
        getOpenSaleFulfillmentStateQuery
    );

    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");

    const { loading, refetch, error, data } = useQuery<IFindOpenSaleFulfillmentsQueryResult, IFindOpenSaleFulfillmentsQueryVariables>(
        findOpenSaleFulfillmentsQuery,
        {
            fetchPolicy: "no-cache",
            skip: !searchInput || !defaultWarehouseId,
            variables: !searchInput || !defaultWarehouseId ? undefined : { sku: searchInput, warehouseId: defaultWarehouseId },
        }
    );

    const onScan = (scannerValue: string) => {
        const scanner = { scannerValue: scannerValue };
        if (!loading || !openSaleFulfillmentStateLoading) setSearchInput(scanner.scannerValue);
    };
    useScanner(onScan);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;
    if (!loading && !data && searchInput) return <ErrorDisplay onClick={refetch}>Fetching open sale fulfillments failed</ErrorDisplay>;

    const go = () => {
        if (inputRef && inputRef.current && inputRef.current.value) {
            setSearchInput(inputRef.current.value.trim());
        }
    };

    const openSaleFulfillmentLineItems = data?.product?.openSaleFulfillmentLineItems ?? [];
    const openSaleFulfillmentLineItemsLength = openSaleFulfillmentLineItems.length;

    const nextButtonHandler = async () => {
        if (!selectedSaleFulfillment) return;
        if (!selectedSaleFulfillment.saleFulfillmentId) return;
        if (!defaultWarehouseId) return;
        //from here, we need to determine if a sale fulfillment is in progress, or if it is the last package in the order, at which point it is complete
        //once an order has started the pack process, that means a station *should* contain all of the products necessary for that order, so we need to search where items for that order might be.
        //if no products for an order show up, that means that the current package we have contains the last of the items that need to be shipped, at which point we are able to start the label printing process.
        //graphql request to find the sale orderfulfillment status
        try {
            setOpenSaleFulfillmentStateLoading(true);
            const data = await runGetOpenSaleFulfillmentMutation({
                variables: {
                    saleFulfillmentId: selectedSaleFulfillment.saleFulfillmentId,
                    warehouseId: defaultWarehouseId,
                },
            });
            const resData = data.data;
            const openSaleFulfillmentState = resData.mobile.getOpenSaleFulfillmentState;
            if (openSaleFulfillmentState.doItemsRemain) {
                if (!openSaleFulfillmentState.stationId) {
                    alert(`There are still items to pack for this order, but no station could be found where the remaining items are`);
                    setShowModal(true);
                } else {
                    history.push(
                        `/mobile/pack/${openSaleFulfillmentState.stationId}/${0}?${forgeURLSearchParams({
                            activeSaleId: openSaleFulfillmentState.saleId,
                        })}`
                    );
                }
            } else {
                //at this point, there are no more remaining items to be packed, and the selected sale fulfillment needs to have a label printed for it,
                //so, we need to select a station for where the label will be printed, then redirect to the label printing process
                setShowModal(true);
            }
        } catch (error) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setOpenSaleFulfillmentStateLoading(false);
        }
    };

    const stations = data?.mobile?.findStations ?? [];

    const modalButtonStyle: CSSProperties = {
        flex: 1,
        height: 38,
        backgroundColor: "white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.55)",
        borderRadius: 4,
        border: "1px",
    };

    return (
        <>
            {loading || openSaleFulfillmentStateLoading || defaultWarehouseIdLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title="Find Open Sale Fulfillments"
                IncludeBackButton
                IncludeHomeButton
                BackButtonText="< Admin Pack"
                CustomBackButtonPath="/mobile/admin/pack"
            />

            <SecondaryHeader Title="Select Open Sale Fulfillment" NextButtonHandler={async () => await nextButtonHandler()} />

            <div ref={searchComponentRef} style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    ref={inputRef}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search by SKU..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    onClick={go}
                >
                    <b>GO</b>
                </button>
            </div>

            <div style={{ margin: 5 }}>
                {loading || !searchInput ? (
                    <></>
                ) : openSaleFulfillmentLineItemsLength < 1 ? (
                    `No open sale fulfillments found for SKU "${searchInput}"`
                ) : (
                    <>
                        <div style={{ marginBottom: 5, textAlign: "center" }}>
                            <b>Open Sale Fulfillments with SKU "{searchInput}"</b>
                        </div>
                        {openSaleFulfillmentLineItems.map((sfli, a) => {
                            const totalPackageQuantity = sfli.saleFulfillment.lineItems.reduce(
                                (accumulator, value) => accumulator + value.packageQuantity,
                                0
                            );
                            const tableBackgroundColor =
                                selectedSaleFulfillment?.saleFulfillmentId === sfli.saleFulfillment.id ? "#CFE3FA" : "";
                            return (
                                <>
                                    <div
                                        key={sfli.saleFulfillment.id + a}
                                        style={{
                                            borderRadius: 4,
                                            border: "1px solid rgba(0,0,0,.3)",
                                            padding: 5,
                                            backgroundColor: tableBackgroundColor,
                                        }}
                                        onClick={() =>
                                            setSelectedSaleFulfillment({
                                                productId: sfli.saleFulfillment.lineItems.find((x) => x)!.saleLineItem.product.id,
                                                saleFulfillmentId: sfli.saleFulfillment.id,
                                                saleId: sfli.saleFulfillment.saleId,
                                            })
                                        }
                                    >
                                        <table style={{ width: "100%", textAlign: "center", marginBottom: 5 }}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#EDF0F4" }}>
                                                    <th style={{ width: "50%" }}>SKU</th>
                                                    <th style={{ width: "50%" }}>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sfli.saleFulfillment.lineItems.map((li, b) => {
                                                    return (
                                                        <tr key={sfli.saleFulfillment.id + a + b}>
                                                            <td style={{ paddingTop: 5 }}>{li.saleLineItem.product.sku}</td>
                                                            <td style={{ paddingTop: 5 }}>{li.packageQuantity}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1, textAlign: "center" }}>
                                                Sale Fulfillment Id: {sfli.saleFulfillment.id}
                                            </div>
                                            <div style={{ flex: 1, textAlign: "center" }}>Total: {totalPackageQuantity}</div>
                                        </div>
                                    </div>
                                    {a === openSaleFulfillmentLineItemsLength - 1 ? <></> : <hr />}
                                </>
                            );
                        })}
                    </>
                )}
            </div>

            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setSelectedStationId("");
                }}
            >
                <Modal.Header closeButton>Select a Station</Modal.Header>
                <Modal.Body>
                    <form>
                        <MobileRadioContainer>
                            {stations.map((station) => {
                                return (
                                    <MobileRadio
                                        id={station.id}
                                        key={station.id}
                                        label={station.stationSet.name}
                                        name={"station"}
                                        onClick={() => setSelectedStationId(station.id)}
                                        value={station.id}
                                    />
                                );
                            })}
                            {/*<MobileRadio id={"100"} key={"100"} label={"label name 100"} name={"station"} onClick={() => setSelectedStationId("100")} value={"100"} />*/}
                        </MobileRadioContainer>
                    </form>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex" }}>
                    <button
                        style={modalButtonStyle}
                        onClick={() =>
                            history.push(
                                `/mobile/pack/${selectedStationId}/${0}?${forgeURLSearchParams({
                                    activeSaleFulfillmentId: selectedSaleFulfillment?.saleFulfillmentId,
                                    activeProductId: selectedSaleFulfillment?.productId,
                                    activeSaleId: selectedSaleFulfillment?.saleId,
                                })}`
                            )
                        }
                    >
                        Continue
                    </button>
                    <button
                        style={modalButtonStyle}
                        onClick={() => {
                            setShowModal(false);
                            setSelectedStationId("");
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FindOpenSaleFulfillments;
