import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { VButton, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IQueryResult {
    settings: {
        items: Array<ISetting>;
    };
}

interface ISetting {
    id: string;
    name: string;
    value: string | null;
    secret: boolean;
}

interface ISettingModel {
    id: string;
    name: string;
    value: string;
}

interface IEditResult {
    setting: {
        edit: ISetting;
    };
}

interface IEditVariables {
    original: ISetting;
    modified: ISetting;
}

interface IAddResult {
    setting: {
        add: ISetting;
    };
}

interface IAddVariables {
    value: ISetting;
}

interface IDeleteResult {
    setting: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: ISetting;
}

const hiddenModal: IModal = {
    show: false,
};

const SettingIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //setting list
    const { data, error, refetch } = useQuery<IQueryResult, {}>("{ settings { items { id name value secret } } }", {
        fetchPolicy: "no-cache",
    });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(
        "mutation ($original: SettingInput!, $modified: SettingInput!) { setting { edit (original: $original, modified: $modified) { id name value secret } } }"
    );
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(
        "mutation ($value: SettingInput!) { setting { add (value: $value) { id name value secret } } }"
    );
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { setting { delete (id: $id) } }");

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    //run when the edit button is pressed
    const onShowEditModal = (setting: ISetting) => {
        setModal({
            show: true,
            original: setting,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const columns: ColDef<ISetting, any>[] = [
        {
            field: "name",
            flex: 1,
            filter: true,
            sortable: true,
            resizable: true,
            sort: "asc",
        },
        {
            field: "value",
            flex: 2,
            filter: true,
            sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams) => {
                return <>{params.data.secret ? <i>(secret)</i> : params.data.value}</>;
            },
        },
        {
            maxWidth: 80,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Button
                        size="sm"
                        variant="white"
                        className="mt-2"
                        style={{ padding: "0.125rem 0.4rem", float: "right" }}
                        onClick={() => onShowEditModal(params.data)}
                    >
                        Edit
                    </Button>
                );
            },
        },
    ];

    let rowData: ISetting[] = data.settings.items.map((item) => {
        return {
            id: item.id,
            name: item.name,
            secret: item.secret,
            value: item.value,
        };
    });

    //run when the save button is pressed
    const onSaveChanges = (modified: ISettingModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        name: modified.name,
                        value: modified.value,
                        secret: false,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.setting.add;
                    data.settings.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding setting", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: {
                        ...modal.original,
                        value: modal.original.value || "",
                    },
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        name: modified.name,
                        value: modified.value,
                        secret: modal.original.secret,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.setting.edit;
                    const oldIndex = data.settings.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.settings.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing setting", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this setting?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.settings.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.settings.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Setting", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: ISettingModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              value: modal.original.value || "",
          }
        : {
              id: "0",
              name: "",
              value: "",
          };

    const card = (active: boolean) => {
        // search data where setting or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        return (
            <Card className="border-primary">
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">Setting</Card.Header>
                <Card.Body>
                    <div className="ag-theme-alpine mt-3">
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={30}
                            domLayout="autoHeight"
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                        ></AgGridReact>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <PageHeader>Settings</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new setting
                </Button>
            </p>

            {/* Active Setting */}
            {card(true)}

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Setting</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="value">Value</VLabel>
                                    <VControl type="text" required valueName="value" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SettingIndex;
