//import React from "react";
//import { Link } from "react-router-dom";
import { /*InputGroup, Row, Col, Form, Table, Button, Modal,*/ Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch } from "react-router-dom";
import PageHeader from "../../components/pageheader/PageHeader";
import PaymentTermIndex from "./payment_term/PaymentTermIndex";
import SalesChannelIndex from "./sales_channel/SalesChannelIndex";
import PickEquipmentIndex from "./pick_equipment/PickEquipmentIndex";
import PickFilterIndex from "./pick_filter/PickFilterIndex";
import PackFilterIndex from "./pack_filter/PackFilterIndex";
import DevicesIndex from "./devices/DevicesIndex";
import HiveLinksIndex from "./hivelinks/HiveLinksIndex";
import SettingIndex from "./setting/SettingIndex";
import ShippingCarrierIndex from "./shipping_carrier/ShippingCarrierIndex";
import LabelProviderIndex from "./label_provider/LabelProviderIndex";
import ErrorLogIndex from "./error_log/ErrorLogIndex";
import IntegrationsIndex from "./integrations/IntegrationsIndex";
import BulkUtilitiesIndex from "./bulk_utilities/BulkUtilitiesIndex";
import SalesChannelDetails from "./sales_channel/SalesChannelDetail";
import LongRunningTasksIndex from "./long_running_tasks/LongRunningTasksIndex";
import ReturnReasonsIndex from "./return_reasons/ReturnReasonsIndex";
import ProductMovementLogs from "./product_movement_logs/ProductMovementLogs";
import PackageIndex from "./package/PackageIndex";
import PackageDetails from "./package/PackageDetail";
import { BuildVersion } from "../../globalVariables";
import { useQuery } from "@shane32/graphql";
import SalesProjection from "./salesprojection/SalesProjection";

const Setup = () => {
    const { data, loading } = useQuery<{ version: string | null }>("{version}");
    const serverVersion = loading ? "" : data?.version || "unknown";
    return (
        <>
            <PageHeader>Setup</PageHeader>
            <Row>
                <Col xs="auto">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <LinkContainer to="/setup/paymentterms" exact>
                                <Nav.Link>Payment Terms</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/saleschannels" exact>
                                <Nav.Link>Sales Channels</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/shippingcarriers" exact>
                                <Nav.Link>Shipping Carriers</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/labelproviders" exact>
                                <Nav.Link>Label Provider</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/pickequipment" exact>
                                <Nav.Link>Pick Equipment</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/pickfilter" exact>
                                <Nav.Link>Pick Filter</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/packfilter" exact>
                                <Nav.Link>Pack Filter</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/hivelinks" exact>
                                <Nav.Link>HiveLinks</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/devices" exact>
                                <Nav.Link>Devices</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/packages" exact>
                                <Nav.Link>Packages</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/returnreasons" exact>
                                <Nav.Link>Return Reasons</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/bulkutilities" exact>
                                <Nav.Link>Bulk Utilities</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/salesprojection" exact>
                                <Nav.Link>Sales Projections</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/errorlogs" exact>
                                <Nav.Link>Error Logs</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/productmovementlogs" exact>
                                <Nav.Link>Product Movement Logs</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/settings" exact>
                                <Nav.Link>Settings</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/integrations/zbox" exact>
                                <Nav.Link>Integrations</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/setup/longrunningtasks" exact>
                                <Nav.Link>Long Running Tasks</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/graphiql" exact>
                                <Nav.Link>GraphiQL</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col>
                    <Switch>
                        <Route path="/setup/settings" exact component={SettingIndex} />
                        <Route path="/setup/paymentterms" exact component={PaymentTermIndex} />
                        <Route path="/setup/saleschannels/:id" component={SalesChannelDetails} />
                        <Route path="/setup/saleschannels" exact component={SalesChannelIndex} />
                        <Route path="/setup/shippingcarriers" exact component={ShippingCarrierIndex} />
                        <Route path="/setup/labelproviders" exact component={LabelProviderIndex} />
                        <Route path="/setup/pickequipment" exact component={PickEquipmentIndex} />
                        <Route path="/setup/pickfilter" exact component={PickFilterIndex} />
                        <Route path="/setup/packfilter" exact component={PackFilterIndex} />
                        <Route path="/setup/packages" exact component={PackageIndex} />
                        <Route path="/setup/packages/:id" component={PackageDetails} />
                        <Route path="/setup/hivelinks" exact component={HiveLinksIndex} />
                        <Route path="/setup/devices" exact component={DevicesIndex} />
                        <Route path="/setup/returnreasons" exact component={ReturnReasonsIndex} />
                        <Route path="/setup/bulkutilities" exact component={BulkUtilitiesIndex} />
                        <Route path="/setup/salesprojection" exact component={SalesProjection} />
                        <Route path="/setup/errorlogs" exact component={ErrorLogIndex} />
                        <Route path="/setup/productmovementlogs" exact component={ProductMovementLogs} />
                        <Route path="/setup/integrations" component={IntegrationsIndex} />
                        <Route path="/setup/longrunningtasks" component={LongRunningTasksIndex} />
                        <Route>
                            <p>Server version: {serverVersion}</p>
                            <p>Client version: {BuildVersion}</p>
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </>
    );
};

export default Setup;
