import { useQuery } from "@shane32/graphql";
import { ZbDbGraphQLClient } from "../..";
import MobileLoading from "../../pages/mobile/components/MobileLoading";
import { IImageQueryResult, IImageQueryVariables, imageQuery } from "../../pages/mobile/graphs/queries/ImageQuery";

interface IProps {
    sku: string | null | undefined;
    /** Number is in relation to pixels */
    size: 500 | 100;
}

const ProductImage = (props: IProps) => {
    const { loading, data } = useQuery<IImageQueryResult, IImageQueryVariables>(imageQuery, {
        variables: !props.sku ? undefined : { sku: props.sku },
        client: ZbDbGraphQLClient,
        skip: !props.sku,
        fetchPolicy: "cache-and-network",
    });

    const firstProductImage = data?.part?.primaryMedia ?? null;
    let src = undefined;
    switch (props.size) {
        case 500:
            src = firstProductImage?.original;
            break;
        case 100:
            src = firstProductImage?.thumbnail;
            break;
    }

    return loading ? <MobileLoading /> : <img src={src} alt={`${props.sku ?? ""}`} style={{ height: "100%" }} />;
};

export default ProductImage;
