import { useHistory } from "react-router";
import PrimaryHeader from "./components/PrimaryHeader";
import SecondaryHeader from "./components/SecondaryHeader";
import MobileTable from "./components/tables/MobileTable";
import UserScoreType from "../../enums/UserScoreType";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "./components/MobileLoading";

const meQuery = `
query ($from: DateOnly!, $to: DateOnly!) {
    me {
      scores (from: $from, to: $to) {
        date
        type
        score
      }
    }
  }
`;

interface IMeQueryVariables {
    from: string;
    to: string;
}

interface IMeQueryResult {
    me: {
        scores: Array<{
            date: string;
            type: UserScoreType;
            score: number;
        }>;
    };
}

type TRoleBase = {
    id: string;
    name: string;
    priority: number;
    quests?: number;
    score?: number;
    path: string;
};

const SelectRole = () => {
    const history = useHistory();
    const today = new Date().toISOString().split("T")[0];

    const { data: meData, loading: meLoading } = useQuery<IMeQueryResult, IMeQueryVariables>(meQuery, {
        fetchPolicy: "cache-and-network",
        variables: {
            from: today,
            to: today,
        },
    });

    const roles: TRoleBase[] = [
        { id: "intake", name: "Intake", priority: 1, path: "/mobile/intake/selectpurchaseorder" },
        { id: "blindintake", name: "Blind Intake", priority: 2, path: "/mobile/blindintake/selectsupplier" },
        { id: "pick", name: "Pick", priority: 3, path: "/mobile/pick/selectlist" },
        { id: "pack", name: "Pack", priority: 4, path: "/mobile/pack/scanstation" },
        { id: "returns", name: "Returns", priority: 5, path: "/mobile/returns/selectreturnnumber" },
        { id: "stocktake", name: "Stock Take", priority: 6, path: "/mobile/stocktake/stocktakesearch" },
        { id: "stocktransfer", name: "Transfer", priority: 7, path: "/mobile/stocktransfer/scanpickbin" },
        { id: "admin", name: "Admin", priority: 100, path: "/mobile/admin" },
    ];

    return (
        <>
            <PrimaryHeader Title="Role List" IncludeHomeButton />
            <SecondaryHeader Title="Select Role" NextButtonHandler={() => void 0} />
            <MobileTable ignoreSpacing>
                <thead>
                    <tr>
                        <td>Type</td>
                        <td>Quests</td>
                        <td>Score</td>
                    </tr>
                </thead>
                <tbody>
                    {roles
                        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                        .map((role) => {
                            return (
                                <tr key={role.id} id={role.id} onClick={() => role.path && history.push(role.path)}>
                                    <td>{role.name}</td>
                                    <td>{role.quests}</td>
                                    <td>{role.score}</td>
                                </tr>
                            );
                        })}
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td style={{ position: "relative" }}>
                            {meLoading ? (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        padding: "inherit",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    <MobileLoading />
                                </div>
                            ) : (
                                meData?.me?.scores?.reduce((acc, score) => acc + score.score, 0)
                            )}
                        </td>
                    </tr>
                </tbody>
            </MobileTable>
        </>
    );
};

export default SelectRole;
