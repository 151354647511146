enum ModelType {
    Product = "PRODUCT", //ZP
    Location = "LOCATION", //ZL
    PickZone = "PICK_ZONE", //ZPZ
    Warehouse = "WAREHOUSE", //ZW
    PurchaseOrder = "PURCHASE_ORDER", //ZPO
    PurchaseInvoice = "PURCHASE_INVOICE", //ZPI
    SaleOrder = "SALE_ORDER", //ZSO
    SaleFulfillment = "SALE_FULFILLMENT", //ZSF
    Supplier = "SUPPLIER", //ZSP
}

export default ModelType;
