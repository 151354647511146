import React from "react";
import { Row, Col, Form, Table, Button, Modal, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import PageHeader from "../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

interface IWarehouseQueryResult {
    warehouses: {
        items: Array<IWarehouse>;
    };
}
interface IWarehouse {
    id: string;
    name: string;
    address: {
        id: string;
    };
    active: boolean;
}

interface IAddResult {
    warehouse: {
        add: IWarehouse;
    };
}

interface IAddVariables {
    value: IWarehouse;
}

function sortWarehouse(a: IWarehouse, b: IWarehouse) {
    return a.name > b.name ? 1 : -1;
}

const WarehousesIndex = () => {
    const [saving, setSaving] = React.useState(false);
    const { data, error, refetch } = useQuery<IWarehouseQueryResult, {}>("{ warehouses { items { id name address { id } active } } }", {
        fetchPolicy: "no-cache",
    });
    const [runAdd] = useMutation<IAddResult, IAddVariables>(
        "mutation ($value: WarehouseInput!) { warehouse { add (value: $value) { id name address { id } active } } }"
    );
    const [showModal, setShowModal] = React.useState(false);
    const history = useHistory();

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!data) return <Loading />;

    const onShowModal = () => {
        setShowModal(true);
    };
    ////run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setShowModal(false);
    };

    const originalModel: IWarehouse = {
        id: "0",
        name: "",
        address: {
            id: "",
        },
        active: true,
    };

    const onSaveChanges = (modified: IWarehouse) => {
        setSaving(true);
        runAdd({
            variables: {
                value: {
                    id: "0",
                    name: modified.name,
                    active: modified.active,
                    address: {
                        id: "0",
                    },
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                var newValue = ret.data.warehouse.add;
                data.warehouses.items.push(newValue);
                //enable form controls and hide the modal
                setSaving(false);
                setShowModal(false);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding a Warehouse", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const navTo = (e: React.MouseEvent, id: string) => {
        if (e.currentTarget === e.target) history.push("/warehouses/" + encodeURIComponent(id) + "/locations");
    };

    const card = (active: boolean) => {
        // search data where active or inactive, as appropriate
        // sort by defaultpaymentTermId -- see function defined above
        const warehouse = data!.warehouses.items.filter((warehouse) => warehouse.active === active).sort(sortWarehouse);
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                <Card.Header className="bg-primary text-white">
                    {/* set card header appropriately */}
                    {active ? "Active" : "Inactive"} Warehouses
                </Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Warehouses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warehouse.map((warehouse) => (
                                <tr key={warehouse.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, warehouse.id)}>
                                        <Link to={`/warehouses/${warehouse.id}/locations`}>{warehouse.name}</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <PageHeader>Warehouses</PageHeader>
            <p>
                <Button variant="white" onClick={onShowModal}>
                    Add new warehouse
                </Button>
            </p>

            {/* Active Warehouses */}
            {card(true)}

            {/* Inactive Warehouses*/}
            {card(false)}

            <Modal show={showModal} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Add Warehouse</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formName">
                                    <VLabel valueName="name">Name</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formActive">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default WarehousesIndex;
