import "graphiql/graphiql.css";
import * as React from "react";
import useTimer from "../hooks/useTimer";

const TestScanner = () => {
    const [boxValue, setBoxValue] = React.useState<string>("");
    const [textAreaValue, setTextAreaValue] = React.useState<string>("");
    const [logs, setLogs] = React.useState<string>("");
    const logsRef = React.useRef<string>("");
    const [, setTimer] = useTimer(
        () => {
            console.log("timer");
            if (logsRef.current !== logs) {
                setLogs(logsRef.current);
                console.log("setLogs", logsRef.current);
            }
        },
        100,
        true
    );

    const appendLog = (str: string) => {
        console.log("appendLog", str);
        logsRef.current = logsRef.current + str + "\n";
        setTimer(100);
    };

    const parsetarget = (elem: Element) => {
        if (elem.id) {
            return `#${elem.id}`;
        } else if (elem.tagName) {
            return elem.tagName;
        } else {
            return "window";
        }
    };

    const appendLog2 = (str: string, e: React.KeyboardEvent) => {
        //const str2 = `${str} current=${parsetarget(e.currentTarget)} target=${parsetarget(e.target as Element)} key='${e.key}' code=${e.keyCode}`;
        const str2 = `${str} ${parsetarget(e.currentTarget)} ${parsetarget(e.target as Element)} '${e.key}' ${e.keyCode}`;
        appendLog(str2);
    };

    const onkeypress = (e: React.KeyboardEvent) => {
        console.log("onkeypress", e);
        appendLog2("press", e);
    };
    const onkeyup = (e: React.KeyboardEvent) => {
        console.log("onkeyup", e);
        appendLog2("up", e);
    };
    const onkeydown = (e: React.KeyboardEvent) => {
        console.log("onkeydown", e);
        appendLog2("down", e);
    };

    const onform = (desc: string, e: React.FormEvent) => {
        console.log(desc, e);
        const str = `${desc} ${parsetarget(e.currentTarget)} ${parsetarget(e.target as Element)}`;
        appendLog(str);
    };

    const oninput = (e: React.FormEvent) => {
        onform("oninput", e);
    };

    const onchange = (e: React.FormEvent) => {
        onform("onchange", e);
    };

    React.useEffect(() => {
        window.addEventListener("keypress", onkeypress as any);
        window.addEventListener("keyup", onkeyup as any);
        window.addEventListener("keydown", onkeydown as any);
        return () => {
            window.removeEventListener("keypress", onkeypress as any);
            window.removeEventListener("keyup", onkeyup as any);
            window.removeEventListener("keydown", onkeydown as any);
        };
    });

    const clearLogs = () => {
        logsRef.current = "";
        setLogs("");
    };

    return (
        <>
            <p>This is a sample paragraph. The quick brown fox jumped over the lazy dog</p>
            <p>
                <input
                    id="box1"
                    type="text"
                    placeholder="uncontrolled box"
                    style={{ width: "100%" }}
                    onKeyDown={onkeydown}
                    onKeyUp={onkeyup}
                    onKeyPress={onkeypress}
                    onInput={oninput}
                    onChange={onchange}
                />
            </p>
            <p>
                <input
                    id="box2"
                    type="text"
                    placeholder="controlled box"
                    style={{ width: "100%" }}
                    value={boxValue}
                    onInput={oninput}
                    onChange={(e) => {
                        onform("onchange", e);
                        setBoxValue(e.currentTarget.value);
                    }}
                    onKeyDown={onkeydown}
                    onKeyUp={onkeyup}
                    onKeyPress={onkeypress}
                />
            </p>
            <p>
                <textarea
                    id="textarea1"
                    placeholder="uncontrolled textarea"
                    rows={5}
                    style={{ width: "100%" }}
                    onKeyDown={onkeydown}
                    onKeyUp={onkeyup}
                    onKeyPress={onkeypress}
                    onInput={oninput}
                    onChange={onchange}
                />
            </p>
            <p>
                <textarea
                    id="textarea2"
                    placeholder="controlled textarea"
                    rows={5}
                    style={{ width: "100%" }}
                    value={textAreaValue}
                    onInput={oninput}
                    onChange={(e) => {
                        onform("onchange", e);
                        setTextAreaValue(e.currentTarget.value);
                    }}
                    onKeyDown={onkeydown}
                    onKeyUp={onkeyup}
                    onKeyPress={onkeypress}
                />
            </p>
            <p>Diagnostic logs:</p>
            <textarea readOnly rows={10} value={logs} style={{ width: "100%", fontSize: 10 }} />
            <button onClick={clearLogs}>Clear logs</button>
        </>
    );
};

export default TestScanner;
