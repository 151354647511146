//import { useContext, useRef, useState } from "react";
//import LoginModal from "./components/LoginModal";
//import PrimaryHeader from "./components/PrimaryHeader";
//import SecondaryHeader from "./components/SecondaryHeader";
//import MobileTable from "./components/tables/MobileTable";
//import DemoContext from "./contexts/DemoContext";

//const MobileIndex = () => {
//    const demoContext = useContext(DemoContext);
//    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
//    const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
//    const tbodyRef = useRef<HTMLTableSectionElement>(null);

//    const tableRowClickHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
//        if (tbodyRef?.current !== null) {
//            const previousTr = Array.from(tbodyRef.current.children).find(x => x.id === selectedUserEmail);
//            if (previousTr !== undefined) (previousTr as HTMLTableRowElement).style.backgroundColor = "white";
//        }
//        setSelectedUserEmail(e.currentTarget.id);
//        e.currentTarget.style.backgroundColor = "#CFE3FA";
//    };

//    const nextButtonHandler = () => {
//        if (selectedUserEmail === "") return alert("Please select a user");
//        setShowLoginModal(true);
//    };

//    return <>
//        <PrimaryHeader Title="Dashboard" />

//        <SecondaryHeader Title="Sign In" NextButtonHandler={nextButtonHandler} />

//        <MobileTable>
//            <thead>
//                <tr>
//                    <td>Name</td>
//                    <td>Active Role</td>
//                    <td>Score</td>
//                </tr>
//            </thead>
//            <tbody ref={tbodyRef}>
//                {demoContext.users.map(u => {
//                    return <tr key={u.email} id={u.email} onClick={tableRowClickHandler}>
//                        <td>{u.username}</td>
//                        <td>-</td>
//                        <td>-</td>
//                    </tr>;
//                })}
//                <tr>
//                    <td>Total</td>
//                    <td>-</td>
//                    <td>-</td>
//                </tr>
//            </tbody>
//        </MobileTable>

//        <LoginModal ShowLoginModal={showLoginModal} SelectedUserEmail={selectedUserEmail} SetShowLoginModal={setShowLoginModal} />
//    </>;
//};

//commenting out above for now. this component doesn't actually do anything...

import { useHistory } from "react-router-dom";

const MobileIndex = () => {
    const history = useHistory();
    history.replace(`/mobile/selectrole`);
    return <div>Nothing</div>;
};

export default MobileIndex;
