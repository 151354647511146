import { createElement, useRef, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import useConfirm from "../../hooks/useConfirm";
import forgeURLSearchParams from "../../helpers/forgeURLSearchParameters";
import { IAddOrPrintBarcodeReturnsURLSearchParams } from "../returns/AddOrPrintBarcodeContainer";
import {
    addProductToPurchaseInvoiceMutation,
    IAddProductToPurchaseInvoiceMutationResponse,
    IAddProductToPurchaseInvoiceMutationVariables,
} from "../../graphs/mutations/AddProductToPurchaseInvoiceMutation";
import GroupBy from "../../../../helpers/GroupBy";

const productsQuery = `
query ($search: String!, $purchaseInvoiceId: ID!) {
  products(search: $search) {
    items {
      id
      sku
      description
      partNumbers {
        partNumber
        sortOrder
      }
      barcodes {
        barcode
      }
      supplier {
        name
      }
    }
  }
  purchaseInvoice(id: $purchaseInvoiceId) {
    invoiceNumber
    lineItems {
      id
      expectedQuantity
      actualQuantity
      purchaseOrderLineItem {
        id
        description
        partNumber
        product {
          id
          sku
          description
          partNumbers {
            partNumber
            sortOrder
          }
          barcodes {
            barcode
          }
          supplier {
            name
          }
        }
      }
    }
  }
}
`;

interface IProduct {
    id: string;
    sku: string;
    description: string;
    partNumbers: Array<{
        partNumber: string;
        sortOrder: number;
    }>;
    barcodes: Array<{
        barcode: string;
        sortOrder: number;
    }>;
    supplier: {
        name: string | null;
    } | null;
}

interface IProductsQueryResult {
    products: {
        items: IProduct[];
    };
    purchaseInvoice: {
        invoiceNumber: string;
        lineItems: IPurchaseInvoiceLineItem[];
    };
}

interface IPurchaseInvoiceLineItem {
    id: string;
    expectedQuantity: number;
    actualQuantity: number;
    purchaseOrderLineItem: {
        id: string;
        description: string;
        partNumber: string;
        product: IProduct;
    };
}

interface IProductsQueryVariables {
    search: string;
    purchaseInvoiceId: string;
}

interface IRouteParams {
    purchaseOrderId: string;
    purchaseInvoiceId: string;
}

export interface ISearchComponentProps {
    showSearchComponent: boolean;
    searchValue: string;
}

type TPropsBase = {
    searchComponentProps: ISearchComponentProps;
    setSearchComponentProps: React.Dispatch<React.SetStateAction<ISearchComponentProps>>;
    componentType: PurchaseInvoiceSearchComponentType;
};

type TPropsVariations =
    | {
          componentType: PurchaseInvoiceSearchComponentType.Intake;
      }
    | {
          componentType: PurchaseInvoiceSearchComponentType.Return;
          setShowAddOrPrintBarcodeModal: React.Dispatch<React.SetStateAction<boolean>>;
      };

type TProps = TPropsBase & TPropsVariations;

export enum PurchaseInvoiceSearchComponentType {
    Intake,
    Return,
}

interface IReturnPaths {
    selectPurchaseOrderIdPath: string;
    selectPurchaseInvoiceIdPath: string;
}

const PurchaseInvoiceSearch = (props: TProps) => {
    const history = useHistory();
    const params = useParams<IRouteParams>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [manualLoading, setManualLoading] = useState(false);
    const { confirmModal, showConfirmModal } = useConfirm();
    const [runAddProductMutation] = useMutation<
        IAddProductToPurchaseInvoiceMutationResponse,
        IAddProductToPurchaseInvoiceMutationVariables
    >(addProductToPurchaseInvoiceMutation);
    const { loading, data, refetch, error } = useQuery<IProductsQueryResult, IProductsQueryVariables>(productsQuery, {
        fetchPolicy: "no-cache",
        variables: !props.searchComponentProps.searchValue
            ? undefined
            : {
                  search: props.searchComponentProps.searchValue,
                  purchaseInvoiceId: params.purchaseInvoiceId,
              },
        skip: !props.searchComponentProps.searchValue,
    });

    //determines what paths should be used as a result of specific actions within this component
    let paths: IReturnPaths = (() => {
        switch (props.componentType) {
            case PurchaseInvoiceSearchComponentType.Intake:
                return {
                    selectPurchaseOrderIdPath: `/mobile/intake/selectpurchaseorder`,
                    selectPurchaseInvoiceIdPath: `/mobile/intake/${params.purchaseOrderId}/selectinvoice`,
                };
            case PurchaseInvoiceSearchComponentType.Return:
                return {
                    selectPurchaseOrderIdPath: `/mobile/returns/selectreturnnumber`,
                    selectPurchaseInvoiceIdPath: `/mobile/returns/${params.purchaseOrderId}/selecttrackingnumber`,
                };
        }
    })();

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for product failed"}</ErrorDisplay>;

    if (!params.purchaseOrderId) return <Redirect to={paths.selectPurchaseOrderIdPath} />;

    if (!params.purchaseInvoiceId) return <Redirect to={paths.selectPurchaseInvoiceIdPath} />;

    let productsInDbOG = data?.products.items ?? [];
    let productsInInvoiceOG = data?.purchaseInvoice.lineItems ?? [];
    //remove any of the products returned from the db that already exist in the invoice
    let productsInDb = productsInDbOG.filter((productInDb) => {
        return !productsInInvoiceOG.some((productInInvoice) => {
            return productInDb.id === productInInvoice.purchaseOrderLineItem.product.id;
        });
    });
    //remove any of the products in the invoice that don't match any of the search results
    let productsInInvoice = productsInInvoiceOG.filter((productInInvoice) => {
        return productsInDbOG.some((productInDb) => {
            return productInDb.id === productInInvoice.purchaseOrderLineItem.product.id;
        });
    });

    productsInInvoice = Array.from(GroupBy(productsInInvoice, (x) => x.purchaseOrderLineItem.product.id)).map(([key, values]) => values[0]);

    const go = () => {
        if (!inputRef || !inputRef.current || !inputRef.current.value) return;
        const value = inputRef.current.value.trim();
        if (!value) return;
        props.setSearchComponentProps({ ...props.searchComponentProps, searchValue: value });
    };

    //this is called when we find a product in the database that we want to add to the active invoice
    const addProductToInvoice = async (clickedProductId: string) => {
        if (loading || manualLoading || !data) return;
        try {
            setManualLoading(true);
            const confirmed = await showConfirmModal(
                `Are you sure you want to add this product to invoice ${data.purchaseInvoice.invoiceNumber}`,
                "Add Product to Invoice?",
                { confirm: "Yes", cancel: "No" }
            );
            if (!confirmed) return;
            const response = await (
                await runAddProductMutation({
                    variables: {
                        purchaseInvoiceId: params.purchaseInvoiceId,
                        productId: clickedProductId,
                    },
                })
            ).data;
            const productId = response.purchaseInvoice.addProductToInvoice;
            switch (props.componentType) {
                case PurchaseInvoiceSearchComponentType.Intake:
                    let urlSP = new URLSearchParams();
                    urlSP.set("addToHand", "true");
                    return history.replace(
                        `/mobile/intake/${params.purchaseOrderId}/${
                            params.purchaseInvoiceId
                        }/${productId}/addorprintbarcode?${urlSP.toString()}`
                    );
                case PurchaseInvoiceSearchComponentType.Return:
                    return history.replace(
                        `/mobile/returns/${params.purchaseOrderId}/${
                            params.purchaseInvoiceId
                        }/${productId}/addorprintbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>({
                            addToHand: true,
                        })}`
                    );
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    //if the searched for product is not on the invoice and not in the database, we need to create a new product
    const redirectToAddOrPrintBarcode = () => {
        switch (props.componentType) {
            case PurchaseInvoiceSearchComponentType.Intake:
                let urlSP = new URLSearchParams();
                urlSP.set("addToHand", "true");
                return history.push(
                    `/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/0/addorprintbarcode?${urlSP.toString()}`
                );
            case PurchaseInvoiceSearchComponentType.Return:
                return history.replace(
                    `/mobile/returns/${params.purchaseOrderId}/${
                        params.purchaseInvoiceId
                    }/0/addorprintbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>({
                        addToHand: true,
                    })}`
                );
        }
    };

    //update the search input to use the most current seach term from the search query
    if (inputRef && inputRef.current) inputRef.current.value = props.searchComponentProps.searchValue;

    return (
        <>
            {loading || manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={`Search ${data?.purchaseInvoice?.invoiceNumber}`}
                IncludeBackButton
                BackButtonText={`< Invoice ${data?.purchaseInvoice?.invoiceNumber}`}
                CustomBackButtonFunction={() => props.setSearchComponentProps({ searchValue: "", showSearchComponent: false })}
                IncludeHomeButton
            />

            <SecondaryHeader Title="Search SKU" NextButtonHandler={() => void 0} />

            <div style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    ref={inputRef}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search for SKU..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    onClick={go}
                >
                    <b>GO</b>
                </button>
            </div>

            {productsInDb.length < 1 && productsInInvoice.length < 1 ? <MobileTableTitle>No Results Found</MobileTableTitle> : <></>}

            {/*On Invoice*/}
            {productsInInvoice.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>On Invoice</MobileTableTitle>

                    <MobileTable ignoreSpacing>
                        <thead>
                            <tr>
                                <td>Description</td>
                                <td>Part #</td>
                                <td>Supplier</td>
                            </tr>
                        </thead>
                        <tbody>
                            {productsInInvoice.map((pili, i) => {
                                return (
                                    <tr
                                        key={pili.id + pili.purchaseOrderLineItem.partNumber}
                                        id={pili.purchaseOrderLineItem.partNumber}
                                        onClick={() => {
                                            switch (props.componentType) {
                                                case PurchaseInvoiceSearchComponentType.Intake:
                                                    return history.push(
                                                        `/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${pili.purchaseOrderLineItem.product.id}/addorprintbarcode`
                                                    );
                                                case PurchaseInvoiceSearchComponentType.Return:
                                                    (() => {
                                                        props.setSearchComponentProps({ searchValue: "", showSearchComponent: false });
                                                        history.push(
                                                            `/mobile/returns/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${
                                                                pili.purchaseOrderLineItem.product.id
                                                            }/addorprintbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>(
                                                                {
                                                                    activeProductInvoiceLineItemId: pili.purchaseOrderLineItem.id,
                                                                }
                                                            )}`
                                                        );
                                                    })();
                                                    return;
                                            }
                                        }}
                                    >
                                        <td>{pili.purchaseOrderLineItem.product.description}</td>
                                        <td>{pili.purchaseOrderLineItem.partNumber ?? "(No part number found)"}</td>
                                        <td>{pili.purchaseOrderLineItem.product.supplier?.name ?? "N/A"}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            {/*On Catalog Database*/}
            {productsInDb.length < 1 ? (
                <></>
            ) : (
                <div style={{ marginTop: 10 }}>
                    <MobileTableTitle>On Catalog Database</MobileTableTitle>

                    <MobileTable ignoreSpacing>
                        <thead>
                            <tr>
                                <td>Description</td>
                                <td>Part #</td>
                                <td>Supplier</td>
                            </tr>
                        </thead>
                        <tbody>
                            {productsInDb.map((product, i) => {
                                return product.partNumbers.map((pn) => {
                                    return (
                                        <tr key={pn.partNumber} id={product.id} onClick={(e) => addProductToInvoice(e.currentTarget.id)}>
                                            <td>{product.description}</td>
                                            <td>{pn?.partNumber ?? "(Not found)"}</td>
                                            <td>{product.supplier?.name ?? "N/A"}</td>
                                        </tr>
                                    );
                                });
                            })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            <MobileButtonContainer>
                <MobileButton onClick={redirectToAddOrPrintBarcode}>Missing From Invoice And Database</MobileButton>
            </MobileButtonContainer>

            {createElement(confirmModal)}
        </>
    );
};

export default PurchaseInvoiceSearch;
