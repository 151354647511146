import { ForwardedRef, forwardRef } from "react";
import styles from "./MobileCheckbox.module.scss";

interface IProps {
    /** The id of the checkbox. */
    id: string;
    /** The text displayed next to the checkbox. */
    label: string;
    /** The name of the group of checkboxes. */
    name: string;
    /** Whether or not to have the value of the input as checked as default. */
    defaultChecked?: boolean;
}

const MobileCheckbox = forwardRef<HTMLInputElement, IProps>((props: IProps, ref?: ForwardedRef<HTMLInputElement>) => {
    return (
        <div key={props.id} className={styles.container}>
            <input name={props.name} ref={ref} defaultChecked={props.defaultChecked} type="checkbox" id={props.id} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
});

export default MobileCheckbox;
