import { useContext, useEffect, useRef, useState } from "react";
import { VSelect, ValidationFormContext } from "@shane32/vform";
import styles from "./VSpecialSelect.module.scss";

/** A special version of VSelect that allows the browser to use the native CTRL + F search functionality to find the currently selected value of the <select> element. */
function VSpecialSelect(props: {
    valueName: string;
    required?: boolean;
    options: { value: string; displayName: string }[];
    map?: Map<string, string>;
}) {
    useContext(ValidationFormContext); //if this is not called, the value of the select will not be set after the first render
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [val, setVal] = useState<string | undefined>("");
    const ref = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const selectedValue = ref.current?.querySelector<HTMLSelectElement>(".vselectval")?.value;
        if (props.map && selectedValue !== undefined) {
            const mappedValue = props.map.get(selectedValue) ?? "";
            setVal(mappedValue);
        }
    });

    return (
        <div style={{ position: "relative" }}>
            <div ref={ref}>
                <VSelect className={`${styles.vselect} vselectval`} valueName={props.valueName} required={props.required}>
                    <option value=""></option>
                    {props.options.map((o) => {
                        return (
                            <option key={o.value} value={o.value}>
                                {o.displayName}
                            </option>
                        );
                    })}
                </VSelect>
            </div>
            <div className={styles.selectElementTextContainer}>
                <div>{val}</div>
                <div></div>
            </div>
        </div>
    );
}

export default VSpecialSelect;
