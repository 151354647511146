import styles from "./MobileRadioContainer.module.scss";

interface IProps {
    children: React.ReactNode;
}

const MobileRadioContainer = (props: IProps) => {
    return <div className={styles.mobileRadioContainer}>{props.children}</div>;
};

export default MobileRadioContainer;
