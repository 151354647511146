import { Route, Switch } from "react-router-dom";
import AddOrPrintBarcodeContainer from "./AddOrPrintBarcodeContainer";
import ScanBarcode from "./ScanBarcode";
import SelectReturnNumber from "./SelectReturnNumber";
import SelectTrackingNumber from "./SelectTrackingNumber";

const ReturnsController = () => {
    return (
        <Switch>
            <Route exact path="/mobile/returns/selectreturnnumber">
                <SelectReturnNumber />
            </Route>
            <Route exact path="/mobile/returns/:purchaseOrderId/selecttrackingnumber">
                <SelectTrackingNumber />
            </Route>
            <Route exact path="/mobile/returns/:purchaseOrderId/:purchaseInvoiceId/scanbarcode">
                <ScanBarcode />
            </Route>
            <Route exact path="/mobile/returns/:purchaseOrderId/:purchaseInvoiceId/:productId/addorprintbarcode">
                <AddOrPrintBarcodeContainer />
            </Route>
        </Switch>
    );
};

export default ReturnsController;
