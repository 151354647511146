enum PackageType {
    Mailer = "MAILER",
    PaddedMailer = "PADDED_MAILER",
    Box = "BOX",
    Tube = "TUBE",
    Pallet = "PALLET",
    Prepackaged = "PREPACKAGED",
}

export default PackageType;
