import { useQuery } from "@shane32/graphql";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import useScanner from "./useScanner";

const productQuery = `
query ($productId: ID!) {
    product(id: $productId) {
      id
      sku
      locationProducts {
        location {
          name
        }
        stockOnHand
      }
    }
  }
`;

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
        locationProducts: Array<{
            location: {
                name: string;
            };
            stockOnHand: number;
        }>;
    };
}

interface IProductQueryVariables {
    productId: string;
}

interface ILocation {
    locationName: string;
    stockOnHand: number;
}

type ResolveType = (value: string | undefined | PromiseLike<string | undefined>) => void;

type BaseCallbackType = {
    modalTitle?: string;
    passedInValue?: string;
    infoText?: string;
    sku?: string;
};

type Variations = { productId?: string; locations?: never } | { productId?: never; locations?: Array<ILocation> };

type CallbackType = BaseCallbackType & Variations;

const useLocationInputModal = () => {
    const resolveRef = useRef<ResolveType>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [previousValue, setPreviousValue] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("");
    const [infoText, setInfoText] = useState<string>(""); //text to display above the input field
    const [productId, setProductId] = useState<string | undefined>(undefined); //if provided, searches for the locations of this product in Hive and displays them in the modal
    const [locations, setLocations] = useState<Array<ILocation>>([]); //if provided, displays the locations in the modal
    const [sku, setSku] = useState<string | undefined>(undefined); //if provided, displays the sku in the modal
    const skip = !productId || locations.length > 0;
    const { loading: productLoading, data: productData } = useQuery<IProductQueryResult, IProductQueryVariables>(productQuery, {
        variables: skip ? undefined : { productId },
        skip: skip,
        fetchPolicy: "no-cache",
    });

    const onScan = async (scannerValue: string) => {
        if (inputRef.current) inputRef.current.value = scannerValue;
    };
    useScanner(onScan);

    const showLocationInputModal = useCallback((callbackProps: CallbackType) => {
        setPreviousValue(callbackProps.passedInValue ?? "");
        setModalTitle(callbackProps.modalTitle ?? "");
        setInfoText(callbackProps.infoText ?? "");
        setProductId(callbackProps.productId);
        setLocations(callbackProps.locations ?? []);
        if (callbackProps.sku) setSku(callbackProps.sku);
        return new Promise<string | undefined>((resolve) => {
            resolveRef.current = resolve;
            setOpen(true);
        });
    }, []);

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = previousValue;
    }, [open, previousValue]);

    useEffect(() => {
        if (inputRef?.current && open) inputRef.current.focus();
    }, [open]);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef && resolveRef.current && inputRef && inputRef.current) {
            const value = inputRef.current.value;
            if (!value) return alert("Please enter a value!");
            resolveRef.current(value);
            setPreviousValue(value);
            setOpen(false);
        }
    };

    const onClickLocationNameHandler = async (locationName: string) => {
        if ("clipboard" in navigator) await navigator.clipboard.writeText(locationName);
        else document.execCommand("copy", true, locationName); //fallback for old browsers
        if (inputRef.current) inputRef.current.value = locationName;
    };

    function locationInputModal() {
        return (
            <>
                <div></div>
                <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <b>{modalTitle}</b>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="d-flex flex-column">
                        {infoText && <div>{infoText}</div>}
                        <input
                            ref={inputRef}
                            style={{
                                padding: 10,
                                alignSelf: "stretch",
                                height: 40,
                                borderRadius: 4,
                                border: "1px solid #798394",
                                marginRight: 10,
                                width: "100%",
                            }}
                        />
                    </Modal.Body>

                    <div style={{ display: "flex", flexDirection: "column", padding: "15px", borderTop: "1px solid lightgray", gap: 15 }}>
                        <div style={{ display: "flex", flex: 1, gap: 15 }}>
                            <button className="btn btn-primary" style={{ flex: 1 }} onClick={() => confirm()}>
                                <b>Confirm</b>
                            </button>
                            <button className="btn btn-secondary" style={{ flex: 1 }} onClick={() => cancel()}>
                                <b>Cancel</b>
                            </button>
                        </div>
                        {(productId || locations.length > 0) && (
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <b>Locations of {productData?.product?.sku ?? sku}</b>
                                <div style={{ display: "flex", flexDirection: "column", gap: 15, fontSize: 18 }}>
                                    {productLoading ? (
                                        <div>Loading...</div>
                                    ) : productData ? (
                                        (productData.product?.locationProducts ?? []).map((locationProduct) => {
                                            return (
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <b
                                                        onClick={async () =>
                                                            await onClickLocationNameHandler(locationProduct.location.name)
                                                        }
                                                    >
                                                        {locationProduct.location.name}
                                                    </b>
                                                    <div>{locationProduct.stockOnHand}</div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        (locations ?? []).map((locationProduct) => {
                                            return (
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <b onClick={async () => await onClickLocationNameHandler(locationProduct.locationName)}>
                                                        {locationProduct.locationName}
                                                    </b>
                                                    <div>{locationProduct.stockOnHand}</div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            </>
        );
    }

    return { locationInputModal, showLocationInputModal, open };
};

export default useLocationInputModal;
