import { Table, Row, Col, Card, Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { Info } from "../../../components/misc/Info";
import { useMutation, useQuery } from "@shane32/graphql";
import FormatDate from "../../../helpers/FormatDate";
import Rounding from "../../../helpers/Rounding";
import StringHelper from "../../../helpers/StringHelper";
import ProductImage from "../../../components/images/ProductImage";

interface IPurchaseOrderQueryResult {
    purchaseOrder: IPurchaseOrder;
}

interface IPurchaseOrder {
    id: string;
    notes: string | null;
    reference: string | null;
    subtotal: number;
    tax: number;
    total: number;
    orderDate: string | null;
    refSaleId: string | null;
    isReturn: boolean;
    isDropShip: boolean;
    supplier: {
        id: string;
        name: string | null;
    } | null;
    supplierContact: {
        id: string;
        name: string | null;
    } | null;
    supplierAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        country: {
            id: string;
            isoCode: string;
        } | null;
    } | null;
    shippingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        country: {
            id: string;
            isoCode: string;
        } | null;
    };
    invoices: Array<{
        id: string;
        invoiceNumber: string;
        status: string;
        invoiceDate: string;
        lineItems: Array<{
            id: string;
            purchaseOrderLineItemId: string;
            expectedQuantity: number;
            actualQuantity: number;
        }>;
        trackingNumbers: Array<{
            trackingNumber: string;
        }>;
    }>;
    lineItems: Array<{
        id: string;
        quantity: number;
        discount: number;
        supplierSku: string | null;
        total: number;
        unitPrice: number;
        partNumber: string;
        comment: string | null;
        description: string | null;
        isAdditionalCharge: boolean;
        unitOfMeasure: {
            id: string;
            abbreviation: string;
        };
        product: {
            id: string;
            sku: string;
        } | null;
    }>;
}

interface IPrinterQueryResult {
    printers: {
        items: Array<IPrinter>;
    };
}

interface IPrinter {
    id: string;
    description: string;
    type: string;
    active: boolean;
}

interface IDownloadResult {
    api: {
        print: {
            purchaseOrder: {
                download: string;
            };
        };
    };
}

interface IPrintResult {
    api: {
        print: {
            purchaseOrder: {
                print: boolean;
            };
        };
    };
}

interface IPrintVariables {
    purchaseOrderId: string;
    isIntake: boolean;
    printerId: string;
}

interface IDownloadVariables {
    purchaseOrderId: string;
    isIntake: boolean;
}

const PrintMutation = `
mutation ($purchaseOrderId: ID!, $isIntake: Boolean, $printerId: ID!) {
  api {
    print {
      purchaseOrder(purchaseOrderId: $purchaseOrderId, isIntake: $isIntake) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

const DownloadMutation = `
mutation ($purchaseOrderId: ID!, $isIntake: Boolean) {
  api {
    print {
      purchaseOrder(purchaseOrderId: $purchaseOrderId, isIntake: $isIntake) {
        download
      }
    }
  }
}
`;

const purchaseOrderQuery = `
query ($id: ID!) {
  purchaseOrder(id: $id) {
    id
    reference
    notes
    subtotal
    tax
    orderDate
    refSaleId
    isReturn
    isDropShip
    total
    supplier {
      id
      name
      contacts {
        id
        name
      }
      addresses {
        address {
          id
          city
          state
        }
      }
    }
    supplierContact {
      id
      name
    }
    supplierAddress {
      id
      address1
      address2
      city
      state
      zip
      country {
        id
        isoCode
      }
    }
    shippingAddress {
      id
      firstName
      lastName
      company
      address1
      address2
      city
      state
      zip
      phone
      country {
        id
        isoCode
      }
    }
    invoices {
      id
      status
      invoiceNumber
      invoiceDate
      lineItems {
        id
        purchaseOrderLineItemId
        expectedQuantity
        actualQuantity
      }
      trackingNumbers {
        trackingNumber
      }
    }
    lineItems {
      id
      quantity
      discount
      comment
      partNumber
      description
      unitPrice
      isAdditionalCharge
      supplierSku
      unitOfMeasure {
        id
        abbreviation
      }
      total
      product {
        id
        sku
      }
    }
  }
}
`;

const PrinterQuery = `
{
  printers {
    items {
      id
      description
      type
      active
    }
  }
}
`;

const PurchaseOrdersSummary = () => {
    const { id } = useParams<{ id: string }>();
    const {
        data: printerData,
        error: printerError,
        refetch: printerRefetch,
    } = useQuery<IPrinterQueryResult, {}>(PrinterQuery, { fetchPolicy: "no-cache" });
    const { data, error, refetch } = useQuery<IPurchaseOrderQueryResult, { id: string }>(purchaseOrderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [runPrint] = useMutation<IPrintResult, IPrintVariables>(PrintMutation);
    const [runDownload] = useMutation<IDownloadResult, IDownloadVariables>(DownloadMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (printerError) return <ErrorDisplay onClick={printerRefetch}>{printerError.message}</ErrorDisplay>;

    if (!data || !printerData) return <Loading />;
    if (!data.purchaseOrder) return <ErrorDisplay onClick={refetch}>Purchase Order ID #{id}</ErrorDisplay>;

    const onPrint = (modified: IPrinter) => {
        runPrint({
            variables: {
                purchaseOrderId: id,
                isIntake: false,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print purchaseOrder`, err);
                alert(err.message);
            }
        );
    };

    const onDownload = () => {
        runDownload({
            variables: {
                purchaseOrderId: id,
                isIntake: false,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.print.purchaseOrder.download;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `ZPO-${id} Receipt`;
                fileLink.click();
                window.open(fileURL);
            },
            (err) => {
                console.error(`Error trying to download purchaseOrder`, err);
                alert(err.message);
            }
        );
    };

    const onIntakePrint = (modified: IPrinter) => {
        runPrint({
            variables: {
                purchaseOrderId: id,
                isIntake: true,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print purchaseOrder`, err);
                alert(err.message);
            }
        );
    };

    const onIntakeDownload = () => {
        runDownload({
            variables: {
                purchaseOrderId: id,
                isIntake: true,
            },
        }).then(
            (ret) => {
                let returnedData = ret?.data?.api.print.purchaseOrder.download;
                if (returnedData) {
                    var byteCharacters = atob(returnedData);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/pdf;base64" });
                    var fileURL = URL.createObjectURL(file);
                    var fileLink = document.createElement(`a`);
                    fileLink.href = fileURL;
                    fileLink.download = `ZPO-${id} Intake`;
                    fileLink.click();
                    window.open(fileURL);
                }
            },
            (err) => {
                console.error(`Error trying to download purchaseOrder`, err);
                alert(err.message);
            }
        );
    };
    const lineItems = data.purchaseOrder.lineItems.filter((x) => !x.isAdditionalCharge);
    const isAdditionalCharges = data.purchaseOrder.lineItems.filter((x) => x.isAdditionalCharge) || "";
    const purchaseInvoiceLineItems = data.purchaseOrder.invoices.flatMap((x) => x.lineItems);
    const printers = printerData.printers.items.filter((x) => x.active === true && (x.type === "GENERIC" || x.type === "COLOR"));
    const shippingAddress = data.purchaseOrder.shippingAddress;
    const supplierAddress = data.purchaseOrder.supplierAddress;

    return (
        <>
            <div className="d-flex">
                <Dropdown className="mb-3 me-4">
                    <Dropdown.Toggle variant="primary">Print</Dropdown.Toggle>
                    <Dropdown.Menu variant="secondary">
                        {printers.map((printer) => (
                            <Dropdown.Item onClick={() => onPrint(printer)} key={printer.id} value={printer.id}>
                                {printer.description}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onDownload}>Download</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mb-3">
                    <Dropdown.Toggle variant="primary">Print (Intake)</Dropdown.Toggle>
                    <Dropdown.Menu variant="secondary">
                        {printers.map((printer) => (
                            <Dropdown.Item onClick={() => onIntakePrint(printer)} key={printer.id} value={printer.id}>
                                {printer.description}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onIntakeDownload}>Download</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Row>
                <Col lg={7} xxl={8}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">General</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Info label="Type">
                                        {data.purchaseOrder.isDropShip ? "Drop Ship" : data.purchaseOrder.isReturn ? "Return" : "Restock"}
                                    </Info>
                                    <Info label="Supplier">{data.purchaseOrder.supplier?.name || ""}</Info>
                                    <Info label="Vendor Address">
                                        {supplierAddress?.firstName || supplierAddress?.lastName ? (
                                            <>
                                                {supplierAddress?.firstName || ""} {supplierAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.address1 ? (
                                            <>
                                                {supplierAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.address2 ? (
                                            <>
                                                {supplierAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.city ? (
                                            <>
                                                {supplierAddress.city || ""}
                                                {supplierAddress?.state && shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.state ? (
                                            <>
                                                {supplierAddress?.state || ""}
                                                {supplierAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.zip ? (
                                            <>
                                                {supplierAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {supplierAddress?.country?.isoCode ? <>{supplierAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Order Date">
                                        {StringHelper.IsNullOrWhitespace(data?.purchaseOrder?.orderDate)
                                            ? ""
                                            : FormatDate.ConvertDateToLocalDateMMDDYYYY(data!.purchaseOrder!.orderDate)}
                                    </Info>
                                    <Info label="Reference">{data.purchaseOrder.reference || ""}</Info>
                                    <Info label="Shipping Address">
                                        {shippingAddress?.firstName || shippingAddress?.lastName ? (
                                            <>
                                                {shippingAddress?.firstName || ""} {shippingAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.company ? (
                                            <>
                                                {shippingAddress?.company || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address1 ? (
                                            <>
                                                {shippingAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address2 ? (
                                            <>
                                                {shippingAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.city ? (
                                            <>
                                                {shippingAddress.city || ""}
                                                {shippingAddress?.state && shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.state ? (
                                            <>
                                                {shippingAddress?.state || ""}
                                                {shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.zip ? (
                                            <>
                                                {shippingAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.phone ? (
                                            <>
                                                {shippingAddress?.phone || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.country?.isoCode ? <>{shippingAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Notes">{data.purchaseOrder?.notes || ""}</Info>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={5} xxl={4}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">Summary</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>${Rounding.EvenRound(data.purchaseOrder.subtotal).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td>${Rounding.EvenRound(data.purchaseOrder.tax).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Total</b>
                                        </td>
                                        <td>${Rounding.EvenRound(data.purchaseOrder.total).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white bg-gradient">Packages</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Status</th>
                                        <th>Qty / Products</th>
                                        <th>Tracking Number(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.purchaseOrder.invoices.map((invoice) => {
                                        return (
                                            <tr key={invoice.id}>
                                                <td>{invoice.invoiceNumber}</td>
                                                <td>{invoice.status}</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            {invoice.lineItems.map((invoiceLineItem) => {
                                                                return (
                                                                    <tr key={invoiceLineItem.id}>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            {invoiceLineItem.expectedQuantity} -&nbsp;
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                data.purchaseOrder.lineItems.find(
                                                                                    (lineItem) =>
                                                                                        lineItem.id ===
                                                                                        invoiceLineItem.purchaseOrderLineItemId
                                                                                )?.partNumber
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    {invoice.trackingNumbers.length > 0
                                                        ? invoice.trackingNumbers
                                                              .sort((a, b) => a.trackingNumber.localeCompare(b.trackingNumber))
                                                              .map((x, index) => <div key={x.trackingNumber}>{x.trackingNumber}</div>)
                                                        : ""}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white bg-gradient">Products & Services</Card.Header>
                <Card.Body>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Product</th>
                                <th>Supplier Sku</th>
                                <th>Comment</th>
                                <th className="text-center">Quantity</th>
                                <th className="text-center">Unit</th>
                                <th className="text-end">Price</th>
                                <th className="text-end">Discount</th>
                                <th className="text-end">Total</th>
                                <th className="text-end">Qty Received</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((lineItem) => {
                                const purchaseInvoiceLineItemsForOrderLineItem = purchaseInvoiceLineItems.filter(
                                    (x) => x.purchaseOrderLineItemId === lineItem.id
                                );
                                const receivedQuantity = purchaseInvoiceLineItemsForOrderLineItem.reduce((a, b) => a + b.actualQuantity, 0);

                                return (
                                    <tr key={lineItem.id}>
                                        <td style={{ width: 120, height: 100 }}>
                                            <ProductImage sku={lineItem.product?.sku} size={100} />
                                        </td>
                                        <td>
                                            {lineItem.product && lineItem.product.sku ? (
                                                <Link to={`/products/${lineItem.product.id}`}>{lineItem.product.sku}</Link>
                                            ) : (
                                                ""
                                            )}
                                            <br />
                                            {lineItem.product ? (
                                                lineItem.product.sku === null ? (
                                                    <Link to={`/products/${lineItem.product.id}`}>{lineItem.partNumber}</Link>
                                                ) : (
                                                    lineItem.partNumber || ""
                                                )
                                            ) : (
                                                ""
                                            )}
                                            <br />
                                            {lineItem.description}
                                        </td>
                                        <td>{lineItem.supplierSku}</td>
                                        <td>{lineItem.comment}</td>
                                        <td className="text-center">{lineItem.quantity}</td>
                                        <td className="text-center">{lineItem.unitOfMeasure.abbreviation}</td>
                                        <td className="text-end">
                                            {lineItem.unitPrice ? `$${Rounding.EvenRound(lineItem.unitPrice).toFixed(2)}` : ""}
                                        </td>
                                        <td className="text-end">{lineItem.discount}%</td>
                                        <td className="text-end">
                                            {Rounding.EvenRound(lineItem.total).toFixed(2)
                                                ? `$${Rounding.EvenRound(lineItem.total).toFixed(2)}`
                                                : ""}
                                        </td>
                                        <td className="text-center">
                                            <b>
                                                {receivedQuantity}/{lineItem.quantity}
                                            </b>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={8}>
                                    <br />
                                    Additional Charges
                                    {/*DESCRIPTION*/}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {isAdditionalCharges.map((additionalCharge) => (
                                <tr key={additionalCharge.id}>
                                    <td></td>
                                    <td>{additionalCharge.description || ""}</td>
                                    <td>{additionalCharge.supplierSku || ""}</td>
                                    <td>{additionalCharge.comment}</td>
                                    <td className="text-center">{additionalCharge.quantity}</td>
                                    <td className="text-center">{additionalCharge.unitOfMeasure.abbreviation}</td>
                                    <td className="text-end">
                                        {additionalCharge.unitPrice ? `$${Rounding.EvenRound(additionalCharge.unitPrice).toFixed(2)}` : ""}
                                    </td>
                                    <td className="text-end">{additionalCharge.discount}%</td>
                                    <td className="text-end">
                                        {Rounding.EvenRound(additionalCharge.total).toFixed(2)
                                            ? `$${Rounding.EvenRound(additionalCharge.total).toFixed(2)}`
                                            : ""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default PurchaseOrdersSummary;
