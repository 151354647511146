import SaleStatus from "../../../../../enums/SaleStatus";
import StationType from "../../../../../enums/StationTypes";

export interface IStationSet {
    id: number;
    name: string;
    /** specifically used to verify that there is an output station at the current station set */
    stations: Array<{
        id: string;
        type: StationType;
    }>;
    stashPickZone: {
        id: string;
        name: string;
        locations: ILocation[];
    };
    printers: Array<{
        id: string;
        description: string;
        type: string;
        stationSetId: string;
    }>;
}

export interface IProduct {
    productId: string;
    saleId: string | null;
    sale: {
        status: SaleStatus;
        salesChannel: {
            name: string;
        } | null;
        salesChannelReference: string | null;
    };
    stockOnHand: number;
    product: {
        description: string;
        sku: string;
        barcodes: Array<{
            barcode: string;
        }>;
    };
}

export interface ILocation {
    id: string;
    name: string;
    products: IProduct[];
}

export interface IPickZone {
    /** PickZoneId */
    id: string;
    name: string;
    isCart: boolean;
    locations: ILocation[];
}

export interface IPackStation {
    id: string;
    /** All of the carts at the station.
     *   Also includes locations specific to a packing station.
     *   THESE ARE PICKZONES!!!!!
     */
    carts: IPickZone[];
    stationSet: IStationSet;
}

export interface IStationQueryResult {
    station: IPackStation;
}

export interface IStationQueryVariables {
    stationId: string;
}

export const locationBase = `
id
name
products {
  productId
  saleId
  sale {
    status
    salesChannel {
      name
    }
    salesChannelReference
  }
  stockOnHand
  product {
    description
    sku
    barcodes {
      barcode
    }
  }
}
`;

const pickZoneBase = `
    id
    name
    isCart
    locations {
      ${locationBase}
    }
`;

export const stationQueryBase = `
  station(id: $stationId) {
    id
    carts {
      ${pickZoneBase}
    }
    stationSet {
      id
      name
      stations {
        id
        type
      }
      stashPickZone {
        ${pickZoneBase}
      }
      printers {
        id
        description
        type
        stationSetId
      }
    }
  }
`;

export const stationQuery = `
query ($stationId: ID!) {
  ${stationQueryBase}
}
`;
