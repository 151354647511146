import { ForwardedRef, forwardRef } from "react";
import styles from "./MobileInput.module.scss";

interface IProps {
    defaultValue?: string;
    id: string;
    name: string;
    onClick?: () => void;
    placeholder?: string;
}

const MobileInput = forwardRef<HTMLInputElement, IProps>((props: IProps, ref?: ForwardedRef<HTMLInputElement>) => {
    return (
        <input
            ref={ref}
            type="search" // Adds a clear button on the right side of the input field. Not supported in Firefox.
            onClick={props.onClick}
            defaultValue={props.defaultValue}
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            className={styles.inputElement}
        />
    );
});

export default MobileInput;
