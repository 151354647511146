import { Route, Switch, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import PurchaseOrderSummary from "./PurchaseOrderSummary";
import PurchaseOrderEdit from "./PurchaseOrderEdit";
import PurchaseOrderInvoices from "./PurchaseOrderInvoices";
import PurchaseOrderAssociatedOrders from "./PurchaseOrderAssociatedOrders";
import PurchaseOrderActivityLog from "./PurchaseOrderActivityLog";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useQuery } from "@shane32/graphql";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PurchaseOrderAttachments from "./PurchaseOrderAttachments";

interface IPurchaseOrderQueryResult {
    purchaseOrder: IPurchaseOrder;
}

interface IPurchaseOrder {
    id: string;
    status: string;
    invoices: Array<{
        id: string;
    }>;
}

const purchaseOrderQuery = `
query ($id: ID!) {
    purchaseOrder(id: $id) {
        id
        status
        invoices {
            id
        }
    }
}
`;

const PurchaseOrderDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { invoiceId } = useParams<{ invoiceId: string }>();

    const { data, error, refetch } = useQuery<IPurchaseOrderQueryResult, { id: string }>(purchaseOrderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (!data) return <Loading />;
    if (!data.purchaseOrder) return <ErrorDisplay onClick={refetch}>PurchaseOrder ID #{id}</ErrorDisplay>;

    return (
        <>
            <PageHeader badge={data.purchaseOrder.status}>{`ZPO-${data.purchaseOrder.id}`}</PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/purchaseorders/${id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/purchaseorders/${id}/edit`} exact>
                        <Nav.Link>Edit</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    {data.purchaseOrder.invoices.length > 0 ? (
                        <LinkContainer
                            to={`/purchaseorders/${id}/invoices/${invoiceId ? invoiceId : data.purchaseOrder.invoices[0].id}`}
                            exact
                        >
                            <Nav.Link>Invoices</Nav.Link>
                        </LinkContainer>
                    ) : (
                        <LinkContainer to={`/purchaseorders/${id}/invoices/0`} exact>
                            <Nav.Link>Invoices</Nav.Link>
                        </LinkContainer>
                    )}
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/purchaseorders/${id}/associatedorders`} exact>
                        <Nav.Link>Associated Orders</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/purchaseorders/${id}/attachments`} exact>
                        <Nav.Link>Attachments</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/purchaseorders/${id}/log`} exact>
                        <Nav.Link>Activity Log</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/purchaseorders/:id" exact component={PurchaseOrderSummary} />
                <Route path="/purchaseorders/:id/edit" exact component={PurchaseOrderEdit} />
                <Route path="/purchaseorders/:id/invoices/:invoiceId" exact component={PurchaseOrderInvoices} />
                <Route path="/purchaseorders/:id/associatedorders" exact component={PurchaseOrderAssociatedOrders} />
                <Route path="/purchaseorders/:id/attachments" exact component={PurchaseOrderAttachments} />
                <Route path="/purchaseorders/:id/log" exact component={PurchaseOrderActivityLog} />
            </Switch>
        </>
    );
};

export default PurchaseOrderDetails;
