import { createElement, useState } from "react";
import { GraphQLError, useMutation } from "@shane32/graphql";
import {
    addProductToPurchaseInvoiceMutation,
    IAddProductToPurchaseInvoiceMutationResponse,
    IAddProductToPurchaseInvoiceMutationVariables,
} from "../../graphs/mutations/AddProductToPurchaseInvoiceMutation";
import useConfirm from "../../hooks/useConfirm";
import MobileLoading from "../MobileLoading";
import MobileButton from "./MobileButton";
import MobileButtonContainer from "./MobileButtonContainer";

type TProps = {
    purchaseInvoiceNumber: string;
    purchaseInvoiceId: string;
    addOrPrintBarcodeRedirectFunc: (productId: string) => void;
};

const AddProductToPurchaseInvoiceButton = (props: TProps) => {
    const [runAddProductToPurchaseInvoiceMutation] = useMutation<
        IAddProductToPurchaseInvoiceMutationResponse,
        IAddProductToPurchaseInvoiceMutationVariables
    >(addProductToPurchaseInvoiceMutation);
    const { confirmModal, showConfirmModal } = useConfirm();
    const [loading, setLoading] = useState(false);

    const generateSku = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const confirmed = await showConfirmModal(
                `Are you sure you want to generate and add a new product to invoice ${props.purchaseInvoiceNumber}`,
                "Add New Product to Invoice?",
                { confirm: "Yes", cancel: "No" }
            );
            if (!confirmed) return;
            const response = await (
                await runAddProductToPurchaseInvoiceMutation({
                    variables: {
                        purchaseInvoiceId: props.purchaseInvoiceId,
                    },
                })
            ).data;
            const productId = response.purchaseInvoice.addProductToInvoice;
            props.addOrPrintBarcodeRedirectFunc(productId.toString());
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}
            <MobileButtonContainer>
                <MobileButton onClick={generateSku}>Add Product to Invoice</MobileButton>
            </MobileButtonContainer>
            {createElement(confirmModal)}
        </>
    );
};

export default AddProductToPurchaseInvoiceButton;
