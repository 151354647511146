import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import MobileRadio from "../components/radios/MobileRadio";
import MobileRadioContainer from "../components/radios/MobileRadioContainer";
import { GraphQLError, useMutation } from "@shane32/graphql";
import MobileLoading from "../components/MobileLoading";

const productQuery = `
query ($id: ID!) {
    product(id: $id) {
      id
      sku
    }
  }
`;

interface IProductQueryVariables {
    id: string;
}

interface IProduct {
    id: string;
    sku: string;
}

interface IProductQueryResponse {
    product: IProduct;
}

const locationQuery = `
query ($id: ID!) {
    location(id: $id) {
      id
      name
      warehouseId
    }
  }
`;

interface ILocationQueryVariables {
    id: string;
}

interface ILocationQueryResult {
    location: ILocation;
}

interface ILocation {
    id: string;
    name: string;
    warehouseId: string;
}

interface IState {
    ids: string[];
    modalTitle: string;
    selectedRadioId: string | undefined;
    dataType: MultiMatchDataType;
}

interface IResult {
    id: string;
    name: string;
}

export enum MultiMatchDataType {
    Product = "Product",
    Location = "Location",
}

type ResolveType = (value: IResult | undefined | PromiseLike<IResult | undefined>) => void;

const useMultiMatchSelectorModal = () => {
    const resolveRef = useRef<ResolveType>();
    const formRef = useRef<HTMLFormElement>(null);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState<IState>({
        selectedRadioId: undefined,
        modalTitle: "",
        ids: [],
        dataType: MultiMatchDataType.Product,
    });
    const [results, setResults] = useState<IResult[]>([]);

    const [manualLoading, setManualLoading] = useState(false);
    const [runProductQuery] = useMutation<IProductQueryResponse, IProductQueryVariables>(productQuery);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);

    const showMultiMatchSelectorModal = useCallback(
        (title: string, ids: string[], dataType: MultiMatchDataType, defaultSelectedId?: string) => {
            setState({ ...state, modalTitle: title, ids: ids, selectedRadioId: defaultSelectedId, dataType: dataType });
            return new Promise<IResult | undefined>((resolve) => {
                resolveRef.current = resolve;
                setOpen(true);
            });
        },
        [state]
    );

    useEffect(() => {
        if (formRef?.current && open && state.ids.length > 0) {
            (async () => {
                //fetch products
                try {
                    setManualLoading(true);
                    let results: IResult[] = [];
                    for (const id of state.ids) {
                        switch (state.dataType) {
                            case MultiMatchDataType.Product:
                                const r1 = await runProductQuery({ variables: { id: id } });
                                results.push({
                                    id: r1.data.product.id,
                                    name: r1.data.product.sku,
                                });
                                break;
                            case MultiMatchDataType.Location:
                                const r2 = await runLocationQuery({ variables: { id: id } });
                                results.push({
                                    id: r2.data.location.id,
                                    name: r2.data.location.name,
                                });
                                break;
                        }
                    }
                    setResults(results);
                } catch (e: any) {
                    alert((e as GraphQLError)?.message ?? (e as Error)?.message ?? "Failed to fetch products.");
                } finally {
                    setManualLoading(false);
                }
            })();
        }
    }, [open, state.ids]); //eslint-disable-line react-hooks/exhaustive-deps

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef && resolveRef.current && formRef && formRef.current) {
            const selectRadios = formRef.current.getElementsByTagName("input");
            const selectId = Array.from(selectRadios).find((x) => x.checked)?.value;
            if (!selectId) return alert("Please select a value!");
            const value = results.find((x) => x.id === selectId);
            if (!value) return alert("No matching values found!");
            resolveRef.current(value);
            setOpen(false);
        }
    };

    function multiMatchSelectorModal() {
        return (
            <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{state.modalTitle}</b>
                    </Modal.Title>
                </Modal.Header>
                {manualLoading ? (
                    <div style={{ width: "100%", height: 100, padding: 15, display: "flex", justifyContent: "center" }}>
                        <MobileLoading />
                    </div>
                ) : (
                    <>
                        <Modal.Body className="d-flex flex-column">
                            <form ref={formRef}>
                                <MobileRadioContainer>
                                    {results.map((result) => {
                                        return (
                                            <MobileRadio
                                                defaultChecked={result.id === state.selectedRadioId}
                                                id={result.id}
                                                key={result.id}
                                                label={result.name}
                                                name={"selectValue"}
                                                onClick={() => void 0}
                                                value={result.id}
                                            />
                                        );
                                    })}
                                </MobileRadioContainer>
                            </form>
                        </Modal.Body>
                        <Modal.Footer className="flex-nowrap">
                            <button className="btn btn-primary" style={{ width: "50%" }} onClick={() => confirm()}>
                                <b>Confirm</b>
                            </button>
                            <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                                <b>Cancel</b>
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        );
    }

    return { multiMatchSelectorModal, showMultiMatchSelectorModal, open };
};

export default useMultiMatchSelectorModal;
