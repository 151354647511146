import { useQuery } from "@shane32/graphql";
import StationSetType from "../../../enums/StationSetTypes";
import StationType from "../../../enums/StationTypes";
import { ISearchBarcode } from "../graphs/queries/SearchBarcodesQuery";
import ModelType from "../../../enums/ModelType";

interface IStationSetsQueryResult {
    stationSets: {
        items: IStationSet[] | null;
    };
}

interface IStationSet {
    type: StationSetType;
    name: string;
    warehouseId: string;
    stations: IStation[];
}

interface IStation {
    type: StationType;
    carts: IPickZone[]; //for some reason, the pick zones are called carts, but not filtered in any way
}

interface IPickZone {
    lastLocation: ILastLocation | null;
    id: string;
    name: string;
    isCart: boolean;
    locations: ILocation[];
}

interface ILastLocation {
    name: string;
}

interface ILocation {
    id: string;
    name: string;
    products: ILocationProductMapping[];
}

interface ILocationProductMapping {
    stockOnHand: number;
    saleId: string | null;
}

const stationSetsQuery = `
{
    stationSets {
      items {
        type
        name
        warehouseId
        stations {
          type
          carts {
            lastLocation {
              name
            }
            id
            name
            isCart
            locations {
              id
              name
              products {
                stockOnHand
                saleId
              }
            }
          }
        }
      }
    }
  }
`;

interface IAvailableCart {
    stationSet: {
        type: IStationSet["type"];
        name: IStationSet["name"];
    };
    pickZoneId: IPickZone["id"];
    pickZoneName: IPickZone["name"];
    isCart: IPickZone["isCart"];
    locations: IPickZone["locations"];
    lastLocation: IPickZone["lastLocation"];
    stationType: IStation["type"];
}

const useAvailableCarts = (warehouseId: string | null | undefined) => {
    const { data, loading } = useQuery<IStationSetsQueryResult>(stationSetsQuery, {
        fetchPolicy: "no-cache",
        skip: !warehouseId,
    });

    const availableCarts = (data?.stationSets?.items ?? [])
        .filter((x) => x.warehouseId === warehouseId)
        .flatMap((stationSet) => {
            return stationSet.stations.flatMap((station) => {
                return station.carts.flatMap((pickZone) => {
                    return {
                        stationSet: {
                            type: stationSet.type,
                            name: stationSet.name,
                        },
                        pickZoneId: pickZone.id,
                        pickZoneName: pickZone.name,
                        isCart: pickZone.isCart,
                        locations: pickZone.locations,
                        lastLocation: pickZone.lastLocation,
                        stationType: station.type,
                    } as IAvailableCart;
                });
            });
        })
        .filter((pickZone) => {
            const locationProductMappings = pickZone.locations.flatMap((location) => location.products);
            return (
                pickZone.isCart &&
                (locationProductMappings.length < 1 ||
                    locationProductMappings.every(
                        (locationProductMapping) => !locationProductMapping.saleId || locationProductMapping.saleId === "0"
                    ) ||
                    (locationProductMappings.length > 0 && pickZone.stationSet.type !== StationSetType.Pack) ||
                    (locationProductMappings.length > 0 &&
                        pickZone.stationSet.type === StationSetType.Pack &&
                        pickZone.stationType === StationType.Output))
            );
        });

    return { availableCarts, availableCartsLoading: loading };
};

export default useAvailableCarts;

/** Helper function to find valid carts amongst the available carts based off of what the user scanned */
export const findValidCart = (availableCarts: IAvailableCart[], searchBarcodeResults: ISearchBarcode[], value: string) => {
    const matchingScannedPickZones = searchBarcodeResults.filter((x) => x.type === ModelType.PickZone);
    const matchingScannedLocations = searchBarcodeResults.filter((x) => x.type === ModelType.Location);
    const matchingScannedValues = matchingScannedPickZones.concat(matchingScannedLocations);
    if (matchingScannedValues.length < 1) return alert(`Found 0 pick zones or locations matching value ${value}.`);
    if (matchingScannedValues.length > 1)
        return alert(`Found ${matchingScannedValues.length} pick zones or locations matching value ${value}.`);
    let validCart: IAvailableCart | undefined = undefined;
    if (matchingScannedPickZones.length === 1) {
        validCart = availableCarts.find((cart) => cart.pickZoneId === matchingScannedPickZones[0].id);
    } else if (matchingScannedLocations.length === 1) {
        const pickZoneId = availableCarts.find((cart) =>
            cart.locations.map((x) => x.id).includes(matchingScannedLocations[0].id)
        )?.pickZoneId;
        validCart = availableCarts.find((cart) => cart.pickZoneId === pickZoneId);
    }
    return validCart;
};
