import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import SupplierSummary from "./SupplierSummary";
import SupplierPurchaseOrder from "./SupplierPurchaseOrder";
import SupplierProducts from "./SupplierProducts";
import PageHeader from "../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import SupplierWarehouses from "./SupplierWarehouses";

interface IPaymentTermQueryResult {
    paymentTerms: {
        items: Array<IPaymentTerm>;
    };
}

interface ISupplierQueryResult {
    supplier: ISupplier | null;
}

interface IPaymentTerm {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: string;
    active: boolean;
}

interface IEditResult {
    supplier: {
        edit: ISupplier;
    };
}

interface IEditVariables {
    original: ISupplier;
    modified: ISupplier;
}

interface IDeleteResult {
    supplier: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: ISupplier;
}

const hiddenModal: IModal = {
    show: false,
};

function sortPaymentTerm(a: IPaymentTerm, b: IPaymentTerm) {
    return a.name > b.name ? 1 : -1;
}

const SupplierDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<ISupplierQueryResult, { id: string }>(
        "query($id: ID!){ supplier(id: $id){ id name defaultPaymentTermId active }}",
        { variables: { id: id }, fetchPolicy: "no-cache" }
    );
    const {
        data: paymentTermData,
        error: paymentTermError,
        refetch: runRefetchPaymentTermList,
    } = useQuery<IPaymentTermQueryResult, {}>("{ paymentTerms { items { id name sortOrder active } } }", { fetchPolicy: "no-cache" });
    const history = useHistory();

    const [runEdit] = useMutation<IEditResult, IEditVariables>(
        "mutation($original: SupplierInput!, $modified: SupplierInput!) { supplier { edit(original: $original, modified: $modified) { id name defaultPaymentTermId active } } }"
    );
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { supplier { delete (id: $id) } }");

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (paymentTermError) return <ErrorDisplay onClick={runRefetchPaymentTermList}>{paymentTermError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data || !paymentTermData) return <Loading />;

    if (!data.supplier) return <ErrorDisplay onClick={refetch}>Supplier ID {id} could not be found</ErrorDisplay>;

    const paymentTerms = paymentTermData.paymentTerms.items.filter((paymentTerm) => paymentTerm.active === true).sort(sortPaymentTerm);

    const originalModel: ISupplier = {
        id: data.supplier.id,
        name: data.supplier.name,
        defaultPaymentTermId: data.supplier.defaultPaymentTermId,
        active: data.supplier.active,
    };

    const onSaveChanges = (modified: ISupplier) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    name: originalModel.name,
                    defaultPaymentTermId: originalModel.defaultPaymentTermId,
                    active: originalModel.active,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    name: modified.name,
                    defaultPaymentTermId: modified.defaultPaymentTermId,
                    active: modified.active,
                },
            },
        }).then(
            (ret) => {
                data.supplier = ret.data.supplier.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing Supplier", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        const id = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this supplier?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main supplier page
                setSaving(false);
                let path = `/suppliers`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Supplier", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                {originalModel.name}
            </PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/suppliers/${id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/suppliers/${id}/products`} exact>
                        <Nav.Link>Products</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/suppliers/${id}/purchaseOrders`} exact>
                        <Nav.Link>Orders</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/suppliers/${id}/warehouses`} exact>
                        <Nav.Link>Warehouses</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/suppliers/:id" exact component={SupplierSummary} />
                <Route path="/suppliers/:id/purchaseorders" exact component={SupplierPurchaseOrder} />
                <Route path="/suppliers/:id/products" exact component={SupplierProducts} />
                <Route path="/suppliers/:id/warehouses" exact component={SupplierWarehouses} />
            </Switch>
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Edit Supplier</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="supplierDefaultPaymentTerm">
                                    <VLabel valueName="defaultPaymentTermId">Default Payment Term</VLabel>
                                    <VSelect valueName="defaultPaymentTermId" required>
                                        <option value=""></option>
                                        {
                                            //Query from paymentTerm database by ID and display by name
                                            paymentTerms.map((paymentTerm) => (
                                                <option value={paymentTerm.id} key={paymentTerm.id}>
                                                    {paymentTerm.name}
                                                </option>
                                            ))
                                        }
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SupplierDetails;
