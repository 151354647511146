import { createElement, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import useSelectModal, { ISelect } from "./useSelectModal";
import ModelType from "../../../enums/ModelType";
import { GraphQLError, useMutation } from "@shane32/graphql";
import useConfirm from "./useConfirm";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../graphs/queries/SearchBarcodesQuery";
import MobileLoading from "../components/MobileLoading";
import styles from "./useQuickIntakeModal.module.scss";
import SelectionType from "../../../enums/SelectionType";
import { useLocation } from "react-router-dom";

const binAdjustmentMutation = `
mutation ($locationId: ID!, $productId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!) {
    inventory {
        binAdjustment(locationId: $locationId, productId: $productId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
    }
}
`;

interface IBinAdjustmentMutationVariables {
    locationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

const locationQuery = `
query ($locationId: ID!) {
  location(id: $locationId) {
    id
    name
    warehouse {
      name
    }
  }
}
`;

interface ILocationQueryVariables {
    locationId: string;
}

interface ILocation {
    id: string;
    name?: string;
    warehouse?: {
        name?: string;
    };
}

interface ILocationQueryResult {
    location: ILocation;
}

interface IProduct {
    id: string;
    sku: string;
}

type ResolveType = (value: boolean | undefined | PromiseLike<boolean | undefined>) => void;

const useQuickIntakeModal = () => {
    const location = useLocation();
    const resolveRef = useRef<ResolveType>();
    const locationInputRef = useRef<HTMLInputElement>(null);
    const quantityInputRef = useRef<HTMLInputElement>(null);
    const mounted = useRef<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [product, setProduct] = useState<IProduct | undefined>(undefined);
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const [runBinAdjustmentMutation] = useMutation<{}, IBinAdjustmentMutationVariables>(binAdjustmentMutation);
    const { selectModal, showSelectModal } = useSelectModal();
    const { confirmModal, showConfirmModal } = useConfirm();

    const showQuickIntakeModal = useCallback((product: IProduct) => {
        if (product) setProduct(product);
        return new Promise<boolean | undefined>((resolve) => {
            resolveRef.current = resolve;
            setOpen(true);
        });
    }, []);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    useEffect(() => {
        if (locationInputRef?.current && open) locationInputRef.current.focus();
        if (quantityInputRef?.current && open) quantityInputRef.current.value = "1";
    }, [open]);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(false);
            setOpen(false);
        }
    };

    const confirm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (resolveRef && resolveRef.current && locationInputRef.current && quantityInputRef.current) {
            if (!product?.id) return;
            const locationValue = locationInputRef.current.value.trim();
            const quantityValue = quantityInputRef.current.value.trim();
            if (!locationValue) return alert("Please enter a location!");
            if (!quantityValue) return alert("Please enter a quantity!");
            if (isNaN(+quantityValue)) throw new Error("Quantity must be a number.");
            //search Hive to make sure location exists
            try {
                setManualLoading(true);
                let locationId: string | undefined = undefined;
                const res = await runSearchBarcodes({ variables: { search: locationValue, userEntered: true } });
                const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
                if (barcodeLocations.length > 0) {
                    if (barcodeLocations.length > 1) {
                        if (
                            !(await showConfirmModal(
                                `There are ${barcodeLocations.length} locations matching ${locationValue}. Please click 'Continue' to load all of the locations data (The more matching locations, the longer the query will take).`,
                                "Multiple Locations Found!",
                                { confirm: "Continue", cancel: "Cancel" }
                            ))
                        )
                            return;

                        let locations: ILocation[] = [];
                        for (let bl of barcodeLocations) {
                            if (mounted.current) {
                                const location = (await runLocationQuery({ variables: { locationId: bl.id } })).data.location;
                                locations.push(location);
                            }
                        }

                        if (locations.length < 1) {
                            await showConfirmModal("No locations found. Please try again!", "No Locations Found!", undefined, true);
                            return;
                        }

                        locationId = (
                            await showSelectModal(
                                "Select Location",
                                locations
                                    .filter((x) => x.name && x.warehouse?.name)
                                    .sort((a, b) => (b.name ?? "").localeCompare(a.name ?? ""))
                                    .map((x) => ({ description: `${x.name ?? ""} (${x.warehouse?.name ?? ""})`, id: x.id } as ISelect))
                            )
                        )?.id;
                    } else locationId = barcodeLocations[0].id;
                } else await showConfirmModal(`No locations found matching value ${locationValue}`, "No Locations Found!", undefined, true);
                if (!locationId) return;

                //product has already been searched at this point, so now we need to stock take the product to the location with the provided quantity
                await runBinAdjustmentMutation({
                    variables: {
                        locationId: locationId,
                        productId: product.id,
                        quantity: +quantityValue,
                        locationSelectionType: SelectionType.Searched,
                        productSelectionType: SelectionType.Searched,
                    },
                });

                resolveRef.current(true);
                setOpen(false);
            } catch (error: any) {
                await showConfirmModal((error as GraphQLError)?.message ?? (error as Error)?.message ?? "Error", "Error!", undefined, true);
                return;
            } finally {
                setManualLoading(false);
            }
        }
    };

    const onKeyDownInputTypeNumberHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const acceptedKeys = ["Backspace", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Delete"];
        if (isNaN(+e.key) && acceptedKeys.indexOf(e.key) < 0) {
            e.preventDefault();
        }
        if (e.key === "0" && !e.currentTarget.value) {
            e.preventDefault();
            e.currentTarget.value = "1";
        }
    };

    function quickIntakeModal() {
        return (
            <>
                <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }}>
                    <form onSubmit={async (e) => await confirm(e)} style={{ position: "relative" }}>
                        {manualLoading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 1,
                                    backgroundColor: "rgba(0,0,0,0.1)",
                                }}
                            >
                                <MobileLoading />
                            </div>
                        )}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b style={{ fontSize: 16 }}>Add {product?.sku} to Location</b>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: 100, display: "flex", alignItems: "center", marginRight: 10 }}>Location</div>
                                <input
                                    ref={locationInputRef}
                                    style={{
                                        padding: 10,
                                        alignSelf: "stretch",
                                        height: 40,
                                        borderRadius: 4,
                                        border: "1px solid #798394",
                                        marginRight: 10,
                                        width: "100%",
                                    }}
                                />
                                <div style={{ width: 50, display: "flex", alignItems: "center", marginRight: 5 }}>Qty</div>
                                <input
                                    ref={quantityInputRef}
                                    className={styles.inputTypeNumber}
                                    type="number"
                                    min={1}
                                    defaultValue={1}
                                    onKeyDown={(e) => onKeyDownInputTypeNumberHandler(e)}
                                    onFocus={(e) =>
                                        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                                    }
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer className="flex-nowrap">
                            <button className="btn btn-primary" style={{ width: "50%" }} type="submit">
                                <b>Confirm</b>
                            </button>
                            <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                                <b>Cancel</b>
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {createElement(selectModal)}

                {createElement(confirmModal)}
            </>
        );
    }

    return { quickIntakeModal, showQuickIntakeModal, open };
};

export default useQuickIntakeModal;
