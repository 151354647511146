import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import PageHeader from "../../../components/pageheader/PageHeader";
import {
    IBulkUpdateStockSubscriptionResponse,
    IUpdateStockSubscriptionBody,
    IUpdateSupplierStockSubscriptionResponse,
    updateStockByExcelSubscription,
    updateSupplierStock,
} from "../../../subscriptions/RecalculatingStock";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import { VButton, VControl, VForm, VLabel } from "@shane32/vform";

interface IBulkBarcodeEditMutation {
    api: {
        importExport: {
            import: {
                importProductBarcodes: boolean;
            };
        };
    };
}

interface IImportVariables {
    base64: string;
}

interface IExportBase<T> {
    api: {
        importExport: {
            export: T;
        };
    };
}

interface IImportBase<T> {
    api: {
        importExport: {
            import: T;
        };
    };
}

enum ReportType {
    Export = "Export",
    History = "History",
}

interface IRestockInput {
    daysToProject: string;
    baseDaysLeadTime: string;
    type: ReportType;
}

interface IModal {
    show: boolean;
    input?: IRestockInput;
}

interface IExportBarcodeLocationsResult extends IExportBase<{ locationBarcodes: string }> {}
interface IExportProductDetailsResult extends IExportBase<{ productDetails: string }> {}
interface IExportProductAvailabilitiesResult extends IExportBase<{ productAvailabilities: string }> {}
interface IExportRunExportMonthlySalesResult extends IExportBase<{ monthlySales: string }> {}
interface IExportRestockReportResult extends IExportBase<{ restockReport: string }> {}
interface IExportRestockReportHistoryResult extends IExportBase<{ restockReportHistory: string }> {}
interface IBulkImportSalesChannelPricesMutationResult extends IImportBase<{ importSalesChannelPrice: string }> {}
interface IImportAmazonFulfillmentCentersMutationResult extends IImportBase<{ importAmazonFulfillmentCenters: boolean }> {}
interface IImportCountriesMutationResult extends IImportBase<{ importCountries: boolean }> {}
//interface IImportAmazonVendorShipmentsMutationResult extends IImportBase<{ importAmazonVendorShipments: boolean }> {};
interface IImportAmazonOrdersMutationResult extends IImportBase<{ importAmazonOrders: { id: string } }> {}
interface IImportAmazonFbaOrdersMutationResult extends IImportBase<{ importAmazonFbaOrders: { id: string } }> {}
interface IImportAmazonFbaStockMutationResult extends IImportBase<{ importAmazonFbaStock: boolean }> {}
interface IImportZipCodeZonesMutationResult extends IImportBase<{ importZipCodeZones: boolean }> {}
interface IImportSalesChannelReturnReasonsMutationResult extends IImportBase<{ importSalesChannelReturnReasons: boolean }> {}
interface IImportBulkUpdateProductsMutationResult extends IImportBase<{ importBulkUpdateProducts: boolean }> {}
interface IBulkPurchaseOrderStatusChangeToOrderedMutationResult extends IImportBase<{ bulkPurchaseOrderStatusChangeToOrdered: boolean }> {}

const ExportProductAvailabilitiesMutation = `
mutation {
  api {
    importExport {
      export {
        productAvailabilities
      }
    }
  }
}
`;

const ExportMonthlySalesMutation = `
mutation($year: Int!) {
  api {
    importExport {
      export {
        monthlySales(year: $year)
      }
    }
  }
}
`;

const ExportRestockReportMutation = `
mutation($daysToProject: Int!, $baseDaysLeadTime: Int) {
    api {
        importExport {
            export {
                restockReport(daysToProject: $daysToProject, baseDaysLeadTime: $baseDaysLeadTime)
            }
        }
    }
}
`;

const ExportRestockReportHistoryMutation = `
mutation($daysToProject: Int!, $baseDaysLeadTime: Int) {
    api {
        importExport {
            export {
                restockReportHistory(daysToProject: $daysToProject, baseDaysLeadTime: $baseDaysLeadTime)
            }
        }
    }
}
`;

//bulkEditBarcode query
const BulkBarcodeEditMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        importProductBarcodes(base64: $base64)
      }
    }
  }
}
`;

//barcodeLocation mutation
const BarcodeLocationMutation = `
mutation {
  api {
    importExport {
      export {
        locationBarcodes
      }
    }
  }
}
`;

const ProductDetailsMutation = `
mutation {
  api {
    importExport {
      export {
        productDetails
      }
    }
  }
}
`;

const ImportAmazonOrdersMutation = `
mutation ($file1: String!, $file2: String!) {
  api {
    importExport {
      import {
        importAmazonOrders(amazonExportFileBase64: $file1, amazonXrefFileBase64: $file2) {
          id
        }
      }
    }
  }
}
`;

const ImportAmazonFbaOrdersMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        importAmazonFbaOrders(base64: $base64) {
          id
        }
      }
    }
  }
}
`;

const ImportAmazonFbaStockMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        importAmazonFbaStock(base64: $base64)
      }
    }
  }
}
`;

//import sale channel prices
const BulkImportSalesChannelPricesMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        importSalesChannelPrice(base64: $base64)
      }
    }
  }
}
`;

const BulkPurchaseOrderStatusChangeToOrderedMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        bulkPurchaseOrderStatusChangeToOrdered(base64: $base64)
      }
    }
  }
}
`;

//import bulk product edit
const ImportBulkUpdateProductsMutation = `
mutation ($base64: String!) {
  api {
    importExport {
      import {
        importBulkUpdateProducts(base64: $base64)
      }
    }
  }
}
`;

const ImportAmazonFulfillmentCentersMutation = `
mutation($base64: String!) {
    api {
      importExport {
        import {
          importAmazonFulfillmentCenters(base64: $base64)
        }
      }
    }
}
`;

const ImportCountriesMutation = `
mutation($base64: String!) {
    api {
      importExport {
        import {
          importCountries(base64: $base64)
        }
      }
    }
}
`;

const ImportZipCodeZonesMutation = `
mutation($base64: String!, $shippingServiceId: ID!) {
    api {
      importExport {
        import {
          importZipCodeZones(base64: $base64, shippingServiceId: $shippingServiceId)
        }
      }
    }
}
`;

const ImportSalesChannelReturnReasonsMutation = `
mutation($base64: String!, $salesChannelId: ID!) {
    api {
      importExport {
        import {
          importSalesChannelReturnReasons(base64: $base64, salesChannelId: $salesChannelId)
        }
      }
    }
}
`;

const shippingServicesQuery = `
query {
    shippingServices {
      items {
        id
        name
        active
        shippingCarrier {
          name
        }
      }
    }
  }
`;

interface IShippingServicesQueryResult {
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
}

const salesChannelQuery = `
query {
    salesChannels {
      items {
        id
        name
        active
      }
    }
  }
`;

interface ISalesChannelsQueryResult {
    salesChannels: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
        }>;
    };
}

// const ImportAmazonVendorShipmentsMutation = `
// mutation($base64: String!) {
//     api {
//       importExport {
//         import {
//           importAmazonVendorShipments(base64: $base64)
//         }
//       }
//     }
// }
// `;

const convertBase64 = async (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            if (fileReader.result) {
                //remove the metadata at the beginning
                let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
                if (encoded.length % 4 > 0) {
                    encoded += "=".repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            }
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const BulkUtilitiesIndex = () => {
    const graphQLContext = useContext(GraphQLContext);
    const [saving, setSaving] = React.useState(false);
    const [inputAmazonFile1, setInputAmazonFile1] = React.useState<File | null>(null);
    const [inputAmazonFile2, setInputAmazonFile2] = React.useState<File | null>(null);
    const [inputAmazonFbaOrdersFile, setInputAmazonFbaOrdersFile] = React.useState<File | null>(null);
    const [inputZipCodeZonesFile, setInputZipCodeZonesFile] = React.useState<File | null>(null);
    const [inputZipCodeZonesShippingServiceId, setInputZipCodeZonesShippingServiceId] = React.useState<string | null>(null);
    const [inputReturnReasonsFile, setInputReturnReasonsFile] = React.useState<File | null>(null);
    const [inputReturnReasonsSalesChannelId, setInputReturnReasonsSalesChannelId] = React.useState<string | null>(null);
    const [runBulkUpdateProductsMutation] = useMutation<IImportBulkUpdateProductsMutationResult, IImportVariables>(
        ImportBulkUpdateProductsMutation
    );
    const [runBulkPurchaseOrderStatusChangeToOrderedMutation] = useMutation<
        IBulkPurchaseOrderStatusChangeToOrderedMutationResult,
        IImportVariables
    >(BulkPurchaseOrderStatusChangeToOrderedMutation);
    const [runBarcodeEditMutation] = useMutation<IBulkBarcodeEditMutation, IImportVariables>(BulkBarcodeEditMutation);
    const [runProductDetailsMutation] = useMutation<IExportProductDetailsResult, {}>(ProductDetailsMutation);
    const [runBarcodeLocationMutation] = useMutation<IExportBarcodeLocationsResult, {}>(BarcodeLocationMutation);
    const [runImportAmazonOrdersMutation] = useMutation<IImportAmazonOrdersMutationResult, {}>(ImportAmazonOrdersMutation);
    const [runImportAmazonFbaOrdersMutation] = useMutation<IImportAmazonFbaOrdersMutationResult, {}>(ImportAmazonFbaOrdersMutation);
    const [runImportAmazonFbaStockMutation] = useMutation<IImportAmazonFbaStockMutationResult, {}>(ImportAmazonFbaStockMutation);
    const [runImportZipCodeZonesMutation] = useMutation<IImportZipCodeZonesMutationResult, {}>(ImportZipCodeZonesMutation);
    const [runImportSalesChannelReturnReasonsMutation] = useMutation<IImportSalesChannelReturnReasonsMutationResult, {}>(
        ImportSalesChannelReturnReasonsMutation
    );
    const [runBulkImportSalesChannelPricesMutation] = useMutation<IBulkImportSalesChannelPricesMutationResult, IImportVariables>(
        BulkImportSalesChannelPricesMutation
    );
    const [runExportProductAvailabilitiesMutation] = useMutation<IExportProductAvailabilitiesResult, {}>(
        ExportProductAvailabilitiesMutation
    );
    const [runExportMonthlySalesMutation] = useMutation<IExportRunExportMonthlySalesResult, { year: number }>(ExportMonthlySalesMutation);
    const [runExportRestockReportMutation] = useMutation<IExportRestockReportResult, {}>(ExportRestockReportMutation);
    const [runExportRestockReportHistoryMutation] = useMutation<IExportRestockReportHistoryResult, {}>(ExportRestockReportHistoryMutation);
    const [runImportAmazonFulfillmentCentersMutation] = useMutation<IImportAmazonFulfillmentCentersMutationResult, {}>(
        ImportAmazonFulfillmentCentersMutation
    );
    const [runImportCountriesMutation] = useMutation<IImportCountriesMutationResult, {}>(ImportCountriesMutation);
    // const [runImportAmazonVendorShipmentsMutation] = useMutation<IImportAmazonVendorShipmentsMutationResult, {}>(
    //     ImportAmazonVendorShipmentsMutation
    // );

    const { data, loading, error, refetch } = useQuery<IShippingServicesQueryResult>(shippingServicesQuery, {
        fetchPolicy: "no-cache",
    });
    const {
        data: salesChannelData,
        loading: salesChannelLoading,
        error: salesChannelError,
        refetch: salesChannelRefetch,
    } = useQuery<ISalesChannelsQueryResult>(salesChannelQuery, {
        fetchPolicy: "no-cache",
    });
    if (!data || !salesChannelData || loading || salesChannelLoading) return <Loading />;
    if (!data.shippingServices) return <ErrorDisplay onClick={refetch}>Cannot load shippingServices from database</ErrorDisplay>;
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (!salesChannelData.salesChannels) return <ErrorDisplay onClick={refetch}>Cannot load salesChannels from database</ErrorDisplay>;
    if (salesChannelError) return <ErrorDisplay onClick={salesChannelRefetch}>{salesChannelError.message}</ErrorDisplay>;

    const exportProductDetails = () => {
        return runProductDetailsMutation({}).then((ret) => {
            if (ret.data) {
                return ret.data.api.importExport.export.productDetails;
            }
            throw new Error("No data return from product details export");
        });
    };

    const exportBarcodeLocation = () => {
        return runBarcodeLocationMutation({}).then((ret) => {
            if (ret.data) {
                return ret.data.api.importExport.export.locationBarcodes;
            }
            throw new Error("No data return from barcode location export");
        });
    };

    const exportProductAvailabilities = () => {
        return runExportProductAvailabilitiesMutation({}).then((ret) => {
            if (ret.data) {
                return ret.data.api.importExport.export.productAvailabilities;
            }
            throw new Error("No data return from product Availabilities export");
        });
    };

    const exportMonthlySales = () => {
        return runExportMonthlySalesMutation({ variables: { year: new Date().getFullYear() } }).then((ret) => {
            if (ret.data) {
                return ret.data.api.importExport.export.monthlySales;
            }
            throw new Error("No data return from monthly sales export");
        });
    };
    const uploadSalesChannelPrices = (ret: string) => {
        return runBulkImportSalesChannelPricesMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data?.api?.importExport?.import?.importSalesChannelPrice;
            },
            (err) => {
                console.error(`Error uploading Sale `, err);
                return err.message;
            }
        );
    };

    const onChangeAmazonFile1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputAmazonFile1(e.currentTarget.files[0]);
        }
    };

    const onChangeAmazonFile2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputAmazonFile2(e.currentTarget.files[0]);
        }
    };

    const onChangeAmazonFbaOrdersFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputAmazonFbaOrdersFile(e.currentTarget.files[0]);
        }
    };

    const onChangeZipCodeZonesFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputZipCodeZonesFile(e.currentTarget.files[0]);
        }
    };

    const onChangeZipCodeZonesShippingServiceId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        if (e.currentTarget.value) {
            setInputZipCodeZonesShippingServiceId(e.currentTarget.value);
        }
    };

    const onChangeReturnReasonSalesChannelId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        if (e.currentTarget.value) {
            setInputReturnReasonsSalesChannelId(e.currentTarget.value);
        }
    };

    const onChangeReturnReasonFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputReturnReasonsFile(e.currentTarget.files[0]);
        }
    };

    const uploadBulkUpdateProducts = (ret: string) => {
        return runBulkUpdateProductsMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data.api.importExport.import.importBulkUpdateProducts;
            },
            (err) => {
                console.error(`Error uploading Products `, err);
                return err.message;
            }
        );
    };

    const bulkPurchaseOrderStatusChangeToOrdered = (ret: string) => {
        return runBulkPurchaseOrderStatusChangeToOrderedMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data.api.importExport.import.bulkPurchaseOrderStatusChangeToOrdered;
            },
            (err) => {
                console.error("Error uploading products ", err);
                return err.message;
            }
        );
    };

    const uploadBarcodes = (ret: string) => {
        return runBarcodeEditMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data.api.importExport.import.importProductBarcodes;
            },
            (err) => {
                console.error(`Error uploading Sale `, err);
                return err.message;
            }
        );
    };

    const uploadZipCodeZones = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputZipCodeZonesFile && inputZipCodeZonesShippingServiceId) {
            convertBase64(inputZipCodeZonesFile).then((base64) => {
                runImportZipCodeZonesMutation({
                    variables: { base64: base64, shippingServiceId: inputZipCodeZonesShippingServiceId },
                }).then(
                    (ret) => {
                        setSaving(false);
                        setInputZipCodeZonesFile(null);
                        setInputZipCodeZonesShippingServiceId(null);
                        alert(ret.data?.api?.importExport?.import?.importZipCodeZones);
                    },
                    (err) => {
                        setSaving(false);
                        setInputZipCodeZonesFile(null);
                        setInputZipCodeZonesShippingServiceId(null);
                        alert(`Error uploading zip code zones: ${err.message}`);
                    }
                );
            });
        }
        setSaving(false);
    };

    const uploadSalesChannelReturnReasons = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputReturnReasonsFile && inputReturnReasonsSalesChannelId) {
            convertBase64(inputReturnReasonsFile).then((base64) => {
                runImportSalesChannelReturnReasonsMutation({
                    variables: { base64: base64, salesChannelId: inputReturnReasonsSalesChannelId },
                }).then(
                    (ret) => {
                        setSaving(false);
                        setInputReturnReasonsFile(null);
                        setInputReturnReasonsSalesChannelId(null);
                        alert(ret.data?.api?.importExport?.import?.importSalesChannelReturnReasons);
                    },
                    (err) => {
                        setSaving(false);
                        setInputReturnReasonsFile(null);
                        setInputReturnReasonsSalesChannelId(null);
                        alert(`Error uploading sales channel return reasons: ${err.message}`);
                    }
                );
            });
        }
        setSaving(false);
    };

    async function basicStockSubscription<T>(
        base64: string,
        query: string,
        propery: string,
        setMessage: React.Dispatch<React.SetStateAction<string>>,
        onClose: () => void
    ) {
        await graphQLContext.client.ExecuteSubscription<T, IImportVariables>(
            {
                query: query,
                variables: {
                    base64: base64,
                },
            },
            (res) => {
                var message = "";

                if (res.errors) {
                    message = res.errors.map((x) => `message: ${x.message}`).join("\n\n");
                }

                if (res.data) {
                    const qqq: IUpdateStockSubscriptionBody = res.data[propery];
                    var errorList = qqq.errorList;
                    if (errorList && errorList.length > 0) {
                        message += errorList
                            .filter((x) => x.errorType.trim() !== "Requested Quantity revision is redundant.")
                            .map((x) => `type: ${x.errorType} \nmessage: ${x.errorMessage} `)
                            .join("\n\n");
                    }
                }

                if (message) setMessage(message);
            },
            () => {
                onClose();
                setMessage((x) => x + "\nDone");
            }
        );
        return;
    }

    const uploadSupplierStock = async (base64: string, setMessage: React.Dispatch<React.SetStateAction<string>>, onClose: () => void) => {
        await basicStockSubscription<IUpdateSupplierStockSubscriptionResponse>(
            base64,
            updateSupplierStock,
            "importSupplierStock",
            setMessage,
            onClose
        );
    };

    const bulkRecalculateChannelStock = async (
        base64: string,
        setMessage: React.Dispatch<React.SetStateAction<string>>,
        onClose: () => void
    ) => {
        await basicStockSubscription<IBulkUpdateStockSubscriptionResponse>(
            base64,
            updateStockByExcelSubscription,
            "bulkRecalculateChannelStock",
            setMessage,
            onClose
        );
    };

    const onImportAmazonOrders = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputAmazonFile1 && inputAmazonFile2) {
            try {
                const file1 = await convertBase64(inputAmazonFile1);
                const file2 = await convertBase64(inputAmazonFile2);
                await runImportAmazonOrdersMutation({ variables: { file1, file2 } });
                alert("Upload complete; check Long Running Tasks for status");
            } catch (err: any) {
                console.error(err);
                if (err.message) {
                    alert("Error: " + err.message);
                } else {
                    alert("Error: " + err);
                }
            }
            setSaving(false);
            setInputAmazonFile1(null);
            setInputAmazonFile2(null);
        }
    };

    const onImportAmazonFbaOrders = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputAmazonFbaOrdersFile) {
            try {
                const base64 = await convertBase64(inputAmazonFbaOrdersFile);
                await runImportAmazonFbaOrdersMutation({ variables: { base64: base64 } });
                alert("Upload complete; check Long Running Tasks for status");
            } catch (err: any) {
                console.error(err);
                if (err.message) {
                    alert("Error: " + err.message);
                } else {
                    alert("Error: " + err);
                }
            }
            setSaving(false);
            setInputAmazonFbaOrdersFile(null);
        }
    };

    const onImportAmazonFbaStock = (ret: string) => {
        return runImportAmazonFbaStockMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data?.api?.importExport?.import?.importAmazonFbaStock;
            },
            (err) => {
                console.error(`Error uploading Amazon FBA Stock `, err);
                return err.message;
            }
        );
    };

    const onImportAmazonFulfillmentCenters = (ret: string) => {
        return runImportAmazonFulfillmentCentersMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data.api.importExport.import.importAmazonFulfillmentCenters;
            },
            (err) => {
                console.error(`Error uploading Amazon Fulfillment Centers `, err);
                return err.message;
            }
        );
    };

    const onImportCountries = (ret: string) => {
        return runImportCountriesMutation({
            variables: {
                base64: ret,
            },
        }).then(
            (ret) => {
                return ret.data.api.importExport.import.importCountries;
            },
            (err) => {
                console.error(`Error uploading Countries `, err);
                return err.message;
            }
        );
    };

    // const onImportAmazonVendorShipments = (ret: string) => {
    //     return runImportAmazonVendorShipmentsMutation({
    //         variables: {
    //             base64: ret,
    //         },
    //     }).then(
    //         (ret) => {
    //             return ret.data?.api?.importExport?.import?.importAmazonVendorShipments;
    //         },
    //         (err) => {
    //             console.error(`Error uploading Amazon Vendor Shipments `, err);
    //             return err.message;
    //         }
    //     );
    // };

    const exportRestockReport = async (daysToProject: number, baseDaysLeadTime: number): Promise<string> => {
        try {
            const result = await runExportRestockReportMutation({
                variables: { daysToProject, baseDaysLeadTime },
            });
            return result.data.api.importExport.export.restockReport;
        } catch (error) {
            console.error("Export failed", error);
            throw new Error("Export failed");
        }
    };

    const exportRestockReportHistory = async (daysToProject: number, baseDaysLeadTime: number): Promise<string> => {
        try {
            const result = await runExportRestockReportHistoryMutation({
                variables: { daysToProject, baseDaysLeadTime },
            });
            return result.data.api.importExport.export.restockReportHistory;
        } catch (error) {
            console.error("Export failed", error);
            throw new Error("Export failed");
        }
    };

    const defaultReportModal: IModal = { show: false, input: { daysToProject: "", baseDaysLeadTime: "", type: ReportType.Export } };
    const defaultReportHistoryModal: IModal = { show: false, input: { daysToProject: "", baseDaysLeadTime: "", type: ReportType.History } };

    return (
        <>
            <PageHeader>Bulk Utilities</PageHeader>
            <Card className="border-primary" style={{ maxWidth: 800 }}>
                <Card.Header className="bg-primary text-white">Export Utilities</Card.Header>
                <Card.Body>
                    <BasicExport onExport={exportProductDetails} title="Export Product Details" fileName="Product_Details"></BasicExport>
                    <BasicExport
                        onExport={exportBarcodeLocation}
                        title="Export Barcode Locations"
                        fileName="Barcode_Locations"
                    ></BasicExport>
                    <BasicExport
                        onExport={exportProductAvailabilities}
                        title="Export Product Availabilities"
                        fileName="Product_Availabilities"
                    ></BasicExport>
                    <BasicExport onExport={exportMonthlySales} title="Export Monthly Sales" fileName="Monthly_Sales"></BasicExport>
                    <RestockExport
                        onExport={exportRestockReport}
                        title="Export Restock Report"
                        fileName="Restock_Report"
                        defaultValues={defaultReportModal}
                    ></RestockExport>
                    <RestockExport
                        onExport={exportRestockReportHistory}
                        title="Export Restock Report History"
                        fileName="Restock_Report_History"
                        defaultValues={defaultReportHistoryModal}
                    ></RestockExport>
                </Card.Body>
            </Card>
            <BasicBulkUpload title="Countries Import" headers={["IsoCode", "Name", "SortOrder"]} runMutation={onImportCountries} />
            <BasicBulkUpload
                title="Sale Channel Bulk Upload"
                headers={["Sku", "Price", "ChannelName"]}
                runMutation={uploadSalesChannelPrices}
            />
            <BasicBulkUpload
                title="Bulk Update Products"
                headers={["Sku", "DropShipMode", "SupplierLatestPrice"]}
                runMutation={uploadBulkUpdateProducts}
            />
            <BasicBulkUpload
                title="Bulk PurchaseOrderStatus Change To Ordered"
                headers={["PurchaseOrderNumber"]}
                runMutation={bulkPurchaseOrderStatusChangeToOrdered}
            />
            {/* <BasicBulkUpload
                title="Bulk Upload Amazon Vendor Shipments"    
                headers={["Sku", "Price", "ChannelName"]}
                runMutation={onImportAmazonVendorShipments}
            /> */}
            <BasicBulkUpload title="Bulk Product Barcode Edit" headers={["Sku", "Barcode"]} runMutation={uploadBarcodes} />
            <BasicBulkUpload title="Bulk Recalculate Stock" headers={["Sku"]} runObservable={bulkRecalculateChannelStock} />
            <BasicBulkUpload
                title="Import Supplier Stock"
                headers={["Sku", "SupplierName", "Warehouse", "StockOnHand"]}
                runObservable={uploadSupplierStock}
            />
            <Card className="border-primary" style={{ maxWidth: 800 }}>
                <Card.Header className="bg-primary text-white">Import Zip Code Zones</Card.Header>
                <Card.Body>
                    <form onSubmit={uploadZipCodeZones}>
                        <Form.Group controlId="zipCodeZonesShippingServiceId" className="mb-2">
                            <Form.Label>Shipping Service</Form.Label>
                            <Form.Select required onChange={onChangeZipCodeZonesShippingServiceId}>
                                <option value=""></option>
                                {data.shippingServices.items
                                    .filter((x) => x.active)
                                    .map((shippingService) => (
                                        <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                            {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="zipCodeZonesFile1" className="mb-2">
                            <Form.Label>Zip Code Zones Excel</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeZipCodeZonesFile}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            className="mb-2"
                            type="submit"
                            disabled={saving || inputZipCodeZonesFile === null || inputZipCodeZonesShippingServiceId === null}
                        >
                            Upload
                        </Button>
                    </form>
                </Card.Body>
            </Card>
            <Card className="border-primary" style={{ maxWidth: 800 }}>
                <Card.Header className="bg-primary text-white">Import Sales Channel Return Reasons</Card.Header>
                <Card.Body>
                    <form onSubmit={uploadSalesChannelReturnReasons}>
                        <Form.Group controlId="returnReasonSalesChannelId" className="mb-2">
                            <Form.Label>Sales Channel</Form.Label>
                            <Form.Select required onChange={onChangeReturnReasonSalesChannelId}>
                                <option value=""></option>
                                {salesChannelData.salesChannels.items
                                    .filter((x) => x.active)
                                    .map((salesChannel) => (
                                        <option key={`sales-${salesChannel.id}`} value={salesChannel.id}>
                                            {salesChannel.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="returnReasonFile" className="mb-2">
                            <Form.Label>Return Reason Excel</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeReturnReasonFile}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            className="mb-2"
                            type="submit"
                            disabled={saving || inputReturnReasonsFile === null || inputReturnReasonsSalesChannelId === null}
                        >
                            Upload
                        </Button>
                    </form>
                </Card.Body>
            </Card>
            <Card className="border-primary" style={{ maxWidth: 800 }}>
                <Card.Header className="bg-primary text-white">Import Amazon Orders</Card.Header>
                <Card.Body>
                    <form onSubmit={onImportAmazonOrders}>
                        <Form.Group controlId="amazonFile1" className="mb-2">
                            <Form.Label>Amazon Export XLSX file</Form.Label>
                            <Form.Control type="file" accept=".xls, .xlsx" required className="primary" onChange={onChangeAmazonFile1} />
                        </Form.Group>
                        <Form.Group controlId="amazonFile2" className="mb-2">
                            <Form.Label>Amazon SKU cross reference XLSX file</Form.Label>
                            <Form.Control type="file" accept=".xls, .xlsx" required className="primary" onChange={onChangeAmazonFile2} />
                        </Form.Group>
                        <Button
                            variant="primary"
                            className="mb-2"
                            type="submit"
                            disabled={saving || inputAmazonFile1 === null || inputAmazonFile2 === null}
                        >
                            Upload
                        </Button>
                    </form>
                </Card.Body>
            </Card>
            <Card className="border-primary" style={{ maxWidth: 800 }}>
                <Card.Header className="bg-primary text-white">Import Amazon FBA Orders</Card.Header>
                <Card.Body>
                    <form onSubmit={onImportAmazonFbaOrders}>
                        <Form.Group controlId="amazonFbaOrdersFile" className="mb-2">
                            <Form.Label>Amazon FBA Orders XLSX File</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeAmazonFbaOrdersFile}
                            />
                        </Form.Group>
                        <Button variant="primary" className="mb-2" type="submit" disabled={saving || inputAmazonFbaOrdersFile === null}>
                            Upload
                        </Button>
                    </form>
                </Card.Body>
            </Card>
            <BasicBulkUpload title="Bulk Amazon FBA Stock Import" headers={["ASIN", "Available"]} runMutation={onImportAmazonFbaStock} />
            <BasicBulkUpload
                title="Bulk Amazon Fulfillment Centers Import"
                headers={[
                    "AmazonIdentifier",
                    "Name",
                    "Address1",
                    "Address2",
                    "City",
                    "State",
                    "Zip",
                    "Country",
                    "SAN",
                    "Carton Quantity SAN*",
                ]}
                runMutation={onImportAmazonFulfillmentCenters}
            />
        </>
    );
};

// const BasicExport = (props: { onExport: () => Promise<string | void | undefined>; fileName: string; title: string }) => {
const BasicExport = (props: { onExport: () => Promise<string>; fileName: string; title: string }) => {
    const [loading, setLoading] = useState(false);
    const onExportMutation = () => {
        setLoading(true);
        props
            .onExport()
            .then((returnedData) => {
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = props.fileName;
                fileLink.click();
            })
            .catch((err) => {
                console.error(`Error trying to export ${props.title}`, err);
                alert("Error trying to export data");
            })
            .finally(() => setLoading(false));
    };
    return (
        <Button disabled={loading} variant="primary" className="mx-2 my-2" onClick={onExportMutation}>
            {loading ? "Loading" : props.title}
        </Button>
    );
};

const RestockExport = (props: {
    onExport: (daysToProject: number, baseDaysLeadTime: number) => Promise<string>;
    fileName: string;
    title: string;
    defaultValues: IModal;
}) => {
    const [loading, setLoading] = useState(false);
    const [modal] = React.useState<IModal>(props.defaultValues);
    const [showModal, setShowModal] = useState(false);

    const onRestockReportRequest = (values: IRestockInput) => {
        const { daysToProject, baseDaysLeadTime } = values;
        setLoading(true);
        setShowModal(false);
        props
            .onExport(parseInt(daysToProject), parseInt(baseDaysLeadTime))
            .then((returnedData) => {
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = props.fileName;
                fileLink.click();
            })
            .catch((err) => {
                console.error(`Error trying to export ${props.title}`, err);
                alert("Error trying to export data");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const toggleShowModal = () => setShowModal(!showModal);

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <VForm onSubmit={onRestockReportRequest} initialValue={modal.input}>
                    <Modal.Header closeButton>
                        <Modal.Title>Restock Report Parameters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4">
                                    <VLabel valueName="name">Days to Project</VLabel>
                                    <VControl type="text" valueName="daysToProject" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <VLabel valueName="name">Base Leadtime</VLabel>
                                    <VControl type="text" valueName="baseDaysLeadTime" />
                                </Form.Group>
                                <Form.Group>
                                    <VButton type="submit">Restock Report</VButton>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                </VForm>
            </Modal>
            <Button
                disabled={loading}
                variant="primary"
                className="mx-2 my-2"
                onClick={() => {
                    toggleShowModal();
                }}
            >
                {loading ? "Loading" : props.title}
            </Button>
        </>
    );
};

const BasicBulkUpload = (props: {
    title: string;
    headers?: string[];
    runMutation?: (base64: string) => Promise<string>;
    runObservable?: (base64: string, setMessage: React.Dispatch<React.SetStateAction<string>>, onClose: () => void) => Promise<void>;
}) => {
    const [saving, setSaving] = React.useState(false);
    const [inputFile, setInputFile] = React.useState<File | null>(null);
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const [message, setMessage] = React.useState<string>("");

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputFile) {
            convertBase64(inputFile).then((ret) => {
                if (props.runMutation) {
                    props.runMutation(ret).then((res) => {
                        res != null ? setMessage(res) : setMessage("unkown error occured");
                        setSaving(false);
                        setInputFile(null);
                    });
                } else if (props.runObservable) {
                    const onClose = () => {
                        setSaving(false);
                        setInputFile(null);
                    };
                    props.runObservable(ret, setMessage, onClose);
                } else {
                    console.error("No mutation or observable provided");
                }
            });
        }
    };

    const clearModal = () => {
        if (inputFileRef && inputFileRef?.current) inputFileRef.current.value = "";
        setInputFile(null);
        setMessage("");
        setSaving(false);
    };

    const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputFile(e.currentTarget.files[0]);
        }
    };

    return (
        <Card className="border-primary" style={{ maxWidth: 800 }}>
            <Card.Header className="bg-primary text-white">{props.title}</Card.Header>
            <Card.Body>
                <form onSubmit={onSubmit}>
                    <Form.Group controlId="csvFile" className="mb-2">
                        <Form.Label>Import excel</Form.Label>
                        {props.headers && <Form.Label>&nbsp; &nbsp; Sheet Column Headers: {props.headers.join(", ")} </Form.Label>}
                        <Form.Control
                            type="file"
                            accept=".xls, .xlsx"
                            required
                            className="primary"
                            onChange={onChangeImportFile}
                            ref={inputFileRef}
                        />
                    </Form.Group>
                    <Button variant="primary" className="mb-2" type="submit" disabled={saving || inputFile === null}>
                        {saving ? "Running" : "Upload"}
                    </Button>

                    <Form.Group className="mb-2">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" readOnly value={message} rows={5} />
                    </Form.Group>
                    <Button variant="secondary" onClick={clearModal}>
                        Clear
                    </Button>
                </form>
            </Card.Body>
        </Card>
    );
};

export default BulkUtilitiesIndex;
