import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import StateCode from "../../enums/StatesCode";
import StringHelper from "../../helpers/StringHelper";
import { useMutation, useQuery } from "@shane32/graphql";

interface ISupplierQueryResult {
    supplier: ISupplierWithWarehouses;
    countries: {
        items: Array<ICountry>;
    };
}

interface ISupplierWithWarehouses {
    id: string;
    name: string;
    defaultPaymentTermId: number;
    active: boolean;
    warehouses: Array<ISupplierWarehouse>;
}

interface ISupplierWarehouse {
    id: string;
    name: string;
    address: {
        id: string;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        countryId: string | null;
    } | null;
    active: boolean;
    sortOrder: number;
}

interface ISupplierWarehouseModel {
    id: string;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    active: boolean;
    countryId: string;
    sortOrder: number;
}

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: number;
    active: boolean;
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

interface IEditVariables {
    original: ISupplier;
    modified: ISupplierWithWarehouses;
}

interface IEditResult {
    supplier: {
        edit: {
            warehouses: Array<ISupplierWarehouse>;
        };
    };
}

const SupplierQuery = `
query ($id: ID!) {
  supplier(id: $id) {
    id
    name
    defaultPaymentTermId
    active
    warehouses{
      id
      name
      sortOrder
      active
    }
  }
}`;

const SupplierEditMutation = `
mutation ($original: SupplierInput!, $modified: SupplierInput!) {
  supplier{
    edit(original: $original, modified: $modified){
      warehouses{
        id
        name
        sortOrder
        active
      }
    }
  }
}`;

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const SupplierWarehouses = () => {
    const { id } = useParams<{ id: string }>();
    const [saving, setSaving] = React.useState(false);
    const { data, error, refetch } = useQuery<ISupplierQueryResult, { id: string }>(SupplierQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<ISupplierQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [runSupplierEdit] = useMutation<IEditResult, IEditVariables>(SupplierEditMutation);
    const [showModal, setShowModal] = React.useState(false);
    const history = useHistory();

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    if (!data || !dataCountry) return <Loading />;

    if (!data) return <Loading />;
    else if (!data.supplier) return <ErrorDisplay onClick={refetch}>Supplier ID #{id}</ErrorDisplay>;

    const onShowModal = () => {
        setShowModal(true);
    };

    const onHideModal = () => {
        if (!saving) setShowModal(false);
    };

    const originalModel: ISupplierWarehouseModel = {
        id: "0",
        name: "",
        active: true,
        address1: "",
        address2: "",
        city: "",
        countryId: "",
        phone: "",
        state: "",
        zip: "",
        sortOrder: 1,
    };

    const onSaveChanges = (modified: ISupplierWarehouseModel) => {
        setSaving(true);

        const shippingAddressIsEmpty =
            StringHelper.IsNullOrWhitespace(modified.address1) &&
            StringHelper.IsNullOrWhitespace(modified.address2) &&
            StringHelper.IsNullOrWhitespace(modified.city) &&
            StringHelper.IsNullOrWhitespace(modified.countryId) &&
            StringHelper.IsNullOrWhitespace(modified.phone) &&
            StringHelper.IsNullOrWhitespace(modified.state) &&
            StringHelper.IsNullOrWhitespace(modified.zip);

        runSupplierEdit({
            variables: {
                original: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                },
                modified: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [
                        {
                            id: modified.id,
                            name: modified.name,
                            sortOrder: modified.sortOrder,
                            address: shippingAddressIsEmpty
                                ? null
                                : {
                                      id: "0",
                                      address1: modified.address1,
                                      address2: modified.address2,
                                      city: modified.city,
                                      countryId: modified.countryId,
                                      phone: modified.phone,
                                      state: modified.state,
                                      zip: modified.zip,
                                  },
                            active: modified.active,
                        },
                    ],
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                data.supplier.warehouses = ret.data.supplier.edit.warehouses;
                //enable form controls and hide the modal
                setSaving(false);
                setShowModal(false);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding a Supplier Warehouse", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const navTo = (e: React.MouseEvent, warehouseId: string) => {
        if (e.currentTarget === e.target)
            history.push("/suppliers/" + encodeURIComponent(id) + "/warehouses/" + encodeURIComponent(warehouseId));
    };

    const card = (active: boolean) => {
        const supplierWarehouses = data.supplier.warehouses
            .filter((warehouse) => warehouse.active === active)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                <Card.Header className="bg-primary text-white">
                    {/* set card header appropriately */}
                    {active ? "Active" : "Inactive"} Warehouses
                </Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Warehouses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierWarehouses.map((warehouse) => (
                                <tr key={warehouse.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, warehouse.id)}>
                                        <Link to={`/suppliers/${id}/warehouses/${warehouse.id}`}>{warehouse.name}</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    const states = Object.values<string>(StateCode);

    return (
        <>
            <h2>Warehouses</h2>
            <p>
                <Button variant="white" onClick={onShowModal}>
                    Add new warehouse
                </Button>
            </p>

            {/* Active Warehouses */}
            {card(true)}

            {/* Inactive Warehouses*/}
            {card(false)}

            <Modal show={showModal} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Warehouse</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formName">
                                    <VLabel valueName="name">Name</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress1">
                                    <VLabel valueName="address1">Address 1</VLabel>
                                    <VControl type="text" valueName="address1" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress2">
                                    <VLabel valueName="address2">Address 2</VLabel>
                                    <VControl type="text" valueName="address2" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formCity">
                                    <VLabel valueName="city">City</VLabel>
                                    <VControl type="text" valueName="city" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formState">
                                    <VLabel valueName="state">State</VLabel>
                                    <VSelect valueName="state">
                                        <option value=""></option>
                                        {states.map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formZip">
                                    <VLabel valueName="zip">Zip</VLabel>
                                    <VControl type="text" valueName="zip" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPhone">
                                    <VLabel valueName="phone">Phone</VLabel>
                                    <VControl type="text" valueName="phone" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formCountry">
                                    <VLabel valueName="countryId">Country</VLabel>
                                    <VSelect valueName="countryId">
                                        <option></option>
                                        {dataCountry.countries.items
                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                            .map((country) => (
                                                <option key={`PurchaseOrder-${country.id}`} value={country.id}>
                                                    {country.isoCode}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formSortOrder">
                                    <VLabel valueName="sortOrder">Sort Order</VLabel>
                                    <VControl type="text" pattern="^$|\d+\.?\d*" valueName="sortOrder" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formActive">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SupplierWarehouses;
