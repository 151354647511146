import React from "react";
import { ValidationFormContext, VButton } from "@shane32/vform";

export const XButton = () => {
    // context is only needed here to hide the X button when the parent row is an automatically-appended blank row;
    // VButton will auto-disable itself when the parent row is blank, so this is not strictly necessary
    const validationFormContext = React.useContext(ValidationFormContext);
    if (validationFormContext.parentRowIsBlank) return <></>;
    else return <VButton type="removerow">X</VButton>;
};
