import * as React from "react";
import { Button, Modal } from "react-bootstrap";

interface IMessageContext {
    display: (title: string, message: string) => void;
    clear: () => void;
}

const MessageContext = React.createContext<IMessageContext>({ display: () => {}, clear: () => {} });

export default MessageContext;

export const MessageController = (props: React.PropsWithChildren<{}>) => {
    const [data, setData] = React.useState<{ title: string; message: string } | null>(null);
    const display = (title: string, message: string) => {
        setData({ title, message });
    };
    const clear = () => {
        setData(null);
    };
    const contextState: IMessageContext = {
        display: display,
        clear: clear,
    };
    return (
        <>
            <MessageContext.Provider value={contextState}>{props.children}</MessageContext.Provider>
            <Modal
                centered
                show={!!data}
                onHide={() => {
                    setData(null);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{data?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{data?.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setData(null);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
