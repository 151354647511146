export default function mapStringToEnum<T extends Record<string, string>>(enumType: T, stringValue: string | null): T[keyof T] | null {
    if (!stringValue) return null;

    const enumKeys = Object.keys(enumType) as Array<keyof T>;
    const lowerCaseString = stringValue.toLowerCase(); // Convert the input string to lowercase.

    for (const key of enumKeys) {
        if (enumType[key].toLowerCase() === lowerCaseString) {
            return enumType[key];
        }
    }

    return null;
}
