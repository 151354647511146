export const imagesQuery = `
query ($sku: String!) {
  part(sku: $sku) {
    medias {
      sortOrder
      fullscreen: photoUri
      original: thumbnailUri(size: 500)
      thumbnail: thumbnailUri(size: 100)
    }
  }
}
`;

export interface IImagesQueryResult {
    part: {
        medias: {
            sortOrder: number;
            fullscreen: string;
            original: string;
            thumbnail: string;
        }[];
    };
}

export interface IImagesQueryVariables {
    sku: string;
}
