import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import WarehouseLocations from "./WarehouseLocations";
import WarehousePickZones from "./WarehousePickZones";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import WarehouseStationSets from "./WarehouseStationSets";
import StringHelper from "../../../helpers/StringHelper";
import StateCode from "../../../enums/StatesCode";
import WarehouseReturnAddresses from "./WarehouseReturnAddresses";

interface IWarehouseQueryResult {
    warehouse: IWarehouse;
    countries: {
        items: Array<ICountry>;
    };
}

interface IWarehouse {
    id: string;
    name: string;
    address: {
        id: string;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        countryId: string | null;
    };
    active: boolean;
}

interface IWarehouseModel {
    id: string;
    name: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    active: boolean;
    countryId: string;
}
interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}
interface IEditResult {
    warehouse: {
        edit: IWarehouse;
    };
}

interface IEditVariables {
    original: IWarehouse;
    modified: IWarehouse;
}

interface IDeleteResult {
    warehouse: {
        delete: string;
    };
}

interface IModal {
    show: boolean;
    original?: IWarehouse;
}

const hiddenModal: IModal = {
    show: false,
};

const WarehouseQuery = `
query($id: ID!) {
    warehouse (id: $id) {
        id
        name
        address {
            id
            company
            address1
            address2
            city
            state
            zip
            phone
            countryId
        }
        active
    }
}
`;
const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;
const WarehouseMutationQuery = `
mutation($original: WarehouseInput!, $modified: WarehouseInput!){
    warehouse {
        edit(original: $original, modified: $modified)
        {
            id
            name
            address {
                id
                company
                address1
                address2
                city
                state
                zip
                phone
                countryId
            }
            active
        }
    }
}
`;

const WarehouseDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IWarehouseQueryResult, { id: string }>(WarehouseQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<IWarehouseQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(WarehouseMutationQuery);
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { supplier { delete (id: $id) } }");
    const history = useHistory();

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    if (!data || !dataCountry) return <Loading />;

    const originalModel: IWarehouseModel = {
        id: data.warehouse.id,
        name: data.warehouse.name,
        company: data.warehouse.address.company || "",
        address1: data.warehouse.address.address1 || "",
        address2: data.warehouse.address.address2 || "",
        city: data.warehouse.address.city || "",
        state: data.warehouse.address.state || "",
        zip: data.warehouse.address.zip || "",
        phone: data.warehouse.address.phone || "",
        active: data.warehouse.active,
        countryId: data.warehouse.address.countryId || "",
    };

    const onSaveChanges = (modified: IWarehouseModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: data.warehouse,
                //pass in modified data
                modified: {
                    id: data.warehouse.id,
                    name: modified.name,
                    address: {
                        id: data.warehouse.address.id,
                        company: StringHelper.IsNullOrWhitespace(modified.company) ? null : modified.company,
                        address1: StringHelper.IsNullOrWhitespace(modified.address1) ? null : modified.address1,
                        address2: StringHelper.IsNullOrWhitespace(modified.address2) ? null : modified.address2,
                        city: StringHelper.IsNullOrWhitespace(modified.city) ? null : modified.city,
                        state: StringHelper.IsNullOrWhitespace(modified.state) ? null : modified.state,
                        zip: StringHelper.IsNullOrWhitespace(modified.zip) ? null : modified.zip,
                        phone: StringHelper.IsNullOrWhitespace(modified.phone) ? null : modified.phone,
                        countryId: StringHelper.IsNullOrWhitespace(modified.countryId) ? null : modified.countryId,
                    },
                    active: modified.active,
                },
            },
        }).then(
            (ret) => {
                data.warehouse = ret.data.warehouse.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing Supplier", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===

        //verify the user wanted to delete this entry
        if (!window.confirm("Please make sure to delete all pick zones, locations, and stations before deleting this warehouse!")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: originalModel.id } }).then(
            //success
            () => {
                //redirects to the main warehouses page
                setSaving(false);
                let path = `/warehouses`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting warehouse", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const states = Object.values<string>(StateCode);
    states.unshift("");

    return (
        <>
            <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                {data.warehouse.name}
            </PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/warehouses/${data.warehouse.id}/locations`} exact>
                        <Nav.Link>Locations</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/warehouses/${data.warehouse.id}/pickzones`} exact>
                        <Nav.Link>Pick Zones</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/warehouses/${data.warehouse.id}/stationsets`} exact>
                        <Nav.Link>Station Sets</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/warehouses/${data.warehouse.id}/returnaddresses`} exact>
                        <Nav.Link>Return Addresses</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/warehouses/:id/pickzones" exact component={WarehousePickZones} />
                <Route path="/warehouses/:id/locations" exact component={WarehouseLocations} />
                <Route path="/warehouses/:id/stationsets" exact component={WarehouseStationSets} />
                <Route path="/warehouses/:id/returnaddresses" exact component={WarehouseReturnAddresses} />
            </Switch>
            <Modal show={modal.show} onHide={onShowModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton onClick={onHideModal}>
                        {/* set popup title appropriately */}
                        <Modal.Title>Edit Warehouse</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formName">
                                    <VLabel valueName="name">Description</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formCompany">
                                    <VLabel valueName="company">Company Name</VLabel>
                                    <VControl type="text" required valueName="company" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress1">
                                    <VLabel valueName="address1">Address 1</VLabel>
                                    <VControl type="text" valueName="address1" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress2">
                                    <VLabel valueName="address2">Address 2</VLabel>
                                    <VControl type="text" valueName="address2" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formCity">
                                    <VLabel valueName="city">City</VLabel>
                                    <VControl type="text" valueName="city" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formState">
                                    <VLabel valueName="state">State</VLabel>
                                    <VSelect valueName="state">
                                        <option value=""></option>
                                        {states.map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formZip">
                                    <VLabel valueName="zip">Zip</VLabel>
                                    <VControl type="text" valueName="zip" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPhone">
                                    <VLabel valueName="phone">Phone</VLabel>
                                    <VControl type="text" valueName="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCountry">
                                    <VLabel valueName="countryId">Country</VLabel>
                                    <VSelect valueName="countryId">
                                        {dataCountry.countries.items
                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                            .map((country) => (
                                                <option key={`PurchaseOrder-${country.id}`} value={country.id}>
                                                    {country.isoCode}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formActive">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default WarehouseDetails;
