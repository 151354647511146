import { createElement, useContext, useEffect, useRef, useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import PurchaseInvoiceStatus from "../../../../enums/PurchaseInvoiceStatus";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import { IReturnsSelectTrackingNumberRouteParams } from "./SelectTrackingNumber";
import useConfirm from "../../hooks/useConfirm";
import MobileLoading from "../../components/MobileLoading";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ProductCondition from "../../../../enums/ProductCondition";
import useScanner from "../../hooks/useScanner";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import ModelType from "../../../../enums/ModelType";
import ProductType from "../../../../enums/ProductType";
import PurchaseInvoiceSearch, { ISearchComponentProps, PurchaseInvoiceSearchComponentType } from "../_shared/PurchaseInvoiceSearch";
import typedParse from "../../helpers/typedParse";
import forgeURLSearchParams from "../../helpers/forgeURLSearchParameters";
import SelectionType from "../../../../enums/SelectionType";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";
import caseFormatter from "../../helpers/caseFormatter";
import useLocationInputModal from "../../hooks/useLocationInputModal";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import { useActionBarContext } from "../../contexts/ActionBarContext/ActionBarContext";

const purchaseInvoiceQueryBase = `
id
purchaseOrderId
invoiceNumber
invoiceDate
arrivalDate
status
lineItems {
  id
  purchaseInvoiceId
  expectedQuantity
  actualQuantity
  unitPrice
  discount
  comment
  total
  actualCondition
  purchaseOrderLineItem {
    id
    expectedCondition
    product {
      id
      sku
      description
      type
    }
  }
}
trackingNumbers {
  purchaseInvoiceId
  trackingNumber
  sortOrder
}
subtotal
tax
total
paymentTermId
purchaseOrder {
  id
  returnReason {
    description
  }
  notes
}
`;

const purchaseInvoiceQuery = `
query($id: ID!) {
    purchaseInvoice(id: $id) {
      ${purchaseInvoiceQueryBase}
    }
  }   
`;

interface IPurchaseInvoiceQueryVariables {
    id: string;
}

interface IPurchaseInvoiceQueryResult {
    purchaseInvoice: IPurchaseInvoice | null;
}

interface IPurchaseInvoiceLineItem {
    id: string;
    purchaseInvoiceId: string;
    expectedQuantity: number;
    actualQuantity: number;
    unitPrice: number;
    discount: number;
    comment: string | null;
    total: number;
    actualCondition: ProductCondition | null;
    purchaseOrderLineItem: {
        id: string;
        expectedCondition: ProductCondition | null;
        product: {
            id: string;
            sku: string;
            description: string | null;
            type: ProductType;
        } | null;
    };
}

interface ITrackingNumber {
    purchaseInvoiceId: string;
    trackingNumber: string;
    sortOrder: number;
}

interface IPurchaseInvoice {
    id: string;
    purchaseOrderId: string;
    invoiceNumber: string;
    invoiceDate: string;
    arrivalDate: string | null;
    status: PurchaseInvoiceStatus;
    lineItems: Array<IPurchaseInvoiceLineItem>;
    trackingNumbers: ITrackingNumber;
    subtotal: number;
    tax: number;
    total: number;
    paymentTermId: string;
    purchaseOrder: {
        id: string;
        returnReason: {
            description: string;
        } | null;
        notes: string | null;
    };
}

interface IPurchaseInvoiceTrackingNumberInputModel {
    trackingNumber: string;
    sortOrder: number;
    shippingServiceId?: string;
}

interface IPurchaseInvoiceLineItemInputModel {
    id: string;
    purchaseOrderLineItemId: string;
    expectedQuantity: number;
    actualQuantity: number;
    unitPrice: number;
    discount: number;
    total: number;
    comment?: string;
    actualCondition: string | null;
}

const purchaseInvoiceMutation = `
mutation ($original: PurchaseInvoiceInput!, $modified: PurchaseInvoiceInput!) {
    purchaseInvoice {
      edit(original: $original, modified: $modified) {
        ${purchaseInvoiceQueryBase}
      }
    }
  }
`;

interface IPurchaseInvoiceMutationVariables {
    original: IPurchaseInvoiceInputModel;
    modified: IPurchaseInvoiceInputModel;
}

interface IPurchaseInvoiceMutationResult {
    purchaseInvoice: {
        edit: IPurchaseInvoice;
    };
}

interface IPurchaseInvoiceInputModel {
    id: string;
    purchaseOrderId: string;
    invoiceNumber: string;
    invoiceDate: string;
    arrivalDate?: string;
    status: PurchaseInvoiceStatus;
    lineItems?: Array<IPurchaseInvoiceLineItemInputModel>;
    trackingNumbers?: Array<IPurchaseInvoiceTrackingNumberInputModel>;
    subtotal: number;
    tax: number;
    total: number;
    paymentTermId: string;
}

const moveFromPurchaseInvoiceMutation = `
mutation ($purchaseInvoiceId: ID!, $productId: ID!, $toLocationId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!) {
  inventory {
    moveFromPurchaseInvoice(purchaseInvoiceId: $purchaseInvoiceId, productId: $productId, locationId: $toLocationId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
  }
  query {
    purchaseInvoice(id: $purchaseInvoiceId) {
      ${purchaseInvoiceQueryBase}
    }
  }
}
`;

interface IMoveFromPurchaseInvoiceMutationResult {
    query: {
        purchaseInvoice: IPurchaseInvoice | null;
    };
}

interface IMoveFromPurchaseInvoiceMutationVariables {
    purchaseInvoiceId: string;
    productId: string;
    toLocationId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

const changeInvoiceStatusMutation = `
mutation ($purchaseInvoiceId: ID!, $oldStatus: PurchaseInvoiceStatus!, $newStatus: PurchaseInvoiceStatus!) {
  purchaseInvoice {
    changeStatus(id: $purchaseInvoiceId, oldStatus: $oldStatus, newStatus: $newStatus) {
      ${purchaseInvoiceQueryBase}
    }
  }
}
`;

interface IChangeInvoiceStatusMutationResult {
    purchaseInvoice: {
        changeStatus: IPurchaseInvoice | null;
    };
}

interface IChangeInvoiceStatusMutationVariables {
    purchaseInvoiceId: string;
    oldStatus: PurchaseInvoiceStatus;
    newStatus: PurchaseInvoiceStatus;
}

interface IReturnsScanBarcodeRouteParams extends IReturnsSelectTrackingNumberRouteParams {
    purchaseInvoiceId: string;
}

export interface IURLSearchParams {
    activeProductInvoiceLineItemId?: string | null;
    trackingNumber?: string | null;
    addToHand?: boolean | null;
}

const ScanBarcode = () => {
    const actionBarContext = useActionBarContext();
    const history = useHistory();
    const location = useLocation();
    const params = useParams<IReturnsScanBarcodeRouteParams>();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IURLSearchParams>(rawURLSearchParams.get("urlsp"));
    const [numberInHand, setNumberInHand] = useState(0);
    const [autoStow, setAutoStow] = useState(false);
    const { confirmModal, showConfirmModal } = useConfirm();
    const [showSelectConditionModal, setShowSelectConditionModal] = useState(false);
    const [manualLoading, setManualLoading] = useState(false);
    const { multiMatchSelectorModal, showMultiMatchSelectorModal, open: isMultiMatchSelectorModalOpen } = useMultiMatchSelectorModal();
    const { locationInputModal, showLocationInputModal, open: isLocationInputModalOpen } = useLocationInputModal();
    const [showAddOrPrintBarcodeModal, setShowAddOrPrintBarcodeModal] = useState(false);
    const [productSelectionType, setProductSelectionType] = useState(SelectionType.None);
    const [searchComponentValues, setSearchComponentValues] = useState<ISearchComponentProps>({
        showSearchComponent: false,
        searchValue: "",
    });
    const inputRef = useRef<HTMLInputElement>(null);

    const {
        loading: purchaseInvoiceLoading,
        refetch: purchaseInvoiceRefetch,
        data: purchaseInvoiceData,
        error: purchaseInvoiceError,
    } = useQuery<IPurchaseInvoiceQueryResult, IPurchaseInvoiceQueryVariables>(purchaseInvoiceQuery, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.purchaseInvoiceId,
        },
    });

    //fetch scrap location id from settings
    const {
        settingsValue: defaultScrapLocationId,
        error: defaultScrapLocationError,
        loading: defaultScrapLocationLoading,
        refetch: defaultScrapLocationRefetch,
    } = useSettingsValue("defaultScrapLocationId");

    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runMoveFromPurchaseInvoiceMutation] = useMutation<
        IMoveFromPurchaseInvoiceMutationResult,
        IMoveFromPurchaseInvoiceMutationVariables
    >(moveFromPurchaseInvoiceMutation);
    const [runPurchaseInvoiceMutation] = useMutation<IPurchaseInvoiceMutationResult, IPurchaseInvoiceMutationVariables>(
        purchaseInvoiceMutation
    );
    const [runChangeInvoiceStatusMutation] = useMutation<IChangeInvoiceStatusMutationResult, IChangeInvoiceStatusMutationVariables>(
        changeInvoiceStatusMutation
    );
    const [purchaseInvoiceMutationLoading, setPurchaseInvoiceMutationLoading] = useState(false);

    const activeItem: IPurchaseInvoiceLineItem | undefined = purchaseInvoiceData?.purchaseInvoice?.lineItems.find(
        (li) => li.id === urlSearchParams.activeProductInvoiceLineItemId
    );
    const remainingPurchaseInvoiceLineItems = (purchaseInvoiceData?.purchaseInvoice?.lineItems ?? [])
        .filter((li) => li.purchaseOrderLineItem.product?.type !== ProductType.Service)
        .filter((li) => li.id !== activeItem?.id);

    useEffect(() => {
        if (activeItem && !activeItem.actualCondition) {
            setShowSelectConditionModal(true);
        }
    }, [activeItem]);

    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const onScan = async (scannerValue: string) => {
        if (
            isLoading ||
            manualLoading ||
            !scannerValue ||
            showAddOrPrintBarcodeModal ||
            isLocationInputModalOpen ||
            isMultiMatchSelectorModalOpen
        )
            return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            //check if there are matches for products and locations from the scanned value
            if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                await showConfirmModal(
                    formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                    "Multiple Matches In Database",
                    { confirm: "Confirm" },
                    true
                );
            } else if (barcodeProducts.length > 0) {
                //check to see how many matches there are
                if (barcodeProducts.length === 1) {
                    //only match for a product. get product object and continue like normal.
                    //verify that the scanned product matches something we want on the active purchase order invoice.
                    const purchaseInvoiceLineItem = (purchaseInvoiceData?.purchaseInvoice?.lineItems ?? []).find(
                        (pili) => pili.purchaseOrderLineItem.product?.id === barcodeProducts[0].id
                    );
                    if (purchaseInvoiceLineItem) {
                        setSearchComponentValues({ ...searchComponentValues, showSearchComponent: false });
                        if (purchaseInvoiceLineItem.purchaseOrderLineItem.product?.id === activeItem?.purchaseOrderLineItem?.product?.id) {
                            if (autoStow) {
                                await stow(SelectionType.Scanned, undefined, undefined, undefined, 1);
                            } else {
                                setProductSelectionType(SelectionType.Scanned);
                                playPositiveTone();
                                increment();
                            }
                        } else {
                            if (!purchaseInvoiceLineItem.purchaseOrderLineItem.product)
                                return alert(`Could not find a product id for purchase order line item id ${purchaseInvoiceLineItem.id}`);
                            //if (!purchaseOrderLineItem.partNumber) return alert(`Could not find a part number for purchase order line item id ${purchaseOrderLineItem.id}`);
                            const confirmed =
                                numberInHand < 1
                                    ? true
                                    : await showConfirmModal("You have items in hand, do you want to put them back?", "Put Items Back?", {
                                          confirm: "Yes",
                                          cancel: "No",
                                      });
                            if (!confirmed) return;
                            setProductSelectionType(SelectionType.Scanned);
                            history.replace(
                                `${location.pathname}?${forgeURLSearchParams({
                                    trackingNumber: urlSearchParams.trackingNumber,
                                    activeProductInvoiceLineItemId: purchaseInvoiceLineItem.id,
                                })}`
                            );
                            if (autoStow)
                                await stow(
                                    SelectionType.Scanned,
                                    undefined,
                                    undefined,
                                    purchaseInvoiceLineItem.purchaseOrderLineItem.product.id,
                                    1
                                );
                            else {
                                setProductSelectionType(SelectionType.Scanned);
                                playPositiveTone();
                                setNumberInHand(1);
                            }
                        }
                    } else {
                        //reaching this point means that there was a product in the db that matched a product we scanned, but it is not something that looks to be in the active purchase order invoice,
                        //so we need to redirect to the search page to decide what we want to do with the product
                        playNegativeTone();
                        setSearchComponentValues({ showSearchComponent: true, searchValue: scannerValue });
                    }
                } else {
                    await showConfirmModal(
                        formatMultipleMatchesString(barcodeProducts, scannerValue),
                        "Multiple Matches In Database",
                        { confirm: "Confirm" },
                        true
                    );
                }
            } else if (barcodeLocations.length > 0) {
                //check to see how many matches there are
                if (barcodeLocations.length === 1) {
                    await stow(productSelectionType, SelectionType.Scanned, { id: barcodeLocations[0].id, name: scannerValue });
                } else {
                    const selectedLocation = await showMultiMatchSelectorModal(
                        "Select Location",
                        barcodeLocations.map((x) => x.id),
                        MultiMatchDataType.Location
                    );
                    if (!selectedLocation) return;
                    await stow(productSelectionType, SelectionType.Scanned, { id: selectedLocation.id, name: selectedLocation.name });
                }
            } else {
                //reaching here means there are no matches and we need to navigate to the search page
                playNegativeTone();
                if (numberInHand > 0) return;
                setSearchComponentValues({ showSearchComponent: true, searchValue: scannerValue });
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    if (purchaseInvoiceError || (!purchaseInvoiceLoading && !purchaseInvoiceData))
        return (
            <ErrorDisplay onClick={purchaseInvoiceRefetch}>
                {purchaseInvoiceError?.message ?? "Fetching data for purchase orders failed"}
            </ErrorDisplay>
        );

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/returns/selectreturnnumber`} />;
    if (!params.purchaseInvoiceId) return <Redirect to={`/mobile/returns/${params.purchaseOrderId}/selecttrackingnumber`} />;

    if (defaultScrapLocationError)
        return <ErrorDisplay onClick={defaultScrapLocationRefetch}>{defaultScrapLocationError.message}</ErrorDisplay>;

    const isLoading = [purchaseInvoiceLoading, manualLoading, defaultScrapLocationLoading].some((x) => x === true);

    const restockConditions = [ProductCondition.New, ProductCondition.Used];
    const scrapConditions = [ProductCondition.ManufacturingDefect, ProductCondition.PartsMissing];

    const whereToPutProduct = restockConditions.some((x) => x === activeItem?.actualCondition) ? "Any" : "Scrap";

    const increment = () => (!activeItem ? void 0 : setNumberInHand(numberInHand + 1));

    const decrement = () => (numberInHand < 1 ? void 0 : setNumberInHand(numberInHand - 1));

    const max = () => (!activeItem ? void 0 : setNumberInHand(activeItem.expectedQuantity - activeItem.actualQuantity));

    const verifySetAutoStow = async () => {
        const confirmed =
            numberInHand < 1
                ? true
                : await showConfirmModal("You have items in hand, do you want to put them back?", "Put Items Back?", {
                      confirm: "Yes",
                      cancel: "No",
                  });
        if (!confirmed) return;
        setNumberInHand(0);
        setAutoStow(!autoStow);
    };

    const done = async () => {
        if (numberInHand > 0)
            await showConfirmModal(
                "There are items in your hand. Please put them back or intake them first.",
                "Items Still In Hand",
                undefined,
                true
            );
        else history.push(`/mobile/selectrole`);
    };

    const addOrPrintBarcode = () => {
        if (!activeItem?.purchaseOrderLineItem?.product?.id) return;
        history.push(
            `/mobile/returns/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${
                activeItem.purchaseOrderLineItem.product.id
            }/addorprintbarcode?${forgeURLSearchParams({
                activeProductInvoiceLineItemId: activeItem.purchaseOrderLineItem.id,
            })}`
        );
    };

    const onClickRemainingItemsOnInvoiceHandler = (purchaseInvoiceLineItem: IPurchaseInvoiceLineItem) => {
        setProductSelectionType(SelectionType.Selected);
        history.push(
            `${location.pathname}?${forgeURLSearchParams({
                trackingNumber: urlSearchParams.trackingNumber,
                activeProductInvoiceLineItemId: purchaseInvoiceLineItem.id,
            })}`
        );
    };

    const determineProductConditionHandler = (productCondition: ProductCondition) => {
        if (!purchaseInvoiceData?.purchaseInvoice) return;
        if (!activeItem) return;
        setPurchaseInvoiceMutationLoading(true);
        const original: IPurchaseInvoiceInputModel = {
            id: purchaseInvoiceData.purchaseInvoice.id,
            invoiceDate: purchaseInvoiceData.purchaseInvoice.invoiceDate,
            invoiceNumber: purchaseInvoiceData.purchaseInvoice.invoiceNumber,
            paymentTermId: purchaseInvoiceData.purchaseInvoice.paymentTermId,
            purchaseOrderId: purchaseInvoiceData.purchaseInvoice.purchaseOrderId,
            status: purchaseInvoiceData.purchaseInvoice.status,
            subtotal: purchaseInvoiceData.purchaseInvoice.subtotal,
            tax: purchaseInvoiceData.purchaseInvoice.tax,
            total: purchaseInvoiceData.purchaseInvoice.total,
            lineItems: [
                {
                    actualQuantity: activeItem.actualQuantity,
                    discount: activeItem.discount,
                    expectedQuantity: activeItem.expectedQuantity,
                    id: activeItem.id,
                    purchaseOrderLineItemId: activeItem.purchaseOrderLineItem.id,
                    total: activeItem.total,
                    unitPrice: activeItem.unitPrice,
                    actualCondition: activeItem.actualCondition,
                },
            ],
        };

        let modified = { ...original };
        modified.lineItems = [
            {
                actualQuantity: activeItem.actualQuantity,
                discount: activeItem.discount,
                expectedQuantity: activeItem.expectedQuantity,
                id: activeItem.id,
                purchaseOrderLineItemId: activeItem.purchaseOrderLineItem.id,
                total: activeItem.total,
                unitPrice: activeItem.unitPrice,
                actualCondition: productCondition,
            },
        ];

        runPurchaseInvoiceMutation({
            variables: {
                modified: modified,
                original: original,
            },
        }).then(
            (res) => {
                purchaseInvoiceData.purchaseInvoice = res.data.purchaseInvoice.edit;
                setPurchaseInvoiceMutationLoading(false);
                setShowSelectConditionModal(false);
            },
            (error) => {
                alert((error as GraphQLError).message ?? "Unknown Error");
                setPurchaseInvoiceMutationLoading(false);
            }
        );
    };

    const stow = async (
        productSelectionType: SelectionType,
        locationSelectionType?: SelectionType,
        toLocation?: { id: string; name: string },
        productId?: string,
        quantity?: number
    ) => {
        try {
            if (
                !purchaseInvoiceData?.purchaseInvoice ||
                isLoading ||
                manualLoading ||
                purchaseInvoiceLoading ||
                !activeItem ||
                (quantity && (quantity < 1 || quantity > activeItem.expectedQuantity - activeItem.actualQuantity)) ||
                !defaultScrapLocationId
            )
                return;
            if (numberInHand < 1) {
                playNegativeTone();
                return alert("There are no items in hand.");
            }
            setManualLoading(true);
            let selectedLocation: { id: string; name: string } | undefined = undefined;
            if (scrapConditions.some((x) => x === activeItem.actualCondition)) {
                selectedLocation = { id: defaultScrapLocationId, name: "Scrap" }; //<----- need to set the id to a special location that is used for scrap.
                locationSelectionType = SelectionType.None;
            } else if (toLocation) {
                selectedLocation = { id: toLocation.id, name: toLocation.name };
            } else {
                if (!activeItem.purchaseOrderLineItem.product?.id) return alert("Could not find a product id for the active item.");
                let locationName = (
                    await showLocationInputModal({
                        modalTitle: "Enter a Location Name",
                        productId: activeItem.purchaseOrderLineItem.product.id,
                        sku: activeItem.purchaseOrderLineItem.product.sku,
                    })
                )?.trim();
                if (!locationName) return;
                const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: true } });
                const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
                if (barcodeLocations.length > 0) {
                    if (barcodeLocations.length > 1) {
                        return alert(`Multiple locations found matching value '${locationName}'. Please try again.`);
                    } else selectedLocation = { id: barcodeLocations[0].id, name: locationName };
                } else {
                    return playNegativeTone();
                }

                locationSelectionType = SelectionType.Selected;
            }
            if (!selectedLocation || !activeItem?.purchaseOrderLineItem?.product?.id) return;

            const res = await runMoveFromPurchaseInvoiceMutation({
                variables: {
                    toLocationId: selectedLocation.id,
                    productId: productId ?? activeItem.purchaseOrderLineItem.product.id,
                    purchaseInvoiceId: params.purchaseInvoiceId,
                    quantity: quantity ?? numberInHand,
                    locationSelectionType: !locationSelectionType ? SelectionType.None : locationSelectionType,
                    productSelectionType: productSelectionType,
                },
            });

            const resData = res.data;
            purchaseInvoiceData.purchaseInvoice = resData.query.purchaseInvoice;
            if (activeItem.expectedQuantity - activeItem.actualQuantity < 1)
                history.push(
                    `${location.pathname}?${forgeURLSearchParams({
                        trackingNumber: urlSearchParams.trackingNumber,
                        activeProductInvoiceLineItemId: undefined,
                    })}`
                );
            playPositiveTone();
            actionBarContext.setActionBarContent(`Transfered ${numberInHand} to ${selectedLocation.name}`);
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setNumberInHand(0);
            setManualLoading(false);
        }
    };

    const nextButtonHandler = () => {};

    const invoiceComplete = async () => {
        if (isLoading || !purchaseInvoiceData?.purchaseInvoice) return;
        try {
            setManualLoading(true);
            const confirmed = await showConfirmModal("Are you sure you want to complete this invoice?", "Complete Invoice?", {
                confirm: "Yes",
                cancel: "No",
            });
            if (!confirmed) return;
            await runChangeInvoiceStatusMutation({
                variables: {
                    purchaseInvoiceId: params.purchaseInvoiceId,
                    newStatus: PurchaseInvoiceStatus.Completed,
                    oldStatus: purchaseInvoiceData.purchaseInvoice.status,
                },
            });
            const urlSearchParams = new URLSearchParams([["invoicesWarning", "true"]]);
            history.push(`/mobile/returns/${params.purchaseOrderId}/selecttrackingnumber?${urlSearchParams.toString()}`);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const go = () => {
        if (!inputRef.current?.value) return;
        const value = inputRef.current.value.trim();
        if (!value) return;
        setSearchComponentValues({ showSearchComponent: true, searchValue: value });
    };

    if (searchComponentValues.showSearchComponent)
        return (
            <PurchaseInvoiceSearch
                searchComponentProps={searchComponentValues}
                setSearchComponentProps={setSearchComponentValues}
                componentType={PurchaseInvoiceSearchComponentType.Return}
                setShowAddOrPrintBarcodeModal={setShowAddOrPrintBarcodeModal}
            />
        );
    else {
        return (
            <>
                {isLoading ? <MobileLoading fullscreen /> : <></>}

                <PrimaryHeader
                    Title={`Invoice ${purchaseInvoiceData?.purchaseInvoice?.id ?? ""}`}
                    IncludeBackButton
                    BackButtonText="< Select Invoice"
                    CustomBackButtonPath={`/mobile/returns/${params.purchaseOrderId}/selecttrackingnumber`}
                    IncludeHomeButton
                    CustomHomeButtonFunctionAsync={async () => await done()}
                />

                <SecondaryHeader Title="Scan Barcode" NextButtonHandler={nextButtonHandler} />

                <div style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                    <input
                        ref={inputRef}
                        onFocus={(e) => e.currentTarget.select()}
                        style={{
                            padding: 10,
                            alignSelf: "stretch",
                            height: 40,
                            borderRadius: 4,
                            border: "1px solid #798394",
                            marginRight: 10,
                            width: "100%",
                        }}
                        placeholder="Search by Part Number..."
                    />
                    <button
                        style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                        onClick={go}
                    >
                        <b>GO</b>
                    </button>
                </div>

                <MobileTable isActiveTable>
                    <thead>
                        <tr>
                            <td>Shipment</td>
                            <td>Hand</td>
                            <td>Bin</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{!activeItem ? "-" : activeItem.expectedQuantity - activeItem.actualQuantity - numberInHand}</td>
                            <td>{!activeItem ? "-" : numberInHand}</td>
                            <td>{!activeItem ? "-" : "-"}</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#CC0A3B" }}>
                                {!activeItem ? "-" : purchaseInvoiceData?.purchaseInvoice?.invoiceNumber ?? ""}
                            </td>
                            <td>{!activeItem ? "-" : activeItem.purchaseOrderLineItem.product?.sku ?? "(No part number)"}</td>
                            <td>{!activeItem ? "-" : whereToPutProduct}</td>
                        </tr>
                    </tbody>
                </MobileTable>

                <div style={{ padding: "10px 10px 5px 10px" }}>
                    <ActiveProductImages sku={activeItem?.purchaseOrderLineItem?.product?.sku} />
                </div>

                {/* category */}
                <div style={{ fontSize: 12, margin: "5px 10px", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <>Category: </>
                        <b>{activeItem?.purchaseOrderLineItem?.product?.description ?? "(None)"}</b>
                    </div>
                    <div>
                        <>Condition: </>
                        <b>{caseFormatter(activeItem?.actualCondition, ["_"])}</b>
                    </div>
                </div>

                <MobileButtonContainer>
                    {/* buttons 1 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            <MobileButton
                                disabled={!activeItem || autoStow}
                                onClick={async () => await stow(productSelectionType)}
                            >{`Stow (${numberInHand}) to Bin ${whereToPutProduct}`}</MobileButton>
                        </MobileButtonCol>
                        <MobileButtonCol>
                            <MobileButton disabled={!activeItem || autoStow} onClick={decrement}>
                                -
                            </MobileButton>
                            <MobileButton disabled={!activeItem || autoStow} onClick={increment}>
                                +
                            </MobileButton>
                            <MobileButton disabled={!activeItem || autoStow} onClick={max}>
                                Max
                            </MobileButton>
                        </MobileButtonCol>
                    </MobileButtonRow>

                    {/* buttons 2 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            <MobileButton disabled onClick={verifySetAutoStow}>
                                Auto Stow ({autoStow ? "On" : "Off"})
                            </MobileButton>
                            <MobileButton onClick={invoiceComplete}>Invoice Complete</MobileButton>
                        </MobileButtonCol>
                    </MobileButtonRow>

                    {/* buttons 3 */}
                    <MobileButtonRow>
                        <MobileButtonCol>
                            <MobileButton disabled={!activeItem} onClick={addOrPrintBarcode}>
                                Add Or Print Barcode
                            </MobileButton>
                            <MobileButton disabled={!activeItem} onClick={() => setShowSelectConditionModal(true)}>
                                Change Condition
                            </MobileButton>
                        </MobileButtonCol>
                    </MobileButtonRow>
                </MobileButtonContainer>

                {/*Remaining Items On Invoice*/}
                {remainingPurchaseInvoiceLineItems.length < 1 ? (
                    <></>
                ) : (
                    <div>
                        <MobileTableTitle>Remaining Items On Invoice</MobileTableTitle>

                        <MobileTable>
                            <tbody>
                                {remainingPurchaseInvoiceLineItems.map((pili, i) => {
                                    return (
                                        <tr key={i.toString() + "a"} onClick={() => onClickRemainingItemsOnInvoiceHandler(pili)}>
                                            <td>{purchaseInvoiceData?.purchaseInvoice?.invoiceNumber ?? ""}</td>
                                            <td>{pili.purchaseOrderLineItem.product?.sku ?? "(No SKU)"}</td>
                                            <td>{pili.expectedQuantity - pili.actualQuantity}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </MobileTable>
                    </div>
                )}

                {!showSelectConditionModal ? (
                    <></>
                ) : (
                    <div style={{ width: "100%", position: "fixed", top: 0, backgroundColor: "white", height: "100vh" }}>
                        {purchaseInvoiceMutationLoading ? <MobileLoading fullscreen /> : <></>}

                        <PrimaryHeader
                            Title={activeItem?.purchaseOrderLineItem?.product?.sku ?? ""}
                            IncludeBackButton
                            BackButtonText="<"
                            CustomBackButtonFunction={() => {
                                if (!activeItem?.actualCondition) history.goBack();
                                setShowSelectConditionModal(false);
                            }}
                            IncludeHomeButton
                        />

                        <SecondaryHeader Title="Determine Product Condition" NextButtonHandler={() => void 0} />

                        <MobileTable>
                            <thead>
                                <tr>
                                    <td></td>
                                </tr>
                            </thead>
                        </MobileTable>

                        <MobileButtonContainer>
                            <MobileButtonRow>
                                <MobileButton onClick={() => determineProductConditionHandler(ProductCondition.New)}>
                                    {caseFormatter(ProductCondition.New)}
                                </MobileButton>
                                <MobileButton onClick={() => determineProductConditionHandler(ProductCondition.Used)}>
                                    {caseFormatter(ProductCondition.Used)}
                                </MobileButton>
                            </MobileButtonRow>
                            <MobileButtonRow>
                                <MobileButton onClick={() => determineProductConditionHandler(ProductCondition.ManufacturingDefect)}>
                                    {caseFormatter(ProductCondition.ManufacturingDefect)}
                                </MobileButton>
                                <MobileButton onClick={() => determineProductConditionHandler(ProductCondition.PartsMissing)}>
                                    {caseFormatter(ProductCondition.PartsMissing, ["_"])}
                                </MobileButton>
                            </MobileButtonRow>
                        </MobileButtonContainer>

                        <div style={{ display: "flex", flexDirection: "column", margin: "0 10px" }}>
                            <div>
                                Return Reason: <b>{purchaseInvoiceData?.purchaseInvoice?.purchaseOrder?.returnReason?.description ?? ""}</b>
                            </div>
                            <div>
                                Notes: <b>{purchaseInvoiceData?.purchaseInvoice?.purchaseOrder?.notes ?? "(No notes)"}</b>
                            </div>
                            {activeItem?.purchaseOrderLineItem?.expectedCondition ? (
                                <div>
                                    Expected Condition: <b>{caseFormatter(activeItem.actualCondition, ["_"])}</b>
                                </div>
                            ) : (
                                <></>
                            )}
                            {activeItem?.actualCondition ? (
                                <div>
                                    Condition: <b>{caseFormatter(activeItem.actualCondition, ["_"])}</b>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )}

                {createElement(locationInputModal)}

                {createElement(confirmModal)}

                {createElement(multiMatchSelectorModal)}
            </>
        );
    }
};

export default ScanBarcode;
