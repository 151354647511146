import { Fragment } from "react";
import AuthorizedAnchor from "../AuthorizedAnchor";
import styles from "./Attachments.module.scss";
import { Link } from "react-router-dom";

export interface IAttachment {
    id: string;
    dateUploaded: Date;
    url: string;
    data: string;
    isLabel: boolean;
    trackingNumber: string | null;
    labelProvider: {
        name: string;
    } | null;
    labelProviderShipmentId: string | null;
    shippingServiceId: string | null;
    filename: string;
}

export interface IAttachmentWithSaleId extends IAttachment {
    saleId?: string;
}

interface IAttachmentGroup {
    title: string;
    attachments: IAttachmentWithSaleId[];
}

interface IProps {
    attachments: Array<IAttachmentGroup>;
    deleteAttachmentAsync: (attachmentId: string) => Promise<void>;
}

const Attachments = (props: IProps) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            {props.attachments.map((attachmentGroup, a) => {
                return !attachmentGroup.attachments.length ? (
                    <Fragment key={a}></Fragment>
                ) : (
                    <div key={a}>
                        <h5>{attachmentGroup.title}</h5>
                        <div className={styles.rowsContainer}>
                            {attachmentGroup.attachments.map((attachment, b) => {
                                return (
                                    <div key={attachment.id} className={styles.attachmentRow}>
                                        <AuthorizedAnchor href={attachment.url} target="_blank" rel="noreferrer">
                                            {attachment.filename}
                                        </AuthorizedAnchor>
                                        {attachment.saleId ? (
                                            <Link to={`/sales/${attachment.saleId}/attachments`}>SO-{attachment.saleId}</Link>
                                        ) : null}
                                        {attachment.labelProvider ? <div>{attachment.labelProvider.name}</div> : null}
                                        {attachment.labelProviderShipmentId ? <div>{attachment.labelProviderShipmentId}</div> : null}
                                        {!attachment.saleId ? (
                                            <button
                                                onClick={async () => await props.deleteAttachmentAsync(attachment.id)}
                                                className="btn btn-danger"
                                            >
                                                Delete
                                            </button>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Attachments;
