import { Route, Switch } from "react-router-dom";
import GroupBy from "../../../../helpers/GroupBy";
import AdminGenericComponent from "./AdminGenericComponent";
import AdminHome from "./AdminHome";
import FindOpenSaleFulfillments from "./pack/FindOpenSaleFulfillments";
import ManageCartsWrapper from "./cart/ManageCarts/ManageCartsWrapper";
import ProductManager from "./inventory/ProductManager/ProductManager";
import Product from "./inventory/ProductManager/Product/Product";
import StrictOmit from "../../../../types/StrictOmit";
import PrintLocationBarcode from "./printer/PrintLocationBarcode/PrintLocationBarcode";
import PrintMailInnovationsBagLabel from "./printer/PrintMailInnovationsBagLabel/PrintMailInnovationsBagLabel";

/**
 * Base properties shared between all admin components
 * */
type TAdminComponentBase = {
    /**
     * The name that will display in the table of functions for the associated route
     * */
    displayName: string;
    /**
     * The actual component of the function for the associated route
     * */
    component: JSX.Element;
    /**
     * The route where the component should appear
     * */
    routeType: EAdminRouteType;
    /**
     * Child routes of a component. Will render recursively.
     */
    childRoutes?: TAdminComponentChild[];
};

/**
 * The various types of properties a TAdminComponentBase could have.
 * Some properties are only required if another property is set to a specific value.
 * */
type Variations = { openInModal: true } | { openInModal: false; path: string; exact: boolean };

export type TAdminComponent = TAdminComponentBase & Variations;

export type TAdminComponentChild = StrictOmit<TAdminComponentBase, "routeType"> & Variations;

export type IAdminRoute = {
    /**
     * The name that will display in the table of roles for the admin route
     * */
    displayName: string;
    /**
     * The path name for the associated route type
     * */
    pathName: string;
    /**
     * The type of route that the component will be associated with
     * */
    routeType: EAdminRouteType;
};

export enum EAdminRouteType {
    Pack,
    Pick,
    StockTransfer,
    StockTake,
    Cart,
    Inventory,
    Print,
}

const AdminController = () => {
    /**
     * All of the different roles admin functions could fall under
     * Add to this array the required properties to add a new role to be displayed.
     * */
    const adminRoutes: IAdminRoute[] = [
        { displayName: "Pack", pathName: "pack", routeType: EAdminRouteType.Pack },
        { displayName: "Cart", pathName: "cart", routeType: EAdminRouteType.Cart },
        { displayName: "Inventory", pathName: "inventory", routeType: EAdminRouteType.Inventory },
        { displayName: "Print", pathName: "print", routeType: EAdminRouteType.Print },
    ];

    /**
     * All of the admin components that will be rendered to the appropriate route.
     * Add to this array the required properties to add a new function to be displayed.
     * */
    const components: TAdminComponent[] = [
        {
            displayName: "Find Open Sale Fulfillments (Page)",
            component: <FindOpenSaleFulfillments />,
            routeType: EAdminRouteType.Pack,
            openInModal: false,
            path: "findopensalefulfillments",
            exact: true,
        },
        {
            displayName: "Manage Carts",
            component: <ManageCartsWrapper />,
            routeType: EAdminRouteType.Cart,
            openInModal: false,
            path: "managecarts",
            exact: true,
        },
        {
            displayName: "Product Manager",
            component: <ProductManager />,
            routeType: EAdminRouteType.Inventory,
            openInModal: false,
            path: "productmanager",
            exact: false,
            childRoutes: [
                {
                    displayName: "Product",
                    component: <Product />,
                    openInModal: false,
                    path: ":productId",
                    exact: true,
                },
            ],
        },
        {
            displayName: "Print Location Barcode",
            component: <PrintLocationBarcode />,
            routeType: EAdminRouteType.Print,
            openInModal: false,
            path: "printlocationbarcode",
            exact: true,
        },
        {
            displayName: "Print Mail Innovations Bag Label",
            component: <PrintMailInnovationsBagLabel />,
            routeType: EAdminRouteType.Print,
            openInModal: false,
            path: "printmailinnovationsbaglabel",
            exact: true,
        },
    ];

    const componentsByRouteType = GroupBy(components, (c) => c.routeType);

    return (
        <Switch>
            <Route exact path={`/mobile/admin`}>
                <AdminHome adminRoutes={adminRoutes} />
            </Route>

            {Array.from(componentsByRouteType).map(([key, values], i) => {
                const adminRoute = adminRoutes.find((route) => route.routeType === key);
                if (!adminRoute || values.length < 1) return <></>;
                return (
                    <Route key={i} path={`/mobile/admin/${adminRoute.pathName}`}>
                        <AdminGenericComponent components={values} adminRoute={adminRoute} />
                    </Route>
                );
            })}
        </Switch>
    );
};

export default AdminController;
