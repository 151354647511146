export default function GroupBy<TK extends keyof any, T>(array: T[], k: (item: T) => TK) {
    let map = new Map<TK, T[]>();
    array.forEach((item) => {
        let key = k(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
