import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch } from "react-router-dom";
import PageHeader from "../../../components/pageheader/PageHeader";
import EbayIndex from "./ebay/EbayIndex";
import AmazonIndex from "./amazon/AmazonIndex";

const Integrations = () => {
    return (
        <>
            <PageHeader>Integrations</PageHeader>
            <Nav variant="tabs" className="mb-3">
                <Nav.Item>
                    <LinkContainer to="/setup/integrations/zbox" exact>
                        <Nav.Link>Zbox</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/setup/integrations/ebay" exact>
                        <Nav.Link>Ebay</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/setup/integrations/amazon" exact>
                        <Nav.Link>Amazon</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/setup/integrations/ebay" component={EbayIndex} exact />
                <Route path="/setup/integrations/amazon" component={AmazonIndex} exact />
            </Switch>
        </>
    );
};

export default Integrations;
