import { GraphQLError, useMutation } from "@shane32/graphql";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import PageHeader from "../../../components/pageheader/PageHeader";
import styles from "./ProductMovementLogs.module.scss";

const exportProductLocationSearchedSelectedMutation = `
mutation ($startDate: DateTimeOffset, $endDate: DateTimeOffset) {
    api {
      importExport {
        export {
          exportProductLocationSearchedSelected(startDate: $startDate, endDate: $endDate)
        }
      }
    }
  }
`;

interface IExportProductLocationSearchedSelectedMutationVariables {
    endDate: string | null;
    startDate: string | null;
}

interface IExportProductLocationSearchedSelectedMutationResponse {
    api: {
        importExport: {
            export: {
                exportProductLocationSearchedSelected: string;
            };
        };
    };
}

enum TimeOfDayType {
    StartOfDay = "StartOfDay",
    EndOfDay = "EndOfDay",
}

const ProductMovementLogs = () => {
    const [runExportProductLocationSearchedSelectedMutation] = useMutation<
        IExportProductLocationSearchedSelectedMutationResponse,
        IExportProductLocationSearchedSelectedMutationVariables
    >(exportProductLocationSearchedSelectedMutation);
    const [isCurrentlyExporting, setIsCurrentlyExporting] = useState(false);
    const startDateInputRef = useRef<HTMLInputElement>(null);
    const endDateInputRef = useRef<HTMLInputElement>(null);

    const formatDate = (date: Date, timeOfDayType: TimeOfDayType) => {
        // get the timezone offset in minutes
        const timezoneOffset = date.getTimezoneOffset();
        // adjust the date to the next day in local time zone
        date.setDate(date.getDate() + 1);
        // adjust the hours based on the timezone offset.
        switch (timeOfDayType) {
            case TimeOfDayType.StartOfDay:
                // sets the time to 00:00:00 so that the date range starts at the beginning of the day
                date.setHours(0 - timezoneOffset / 60, 0, 0);
                break;
            case TimeOfDayType.EndOfDay:
                // sets the time to 23:59:59 so that the date range is inclusive
                date.setHours(23 - timezoneOffset / 60, 59, 59);
                break;
        }
        // convert the date to ISO string format (which represents the date and time in UTC)
        return date.toISOString();
    };

    const exportLogs = () => {
        if (isCurrentlyExporting) return;
        const endDate = endDateInputRef.current?.value ? formatDate(new Date(endDateInputRef.current.value), TimeOfDayType.EndOfDay) : null;
        const startDate = startDateInputRef.current?.value
            ? formatDate(new Date(startDateInputRef.current.value), TimeOfDayType.StartOfDay)
            : null;
        setIsCurrentlyExporting(true);
        runExportProductLocationSearchedSelectedMutation({
            variables: {
                endDate: endDate,
                startDate: startDate,
            },
        }).then(
            (response) => {
                let returnedData = response.data.api.importExport.export.exportProductLocationSearchedSelected;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `ProductLocationSearchedSelectedLogs.xlsx`;
                fileLink.click();
                setIsCurrentlyExporting(false);
            },
            (error: GraphQLError) => {
                alert(error.message);
                setIsCurrentlyExporting(false);
            }
        );
    };

    return (
        <>
            <PageHeader>Product Location Search Selected Logs</PageHeader>
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <div>Select a date range to view the logs. If no range is provided, then all available logs will be selected.</div>
                <div style={{ display: "flex", gap: 50 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", gap: 5 }}>
                            <label>Start Date</label>
                            <div className={styles.dateInfoContainer}>
                                ⓘ
                                <div className={styles.dateInfoContent}>
                                    Sets the time to 00:00:00 so that the date range starts at the beginning of the day.
                                </div>
                            </div>
                        </div>
                        <input disabled={isCurrentlyExporting} ref={startDateInputRef} type="date" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", gap: 5 }}>
                            <label>End Date</label>
                            <div className={styles.dateInfoContainer}>
                                ⓘ
                                <div className={styles.dateInfoContent}>Sets the time to 23:59:59 so that the date range is inclusive.</div>
                            </div>
                        </div>
                        <div>
                            <input disabled={isCurrentlyExporting} ref={endDateInputRef} type="date" />
                        </div>
                    </div>
                </div>

                <Button disabled={isCurrentlyExporting} style={{ maxWidth: 100 }} onClick={exportLogs}>
                    {!isCurrentlyExporting ? "Export Logs" : "Exporting..."}
                </Button>
            </div>
        </>
    );
};

export default ProductMovementLogs;
