import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IHiveLink {
    id: string;
    name: string;
}

interface IDevicesQueryResult {
    deviceSubTypes: {
        enumValues: Array<{
            name: string;
            description: string | null;
        }>;
    };
    hiveLinks: {
        items: IHiveLink[];
    };
    stationSets: {
        items: IStationSet[];
    };
    devices: {
        items: IDevice[];
    };
}

interface IDevice {
    id: string;
    description: string;
    location: string | null;
    deviceTypes: string[];
    type: string;
    stationSetId: string | null;
    paperWidth: number | null;
    paperHeight: number | null;
    sortOrder: number;
    hiveLinkId: string | null;
    hostName: string | null;
    port: number | null;
    connectionGuid: string;
    active: boolean;
}

interface IStationSet {
    id: string;
    name: string;
    type: string;
    active: boolean;
}

interface IDeviceModel {
    id: string;
    description: string;
    location: string;
    deviceTypePrinter: boolean;
    deviceTypeScale: boolean;
    deviceTypeDimensioner: boolean;
    deviceTypeCamera: boolean;
    type: string;
    stationSetId: string;
    paperWidth: string;
    paperHeight: string;
    sortOrder: string;
    hiveLinkId: string;
    hostName: string;
    port: string;
    active: boolean;
    connectionGuid: string;
}

interface IEditResult {
    device: {
        edit: IDevice;
    };
}

interface IEditVariables {
    original: Omit<IDevice, "connectionGuid">;
    modified: Omit<IDevice, "connectionGuid">;
}

interface IAddResult {
    device: {
        add: IDevice;
    };
}

interface IAddVariables {
    value: Omit<IDevice, "connectionGuid">;
}

interface IDeleteResult {
    device: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: IDevice;
    guid?: string;
}

const hiddenModal: IModal = {
    show: false,
};

//define global functions
function sortDevices(a: IDevice, b: IDevice) {
    return a.sortOrder - b.sortOrder || a.type.localeCompare(b.type) || a.description.localeCompare(b.description);
}

interface ITestPrintResult {
    api: {
        print: {
            testPage: {
                print: boolean;
            };
        };
    };
}
interface ITestPrintVariables {
    printerId: string;
    width: number | null;
    height: number | null;
    type: "GENERIC" | "RECEIPT" | "COLOR" | "LABEL";
}

const PrinterTestQuery = `
mutation ($printerId: ID!, $height: Decimal, $type: DeviceSubType!, $width: Decimal) {
  api {
    print {
      testPage(height: $height, type: $type, width: $width) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

const WeightTestQuery = `
mutation ($id: ID!) {
  device {
    readWeight(id: $id)
  }
}
`;

interface ITestWeightResult {
    device: {
        readWeight: number;
    };
}

const DimensionTestQuery = `
mutation ($id: ID!) {
  device {
    readDimensions(id: $id) {
      width
      height
      length
    }
  }
}
`;

interface ITestDimensionResult {
    device: {
        readDimensions: {
            width: number;
            height: number;
            length: number;
        };
    };
}

const DevicesQuery = `
{
  deviceSubTypes: __type(name: "DeviceSubType") {
    enumValues (includeDeprecated: false) {
      name
      description
    }
  }
  hiveLinks {
    items {
      id
      name
    }
  }
  stationSets {
    items {
      id
      name
      type
      active
    }
  }
  devices {
    items {
      id
      description
      deviceTypes
      type
      location
      stationSetId
      paperWidth
      paperHeight
      sortOrder
      hiveLinkId
      hostName
      port
      active
      connectionGuid
    }
  }
}
`;

const AddDeviceMutation = `
mutation ($value: DeviceInput!) {
  device {
    add(value: $value) {
      id
      description
      deviceTypes
      type
      location
      paperWidth
      paperHeight
      stationSetId
      sortOrder
      hostName
      port
      active
      connectionGuid
    }
  }
}
`;

const EditDeviceMutation = `
mutation ($original: DeviceInput!, $modified: DeviceInput!) {
  device {
    edit(original: $original, modified: $modified) {
      id
      description
      deviceTypes
      type
      location
      paperWidth
      paperHeight
      stationSetId
      sortOrder
      hiveLinkId
      hostName
      port
      active
      connectionGuid
    }
  }
}
`;

const DeleteDeviceMutation = `
mutation ($id: ID!) {
  device {
    delete(id: $id)
  }
}
`;

const DevicesIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [tempModel, setTempModel] = React.useState<IDeviceModel>({} as IDeviceModel);
    const [saving, setSaving] = React.useState(false);

    //=== set up queries and mutations ===
    //queries
    const { data, error, refetch } = useQuery<IDevicesQueryResult>(DevicesQuery, { fetchPolicy: "no-cache" });
    const [runTestPrint] = useMutation<ITestPrintResult, ITestPrintVariables>(PrinterTestQuery);
    const [runTestWeight] = useMutation<ITestWeightResult, { id: string }>(WeightTestQuery);
    const [runTestDimensions] = useMutation<ITestDimensionResult, { id: string }>(DimensionTestQuery);

    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditDeviceMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddDeviceMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteDeviceMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data) return <Loading />;

    const onShowEditModal = (value: IDevice) => {
        setModal({
            show: true,
            original: value,
        });
    };
    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({
            show: true,
            guid: "(new)",
        });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };
    const onTest = async () => {
        setSaving(true);
        try {
            console.log("tempModel", tempModel);
            // test print
            if (tempModel.deviceTypePrinter) {
                try {
                    await runTestPrint({
                        variables: {
                            printerId: originalModel.id,
                            width: originalModel.paperWidth ? parseFloat(originalModel.paperWidth) : null,
                            height: originalModel.paperHeight ? parseFloat(originalModel.paperHeight) : null,
                            type: originalModel.type as "GENERIC" | "RECEIPT" | "COLOR" | "LABEL",
                        },
                    });
                    alert("Print Success!");
                } catch (err: any) {
                    console.error(`Error trying to test print`, err);
                    alert(err.message);
                }
            }
            // test read scale
            if (tempModel.deviceTypeScale) {
                try {
                    const ret = await runTestWeight({
                        variables: {
                            id: originalModel.id,
                        },
                    });
                    alert("Read weight: " + ret.data?.device.readWeight);
                } catch (err: any) {
                    console.error(`Error trying to test read weight`, err);
                    alert(err.message);
                }
            }
            // test read dimensions
            if (tempModel.deviceTypeDimensioner) {
                try {
                    const ret = await runTestDimensions({
                        variables: {
                            id: originalModel.id,
                        },
                    });
                    alert("Read dimensions: " + JSON.stringify(ret.data.device.readDimensions));
                } catch (err: any) {
                    console.error(`Error trying to test read dimensions`, err);
                    alert(err.message);
                }
            }
        } finally {
            setSaving(false);
        }
    };
    const originalModel: IDeviceModel = modal.original
        ? {
              id: modal.original.id,
              description: modal.original.description,
              type: modal.original.type,
              location: modal.original.location || "",
              stationSetId: modal.original.stationSetId || "",
              sortOrder: modal.original.sortOrder.toString(),
              hiveLinkId: modal.original.hiveLinkId || "",
              hostName: modal.original.hostName || "",
              port: modal.original.port?.toString() || "",
              active: modal.original.active,
              connectionGuid: modal.original.connectionGuid,
              paperWidth: modal.original.paperWidth ? modal.original.paperWidth.toString() : "",
              paperHeight: modal.original.paperHeight ? modal.original.paperHeight.toString() : "",
              deviceTypePrinter: modal.original.deviceTypes.includes("PRINTER"),
              deviceTypeScale: modal.original.deviceTypes.includes("SCALE"),
              deviceTypeDimensioner: modal.original.deviceTypes.includes("DIMENSIONER"),
              deviceTypeCamera: modal.original.deviceTypes.includes("CAMERA"),
          }
        : {
              id: "",
              description: "",
              type: "",
              location: "",
              stationSetId: "",
              sortOrder: "",
              hiveLinkId: "",
              hostName: "",
              port: "",
              active: true,
              connectionGuid: modal.guid || "",
              paperWidth: "",
              paperHeight: "",
              deviceTypePrinter: false,
              deviceTypeScale: false,
              deviceTypeDimensioner: false,
              deviceTypeCamera: false,
          };

    const onSaveChanges = (modified: IDeviceModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            const modifiedDeviceTypes = [];
            if (modified.deviceTypePrinter) modifiedDeviceTypes.push("PRINTER");
            if (modified.deviceTypeScale) modifiedDeviceTypes.push("SCALE");
            if (modified.deviceTypeDimensioner) modifiedDeviceTypes.push("DIMENSIONER");
            if (modified.deviceTypeCamera) modifiedDeviceTypes.push("CAMERA");
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        description: modified.description,
                        deviceTypes: modifiedDeviceTypes,
                        type: modified.type,
                        location: modified.location,
                        stationSetId: modified.stationSetId || null,
                        sortOrder: parseInt(modified.sortOrder),
                        hiveLinkId: modified.hiveLinkId || null,
                        hostName: modified.hostName || null,
                        port: modified.port ? parseInt(modified.port) : null,
                        active: modified.active,
                        paperWidth: modified.paperWidth ? parseFloat(modified.paperWidth) : null,
                        paperHeight: modified.paperHeight ? parseFloat(modified.paperHeight) : null,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.device.add;
                    data.devices.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error adding device", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            const { connectionGuid, ...original } = modal.original;
            const modifiedDeviceTypes = [];
            if (modified.deviceTypePrinter) modifiedDeviceTypes.push("PRINTER");
            if (modified.deviceTypeScale) modifiedDeviceTypes.push("SCALE");
            if (modified.deviceTypeDimensioner) modifiedDeviceTypes.push("DIMENSIONER");
            if (modified.deviceTypeCamera) modifiedDeviceTypes.push("CAMERA");
            runEdit({
                variables: {
                    //pass in original data
                    original: original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        description: modified.description,
                        deviceTypes: modifiedDeviceTypes,
                        type: modified.type,
                        location: modified.location,
                        stationSetId: modified.stationSetId || null,
                        sortOrder: parseInt(modified.sortOrder),
                        hiveLinkId: modified.hiveLinkId || null,
                        hostName: modified.hostName || null,
                        port: modified.port ? parseInt(modified.port) : null,
                        active: modified.active,
                        paperWidth: modified.paperWidth ? parseFloat(modified.paperWidth) : null,
                        paperHeight: modified.paperHeight ? parseFloat(modified.paperHeight) : null,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.device.edit;
                    const oldIndex = data.devices.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.devices.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error editing device", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this device?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.devices.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.devices.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting device", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const hiveLinks = data.hiveLinks.items;
    const devices = data.devices.items.slice().sort(sortDevices);
    const stationSets = data.stationSets.items.filter((x) => x.active === true);
    const getConnectionInfo = (device: IDevice) => {
        const hiveLinkId = device.hiveLinkId;
        const hostName = device.hostName;
        const port = device.port;
        const nameAndPort = hostName && port ? `${hostName}:${port}` : "";
        if (hiveLinkId !== null) {
            const hiveLink = hiveLinks.find((x) => x.id === hiveLinkId);
            return hiveLink && nameAndPort ? `${hiveLink.name} - ${nameAndPort}` : hiveLink ? hiveLink.name : nameAndPort;
        }
        return nameAndPort;
    };
    const deviceSubTypesMap: { [key: string]: string } = {};
    data.deviceSubTypes.enumValues.forEach((enumValue) => {
        deviceSubTypesMap[enumValue.name] = enumValue.description || enumValue.name;
    });
    const card = (active: boolean) => {
        return (
            <Card className="border-primary">
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">{active ? "Active" : "Inactive"} Devices</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Type</th>
                                <th>Location</th>
                                <th>Station Set</th>
                                <th>Paper Size</th>
                                <th>Connection</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices
                                .filter((x) => x.active === active)
                                .map((device) => {
                                    const stationSetName = data.stationSets.items.find((x) => x.id === device.stationSetId);
                                    return (
                                        <tr key={device.id}>
                                            <td>{device.description}</td>
                                            <td>{deviceSubTypesMap[device.type]}</td>
                                            <td>{device.location}</td>
                                            <td>{stationSetName ? stationSetName.name : ""}</td>
                                            <td>
                                                {device.paperWidth && device.paperHeight
                                                    ? `${device.paperWidth}" x ${device.paperHeight}"`
                                                    : ""}
                                            </td>
                                            <td>{getConnectionInfo(device)}</td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    variant="white"
                                                    className="ms-4"
                                                    style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                    onClick={() => onShowEditModal(device)}
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <PageHeader>Devices</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new device
                </Button>
            </p>
            {card(true)}
            {card(false)}
            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id} onChange={setTempModel}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Device</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formDesc">
                                    <VLabel valueName="description" title="Description" />
                                    <VControl type="text" required valueName="description" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formDeviceType">
                                    <VLabel valueName="description" title="Type(s)" />
                                    <VCheck valueName="deviceTypePrinter" label="Printer" />
                                    <VCheck valueName="deviceTypeScale" label="Scale" />
                                    <VCheck valueName="deviceTypeDimensioner" label="Dimensioner" />
                                    <VCheck valueName="deviceTypeCamera" label="Camera" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formType">
                                    <VLabel valueName="type" title="Subtype" />
                                    <VSelect valueName="type" required>
                                        <option value=""></option>
                                        {data.deviceSubTypes.enumValues
                                            .sort((a, b) => (a.description || a.name).localeCompare(b.description || b.name))
                                            .map((enumValue) => (
                                                <option key={enumValue.name} value={enumValue.name}>
                                                    {enumValue.description || enumValue.name}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formStationSet">
                                    <VLabel valueName="stationSetId" title="Station Set" />
                                    <VSelect valueName="stationSetId">
                                        <option></option>
                                        {stationSets.map((stationSet) => (
                                            <option key={stationSet.id} value={stationSet.id}>
                                                {stationSet.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formLocation">
                                    <VLabel valueName="location" title="Location" />
                                    <VControl type="text" valueName="location" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPaperWidth">
                                    <VLabel valueName="paperWidth" title="Paper Width" />
                                    <VControl type="text" valueName="paperWidth" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPaperHeight">
                                    <VLabel valueName="paperHeight" title="Paper Height" />
                                    <VControl type="text" valueName="paperHeight" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formHiveLink">
                                    <VLabel valueName="hiveLinkId" title="Hive Link" />
                                    <VSelect valueName="hiveLinkId">
                                        <option></option>
                                        {hiveLinks.map((hiveLink) => (
                                            <option key={hiveLink.id} value={hiveLink.id}>
                                                {hiveLink.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formHostName">
                                    <VLabel valueName="hostName" title="Host Name" />
                                    <VControl type="text" valueName="hostName" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPort">
                                    <VLabel valueName="port" title="Port" />
                                    <VControl type="text" valueName="port" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formGuid">
                                    <VLabel valueName="connectionGuid" title="Connection Guid" />
                                    <VControl
                                        type="text"
                                        readOnly
                                        valueName="connectionGuid"
                                        pattern="(\{?[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}\}?)"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formSortOrder">
                                    <VLabel valueName="sortOrder" title="Sort Order" />
                                    <VControl type="text" required valueName="sortOrder" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formActive">
                                    <VCheck valueName="active" label="Active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className={modal.original ? undefined : "me-auto"}>
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="white" onClick={onTest} className="me-auto">
                                    Test
                                </VButton>
                            ) : null
                        }
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default DevicesIndex;
