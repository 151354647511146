import { createElement, SyntheticEvent, useRef, useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import StationSetType from "../../../../../../enums/StationSetTypes";
import StationType from "../../../../../../enums/StationTypes";
import useReload from "../../../../hooks/useReload";
import MobileLoading from "../../../../components/MobileLoading";
import styles from "./CartManager.module.scss";
import useSelectModal, { ISelect } from "../../../../hooks/useSelectModal";
import useConfirm from "../../../../hooks/useConfirm";
import { Link } from "react-router-dom";

interface IPickZone {
    id: string;
    name: string;
    active: boolean;
    pickable: boolean;
    isCart: boolean;
    warehouseId: string;
    priority: number;
    station: {
        id: string;
        stationSet: {
            id: string;
            name: string;
            type: StationSetType;
            active: boolean;
        };
        type: StationType;
    } | null;
    lastLocation: {
        name: string;
    } | null;
    locations: Array<{
        id: string;
        name: string;
        active: boolean;
        deleted: boolean;
        products: Array<{
            saleId: string;
            product: {
                id: string;
                sku: string;
            };
            stockOnHand: number;
        }>;
    }>;
}

const pickZoneBaseQuery = `
id
name
active
pickable
isCart
warehouseId
priority
station {
    id
    stationSet {
        id
        name
        type
        active
    }
    type
}
lastLocation {
    name
}
locations {
    id
    name
    active
    deleted
    products {
        saleId
        product {
            id
            sku
        }
        stockOnHand
    }
}
`;

const pickZoneQuery = `
query ($pickZoneId: ID!) {
    pickZone(id: $pickZoneId) {
        ${pickZoneBaseQuery}
    }
  }
`;

interface IPickZoneQueryVariables {
    pickZoneId: string;
}

interface IPickZoneQueryResult {
    pickZone: IPickZone;
}

const stationSetsQuery = `
  {
    stationSets {
      items {
        active
        name
        stations {
          id
          type
        }
      }
    }
  }
`;

interface IStationSetsQueryVariables {}

interface IStationSetsQueryResult {
    stationSets: {
        items: Array<{
            active: boolean;
            name: string;
            stations: Array<{
                id: string;
                type: StationType;
            }>;
        }>;
    };
}

const editPickZoneMutation = `
mutation ($original: PickZoneInput!, $modified: PickZoneInput!) {
    pickZone {
      edit(original: $original, modified: $modified) {
        ${pickZoneBaseQuery}
      }
    }
  }
`;

interface IPickZoneInputModel {
    id: string;
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: boolean;
    isCart: boolean;
    priority: number;
    stationId: string | null;
}

interface IEditPickZoneMutationVariables {
    original: IPickZoneInputModel;
    modified: IPickZoneInputModel;
}

interface IEditPickZoneMutationResult {
    pickZone: {
        edit: IPickZone;
    };
}

interface IProps {
    pickZoneId: string;
}

const CartManager = (props: IProps) => {
    const activeInputRef = useRef<HTMLInputElement>(null);
    const isCartInputRef = useRef<HTMLInputElement>(null);
    const pickableInputRef = useRef<HTMLInputElement>(null);

    const { reloadModal, showReloadModal } = useReload();
    const { selectModal, showSelectModal } = useSelectModal();
    const { confirmModal, showConfirmModal } = useConfirm();
    const [manualLoading, setManualLoading] = useState(false);
    const [runEditPickZoneMutation] = useMutation<IEditPickZoneMutationResult, IEditPickZoneMutationVariables>(editPickZoneMutation);
    const [runStationSetsQuery] = useMutation<IStationSetsQueryResult, IStationSetsQueryVariables>(stationSetsQuery);
    const {
        data: pickZoneData,
        loading: pickZoneLoading,
        error: pickZoneError,
    } = useQuery<IPickZoneQueryResult, IPickZoneQueryVariables>(pickZoneQuery, {
        fetchPolicy: "no-cache",
        variables: {
            pickZoneId: props.pickZoneId,
        },
    });

    if (pickZoneError) {
        showReloadModal("Failed to Load Pick Zones", "An error occurred while loading the pick zones.");
    }

    const loading = [pickZoneLoading, manualLoading].some((x) => x);

    const confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart = async (e: SyntheticEvent) => {
        const saleProducts = (pickZoneData?.pickZone?.locations ?? []).flatMap((location) =>
            location.products.filter((product) => product.saleId)
        );
        if (saleProducts.length < 1) return true;
        e.preventDefault();
        const confirm = await showConfirmModal(
            "This cart has products on it that are associated to a sale. Are you sure you want to modify this pick zone?",
            "Pick Zone Contains Sale Products",
            { confirm: "Yes", cancel: "No" }
        );
        return confirm;
    };

    const selectStation = async () => {
        let stationId: string | null = null;
        try {
            if (loading || !pickZoneData) return null;
            setManualLoading(true);
            const stationSets = (await runStationSetsQuery()).data.stationSets.items.filter((x) => x.active);
            if (stationSets.length === 0) {
                alert("No active station sets found.");
                return null;
            }
            const selects: ISelect[] = stationSets
                .flatMap((stationSet) =>
                    stationSet.stations.map((station) => {
                        return {
                            description: `${stationSet.name} (${station.type})`,
                            id: station.id,
                        };
                    })
                )
                .sort((a, b) => a.description.localeCompare(b.description));
            stationId = (await showSelectModal("Select a Station", selects))?.id ?? null;
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "An error occurred while fetching stations.");
        } finally {
            setManualLoading(false);
            return stationId;
        }
    };

    const originalPickZoneInputModel = {
        id: pickZoneData?.pickZone?.id ?? "",
        warehouseId: pickZoneData?.pickZone?.warehouseId ?? "",
        name: pickZoneData?.pickZone?.name ?? "",
        active: pickZoneData?.pickZone?.active ?? false,
        pickable: pickZoneData?.pickZone?.pickable ?? false,
        isCart: pickZoneData?.pickZone?.isCart ?? false,
        priority: pickZoneData?.pickZone?.priority ?? 0,
        stationId: pickZoneData?.pickZone?.station?.id ?? null,
    };

    const updatePickZone = async (modifiedPickZoneInputModel: IPickZoneInputModel) => {
        if (loading || !pickZoneData) return;
        try {
            setManualLoading(true);
            const result = await runEditPickZoneMutation({
                variables: {
                    original: originalPickZoneInputModel,
                    modified: modifiedPickZoneInputModel,
                },
            });
            pickZoneData.pickZone = result.data.pickZone.edit;
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "An error occurred while modifying the cart.");
        } finally {
            setManualLoading(false);
        }
    };

    return (
        <>
            {loading && <MobileLoading fullscreen />}

            {!pickZoneData?.pickZone ? (
                <></>
            ) : (
                <div className={styles.mainContainer}>
                    <h1>
                        <b>{pickZoneData?.pickZone?.name}</b>
                    </h1>

                    <div className={styles.checkboxesContainer}>
                        <label className={styles.labelCheckbox}>
                            <input
                                ref={activeInputRef}
                                disabled={loading}
                                type="checkbox"
                                defaultChecked={pickZoneData.pickZone.active}
                                onClick={async (e) => {
                                    if (!activeInputRef.current) return;
                                    if (!(await confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart(e))) return;
                                    activeInputRef.current.defaultChecked = !activeInputRef.current.defaultChecked;
                                    activeInputRef.current.checked = activeInputRef.current.defaultChecked;
                                    await updatePickZone({ ...originalPickZoneInputModel, active: activeInputRef.current.checked });
                                }}
                            />
                            <div className={styles.labelText}>Active</div>
                        </label>

                        <label className={styles.labelCheckbox}>
                            <input
                                ref={pickableInputRef}
                                disabled={loading}
                                type="checkbox"
                                defaultChecked={pickZoneData.pickZone.pickable}
                                onClick={async (e) => {
                                    if (!pickableInputRef.current) return;
                                    if (!(await confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart(e))) return;
                                    pickableInputRef.current.defaultChecked = !pickableInputRef.current.defaultChecked;
                                    pickableInputRef.current.checked = pickableInputRef.current.defaultChecked;
                                    await updatePickZone({ ...originalPickZoneInputModel, pickable: pickableInputRef.current.checked });
                                }}
                            />
                            <div className={styles.labelText}>Pickable</div>
                        </label>

                        <label className={styles.labelCheckbox}>
                            <input
                                ref={isCartInputRef}
                                disabled={loading}
                                type="checkbox"
                                defaultChecked={pickZoneData.pickZone.isCart}
                                onClick={async (e) => {
                                    if (!isCartInputRef.current) return;
                                    if (!(await confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart(e))) return;
                                    isCartInputRef.current.defaultChecked = !isCartInputRef.current.defaultChecked;
                                    isCartInputRef.current.checked = isCartInputRef.current.defaultChecked;
                                    await updatePickZone({ ...originalPickZoneInputModel, isCart: isCartInputRef.current.defaultChecked });
                                }}
                            />
                            <div className={styles.labelText}>Is Cart</div>
                        </label>
                    </div>

                    <div className={styles.stationContainer}>
                        <h4>Station Set</h4>
                        {pickZoneData.pickZone.station && (
                            <div className={styles.stationLineContainer}>
                                <div className={styles.stationLine}>
                                    <span>Station Name</span>
                                    <div>{pickZoneData.pickZone.station.stationSet.name}</div>
                                </div>
                                <div className={styles.stationLine}>
                                    <span>Type</span>
                                    <div>{pickZoneData.pickZone.station.type}</div>
                                </div>
                                <div className={styles.stationLine}>
                                    <span>Last Location</span>
                                    <div>{pickZoneData.pickZone.lastLocation?.name ?? "-"}</div>
                                </div>
                            </div>
                        )}
                        <button
                            disabled={loading}
                            className={styles.assignToStationButton}
                            onClick={async (e) => {
                                if (!(await confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart(e))) return;
                                const stationId = await selectStation();
                                if (!stationId) return;
                                await updatePickZone({ ...originalPickZoneInputModel, stationId: stationId });
                            }}
                        >
                            {pickZoneData.pickZone.station ? "Assign to Different Station" : "Assign to Station"}
                        </button>
                        {pickZoneData.pickZone.station && (
                            <button
                                disabled={loading}
                                className={styles.removeFromStationButton}
                                onClick={async (e) => {
                                    if (!(await confirmThatUserWantsToContinueEvenThoughThereAreSaleProductsInCart(e))) return;
                                    await updatePickZone({ ...originalPickZoneInputModel, stationId: null });
                                }}
                            >
                                Remove From Station
                            </button>
                        )}
                    </div>

                    {pickZoneData.pickZone.locations.length > 0 && (
                        <div className={styles.locationsContainer}>
                            <h4>Locations</h4>
                            <table className={styles.locationsTable}>
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Product Qty</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pickZoneData.pickZone.locations.map((location) => {
                                        return (
                                            <tr key={location.id}>
                                                <td>{location.name}</td>
                                                <td>{location.products.reduce((acc, val) => acc + val.stockOnHand, 0)}</td>
                                                <td>
                                                    <Link to={`/mobile/stocktake/stocktake/${location.id}`}>View Products</Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}

            {createElement(reloadModal)}

            {createElement(selectModal)}

            {createElement(confirmModal)}
        </>
    );
};

export default CartManager;
