import { useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useParams } from "react-router-dom";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import useAttachmentUploader from "../../../hooks/useAttachmentUploader";
import MobileLoading from "../../mobile/components/MobileLoading";
import Attachments, { IAttachment, IAttachmentWithSaleId } from "../../../components/misc/attachments/Attachments";
import { Card } from "react-bootstrap";

interface ISaleAttachmentsQueryResponse {
    sale: {
        attachments: Array<IAttachment>;
        salesChannel: {
            name: string;
        };
    };
    sales: {
        items: Array<{
            id: string;
            rootSaleId: string | null;
            parentSaleId: string | null;
            childSaleType: string | null;
            attachments: Array<IAttachment>;
        }>;
    };
}

interface ISaleAttachmentsQueryVariables {
    saleId: string;
}

const deleteSaleAttachmentMutation = `
mutation ($attachmentId: ID!, $saleId: ID) {
    attachments {
      deleteAttachment(
        attachmentId: $attachmentId
        saleId: $saleId
      )
    }
  }
`;

interface IDeleteSaleAttachmentMutationVariables {
    attachmentId: string;
    saleId: string;
}

interface IDeleteSaleAttachmentMutationResponse {
    attachments: {
        deleteAttachment: boolean;
    };
}

interface ISalesAttachmentRouteParams {
    id: string;
}

const SalesAttachments = () => {
    const routeParams = useParams<ISalesAttachmentRouteParams>();
    const saleAttachmentsBaseQuery = `
        sale(id: ${routeParams.id}) {
            attachments {
                id
                dateUploaded
                url
                data
                isLabel
                filename
                trackingNumber
                labelProvider {
                    name
                }
                labelProviderShipmentId
                shippingServiceId
            }
            salesChannel {
                name
            }
        }
        sales(relatedSaleId: ${routeParams.id}) {
            items {
                id
                rootSaleId
                parentSaleId
                childSaleType
                attachments {
                    id
                    dateUploaded
                    url
                    data
                    isLabel
                    filename
                    trackingNumber
                    labelProvider {
                        name
                    }
                    labelProviderShipmentId
                    shippingServiceId
                }
            }
        }
    `;

    const saleAttachmentsQuery = `
        query {
            ${saleAttachmentsBaseQuery}
        }
    `;

    const attachmentUploader = useAttachmentUploader<ISaleAttachmentsQueryResponse>({ saleId: routeParams.id }, saleAttachmentsBaseQuery);
    const {
        data: saleAttachmentsQueryData,
        error: saleAttachmentsQueryError,
        refetch: saleAttachmentsQueryRefetch,
        loading: saleAttachmentsQueryLoading,
    } = useQuery<ISaleAttachmentsQueryResponse, ISaleAttachmentsQueryVariables>(saleAttachmentsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            saleId: routeParams.id,
        },
    });

    const [mutationLoading, setMutationLoading] = useState(false);
    const [runDeleteAttachmentMutation] = useMutation<IDeleteSaleAttachmentMutationResponse, IDeleteSaleAttachmentMutationVariables>(
        deleteSaleAttachmentMutation
    );

    if (saleAttachmentsQueryError)
        return <ErrorDisplay onClick={saleAttachmentsQueryRefetch}>{saleAttachmentsQueryError.message}</ErrorDisplay>;

    const postUploadAttachmentsResult = attachmentUploader.postAttachmentUploadQueryResult?.sale?.attachments ?? [];
    let baseAttachments = saleAttachmentsQueryData?.sale?.attachments ?? [];
    if (postUploadAttachmentsResult.length > 0) baseAttachments = postUploadAttachmentsResult;
    const postUploadRelatedSaleAttachmentsResult = attachmentUploader.postAttachmentUploadQueryResult?.sales?.items ?? [];
    let relatedSaleBaseAttachments: IAttachmentWithSaleId[] = (saleAttachmentsQueryData?.sales?.items ?? []).flatMap((relatedSale) =>
        relatedSale.attachments.map((x) => ({
            ...x,
            saleId: relatedSale.id,
        }))
    );
    if (postUploadRelatedSaleAttachmentsResult.length > 0)
        relatedSaleBaseAttachments = postUploadRelatedSaleAttachmentsResult.flatMap((relatedSale) =>
            relatedSale.attachments.map((x) => ({
                ...x,
                saleId: relatedSale.id,
            }))
        );

    const vendorBaseAttachments = baseAttachments.filter((x) => x.trackingNumber !== null && x.labelProvider?.name === "Amazon Vendor");
    const fbaBaseAttachments = baseAttachments.filter((x) => x.trackingNumber !== null && x.labelProvider?.name === "Amazon FBA");
    baseAttachments = baseAttachments.filter((x) => x.trackingNumber === null);

    const attachments = [
        { title: "Labels", attachments: baseAttachments.filter((attachment) => attachment.isLabel) },
        { title: "Other Attachments", attachments: baseAttachments.filter((attachment) => !attachment.isLabel) },
    ];

    const vendorAttachments = [
        { title: "Labels", attachments: vendorBaseAttachments.filter((attachment) => attachment.isLabel) },
        { title: "Other Attachments", attachments: vendorBaseAttachments.filter((attachment) => !attachment.isLabel) },
    ];

    const fbaAttachments = [
        { title: "Labels", attachments: fbaBaseAttachments.filter((attachment) => attachment.isLabel) },
        { title: "Other Attachments", attachments: fbaBaseAttachments.filter((attachment) => !attachment.isLabel) },
    ];

    const relatedSaleAttachments = [
        {
            title: "Labels",
            attachments: relatedSaleBaseAttachments.filter((attachment) => attachment.isLabel && attachment.saleId !== routeParams.id),
        },
        {
            title: "Other Attachments",
            attachments: relatedSaleBaseAttachments.filter((attachment) => !attachment.isLabel && attachment.saleId !== routeParams.id),
        },
    ];

    const isLoading = [saleAttachmentsQueryLoading].some((loading) => loading);
    const isVendor = saleAttachmentsQueryData?.sale?.salesChannel?.name === "Amazon Vendor";
    const isFba = saleAttachmentsQueryData?.sale?.salesChannel?.name === "Amazon FBA";

    const deleteAttachmentAsync = async (attachmentId: string) => {
        if (!window.confirm("Are you sure you want to delete this attachment?")) return;
        if (!saleAttachmentsQueryData) return;
        try {
            setMutationLoading(true);
            const res = await runDeleteAttachmentMutation({
                variables: {
                    attachmentId: attachmentId,
                    saleId: routeParams.id,
                },
            });
            if (!res.data.attachments.deleteAttachment) return;
            saleAttachmentsQueryData.sale.attachments = baseAttachments.filter((x) => x.id !== attachmentId);
            if (attachmentUploader.postAttachmentUploadQueryResult) {
                attachmentUploader.postAttachmentUploadQueryResult.sale.attachments =
                    attachmentUploader.postAttachmentUploadQueryResult.sale.attachments.filter((x) => x.id !== attachmentId);
            }
        } catch (err: any) {
            alert((err as GraphQLError)?.message ?? (err as Error)?.message ?? "An unknown error occurred.");
        } finally {
            setMutationLoading(false);
        }
    };

    return (
        <>
            {mutationLoading && <MobileLoading fullscreen />}

            {isLoading ? (
                <div style={{ width: 100, height: 100 }}>
                    <MobileLoading />
                </div>
            ) : (
                <>
                    <Card border="primary">
                        <Card.Header className="bg-primary text-white">
                            <h5>Attachments</h5>
                        </Card.Header>
                        <Card.Body>
                            {attachmentUploader.attachmentUploaderElement}
                            {!attachments.reduce((acc, val) => acc + val.attachments.length, 0) ? (
                                <></>
                            ) : (
                                <>
                                    <h4>Attachments</h4>
                                    <hr />
                                    <Attachments attachments={attachments} deleteAttachmentAsync={deleteAttachmentAsync} />
                                </>
                            )}
                            {!relatedSaleAttachments.reduce((acc, val) => acc + val.attachments.length, 0) ? (
                                <></>
                            ) : (
                                <>
                                    <h4>Attachments on related sales</h4>
                                    <hr />
                                    <Attachments attachments={relatedSaleAttachments} deleteAttachmentAsync={deleteAttachmentAsync} />
                                </>
                            )}
                        </Card.Body>
                    </Card>
                    {!isVendor || !attachmentUploader.shippingServicesData ? null : (
                        <Card>
                            <Card.Header className="bg-primary text-white">
                                <h5>Amazon Vendor Attachments</h5>
                            </Card.Header>
                            <Card.Body>
                                {attachmentUploader.amazonVendorShippingInformationUploaderElement}
                                {!vendorAttachments.reduce((acc, val) => acc + val.attachments.length, 0) ? (
                                    <></>
                                ) : (
                                    <>
                                        <h4>Attachments</h4>
                                        <hr />
                                        <Attachments attachments={vendorAttachments} deleteAttachmentAsync={deleteAttachmentAsync} />
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    )}
                    {!isFba || !attachmentUploader.shippingServicesData ? null : (
                        <Card>
                            <Card.Header className="bg-primary text-white">
                                <h5>Amazon FBA Attachments</h5>
                            </Card.Header>
                            <Card.Body>
                                {attachmentUploader.amazonFbaShippingInformationUploaderElement}
                                {!fbaAttachments.reduce((acc, val) => acc + val.attachments.length, 0) ? (
                                    <></>
                                ) : (
                                    <>
                                        <h4>Attachments</h4>
                                        <hr />
                                        <Attachments attachments={fbaAttachments} deleteAttachmentAsync={deleteAttachmentAsync} />
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default SalesAttachments;
