import * as React from "react";

interface IRulerContext {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    xxl: boolean;
    xsOrSmaller: boolean;
    smOrSmaller: boolean;
    mdOrSmaller: boolean;
    lgOrSmaller: boolean;
    xlOrSmaller: boolean;
}

const RulerContext = React.createContext<IRulerContext>(null as any);

export default RulerContext;

export class RulerController extends React.Component<React.PropsWithChildren<{}>, IRulerContext> {
    state: IRulerContext;
    constructor(props: {}) {
        super(props);
        this.state = this.getWindowSize();
        this.printing = false;
    }
    printing: boolean;

    componentDidMount() {
        window.addEventListener("resize", this.setWindowSize);
        window.addEventListener("onbeforeprint", this.onBeforePrint);
        window.addEventListener("onafterprint", this.onAfterPrint);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setWindowSize);
        window.removeEventListener("onbeforeprint", this.onBeforePrint);
        window.removeEventListener("onafterprint", this.onAfterPrint);
    }

    onBeforePrint = () => {
        this.printing = true;
        this.setWindowSize();
    };

    onAfterPrint = () => {
        this.printing = false;
        this.setWindowSize();
    };

    setWindowSize = () => {
        const newSize = this.getWindowSize();
        if (
            newSize.xs !== this.state.xs ||
            newSize.sm !== this.state.sm ||
            newSize.md !== this.state.md ||
            newSize.lg !== this.state.lg ||
            newSize.xl !== this.state.xl ||
            newSize.xxl !== this.state.xxl
        ) {
            console.log("Window size updated", newSize);
            this.setState(newSize);
        }
    };

    getWindowSize: () => IRulerContext = () => {
        if (this.printing || (window.matchMedia && window.matchMedia("print").matches)) {
            //force md size for printed content
            return {
                xs: false,
                sm: false,
                md: true,
                lg: false,
                xl: false,
                xxl: false,
                xsOrSmaller: false,
                smOrSmaller: false,
                mdOrSmaller: true,
                lgOrSmaller: true,
                xlOrSmaller: true,
            };
        }
        //xs < 576
        if (window.matchMedia ? !window.matchMedia("(min-width: 576px)").matches : window.innerWidth < 576) {
            return {
                xs: true,
                sm: false,
                md: false,
                lg: false,
                xl: false,
                xxl: false,
                xsOrSmaller: true,
                smOrSmaller: true,
                mdOrSmaller: true,
                lgOrSmaller: true,
                xlOrSmaller: true,
            };
        }
        //sm > 576 && < 768
        else if (window.matchMedia ? !window.matchMedia("(min-width: 768px)").matches : window.innerWidth < 768) {
            return {
                xs: false,
                sm: true,
                md: false,
                lg: false,
                xl: false,
                xxl: false,
                xsOrSmaller: false,
                smOrSmaller: true,
                mdOrSmaller: true,
                lgOrSmaller: true,
                xlOrSmaller: true,
            };
        }
        //md > 767 && < 992
        else if (window.matchMedia ? !window.matchMedia("(min-width: 992px)").matches : window.innerWidth < 992) {
            return {
                xs: false,
                sm: false,
                md: true,
                lg: false,
                xl: false,
                xxl: false,
                xsOrSmaller: false,
                smOrSmaller: false,
                mdOrSmaller: true,
                lgOrSmaller: true,
                xlOrSmaller: true,
            };
        }
        //lg > 991 && < 1200
        else if (window.matchMedia ? !window.matchMedia("(min-width: 1200px)").matches : window.innerWidth < 1200) {
            return {
                xs: false,
                sm: false,
                md: false,
                lg: true,
                xl: false,
                xxl: false,
                xsOrSmaller: false,
                smOrSmaller: false,
                mdOrSmaller: false,
                lgOrSmaller: true,
                xlOrSmaller: true,
            };
        }
        //xl > 1199 && < 1536
        else if (window.matchMedia ? !window.matchMedia("(min-width: 1536px)").matches : window.innerWidth < 1536) {
            return {
                xs: false,
                sm: false,
                md: false,
                lg: false,
                xl: true,
                xxl: false,
                xsOrSmaller: false,
                smOrSmaller: false,
                mdOrSmaller: false,
                lgOrSmaller: false,
                xlOrSmaller: true,
            };
        }
        //xxl > 1535
        else {
            return {
                xs: false,
                sm: false,
                md: false,
                lg: false,
                xl: false,
                xxl: true,
                xsOrSmaller: false,
                smOrSmaller: false,
                mdOrSmaller: false,
                lgOrSmaller: false,
                xlOrSmaller: false,
            };
        }
    };

    render() {
        return <RulerContext.Provider value={this.state}>{this.props.children}</RulerContext.Provider>;
    }
}
