//required global css files, which may not be referenced elsewhere
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./scss/custom.scss";

import { AuthContext } from "@zboxglobal/zboxauth";
import React, { useContext, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import DefaultSuspense from "./components/loading/DefaultSuspense";
import Loading from "./components/loading/Loading";
import SiteLayout from "./components/sitelayout/SiteLayout";
import { ApiBaseUrl, GraphQlBaseUrl } from "./globalVariables";
import useScrollToTop from "./hooks/useScrollToTop";
import SignIn from "./pages/account/SignIn";
import ProductsIndex from "./pages/products/ProductsIndex";
import ProductDetails from "./pages/products/details/_ProductDetails";
import PurchaseOrdersIndex from "./pages/purchaseorders/PurchaseOrdersIndex";
import PurchaseOrderDetails from "./pages/purchaseorders/details/_PurchaseOrderDetails";
import SalesIndex from "./pages/sales/SalesIndex";
import SalesDetails from "./pages/sales/details/_SalesDetails";
import Setup from "./pages/setup/Setup";
import LabelProviderDetails from "./pages/setup/label_provider/LabelProviderDetail";
import ShippingCarrierDetails from "./pages/setup/shipping_carrier/ShippingCarrierDetails";
import SupplierDetails from "./pages/suppliers/SupplierDetails";
import SuppliersIndex from "./pages/suppliers/SuppliersIndex";
import WarehousesIndex from "./pages/warehouses/WarehousesIndex";
import WarehouseDetails from "./pages/warehouses/details/_WarehouseDetails";
// mobile stuff
import TestScanner from "./pages/TestScanner";
import Home from "./pages/home/Home";
import MobileIndex from "./pages/mobile/MobileIndex";
import SelectRole from "./pages/mobile/SelectRole";
import { ScannerToneContextController } from "./pages/mobile/contexts/ScannerToneContext";
import { SessionContextController } from "./pages/mobile/contexts/SessionContext";
import AdminController from "./pages/mobile/pages/admin/AdminController";
import IntakeController from "./pages/mobile/pages/intake/IntakeController";
import PackController from "./pages/mobile/pages/pack/PackController";
import PickController from "./pages/mobile/pages/pick/PickController";
import ReturnsController from "./pages/mobile/pages/returns/ReturnsController";
import StockTakeController from "./pages/mobile/pages/stock_take/StockTakeController";
import StockTransferController from "./pages/mobile/pages/stock_transfer/StockTransferController";
import ReportsIndex from "./pages/reports/ReportsIndex";
import ReturnsIndex from "./pages/returns/ReturnsIndex";
import ReturnSummary from "./pages/returns/details/ReturnSummary";
import ReturnDetails from "./pages/returns/details/_ReturnDetails";
import SupplierWarehouseDetail from "./pages/suppliers/supplierwarehouses/SupplierWarehouseDetail";
import PickZoneDetail from "./pages/warehouses/details/pickzones/PickZoneDetail";
import ReportsOld from "./pages/reports/ReportsOld";
import ReportView from "./pages/reports/ReportView";
import { ActionBarContextProvider } from "./pages/mobile/contexts/ActionBarContext/ActionBarContext";
import BlindIntakeController from "./pages/mobile/pages/blind_intake/BlindIntakeController";

// only lazy-load GraphiQL
const GraphiQL = React.lazy(() => import("./pages/GraphiQL"));

const App = (props: { autoLoginPromise: Promise<void>; children?: JSX.Element | JSX.Element[] | null }) => {
    useScrollToTop();
    var auth = useContext(AuthContext);
    var location = useLocation();

    const [loginDone, setLoginDone] = useState(auth.status.loggedIn);
    if (!loginDone) {
        props.autoLoginPromise.then(
            () => {
                setLoginDone(true);
            },
            () => {
                setLoginDone(true);
            }
        );

        return <Loading />;
    }

    return (
        <Switch>
            <Route
                path={"/graphiql"}
                render={() => (
                    <DefaultSuspense>
                        <GraphiQL baseUrl={ApiBaseUrl} apiUrl={GraphQlBaseUrl} />
                    </DefaultSuspense>
                )}
            />
            <Route path={"/mobile"}>
                <DefaultSuspense>
                    <SessionContextController>
                        {props.children ? (
                            props.children
                        ) : auth.status.loggedIn ? (
                            <ScannerToneContextController>
                                <ActionBarContextProvider>
                                    <Switch>
                                        {/*mobile*/}
                                        <Route exact path={"/mobile"} component={MobileIndex} />

                                        {/*select role*/}
                                        <Route exact path={`/mobile/selectrole`} component={SelectRole} />

                                        {/*pick*/}
                                        <Route path={`/mobile/pick`} component={PickController} />

                                        {/*pack*/}
                                        <Route path={`/mobile/pack`} component={PackController} />

                                        {/*stock take*/}
                                        <Route path={`/mobile/stocktake`} component={StockTakeController} />

                                        {/*stock transfer*/}
                                        <Route path={`/mobile/stocktransfer`} component={StockTransferController} />

                                        {/*stock intake*/}
                                        <Route path={`/mobile/intake`} component={IntakeController} />

                                        {/*blind intake*/}
                                        <Route path={`/mobile/blindintake`} component={BlindIntakeController} />

                                        {/*returns*/}
                                        <Route path={`/mobile/returns`} component={ReturnsController} />

                                        {/*admin*/}
                                        <Route path={`/mobile/admin`} component={AdminController} />
                                    </Switch>
                                </ActionBarContextProvider>
                            </ScannerToneContextController>
                        ) : (
                            <Switch>
                                <Route exact path={`/signin`} component={SignIn} />
                                <Route>
                                    <Redirect
                                        to={{
                                            pathname: `/signin`,
                                            search: "?p=" + encodeURIComponent(location.pathname + location.search),
                                        }}
                                    />
                                </Route>
                            </Switch>
                        )}
                    </SessionContextController>
                </DefaultSuspense>
            </Route>
            <Route>
                <SiteLayout>
                    <DefaultSuspense>
                        {props.children ? (
                            props.children
                        ) : auth.status.loggedIn ? (
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/testscanner" component={TestScanner} />
                                <Route exact path="/products" component={ProductsIndex} />
                                <Route path="/products/:id" component={ProductDetails} />
                                <Route exact path="/suppliers" component={SuppliersIndex} />
                                <Route exact path="/suppliers/:id/warehouses/:supplierWarehouseId" component={SupplierWarehouseDetail} />
                                <Route path="/suppliers/:id" component={SupplierDetails} />
                                <Route exact path="/setup/shippingcarriers/:id" component={ShippingCarrierDetails} />
                                <Route exact path="/setup/labelproviders/:id" component={LabelProviderDetails} />
                                <Route exact path="/sales" component={SalesIndex} />
                                <Route exact path="/sales/draft" component={SalesIndex} />
                                <Route exact path="/sales/stock" component={SalesIndex} />
                                <Route exact path="/sales/dropship" component={SalesIndex} />
                                <Route exact path="/sales/backordered" component={SalesIndex} />
                                <Route exact path="/sales/fulfilling" component={SalesIndex} />
                                <Route exact path="/sales/dropshipping" component={SalesIndex} />
                                <Route exact path="/sales/shipped" component={SalesIndex} />
                                <Route exact path="/sales/delivered" component={SalesIndex} />
                                <Route exact path="/sales/void" component={SalesIndex} />
                                <Route exact path="/sales/searchresults" component={SalesIndex} />
                                <Route path="/sales/:id" component={SalesDetails} />
                                <Route path="/setup" component={Setup} />
                                <Route exact path="/signin" component={SignIn} />
                                <Route exact path="/purchaseorders" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/draft" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/stock" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/dropship" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/ordered/stock" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/ordered/dropship" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/invoiced" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/receiving" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/completed" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/void" component={PurchaseOrdersIndex} />
                                <Route exact path="/purchaseorders/searchresults" component={PurchaseOrdersIndex} />
                                {/*<Route path={"/purchaseorders/:id/invoices/:invoiceId"} component={PurchaseOrderDetails} />*/}
                                <Route path="/purchaseorders/:id" component={PurchaseOrderDetails} />
                                <Route exact path="/warehouses" component={WarehousesIndex} />
                                <Route exact path="/warehouses/:id/pickZones/:pickZoneId" component={PickZoneDetail} />
                                {/*keep this route above the warehosues/:id route*/}
                                <Route path="/warehouses/:id" component={WarehouseDetails} />
                                <Route exact path="/returns" component={ReturnsIndex} />
                                <Route exact path="/reports" component={ReportsIndex} />
                                <Route exact path="/reports/warehouse" component={ReportsOld} />
                                <Route exact path="/reports/id/:id" component={ReportView} />
                                <Route exact path="/returns/draft" component={ReturnsIndex} />
                                <Route exact path="/returns/authorized" component={ReturnsIndex} />
                                <Route exact path="/returns/pendingrefund" component={ReturnsIndex} />
                                <Route exact path="/returns/completed" component={ReturnsIndex} />
                                <Route exact path="/returns/void" component={ReturnsIndex} />
                                <Route exact path="/returns/searchresults" component={ReturnsIndex} />
                                <Route path="/returns/:id" component={ReturnDetails} />
                                <Route path="/returns/:id" component={ReturnSummary} />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route exact path="/signin" component={SignIn} />
                                <Route>
                                    <Redirect
                                        to={{
                                            pathname: "/signin",
                                            search: "?p=" + encodeURIComponent(location.pathname + location.search),
                                        }}
                                    />
                                </Route>
                            </Switch>
                        )}
                    </DefaultSuspense>
                </SiteLayout>
            </Route>
        </Switch>
    );
};

export default App;
