import { Route, Switch } from "react-router-dom";
import PickBin from "./PickBin";
import ScanBinComponent from "./ScanBinComponent";
import SelectionType from "../../../../enums/SelectionType";

export interface IStockTransferSearchParams {
    fromLocationSelectionType: SelectionType;
    matchingProductIds?: string[];
    removeSkuAfterScan?: boolean;
    showOneLocation?: boolean;
    skusToRemove?: string[];
}

const StockTransferController = () => {
    return (
        <Switch>
            <Route exact path={`/mobile/stocktransfer/scanpickbin`}>
                <ScanBinComponent />
            </Route>

            <Route exact path={`/mobile/stocktransfer/pickbin/:locationId`}>
                <PickBin />
            </Route>
        </Switch>
    );
};

export default StockTransferController;
