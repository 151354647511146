//import React, { useEffect, useCallback } from "react";
//import { useLocation } from "react-router-dom";
//import useTimer from "./useTimer";
import * as React from "react";
import { NavPushedOrReplaced } from "../globalVariables";

export default function useScrollToTop() {
    React.useEffect(() => {
        const scrollCallback = () => {
            //alert('scroll');
            window.scrollTo({
                top: 0,
                left: 0,
            });
        };
        NavPushedOrReplaced.push(scrollCallback);
        return () => {
            const index = NavPushedOrReplaced.indexOf(scrollCallback);
            if (index >= 0) NavPushedOrReplaced.splice(index, 1);
        };
    }, []);
}
