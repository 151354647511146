import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ZBreadcrumb from "../../../components/breadcrumb/ZBreadcrumb";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";
import SupplierWarehouseProducts from "./SupplierWarehouseSummary";
import StateCode from "../../../enums/StatesCode";

interface IQueryResult {
    supplier: ISupplier;
    countries: {
        items: Array<ICountry>;
    };
}

interface ISupplierWarehouse {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    address: {
        id: string;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        countryId: string | null;
    } | null;
}

interface ISupplierWarehouseModel {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    countryId: string;
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: string;
    active: boolean;
    warehouses: Array<ISupplierWarehouse>;
}

interface IEditResult {
    supplier: {
        edit: {
            warehouses: Array<ISupplierWarehouse>;
        };
    };
}

interface IEditVariables {
    original: ISupplier;
    modified: ISupplier;
}

interface IModal {
    show: boolean;
    original?: ISupplierWarehouseModel;
}

const hiddenModal: IModal = {
    show: false,
};

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const SupplierQuery = `query($id: ID!){
  supplier(id: $id) {
    id
    name
    defaultPaymentTermId
    active
    deleted
    warehouses {
      id
      name
      active
      sortOrder
      address {
        id
        address1
        address2
        city
        state
        zip
        phone
        countryId
      }
    }
  }
}`;

const SupplierEditMutation = `
mutation ($original: SupplierInput!, $modified: SupplierInput!) {
    supplier{
      edit(original: $original, modified: $modified){
        warehouses{
          id
          name
          active
          sortOrder
          address {
            id
            address1
            address2
            city
            state
            zip
            phone
            countryId
          }
        }
      }
    }
  }
`;

const SupplierWarehouseDetail = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { supplierWarehouseId } = useParams<{ supplierWarehouseId: string }>();
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(SupplierQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<IQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(SupplierEditMutation);
    const history = useHistory();

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    if (!data || !dataCountry) return <Loading />;

    const supplierWarehouse = data.supplier.warehouses.find((x) => x.id === supplierWarehouseId);
    if (!supplierWarehouse) return <ErrorDisplay>No supplier warehouse with id {supplierWarehouseId}</ErrorDisplay>;

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };

    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };

    const originalModel: ISupplierWarehouseModel = {
        id: supplierWarehouse.id,
        name: supplierWarehouse.name,
        sortOrder: supplierWarehouse.sortOrder,
        active: supplierWarehouse.active,
        address1: supplierWarehouse.address?.address1 || "",
        address2: supplierWarehouse.address?.address2 || "",
        city: supplierWarehouse.address?.city || "",
        state: supplierWarehouse.address?.state || "",
        phone: supplierWarehouse.address?.phone || "",
        zip: supplierWarehouse.address?.zip || "",
        countryId: supplierWarehouse.address?.countryId || "",
    };

    const onSaveChanges = (modified: ISupplierWarehouseModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                original: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [
                        {
                            id: supplierWarehouse.id,
                            name: supplierWarehouse.name,
                            sortOrder: supplierWarehouse.sortOrder,
                            active: supplierWarehouse.active,
                            address: supplierWarehouse.address
                                ? {
                                      id: supplierWarehouse.address.id,
                                      address1: supplierWarehouse.address.address1,
                                      address2: supplierWarehouse.address.address2,
                                      city: supplierWarehouse.address.city,
                                      countryId: supplierWarehouse.address.countryId,
                                      phone: supplierWarehouse.address.phone,
                                      state: supplierWarehouse.address.state,
                                      zip: supplierWarehouse.address.zip,
                                  }
                                : null,
                        },
                    ],
                },
                modified: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [
                        {
                            id: supplierWarehouse.id,
                            name: modified.name,
                            sortOrder: supplierWarehouse.sortOrder,
                            active: modified.active,
                            address: {
                                id: "0",
                                address1: modified.address1 || null,
                                address2: modified.address2 || null,
                                city: modified.city || null,
                                countryId: modified.countryId || null,
                                phone: modified.phone || null,
                                zip: modified.zip || null,
                                state: modified.state || null,
                            },
                        },
                    ],
                },
            },
        }).then(
            (ret) => {
                let updatedSupplierWarehouse = ret.data.supplier.edit.warehouses.find((x) => x.id === supplierWarehouseId);
                if (updatedSupplierWarehouse) {
                    data.supplier.warehouses = ret.data.supplier.edit.warehouses;
                }
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing supplier warehouse", err);
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this supplier warehouse?")) return;
        //disable form controls
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: data.supplier.warehouses,
                },
                modified: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [],
                },
            },
        }).then(
            (ret) => {
                //redirects to the main pick zone page
                setSaving(false);
                history.push(`/suppliers/${id}/warehouses`);
            },
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting supplier warehouse", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const states = Object.values<string>(StateCode);

    return (
        <>
            <ZBreadcrumb url={`/suppliers/${id}/warehouses`} name={data.supplier.name}>
                <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                    {supplierWarehouse.name}
                </PageHeader>
            </ZBreadcrumb>
            <Switch>
                <Route path="/suppliers/:id/warehouses/:supplierWarehouseId" exact component={SupplierWarehouseProducts} />
            </Switch>

            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Supplier Warehouse</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formName">
                                    <VLabel title="Name" valueName="name" column sm={3} />
                                    <VControl valueName="name" type="text" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress1">
                                    <VLabel valueName="address1">Address 1</VLabel>
                                    <VControl type="text" valueName="address1" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formAddress2">
                                    <VLabel valueName="address2">Address 2</VLabel>
                                    <VControl type="text" valueName="address2" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formCity">
                                    <VLabel valueName="city">City</VLabel>
                                    <VControl type="text" valueName="city" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formState">
                                    <VLabel valueName="state">State</VLabel>
                                    <VSelect valueName="state">
                                        <option value=""></option>
                                        {states.map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formZip">
                                    <VLabel valueName="zip">Zip</VLabel>
                                    <VControl type="text" valueName="zip" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formPhone">
                                    <VLabel valueName="phone">Phone</VLabel>
                                    <VControl type="text" valueName="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCountry">
                                    <VLabel valueName="countryId">Country</VLabel>
                                    <VSelect valueName="countryId">
                                        <option></option>
                                        {dataCountry.countries.items
                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                            .map((country) => (
                                                <option key={`PurchaseOrder-${country.id}`} value={country.id}>
                                                    {country.isoCode}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formSortOrder">
                                    <VLabel valueName="sortOrder">Sort Order</VLabel>
                                    <VControl type="text" pattern="^$|\d+\.?\d*" valueName="sortOrder" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formActive">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SupplierWarehouseDetail;
