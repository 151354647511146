import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import { Table } from "react-bootstrap";
import { useQuery } from "@shane32/graphql";
import Loading from "../../../components/loading/Loading";

const SalesAssociatedOrders = () => {
    const { id } = useParams<{ id: string }>();
    const ret = useQuery<
        {
            sales: {
                items: Array<{
                    id: string;
                    rootSaleId: string | null;
                    parentSaleId: string | null;
                    childSaleType: string | null;
                    dropShip: boolean;
                }>;
            };
            purchaseOrders: { items: Array<{ id: string; isReturn: boolean }> };
            returns: { items: Array<{ id: string }> };
        },
        { id: string }
    >(
        `query ($id: ID!) { 
            sales(relatedSaleId: $id) { items { id rootSaleId parentSaleId childSaleType dropShip } } 
            purchaseOrders(relatedSaleId: $id) { items { id isReturn } } 
            returns(relatedSaleId: $id) {items { id } }
        }`,
        { variables: { id } }
    );
    if (!ret.data) return <Loading />;

    return (
        <>
            <Row>
                <Col md={4}>
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Sales Orders</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Order #</th>
                                        <th>Relationship</th>
                                        <th>Ref</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ret.data.sales.items
                                        .filter((x) => x.id !== id)
                                        .map((row) => (
                                            <tr key={row.id}>
                                                <td>
                                                    <Link to={`/sales/${row.id}`}>SO-{row.id}</Link>
                                                </td>
                                                <td>
                                                    {row.rootSaleId === null || row.parentSaleId === null
                                                        ? "Original Sale"
                                                        : (row.childSaleType
                                                              ? row.childSaleType === "INCOMPLETE"
                                                                  ? row.dropShip
                                                                      ? "Drop ship sale for remaining Items"
                                                                      : "Sale for remaining items"
                                                                  : row.childSaleType
                                                              : "Child") +
                                                          " of sale #" +
                                                          row.parentSaleId}
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Purchase Orders</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Order #</th>
                                        <th>Relationship</th>
                                        <th>Ref</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ret.data.purchaseOrders.items.map((row) => (
                                        <tr key={row.id}>
                                            <td>
                                                <Link to={`/purchaseorders/${row.id}`}>ZPO-{row.id}</Link>
                                            </td>
                                            <td>Parent</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Returns</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Ticket #</th>
                                        <th>Relationship</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ret.data.returns.items.map((row) => (
                                        <tr key={row.id}>
                                            <td>
                                                <Link to={`/returns/${row.id}`}>RN-{row.id}</Link>
                                            </td>
                                            <td>Parent</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default SalesAssociatedOrders;
