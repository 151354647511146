import ModelType from "../../../../enums/ModelType";
import GroupBy from "../../../../helpers/GroupBy";

export const searchBarcodesQuery = `
query ($search: String!, $userEntered: Boolean!, $vendorSpecific: Boolean) {
  searchBarcodes(search: $search, userEntered: $userEntered, vendorSpecific: $vendorSpecific) {
    id
    type
  }
}
`;

export interface ISearchBarcodesQueryVariables {
    search: string;
    userEntered: boolean;
    vendorSpecific?: boolean;
}

export interface ISearchBarcode {
    id: string;
    type: ModelType;
}

export interface ISearchBarcodesQueryResult {
    searchBarcodes: ISearchBarcode[];
}

//This might not be the best place for this function, but it is related to the search barcodes query,
//so, placing this here to keep things together because they are related enough to warrant this placement.
/**
 * Formats an array of ISearchBarcode results into a string showing what IDs belong to what ModelType.
 * @param searchBarcodes The array of ISearchBarcode results
 * @param searchedValue The value used to search the database
 */
export const formatMultipleMatchesString = (searchBarcodes: ISearchBarcode[], searchedValue?: string) => {
    const searchedValueText = searchedValue
        ? `Multiple items matching value '${searchedValue}' in database!\n`
        : `Multiple items matching scanned value in database!\n`;
    return (
        searchedValueText +
        `${Array.from(GroupBy(searchBarcodes, (x) => x.type))
            .map(([t, values]) => `${t} IDs: ${values.map((a) => a.id).join(", ")}\n`)
            .join("")}`
    );
};
