import React from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";
import StateCode from "../../../enums/StatesCode";

interface IQueryResult {
    warehouse: IWarehouse;
}

interface IWarehouse {
    id: string;
    name: string;
    active: boolean;
    address: {
        id: string;
    };
    returnAddresses: Array<IWarehouseReturnAddress>;
}

interface IWarehouseReturnAddress {
    address: {
        id: string;
        company?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        phone?: string | null;
        countryId?: string | null;
    };
    salesChannelId: string;
}

interface IWarehouseReturnAddressModel {
    id: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    countryId: string;
    phone: string;
    company: string;
    salesChannelId: string;
}

interface IEditResult {
    warehouse: {
        edit: IWarehouse;
    };
}

interface IEditVariables {
    original: IWarehouse;
    modified: IWarehouse;
}

interface IModal {
    show: boolean;
    original?: IWarehouseReturnAddress;
}

const hiddenModal: IModal = {
    show: false,
};

interface ISalesChannel {
    id: string;
    name: string;
    active: boolean;
    deleted: boolean;
}

interface ISalesChannelQuery {
    salesChannels: {
        items: Array<ISalesChannel>;
    };
}

interface ICountryQueryResult {
    countries: {
        items: Array<ICountry>;
    };
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

const SalesChannelQuery = `
{
    salesChannels {
      items {
        id
        name
        active
        deleted
      }
    }
}
`;

const ReturnAddressQuery = `
query($id: ID!) {
    warehouse(id: $id) {
      id
      name
      active
      address { id }
      returnAddresses {
        address {
            id
            company
            address1
            address2
            city
            state
            zip
            phone
            countryId
        }
        salesChannelId
      }
    }
  }
`;

const EditMutation = `
mutation($original: WarehouseInput!, $modified: WarehouseInput!){
    warehouse {
      edit(original: $original, modified: $modified) {
        id
        name
        active
        address { id }
        returnAddresses {
          address {
            id
            company
            address1
            address2
            city
            state
            zip
            phone
            countryId
          }
          salesChannelId
        }
      }
    }
  }
`;

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const WarehouseReturnAddresses = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    //const [type, setType] = React.useState<string>();

    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(ReturnAddressQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataSalesChannels,
        error: errorSalesChannels,
        refetch: refetchSalesChannels,
    } = useQuery<ISalesChannelQuery, {}>(SalesChannelQuery, { fetchPolicy: "no-cache" });

    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<ICountryQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });

    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditMutation);

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorSalesChannels) return <ErrorDisplay onClick={refetchSalesChannels}>{errorSalesChannels.message}</ErrorDisplay>;
    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data || !dataSalesChannels || !dataCountry) return <Loading />;

    //=== set up local functions ===
    //run when the edit button is pressed
    const onShowEditAddressModal = (value: IWarehouseReturnAddress) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddAddressModal = () => {
        setModal({ ...hiddenModal, show: true });
    };
    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const originalModel: IWarehouseReturnAddressModel = modal.original
        ? {
              id: modal.original.address.id || "",
              company: modal.original.address.company || "",
              address1: modal.original.address.address1 || "",
              address2: modal.original.address.address2 || "",
              city: modal.original.address.city || "",
              zip: modal.original.address.zip || "",
              state: modal.original.address.state || "",
              countryId: modal.original.address.countryId || "",
              phone: modal.original.address.phone || "",
              salesChannelId: modal.original.salesChannelId || "",
          }
        : {
              id: "",
              company: "",
              address1: "",
              address2: "",
              city: "",
              zip: "",
              state: "",
              countryId: "",
              phone: "",
              salesChannelId: "",
          };
    //run when the save button is pressed
    const onSaveChanges = (modified: IWarehouseReturnAddressModel) => {
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                original: {
                    id: data.warehouse.id,
                    name: data.warehouse.name,
                    active: data.warehouse.active,
                    address: { id: data.warehouse.address.id },
                    returnAddresses: !modal.original
                        ? []
                        : [
                              {
                                  address: {
                                      id: modal.original.address.id,
                                      address1: modal.original.address.address1,
                                      address2: modal.original.address.address2,
                                      city: modal.original.address.city,
                                      state: modal.original.address.state,
                                      zip: modal.original.address.zip,
                                      phone: modal.original.address.phone,
                                      countryId: modal.original.address.countryId,
                                      company: modal.original.address.company,
                                  },
                                  salesChannelId: modal.original.salesChannelId,
                              },
                          ],
                },
                modified: {
                    id: data.warehouse.id,
                    name: data.warehouse.name,
                    active: data.warehouse.active,
                    address: { id: data.warehouse.address.id },
                    returnAddresses: [
                        {
                            address: {
                                id: !modal.original ? "0" : modal.original.address.id,
                                company: modified.company,
                                address1: modified.address1,
                                address2: modified.address2,
                                city: modified.city,
                                state: modified.state,
                                zip: modified.zip,
                                phone: modified.phone,
                                countryId: modified.countryId,
                            },
                            salesChannelId: modified.salesChannelId,
                        },
                    ],
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                data.warehouse = ret.data.warehouse.edit;
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding Return Address", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Return Address?")) return;

        if (!modal.original) {
            alert("Cannot delete address without loading one");
            return;
        }
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runEdit({
            variables: {
                original: {
                    id: data.warehouse.id,
                    name: data.warehouse.name,
                    active: data.warehouse.active,
                    address: { id: data.warehouse.address.id },
                    returnAddresses: [
                        {
                            address: {
                                id: modal.original.address.id,
                            },
                            salesChannelId: modal.original.salesChannelId,
                        },
                    ],
                },
                modified: {
                    id: data.warehouse.id,
                    name: data.warehouse.name,
                    active: data.warehouse.active,
                    address: { id: data.warehouse.address.id },
                    returnAddresses: [],
                },
            },
        }).then(
            //success
            () => {
                if (data) {
                    //delete the entry from the local list
                    const oldIndex = data.warehouse.returnAddresses.findIndex((x) => x.salesChannelId === modal.original!.salesChannelId);
                    if (oldIndex >= 0) {
                        data.warehouse.returnAddresses.splice(oldIndex, 1);
                    }
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                }
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Return Address", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const states = Object.values<string>(StateCode);
    states.unshift("");

    //render
    return (
        <>
            <Card className="mb-4 border-primary">
                <Card.Header className="bg-primary text-white">Addresses</Card.Header>
                <Card.Body>
                    <Row>
                        {data.warehouse.returnAddresses.map((addressMapping) => {
                            const country = dataCountry.countries.items.find((x) => x.id === addressMapping.address.countryId);
                            return (
                                <Col lg={6} className="pe-lg-4 mb-3" key={`Address-${addressMapping.salesChannelId}`}>
                                    <div>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditAddressModal(addressMapping)}
                                        >
                                            Edit
                                        </Button>
                                        <b>{addressMapping.address.company}</b>
                                        <br />
                                        {addressMapping.address.address1}
                                        <br />
                                        {addressMapping.address.city}, {addressMapping.address.state} {addressMapping.address.zip}
                                        <br />
                                        {country?.isoCode}
                                        <br />
                                        {addressMapping.address.phone}
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button variant="white" onClick={onShowAddAddressModal}>
                        Add
                    </Button>
                </Card.Footer>
            </Card>

            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Return Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="addressSalesChannelId">
                                    <VLabel valueName="salesChannelId">Sales Channel</VLabel>
                                    <VSelect valueName="salesChannelId" required>
                                        <option value=""></option>
                                        {dataSalesChannels.salesChannels.items.map((salesChannel) => (
                                            <option key={salesChannel.id} value={salesChannel.id}>
                                                {salesChannel.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressCompany">
                                    <VLabel valueName="company">Company</VLabel>
                                    <VControl type="text" valueName="company" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressAddress1">
                                    <VLabel valueName="address1">Address1</VLabel>
                                    <VControl type="text" valueName="address1" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressAddress2">
                                    <VLabel valueName="address2">Address 2</VLabel>
                                    <VControl type="text" valueName="address2" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressCity">
                                    <VLabel valueName="city">City</VLabel>
                                    <VControl type="text" valueName="city" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressState">
                                    <VLabel valueName="state">State</VLabel>
                                    <VSelect valueName="state">
                                        <option value=""></option>
                                        {states.map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressZip">
                                    <VLabel valueName="zip">Zip</VLabel>
                                    <VControl type="text" valueName="zip" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressCountry">
                                    <VLabel valueName="countryId">Country</VLabel>
                                    <VSelect valueName="countryId">
                                        <option value=""></option>
                                        {dataCountry.countries.items
                                            .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                            .map((country) => (
                                                <option key={`sales-${country.id}`} value={country.id}>
                                                    {country.isoCode}
                                                </option>
                                            ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="addressPhone">
                                    <VLabel valueName="phone">Phone</VLabel>
                                    <VControl type="text" valueName="phone" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default WarehouseReturnAddresses;
