import React from "react";
import { Button, Card, Col, Form, Modal, Nav, Row, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { VArray, VButton, VControl, VForm, VLabel, VSelect, ValidationFormContext } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";
import PurchaseInvoiceStatus from "../../../enums/PurchaseInvoiceStatus";
import PurchaseOrderStatus from "../../../enums/PurchaseOrderStatus";
import StringHelper from "../../../helpers/StringHelper";
import { XButton } from "../../../components/misc/XButton";
import VSpecialSelect from "../../../components/form/Custom/VSpecialSelect/VSpecialSelect";

interface IPurchaseOrderQueryResult {
    purchaseOrder: IPurchaseOrder;
    paymentTerms: {
        items: Array<{
            id: string;
            name: string;
            deleted: boolean;
        }>;
    };
}

interface IPurchaseOrder {
    id: string;
    subtotal: number;
    tax: number;
    total: number;
    blindReceipt: boolean;
    status: PurchaseOrderStatus;
    orderDate: string;
    supplierId: string;
    supplierContactId: string | null;
    supplierAddressId: string | null;
    billingAddress: {
        id: string;
    };
    shippingAddress: {
        id: string;
    };
    lineItems: Array<ILineItem>;
    invoices: Array<IPurchaseInvoice>;
    reference: string | null;
    notes: string | null;
}

interface ILineItem {
    id: string;
    description: string;
    isAdditionalCharge: boolean;
    partNumber: string;
    product: {
        id: string;
        sku: string;
    };
    quantity: number;
    unitOfMeasureId: string;
    unitPrice: number;
    discount: number;
    total: number;
    comment: string;
    sortOrder: number;
    supplierSku: string;
}

interface IPurchaseInvoice {
    id: string;
    purchaseOrderId: string;
    invoiceNumber: string;
    invoiceDate: string;
    arrivalDate: string | null;
    status: PurchaseInvoiceStatus;
    subtotal: number;
    tax: number;
    total: number;
    paymentTermId: string;
    trackingNumbers: Array<ITrackingNumber> | null;
    lineItems: Array<IPurchaseInvoiceLineItem> | null;
}

//interface IPurchaseInvoiceMutation {
//    invoices: Array<IPurchaseInvoice> | null;
//}

interface IPurchaseInvoiceModel {
    id: string;
    invoiceNumber: string;
    invoiceDate: string;
    arrivalDate: string | null;
    status: PurchaseInvoiceStatus;
    subtotal: string;
    tax: string;
    total: string;
    paymentTermId: string;
    purchaseOrderId: string;
    trackingNumbers: Array<ITrackingNumber>;
    lineItems: Array<IPurchaseInvoiceLineItemModel>;
    additionalCharges: Array<IPurchaseInvoiceLineItemModel>;
}

interface IPurchaseInvoiceLineItem {
    id: string;
    purchaseOrderLineItemId: string;
    expectedQuantity: number;
    actualQuantity: number;
    unitPrice: number;
    discount: number;
    comment: string;
    total: number;
    purchaseOrderLineItem?: {
        product: {
            sku: string;
        } | null;
    };
}

interface IPurchaseInvoiceLineItemModel {
    id: string;
    purchaseOrderLineItemId: string;
    expectedQuantity: string;
    actualQuantity: string;
    supplierSku: string;
    unitPrice: string;
    discount: string;
    comment: string;
    total: string;
    isAdditionalCharge: boolean;
}

interface ITrackingNumber {
    trackingNumber: string;
    shippingServiceId: string | null;
    sortOrder: number;
}

interface IEditResult {
    purchaseInvoice: {
        edit: IPurchaseInvoice;
    };
}

interface IEditVariables {
    original: IPurchaseInvoice;
    modified: IPurchaseInvoice;
}

interface IAddVariables {
    value: IPurchaseInvoice;
}

interface IAddResult {
    purchaseInvoice: {
        add: IPurchaseInvoice;
    };
}

interface IImportResult {
    api: {
        importExport: {
            import: {
                purchaseInvoiceLineItems: IPurchaseInvoice;
            };
        };
    };
}

interface IImportVariables {
    purchaseInvoiceId: string;
    base64: string;
}

interface IExportResult {
    api: {
        importExport: {
            export: {
                purchaseInvoiceLineItems: string;
            };
        };
    };
}

interface IExportVariables {
    purchaseInvoiceId: string;
}

interface IModal {
    show: boolean;
    original?: IPurchaseInvoice;
}

const hiddenModal: IModal = {
    show: false,
};

const PurchaseOrderQuery = `
query ($id: ID!) {
  purchaseOrder(id: $id) {
    id
    subtotal
    tax
    total
    status
    blindReceipt
    supplierContactId
    supplierAddressId
    supplierId
    supplierContactId
    supplierAddressId
    billingAddress {
      id
    }
    shippingAddress {
      id
    }
    invoices {
      id
      invoiceNumber
      invoiceDate
      arrivalDate
      status
      subtotal
      tax
      total
      paymentTermId
      purchaseOrderId
      trackingNumbers {
        trackingNumber
        shippingServiceId
        sortOrder
      }
      lineItems {
        id
        purchaseOrderLineItemId
        expectedQuantity
        actualQuantity
        unitPrice
        discount
        comment
        total
      }
    }
    lineItems {
      id
      unitPrice
      quantity
      unitOfMeasureId
      sortOrder
      supplierSku
      partNumber
      description
      discount
      total
      isAdditionalCharge
      product {
        id
        sku
      }
    }
  }
  paymentTerms {
    items {
      id
      name
      deleted
    }
  }
}
`;

const EditPurchaseInvoiceMutation = `
mutation ($original: PurchaseInvoiceInput!, $modified: PurchaseInvoiceInput!) {
  purchaseInvoice {
    edit(original: $original, modified: $modified) {
        id
        invoiceNumber
        invoiceDate
        arrivalDate
        status
        subtotal
        tax
        total
        purchaseOrderId
        paymentTermId
        trackingNumbers {
          trackingNumber
          shippingServiceId
          sortOrder
        }
        lineItems {
          id
          purchaseOrderLineItemId
          expectedQuantity
          actualQuantity
          unitPrice
          discount
          comment
          total
        }
    }
  }
}
`;

const AddPurchaseInvoiceMutation = `
mutation ($value: PurchaseInvoiceInput!) {
  purchaseInvoice {
    add(value: $value) {
        id
        invoiceNumber
        invoiceDate
        arrivalDate
        status
        subtotal
        tax
        total
        purchaseOrderId
        paymentTermId
        trackingNumbers {
          trackingNumber
          shippingServiceId
          sortOrder
        }
        lineItems {
          id
          purchaseOrderLineItemId
          expectedQuantity
          actualQuantity
          unitPrice
          discount
          comment
          total
        }
    }
  }
}
`;

const ImportMutation = `
mutation ($purchaseInvoiceId: ID!, $base64: String!) {
    api{
      importExport{
        import{
          purchaseInvoiceLineItems(purchaseInvoiceId: $purchaseInvoiceId, base64: $base64){
            id
            purchaseOrderId
            invoiceNumber
            invoiceDate
            arrivalDate
            status
            subtotal
            tax
            total
            paymentTermId
            trackingNumbers {
              trackingNumber
              shippingServiceId
              sortOrder
            }
              lineItems{
              id
              purchaseOrderLineItemId
              expectedQuantity
              actualQuantity
              unitPrice
              discount
              comment
              total
            }
          }
        }
      }
    }
  }
`;

const ExportMutation = `
mutation ($purchaseInvoiceId: ID!) {
    api{
      importExport{
        export{
          purchaseInvoiceLineItems(purchaseInvoiceId: $purchaseInvoiceId)
        }
      }
    }
  }
`;

const ShippingServicesQuery = `
{
shippingServices {
    items {
        id
        name
        active
        shippingCarrierId
    }
}
}
`;

interface IShippingService {
    id: string;
    name: string;
    active: boolean;
    shippingCarrierId: string;
}
//query for shippingServices
interface IShippingServiceQueryResult {
    shippingServices: {
        items: Array<IShippingService>;
    };
}

const PurchaseOrdersInvoices = () => {
    const { id } = useParams<{ id: string }>();
    let { invoiceId } = useParams<{ invoiceId: string }>();
    const [saving, setSaving] = React.useState(false);
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const { data, error, refetch } = useQuery<IPurchaseOrderQueryResult, { id: string }>(PurchaseOrderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditPurchaseInvoiceMutation);
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddPurchaseInvoiceMutation);
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);
    const [inputFile, setInputFile] = React.useState<File | null>(null);
    const [fileError, setFileError] = React.useState<string>("");
    const history = useHistory();
    const [runImport] = useMutation<IImportResult, IImportVariables>(ImportMutation);
    const [runExport] = useMutation<IExportResult, IExportVariables>(ExportMutation);
    const {
        data: dataShippingService,
        error: errorShippingService,
        refetch: refetchShippingService,
    } = useQuery<IShippingServiceQueryResult, {}>(ShippingServicesQuery, { fetchPolicy: "cache-and-network" });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorShippingService) return <ErrorDisplay onClick={refetchShippingService}>{errorShippingService.message}</ErrorDisplay>;

    if (!data) return <Loading />;

    if (!data.purchaseOrder) return <ErrorDisplay onClick={refetch}>PurchaseOrder ID #{id} cannot be found</ErrorDisplay>;
    if (!dataShippingService) return <ErrorDisplay onClick={refetchShippingService}>Shipping Services cannot be found</ErrorDisplay>;

    let existingInvoice =
        data.purchaseOrder.invoices != null && data.purchaseOrder.invoices.length > 0
            ? data.purchaseOrder.invoices.find((x) => x.id === invoiceId)
            : null;
    let filteredLineItems = data.purchaseOrder.lineItems.filter((x) => x.isAdditionalCharge === false);
    let additionalChargeItems = data.purchaseOrder.lineItems.filter((x) => x.isAdditionalCharge === true);

    const onShowModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    const onHideModal = () => {
        setModal(hiddenModal);
        clearModal();
    };

    const clearModal = () => {
        if (inputFileRef && inputFileRef?.current) inputFileRef.current.value = "";
        setInputFile(null);
        setFileError("");
        setSaving(false);
    };

    //convert file to base64
    const convertBase64 = async (file: Blob) => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                if (fileReader.result) {
                    //remove the metadata at the beginning
                    let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
                    if (encoded.length % 4 > 0) {
                        encoded += "=".repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            setInputFile(e.currentTarget.files[0]);
        }
    };

    const onImport = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSaving(true);
        if (inputFile) {
            convertBase64(inputFile).then(
                (ret) => {
                    runImport({
                        variables: {
                            purchaseInvoiceId: invoiceId,
                            base64: ret,
                        },
                    }).then(
                        (ret) => {
                            let invoiceIndex = data.purchaseOrder.invoices.findIndex((x) => x.id === invoiceId);
                            if (invoiceIndex !== -1) {
                                // let copy: IPurchaseOrder = JSON.parse(JSON.stringify(data.purchaseOrder));
                                let copy: IPurchaseOrder = {
                                    id: data.purchaseOrder.id,
                                    subtotal: data.purchaseOrder.subtotal,
                                    tax: data.purchaseOrder.tax,
                                    total: data.purchaseOrder.total,
                                    blindReceipt: data.purchaseOrder.blindReceipt,
                                    status: data.purchaseOrder.status,
                                    orderDate: data.purchaseOrder.orderDate,
                                    supplierId: data.purchaseOrder.supplierId,
                                    supplierContactId: data.purchaseOrder.supplierContactId,
                                    supplierAddressId: data.purchaseOrder.supplierAddressId,
                                    billingAddress: {
                                        id: data.purchaseOrder.billingAddress.id,
                                    },
                                    shippingAddress: {
                                        id: data.purchaseOrder.shippingAddress.id,
                                    },
                                    lineItems: data.purchaseOrder.lineItems,
                                    invoices: data.purchaseOrder.invoices,
                                    reference: data.purchaseOrder.reference,
                                    notes: data.purchaseOrder.notes,
                                };
                                copy.invoices[invoiceIndex] = ret.data.api.importExport.import.purchaseInvoiceLineItems;
                                data.purchaseOrder = copy;
                                // data.purchaseOrder.invoices[invoiceIndex] = ret.data.api.importExport.import.purchaseInvoiceLineItems;
                                onHideModal();
                                setSaving(false);
                                setInputFile(null);
                            }
                        },
                        (err) => {
                            console.error(`Error uploading Purchase Invoice `, err);
                            setFileError(err.message);
                            setSaving(false);
                            setInputFile(null);
                        }
                    );
                },
                (err) => {
                    console.log(err.message);
                    setSaving(false);
                    setInputFile(null);
                }
            );
        }
    };

    const onExport = () => {
        runExport({
            variables: {
                purchaseInvoiceId: invoiceId,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.importExport.export.purchaseInvoiceLineItems;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                let currentInvoiceNumber = data.purchaseOrder.invoices.find((x) => x.id === invoiceId)?.invoiceNumber;
                fileLink.download = `Purchase Order ZPO-${data.purchaseOrder.id} ${
                    currentInvoiceNumber ? "Invoice " + currentInvoiceNumber : "New Invoice"
                }`;
                fileLink.click();
            },
            (err) => {
                console.error(`Error trying to export LineItems`, err);
                alert("Error trying to export data");
            }
        );
    };

    const originalModel: IPurchaseInvoiceModel = {
        id: existingInvoice ? existingInvoice.id : "0",
        purchaseOrderId: data.purchaseOrder.id,
        invoiceNumber: existingInvoice ? existingInvoice.invoiceNumber : "",
        invoiceDate: existingInvoice ? existingInvoice!.invoiceDate! : "",
        arrivalDate: existingInvoice?.arrivalDate ?? "",
        status: existingInvoice ? existingInvoice.status : PurchaseInvoiceStatus.Authorized,
        trackingNumbers: existingInvoice && existingInvoice.trackingNumbers ? existingInvoice.trackingNumbers : [],
        lineItems:
            existingInvoice && existingInvoice.lineItems
                ? existingInvoice.lineItems
                      .map((lineItem) => ({
                          id: lineItem.id,
                          purchaseOrderLineItemId: lineItem.purchaseOrderLineItemId,
                          expectedQuantity: lineItem.expectedQuantity.toString(),
                          actualQuantity: lineItem.actualQuantity.toString(),
                          unitPrice: lineItem.unitPrice.toString(),
                          discount: lineItem.discount.toFixed(2) || "0",
                          comment: lineItem.comment || "",
                          total: lineItem.total.toString(),
                          supplierSku:
                              data.purchaseOrder.lineItems.find((x) => x.id === lineItem.purchaseOrderLineItemId)?.supplierSku || "",
                          isAdditionalCharge: false,
                      }))
                      .filter((x) =>
                          data.purchaseOrder.lineItems
                              .filter((y) => y.isAdditionalCharge === false)
                              .find((z) => z.id === x.purchaseOrderLineItemId)
                      )
                : [],
        additionalCharges:
            existingInvoice && existingInvoice.lineItems
                ? existingInvoice.lineItems
                      .map((lineItem) => ({
                          id: lineItem.id,
                          purchaseOrderLineItemId: lineItem.purchaseOrderLineItemId,
                          expectedQuantity: lineItem.expectedQuantity.toString(),
                          actualQuantity: lineItem.actualQuantity.toString(),
                          unitPrice: lineItem.unitPrice.toString(),
                          discount: lineItem.discount.toFixed(2) || "0",
                          comment: lineItem.comment || "",
                          total: lineItem.total.toString(),
                          supplierSku:
                              data.purchaseOrder.lineItems.find((x) => x.id === lineItem.purchaseOrderLineItemId)?.supplierSku || "",
                          isAdditionalCharge: true,
                      }))
                      .filter((x) =>
                          data.purchaseOrder.lineItems
                              .filter((y) => y.isAdditionalCharge === true)
                              .find((z) => z.id === x.purchaseOrderLineItemId)
                      )
                : [],
        subtotal: existingInvoice ? existingInvoice.subtotal.toString() : "0",
        tax: existingInvoice ? existingInvoice.tax.toString() : "0",
        total: existingInvoice ? existingInvoice.total.toString() : "0",
        paymentTermId: existingInvoice ? existingInvoice.paymentTermId : "",
    };

    const total = (obj: IPurchaseInvoiceModel) => {
        return obj.lineItems
            .concat(obj.additionalCharges)
            .map(
                (lineItem) =>
                    parseFloat(lineItem.expectedQuantity) * parseFloat(lineItem.unitPrice) -
                    parseFloat(lineItem.expectedQuantity) *
                        parseFloat(lineItem.unitPrice) *
                        (parseFloat(lineItem.discount ? lineItem.discount : "0") / 100)
            )
            .reduce((sum, current) => sum + current, 0)
            .toFixed(2);
    };

    const lineItemTotal = (obj: IPurchaseInvoiceLineItemModel) => {
        return (
            parseFloat(obj.expectedQuantity) * parseFloat(obj.unitPrice) -
            parseFloat(obj.expectedQuantity) * parseFloat(obj.unitPrice) * (parseFloat(obj.discount ? obj.discount : "0") / 100)
        ).toFixed(2);
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ImportExportButtons = () => {
        if (invoiceId !== "0") {
            return (
                <>
                    <Button variant="outline-secondary" onClick={onShowModal}>
                        Import
                    </Button>
                    <Button variant="outline-secondary" className="mx-3" onClick={onExport}>
                        Export
                    </Button>
                </>
            );
        }
        return <></>;
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CopyRemainingButton = (props: { table: string; charges: boolean }) => {
        const validationFormContext = React.useContext(ValidationFormContext);

        const addRemainingLineItems = () => {
            const currentLineItems: IPurchaseInvoiceLineItemModel[] = validationFormContext.currentValue[props.table];

            //make dictionary of purchase order line item to expected quantity for what's already in every invoice expcept the current one
            var additionalLineItems = new Array<IPurchaseInvoiceLineItemModel>();
            var lineItemToInvoiceQuantity = {};
            data.purchaseOrder.invoices.forEach((purchaseOrderInvoice) => {
                if (purchaseOrderInvoice.id !== invoiceId) {
                    purchaseOrderInvoice.lineItems?.forEach((invoiceLineItem) => {
                        if (invoiceLineItem.purchaseOrderLineItemId in lineItemToInvoiceQuantity)
                            lineItemToInvoiceQuantity[invoiceLineItem.purchaseOrderLineItemId] += invoiceLineItem.expectedQuantity;
                        else lineItemToInvoiceQuantity[invoiceLineItem.purchaseOrderLineItemId] = invoiceLineItem.expectedQuantity;
                    });
                }
            });

            // now add the line items from the current invoice to the dictionary
            currentLineItems.forEach((invoiceLineItem) => {
                if (invoiceLineItem.purchaseOrderLineItemId in lineItemToInvoiceQuantity)
                    lineItemToInvoiceQuantity[invoiceLineItem.purchaseOrderLineItemId] += invoiceLineItem.expectedQuantity;
                else lineItemToInvoiceQuantity[invoiceLineItem.purchaseOrderLineItemId] = invoiceLineItem.expectedQuantity;
            });
            let currentLineItemsChanged = false;
            data.purchaseOrder.lineItems
                .filter((x) => x.isAdditionalCharge === props.charges)
                .forEach((lineItem) => {
                    if (!(lineItem.id in lineItemToInvoiceQuantity))
                        additionalLineItems.push({
                            id: "0",
                            purchaseOrderLineItemId: lineItem.id,
                            expectedQuantity: lineItem.quantity.toString(),
                            actualQuantity: "0",
                            unitPrice: lineItem.unitPrice.toString(),
                            discount: lineItem.discount.toString(),
                            comment: lineItem.comment,
                            total: lineItem.total.toString(),
                            supplierSku: lineItem.supplierSku,
                            isAdditionalCharge: lineItem.isAdditionalCharge,
                        });
                    else if (lineItemToInvoiceQuantity[lineItem.id] < lineItem.quantity) {
                        let existingLineItemIndex = currentLineItems.findIndex((currentLineItem) => {
                            return (
                                lineItem.id === currentLineItem.purchaseOrderLineItemId &&
                                lineItem.unitPrice === parseFloat(currentLineItem.unitPrice) &&
                                lineItem.discount === parseFloat(currentLineItem.discount)
                            );
                        });
                        if (existingLineItemIndex !== -1) {
                            currentLineItemsChanged = true;
                            currentLineItems[existingLineItemIndex].expectedQuantity = (
                                lineItem.quantity -
                                (lineItemToInvoiceQuantity[lineItem.id] -
                                    parseFloat(currentLineItems[existingLineItemIndex].expectedQuantity))
                            ).toString();
                        } else {
                            additionalLineItems.push({
                                id: "0",
                                purchaseOrderLineItemId: lineItem.id,
                                expectedQuantity: (lineItem.quantity - lineItemToInvoiceQuantity[lineItem.id]).toString(),
                                actualQuantity: "0",
                                unitPrice: lineItem.unitPrice.toString(),
                                discount: lineItem.discount.toString(),
                                comment: lineItem.comment,
                                total: (lineItem.total * (lineItemToInvoiceQuantity[lineItem.id] / lineItem.quantity)).toString(),
                                supplierSku: lineItem.supplierSku,
                                isAdditionalCharge: lineItem.isAdditionalCharge,
                            });
                        }
                    }
                });
            if (additionalLineItems.length === 0 && !currentLineItemsChanged) {
                console.error("All remaining line items are already in this purchase order's invoices");
                alert("All remaining line items are already in this purchase order's invoices");
            } else {
                //const newRow: IPurchaseInvoiceLineItemModel = { id: "0", purchaseOrderLineItemId: "", expectedQuantity: "", actualQuantity: "", unitPrice: "", discount: "", comment: "", total: "" };
                const newLineItems = [...currentLineItems, ...additionalLineItems];
                validationFormContext.onChange(props.table, newLineItems);
            }
        };
        return (
            <Button variant="outline-success" onClick={addRemainingLineItems}>
                Copy Remaining
            </Button>
        );
    };

    const onSaveChanges = (modified: IPurchaseInvoiceModel) => {
        setSaving(true);
        if (existingInvoice) {
            runEdit({
                variables: {
                    original: existingInvoice,
                    modified: {
                        id: existingInvoice ? existingInvoice.id : "0",
                        purchaseOrderId: data.purchaseOrder.id,
                        invoiceDate: modified.invoiceDate,
                        arrivalDate: StringHelper.IsNullOrWhitespace(modified.arrivalDate) ? null : modified.arrivalDate,
                        invoiceNumber: modified.invoiceNumber,
                        paymentTermId: modified.paymentTermId,
                        status: modified.status,
                        tax: parseFloat(modified.tax || "0"),
                        total: parseFloat(total(modified)),
                        subtotal: parseFloat(total(modified)),
                        lineItems: modified.lineItems.concat(modified.additionalCharges).map((lineItem) => ({
                            id: lineItem.id || "0",
                            purchaseOrderLineItemId: lineItem.purchaseOrderLineItemId,
                            expectedQuantity: parseFloat(lineItem.expectedQuantity),
                            actualQuantity: parseFloat(lineItem.actualQuantity) || 0,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount || "0"),
                            comment: lineItem.comment,
                            total: parseFloat(lineItemTotal(lineItem)),
                        })),
                        trackingNumbers: modified.trackingNumbers
                            ? modified.trackingNumbers.map((x, i) => ({
                                  shippingServiceId: x.shippingServiceId,
                                  trackingNumber: x.trackingNumber,
                                  sortOrder: i,
                              }))
                            : null,
                    },
                },
            }).then(
                (ret) => {
                    if (existingInvoice) {
                        const newValue = ret.data.purchaseInvoice.edit;
                        const originalId = existingInvoice.id;
                        const oldIndex = data.purchaseOrder.invoices.findIndex((x) => x.id === originalId);
                        if (oldIndex >= 0) data.purchaseOrder.invoices[oldIndex] = newValue;
                        const lastIndex =
                            invoiceId === "0"
                                ? data.purchaseOrder.invoices.length - 1
                                : data.purchaseOrder.invoices.findIndex((x) => x.id === invoiceId);
                        let path = `/purchaseorders/${data.purchaseOrder.id}/invoices/${data.purchaseOrder.invoices[lastIndex]?.id}`;
                        history.push(path);
                        setSaving(false);
                    }
                },
                (err) => {
                    setSaving(false);
                    console.error("Error editing purchase order", err);
                    alert(err.message);
                }
            );
        } else {
            runAdd({
                variables: {
                    value: {
                        id: "0",
                        purchaseOrderId: data.purchaseOrder.id,
                        invoiceDate: modified.invoiceDate,
                        arrivalDate: StringHelper.IsNullOrWhitespace(modified.arrivalDate) ? null : modified.arrivalDate,
                        invoiceNumber: modified.invoiceNumber,
                        paymentTermId: modified.paymentTermId,
                        status: modified.status,
                        tax: parseFloat(modified.tax || "0"),
                        total: parseFloat(total(modified)),
                        subtotal: parseFloat(total(modified)),
                        lineItems: modified.lineItems.concat(modified.additionalCharges).map((lineItem) => ({
                            id: lineItem.id || "0",
                            purchaseOrderLineItemId: lineItem.purchaseOrderLineItemId,
                            expectedQuantity: parseFloat(lineItem.expectedQuantity),
                            actualQuantity: parseFloat(lineItem.actualQuantity) || 0,
                            unitPrice: parseFloat(lineItem.unitPrice),
                            discount: parseFloat(lineItem.discount || "0"),
                            comment: lineItem.comment,
                            total: parseFloat(lineItemTotal(lineItem)),
                        })),
                        trackingNumbers: modified.trackingNumbers
                            ? modified.trackingNumbers.map((x, i) => ({
                                  shippingServiceId: x.shippingServiceId,
                                  trackingNumber: x.trackingNumber,
                                  sortOrder: i,
                              }))
                            : null,
                    },
                },
            }).then(
                (ret) => {
                    data.purchaseOrder.invoices.push(ret.data.purchaseInvoice.add); // newValue.invoices;
                    const lastIndex =
                        invoiceId === "0"
                            ? data.purchaseOrder.invoices.length - 1
                            : data.purchaseOrder.invoices.findIndex((x) => x.id === invoiceId);
                    let path = `/purchaseorders/${data.purchaseOrder.id}/invoices/${data.purchaseOrder.invoices[lastIndex]?.id}`;
                    history.push(path);
                    setSaving(false);
                },
                (err) => {
                    setSaving(false);
                    console.error("Error editing purchase order", err);
                    alert(err.message);
                }
            );
        }
    };

    const invoiceStatus = Object.values(PurchaseInvoiceStatus).filter((v) => isNaN(Number(v)));

    return (
        <>
            <div>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} reloadKey={data.purchaseOrder}>
                    <Nav
                        variant="pills"
                        className="mb-3"
                        activeKey={
                            invoiceId !== "0" && existingInvoice
                                ? existingInvoice.id
                                : `/purchaseorders/${data.purchaseOrder.id}/invoices/0`
                        }
                    >
                        {data.purchaseOrder.invoices.map((invoice) => (
                            <Nav.Item key={invoice.id}>
                                <Nav.Link
                                    eventKey={`${invoice.id}`}
                                    href={`/purchaseorders/${data.purchaseOrder.id}/invoices/${invoice.id}`}
                                >
                                    #{invoice.invoiceNumber}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                        <Nav.Item>
                            <Nav.Link
                                eventKey={`/purchaseorders/${data.purchaseOrder.id}/invoices/0`}
                                href={`/purchaseorders/${data.purchaseOrder.id}/invoices/0`}
                            >
                                Add Invoice
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Row>
                        <Col xs={{ span: 12, order: 2 }} xxl={{ span: 8, order: 1 }}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>General</div>
                                    <div>
                                        <VSelect valueName="status">
                                            {invoiceStatus.map((s) => (
                                                <option key={s} value={s}>
                                                    {s}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel valueName="invoiceNumber" column sm={4}>
                                                    Invoice Number
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl valueName="invoiceNumber" required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel valueName="invoiceDate" column sm={4}>
                                                    Invoice Date
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="date" valueName="invoiceDate" required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel valueName="arrivalDate" column sm={4}>
                                                    Arrival Date
                                                </VLabel>
                                                <Col sm={8}>
                                                    <VControl type="date" valueName="arrivalDate" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel title="Payment Term" valueName="paymentTermId" column sm={4} />
                                                <Col sm={8}>
                                                    <VSelect valueName="paymentTermId" required>
                                                        <option value=""></option>
                                                        {data.paymentTerms.items
                                                            .filter((x) => x.deleted === false)
                                                            .map((paymentTerm) => (
                                                                <option key={paymentTerm.id} value={paymentTerm.id}>
                                                                    {paymentTerm.name}
                                                                </option>
                                                            ))}
                                                    </VSelect>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Group as={Row} className="mb-2">
                                                <VLabel valueName="trackingNumbers" column sm={4} lg={3}>
                                                    Tracking
                                                </VLabel>
                                                <Col sm={8} lg={9}>
                                                    <div>
                                                        <Table hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Number</th>
                                                                    <th>Shipping Service</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <VArray
                                                                    fieldName="trackingNumbers"
                                                                    blankRow={{
                                                                        trackingNumber: "",
                                                                        shippingServiceId: "",
                                                                    }}
                                                                >
                                                                    <tr>
                                                                        <td>
                                                                            <VControl type="text" valueName="trackingNumber" required />
                                                                        </td>
                                                                        <td>
                                                                            <VSpecialSelect
                                                                                required
                                                                                valueName="shippingServiceId"
                                                                                options={dataShippingService.shippingServices.items.map(
                                                                                    (x) => ({ value: x.id, displayName: x.name })
                                                                                )}
                                                                                map={
                                                                                    new Map(
                                                                                        dataShippingService.shippingServices.items.map(
                                                                                            (x) => [x.id, x.name]
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <XButton />
                                                                        </td>
                                                                    </tr>
                                                                </VArray>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xxl={{ span: 4, order: 2 }}>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white">Summary</Card.Header>
                                <Card.Body>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Subtotal</td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        valueName="subtotal"
                                                        dynamicValue={(obj: IPurchaseInvoiceModel) => total(obj)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tax</td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        valueName="tax"
                                                        dynamicValue={(obj: IPurchaseInvoiceModel) => obj.tax}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Total</b>
                                                </td>
                                                <td>
                                                    <VControl
                                                        type="text"
                                                        valueName="total"
                                                        dynamicValue={(obj: IPurchaseInvoiceModel) => total(obj)}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <p>
                                <VButton variant="primary" className="me-2" type="submit">
                                    Save
                                </VButton>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="border-primary">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    <div>Products & Services</div>
                                    <div>
                                        <Button variant="success">Authorize</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p>Products</p>
                                    {/* <div>
                                    <Button variant="outline-secondary" onClick={onShowModal}>Import</Button>
                                    <Button variant="outline-secondary" className="mx-3" onClick={onExport}>Export</Button>
                                </div> */}
                                    <ImportExportButtons />
                                    <CopyRemainingButton table="lineItems" charges={false} />
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Purchase Line Item</th>
                                                <th>Supplier Sku</th>
                                                <th>Expected Quantity</th>
                                                <th>Actual Quantity</th>
                                                <th>Unit Price</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="lineItems"
                                                blankRow={{
                                                    purchaseOrderLineItemId: "",
                                                    expectedQuantity: "",
                                                    actualQuantity: "" || 0,
                                                    unitPrice: "",
                                                    discount: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td className="invoice-tbl">
                                                        <VSpecialSelect
                                                            required
                                                            valueName="purchaseOrderLineItemId"
                                                            options={filteredLineItems.map((x) => ({
                                                                value: x.id,
                                                                displayName: x.product.sku,
                                                            }))}
                                                            map={
                                                                new Map(
                                                                    data.purchaseOrder.lineItems.map((poli) => [poli.id, poli.product.sku])
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="supplierSku" required readOnly />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="expectedQuantity" required pattern="^\d+$" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="actualQuantity" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="unitPrice"
                                                            required
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="discount"
                                                            pattern="^([0-9][0-9]?|)(\.[0-9]{0,2})?$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="total"
                                                            dynamicValue={(obj: IPurchaseInvoiceLineItemModel) =>
                                                                obj.unitPrice ? lineItemTotal(obj) : ""
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                    <p>Additional Charges</p>
                                    <CopyRemainingButton table="additionalCharges" charges={true} />
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Shipping Rate</th>
                                                <th>Supplier Sku</th>
                                                <th>Expected Quantity</th>
                                                <th>Actual Quantity</th>
                                                <th>Unit Price</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <VArray
                                                fieldName="additionalCharges"
                                                blankRow={{
                                                    purchaseOrderLineItemId: "",
                                                    expectedQuantity: "",
                                                    actualQuantity: "" || 0,
                                                    unitPrice: "",
                                                    discount: "",
                                                    total: "",
                                                }}
                                            >
                                                <tr>
                                                    <td className="invoice-tbl">
                                                        <VSpecialSelect
                                                            required
                                                            valueName="purchaseOrderLineItemId"
                                                            options={additionalChargeItems.map((x) => ({
                                                                value: x.id,
                                                                displayName: x.product.sku,
                                                            }))}
                                                            map={
                                                                new Map(
                                                                    data.purchaseOrder.lineItems.map((poli) => [poli.id, poli.product.sku])
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="supplierSku" required readOnly />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="expectedQuantity" required pattern="^\d+$" />
                                                    </td>
                                                    <td>
                                                        <VControl type="text" valueName="actualQuantity" disabled />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="unitPrice"
                                                            required
                                                            pattern="^[0-9]*(\.[0-9]{0,4})?$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="discount"
                                                            pattern="^([0-9][0-9]?|)(\.[0-9]{0,2})?$"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VControl
                                                            type="text"
                                                            valueName="total"
                                                            dynamicValue={(obj: IPurchaseInvoiceLineItemModel) =>
                                                                obj.unitPrice ? lineItemTotal(obj) : ""
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <XButton />
                                                    </td>
                                                </tr>
                                            </VArray>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </VForm>
            </div>
            <Modal show={modal.show} onHide={onHideModal}>
                <form onSubmit={onImport}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="csvFile" className="mb-2">
                            <Form.Label>Import CSV</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xls, .xlsx"
                                required
                                className="primary"
                                onChange={onChangeImportFile}
                                ref={inputFileRef}
                            />
                        </Form.Group>
                        <Button variant="primary" className="mb-2" type="submit" disabled={saving || inputFile === null}>
                            Upload
                        </Button>
                        <Form.Group className="mb-2">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" readOnly value={fileError} rows={5} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={clearModal}>
                            Clear
                        </Button>
                        <Button variant="secondary" onClick={onHideModal} className="me-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default PurchaseOrdersInvoices;
