import { useQuery } from "@shane32/graphql";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";

const locationQuery = `
query ($locationId: ID!) {
    location(id: $locationId) {
      name
    }
  }
`;

interface ILocationQueryResult {
    location: {
        name: string;
    };
}

interface ILocationQueryVariables {
    locationId: string;
}

interface IRouteParams {
    locationId: string;
    productId: string;
}

const AddOrPrintBarcodeContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<IRouteParams>();

    const { refetch, data, error } = useQuery<ILocationQueryResult, ILocationQueryVariables>(locationQuery, {
        variables: !params.locationId
            ? undefined
            : {
                  locationId: params.locationId,
              },
        skip: !params.locationId,
    });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!params.locationId) return <Redirect to={`/mobile/stocktake/stocktakesearch`} />;

    if (!params.productId) return <Redirect to={`/mobile/stocktake/stocktake/${params.locationId}`} />;

    return (
        <>
            <PrimaryHeader
                Title={data?.location.name ?? "Loading..."}
                IncludeHomeButton
                IncludeBackButton
                BackButtonText={"< Back"}
                CustomBackButtonPath={`/mobile/stocktake/stocktake/${params.locationId}${location.search}`}
            />

            <SecondaryHeader Title="Confirm Stock" NextButtonHandler={() => void 0} />

            <AddOrPrintBarcode
                productId={params.productId}
                onBarcodeSaveAction={() => history.push(`/mobile/stocktake/stocktake/${params.locationId}${location.search}`)}
            />
        </>
    );
};

export default AddOrPrintBarcodeContainer;
