import { CSSProperties } from "react";
import style from "./MobileLoading.module.scss";

interface IMobileLoadingProps {
    /**
     * Sets whether or not to take up the entire screen when component is used.
     * Fullscreen mode includes a backdrop and centers the loading icon in the middle of the page, vertically and horizontally.
     * If no value is provided as a prop, "fullscreen" defaults to false. */
    fullscreen?: boolean;
    /**
     * Sets the color of the loader. Accepts any CSS3 color or hex value.
     * Default color is yellow (#FFE81A). */
    loaderColor?: string;
}

/**
 * Used as a loading element for mobile pages. Place this component anywhere you want a loading icon.
 * To change the size of the loader, wrap this component in a containing <div> with an explicit height.
 * Ex: <div style={{height: 50}}><MobileLoading /></div>
 * Note: Max width/height is 120px. The aspect ratio is also kept as 1:1 no matter the width/height.
 * @param props
 */
const MobileLoading = (props: IMobileLoadingProps) => {
    const loader = (
        <div
            className={style.loader}
            style={{
                border: `5px solid ${"#f3f3f3"}` /* Loader background color */,
                borderTop: `5px solid ${props.loaderColor ?? "#FFE81A"}` /* Loader color */,
            }}
        />
    );

    const background: CSSProperties = {
        position: "fixed",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        margin: "auto",
        zIndex: 1000000,
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const fullscreenLoader = <div style={background}>{loader}</div>;

    return !props.fullscreen ? loader : fullscreenLoader;
};

export default MobileLoading;
