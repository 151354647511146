import React from "react";
import { Row, Col, Form, Button, Modal, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import StateCode from "../../enums/StatesCode";
import { useMutation, useQuery } from "@shane32/graphql";

interface ISupplierQueryResult {
    supplier: ISupplier;
}

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: number;
    active: boolean;
    contacts: Array<ISupplierContact>;
    addresses: Array<ISupplierAddressMapping>;
}

interface ISupplierContact {
    id: string;
    name: string;
    role: string;
    phone: string | null;
    mobilePhone: string | null;
    fax: string | null;
    email: string | null;
    notes: string | null;
    sortOrder: number;
}

interface ICountryQueryResult {
    countries: {
        items: Array<ICountry>;
    };
}

interface ICountry {
    id: string;
    name: string;
    isoCode: string;
    sortOrder: number;
}

interface ISupplierContactModel {
    id: string;
    name: string;
    role: string;
    phone: string;
    mobilePhone: string;
    fax: string;
    email: string;
    notes: string;
    sortOrder: string;
}

interface ISupplierAddressMapping {
    sortOrder: number;
    address: ISupplierAddress;
}

interface ISupplierAddress {
    id: string;
    description: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    countryId: string | null;
    phone: string | null;
}

interface ISupplierAddressModel {
    id: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    countryId: string;
    phone: string;
    sortOrder: string;
}

interface IEditResult {
    supplier: {
        edit: ISupplier;
    };
}

interface IEditVariables {
    original: ISupplier;
    modified: ISupplier;
}

interface IContactModal {
    show: boolean;
    original?: ISupplierContact;
    modified?: ISupplierContact;
}

interface IAddressModal {
    show: boolean;
    original?: ISupplierAddressMapping;
    modified?: ISupplierAddressMapping;
}

const hiddenContactModal: IContactModal = {
    show: false,
};

const hiddenAddressModal: IAddressModal = {
    show: false,
};

const SupplierQuery = `
query ($id: ID!) {
  supplier(id: $id) {
    id
    name
    defaultPaymentTermId
    active
    contacts {
      id
      name
      role
      phone
      mobilePhone
      fax
      email
      notes
      sortOrder
    }
    addresses {
      sortOrder
      address {
        id
        description
        address1
        address2
        city
        state
        zip
        phone
        countryId
      }
    }
  }
}`;

const SupplierMutation = `
mutation ($original: SupplierInput!, $modified: SupplierInput!) {
  supplier {
    edit(original: $original, modified: $modified) {
      id
      name
      defaultPaymentTermId
      active
      contacts {
        id
        name
        role
        phone
        mobilePhone
        fax
        email
        notes
        sortOrder
      }
      addresses {
        sortOrder
        address {
          id
          description
          address1
          address2
          city
          state
          zip
          phone
          countryId
        }
      }
    }
  }
}
`;

const CountryQuery = `
{
  countries {
    items {
      id
      name
      isoCode
      sortOrder
    }
  }
}
`;

const SupplierSummary = () => {
    const [contactModal, setContactModal] = React.useState<IContactModal>(hiddenContactModal);
    const [addressModal, setAddressModal] = React.useState<IAddressModal>(hiddenAddressModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<ISupplierQueryResult, { id: string }>(SupplierQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: dataCountry,
        error: errorCountry,
        refetch: refetchCountry,
    } = useQuery<ICountryQueryResult, {}>(CountryQuery, { fetchPolicy: "no-cache" });
    const [runSupplierEdit] = useMutation<IEditResult, IEditVariables>(SupplierMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (errorCountry) return <ErrorDisplay onClick={refetchCountry}>{errorCountry.message}</ErrorDisplay>;

    if (!data || !dataCountry) return <Loading />;

    if (!data.supplier) return <ErrorDisplay onClick={refetch}>Supplier ID #{id}</ErrorDisplay>;

    const onShowAddContactModal = () => {
        setContactModal({ ...hiddenContactModal, show: true });
    };

    const onShowEditContactModal = (value: ISupplierContact) => {
        setContactModal({
            show: true,
            original: value,
        });
    };

    const onShowAddAddressModal = () => {
        setAddressModal({ ...hiddenAddressModal, show: true });
    };

    const onShowEditAddressModal = (value: ISupplierAddressMapping) => {
        setAddressModal({
            show: true,
            original: value,
        });
    };

    const onHideContactModal = () => {
        if (!saving) setContactModal(hiddenContactModal);
    };

    const onHideAddressModal = () => {
        if (!saving) setAddressModal(hiddenAddressModal);
    };

    const originalContactModel: ISupplierContactModel = contactModal.original
        ? {
              id: contactModal.original.id,
              name: contactModal.original.name,
              role: contactModal.original.role || "",
              email: contactModal.original.email || "",
              fax: contactModal.original.fax || "",
              mobilePhone: contactModal.original.mobilePhone || "",
              notes: contactModal.original.notes || "",
              phone: contactModal.original.phone || "",
              sortOrder: contactModal.original.sortOrder + "",
          }
        : {
              id: "",
              name: "",
              role: "",
              email: "",
              fax: "",
              mobilePhone: "",
              notes: "",
              phone: "",
              sortOrder: "",
          };

    const originalAddressModel: ISupplierAddressModel = addressModal.original
        ? {
              id: addressModal.original.address.id,
              description: addressModal.original.address.description || "",
              address1: addressModal.original.address.address1 || "",
              address2: addressModal.original.address.address2 || "",
              city: addressModal.original.address.city || "",
              zip: addressModal.original.address.zip || "",
              state: addressModal.original.address.state || "",
              countryId: addressModal.original.address.countryId || "",
              phone: addressModal.original.address.phone || "",
              sortOrder: addressModal.original.sortOrder + "" || "",
          }
        : {
              id: "",
              description: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              countryId: "",
              phone: "",
              sortOrder: "",
          };
    /*
        Save Contact Helper Method
    */
    const runSave = (
        originalContact: ISupplierContact | null,
        modifiedContact: ISupplierContact | null,
        originalAddress: ISupplierAddressMapping | null,
        modifiedAddress: ISupplierAddressMapping | null
    ) => {
        setSaving(true);
        runSupplierEdit({
            variables: {
                original: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    contacts: originalContact ? [originalContact] : [],
                    addresses: originalAddress ? [originalAddress] : [],
                    active: data.supplier.active,
                },
                modified: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    contacts: modifiedContact ? [modifiedContact] : [],
                    addresses: modifiedAddress ? [modifiedAddress] : [],
                    active: data.supplier.active,
                },
            },
        }).then(
            (ret) => {
                setSaving(false);
                data.supplier = ret.data.supplier.edit;
                setContactModal(hiddenContactModal);
                setAddressModal(hiddenAddressModal);
            },
            (err) => {
                setSaving(false);
                console.error("Error updating supplier", err);
                alert(err.message);
            }
        );
    };
    /*
        Save Contact Changes
    */
    const onSaveContactChanges = (modified: ISupplierContactModel) => {
        runSave(
            //send original, or null if adding
            contactModal.original || null,
            //send modified contact with original id if editing
            {
                id: contactModal.original?.id || "0",
                name: modified.name,
                email: modified.email,
                fax: modified.fax,
                mobilePhone: modified.mobilePhone,
                notes: modified.notes,
                phone: modified.phone,
                role: modified.role,
                sortOrder: parseFloat(modified.sortOrder || "1"),
            },
            //do not modify address
            null,
            null
        );
    };
    /*
        Delete Contact
    */
    const onDeleteContact = () => {
        //=== DELETE ===
        //ensure we are editing
        if (!contactModal.original) return;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this contact?")) return;
        //delete the contact
        runSave(
            //send original contact
            contactModal.original,
            //and null to delete it
            null,
            //do not modify address
            null,
            null
        );
    };
    /*
        Save Address Changes
    */
    const onSaveAddressChanges = (modified: ISupplierAddressModel) => {
        runSave(
            //do not modify contact
            null,
            null,
            //send original address or null if adding
            addressModal.original || null,
            //send modified address mapping
            {
                sortOrder: parseFloat(modified.sortOrder || "1"),
                address: {
                    id: addressModal.original?.address.id || "0", //send 0 for add
                    description: modified.description,
                    address1: modified.address1,
                    address2: modified.address2,
                    city: modified.city,
                    state: modified.state,
                    zip: modified.zip,
                    countryId: modified.countryId === "" ? null : modified.countryId,
                    phone: modified.phone,
                },
            }
        );
    };
    /*
        Delete Address
    */
    const onDeleteAddress = () => {
        //=== DELETE ===
        //verify we are deleting
        if (!addressModal.original) return;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this address?")) return;

        runSave(
            //do not modify contact
            null,
            null,
            //send original address
            addressModal.original,
            //send null to delete
            null
        );
    };

    if (data.supplier.contacts.length > 0) {
        data.supplier.contacts.sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name));
    }

    if (data.supplier.addresses.length > 0) {
        data.supplier.addresses.sort(
            (a, b) => a.sortOrder - b.sortOrder || (a.address.description || "").localeCompare(b.address.description || "")
        );
    }

    const states = Object.values<string>(StateCode);
    states.unshift("");

    return (
        <>
            <Row>
                <Col md={6}>
                    {/*
                    Contact Card
                 */}
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Contacts</Card.Header>
                        <Card.Body>
                            <Row>
                                {data.supplier.contacts.map((contact) => (
                                    <Col lg={6} className="pe-lg-4 mb-3" key={contact.id}>
                                        <Button
                                            size="sm"
                                            variant="white"
                                            className="ms-4"
                                            style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                            onClick={() => onShowEditContactModal(contact)}
                                        >
                                            Edit
                                        </Button>
                                        <b>{contact.name}</b>
                                        <br />
                                        {contact.role}
                                        <br />
                                        {contact.phone}
                                    </Col>
                                ))}
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="white" onClick={onShowAddContactModal}>
                                Add
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={6}>
                    {/*
                    Address Card
                 */}
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Addresses</Card.Header>
                        <Card.Body>
                            <Row>
                                {data.supplier.addresses.map((addressMapping) => {
                                    const country = dataCountry.countries.items.find((x) => x.id === addressMapping.address.countryId);
                                    return (
                                        <Col lg={6} className="pe-lg-4 mb-3" key={`Address-${addressMapping.address.id}`}>
                                            <div>
                                                <Button
                                                    size="sm"
                                                    variant="white"
                                                    className="ms-4"
                                                    style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                    onClick={() => onShowEditAddressModal(addressMapping)}
                                                >
                                                    Edit
                                                </Button>
                                                <b>{addressMapping.address.description}</b>
                                                <br />
                                                {addressMapping.address.address1}
                                                <br />
                                                {addressMapping.address.city}, {addressMapping.address.state} {addressMapping.address.zip}
                                                <br />
                                                {country?.isoCode}
                                                <br />
                                                {addressMapping.address.phone}
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="white" onClick={onShowAddAddressModal}>
                                Add
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            {/*
            Contact Modal
        */}
            <Modal show={contactModal.show} onHide={onHideContactModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveContactChanges} initialValue={originalContactModel} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!contactModal.original ? "Add" : "Edit"} Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="contactName">
                                    <VLabel valueName="name">Name</VLabel>
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactRole">
                                    <VLabel valueName="role">Role</VLabel>
                                    <VControl type="text" valueName="role" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactPhone">
                                    <VLabel valueName="phone">Phone</VLabel>
                                    <VControl type="text" valueName="phone" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactMobile">
                                    <VLabel valueName="mobilePhone">Mobile</VLabel>
                                    <VControl type="text" valueName="mobilePhone" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactFax">
                                    <VLabel valueName="fax">Fax</VLabel>
                                    <VControl type="text" valueName="fax" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactEmail">
                                    <VLabel valueName="email">Email</VLabel>
                                    <VControl type="text" valueName="email" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactNotes">
                                    <VLabel valueName="notes">Notes</VLabel>
                                    <VControl type="textarea" valueName="notes" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactSortOrder">
                                    <VLabel valueName="sortOrder">Sort Order</VLabel>
                                    <VControl type="text" pattern="^$|\d+\.?\d*" valueName="sortOrder" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideContactModal} className="me-auto">
                            Cancel
                        </VButton>
                        {contactModal.original ? (
                            <VButton type="button" variant="danger" onClick={onDeleteContact}>
                                Delete
                            </VButton>
                        ) : null}
                    </Modal.Footer>
                </VForm>
            </Modal>
            {/*
            Address Modal
        */}
            <Modal show={addressModal.show} onHide={onHideAddressModal}>
                <Modal.Body>
                    <VForm onSubmit={onSaveAddressChanges} initialValue={originalAddressModel} saving={saving}>
                        <Modal.Header closeButton>
                            {/* set popup title appropriately */}
                            <Modal.Title>{!addressModal.original ? "Add" : "Edit"} Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={{ span: 10, offset: 1 }}>
                                    <Form.Group className="mb-4" controlId="addressDescription">
                                        <VLabel valueName="description">Description</VLabel>
                                        <VControl type="text" valueName="description" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressAddress1">
                                        <VLabel valueName="address1">Address1</VLabel>
                                        <VControl type="text" valueName="address1" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressAddress2">
                                        <VLabel valueName="address2">Address 2</VLabel>
                                        <VControl type="text" valueName="address2" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressCity">
                                        <VLabel valueName="city">City</VLabel>
                                        <VControl type="text" valueName="city" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressState">
                                        <VLabel valueName="state">State</VLabel>
                                        <VSelect valueName="state">
                                            <option value=""></option>
                                            {states.map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </VSelect>
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressZip">
                                        <VLabel valueName="zip">Zip</VLabel>
                                        <VControl type="text" valueName="zip" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressCountry">
                                        <VLabel valueName="countryId">Country</VLabel>
                                        <VSelect valueName="countryId">
                                            <option value=""></option>
                                            {dataCountry.countries.items
                                                .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name))
                                                .map((country) => (
                                                    <option key={`sales-${country.id}`} value={country.id}>
                                                        {country.isoCode}
                                                    </option>
                                                ))}
                                        </VSelect>
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressPhone">
                                        <VLabel valueName="phone">Phone</VLabel>
                                        <VControl type="text" valueName="phone" />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="addressSortOrder">
                                        <VLabel valueName="sortOrder">Sort Order</VLabel>
                                        <VControl type="text" pattern="^$|\d+\.?\d*" valueName="sortOrder" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* set 'disabled' while saving or when there are no changes */}
                            <VButton type="submit" variant="primary">
                                Save Changes
                            </VButton>
                            <VButton type="button" variant="white" onClick={onHideAddressModal} className="me-auto">
                                Cancel
                            </VButton>
                            {addressModal.original ? (
                                <VButton type="button" variant="danger" onClick={onDeleteAddress}>
                                    Delete
                                </VButton>
                            ) : null}
                        </Modal.Footer>
                    </VForm>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SupplierSummary;
