import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel } from "@shane32/vform";
import React from "react";
import { useQuery, useMutation } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import { IPackage } from "./PackageIndex";

interface IQueryResult {
    package: IPackageAndMappings;
}

interface IPackageAndMappings extends IPackage {
    packageProducts: IPackageProduct[];
}

interface IPackageProduct {
    sku: string;
}

interface IPackageAndSkus extends IPackage {
    packageProductSkus: Array<string>;
}

interface IEditResult {
    package: {
        edit: IPackageAndMappings;
    };
}

interface IPackageMapping {
    original: IPackageAndSkus;
    modified: IPackageAndSkus;
}

interface IAddSku {
    sku: string;
}

const originalAdd: IAddSku = {
    sku: "",
};

const PackageProductQuery = `
query($id: ID!) {
    package(id: $id) {
      id
      barcode
      active
      height
      length
      weight
      width
      type
      packageProducts {
        sku
      }
    }
  }
`;

const addPackageSku = `
mutation ($original: PackageInput!, $modified: PackageInput!) {
    package {
        edit(original: $original, modified: $modified) {
            id
            barcode
            active
            height
            length
            weight
            width
            type
            packageProducts {
                sku
            }
        }
    }
}`;

const deletePackageSku = `
mutation ($original: PackageInput!, $modified: PackageInput!){
    package {
        edit(original: $original, modified: $modified) {
            id
            barcode
            active
            height
            length
            weight
            width
            type
            packageProducts {
                sku
            }
        }
    }
}`;

// const bulkAddPackagingSkus = `
// mutation ($file: String!, packagingId: ID!){
//     packageMapping{
//         bulkAdd(file:  $file, packagingId: $packagingId)
//     }
// }`;

const PackageSummary = () => {
    const [packageModal, setPackageModal] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    //const [bulkSkuModel, setBulkSkuModel] = React.useState<boolean>(false);
    //const [skuFile, setSkuFile] = React.useState<File | null>(null);

    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(PackageProductQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });

    const [runAdd] = useMutation<IEditResult, IPackageMapping>(addPackageSku);
    const [runDelete] = useMutation<IEditResult, IPackageMapping>(deletePackageSku);
    //const [runAddFile] = useMutation<IEditResult, { file: string; packageId: number }>(bulkAddPackagingSkus);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!data) return <Loading />;

    if (!data.package) return <ErrorDisplay onClick={refetch}>Package Id #{id}</ErrorDisplay>;

    // const convertBase64 = async (file: Blob) => {
    //     return new Promise<string>((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);
    //         fileReader.onload = () => {
    //             if (fileReader.result) {
    //                 //remove the metadata at the beginning
    //                 let encoded = fileReader.result.toString().replace(/^data:(.*,)?/, "");
    //                 if (encoded.length % 4 > 0) {
    //                     encoded += "=".repeat(4 - (encoded.length % 4));
    //                 }
    //                 resolve(encoded);
    //             }
    //         };
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    // const onChangeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     e.preventDefault();
    //     if (e.currentTarget.files && e.currentTarget.files[0]) {
    //         setSkuFile(e.currentTarget.files[0]);
    //     }
    // };

    const onShowAddSkuModal = () => {
        setPackageModal(true);
    };

    const onHidePackageModal = () => {
        if (!saving) setPackageModal(false);
    };

    // const onShowBulkAddSkus = () => {
    //     setBulkSkuModel(true);
    // };

    // const onHideBulkAddSkus = () => {
    //     setSkuFile(null);
    //     setBulkSkuModel(false);
    // };

    const runSaveSku = (newSku: IAddSku) => {
        setSaving(true);
        runAdd({
            variables: {
                original: {
                    id: data.package.id,
                    barcode: data.package.barcode,
                    length: data.package.length,
                    width: data.package.width,
                    height: data.package.height,
                    weight: data.package.weight,
                    type: data.package.type,
                    active: data.package.active,
                    packageProductSkus: [],
                },
                modified: {
                    id: data.package.id,
                    barcode: data.package.barcode,
                    length: data.package.length,
                    width: data.package.width,
                    height: data.package.height,
                    weight: data.package.weight,
                    type: data.package.type,
                    active: data.package.active,
                    packageProductSkus: [newSku.sku],
                },
            },
        }).then(
            (ret) => {
                data.package.packageProducts = data.package.packageProducts.concat({ sku: newSku.sku });
                setSaving(false);
                setPackageModal(false);
                refetch();
            },
            (err) => {
                setSaving(false);
                console.error("Error updating skus assigned to package", err);
                alert(err.message);
            }
        );
    };

    // const runSaveFile = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     setSaving(true);
    //     convertBase64(skuFile!).then((ret) => {
    //         runAddFile({
    //             variables: {
    //                 file: ret,
    //                 packageId: parseInt(id),
    //             },
    //         }).then(
    //             (ret) => {
    //                 if (data && ret.data) {
    //                     setSaving(false);
    //                     setBulkSkuModel(false);
    //                 }
    //             },
    //             (err) => {
    //                 setSaving(false);
    //                 console.error("Error updating skus assigned to packaging", err);
    //                 alert(err.message);
    //             }
    //         );
    //     });
    // };

    const onDeleteSku = (product: IPackageProduct) => {
        if (!window.confirm("Are you sure you want to delete this package product?")) return;
        setSaving(true);
        runDelete({
            variables: {
                original: {
                    id: data.package.id,
                    barcode: data.package.barcode,
                    length: data.package.length,
                    width: data.package.width,
                    height: data.package.height,
                    weight: data.package.weight,
                    type: data.package.type,
                    active: data.package.active,
                    packageProductSkus: [product.sku],
                },
                modified: {
                    id: data.package.id,
                    barcode: data.package.barcode,
                    length: data.package.length,
                    width: data.package.width,
                    height: data.package.height,
                    weight: data.package.weight,
                    type: data.package.type,
                    active: data.package.active,
                    packageProductSkus: [],
                },
            },
        }).then(
            () => {
                //remove it from the local list
                const newList = data.package.packageProducts.filter((x) => x.sku !== product.sku);
                data.package.packageProducts = newList;
                setSaving(false);
            }, //failure
            (err: any) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting package product", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddSkuModal} style={{ marginRight: 10 }}>
                    Add product to package
                </Button>
                {/* <Button variant="white" onClick={onShowBulkAddSkus}>
                    Bulk add skus to package
                </Button> */}
            </p>
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                <Card.Header className="bg-primary text-white">Products assigned to package</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Sku</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.package.packageProducts.map((product) => {
                                return (
                                    <tr key={product.sku}>
                                        <td>{product.sku}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                variant="white"
                                                className="ms-4"
                                                style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                onClick={() => onDeleteSku(product)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={packageModal} onHide={onHidePackageModal}>
                <VForm initialValue={originalAdd} onSubmit={runSaveSku} saving={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Package Sku - Single</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4">
                                    <VLabel valueName="sku">Sku</VLabel>
                                    <VControl type="text" valueName="sku" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHidePackageModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
            {/* <Modal show={bulkSkuModel} onHide={onHideBulkAddSkus}>
                <Form onSubmit={runSaveFile}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Package Sku - Bulk</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBulkSkuImport">
                                    <Form.Label>Bulk Sku File</Form.Label>
                                    <Form.Control type="file" accept=".xls, .xlsx" onChange={onChangeImportFile} required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" disabled={saving || skuFile === null}>
                            Save
                        </Button>
                        <Button type="button" variant="white" onClick={onHideBulkAddSkus} className="me-auto">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </>
    );
};

export default PackageSummary;
