import { useHistory, useParams } from "react-router-dom";
import AddOrPrintBarcode from "../../../../../components/add_or_print_barcode/AddOrPrintBarcode";
import { IProductManagerHistory } from "../ProductManager";
import PrimaryHeader from "../../../../../components/PrimaryHeader";

interface IProductRouteParams {
    productId: string;
}

const Product = () => {
    const productRouteParams = useParams<IProductRouteParams>();
    const history = useHistory<IProductManagerHistory>();
    return (
        <>
            <AddOrPrintBarcode
                productId={productRouteParams.productId}
                primaryHeader={
                    <PrimaryHeader
                        Title="Product"
                        IncludeBackButton
                        IncludeHomeButton
                        BackButtonText="< Back"
                        CustomBackButtonFunction={() =>
                            history.location.state.backPath
                                ? history.push(history.location.state.backPath, {
                                      previouslySearchedProducts: history.location.state.previouslySearchedProducts,
                                  })
                                : void 0
                        }
                    />
                }
                includeSecondaryHeader
            />
        </>
    );
};

export default Product;
