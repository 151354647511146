import { GraphQLError, useMutation } from "@shane32/graphql";
import { createElement, useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ModelType from "../../../../enums/ModelType";
import SaleFulfillmentStatus from "../../../../enums/SaleFulfillmentStatus";
import StationType from "../../../../enums/StationTypes";
import StrictOmit from "../../../../types/StrictOmit";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useReload from "../../hooks/useReload";
import useScanner from "../../hooks/useScanner";
import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import {
    ILocation,
    IPackStation,
    IProduct,
    IStationQueryResult,
    IStationQueryVariables,
    locationBase,
    stationQuery,
    stationQueryBase,
} from "../../models/graphql/station/Station";
import { IMoveCartMutationResult, IMoveCartMutationVariables, moveCartMutation } from "../_shared/ReturnCart";
import PrinterType from "../../../../enums/PrinterType";
import {
    IReprintShippingLabelMutationResult,
    IReprintShippingLabelMutationVariables,
    reprintShippingLabelMutation,
} from "./print_label/PrintLabel";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import SelectionType from "../../../../enums/SelectionType";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";

const getPackStateQueryBase = `
mobile {
    getPackState(
      warehouseId: $warehouseId
      stationId: $stationId
      productId: $packStateProductId
      saleId: $saleId
    ) {
      isOrderPackable
      availablePackages {
        suggested
        package {
            id
            barcode
            length
            width
            height
            type
            packageProducts {
                locationProducts {
                    locationId
                }
            }
        }
      }
      requiredProducts {
        saleId
        product {
          id
          sku
        }
        quantityRemainingToPick
        pickedProductsAtStation {
          stockOnHand
          location {
            id
            name
            pickZoneId
          }
        }
        pickedProductsNotAtStation {
          stockOnHand
          location {
            id
            name
            pickZoneId
          }
        }
        productsToPick {
          stockOnHand
          location {
            id
            name
            pickZoneId
          }
        }
      }
    }
  }
`;

const saleFulfillmentsQueryBase = `
sale(id: $saleId) {
    fulfillments {
      id
      packageId
      status
      saleId
      lineItems {
        saleFulfillmentId
        saleLineItemId
        packageQuantity
        saleLineItem {
          saleId
          product {
            id
            sku
          }
        }
      }
    }
  }
`;

const moveProductToBinMutation = `
mutation ($fromLocationId: ID!, $toLocationId: ID!, $productId: ID!, $saleId: ID!, $quantity: Decimal!, $stationId: ID!, $warehouseId: ID!, $packStateProductId: ID) {
  inventory {
    moveProductToBin(fromLocationId: $fromLocationId, toLocationId: $toLocationId, productId: $productId, saleId: $saleId, quantity: $quantity)
  }
  query {
    ${stationQueryBase}
    ${getPackStateQueryBase}
    location(id: $fromLocationId) {
      ${locationBase}
    }
  }
}
`;

interface IMoveProductToBinMutationVariables extends IStationQueryVariables {
    fromLocationId: string;
    toLocationId: string;
    productId: string;
    saleId: string;
    quantity: number;
    warehouseId: string; //this is used exclusively for the pack state refetch
}

interface IMoveProductToBinMutationResult {
    query: IStationQueryResult & IPackStateResponse & ILocationQueryResult;
}

const getPackStateQuery = `
    query ($warehouseId: ID!, $stationId: ID!, $packStateProductId: ID, $saleId: ID!) {
      ${getPackStateQueryBase}
    }
`;

export interface ILocationStock {
    stockOnHand: number;
    location: {
        id: string;
        name: string;
        pickZoneId: string;
    };
}

interface IRequiredProduct {
    saleId: string;
    product: {
        id: string;
        sku: string;
    };
    quantityRemainingToPick: number;
    pickedProductsAtStation: ILocationStock[];
    pickedProductsNotAtStation: ILocationStock[];
    productsToPick: ILocationStock[];
}

interface IAvailablePackage {
    suggested: boolean;
    override: boolean;
    package: {
        id: string;
        barcode: string;
        length: number;
        width: number;
        height: number;
        weight: number;
        type: string;
        packageProducts: Array<{
            locationProducts: Array<{
                locationId: string;
            }>;
        }>;
    };
}

export interface IPackStateResponse {
    mobile: {
        getPackState: {
            isOrderPackable: boolean;
            requiredProducts: Array<IRequiredProduct>;
            availablePackages: Array<IAvailablePackage>;
        };
    };
}

interface IPackStateQueryVariables {
    warehouseId: string;
    stationId: string;
    packStateProductId?: string | null;
    saleId: string;
}

interface IPackStateRequiredProduct {
    productId: string;
    productSku: string;
    productSaleId: string;
    productLocationId: string;
    productLocationName: string;
    productLocationStockOnHand: number;
    productQuantityRemainingToPick: number;
}

//sale fulfillments
const saleFulfillmentsQuery = `
query ($saleId: ID!) {
  ${saleFulfillmentsQueryBase}
}
`;

export interface ISaleFulfillmentsQueryVariables {
    saleId: string;
}

export interface ISaleFulfillmentsLineItem {
    packageQuantity: number;
    saleFulfillmentId: string;
    saleLineItemId: string;
    saleLineItem: {
        saleId: string;
        product: {
            id: string;
            sku: string | null;
        };
    };
}

export interface IFulfillment {
    id: string;
    packageId: string | null;
    status: SaleFulfillmentStatus;
    saleId: string;
    lineItems: ISaleFulfillmentsLineItem[];
}

export interface ISaleFulfillmentsQueryResult {
    sale: {
        fulfillments: IFulfillment[];
    };
}

const locationQuery = `
query ($locationId: ID!) {
  location(id: $locationId) {
    ${locationBase}
  }
}
`;

export interface ILocationQueryResult {
    location: ILocation;
}

interface ILocationQueryVariables {
    locationId: string;
}

const bulkGenerateShippingLabelsMutation = `
mutation($stationId: ID!, $printerId: ID!) {
    api {
      shippingLabel {
        bulkGenerateShippingLabels(stationId: $stationId) {
          errors
          labels {
            print(printerId: $printerId)
          }
        }
      }
    }
    query {
        ${stationQueryBase}
      }
  }
`;

// Use for testing
// const bulkGenerateShippingLabelsMutation = `
// mutation ($stationId: ID!) {
//   api {
//     shippingLabel {
//       bulkGenerateShippingLabels(stationId: $stationId) {
//         errors
//         labels {
//           download
//         }
//       }
//     }
//   }
//   query {
//     ${stationQueryBase}
//   }
// }
// `;

interface IBulkGenerateShippingLabelsMutationVariables {
    stationId: string;
    printerId: string;
    locationId: string;
}

interface IBulkGenerateShippingLabelsMutationResult {
    api: {
        shippingLabel: {
            bulkGenerateShippingLabels: {
                errors: string[] | null;
            };
        };
    };
    query: IStationQueryResult;
}

const moveToSaleFulfillmentMutation = `
mutation ($locationId: ID!, $productId: ID!, $saleFulfillmentId: ID!, $quantity: Decimal!, $stationId: ID!, $saleId: ID!, $warehouseId: ID!, $packStateProductId: ID) {
  inventory {
    moveToSaleFulfillment(locationId: $locationId, productId: $productId, saleFulfillmentId: $saleFulfillmentId, quantity: $quantity)
  }
  query {
    ${stationQueryBase}
    ${saleFulfillmentsQueryBase}
    ${getPackStateQueryBase}
  }
}
`;

interface IMoveToSaleFulfillmentVariables extends IMoveToNewSaleFulfillmentVariables {
    saleFulfillmentId: string;
}

interface IMoveToSaleFulfillmentResult {
    query: IStationQueryResult & ISaleFulfillmentsQueryResult & IPackStateResponse;
}

const moveToNewSaleFulfillmentMutation = `
mutation ($locationId: ID!, $productId: ID!, $saleId: ID!, $quantity: Decimal!, $stationId: ID!, $warehouseId: ID!, $packStateProductId: ID) {
  inventory {
    moveToNewSaleFulfillment(locationId: $locationId, productId: $productId, saleId: $saleId, quantity: $quantity) {
      id
      saleId
      status
    }
  }
  query {
    ${stationQueryBase}
    ${saleFulfillmentsQueryBase}
    ${getPackStateQueryBase}
}
}
`;
interface IMoveToNewSaleFulfillmentVariables extends IStationQueryVariables, ISaleFulfillmentsQueryVariables {
    locationId: string;
    productId: string;
    quantity: number;
    warehouseId: string;
}

interface IMoveToNewSaleFulfillmentResponse extends IMoveToSaleFulfillmentResult {
    inventory: {
        moveToNewSaleFulfillment: {
            id: string;
            saleId: string;
            status: SaleFulfillmentStatus;
        };
    };
}

interface IAttachPackageToNewSaleFulfillmentResponse extends IMoveToSaleFulfillmentResult {
    inventory: {
        attachPackageToNewSaleFulfillment: {
            id: string;
            saleId: string;
            status: SaleFulfillmentStatus;
        };
    };
}

interface IAttachPackageToNewSaleFulfillmentVariables
    extends IStationQueryVariables,
        ISaleFulfillmentsQueryVariables,
        IPackStateQueryVariables {
    locationId: string;
    packageId: string;
    locationSelectionType: SelectionType;
    packageSelectionType: SelectionType;
}

const attachPackageToNewSaleFulfillmentMutation = `
mutation ($locationId: ID!, $saleId: ID!, $packageId: ID!, $packageSelectionType: SelectionType!, $locationSelectionType: SelectionType!, $stationId: ID!, $warehouseId: ID!, $packStateProductId: ID) {
  inventory {
    attachPackageToNewSaleFulfillment(locationId: $locationId, saleId: $saleId, packageId: $packageId, packageSelectionType: $packageSelectionType, locationSelectionType: $locationSelectionType) {
      id
      saleId
      status
    }
  }
  query {
    ${stationQueryBase}
    ${saleFulfillmentsQueryBase}
    ${getPackStateQueryBase}
  }
}
`;

interface IParams {
    stationId?: string;
    locationId?: string;
}

export interface ILastPrintedProperties {
    saleFulfillmentId: string;
    printerId: string;
}

export interface IPackURLSearchParams {
    activeProductId?: string | null;
    activeSaleFulfillmentId?: string | null;
    activeSaleId?: string | null;
    autoPrintShippingLabels?: boolean | null;
    lastPrintedProperties?: ILastPrintedProperties;
    scannedBoxDimensions?: IBoxDimensions;
}

/**
 * This function should ALWAYS be used to forge the new set of url search parameters.
 * Ensures that the key remains the same, while the user only needs to change the value.
 * @param urlSearchParams
 */
export const forgeURLSearchParams = (urlSearchParams: IPackURLSearchParams) =>
    new URLSearchParams({ urlsp: JSON.stringify(urlSearchParams) }).toString();

export const typedParse = <T,>(value: string | null): T => {
    try {
        return JSON.parse(value ?? "{}") as T;
    } catch {
        return {} as T;
    }
};

type TBoxFullProps =
    | { skipUserConfirmation: true; saleFulfillmentId: string }
    | { skipUserConfirmation: false; saleFulfillmentId: string; locations: ILocation[]; saleId: string };

interface IStationState {
    data: IStationQueryResult | null | undefined;
    loading: boolean;
}

interface IPackStateState {
    data: IPackStateResponse | null | undefined;
    loading: boolean;
}

interface ISaleFulfillmentsState {
    data: ISaleFulfillmentsQueryResult | null | undefined;
    loading: boolean;
}

interface ILocationState {
    data: ILocationQueryResult | null | undefined;
    loading: boolean;
}

interface IProductsAllowedToScan {
    id: string;
    saleId: string;
    locationId: string;
}

type IScannedProduct = StrictOmit<IProductsAllowedToScan, "locationId">;

interface IScannedProductResult {
    scannedProduct: IScannedProduct | undefined;
    matchesActiveProduct: boolean;
    isNewSaleId: boolean;
}

export interface IBoxDimensions {
    length: number;
    width: number;
    height: number;
}

/**
    ^:     Matches the start of the string.

    ZBOX-: Matches the literal string "ZBOX-" exactly.

    \d+:   Matches one or more digits.

    -:     Matches a hyphen.

    $:     Matches the end of the string.

    i:     Makes the regular expression case-insensitive.
 */
var boxBarcodeRegex = /^ZBOX-\d+-\d+-\d+$/i;

const ScanSku = () => {
    const params = useParams<IParams>();
    const location = useLocation();
    const history = useHistory();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IPackURLSearchParams>(rawURLSearchParams.get("urlsp"));

    const { settingsValue: defaultWarehouseId, loading: defaultWarehouseIdLoading } = useSettingsValue("defaultWarehouseId");

    const { selectModal, showSelectModal, open: isSelectModalOpen } = useSelectModal();
    const { reloadModal, showReloadModal } = useReload();
    const { confirmModal, showConfirmModal } = useConfirm();
    const { multiMatchSelectorModal, showMultiMatchSelectorModal } = useMultiMatchSelectorModal();

    const [runStationQuery] = useMutation<IStationQueryResult, IStationQueryVariables>(stationQuery);
    const [stationState, setStationState] = useState<IStationState>({ data: undefined, loading: false });

    const [runPackStateQuery] = useMutation<IPackStateResponse, IPackStateQueryVariables>(getPackStateQuery);
    const [packStateState, setPackStateState] = useState<IPackStateState>({ data: undefined, loading: false });

    const [runSaleFulfillmentsQuery] = useMutation<ISaleFulfillmentsQueryResult, ISaleFulfillmentsQueryVariables>(saleFulfillmentsQuery);
    const [saleFulfillmentsState, setSaleFulfillmentsState] = useState<ISaleFulfillmentsState>({ data: undefined, loading: false });

    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const [locationState, setLocationState] = useState<ILocationState>({ data: undefined, loading: false });

    const [runMoveCartMutation] = useMutation<IMoveCartMutationResult, IMoveCartMutationVariables>(moveCartMutation);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runMoveProductToBinMutation] = useMutation<IMoveProductToBinMutationResult, IMoveProductToBinMutationVariables>(
        moveProductToBinMutation
    );
    const [runMoveToSaleFulfillmentMutation] = useMutation<IMoveToSaleFulfillmentResult, IMoveToSaleFulfillmentVariables>(
        moveToSaleFulfillmentMutation
    );
    const [runMoveToNewSaleFulfillmentMutation] = useMutation<IMoveToNewSaleFulfillmentResponse, IMoveToNewSaleFulfillmentVariables>(
        moveToNewSaleFulfillmentMutation
    );
    const [runAttachPackageToNewSaleFulfillmentMutation] = useMutation<
        IAttachPackageToNewSaleFulfillmentResponse,
        IAttachPackageToNewSaleFulfillmentVariables
    >(attachPackageToNewSaleFulfillmentMutation);
    const [runBulkGenerateShippingLabelsMutation] = useMutation<
        IBulkGenerateShippingLabelsMutationResult,
        IBulkGenerateShippingLabelsMutationVariables
    >(bulkGenerateShippingLabelsMutation);
    const [runReprintShippingLabelMutation] = useMutation<IReprintShippingLabelMutationResult, IReprintShippingLabelMutationVariables>(
        reprintShippingLabelMutation
    );

    const [numberOfActiveProductSelected, setNumberOfActiveProductSelected] = useState(0);
    const [autoPack, setAutoPack] = useState(false);
    const [manualLoading, setManualLoading] = useState(false);

    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);

    //This query is used to fetch all of the data for the current station.
    //The main data sets that are important here are:
    //- carts and their associated locations
    //- stash pick zones and their associated locations
    useEffect(() => {
        (async () => {
            if (params.stationId) {
                let data: IStationQueryResult | null | undefined = undefined;
                try {
                    setStationState({ ...stationState, loading: true });
                    data = (await runStationQuery({ variables: { stationId: params.stationId } })).data;
                } catch (error: any) {
                    await showReloadModal("Station Query Error", (error as GraphQLError)?.message ?? "Unknown error");
                } finally {
                    setStationState({ data: data, loading: false });
                }
            }
        })();
    }, [params.stationId, params.locationId]); // eslint-disable-line react-hooks/exhaustive-deps

    //This query is used to fetch the data for the active item.
    //Whenever the active item changes, or an item is put into a package, a query should be made to get the new data for the active item.
    useEffect(() => {
        (async () => {
            if (urlSearchParams.activeSaleId && params.stationId && defaultWarehouseId) {
                let data: IPackStateResponse | null | undefined = undefined;
                try {
                    setPackStateState({ ...packStateState, loading: true });
                    data = (
                        await runPackStateQuery({
                            variables: {
                                packStateProductId: urlSearchParams.activeProductId,
                                saleId: urlSearchParams.activeSaleId,
                                stationId: params.stationId,
                                warehouseId: defaultWarehouseId,
                            },
                        })
                    ).data;
                } catch (error: any) {
                    await showReloadModal("Station Query Error", (error as GraphQLError)?.message ?? "Unknown error");
                } finally {
                    setPackStateState({ data: data, loading: false });
                }
            } else {
                //Set everything back to initial value if there is no product to check a pack state for
                setPackStateState({ data: undefined, loading: false });
            }
        })();
    }, [urlSearchParams.activeProductId, urlSearchParams.activeSaleId, params.stationId, defaultWarehouseId]); // eslint-disable-line react-hooks/exhaustive-deps

    //This query is used to check the current sales fulfillment status.
    //Whenever the sale id in the url changes, this should refetch data and refresh everything.
    useEffect(() => {
        (async () => {
            if (urlSearchParams.activeSaleId) {
                let data: ISaleFulfillmentsQueryResult | null | undefined = undefined;
                try {
                    setSaleFulfillmentsState({ ...saleFulfillmentsState, loading: true });
                    data = (await runSaleFulfillmentsQuery({ variables: { saleId: urlSearchParams.activeSaleId } })).data;
                } catch (error: any) {
                    await showReloadModal("Sale Fulfillments Query Error", (error as GraphQLError)?.message ?? "Unknown error");
                } finally {
                    setSaleFulfillmentsState({ data: data, loading: false });
                }
            } else {
                setSaleFulfillmentsState({ data: undefined, loading: false });
            }
        })();
    }, [urlSearchParams.activeSaleId]); // eslint-disable-line react-hooks/exhaustive-deps

    //This query is only called when the active location is NOT at the current station.
    //We need to fetch all of the products in the location, because these will be used to pick products for a sale that doesn't have everything at the station already.
    useEffect(() => {
        (async () => {
            if (!isActiveLocationAtActiveStation && params.locationId && params.locationId !== "0") {
                let data: ILocationQueryResult | null | undefined = undefined;
                try {
                    setLocationState({ ...locationState, loading: true });
                    data = (await runLocationQuery({ variables: { locationId: params.locationId } })).data;
                } catch (error: any) {
                    await showReloadModal("Location Query Error", (error as GraphQLError)?.message ?? "Unknown error");
                } finally {
                    setLocationState({ ...locationState, data: data, loading: false });
                }
            } else {
                //Set everything back to initial value if user is in a location at the station
                setLocationState({ data: undefined, loading: false });
            }
        })();
    }, [params.locationId]); // eslint-disable-line react-hooks/exhaustive-deps

    //Used to set the autoPrintLabels flag in the url. Will always be set to true if there is no value in the url.
    useEffect(() => {
        if (urlSearchParams.autoPrintShippingLabels === undefined || urlSearchParams.autoPrintShippingLabels === null) {
            history.replace(`${location.pathname}?${forgeURLSearchParams({ ...urlSearchParams, autoPrintShippingLabels: true })}`);
        }
    }, [history, urlSearchParams, location.pathname]);

    useEffect(() => {
        // We only want to show the box selector modal when any of these are true:
        // 1) When there is only one item remaining to pack for the sale.
        // 2) Nothing left to pick.
        (async () => {
            try {
                //ADD IN CHECK TO SEE IF THE FULFILLMENT ALREADY HAS A PACKAGEID
                const activeSaleFulfillment = saleFulfillmentsState.data?.sale.fulfillments.find(
                    (x) => x.id === urlSearchParams.activeSaleFulfillmentId
                );
                if (
                    saleFulfillmentsState.loading ||
                    packStateState.loading ||
                    !urlSearchParams.activeSaleId ||
                    !params.stationId ||
                    (packStateState.data?.mobile?.getPackState?.availablePackages ?? []).length < 1 ||
                    activeSaleFulfillment ||
                    !params.locationId ||
                    !defaultWarehouseId
                )
                    return;
                const isOrderPackable = packStateState.data?.mobile?.getPackState?.isOrderPackable;
                const quantityRemainingToPick = packStateState.data?.mobile?.getPackState?.requiredProducts?.reduce(
                    (val, acc) => val + acc.quantityRemainingToPick,
                    0
                );
                const pickedProductsNotAtStation = packStateState.data?.mobile?.getPackState?.requiredProducts?.reduce(
                    (val1, acc1) => val1 + acc1.pickedProductsNotAtStation.reduce((acc2, val2) => acc2 + val2.stockOnHand, 0),
                    0
                );
                const pickedProductsAtStation = packStateState.data?.mobile?.getPackState?.requiredProducts?.reduce(
                    (val1, acc1) => val1 + acc1.pickedProductsAtStation.reduce((acc2, val2) => acc2 + val2.stockOnHand, 0),
                    0
                );
                if (isOrderPackable && quantityRemainingToPick === 0 && pickedProductsNotAtStation === 0 && pickedProductsAtStation === 1) {
                    //pop up with modal to select a package for the sale
                    const availablePackages = packStateState.data?.mobile?.getPackState?.availablePackages ?? [];
                    const selectedAvailablePackage = await showSelectModal(
                        "Select Package",
                        availablePackages.map((x) => {
                            return {
                                description: `${x.suggested ? "[S] " : ""}${x.package.length}x${x.package.width}x${x.package.height} - ${
                                    x.package.type
                                }`,
                                id: x.package.id,
                            };
                        }),
                        availablePackages.find((x) => x.suggested)?.package?.id
                    );
                    if (!selectedAvailablePackage) return;

                    const selectedAvailablePackageLocationId = availablePackages
                        .find((x) => x.package.id === selectedAvailablePackage.id)
                        ?.package?.packageProducts.find((x) => x)
                        ?.locationProducts.find((x) => x)?.locationId;

                    if (!selectedAvailablePackageLocationId) return;

                    setManualLoading(true);

                    const data = (
                        await runAttachPackageToNewSaleFulfillmentMutation({
                            variables: {
                                locationId: selectedAvailablePackageLocationId,
                                locationSelectionType: SelectionType.None,
                                packageId: selectedAvailablePackage.id,
                                packageSelectionType: SelectionType.None,
                                saleId: urlSearchParams.activeSaleId,
                                stationId: params.stationId,
                                warehouseId: defaultWarehouseId,
                                packStateProductId: urlSearchParams.activeProductId,
                            },
                        })
                    ).data;

                    setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
                    setSaleFulfillmentsState({ ...saleFulfillmentsState, data: { ...saleFulfillmentsState.data, sale: data.query.sale } });
                    setPackStateState({ ...packStateState, data: { ...packStateState.data, mobile: data.query.mobile } });
                }
            } catch (error: any) {
                playNegativeTone();
                alert((error as GraphQLError)?.message ?? error ?? "Unknown error");
            } finally {
                setManualLoading(false);
            }
        })();
    }, [saleFulfillmentsState.loading, packStateState.loading, urlSearchParams.activeSaleId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onScan = async (scannerValue: string) => {
        const runProductScan = async (scannedProductResult: IScannedProductResult) => {
            if (autoPack) throw new Error("Auto-stow and auto-pack are disabled. Scan has ended.");
            else {
                //Matches same product and same sale id
                if (!scannedProductResult.isNewSaleId && scannedProductResult.matchesActiveProduct) {
                    increment();
                    playPositiveTone();
                }
                //Does not match same product and has same sale id
                else if (!scannedProductResult.isNewSaleId && !scannedProductResult.matchesActiveProduct) {
                    if (numberOfActiveProductSelected > 0)
                        throw new Error(
                            "You already have a product in hand! Please only scan the same sku, or a location if you're able to stow a product!"
                        );
                    increment();
                    playPositiveTone();
                }
                //New sale id and
                else if (scannedProductResult.isNewSaleId && !scannedProductResult.matchesActiveProduct) {
                    if (urlSearchParams.activeSaleFulfillmentId)
                        throw new Error(
                            "The product you scanned does not belong in the active sale fulfillment. Please scan an item for the active sale fulfillment."
                        );
                    if (numberOfActiveProductSelected > 0)
                        throw new Error(
                            "You already have a product in hand! Please only scan the same sku, or a location if you're able to stow a product!"
                        );
                    increment();
                    playPositiveTone();
                } else {
                    throw new Error("Could not determine what to do with scanned product.");
                }
                const newUrl = `${location.pathname}?${forgeURLSearchParams({
                    ...urlSearchParams,
                    activeProductId: scannedProductResult.scannedProduct?.id,
                    activeSaleId: scannedProductResult.scannedProduct?.saleId,
                })}`;
                if (newUrl !== `${location.pathname}${location.search}`) {
                    playPositiveTone();
                    history.replace(newUrl);
                    setNumberOfActiveProductSelected(1);
                }
            }
        };

        if (isLoading || isSelectModalOpen) return;
        try {
            setManualLoading(true);

            if (boxBarcodeRegex.test(scannerValue)) {
                const boxDimensions = scannerValue.split("-").slice(1);
                history.replace(
                    `${location.pathname}?${forgeURLSearchParams({
                        ...urlSearchParams,
                        scannedBoxDimensions: {
                            length: parseInt(boxDimensions[0]),
                            width: parseInt(boxDimensions[1]),
                            height: parseInt(boxDimensions[2]),
                        },
                    })}`
                );
                playPositiveTone();
                return;
            }

            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);

            //Check if there are matches for products and locations from the scanned value.
            if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                await showConfirmModal(
                    formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                    "Multiple Matches In Database",
                    { confirm: "Confirm" },
                    true
                );
            } else if (barcodeProducts.length > 0) {
                let barcodesProductId = barcodeProducts[0].id;
                if (barcodeProducts.length > 1) {
                    const selected = await showMultiMatchSelectorModal(
                        "Select Correct Product SKU",
                        barcodeProducts.map((x) => x.id),
                        MultiMatchDataType.Product
                    );
                    if (!selected) return;
                    barcodesProductId = selected.id;
                }
                //Only match for a product. Get product object and continue like normal.
                //Verify that the scanned product matches something we want.
                //*Since the pack process doesn't start with a product pre-selected, we need to find the first matching product.
                //1) Active product id matches barcode product id
                //2) Barcode product id matches product in current location
                let scannedProductResult: IScannedProductResult | undefined = undefined;
                //If the scanned product matches the active product
                if (
                    activeProduct &&
                    activeProduct.productId === barcodesProductId &&
                    activeProduct.saleId === urlSearchParams.activeSaleId
                ) {
                    if (!activeProduct.saleId || activeProduct.saleId === "0")
                        throw new Error("The active product does not have a sale id...");
                    scannedProductResult = {
                        isNewSaleId: false,
                        matchesActiveProduct: true,
                        scannedProduct: { id: activeProduct.productId, saleId: activeProduct.saleId },
                    };
                } else {
                    //If the scanned product matches something else in the active location and for the same sale.
                    let allowedProductsToScan: IProductsAllowedToScan[] = [
                        ...pickedProductsAtStationForActiveSale,
                        ...pickedProductsNotAtStationForActiveSale,
                        ...productsRemainingToPickForActiveSale,
                    ]
                        .map((p) => {
                            return {
                                locationId: p.productLocationId,
                                saleId: p.productSaleId,
                                id: p.productId,
                            };
                        })
                        .filter((p) => p.locationId === activeLocation?.id);
                    let scannedProduct = allowedProductsToScan.find(
                        (x) => x.id === barcodesProductId && x.saleId === urlSearchParams.activeSaleId
                    );
                    if (scannedProduct) {
                        if (!scannedProduct.saleId) throw new Error("The scanned product does not have a sale id...");
                        scannedProductResult = {
                            isNewSaleId: false,
                            matchesActiveProduct: false,
                            scannedProduct: { id: scannedProduct.id, saleId: scannedProduct.saleId },
                        };
                    } else {
                        //This is used when there isn't already an active product, or there is no active sale id.
                        allowedProductsToScan = isActiveLocationAtActiveStation
                            ? (activeLocation?.products ?? []).map((p) => {
                                  return {
                                      locationId: "0", //"locationId" is not used at this point.
                                      saleId: p.saleId,
                                      id: p.productId,
                                  } as IProductsAllowedToScan;
                              })
                            : allowedProductsToScan;
                        scannedProduct = allowedProductsToScan.find((x) => x.id === barcodesProductId);
                        if (scannedProduct) {
                            if (!scannedProduct.saleId) throw new Error("The scanned product does not have a sale id...");
                            scannedProductResult = {
                                isNewSaleId: true,
                                matchesActiveProduct: false,
                                scannedProduct: { id: scannedProduct.id, saleId: scannedProduct.saleId },
                            };
                        }
                    }
                }
                if (scannedProductResult) await runProductScan(scannedProductResult);
                else playNegativeTone();
            } else if (barcodeLocations.length > 0) {
                if (barcodeLocations.length === 1) {
                    //When scanning a location during the pack process, that could mean the user wants to:
                    // - Switch locations.
                    // - NOT DONE YET: Move a product from one location to a different location.
                    //If there is an active sale fulfillment, only the locations that contain products for the active sale id are allowed to be switched to.
                    if (urlSearchParams.activeSaleFulfillmentId) {
                        const locationsWithProductsRequiredForSaleIds = Array.from(
                            new Set(
                                [
                                    ...pickedProductsAtStationForActiveSale,
                                    ...pickedProductsNotAtStationForActiveSale,
                                    ...productsRemainingToPickForActiveSale,
                                ].map((l) => l.productLocationId)
                            )
                        );
                        const scannedLocationId = locationsWithProductsRequiredForSaleIds.find((l) => l === barcodeLocations[0].id);
                        if (!scannedLocationId) return playNegativeTone();
                        if (numberOfActiveProductSelected > 0) {
                            throw new Error(
                                "Moving products to a new location, while there is an active sale fulfillment, is not allowed."
                            );
                        } else {
                            //Switch locations
                            history.replace(
                                `/mobile/pack/${params.stationId}/${scannedLocationId}?${forgeURLSearchParams({ ...urlSearchParams })}`
                            );
                        }
                    } else {
                        //If there is no active sale fulfillment, let the user switch to any location at the station (including locations needed for picking and moving products for an active sale)
                        let locationsWithProductsRequiredForSaleIds = Array.from(
                            new Set(
                                [
                                    ...pickedProductsAtStationForActiveSale,
                                    ...pickedProductsNotAtStationForActiveSale,
                                    ...productsRemainingToPickForActiveSale,
                                ].map((l) => l.productLocationId)
                            )
                        ).concat(allLocationsAtStation.map((l) => l.id));
                        /** The location ids that products are not allowed to be transfered to. */
                        const restrictedLocationIds = Array.from(
                            new Set(
                                [...pickedProductsNotAtStationForActiveSale, ...productsRemainingToPickForActiveSale].map(
                                    (l) => l.productLocationId
                                )
                            )
                        );
                        if (activeLocation?.id) locationsWithProductsRequiredForSaleIds.push(activeLocation?.id);
                        const scannedLocationId = locationsWithProductsRequiredForSaleIds.find((l) => l === barcodeLocations[0].id);
                        if (!scannedLocationId) return playNegativeTone();
                        if (scannedLocationId === params.locationId) throw new Error("Error: Scanned location matches active location.");
                        if (numberOfActiveProductSelected > 0) {
                            if (activeProduct) {
                                if (!restrictedLocationIds.some((l) => l === scannedLocationId)) {
                                    await moveActiveProductToDifferentLocation(scannedLocationId, numberOfActiveProductSelected);
                                } else {
                                    throw new Error("Transferring a product into a restricted location is not allowed.");
                                }
                            }
                        } else {
                            //Switch locations
                            playPositiveTone();
                            history.replace(
                                `/mobile/pack/${params.stationId}/${scannedLocationId}?${forgeURLSearchParams({ ...urlSearchParams })}`
                            );
                        }
                    }
                } else {
                    await showConfirmModal(
                        formatMultipleMatchesString(barcodeLocations, scannerValue),
                        "Multiple Matches In Database",
                        { confirm: "Confirm" },
                        true
                    );
                }
            } else {
                playNegativeTone();
            }
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    if (!params.stationId || isNaN(+params.stationId)) return <Redirect to="/mobile/pack/scanstation" />;
    if (!params.locationId || isNaN(+params.locationId)) return <Redirect to={`/mobile/pack/${params.stationId}/scanlocation`} />;

    const isLoading = [
        stationState.loading,
        locationState.loading,
        packStateState.loading,
        saleFulfillmentsState.loading,
        manualLoading,
        defaultWarehouseIdLoading,
    ].some((x) => x === true);

    //Validate the items fetched from the sale fulfillments query. This is where the check for any active sale fulfillment occurs.
    //If an "InProgress" sale fulfillment for the active sale is found, the package gets set in the url, so any items from that order get placed in that package.
    const inProgressFulfillments = (saleFulfillmentsState?.data?.sale?.fulfillments ?? []).filter(
        (fulfillment) => fulfillment.status === SaleFulfillmentStatus.InProgress && fulfillment.saleId === urlSearchParams.activeSaleId
    );
    let activeSaleFulfillment: IFulfillment | undefined = undefined;
    if (inProgressFulfillments.length === 1) {
        activeSaleFulfillment = {
            ...inProgressFulfillments[0],
            lineItems: inProgressFulfillments[0].lineItems.filter((x) => x.packageQuantity > 0),
        };
    }
    if (activeSaleFulfillment && activeSaleFulfillment.id !== urlSearchParams.activeSaleFulfillmentId) {
        //Reaching this block of code means that there is a single active fulfillment for the sale, and we need to set the sale fulfillment id in the URL.
        return (
            <Redirect
                to={`${location.pathname}?${forgeURLSearchParams({
                    ...urlSearchParams,
                    activeSaleFulfillmentId: inProgressFulfillments[0].id,
                } as IPackURLSearchParams)}`}
            />
        );
    } else if (!activeSaleFulfillment && inProgressFulfillments.length > 1) {
        alert("Multiple in-progress sale fulfillments found for this order!");
    }

    const getAllLocationsAtStation = (packStation: IPackStation | null | undefined) => {
        const cartLocations = (packStation?.carts ?? []).filter((pickZone) => pickZone.isCart).flatMap((cart) => cart.locations);
        const stashLocations = packStation?.stationSet?.stashPickZone?.locations ?? [];
        return cartLocations.concat(stashLocations);
    };

    //Using the data from the station query, group all necessary data together.
    //At a station, we want to get every location and group them all together, so we know what locations are at the station.
    //The locations at a station should change when a cart leaves the active station.
    const notAtStationLocation = locationState.data?.location;
    const allLocationsAtStation = getAllLocationsAtStation(stationState.data?.station);
    const isActiveLocationAtActiveStation =
        params.locationId && allLocationsAtStation.some((l) => l.id === params.locationId) ? true : false;
    const activeLocation1 = isActiveLocationAtActiveStation
        ? allLocationsAtStation.find((l) => params.locationId && l.id === params.locationId)
        : notAtStationLocation;
    let activeProduct = (activeLocation1?.products ?? []).find(
        (p) => p.productId === urlSearchParams.activeProductId && p.saleId === urlSearchParams.activeSaleId
    );

    //When allowing a user to bulk print shipping labels, a few things need to be met:
    //  1) All of the products at the current station need to be of the same product id.
    //  2) All of the sales must only contain a single product that needs to be shipped for each sale. (this check will happen on Hive server through the graph)
    const allProductsAtStation = allLocationsAtStation.flatMap((location) => location.products);
    const areAllProductsAtStationTheSame =
        allProductsAtStation.length > 1 && allProductsAtStation.every((p) => p.productId === allProductsAtStation[0].productId);

    //If the active location is not at the active station, we should be picking a product or moving an already picked product.
    //An item that needs to be picked or moved will have been clicked on before hand, setting the necessary info into the url search params.
    //Using the url search params info (which represents the product that we need to pick or move), we need to cross reference that info with products in the active location.
    //If we find a match, we are able to set the active product, along with the proper amount that is needed to pick or move.
    if (!isActiveLocationAtActiveStation) {
        //There are two possible states that a product could be in when it is in a location NOT at a station:
        //  1) The product still needs to be picked.
        //  2) The product has been picked and needs to be moved to a location at the active station.
        //Task: Figure out what state the product is in, then set the amount that needs to be picked or moved into the "StockOnHand" property.
        const requiredProducts = [...(packStateState.data?.mobile?.getPackState?.requiredProducts ?? [])];
        for (let product of activeLocation1?.products ?? []) {
            if (product.productId === urlSearchParams.activeProductId) {
                //A matching location and product have been found at this point.
                //Now, find the state of the product and set the "StockOnHand" property.
                const requiredProduct = requiredProducts.find(
                    (reqProduct) =>
                        reqProduct.product.id === urlSearchParams.activeProductId && reqProduct.saleId === urlSearchParams.activeSaleId
                );
                const pickedProductNotAtStation = requiredProduct?.pickedProductsNotAtStation?.find(
                    (ls) => params.locationId === ls.location.id
                );
                const productRemainingToBePicked = requiredProduct?.productsToPick?.find((ls) => params.locationId === ls.location.id);

                if (pickedProductNotAtStation && productRemainingToBePicked) {
                    alert(
                        "Found products that need to be picked AND need to be moved in the same location! This shouldn't ever happen! Contact the dev team!"
                    );
                    break;
                }

                activeProduct = {
                    ...product,
                    saleId: urlSearchParams.activeSaleId ?? "",
                    stockOnHand: pickedProductNotAtStation
                        ? pickedProductNotAtStation.stockOnHand
                        : requiredProduct?.quantityRemainingToPick ?? 0,
                };
                break;
            }
        }
        //If the stock on hand for a product in a location NOT at a station is zero, the active product is no longer valid, so set it to undefined.
        if ((activeProduct?.stockOnHand ?? 0) < 1) activeProduct = undefined;
    }

    //This does NOT include the active product, if any. The active product is filtered out below.
    const pickedProductsAtStationForActiveSale: IPackStateRequiredProduct[] = [
        ...(packStateState.data?.mobile?.getPackState?.requiredProducts ?? []),
    ]
        .map((reqProduct) => {
            return reqProduct.pickedProductsAtStation
                .filter((product) => product.location.id !== params.locationId || reqProduct.product.id !== urlSearchParams.activeProductId)
                .map((product) => {
                    return {
                        productId: reqProduct.product.id,
                        productSku: reqProduct.product.sku,
                        productSaleId: reqProduct.saleId,
                        productLocationId: product.location.id,
                        productLocationName: product.location.name,
                        productLocationStockOnHand: product.stockOnHand,
                        productQuantityRemainingToPick: 0,
                    } as IPackStateRequiredProduct;
                });
        })
        .flatMap((x) => x);

    const pickedProductsNotAtStationForActiveSale: IPackStateRequiredProduct[] = [
        ...(packStateState.data?.mobile?.getPackState?.requiredProducts ?? []),
    ]
        .map((reqProduct) => {
            return reqProduct.pickedProductsNotAtStation
                .filter((product) => product.location.id !== params.locationId || reqProduct.product.id !== urlSearchParams.activeProductId)
                .map((product) => {
                    return {
                        productId: reqProduct.product.id,
                        productSku: reqProduct.product.sku,
                        productSaleId: reqProduct.saleId,
                        productLocationId: product.location.id,
                        productLocationName: product.location.name,
                        productLocationStockOnHand: product.stockOnHand,
                        productQuantityRemainingToPick: 0,
                    } as IPackStateRequiredProduct;
                });
        })
        .flatMap((x) => x);

    const productsRemainingToPickForActiveSale: IPackStateRequiredProduct[] = [
        ...(packStateState.data?.mobile?.getPackState?.requiredProducts ?? []),
    ]
        .map((reqProduct) => {
            return reqProduct.productsToPick
                .filter((product) => product.location.id !== params.locationId || reqProduct.product.id !== urlSearchParams.activeProductId)
                .map((product) => {
                    return {
                        productId: reqProduct.product.id,
                        productSku: reqProduct.product.sku,
                        productSaleId: reqProduct.saleId,
                        productLocationId: product.location.id,
                        productLocationName: product.location.name,
                        productLocationStockOnHand: product.stockOnHand,
                        productQuantityRemainingToPick: reqProduct.quantityRemainingToPick,
                    } as IPackStateRequiredProduct;
                });
        })
        .flatMap((x) => x);

    //If the active location is not at the active station, when setting the products, do not show any of the products besides the one that was selected from an active sale
    let activeLocation: ILocation | undefined = !activeLocation1
        ? undefined
        : {
              ...activeLocation1,
              products: !isActiveLocationAtActiveStation
                  ? []
                  : [...activeLocation1.products].filter((p) => p.saleId !== urlSearchParams.activeSaleId),
          };

    /**
     * Contains the locations at a station that have more than zero stock on hand.
     * If there is an active location, this array will NOT contain that location.
     * */
    const locationsAtStationWithStockOnHand = allLocationsAtStation
        .filter((l) => l.products.reduce((acc, v) => acc + v.stockOnHand, 0) > 0)
        .filter((l) => params.locationId && l.id !== params.locationId);

    /**
     * When a user is packing something, this should get called on any action that takes the user away from the pack flow.
     * This will display a modal that makes sure the user is intentially changing routes or doing something else with the active package.
     * @param path
     */
    const abandonBoxCheck = async (path: string) => {
        let answer = true;
        if ((activeSaleFulfillment?.lineItems?.length ?? 0) > 0)
            answer = await showConfirmModal(
                "You are currently working with an open box. Are you sure you want to leave the page and abandon the box?",
                "Are You Sure?",
                { confirm: "Abandon Box", cancel: "Continue Packing" }
            );
        if (answer) history.push(path);
    };

    const toggleAutoPack = () => {
        if (numberOfActiveProductSelected > 0) return alert("There are still items in your hand! Please put them back before continuing!");
        else setAutoPack(!autoPack);
    };

    const toggleAutoPrintLabels = () => {
        history.replace(
            `${location.pathname}?${forgeURLSearchParams({
                ...urlSearchParams,
                autoPrintShippingLabels: !urlSearchParams.autoPrintShippingLabels,
            })}`
        );
    };

    //This function is used for when clicking on a product that is in the active location.
    //Before setting the new product, we need to check a few things first:
    //  - Verify that the active products amount selected is at zero.
    //  - Verify that there is no active sale fulfillment
    //      - *****If there is an active sale fulfillment, make sure the product clicked belongs to the same sale as the fulfillment
    const onClickItemsInActiveLocationHandler = (product: IProduct) => {
        if (numberOfActiveProductSelected !== 0) return alert("There are items in your hand! Please put them back!");
        if (activeSaleFulfillment && activeSaleFulfillment.saleId !== product.saleId)
            return alert("The product you have selected is not associated to the active sale fulfillment.");
        history.replace(
            `${location.pathname}?${forgeURLSearchParams({
                activeSaleFulfillmentId: urlSearchParams?.activeSaleFulfillmentId,
                activeProductId: product.productId,
                activeSaleId: product.saleId ?? undefined,
                autoPrintShippingLabels: urlSearchParams?.autoPrintShippingLabels,
            })}`
        );
    };

    const onClickPickedProductAtStationTableRowHandler = (product: IPackStateRequiredProduct) => {
        if (numberOfActiveProductSelected !== 0) return alert("There are items in your hand! Please put them back!");
        history.replace(
            `/mobile/pack/${params.stationId}/${product.productLocationId}?${forgeURLSearchParams({
                activeSaleFulfillmentId: urlSearchParams?.activeSaleFulfillmentId,
                activeProductId: product.productId,
                activeSaleId: product.productSaleId,
                autoPrintShippingLabels: urlSearchParams?.autoPrintShippingLabels,
            })}`
        );
    };

    const onClickPickedProductNotAtStationTableRowHandler = (product: IPackStateRequiredProduct) => {
        if (numberOfActiveProductSelected !== 0) return alert("There are items in your hand! Please put them back!");
        history.replace(
            `/mobile/pack/${params.stationId}/${product.productLocationId}?${forgeURLSearchParams({
                activeSaleFulfillmentId: urlSearchParams?.activeSaleFulfillmentId,
                activeProductId: product.productId,
                activeSaleId: product.productSaleId,
                autoPrintShippingLabels: urlSearchParams?.autoPrintShippingLabels,
            })}`
        );
    };

    /**
     * This function is used to allow a product, in a location not at the active station, to be picked.
     * In order for the product to be picked, a product at the active station must have been selected before, so a sale id is able to be set in the url.
     * @param product
     */
    const onClickProductRemainingToPickTableRowHandler = (product: IPackStateRequiredProduct) => {
        if (numberOfActiveProductSelected !== 0) return alert("There are items in your hand! Please put them back!");
        //if (!activeProduct || !urlSearchParams.activeSaleId) return alert("There is no active product or active sale id. The location for the product you clicked on is not currently at the active station.");
        history.replace(
            `/mobile/pack/${params.stationId}/${product.productLocationId}?${forgeURLSearchParams({
                activeSaleFulfillmentId: urlSearchParams?.activeSaleFulfillmentId,
                activeProductId: product.productId,
                activeSaleId: product.productSaleId,
                autoPrintShippingLabels: urlSearchParams?.autoPrintShippingLabels,
            })}`
        );
    };

    /**
     * Used for when selecting a location at a station.
     * */
    const onClickLocationAtStationHandler = (locationId: string) => {
        if (numberOfActiveProductSelected !== 0) return alert("There are items in your hand! Please put them back!");
        if (activeSaleFulfillment || urlSearchParams.activeSaleFulfillmentId)
            return alert(
                "There is currently an active sale fulfillment. You are not allowed to switch locations while there is an active sale fulfillment."
            );
        history.replace(`/mobile/pack/${params.stationId}/${locationId}`);
    };

    let maxStockOnHandForActiveProduct = activeProduct?.stockOnHand ?? 0;

    const increment = () => {
        let value = numberOfActiveProductSelected + 1;
        if (numberOfActiveProductSelected >= maxStockOnHandForActiveProduct) return;
        else setNumberOfActiveProductSelected(value);
    };

    const decrement = () => {
        let value = numberOfActiveProductSelected - 1;
        if (numberOfActiveProductSelected < 1) return;
        else setNumberOfActiveProductSelected(value);
    };

    const returnCartsToOutputStation = async (stationData: IPackStation) => {
        //there should only be one output station per station set
        //maybe in the future have a way to handle multiple output stations.
        const outputStation = stationData.stationSet.stations.find((x) => x.type === StationType.Output);
        if (outputStation) {
            for (let pickZone of stationData.carts) {
                if (!pickZone.isCart) continue;
                //check if all of the products on locations on a cart have no more sale ids attached to them.
                //if the above is true, move the cart to the output area.
                if (
                    pickZone.locations.every(
                        (location) =>
                            location.products.reduce((accumulator, product) => {
                                const value = product.saleId ? +product.saleId : 0;
                                return accumulator + value;
                            }, 0) < 1
                    )
                ) {
                    const pickZoneId = pickZone.id;
                    const outputStationId = outputStation.id;
                    await runMoveCartMutation({ variables: { id: pickZoneId, stationId: outputStationId } });
                }
            }
        }
    };

    /**Checks to see if there are other products for the active sale that have not yet been put into a box.
     * If there are other product for the sale, this shows a confirmation dialogue asking the user whether or not they want to:
     * - "Complete Box" = They want to continue, which would mean that they are going to close out the active sale fulfillment.
     * - "Continue Packing" = They want to cancel, which would mean they want to pack more products into the active sale fulfillment.
     * *** Return value meanings:
     * *** true  = User wants to complete the active box.
     * *** false = User wants to continue packing.*/
    const checkIfUserWantsToCompleteBox = async (locations: ILocation[], saleId: string): Promise<boolean> => {
        //Checks to see how many products are left for the sale. "< 1" means there are no more products left to pack for the sale.
        const allProductsAtStationMatchingActiveSaleId = locations.flatMap((l) => l.products).filter((p) => p.saleId === saleId);
        if (allProductsAtStationMatchingActiveSaleId.length < 1) return true;
        //Ask the user if they want to finish the active fulfillment, or if they want to pack more for the active sale.
        let message =
            allProductsAtStationMatchingActiveSaleId.length > 1
                ? `There are still ${allProductsAtStationMatchingActiveSaleId.reduce(
                      (acc, v) => acc + v.stockOnHand,
                      0
                  )} products left to pack for this order.`
                : `There is still 1 product left to pack for this order.`;
        message += " Are you sure you want to complete this box?";
        return await showConfirmModal(message, "Are You Sure?", { confirm: "Complete Box", cancel: "Continue Packing" });
    };

    const boxFull = async (props: TBoxFullProps) => {
        if (!props.skipUserConfirmation) if (!(await checkIfUserWantsToCompleteBox(props.locations, props.saleId))) return;
        history.push(
            `/mobile/pack/${params.stationId}/${params.locationId}/printlabel?${forgeURLSearchParams({
                ...urlSearchParams,
                activeSaleFulfillmentId: props.saleFulfillmentId,
            })}`
        );
    };

    const removeProductFromSaleFulfillment = async (
        saleFulfillmentId: string,
        productId: string,
        saleId: string,
        productSku: string | undefined | null
    ) => {
        try {
            if (isLoading) return;
            if (!activeSaleFulfillment) return;
            if (!defaultWarehouseId) return;
            if (!params.stationId || params.stationId === "0")
                throw new Error("Route params does not contain a station id, or the station id is '0'.");

            setManualLoading(true);

            const answer = await showConfirmModal(
                `Are you sure you want to remove 1 ${productSku ?? ""} from the active package?`,
                `Remove From Package`
            );
            if (!answer) return;

            if (allLocationsAtStation.length < 1)
                throw new Error(
                    "There are no locations at this station to stow a product. You are only allowed to move products to locations within the active station."
                );

            const location =
                allLocationsAtStation.length > 1
                    ? await showSelectModal(
                          "Select a Location",
                          allLocationsAtStation.map((l) => ({ description: l.name, id: l.id } as ISelect))
                      )
                    : ({ description: allLocationsAtStation[0].name, id: allLocationsAtStation[0].id } as ISelect);
            if (!location) return;

            const data = await (
                await runMoveToSaleFulfillmentMutation({
                    variables: {
                        locationId: location.id,
                        productId: productId,
                        quantity: -1,
                        saleFulfillmentId: saleFulfillmentId,
                        saleId: saleId,
                        stationId: params.stationId,
                        warehouseId: defaultWarehouseId,
                    },
                })
            ).data;
            setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
            setSaleFulfillmentsState({ ...saleFulfillmentsState, data: { ...saleFulfillmentsState.data, sale: data.query.sale } });
            setPackStateState({ ...packStateState, data: { ...packStateState.data, mobile: data.query.mobile } });
            await returnCartsToOutputStation(data.query.station);
            playPositiveTone();
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? error ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const moveActiveProductToDifferentLocation = async (toLocationId?: string, quantityToMove?: number) => {
        try {
            if (isLoading) return;
            if (!defaultWarehouseId) return;
            if (!activeProduct) throw new Error("There is no active product to move.");
            if (!activeProduct.saleId) throw new Error("The active product does not have a sale id associated to it.");
            quantityToMove ??= autoPack ? 1 : numberOfActiveProductSelected;
            if (quantityToMove < 1)
                throw new Error(
                    "There must be at least one product to move to a different location. Please select how many products you want to move."
                );
            if (allLocationsAtStation.length < 1)
                throw new Error(
                    "There are no locations at this station to stow a product. You are only allowed to move products to locations within the active station."
                );
            toLocationId ??= (
                await showSelectModal(
                    "Select a Location",
                    allLocationsAtStation.map((l) => ({ description: l.name, id: l.id } as ISelect))
                )
            )?.id;
            if (!toLocationId) throw new Error("A location to move a product to was not provided.");
            if (!params.locationId || params.locationId === "0")
                throw new Error("Route params does not contain a location id, or the location id is '0'.");
            if (!params.stationId || params.stationId === "0")
                throw new Error("Route params does not contain a station id, or the station id is '0'.");
            if (params.locationId === toLocationId) throw new Error("Moving product to the same location is not allowed.");
            setManualLoading(true);
            const data = (
                await runMoveProductToBinMutation({
                    variables: {
                        fromLocationId: params.locationId,
                        productId: activeProduct.productId,
                        quantity: quantityToMove,
                        saleId: activeProduct.saleId,
                        stationId: params.stationId,
                        toLocationId: toLocationId,
                        warehouseId: defaultWarehouseId,
                    },
                })
            ).data;
            setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
            setPackStateState({ ...packStateState, data: { ...packStateState.data, mobile: data.query.mobile } });
            setLocationState({ ...locationState, data: { ...locationState.data, location: data.query.location } });
            setNumberOfActiveProductSelected(0);
            await returnCartsToOutputStation(data.query.station);
            playPositiveTone();
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? error ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const moveActiveProductToSaleFulfillment = async (quantityToMove?: number, recommendBoxOverride?: boolean) => {
        try {
            if (isLoading) return;
            if (!defaultWarehouseId) return;
            if (!activeProduct) throw new Error("There is no active product to move.");
            if (!activeProduct.saleId) throw new Error("The active product does not have a sale id associated to it.");
            quantityToMove ??= autoPack ? 1 : numberOfActiveProductSelected;
            if (quantityToMove < 1 && recommendBoxOverride === false)
                throw new Error(
                    "There must be at least one product to move to a sale fulfillment. Please select how many products you want to move."
                );
            if (!params.locationId || params.locationId === "0")
                throw new Error("Route params does not contain a location id, or the location id is '0'.");
            if (!params.stationId || params.stationId === "0")
                throw new Error("Route params does not contain a station id, or the station id is '0'.");
            setManualLoading(true);
            const activeSaleId = activeProduct.saleId;
            const activeSaleFulfillmentId = activeSaleFulfillment?.id;
            const variables = {
                locationId: params.locationId,
                productId: activeProduct.productId,
                quantity: quantityToMove,
                saleId: activeSaleId,
                stationId: params.stationId,
                warehouseId: defaultWarehouseId,
            };
            if (activeSaleFulfillmentId) {
                const data = await (
                    await runMoveToSaleFulfillmentMutation({ variables: { ...variables, saleFulfillmentId: activeSaleFulfillmentId } })
                ).data;
                if (!data)
                    throw new Error(
                        "Move product to sale fulfillment mutation response is null. Could not move product to requested sale fulfillment."
                    );
                setNumberOfActiveProductSelected(0);
                setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
                setSaleFulfillmentsState({ ...saleFulfillmentsState, data: { ...saleFulfillmentsState.data, sale: data.query.sale } });
                setPackStateState({ ...packStateState, data: { ...packStateState.data, mobile: data.query.mobile } });
                await returnCartsToOutputStation(data.query.station);
                playPositiveTone();

                const allLocationsAtStation = getAllLocationsAtStation(data.query.station);
                const allProductsAtStationMatchingActiveSaleId = allLocationsAtStation
                    .flatMap((l) => l.products)
                    .filter((p) => p.saleId === activeSaleId);
                //const saleFulfillment = data.query.sale.fulfillments.find((fulfillment) => fulfillment.id === activeSaleFulfillmentId);

                //If there is more than one product in the active sale fulfillment, prevent auto redirects to the "Confirm Weight" component.
                //if (saleFulfillment && saleFulfillment.lineItems.reduce((acc, v) => acc + v.packageQuantity, 0) > 1) return;
                if (allProductsAtStationMatchingActiveSaleId.length < 1) {
                    await boxFull({ skipUserConfirmation: true, saleFulfillmentId: activeSaleFulfillmentId });
                }
            } else {
                const data = (await runMoveToNewSaleFulfillmentMutation({ variables: variables })).data;
                setNumberOfActiveProductSelected(0);
                setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
                setSaleFulfillmentsState({ ...saleFulfillmentsState, data: { ...saleFulfillmentsState.data, sale: data.query.sale } });
                setPackStateState({ ...packStateState, data: { ...packStateState.data, mobile: data.query.mobile } });
                await returnCartsToOutputStation(data.query.station);
                playPositiveTone();

                //If two or more products were moved into a package, do not redirect to the "Confirm Weight" component.
                //if (quantityToMove > 1) return;

                //When moving a product into a package, there is the possibility that the product moved is the last product needed for the sale.
                //If that is the case, the user needs to be redirected to the "Confirm Weight" component to finish the sale.
                const allLocationsAtStation = getAllLocationsAtStation(data.query.station);
                const allProductsAtStationMatchingActiveSaleId = allLocationsAtStation
                    .flatMap((l) => l.products)
                    .filter((p) => p.saleId === activeSaleId);
                if (allProductsAtStationMatchingActiveSaleId.length < 1) {
                    await boxFull({ skipUserConfirmation: true, saleFulfillmentId: data.inventory.moveToNewSaleFulfillment.id });
                }
            }
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? error ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const onClickPackOrStowHandler = async () => {
        if (!packStateState.data?.mobile?.getPackState?.isOrderPackable) await moveActiveProductToDifferentLocation();
        else await moveActiveProductToSaleFulfillment();
    };

    /**
     * Makes a call to Hive server to try to print as many of the labels for sales at a station as possible.
     */
    const onClickBulkPrintLabelsButtonHandler = async () => {
        if (isLoading) return;
        if (!params.stationId) alert("No station id found in route params.");
        if (!params.locationId) alert("No location id found in route params.");
        if ((stationState.data?.station?.stationSet?.printers?.length ?? 0) < 1) return alert("There are no printers at this station");
        if (
            !(await showConfirmModal(
                "Are you sure you want to print labels for all sales at this station? (Please WAIT if you click 'Yes')",
                "Bulk Print Labels",
                {
                    confirm: "Yes",
                    cancel: "No",
                }
            ))
        )
            return;

        try {
            setManualLoading(true);

            let defaultShippingLabelPrinterId = stationState.data!.station!.stationSet!.printers!.find(
                (p) => p.type === PrinterType.Label
            )?.id;
            const selectedShippingLabelPrinterId =
                defaultShippingLabelPrinterId ??
                (await (
                    await showSelectModal(
                        "Select Label Printer",
                        stationState.data!.station!.stationSet!.printers.map((p) => ({ id: p.id, description: p.description } as ISelect)),
                        defaultShippingLabelPrinterId
                    )
                )?.id);
            if (!selectedShippingLabelPrinterId) return;

            const data = (
                await runBulkGenerateShippingLabelsMutation({
                    variables: {
                        stationId: params.stationId!,
                        printerId: selectedShippingLabelPrinterId,
                        locationId: params.locationId!,
                    },
                })
            ).data;
            const errors = data.api.shippingLabel.bulkGenerateShippingLabels.errors ?? [];
            if (errors.length > 0) {
                await showConfirmModal(
                    `Not all labels were printed. See below failures for more details:\n${errors.join("\n")}`,
                    "Bulk Print Labels Failures",
                    { confirm: "Ok" },
                    true
                );
            }
            setStationState({ ...stationState, data: { ...stationState.data, station: data.query.station } });
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const reprintLastLabel = async () => {
        if (!urlSearchParams.lastPrintedProperties) return;
        try {
            setManualLoading(true);
            const res = await runReprintShippingLabelMutation({
                variables: {
                    printerId: urlSearchParams.lastPrintedProperties.printerId,
                    saleFulfillmentId: urlSearchParams.lastPrintedProperties.saleFulfillmentId,
                },
            });
            if (!res.data.api.shippingLabel.reprintLabel.print) {
                alert(`Failed to reprint label.`);
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    let secondaryHeaderTitle = `Scan SKU`;
    let secondaryHeaderSecondaryTitle = "Any";
    //The action buttons text will change depending on what you are able to do.
    if (activeProduct) {
        secondaryHeaderTitle = `Scan SKU`;
        secondaryHeaderSecondaryTitle = activeProduct.product.sku ?? "";
        if (numberOfActiveProductSelected === maxStockOnHandForActiveProduct && maxStockOnHandForActiveProduct !== 0) {
            secondaryHeaderTitle = `Stow to Package`;
            secondaryHeaderSecondaryTitle = `Any`;
            if (!packStateState.data?.mobile?.getPackState?.isOrderPackable) {
                secondaryHeaderTitle = `Stow to Bin`;
            }
        }
    }

    return (
        <>
            {isLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={`${stationState.data?.station?.stationSet?.name ?? ""}`}
                IncludeBackButton
                IncludeHomeButton
                CustomHomeButtonFunctionAsync={async () => await abandonBoxCheck("/mobile/selectrole")}
                BackButtonText="< Stations"
                CustomBackButtonFunction={async () => await abandonBoxCheck("/mobile/pack/scanstation")}
            />

            <SecondaryHeader Title={secondaryHeaderTitle} SecondaryTitle={secondaryHeaderSecondaryTitle} NextButtonHandler={() => void 0} />

            <MobileTable isActiveTable>
                <thead>
                    <tr>
                        <td>Req</td>
                        <td>Hand</td>
                        <td>Pkg</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ color: "black" }}>
                            {!activeProduct ? "-" : activeProduct.stockOnHand - numberOfActiveProductSelected}
                        </td>
                        <td style={{ color: "black" }}>{numberOfActiveProductSelected}</td>
                        <td style={{ color: "black" }}>
                            {activeSaleFulfillment?.lineItems?.find((l) => l.saleLineItem.product.id === activeProduct?.productId)
                                ?.packageQuantity ?? 0}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ color: "black" }}>{activeLocation?.name ?? "-"}</td>
                        <td style={{ color: "black" }}>{activeProduct?.product?.sku ?? `-`}</td>
                        <td style={{ color: "black" }}>
                            <span style={{ fontSize: 10 }}>SID: {activeProduct?.saleId}</span>
                        </td>
                    </tr>
                </tbody>
            </MobileTable>

            {
                //This is temporary. Once Amazon Vendor sales are fully implemented to the pack flow, this will be removed.
                activeProduct?.sale?.salesChannel?.name === "Amazon Vendor" && (
                    <div style={{ display: "flex", justifyContent: "end", fontSize: 10, padding: "10px 10px 0 10px" }}>
                        SCR: <b>{activeProduct?.sale?.salesChannelReference ?? ""}</b>
                    </div>
                )
            }

            <div style={{ padding: "10px 10px 0 10px" }}>
                <ActiveProductImages sku={activeProduct?.product?.sku ?? ""} />
            </div>

            <div style={{ padding: "0 10px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: 12 }}>
                    Category: <b>{activeProduct?.product?.description ?? ""}</b>
                </div>
                <div style={{ fontSize: 12, display: "flex", gap: 10 }}>
                    <div>{urlSearchParams.scannedBoxDimensions && "📦"}</div>
                    <div>
                        Sale Fulfillment Id: <b>{urlSearchParams?.activeSaleFulfillmentId}</b>
                    </div>
                </div>
            </div>

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton
                            disabled={!activeProduct || autoPack || numberOfActiveProductSelected < 1}
                            onClick={async () => await onClickPackOrStowHandler()}
                        >{`Stow (${numberOfActiveProductSelected}) To ${
                            packStateState.data?.mobile?.getPackState?.isOrderPackable ? "Package" : "Bin"
                        }`}</MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled={!activeProduct || autoPack} onClick={decrement}>
                            -
                        </MobileButton>
                        <MobileButton disabled={!activeProduct || autoPack} onClick={increment}>
                            +
                        </MobileButton>
                        <MobileButton
                            disabled={!activeProduct || autoPack}
                            onClick={() => setNumberOfActiveProductSelected(maxStockOnHandForActiveProduct)}
                        >
                            Max
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled={true /*!packStateState.data*/} onClick={toggleAutoPack}>
                            Auto {packStateState.data?.mobile?.getPackState?.isOrderPackable ?? false ? "Pack" : "Stow"} (
                            {autoPack ? "On" : "Off"})
                        </MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton onClick={toggleAutoPrintLabels}>
                            Auto Print ({urlSearchParams.autoPrintShippingLabels ? "On" : "Off"})
                        </MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton
                            disabled={urlSearchParams.activeSaleFulfillmentId ? false : true}
                            onClick={async () => {
                                if (urlSearchParams.activeSaleId && activeSaleFulfillment) {
                                    await boxFull({
                                        skipUserConfirmation: false,
                                        saleFulfillmentId: activeSaleFulfillment.id,
                                        locations: allLocationsAtStation,
                                        saleId: urlSearchParams.activeSaleId,
                                    });
                                }
                            }}
                        >
                            Box Full
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton
                        onClick={async () => await onClickBulkPrintLabelsButtonHandler()}
                        disabled={!areAllProductsAtStationTheSame}
                    >
                        Bulk Print Labels
                    </MobileButton>
                    <MobileButton disabled={!urlSearchParams.lastPrintedProperties} onClick={async () => await reprintLastLabel()}>
                        Reprint Last Label
                    </MobileButton>
                </MobileButtonRow>
            </MobileButtonContainer>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 15, fontSize: 10, fontWeight: "bold" }}>
                SID = Sale ID | L = Location | P = Pick | M = Move
            </div>

            {(activeSaleFulfillment?.lineItems ?? []).length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>Products in Package</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {(activeSaleFulfillment?.lineItems ?? []).map((sfli, i1) => {
                                return (
                                    <tr
                                        key={i1 + sfli.saleFulfillmentId + sfli.saleLineItemId}
                                        onClick={async () =>
                                            await removeProductFromSaleFulfillment(
                                                sfli.saleFulfillmentId,
                                                sfli.saleLineItem.product.id,
                                                sfli.saleLineItem.saleId,
                                                sfli.saleLineItem.product.sku
                                            )
                                        }
                                    >
                                        <td>Package</td>
                                        <td>{sfli.saleLineItem.product.sku}</td>
                                        <td>{sfli.packageQuantity}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                    <div className="d-flex" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                        <MobileButton disabled>Add Extra Item</MobileButton>
                    </div>
                </div>
            )}

            {[...pickedProductsAtStationForActiveSale, ...pickedProductsNotAtStationForActiveSale, ...productsRemainingToPickForActiveSale]
                .length < 1 ? (
                <></>
            ) : (
                <>
                    <MobileTableTitle>Required Products for Sale</MobileTableTitle>
                    {packStateState.loading ? (
                        <div style={{ display: "flex", justifyContent: "center", height: 30 }}>
                            <MobileLoading />
                        </div>
                    ) : (
                        <>
                            <MobileTable>
                                <thead>
                                    <tr>
                                        <td>Bin</td>
                                        <td>SKU</td>
                                        <td>Req</td>
                                    </tr>
                                </thead>
                            </MobileTable>
                            <MobileTable>
                                <tbody>
                                    {pickedProductsAtStationForActiveSale.map((product) => {
                                        return (
                                            <tr
                                                key={product.productId + product.productLocationId + product.productSaleId}
                                                onClick={() => onClickPickedProductAtStationTableRowHandler(product)}
                                            >
                                                <td>{product.productLocationName}</td>
                                                <td>
                                                    {product.productSku}{" "}
                                                    <span style={{ fontSize: 10 }}>(SID: {product.productSaleId})</span>
                                                </td>
                                                <td>
                                                    {product.productLocationStockOnHand}
                                                    <span style={{ fontSize: 10, fontWeight: "bold" }}>L</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </MobileTable>
                            <MobileTable>
                                <tbody>
                                    {pickedProductsNotAtStationForActiveSale.map((product) => {
                                        return (
                                            <tr
                                                key={product.productId + product.productLocationId + product.productSaleId}
                                                style={{ border: "1px solid blue" }}
                                                onClick={() => onClickPickedProductNotAtStationTableRowHandler(product)}
                                            >
                                                <td>{product.productLocationName}</td>
                                                <td>
                                                    {product.productSku}{" "}
                                                    <span style={{ fontSize: 10 }}>(SID: {product.productSaleId})</span>
                                                </td>
                                                <td>
                                                    {product.productLocationStockOnHand}
                                                    <span style={{ fontSize: 10, fontWeight: "bold" }}>L</span> /{" "}
                                                    {product.productLocationStockOnHand}
                                                    <span style={{ fontSize: 10, fontWeight: "bold" }}>M</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </MobileTable>
                            <MobileTable>
                                <tbody>
                                    {productsRemainingToPickForActiveSale.map((product) => {
                                        return (
                                            <tr
                                                key={product.productId + product.productLocationId + product.productSaleId}
                                                style={{ border: "1px solid red" }}
                                                onClick={() => onClickProductRemainingToPickTableRowHandler(product)}
                                            >
                                                <td>{product.productLocationName}</td>
                                                <td>
                                                    {product.productSku}{" "}
                                                    <span style={{ fontSize: 10 }}>(SID: {product.productSaleId})</span>
                                                </td>
                                                <td>
                                                    {product.productLocationStockOnHand}
                                                    <span style={{ fontSize: 10, fontWeight: "bold" }}>L</span> /{" "}
                                                    {product.productQuantityRemainingToPick}
                                                    <span style={{ fontSize: 10, fontWeight: "bold" }}>P</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </MobileTable>
                        </>
                    )}
                </>
            )}

            {(activeLocation?.products ?? []).length < 1 ? (
                <></>
            ) : (
                <>
                    <MobileTableTitle>Products in {activeLocation?.name ?? `-`}</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {(activeLocation?.products ?? []).map((product) => {
                                return (
                                    <tr
                                        key={product.productId + product.saleId}
                                        onClick={() => onClickItemsInActiveLocationHandler(product)}
                                    >
                                        <td>{activeLocation?.name ?? `-`}</td>
                                        <td>
                                            {product.product.sku} <span style={{ fontSize: 10 }}>SID: ({product.saleId})</span>
                                        </td>
                                        <td>{product.stockOnHand}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </>
            )}

            {locationsAtStationWithStockOnHand.length < 1 ? (
                <></>
            ) : (
                <>
                    <MobileTableTitle>Locations at Station</MobileTableTitle>
                    <MobileTable>
                        <thead>
                            <tr>
                                <td>Bin</td>
                                <td>Bin</td>
                            </tr>
                        </thead>
                        <tbody>
                            {locationsAtStationWithStockOnHand.map((location, i) => {
                                return (
                                    <tr key={i} onClick={() => onClickLocationAtStationHandler(location.id)}>
                                        <td>{location.name}</td>
                                        <td>{location.products.reduce((acc, v) => acc + v.stockOnHand, 0)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </>
            )}

            {createElement(selectModal)}

            {createElement(reloadModal)}

            {createElement(confirmModal)}

            {createElement(multiMatchSelectorModal)}
        </>
    );
};

export default ScanSku;
