import GroupBy from "../../../../helpers/GroupBy";

interface ILocation {
    aisle: string | null;
    side: string | null;
    rack: string | null;
    shelf: string | null;
    bin: string | null;
}

interface IWithLocation {
    location: ILocation;
}

const localCompareOptions: Intl.CollatorOptions = {
    numeric: true,
    sensitivity: "base",
};

function customSort<T extends IWithLocation>(a: T, b: T, aisle: string) {
    //determine multiplier based on aisle
    const aisleNumber = parseInt(aisle, 10) || 1;
    const multiplier = Math.floor((aisleNumber - 1) / 2) % 2 === 0 ? 1 : -1;
    //first, localeCompare by bin
    const binComparison = (a.location.bin ?? "").localeCompare(b.location.bin ?? "", undefined, localCompareOptions) * multiplier;
    return binComparison === 0
        ? //if bin values are equal, localeCompare by shelf
          (a.location.shelf ?? "").localeCompare(b.location.shelf ?? "", undefined, localCompareOptions) * multiplier
        : binComparison;
}

export function sortPickList<T extends IWithLocation>(list: T[]) {
    let groupedBySide = GroupBy(list, (l) => l.location.side ?? "");
    let result: T[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    for (let [_, values] of Array.from(groupedBySide.entries())) {
        let groupedByAisle = GroupBy(values, (v) => v.location.aisle ?? "");
        const sortedMapEntries = Array.from(groupedByAisle.entries()).sort((a, b) => {
            return String(a[0]).localeCompare(String(b[0]), undefined, localCompareOptions);
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        for (let [aisle, aisleValues] of sortedMapEntries) {
            const sortedLocations = aisleValues.sort((a, b) => customSort(a, b, aisle));
            result = result.concat(sortedLocations);
        }
    }
    return result;
}
