import { GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { GraphQLContext } from "@shane32/graphql";

interface IPurchaseOrder {
    id: string;
    status: boolean;
    total: number;
    notes: string;
}

interface IPurchaseOrders {
    purchaseOrders: {
        totalCount: number;
        items: Array<IPurchaseOrder>;
    };
}

//Create the query to get all the purchaseOrders by supplier
const purchaseOrdersBySupplierQuery = `
query ($supplierId: ID!, $sortOrder: PurchaseOrdersSortOrder!) {
    purchaseOrders(supplierId: $supplierId, sortOrder: $sortOrder) {
        totalCount
        items {
            id
            status
            total
            notes
        }
    }
}
`;

interface IPurchaseOrdersVariables {
    after: string | null;
    supplierId: string;
    sortOrder: string | null;
}

const SupplierPurchaseOrder = () => {
    const graphQLContext = React.useContext(GraphQLContext);
    const { id } = useParams<{ id: string }>();
    //called when AG Grid renders
    const onGridReady = (params: GridReadyEvent) => {
        //used by AG Grid as a server handle to lazy load data
        let dataSource: IDatasource = {
            //called whenever AG Grid needs more data
            getRows: (params: IGetRowsParams) => {
                //construct the sortOrder string so it matches an enum on the server
                let sortOrder = null;
                if (params.sortModel.length > 0) {
                    if (params.sortModel[0].sort === "asc") {
                        sortOrder = "_ASCENDING";
                    } else if (params.sortModel[0].sort === "desc") {
                        sortOrder = "_DESCENDING";
                    }

                    switch (params.sortModel[0].colId) {
                        case "purchaseOrdersBySupplier.id":
                            sortOrder = "ID" + sortOrder;
                            break;
                        case "purchaseOrdersBySupplier.status":
                            sortOrder = "STATUS" + sortOrder;
                            break;
                        case "purchaseOrdersBySupplier.notes":
                            sortOrder = "NOTES" + sortOrder;
                            break;
                        case "purchaseOrdersBySupplier.total":
                            sortOrder = "TOTAL" + sortOrder;
                            break;
                    }
                }
                //default
                else {
                    sortOrder = "ID_ASCENDING";
                }
                //ask for 100 rows after this index
                let after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                //query the server for data
                const ret = graphQLContext.client.ExecuteQueryRaw<IPurchaseOrders, IPurchaseOrdersVariables>({
                    query: purchaseOrdersBySupplierQuery,
                    variables: {
                        after: after,
                        supplierId: id,
                        sortOrder: sortOrder,
                    },
                });
                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors) {
                            console.log("Error fetching data: 1", result.errors);
                            return;
                        }
                        //feed data into AG Grid
                        params.successCallback(result.data!.purchaseOrders.items, result.data!.purchaseOrders.totalCount);
                    },
                    //failure
                    (result) => {
                        params.failCallback();
                        console.log("Error fetching data: 2", result);
                    }
                );
            },
        };
        //bind the server handle ('dataSource') to AG Grid
        params.api.setDatasource(dataSource);
    };
    //AG Grid column structure
    const columns = [
        {
            field: "id",
            headerName: "Order#",
            flex: 1.5,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data) {
                    const link = `/purchaseorders/${params.data.id}`;
                    return <Link to={link}>{"ZPO-" + params.data.id}</Link>;
                } else return <></>;
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 160,
            cellRenderer: "loadingRenderer",
        },
        {
            field: "notes",
            headerName: "Notes",
        },

        {
            field: "total",
            headerName: "Total",
            minWidth: 165,
        },
    ];

    return (
        <>
            <h2>Orders</h2>
            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={[...columns]}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        resizable: true,
                    }}
                    components={{
                        loadingRenderer: function (params: ICellRendererParams) {
                            if (params.value !== undefined) {
                                return params.value;
                            } else {
                                return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                            }
                        },
                    }}
                    onGridReady={onGridReady}
                    animateRows={true}
                    domLayout="autoHeight"
                    rowModelType={"infinite"}
                    paginationPageSize={10}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    pagination={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                ></AgGridReact>
            </div>
        </>
    );
};

export default SupplierPurchaseOrder;
