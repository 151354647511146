import { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

type ResolveType = (value: boolean | PromiseLike<boolean>) => void;

const useReload = () => {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const resolveRef = useRef<ResolveType>();
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalMessage, setModalMessage] = useState<string>("");
    const [redirectButtonProperties, setRedirectButtonProperties] = useState<{ text: string; path: string }>({
        text: "Home Page",
        path: "/mobile/selectrole",
    });

    const showReloadModal = useCallback((title: string, message: string, redirectButtonProperties?: { text: string; path: string }) => {
        setModalTitle(title);
        setModalMessage(message);
        if (redirectButtonProperties) setRedirectButtonProperties(redirectButtonProperties);
        return new Promise<boolean>((resolve) => {
            resolveRef.current = resolve;
            setOpen(true);
        });
    }, []);

    function reloadModal() {
        return (
            <Modal animation={false} show={open} onHide={() => void 0} style={{ zIndex: 1000001 }}>
                <Modal.Title style={{ margin: "10px 15px 0 15px" }}>
                    <b>{modalTitle}</b>
                </Modal.Title>

                <Modal.Body className="d-flex flex-column">
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div>{modalMessage}</div>
                        <div style={{ display: "flex", gap: 10 }}>
                            <button
                                style={{ flex: 1, height: 75, borderRadius: 4, fontWeight: 500, fontSize: 16, border: "none" }}
                                onClick={() => window.location.reload()}
                            >
                                Reload
                            </button>
                            <button
                                style={{ flex: 1, height: 75, borderRadius: 4, fontWeight: 500, fontSize: 16, border: "none" }}
                                onClick={() => history.push(redirectButtonProperties.path)}
                            >
                                {redirectButtonProperties.text}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return { reloadModal, showReloadModal };
};

export default useReload;
