import { Route, Switch } from "react-router-dom";
import SelectPurchaseOrder from "./SelectPurchaseOrder";
import SelectInvoice from "./SelectInvoice";
import ScanBarcode from "./ScanBarcode";
import AddOrPrintBarcodeContainer from "./AddOrPrintBarcodeContainer";

const IntakeController = () => {
    return (
        <Switch>
            <Route exact path="/mobile/intake/selectpurchaseorder">
                <SelectPurchaseOrder />
            </Route>
            <Route exact path="/mobile/intake/:purchaseOrderId/selectinvoice">
                <SelectInvoice />
            </Route>
            <Route exact path="/mobile/intake/:purchaseOrderId/:purchaseInvoiceId/scanbarcode">
                <ScanBarcode />
            </Route>
            <Route exact path="/mobile/intake/:purchaseOrderId/:purchaseInvoiceId/:productId/addorprintbarcode">
                <AddOrPrintBarcodeContainer />
            </Route>
        </Switch>
    );
};

export default IntakeController;
