import { useMutation, useQuery } from "@shane32/graphql";
import React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useSpreadSheetQuery } from "../../../queries/spreadSheetQueries";

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: string;
    active: boolean;
    warehouses: Array<ISupplierWarehouse>;
}

interface ISupplierWarehouse {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    products: Array<ISupplierWarehouseProduct>;
}

interface ISupplierWarehouseProduct {
    productId: string;
    qtyInStock: string;
    product: {
        sku: string;
    };
}

interface IQueryResult {
    supplier: ISupplier;
}

interface ISupplierModel {
    id: string;
    name: string;
    defaultPaymentTermId: string;
    active: boolean;
    warehouses: Array<ISupplierWarehouseModel>;
}

interface ISupplierWarehouseModel {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
    products: Array<ISupplierWarehouseProductModel> | null;
}

interface ISupplierWarehouseProductModel {
    sku: string;
    qtyInStock: number;
}

interface ISupplierWarehouseProductInputModel {
    sku: string;
    qtyInStock: string;
}

interface IEditResult {
    supplier: {
        edit: {
            warehouses: Array<ISupplierWarehouse>;
        };
    };
}

interface IEditVariables {
    original: ISupplierModel;
    modified: ISupplierModel;
}

const SupplierWarehouseQuery = `
query ($id: ID!) {
    supplier(id: $id) {
      id
      name
      defaultPaymentTermId
      active
      warehouses {
        id
        name
        sortOrder
        active
        products {
          productId
          qtyInStock
          product {
            sku
          }
        }
      }
    }
  }
`;
const exportSupplierStockQuery = `
query($id: ID!){
    supplierWarehouse (id: $id){
      products{
        product{
          sku @column
        }
        qtyInStock @column(name: "Stock")
      }
    }
  }`;

const SupplierEditMutation = `
mutation ($original: SupplierInput!, $modified: SupplierInput!) {
    supplier {
      edit(original: $original, modified: $modified){
        warehouses {
          id
          name
          sortOrder
          active
          products{
            productId
            qtyInStock
            product {
              sku
            }
          }
        }
      }
    }
  }
`;

const SupplierWarehouseSummary = () => {
    const { id } = useParams<{ id: string }>();
    const { supplierWarehouseId } = useParams<{ supplierWarehouseId: string }>();
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(SupplierWarehouseQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState(false);
    const [runSupplierEdit] = useMutation<IEditResult, IEditVariables>(SupplierEditMutation);
    const runSupplierStockExport = useSpreadSheetQuery<{ id: string }>(exportSupplierStockQuery);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!data) return <Loading />;

    const onShowModal = () => {
        setShowModal(true);
    };

    const onHideModal = () => {
        if (!saving) setShowModal(false);
    };
    const downLoadStockXlsx = () => {
        const fileName = `${data.supplier.name}-${
            data.supplier.warehouses.find((x) => x.id === supplierWarehouseId)?.name
        }-${new Date().toLocaleDateString()}`;
        runSupplierStockExport({ id: supplierWarehouseId }, fileName);
    };

    const onSaveChanges = (modified: ISupplierWarehouseProductInputModel) => {
        setSaving(true);
        let currentSupplierWarehouse = data.supplier.warehouses.find((x) => x.id === supplierWarehouseId);
        let existingSupplierWarehouseProduct = currentSupplierWarehouse?.products?.find((x) => x.product.sku === modified.sku);
        runSupplierEdit({
            variables: {
                original: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [
                        {
                            id: supplierWarehouseId,
                            name: currentSupplierWarehouse!.name,
                            sortOrder: currentSupplierWarehouse!.sortOrder,
                            active: currentSupplierWarehouse!.active,
                            products: existingSupplierWarehouseProduct
                                ? [
                                      {
                                          sku: existingSupplierWarehouseProduct.product.sku,
                                          qtyInStock: parseInt(existingSupplierWarehouseProduct.qtyInStock),
                                      },
                                  ]
                                : null,
                        },
                    ],
                },
                modified: {
                    id: data.supplier.id,
                    name: data.supplier.name,
                    defaultPaymentTermId: data.supplier.defaultPaymentTermId,
                    active: data.supplier.active,
                    warehouses: [
                        {
                            id: supplierWarehouseId,
                            name: currentSupplierWarehouse!.name,
                            sortOrder: currentSupplierWarehouse!.sortOrder,
                            active: currentSupplierWarehouse!.active,
                            products: [
                                {
                                    sku: modified.sku,
                                    qtyInStock: parseInt(modified.qtyInStock),
                                },
                            ],
                        },
                    ],
                },
            },
        }).then(
            (ret) => {
                currentSupplierWarehouse!.products = ret.data.supplier.edit.warehouses.find((x) => x.id === supplierWarehouseId)!.products;
                setSaving(false);
                setShowModal(false);
            },
            (err) => {
                setSaving(false);
                console.error("Error adding supplier warehouse product", err);
                alert(err.message);
            }
        );
    };

    const originalModel: ISupplierWarehouseProductInputModel = {
        sku: "",
        qtyInStock: "",
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={onShowModal}>
                    Add/Edit Supplier Warehouse Product
                </Button>
                <Button variant="white" onClick={downLoadStockXlsx} style={{ marginLeft: "20px" }}>
                    Download Stock
                </Button>
            </p>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Supplier Warehouse Products</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>SKU</th>
                                <th>Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.supplier.warehouses
                                .find((x) => x.id === supplierWarehouseId)
                                ?.products.map((supplierWarehouseProduct) => {
                                    return (
                                        <tr key={`${supplierWarehouseProduct.productId}`}>
                                            <td>
                                                <Link to={`/products/${supplierWarehouseProduct.productId}`}>
                                                    {supplierWarehouseProduct.product.sku}
                                                </Link>
                                            </td>
                                            <td>{supplierWarehouseProduct.qtyInStock}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Add/Edit Supplier Warehouse Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formSku">
                                    <VLabel valueName="sku">Sku</VLabel>
                                    <VControl type="text" required valueName="sku" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formStock">
                                    <VLabel valueName="qtyInStock">Stock Quantity</VLabel>
                                    <VControl type="text" required valueName="qtyInStock" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SupplierWarehouseSummary;
