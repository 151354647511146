import React from "react";
import { Row, Col, Form, Table, Button, Modal, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import PageHeader from "../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface ISupplierQueryResult {
    suppliers: {
        items: Array<ISupplier>;
    };
}
interface IPaymentTermQueryResult {
    paymentTerms: {
        items: Array<IPaymentTerm>;
    };
}

interface IPaymentTerm {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface ISupplier {
    id: string;
    name: string;
    defaultPaymentTermId: string;
    active: boolean;
}

interface IAddResult {
    supplier: {
        add: ISupplier;
    };
}

interface IAddVariables {
    value: ISupplier;
}

//define global functions
function sortSupplier(a: ISupplier, b: ISupplier) {
    return a.name > b.name ? 1 : -1;
}

function sortPaymentTerm(a: IPaymentTerm, b: IPaymentTerm) {
    return a.name > b.name ? 1 : -1;
}

const SuppliersIndex = () => {
    //=== set up state variables ===
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //payment list
    const { data, error, refetch } = useQuery<ISupplierQueryResult, {}>("{ suppliers { items { id name defaultPaymentTermId active } } }", {
        fetchPolicy: "no-cache",
    });
    const {
        data: paymentTermData,
        error: paymentTermError,
        refetch: runRefetchPaymentTermList,
    } = useQuery<IPaymentTermQueryResult, {}>("{ paymentTerms { items { id name sortOrder active } } }", { fetchPolicy: "no-cache" });
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(
        "mutation ($value: SupplierInput!) { supplier { add (value: $value) { id name defaultPaymentTermId active } } }"
    );

    //run when the add button is pressed
    const onShowModal = () => {
        setShowModal(true);
    };
    ////run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setShowModal(false);
    };
    //run when the save button is pressed
    const onSaveChanges = (modified: ISupplier) => {
        //disable form controls
        setSaving(true);
        //start add operation via graphql mutation
        runAdd({
            variables: {
                value: {
                    id: "0", //ignored, but required
                    name: modified.name,
                    defaultPaymentTermId: modified.defaultPaymentTermId,
                    active: modified.active,
                },
            },
        }).then(
            //success
            (ret) => {
                //add the entry to the local list
                if (data) {
                    var newValue = ret.data.supplier.add;
                    data.suppliers.items.push(newValue);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setShowModal(false);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error adding supplier", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: ISupplier = {
        id: "0",
        name: "",
        defaultPaymentTermId: "",
        active: true,
    };

    //redirect to suppliers page
    const history = useHistory();
    const navTo = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        if (e.currentTarget === e.target) history.push("/suppliers/" + encodeURIComponent(id));
    };

    //this code displays a card and is nearly the same for active and inactive
    const card = (active: boolean) => {
        // search data where active or inactive, as appropriate
        // sort by defaultpaymentTermId -- see function defined above
        const suppliers = data!.suppliers.items.filter((supplier) => supplier.active === active).sort(sortSupplier);
        return (
            <Card className="border-primary" style={{ maxWidth: 400 }}>
                <Card.Header className="bg-primary text-white">
                    {/* set card header appropriately */}
                    {active ? "Active" : "Inactive"} Suppliers
                </Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Suppliers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {suppliers.map((supplier) => (
                                <tr key={supplier.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, supplier.id)}>
                                        <Link to={`/suppliers/${supplier.id}`}>{supplier.name}</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display message if failed to retrieve data
    if (paymentTermError) return <ErrorDisplay onClick={runRefetchPaymentTermList}>{paymentTermError.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data || !paymentTermData) return <Loading />;

    // search data where active or inactive, as appropriate
    // sort by sortorder, then name -- see function defined above
    const paymentTerms = paymentTermData.paymentTerms.items.filter((paymentTerm) => paymentTerm.active === true).sort(sortPaymentTerm);
    return (
        <>
            <PageHeader>Suppliers</PageHeader>
            <p>
                <Button variant="white" onClick={onShowModal}>
                    Add New Supplier
                </Button>
            </p>

            {/* Active Payment Terms */}
            {card(true)}

            {/* Inactive Payment Terms */}
            {card(false)}

            <Modal show={showModal} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Add Supplier</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="defaultPaymentTermId">Default Payment Term</VLabel>
                                    <VSelect valueName="defaultPaymentTermId" required>
                                        <option value=""></option>
                                        {
                                            //Query from paymentTerm database by ID and display by name
                                            paymentTerms.map((paymentTerm) => (
                                                <option value={paymentTerm.id} key={`PaymentTermSelect-${paymentTerm.id}`}>
                                                    {paymentTerm.name}
                                                </option>
                                            ))
                                        }
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SuppliersIndex;
