import PrimaryHeader from "../../../../components/PrimaryHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import ManageCarts from "./ManageCarts";

const ManageCartsWrapper = () => {
    return (
        <>
            <PrimaryHeader
                Title="Manage Carts"
                IncludeBackButton
                IncludeHomeButton
                BackButtonText="< Admin Cart"
                CustomBackButtonPath="/mobile/admin/cart"
            />

            <SecondaryHeader Title="Select a Cart" NextButtonHandler={async () => void 0} />

            <ManageCarts />
        </>
    );
};

export default ManageCartsWrapper;
