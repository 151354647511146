import { useCallback, createContext, useRef, useMemo } from "react";
import positiveTone from "../sounds/positive_tone.mp3";
import zeldaFanfare from "../sounds/zelda_fanfare.mp3";
import negativeTone from "../sounds/negative_tone.mp3";
import fartWithReverb from "../sounds/fart_with_reverb.mp3";
import quickReverbFart from "../sounds/quick_reverb_fart.mp3";

interface IScannerToneContext {
    playPositiveTone: () => void;
    playNegativeTone: () => void;
}

const ScannerToneContext = createContext<IScannerToneContext>(null as any);
export default ScannerToneContext;

export const ScannerToneContextController = (props: { children: any }) => {
    const positiveAudioElement = useRef<HTMLAudioElement>(null);
    const negativeAudioElement = useRef<HTMLAudioElement>(null);

    const positiveRNGTones = useMemo(() => [zeldaFanfare], []);
    const negativeRNGTones = useMemo(() => [fartWithReverb, quickReverbFart], []);

    const playPositiveTone = useCallback(() => {
        if (!positiveAudioElement?.current) return;
        if (Math.random() * 1000 > 995)
            positiveAudioElement.current.src = positiveRNGTones[Math.floor(Math.random() * positiveRNGTones.length)];
        else positiveAudioElement.current.src = positiveTone;
        positiveAudioElement.current.play();
    }, [positiveRNGTones]);

    const playNegativeTone = useCallback(() => {
        if (!negativeAudioElement?.current) return;
        if (Math.random() * 1000 > 995)
            negativeAudioElement.current.src = negativeRNGTones[Math.floor(Math.random() * negativeRNGTones.length)];
        else negativeAudioElement.current.src = negativeTone;
        negativeAudioElement.current.play();
    }, [negativeRNGTones]);

    const state: IScannerToneContext = {
        playNegativeTone: playNegativeTone,
        playPositiveTone: playPositiveTone,
    };

    return (
        <ScannerToneContext.Provider value={state}>
            <audio ref={positiveAudioElement} src={positiveTone} />
            <audio ref={negativeAudioElement} src={negativeTone} />
            {props.children}
        </ScannerToneContext.Provider>
    );
};
