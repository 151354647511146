import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import PurchaseOrderStatus from "../../../../enums/PurchaseOrderStatus";
import PurchaseOrderType from "../../../../enums/PurchaseOrderType";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import useScanner from "../../hooks/useScanner";

const purchaseOrdersQuery = `
query ($statuses: [PurchaseOrderStatus!], $type: PurchaseOrderType!) {
    purchaseOrders(statuses: $statuses, type: $type) {
      items {
        id
        supplier {
          name
        }
        orderDate
      }
    }
  }
`;

interface IPurchaseOrdersQueryResult {
    purchaseOrders: {
        items: Array<{
            id: string;
            supplier: {
                name: string;
            };
            orderDate: string;
        }>;
    };
}

interface IPurchaseOrdersQueryVariables {
    statuses: Array<PurchaseOrderStatus>;
    type: PurchaseOrderType;
}

const purchaseInvoiceQuery = `
query ($purchaseInvoiceId: ID!) {
    purchaseInvoice(id: $purchaseInvoiceId) {
      id
      purchaseOrderId
    }
  }
`;

interface IPurchaseInvoiceQueryResult {
    purchaseInvoice: {
        id: string;
        purchaseOrderId: string;
    } | null;
}

interface IPurchaseInvoiceQueryVariables {
    purchaseInvoiceId: string;
}

const SelectReturnNumber = () => {
    const history = useHistory();
    const trackingNumberInputRef = useRef<HTMLInputElement>(null);
    const [filterValue, setFilterValue] = useState("");
    const [manualLoading, setManualLoading] = useState(false);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const { loading, data, refetch, error } = useQuery<IPurchaseOrdersQueryResult, IPurchaseOrdersQueryVariables>(purchaseOrdersQuery, {
        fetchPolicy: "no-cache",
        variables: {
            statuses: [PurchaseOrderStatus.Authorized],
            type: PurchaseOrderType.Return,
        },
    });

    const [runSearchBarcodesMutation] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runPurchaseInvoiceQuery] = useMutation<IPurchaseInvoiceQueryResult, IPurchaseInvoiceQueryVariables>(purchaseInvoiceQuery);

    const onScan = async (scannerValue: string) => await searchBarcodes(scannerValue, false);
    useScanner(onScan, { isPackage: true });

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for purchase orders failed"}</ErrorDisplay>;

    const onClickTrackingNumberGo = async () => {
        if (!trackingNumberInputRef?.current?.value) return;
        const value = trackingNumberInputRef.current.value.trim();
        if (!value) return;
        await searchBarcodes(value, true);
    };

    const searchBarcodes = async (searchValue: string, userEntered: boolean) => {
        if (loading || !data || manualLoading) return;
        try {
            setManualLoading(true);
            const barcodesRes = await runSearchBarcodesMutation({ variables: { search: searchValue, userEntered: userEntered } });
            const barcodesData = barcodesRes.data.searchBarcodes.filter((x) => x.type === ModelType.PurchaseInvoice);
            if (barcodesData.length < 1) return alert(`Found ${barcodesData.length} tracking numbers matching value ${searchValue}.`);
            if (barcodesData.length > 1)
                return alert(`Found ${barcodesData.length} tracking numbers matching value ${searchValue}. There should only be one`);
            const purchaseInvoiceRes = await runPurchaseInvoiceQuery({ variables: { purchaseInvoiceId: barcodesData[0].id } });
            if (!purchaseInvoiceRes.data.purchaseInvoice) return playNegativeTone();
            const purchaseInvoice = purchaseInvoiceRes.data.purchaseInvoice;
            playPositiveTone();
            history.push(`/mobile/returns/${purchaseInvoice.purchaseOrderId}/${purchaseInvoice.id}/scanbarcode`);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    //returns are stored as a purchase order in the database.
    const purchaseOrders = (data?.purchaseOrders?.items ?? []).filter((po) => {
        const inputValue = filterValue.trim().toUpperCase();
        if (inputValue) return po.id.toUpperCase().includes(inputValue);
        else return po;
    });

    return (
        <>
            {loading || manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title="Returns"
                IncludeBackButton
                BackButtonText="< Roles"
                CustomBackButtonPath={`/mobile/selectrole`}
                IncludeHomeButton
            />

            <SecondaryHeader Title="║▌Scan Return #" NextButtonHandler={() => void 0} />

            <div style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    ref={trackingNumberInputRef}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search by Tracking Numbers..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    onClick={async () => await onClickTrackingNumberGo()}
                >
                    <b>GO</b>
                </button>
            </div>

            <div style={{ padding: "10px 10px 5px 10px", backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    onChange={(e) => setFilterValue(e.currentTarget.value)}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{ padding: 10, alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", width: "100%" }}
                    placeholder="Filter Return Numbers..."
                />
            </div>

            <MobileTable>
                <thead>
                    <tr>
                        <td>Return #</td>
                        <td>Created</td>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrders.map((po) => {
                        return (
                            <tr key={po.id} id={po.id} onClick={() => history.push(`/mobile/returns/${po.id}/selecttrackingnumber`)}>
                                <td>ZPO-{po.id}</td>
                                <td>{new Date(po.orderDate).toLocaleDateString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>
        </>
    );
};

export default SelectReturnNumber;
