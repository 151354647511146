import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AuthContext } from "@zboxglobal/zboxauth";

interface IProps {
    IncludeBackButton?: boolean;
    BackButtonText?: string;
    Title: string;
    IncludeHomeButton?: boolean;
    CustomBackButtonPath?: string;
    CustomBackButtonFunction?: () => void;
    OnTitleClick?: () => void;
    CustomHomeButtonFunctionAsync?: () => void;
}

//*NOTE* The "forwardRef" type arguments are reversed for whatever reason...
const PrimaryHeader = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    var auth = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    const logout = async () => {
        window.location.pathname = "/mobile";
        await auth.client.Logout();
    };

    const home = async () => {
        if (props.CustomHomeButtonFunctionAsync) await props.CustomHomeButtonFunctionAsync();
        else history.push("/mobile/selectrole");
    };

    const back = () => {
        if (props.CustomBackButtonFunction) props.CustomBackButtonFunction();
        else if (props.CustomBackButtonPath !== undefined) history.push(props.CustomBackButtonPath);
        else history.goBack();
    };

    return (
        <Container ref={ref} style={{ maxWidth: "100%" }}>
            <Row style={{ backgroundColor: "#EDF0F4", height: "60px" }}>
                <Col className="d-flex align-items-center justify-content-start col">
                    {props.IncludeBackButton !== undefined ? (
                        <div role="button" onClick={() => back()}>
                            {props.BackButtonText ? props.BackButtonText ?? "Back" : "Back"}
                        </div>
                    ) : (
                        <></>
                    )}
                </Col>
                <Col
                    className="text-center d-flex align-items-center justify-content-center col-6"
                    style={{ fontSize: "18px" }}
                    onClick={props.OnTitleClick}
                >
                    {props.Title}
                </Col>
                <Col className="d-flex align-items-center justify-content-end col">
                    {location.pathname === "/mobile/selectrole" ? (
                        <div onClick={async () => await logout()}>
                            <div>Sign out:</div>
                            <div style={{ textAlign: "right" }}>{auth.status.userInfo?.name}</div>
                        </div>
                    ) : (
                        <div onClick={async () => await home()}>
                            <div>Home:</div>
                            <div style={{ textAlign: "right" }}>{auth.status.userInfo?.name}</div>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
});

export default PrimaryHeader;
