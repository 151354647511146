interface IProps {
    /** The amount of space (in "px") between each child component */
    gap?: number;
    children: React.ReactNode;
}

const MobileButtonRow = ({ gap, children }: IProps) => {
    return <div style={{ display: "flex", gap: gap ?? 10, width: "100%" }}>{children}</div>;
};

export default MobileButtonRow;
