import styles from "./MobileTableTitle.module.scss";

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    children: React.ReactNode;
}

const MobileTableTitle = ({ children, ...tableAttributes }: IProps) => {
    const { className, ...remainingAttributes } = tableAttributes;
    return (
        <div className={`${styles.container} ${className ?? ""}`} {...remainingAttributes}>
            {children}
        </div>
    );
};

export default MobileTableTitle;
