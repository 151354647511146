import { useState, useCallback, createContext } from "react";

interface ISessionContext {
    autoStow: boolean;
    updateAutoStow: (autoStow: boolean) => void;
}

const SessionContext = createContext<ISessionContext>(null as any);
export default SessionContext;

export const SessionContextController = (props: { children: any }) => {
    const [autoStow, setAutoStow] = useState<boolean>(false);

    const updateAutoStow = useCallback(async (a: boolean) => {
        setAutoStow(a);
    }, []);

    const state: ISessionContext = {
        autoStow: autoStow,
        updateAutoStow: updateAutoStow,
    };

    return <SessionContext.Provider value={state}>{props.children}</SessionContext.Provider>;
};
