import { Route, Switch } from "react-router-dom";
import ScanBin from "./ScanBin";
import ScanSku from "./ScanSku";
import PrintLabel from "./print_label/PrintLabel";
import ScanStation from "./ScanStation";

const PackController = () => {
    return (
        <Switch>
            <Route exact path={`/mobile/pack/scanstation`}>
                <ScanStation />
            </Route>

            <Route exact path={`/mobile/pack/:stationId/scanlocation`}>
                <ScanBin />
            </Route>

            <Route exact path={`/mobile/pack/:stationId/:locationId`}>
                <ScanSku />
            </Route>

            <Route exact path={`/mobile/pack/:stationId/:locationId/printlabel`}>
                <PrintLabel />
            </Route>
        </Switch>
    );
};

export default PackController;
