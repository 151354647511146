import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import PurchaseInvoiceStatus from "../../../../enums/PurchaseInvoiceStatus";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import { useState } from "react";

const purchaseOrderQuery = `
query ($id: ID!) {
  purchaseOrder(id: $id) {
    id
    supplier {
      name
    }
    invoices {
      id
      status
      invoiceNumber
      invoiceDate
      trackingNumbers {
        trackingNumber
      }
    }
  }
}
`;

interface IPurchaseOrderQueryResult {
    purchaseOrder: {
        id: string;
        supplier: {
            name: string;
        } | null;
        invoices: Array<{
            id: string;
            status: PurchaseInvoiceStatus;
            invoiceNumber: string;
            invoiceDate: string;
            trackingNumbers: Array<{
                trackingNumber: string;
            }>;
        }>;
    };
}

interface IPurchaseOrderQueryVariables {
    id: string;
}

export interface IReturnsSelectTrackingNumberRouteParams {
    purchaseOrderId: string;
}

const SelectTrackingNumber = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<IReturnsSelectTrackingNumberRouteParams>();
    const searchParams = new URLSearchParams(location.search);
    const invoicesWarning = searchParams.get("invoicesWarning");
    const [filterValue, setFilterValue] = useState("");
    const { loading, data, refetch, error } = useQuery<IPurchaseOrderQueryResult, IPurchaseOrderQueryVariables>(purchaseOrderQuery, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.purchaseOrderId,
        },
    });

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for purchase orders failed"}</ErrorDisplay>;

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/returns/selectreturnnumber`} />;

    let invoices = data?.purchaseOrder?.invoices?.filter((x) => x.status === PurchaseInvoiceStatus.Authorized) ?? [];
    const primaryHeaderTitle = `ZPO-${data?.purchaseOrder.id ?? ""} ${data?.purchaseOrder?.supplier?.name ?? ""}`;

    //returns are stored as a purchase order in the database.
    invoices = invoices.filter((pi) => {
        const inputValue = filterValue.trim().toUpperCase();
        if (inputValue) return pi.trackingNumbers.map((tn) => tn.trackingNumber.toUpperCase()).includes(inputValue);
        else return pi;
    });

    if (!loading && invoices.length < 1) {
        if (invoicesWarning !== "true") alert(`ZPO-${params.purchaseOrderId} has no invoices.`);
        return <Redirect to={`/mobile/returns/selectreturnnumber`} />;
    }

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={primaryHeaderTitle}
                IncludeBackButton
                BackButtonText="< Return #"
                CustomBackButtonPath={`/mobile/returns/selectreturnnumber`}
                IncludeHomeButton
            />

            <SecondaryHeader Title="║▌Scan Tracking #" NextButtonHandler={() => void 0} />

            <div style={{ padding: "10px 10px 5px 10px", backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    onChange={(e) => setFilterValue(e.currentTarget.value)}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{ padding: 10, alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", width: "100%" }}
                    placeholder="Filter Tracking Numbers..."
                />
            </div>

            <MobileTable>
                <thead>
                    <tr>
                        <td>Invoice #</td>
                        <td>Tracking #</td>
                        <td>Arrived</td>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice, a) => {
                        return (
                            <tr
                                key={invoice.id}
                                id={invoice.id}
                                onClick={() => history.push(`/mobile/returns/${params.purchaseOrderId}/${invoice.id}/scanbarcode`)}
                            >
                                <td>{invoice.invoiceNumber}</td>
                                <td>{invoice.trackingNumbers.map((t) => t.trackingNumber).join(", ")}</td>
                                <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>
        </>
    );
};

export default SelectTrackingNumber;
