enum SaleStatus {
    Draft = "DRAFT", //0
    Authorized = "AUTHORIZED", //10
    Fulfilling = "FULFILLING", //20
    DropShipping = "DROP_SHIPPING", //30
    Shipped = "COMPLETED", //50
    Delivered = "DELIVERED", //55
    Void = "VOID", //60
}

export default SaleStatus;
