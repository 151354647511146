import { useState } from "react";
import { Nav } from "react-bootstrap";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageheader/PageHeader";
import { LinkContainer } from "react-router-bootstrap";
import ReturnSummary from "./ReturnSummary";
import ReturnEdit from "./ReturnEdit";
import ReturnActivityLog from "./ReturnActivityLog";
import { useQuery } from "@shane32/graphql";
//import ReturnRefund from "./ReturnRefund";
//import ReturnAttachments from "./ReturnAttachments";
import ReturnStatus from "../../../enums/ReturnStatus";

const returnQuery = `
query ($id: ID!) {
    return(id: $id) {
      status
      returnPurchaseOrder {
        billingAddress {
            fullName
        }
      }
    }
  }
`;

interface IReturnQueryVariables {
    id: string;
}

interface IReturnQueryResult {
    return: {
        returnPurchaseOrder: {
            billingAddress: {
                fullName: string | null;
            } | null;
        };
        status: ReturnStatus;
    };
}

interface IRouteParams {
    id: string;
}

const ReturnDetails = () => {
    const location = useLocation();
    const params = useParams<IRouteParams>();
    const [returnPageHeaderStatus, setReturnPageHeaderStatus] = useState<ReturnStatus | undefined>(undefined);

    const { data: returnData } = useQuery<IReturnQueryResult, IReturnQueryVariables>(returnQuery, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.id,
        },
    });

    return (
        <>
            <PageHeader badge={returnPageHeaderStatus || returnData?.return?.status}>{`RN-${params.id} - ${
                returnData?.return?.returnPurchaseOrder?.billingAddress?.fullName ?? ""
            }`}</PageHeader>
            <Nav variant="tabs" defaultActiveKey={location.pathname ?? "/returns"} className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/returns/${params.id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/returns/${params.id}/edit`} exact>
                        <Nav.Link>Edit</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                {/* <Nav.Item>
                    <LinkContainer to={`/returns/${params.id}/refund`} exact>
                        <Nav.Link>Refund</Nav.Link>
                    </LinkContainer>
                </Nav.Item> */}
                {/* <Nav.Item>
                    <LinkContainer to={`/returns/${params.id}/attachments`} exact>
                        <Nav.Link>Attachments</Nav.Link>
                    </LinkContainer>
                </Nav.Item> */}
                <Nav.Item>
                    <LinkContainer to={`/returns/${params.id}/activitylog`} exact>
                        <Nav.Link>Activity Log</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/returns/:id" exact component={ReturnSummary} />
                <Route path="/returns/:id/edit" exact component={() => ReturnEdit(setReturnPageHeaderStatus)} />
                {/* <Route path="/returns/:id/refund" exact component={ReturnRefund} />
                <Route path="/returns/:id/attachments" exact component={ReturnAttachments} /> */}
                <Route path="/returns/:id/activitylog" exact component={ReturnActivityLog} />
            </Switch>
        </>
    );
};

export default ReturnDetails;
