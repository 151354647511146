export interface IUpdateStockSubscriptionResponse {
    recalculateChannelStock: IUpdateStockSubscriptionBody;
}

export interface IBulkUpdateStockSubscriptionResponse {
    bulkRecalculateChannelStock: IUpdateStockSubscriptionBody;
}

export interface IUpdateSupplierStockSubscriptionResponse {
    importSupplierStock: IUpdateStockSubscriptionBody;
}

export interface IUpdateStockSubscriptionBody {
    queryDatabase: boolean;
    done: boolean;
    createdPayLoad: boolean;
    calculatedStockUpdates: boolean;
    wasError: boolean;
    errorMessage: string;
    listings: Array<{
        salesChannelSku: string;
        productId: number;
        prevQuantity: number;
        listingId: number;
        quantity: number;
        salesChannelId: number;
        warehouseId: number;
    }>;
    status: Array<{
        channelId: number;
        apiSuccess: boolean;
        dbSuccess: boolean;
        dbErrorMessage: string;
        apiErrorMessage: string;
        generalErrorMessage: string;
    }>;
    errorList: Array<{
        errorType: string;
        errorMessage: string;
        entityId: string;
    }>;
}

const fullResponse = `
queryDatabase
done
createdPayLoad
wasError
errorMessage
listings{
    salesChannelSku
    productId
    prevQuantity
    listingId
    quantity
    salesChannelId
    warehouseId
}
errorList{
    errorType
    errorMessage
    entityId
}
status{
    channelId
    apiSuccess
    dbSuccess
    dbErrorMessage
    apiErrorMessage
    generalErrorMessage
}`;

export const updateStockByExcelSubscription = `
subscription($base64: String!) {
    bulkRecalculateChannelStock(base64: $base64){
        ${fullResponse}
    }
}`;

export const updateStockByIdsSubscription = `
subscription($productIds: [Long!]){
    recalculateChannelStock(productIds: $productIds){
        ${fullResponse}
    }
}`;

export const updateSupplierStock = `
subscription($base64: String!) {
    importSupplierStock(base64: $base64){
        ${fullResponse}
    }
}`;
