import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import React, { useContext } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { IUpdateStockSubscriptionResponse, updateStockByIdsSubscription } from "../../../subscriptions/RecalculatingStock";

const ProductPricesQuery = `
query ($id: ID!) {
    product (id:$id) {
        id
        type
        sku
        unitOfMeasureId
        active
        description
        dropShipMode
        salesChannelPrices {
            salesChannelId
            price
            stockOnHand
            dropShipPrice
        }
    }
}`;

const SalesChannelQuery = `
{
    salesChannels {
        items {
            id
            name
            active
        }
    }
}`;

interface IUpdateStockSubscriptionVariables {
    productIds: number[] | null;
}

const ProductPricesMutation = `
mutation($original:ProductInput!, $modified: ProductInput!) {
  product {
    edit(original: $original, modified: $modified){
      id
      active
      description
      dropShipMode
      sku
      type
      unitOfMeasureId
      salesChannelPrices {
        price
        salesChannelId
        stockOnHand
        dropShipPrice
      }
    }
  }
}`;

interface IProductQueryResult {
    product: IProduct | null;
}

interface IEditResult {
    product: {
        edit: IProduct;
    };
}

interface IEditVariables {
    original: IProduct;
    modified: IProduct;
}

interface IProduct {
    id: string;
    type: string;
    sku: string;
    unitOfMeasureId: string;
    active: boolean;
    description: string;
    dropShipMode: string;
    salesChannelPrices: Array<ISalesChannelPrice>;
}

//query for salesChannel
interface ISalesChannelQueryResult {
    salesChannels: {
        items: Array<ISalesChannel>;
    };
}

interface ISalesChannel {
    id: string;
    name: string;
    active: boolean;
}

interface ISalesChannelPrice {
    salesChannelId: string;
    price: number;
    stockOnHand: number;
    dropShipPrice: number;
}

interface ISalesChannelPriceModel {
    salesChannelId: string;
    price: string;
    stockOnHand: string;
    dropShipPrice: string;
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: ISalesChannelPriceModel;
}

const hiddenModal: IModal = {
    show: false,
};

const ProductPrices = () => {
    //set useParams to get id for specific product
    const { id } = useParams<{ id: string }>();
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const [updatingStock, setUpdatingStock] = React.useState(false);
    const graphQLContext = useContext(GraphQLContext);
    //Product query to get data
    const { data, error, refetch } = useQuery<IProductQueryResult, { id: string }>(ProductPricesQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    //Sales Channel query to get data
    const {
        data: salesChannelData,
        error: salesChannelError,
        refetch: salesChannelRefetch,
    } = useQuery<ISalesChannelQueryResult>(SalesChannelQuery);
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(ProductPricesMutation);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (salesChannelError) return <ErrorDisplay onClick={salesChannelRefetch}>{salesChannelError.message}</ErrorDisplay>;
    if (!data || !salesChannelData) return <Loading />;
    if (!data.product) return <ErrorDisplay onClick={refetch}>Product ID #{id}</ErrorDisplay>;
    if (salesChannelData.salesChannels.items.length === 0)
        return <ErrorDisplay onClick={salesChannelRefetch}>Product ID #{id}</ErrorDisplay>;
    const product = data.product;

    //run when the edit button is pressed
    const onShowEditModal = (value: ISalesChannelPriceModel) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const updateStock = async () => {
        setUpdatingStock(true);

        await graphQLContext.client.ExecuteSubscription<IUpdateStockSubscriptionResponse, IUpdateStockSubscriptionVariables>(
            {
                query: updateStockByIdsSubscription,
                variables: {
                    productIds: [Number(product.id)],
                },
            },
            (data) => {
                var issues = data.errors;
                if (issues && issues.length > 0) {
                    alert(issues.map((x) => x.message).join(", "));
                }
            },
            () => {
                setUpdatingStock(false);
                console.log("its is done");
                refetch();
            }
        );
    };

    const runSave = (originalPrice: ISalesChannelPriceModel | null, modifiedPrice: ISalesChannelPriceModel | null) => {
        setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: product.id,
                    active: product.active,
                    description: product.description,
                    dropShipMode: product.dropShipMode,
                    sku: product.sku,
                    type: product.type,
                    unitOfMeasureId: product.unitOfMeasureId,
                    salesChannelPrices: originalPrice
                        ? [
                              {
                                  price: parseFloat(originalPrice.price),
                                  salesChannelId: originalPrice.salesChannelId,
                                  stockOnHand: parseFloat(originalPrice.stockOnHand),
                                  dropShipPrice: parseFloat(originalPrice.dropShipPrice),
                              },
                          ]
                        : [],
                },
                modified: {
                    id: product.id,
                    active: product.active,
                    description: product.description,
                    dropShipMode: product.dropShipMode,
                    sku: product.sku,
                    type: product.type,
                    unitOfMeasureId: product.unitOfMeasureId,
                    salesChannelPrices: modifiedPrice
                        ? [
                              {
                                  price: parseFloat(modifiedPrice.price),
                                  salesChannelId: modifiedPrice.salesChannelId,
                                  stockOnHand: parseFloat(modifiedPrice.stockOnHand),
                                  dropShipPrice: parseFloat(modifiedPrice.dropShipPrice),
                              },
                          ]
                        : [],
                },
            },
        }).then(
            (ret) => {
                setSaving(false);
                data.product = ret.data.product.edit;
                setModal(hiddenModal);
            },
            (err) => {
                setSaving(false);
                console.error("Error updating price", err);
                alert(err.message);
            }
        );
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: ISalesChannelPriceModel) => {
        runSave(modal.original || null, {
            price: modified.price,
            salesChannelId: modified.salesChannelId,
            stockOnHand: modified.stockOnHand,
            dropShipPrice: modified.dropShipPrice,
        });
    };

    //run when the delete button is pressed
    const onDelete = () => {
        if (!modal.original) return;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this price?")) return;
        runSave(modal.original, null);
    };

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: ISalesChannelPriceModel = modal.original
        ? {
              salesChannelId: modal.original.salesChannelId,
              price: modal.original.price + "",
              stockOnHand: modal.original.stockOnHand + "",
              dropShipPrice: modal.original.dropShipPrice + "",
          }
        : {
              salesChannelId: "",
              price: "",
              stockOnHand: "0",
              dropShipPrice: "",
          };

    return (
        <>
            <PageHeader>Prices</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new price
                </Button>
                <Button
                    variant="white"
                    className="ms-4"
                    title={"this will take a few moments"}
                    disabled={updatingStock}
                    onClick={updateStock}
                >
                    {updatingStock ? "processing" : "Recalculate and Update channel stock"}
                </Button>
            </p>
            <Card className="border-primary" style={{ maxWidth: 500 }}>
                <Card.Header className="bg-primary text-white">Prices</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Sales Channel</th>
                                <th>Stock Price</th>
                                <th>DropShip Price</th>
                                <th>Stock</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data!.product!.salesChannelPrices.map((salesChannelPrice, index) => {
                                const salesChannel = salesChannelData.salesChannels.items.find(
                                    (x) => x.id === salesChannelPrice.salesChannelId
                                );
                                if (salesChannel) {
                                    return (
                                        <tr key={salesChannel.id}>
                                            <td>{salesChannel.name}</td>
                                            <td>{salesChannelPrice.price}</td>
                                            <td>{salesChannelPrice.dropShipPrice}</td>
                                            <td>{salesChannelPrice.stockOnHand}</td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    variant="white"
                                                    className="ms-4"
                                                    style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                    onClick={() =>
                                                        onShowEditModal({
                                                            price: salesChannelPrice.price.toString(),
                                                            salesChannelId: salesChannel.id,
                                                            stockOnHand: salesChannelPrice.stockOnHand.toString(),
                                                            dropShipPrice: salesChannelPrice.dropShipPrice.toString(),
                                                        })
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} Price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicSalesChannelId">
                                    <VLabel valueName="salesChannelId">Sales Channel</VLabel>
                                    <VSelect valueName="salesChannelId" required>
                                        <option value=""></option>
                                        {salesChannelData.salesChannels.items.map((salesChannel) => (
                                            <option key={salesChannel.id} value={salesChannel.id}>
                                                {salesChannel.name}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicPrice">
                                    <VLabel valueName="price">Stock Price</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" valueName="price" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicDropShipPrice">
                                    <VLabel valueName="dropShipPrice">DropShip Price</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" valueName="dropShipPrice" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicStockOnHand">
                                    <VLabel valueName="stockOnHand">Stock</VLabel>
                                    <VControl type="text" required pattern="\d+\.?\d*" valueName="stockOnHand" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default ProductPrices;
