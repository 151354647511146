/**
 * Used to forge the new set of url search parameters. Ensures that the key
 * remains the same, while the user only needs to change the value.
 * Undefined values will be removed from the search params, as that is
 * the default behavior of the JSON.stringify function. Null values will
 * still be included in the search params
 * @param urlSearchParams
 */
const forgeURLSearchParams = <T>(urlSearchParams: T) =>
    new URLSearchParams({ urlsp: encodeURIComponent(JSON.stringify(urlSearchParams)) }).toString();
export default forgeURLSearchParams;
