import * as React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VButton, VCheck, VControl, VForm, VLabel } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IQueryResult {
    labelProviders: {
        items: Array<ILabelProvider>;
    };
}

interface ILabelProvider {
    id: string;
    name: string;
    isDefault: boolean;
}

interface ILabelProviderModel {
    id: string;
    name: string;
    isDefault: boolean;
}

interface IEditResult {
    labelProvider: {
        edit: ILabelProvider;
    };
}

interface IEditVariables {
    original: ILabelProvider;
    modified: ILabelProvider;
}

interface IAddResult {
    labelProvider: {
        add: ILabelProvider;
    };
}

interface IAddVariables {
    value: ILabelProvider;
}

interface IDeleteResult {
    labelProvider: {
        delete: string;
    };
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: ILabelProvider;
}

const hiddenModal: IModal = {
    show: false,
};

const EditLabelProviderMutation = `
mutation ($original: LabelProviderInput!, $modified: LabelProviderInput!) {
  labelProvider {
    edit(original: $original, modified: $modified) {
      id
      name
      isDefault
    }
  }
}
`;

const AddLabelProviderMutation = `
mutation ($value: LabelProviderInput!) {
  labelProvider {
    add(value: $value) {
      id
      name
      isDefault
    }
  }
}
`;

const DeleteLabelProviderMutation = `
mutation ($id: ID!) {
  labelProvider {
    delete(id: $id)
  }
}
`;

const LabelProviderQuery = `
{
  labelProviders{
    items{
      id
      name
      isDefault
    }
  }
}
`;

const LabelProviderIndex = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    //=== set up queries and mutations ===
    //Shipping Carrier list
    const { data, error, refetch } = useQuery<IQueryResult, {}>(LabelProviderQuery, { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditLabelProviderMutation);
    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddLabelProviderMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>(DeleteLabelProviderMutation);
    const history = useHistory();
    const navTo = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        if (e.currentTarget === e.target) history.push("/setup/labelproviders/" + encodeURIComponent(id));
    };

    //=== display page ===
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: ILabelProviderModel) => {
        if (!modal.original) {
            //=== ADD ===
            //disable form controls
            setSaving(true);
            //start add operation via graphql mutation
            runAdd({
                variables: {
                    value: {
                        id: "0", //ignored, but required
                        name: modified.name,
                        isDefault: modified.isDefault,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //add the entry to the local list
                    var newValue = ret.data.labelProvider.add;
                    data.labelProviders.items.push(newValue);
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error Adding Label Provider", err);
                    //display the error message
                    alert(err.message);
                }
            );
        } else {
            //=== EDIT ===
            //disable form controls
            setSaving(true);
            //start edit operation via graphql mutation
            runEdit({
                variables: {
                    //pass in original data
                    original: modal.original,
                    //pass in modified data
                    modified: {
                        id: modal.original.id,
                        name: modified.name,
                        isDefault: modified.isDefault,
                    },
                },
            }).then(
                //success
                (ret) => {
                    //update the local list with the modified entry
                    const newValue = ret.data.labelProvider.edit;
                    const oldIndex = data.labelProviders.items.findIndex((x) => x.id === newValue.id);
                    if (oldIndex >= 0) data.labelProviders.items[oldIndex] = newValue;
                    //enable form controls and hide the modal
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error Editing Label Provider", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    //run when the delete button is pressed
    const onDelete = () => {
        //=== DELETE ===
        const id = modal.original!.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this Label Provider?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //delete the entry from the local list
                const oldIndex = data.labelProviders.items.findIndex((x) => x.id === id);
                if (oldIndex >= 0) {
                    data.labelProviders.items.splice(oldIndex, 1);
                }
                //enable form controls and hide the modal
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting Label Provider", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    //=== set up any other variables needed for page render ===
    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: ILabelProviderModel = modal.original
        ? {
              id: modal.original.id,
              name: modal.original.name,
              isDefault: modal.original.isDefault,
          }
        : {
              id: "0",
              name: "",
              isDefault: false,
          };

    //this code displays a card and is nearly the same for active and inactive
    const card = (active: boolean) => {
        // search data where active or inactive, as appropriate
        // sort by sortorder, then name -- see function defined above
        return (
            <Card className="border-primary" style={{ maxWidth: 350 }}>
                {/* set card header appropriately */}
                <Card.Header className="bg-primary text-white">Label Providers</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Label Provider</th>
                                <th>Default</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.labelProviders.items.map((labelProvider) => (
                                <tr key={labelProvider.id}>
                                    <td style={{ cursor: "pointer" }} onClick={(e) => navTo(e, labelProvider.id)}>
                                        <Link to={`/setup/labelproviders/${labelProvider.id}`}>{labelProvider.name}</Link>
                                    </td>
                                    <td>{labelProvider.isDefault ? "★" : ""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    //render
    return (
        <>
            <PageHeader>Label Providers</PageHeader>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add label provider
                </Button>
            </p>
            {card(true)}
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>Add label provider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <VLabel valueName="name">Name</VLabel>
                                    {/* use 'required' and 'pattern' as necessary to ensure proper validation */}
                                    {/* use 'autocomplete=off' to disable autofill */}
                                    {/* set 'disabled' while saving */}
                                    <VControl type="text" required valueName="name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4">
                                    <VCheck valueName="isDefault" label="Default" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default LabelProviderIndex;
