import { useEffect, useState } from "react";
import PurchaseOrderStatus from "../../../../enums/PurchaseOrderStatus";
import { GraphQLError, useMutation } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import MobileButton from "../../components/buttons/MobileButton";
import MobileTable from "../../components/tables/MobileTable";
import GroupBy from "../../../../helpers/GroupBy";
import { useHistory } from "react-router-dom";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";

const purchaseOrdersQuery = `
query ($statuses: [PurchaseOrderStatus!], $first: Int, $after: String) {
    purchaseOrders(statuses: $statuses, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      items {
        id
        supplier {
          id
          name
        }
      }
    }
  }
`;

interface IPurchaseOrder {
    id: string;
    supplier: {
        id: string;
        name: string;
    };
}

interface IPurchaseOrdersQueryVariables {
    statuses: Array<PurchaseOrderStatus>;
    first: number;
    after: string | null;
}

interface IPurchaseOrdersQueryResult {
    purchaseOrders: {
        pageInfo: {
            hasNextPage: boolean;
            endCursor: string | null;
        };
        items: Array<IPurchaseOrder>;
    };
}

interface IPurchaseOrdersState {
    items: Array<IPurchaseOrder>;
    loading: boolean;
    error: string | null;
}

const SelectSupplier = () => {
    const history = useHistory();

    const [purchaseOrdersState, setPurchaseOrdersState] = useState<IPurchaseOrdersState>({
        items: [],
        loading: false,
        error: null,
    });

    const [runPurchaseOrdersQuery] = useMutation<IPurchaseOrdersQueryResult, IPurchaseOrdersQueryVariables>(purchaseOrdersQuery);

    useEffect(() => {
        (async () => {
            try {
                setPurchaseOrdersState({ ...purchaseOrdersState, loading: true });
                let hasNextPage = false;
                let endCursor: string | null = null;
                let items: IPurchaseOrder[] = [];
                do {
                    const result: IPurchaseOrdersQueryResult | null | undefined = (
                        await runPurchaseOrdersQuery({
                            variables: {
                                statuses: [PurchaseOrderStatus.Invoiced, PurchaseOrderStatus.Receiving],
                                first: 500,
                                after: endCursor,
                            },
                        })
                    ).data;
                    hasNextPage = result.purchaseOrders.pageInfo.hasNextPage;
                    endCursor = result.purchaseOrders.pageInfo.endCursor;
                    items = [...items, ...result.purchaseOrders.items];
                } while (hasNextPage);
                purchaseOrdersState.items = items;
            } catch (error: any) {
                setPurchaseOrdersState({
                    ...purchaseOrdersState,
                    error: (error as GraphQLError)?.message ?? (error as Error)?.message ?? "An unknown error has occured",
                });
            } finally {
                setPurchaseOrdersState({ ...purchaseOrdersState, loading: false });
            }
        })();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const purchaseOrdersBySupplierId = Array.from(GroupBy(purchaseOrdersState.items, (x) => x.supplier.id))
        .sort((a, b) => a[1].find((x) => x)?.supplier?.name?.localeCompare(b[1].find((x) => x)?.supplier?.name ?? "") ?? 0)
        .map(([supplierId, purchaseOrders]) => {
            return {
                id: supplierId,
                name: purchaseOrders.find((x) => x)?.supplier?.name ?? "N/A",
            };
        });

    return (
        <>
            {purchaseOrdersState.loading && <MobileLoading fullscreen />}
            {purchaseOrdersState.error && (
                <div>
                    {purchaseOrdersState.error}
                    <MobileButton onClick={() => window.location.reload()}>Reload</MobileButton>
                </div>
            )}

            <PrimaryHeader
                Title={`Supplier Shipments`}
                IncludeBackButton
                BackButtonText="< Roles"
                CustomBackButtonPath={`/mobile/selectrole`}
                IncludeHomeButton
            />

            <SecondaryHeader Title="Select Supplier" NextButtonHandler={() => void 0} />

            <MobileTable ignoreSpacing>
                <thead>
                    <tr>
                        <td>Supplier</td>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrdersBySupplierId.map((s) => (
                        <tr
                            key={s.id}
                            onClick={() => {
                                const urlSearchParams = new URLSearchParams([["supplierId", s.id]]);
                                history.push(`/mobile/intake/selectpurchaseorder?${urlSearchParams.toString()}`);
                            }}
                        >
                            <td>{s.name}</td>
                        </tr>
                    ))}
                </tbody>
            </MobileTable>
        </>
    );
};

export default SelectSupplier;
