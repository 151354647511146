import { Route, Switch, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import SalesSummary from "./SalesSummary";
import SalesEdit from "./SalesEdit";
import SalesAssociatedOrders from "./SalesAssociatedOrders";
import SalesActivityLog from "./SalesActivityLog";
import PageHeader from "../../../components/pageheader/PageHeader";
import { useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import SalesReturns from "./SalesReturns";
import SalesAttachments from "./SalesAttachments";

//Sales Interface
interface ISaleQueryResult {
    sale: ISale | null;
}

interface ISale {
    id: string;
    status: string;
    shippingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
    } | null;
}

const SalesDetails = () => {
    //UseParams to grab ID for the page
    const { id } = useParams<{ id: string }>();

    //Sales Query
    const { data, error, refetch } = useQuery<ISaleQueryResult, { id: string }>(
        "query($id: ID!){ sale (id:$id) { id status shippingAddress{ firstName lastName id }}}",
        { variables: { id: id }, fetchPolicy: "no-cache" }
    );

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    //setting the data to variable and checking if Id is there or not
    const sale = data.sale;
    if (!sale) return <ErrorDisplay onClick={refetch}>Sale Id #{id} not found.</ErrorDisplay>;

    /*
    Set badge to Enum status
    Set the top header to salesId and shippingAddress First and last name based on the same ID
    */
    return (
        <>
            <PageHeader badge={`${sale.status}`}>{`SO-${sale.id} - ${sale.shippingAddress?.firstName || ""} ${
                sale.shippingAddress?.lastName || ""
            }`}</PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}/edit`} exact>
                        <Nav.Link>Edit</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}/associatedorders`} exact>
                        <Nav.Link>Associated Orders</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}/returns`} exact>
                        <Nav.Link>Returns</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}/attachments`} exact>
                        <Nav.Link>Attachments</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/sales/${sale.id}/log`} exact>
                        <Nav.Link>Activity Log</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/sales/:id" exact component={SalesSummary} />
                <Route path="/sales/:id/edit" exact component={SalesEdit} />
                <Route path="/sales/:id/associatedorders" exact component={SalesAssociatedOrders} />
                <Route path="/sales/:id/returns" exact component={SalesReturns} />
                <Route path="/sales/:id/attachments" exact component={SalesAttachments} />
                <Route path="/sales/:id/log" exact component={SalesActivityLog} />
            </Switch>
        </>
    );
};

export default SalesDetails;
