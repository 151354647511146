/** If no "productId" is supplied to this mutation, a new product will
 * be created with a random GUID and attached to the purchase invoice. */
export const addProductToPurchaseInvoiceMutation = `
mutation ($purchaseInvoiceId: ID!, $productId: ID) {
  purchaseInvoice {
    addProductToInvoice(purchaseInvoiceId: $purchaseInvoiceId, productId: $productId)
  }
}
`;

export interface IAddProductToPurchaseInvoiceMutationVariables {
    purchaseInvoiceId: string;
    /** If this value is undefined, a new product will be created with
     * a random GUID and attached to the purchase invoice. */
    productId?: string;
}

export interface IAddProductToPurchaseInvoiceMutationResponse {
    purchaseInvoice: {
        /** The "number" represents the id of the product that was added to the purchase invoice. */
        addProductToInvoice: number;
    };
}
