import { Route, Switch, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import ProductSummary from "./ProductSummary";
import ProductEdit from "./ProductEdit";
import ProductInventory from "./ProductInventory";
import ProductActivityLog from "./ProductActivityLog";
import ProductMovements from "./ProductMovements";
import PageHeader from "../../../components/pageheader/PageHeader";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";
import ProductPrices from "./ProductPrices";

//Product Interface
interface IProductQueryResult {
    product: IProduct | null;
}

interface IProduct {
    id: string;
    sku: string;
}

const ProductDetails = () => {
    //Product Query
    const { id } = useParams<{ id: string }>();

    const { data, error, refetch } = useQuery<IProductQueryResult, { id: string }>("query($id: ID!){ product (id:$id) { id sku } }", {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;

    const product = data.product;

    if (!product) return <ErrorDisplay onClick={refetch}>Product Id #{id} not found.</ErrorDisplay>;

    return (
        <>
            <PageHeader>{product.sku}</PageHeader>
            <Nav variant="tabs" defaultActiveKey="/home" className="mb-3">
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}`} exact>
                        <Nav.Link>Summary</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}/edit`} exact>
                        <Nav.Link>Edit</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}/inventory`} exact>
                        <Nav.Link>Inventory</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}/log`} exact>
                        <Nav.Link>Activity Log</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}/movements`} exact>
                        <Nav.Link>Movements</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`/products/${product.id}/prices`} exact>
                        <Nav.Link>Prices</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path="/products/:id" exact component={ProductSummary} />
                <Route path="/products/:id/edit" exact component={ProductEdit} />
                <Route path="/products/:id/inventory" exact component={ProductInventory} />
                <Route path="/products/:id/log" exact component={ProductActivityLog} />
                <Route path="/products/:id/movements" exact component={ProductMovements} />
                <Route path="/products/:id/prices" exact component={ProductPrices} />
            </Switch>
        </>
    );
};

export default ProductDetails;
