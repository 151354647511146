import { useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useRef } from "react";
import { Button, Form } from "react-bootstrap";

interface IAttachment {
    id: string;
}

interface ISaleAttachmentUploadMutationVariables {
    saleId?: string;
    purchaseOrderId?: string;
    purchaseInvoiceId?: string;
    productId?: string;
    saleFulfillmentId?: string;
    data: string;
    filename: string;
    mediaType: string;
    isLabel: boolean;
}

interface IShippingAndCartonLabelAttachmentUploadMutationVariables {
    cartonSplitData: string;
    shippingLabelData: string;
    cartonLabelData: string;
    saleId: string;
    shippingServiceId: string;
}

interface IFbaShippingInformationUploadMutationResponse<T> {
    attachments: {
        uploadAmazonFbaLabels: Array<IAttachment>;
    };
    query: T;
}

interface IFbaShippingInformationUploadMutationVariables {
    saleId: string;
    shippingServiceId: string;
    data: string;
}

interface ISaleAttachmentUploadMutationResponse<T> {
    attachments: {
        upload: IAttachment;
    };
    query: T;
}

interface IVendorLabelAttachmentUploadMutationResponse<T> {
    attachments: {
        uploadAmazonVendorLabels: boolean;
    };
    query: T;
}

type TArguments = {
    saleId?: string;
    purchaseOrderId?: string;
    purchaseInvoiceId?: string;
    productId?: string;
    saleFulfillmentId?: string;
} & (
    | { saleId: string }
    | { purchaseOrderId: string }
    | { purchaseInvoiceId: string }
    | { productId: string }
    | { saleFulfillmentId: string }
);

const shippingServicesQuery = `
query {
    shippingServices {
      items {
        id
        name
        active
        shippingCarrier {
          name
        }
      }
    }
  }
`;

interface IShippingServicesQueryResult {
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
}

/**
 * A hook used to upload attachments to many different types of entities in Hive.
 * @type \<T\> - The type of the data returned by the `postAttachmentUploadQuery` argument.
 * @param props Used to specify the entity to which the attachment will be uploaded. At least one of the following properties must be specified: saleId, purchaseOrderId, purchaseInvoiceId, productId, saleFulfillmentId.
 * @param postAttachmentUploadQuery The query to run after the attachment has been uploaded. All necessary data must be set in the query before hand (i.e. the query must be ready to run as is.).
 * @returns
 */
const useAttachmentUploader = <T,>(props: TArguments, postAttachmentUploadQuery: string) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const shippingServiceInputRef = useRef<HTMLSelectElement>(null);
    const shippingLabelFileInputRef = useRef<HTMLInputElement>(null);
    const cartonSplitFileInputRef = useRef<HTMLInputElement>(null);
    const cartonLabelFileInputRef = useRef<HTMLInputElement>(null);
    const isLabelInputRef = useRef<HTMLInputElement>(null);
    const shippingServiceFbaInputRef = useRef<HTMLSelectElement>(null);
    const shippingLabelFileFbaInputRef = useRef<HTMLInputElement>(null);
    const [postAttachmentUploadQueryResult, setPostAttachmentUploadQueryResult] = useState<T>();
    const [isUploading, setIsUploading] = useState(false);
    const [doesFileInputContainFiles, setDoesFileInputContainFiles] = useState(false);
    const [doesCartonSplitInputContainFiles, setDoesCartonSplitInputContainFiles] = useState(false);
    const [doesShippingFileInputContainFiles, setDoesShippingFileInputContainFiles] = useState(false);
    const [doesFbaShippingFileInputContainFiles, setDoesFbaShippingFileInputContainFiles] = useState(false);
    const [doesFbaShippingServiceInputContainValue, setDoesFbaShippingServiceInputContainValue] = useState(false);
    const [doesCartonLabelInputContainFiles, setDoesCartonLabelInputContainFiles] = useState(false);
    const [doesShippingServiceInputContainValue, setDoesShippingServiceInputContainValue] = useState(false);

    const { data: shippingServicesData } = useQuery<IShippingServicesQueryResult>(shippingServicesQuery, {
        fetchPolicy: "no-cache",
    });
    const [runSaleAttachmentUploadMutation] = useMutation<ISaleAttachmentUploadMutationResponse<T>, ISaleAttachmentUploadMutationVariables>(
        `mutation (
            $saleId: ID,
            $purchaseOrderId: ID,
            $purchaseInvoiceId: ID,
            $product: ID,
            $saleFulfillmentId: ID,
            $data: String!, 
            $filename: String!, 
            $mediaType: String!, 
            $isLabel: Boolean!
          ) {
            attachments {
              upload(
                saleId: $saleId,
                purchaseOrderId: $purchaseOrderId,
                purchaseInvoiceId: $purchaseInvoiceId,
                productId: $product,
                saleFulfillmentId: $saleFulfillmentId,
                data: $data
                filename: $filename
                mediaType: $mediaType
                isLabel: $isLabel
              ) {
                id
              }
            }
            ${
                postAttachmentUploadQuery &&
                `
                query {
                    ${postAttachmentUploadQuery}
                }
            `
            }
          }`
    );

    const [runShippingAndCartonLabelUploadMutation] = useMutation<
        IVendorLabelAttachmentUploadMutationResponse<T>,
        IShippingAndCartonLabelAttachmentUploadMutationVariables
    >(
        `mutation (
            $saleId: ID!,
            $shippingServiceId: ID!,
            $cartonSplitData: String!, 
            $shippingLabelData: String!, 
            $cartonLabelData: String!,
          ) {
            attachments {
              uploadAmazonVendorLabels(
                saleId: $saleId,
                shippingServiceId: $shippingServiceId,
                cartonSplitData: $cartonSplitData,
                shippingLabelData: $shippingLabelData,
                cartonLabelData: $cartonLabelData,
              )
            }
            ${
                postAttachmentUploadQuery &&
                `
                query {
                    ${postAttachmentUploadQuery}
                }
            `
            }
          }`
    );

    const [runFbaShippingInformationUploadMutation] = useMutation<
        IFbaShippingInformationUploadMutationResponse<T>,
        IFbaShippingInformationUploadMutationVariables
    >(
        `mutation (
        $saleId: ID!,
        $shippingServiceId: ID!,
        $data: String!, 
      ) {
        attachments {
          uploadAmazonFbaLabels(
            saleId: $saleId,
            shippingServiceId: $shippingServiceId,
            data: $data,
          ) {
            id
          }
        }
        ${
            postAttachmentUploadQuery &&
            `
            query {
                ${postAttachmentUploadQuery}
            }
        `
        }
      }`
    );

    const onChangeFileInputElement = () => setDoesFileInputContainFiles(!!fileInputRef.current?.files?.length);

    const onChangeShippingFileInputElement = () => setDoesShippingFileInputContainFiles(!!shippingLabelFileInputRef.current?.files?.length);
    const onChangeCartonLabelFileInputElement = () => setDoesCartonLabelInputContainFiles(!!cartonLabelFileInputRef.current?.files?.length);
    const onChangeCartonSplitFileInputElement = () => setDoesCartonSplitInputContainFiles(!!cartonSplitFileInputRef.current?.files?.length);
    const onChangeShippingServiceInputElement = () =>
        setDoesShippingServiceInputContainValue(shippingServiceInputRef.current?.value !== "");
    const onChangeFbaShippingFileInputElement = () =>
        setDoesFbaShippingFileInputContainFiles(!!shippingLabelFileFbaInputRef.current?.files?.length);
    const onChangeFbaShippingServiceInputElement = () =>
        setDoesFbaShippingServiceInputContainValue(shippingServiceFbaInputRef.current?.value !== "");

    const uploadAttachment = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!fileInputRef.current?.files?.length) return;

        const isLabelInputRefChecked = isLabelInputRef.current?.checked;
        if (isLabelInputRefChecked === undefined || isLabelInputRefChecked === null)
            return alert("Could not determine state of 'Is Label' checkbox.");

        const file = fileInputRef.current.files[0];
        const encoded = await readToText(file);
        setIsUploading(true);
        runSaleAttachmentUploadMutation({
            variables: {
                saleId: props.saleId,
                purchaseOrderId: props.purchaseOrderId,
                purchaseInvoiceId: props.purchaseInvoiceId,
                productId: props.productId,
                saleFulfillmentId: props.saleFulfillmentId,
                data: encoded,
                filename: file.name,
                mediaType: file.type,
                isLabel: isLabelInputRef.current!.checked,
            },
        }).then(
            (result) => {
                //reset file input ref
                fileInputRef.current!.value = "";
                setDoesFileInputContainFiles(false);
                setPostAttachmentUploadQueryResult(result.data.query);
                setIsUploading(false);
            },
            (error: GraphQLError) => {
                alert(`Failure: ${error.message}`);
                setIsUploading(false);
            }
        );
    };

    const encode = (txt: string) => {
        let encoded = txt.replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) encoded += "=".repeat(4 - (encoded.length % 4));
        return encoded;
    };

    const readToText = async (file: File) => {
        const temporaryFileReader = new FileReader();
        temporaryFileReader.readAsDataURL(file);
        return new Promise<string>((resolve, reject) => {
            temporaryFileReader.onload = (ev: ProgressEvent<FileReader>) => {
                resolve(encode(ev.target?.result as string));
            };
        });
    };

    const uploadShippingAndCartonLabelAttachment = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !cartonSplitFileInputRef.current?.files?.length ||
            !shippingLabelFileInputRef.current?.files?.length ||
            !cartonLabelFileInputRef.current?.files?.length ||
            shippingServiceInputRef.current?.value === ""
        )
            return;
        if (!props.saleId) {
            alert("No sale id found. Required for uploading shipping and carton labels.");
            return;
        }
        const shippingLabelFile = shippingLabelFileInputRef.current.files[0];
        const cartonLabelFile = cartonLabelFileInputRef.current.files[0];
        const cartonSplitFile = cartonSplitFileInputRef.current.files[0];
        const shippingServiceId = shippingServiceInputRef.current?.value!;

        let cartonSplitEncoded = await readToText(cartonSplitFile);
        let shippingLabelEncoded = await readToText(shippingLabelFile);
        let cartonLabelEncoded = await readToText(cartonLabelFile);

        setIsUploading(true);
        runShippingAndCartonLabelUploadMutation({
            variables: {
                saleId: props.saleId,
                cartonSplitData: cartonSplitEncoded,
                shippingLabelData: shippingLabelEncoded,
                cartonLabelData: cartonLabelEncoded,
                shippingServiceId: shippingServiceId,
            },
        }).then(
            (result) => {
                //reset file input ref
                shippingLabelFileInputRef.current!.value = "";
                cartonSplitFileInputRef.current!.value = "";
                cartonLabelFileInputRef.current!.value = "";
                shippingServiceInputRef.current!.value = "";
                setDoesCartonSplitInputContainFiles(false);
                setDoesCartonLabelInputContainFiles(false);
                setDoesShippingFileInputContainFiles(false);
                setDoesShippingServiceInputContainValue(false);
                console.log(result.data.query);
                setPostAttachmentUploadQueryResult(result.data.query);
                setIsUploading(false);
            },
            (error: GraphQLError) => {
                alert(`Failure: ${error.message}`);
                setIsUploading(false);
            }
        );
    };

    const uploadFbaShippingAttachment = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!shippingLabelFileFbaInputRef.current?.files?.length || shippingServiceFbaInputRef.current?.value === "") return;
        if (!props.saleId) {
            alert("No sale id found. Required for uploading fba shipping labels");
            return;
        }
        const shippingLabelFile = shippingLabelFileFbaInputRef.current.files[0];
        const shippingServiceId = shippingServiceFbaInputRef.current?.value!;

        let shippingLabelEncoded = await readToText(shippingLabelFile);

        setIsUploading(true);
        runFbaShippingInformationUploadMutation({
            variables: {
                saleId: props.saleId,
                data: shippingLabelEncoded,
                shippingServiceId: shippingServiceId,
            },
        }).then(
            (result) => {
                //reset file input ref
                shippingLabelFileFbaInputRef.current!.value = "";
                shippingServiceFbaInputRef.current!.value = "";
                setDoesFbaShippingFileInputContainFiles(false);
                setDoesFbaShippingServiceInputContainValue(false);
                setPostAttachmentUploadQueryResult(result.data.query);
                setIsUploading(false);
            },
            (error: GraphQLError) => {
                alert(`Failure: ${error.message}`);
                setIsUploading(false);
            }
        );
    };

    const attachmentUploaderElement = (
        <form onSubmit={async (e) => uploadAttachment(e)} className="mb-4">
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "minmax(auto, 500px) 50px minmax(0px, 75px)",
                    gridTemplateRows: "1fr 1fr",
                    gap: "0 15px",
                    gridTemplateAreas: `
                    ". . .",
                    ". . ."
                `,
                }}
            >
                <Form.Label>Upload Attachment</Form.Label>
                <Form.Label>Is Label</Form.Label>
                <div />
                <Form.Control disabled={isUploading} ref={fileInputRef} onChange={onChangeFileInputElement} type="file" />
                <Form.Check disabled={isUploading} ref={isLabelInputRef} />
                <Button disabled={isUploading || !doesFileInputContainFiles} type="submit" variant="primary">
                    Upload
                </Button>
            </div>
        </form>
    );

    const amazonVendorShippingInformationUploaderElement = (
        <>
            <Form.Label>Upload Shipping Attachments</Form.Label>
            <form onSubmit={async (e) => uploadShippingAndCartonLabelAttachment(e)} className="mb-4">
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "minmax(auto, 177.5px) minmax(auto, 177.5px) minmax(auto, 177.5px) minmax(auto, 177.5px)",
                        gridTemplateRows: "1fr 1fr",
                        gap: "0 15px",
                        gridTemplateAreas: `
                    ". . . .",
                    ". . . .",
                `,
                    }}
                >
                    <Form.Label>Carton Splits</Form.Label>
                    <Form.Label>Shipping Service</Form.Label>
                    <Form.Label>Shipping Labels</Form.Label>
                    <Form.Label>Carton Labels</Form.Label>
                    <Form.Control
                        disabled={isUploading}
                        ref={cartonSplitFileInputRef}
                        onChange={onChangeCartonSplitFileInputElement}
                        type="file"
                    />
                    <Form.Select disabled={isUploading} ref={shippingServiceInputRef} onChange={onChangeShippingServiceInputElement}>
                        <option value=""></option>
                        {shippingServicesData?.shippingServices.items
                            .filter((x) => x.active)
                            .map((shippingService) => (
                                <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                    {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                </option>
                            ))}
                    </Form.Select>
                    <Form.Control
                        disabled={isUploading}
                        ref={shippingLabelFileInputRef}
                        onChange={onChangeShippingFileInputElement}
                        type="file"
                    />
                    <Form.Control
                        disabled={isUploading}
                        ref={cartonLabelFileInputRef}
                        onChange={onChangeCartonLabelFileInputElement}
                        type="file"
                    />
                </div>
                <Button
                    disabled={
                        isUploading ||
                        !doesCartonSplitInputContainFiles ||
                        !doesShippingFileInputContainFiles ||
                        !doesCartonLabelInputContainFiles ||
                        !doesShippingServiceInputContainValue
                    }
                    type="submit"
                    variant="primary"
                    className="mt-2"
                >
                    Upload
                </Button>
            </form>
        </>
    );

    const amazonFbaShippingInformationUploaderElement = (
        <>
            <Form.Label>Upload Shipping Attachments</Form.Label>
            <form onSubmit={async (e) => uploadFbaShippingAttachment(e)} className="mb-4">
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "minmax(auto, 177.5px) minmax(auto, 177.5px)",
                        gridTemplateRows: "1fr 1fr",
                        gap: "0 15px",
                        gridTemplateAreas: `
                    ". . . .",
                    ". . . .",
                `,
                    }}
                >
                    <Form.Label>Shipping Service</Form.Label>
                    <Form.Label>Shipping Labels</Form.Label>
                    <Form.Select disabled={isUploading} ref={shippingServiceFbaInputRef} onChange={onChangeFbaShippingServiceInputElement}>
                        <option value=""></option>
                        {shippingServicesData?.shippingServices.items
                            .filter((x) => x.active)
                            .map((shippingService) => (
                                <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                    {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                </option>
                            ))}
                    </Form.Select>
                    <Form.Control
                        disabled={isUploading}
                        ref={shippingLabelFileFbaInputRef}
                        onChange={onChangeFbaShippingFileInputElement}
                        type="file"
                    />
                </div>
                <Button
                    disabled={isUploading || !doesFbaShippingFileInputContainFiles || !doesFbaShippingServiceInputContainValue}
                    type="submit"
                    variant="primary"
                    className="mt-2"
                >
                    Upload
                </Button>
            </form>
        </>
    );

    return {
        isUploading,
        shippingServicesData,
        attachmentUploaderElement,
        amazonVendorShippingInformationUploaderElement,
        amazonFbaShippingInformationUploaderElement,
        postAttachmentUploadQueryResult,
    } as const;
};

export default useAttachmentUploader;
