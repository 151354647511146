import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import GroupBy from "../../../../helpers/GroupBy";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import SessionContext from "../../contexts/SessionContext";
import useConfirm from "../../hooks/useConfirm";
import MobileLoading from "../../components/MobileLoading";
import { Redirect, useParams } from "react-router-dom";
import useScanner from "../../hooks/useScanner";
import { IDisplayPicksModel, IScanBinQueryResult, IScanBinQueryVariables } from "./ScanBinController";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButton from "../../components/buttons/MobileButton";
import MobileTable from "../../components/tables/MobileTable";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import scanBinQueryBase from "./scanBinQueryBase";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import ModelType from "../../../../enums/ModelType";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import SelectionType from "../../../../enums/SelectionType";
import useLostSkuModal from "../../hooks/useLostSkuModal";
import { Modal } from "react-bootstrap";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import { useActionBarContext } from "../../contexts/ActionBarContext/ActionBarContext";
import { IFilters } from "./SelectList";

const locationQueryFields = `
products {
    stockOnHand
    product {
      id
      sku
    }
  }
`;

const locationQueryBase = `
  location(id: $locationId) {
    ${locationQueryFields}
  }
`;

const locationQuery = `
query ($locationId: ID!) {
  ${locationQueryBase}
}
`;

interface ILocationQueryResult {
    location: {
        products: Array<{
            stockOnHand: number;
            product: {
                id: string;
                sku: string;
            };
        }>;
    };
}

interface ILocationQueryVariables {
    locationId: string;
}

const moveProductToBinMutation = `
mutation ($moveProductToBin: [MoveProductToBin!]!, $warehouseId: ID!, $displayPicksFiltersInput: DisplayPicksFiltersInput!, $displayPicks: [DisplayPicksInput!]!, $pickZoneId: ID!, $locationId: ID!) {
  inventory {
    moveProductsToBin(moveProductsToBin: $moveProductToBin)
  }
  query {
    ${scanBinQueryBase}
    ${locationQueryBase}
  }
}
`;

interface IMoveProductToBin {
    fromLocationId: string;
    toLocationId: string;
    productId: string;
    saleId: string;
    quantity: number;
    productSelectionType: SelectionType;
    fromLocationSelectionType: SelectionType;
    toLocationSelectionType: SelectionType;
}

interface IMoveProductsToBinMutationVariables extends IScanBinQueryVariables, ILocationQueryVariables {
    moveProductToBin: IMoveProductToBin[];
}

interface IMoveProductsToBinMutationResult {
    inventory: {
        moveProductsToBin: string;
    };
    query: IScanBinQueryResult & ILocationQueryResult;
}

interface IProps {
    cartName: string;
    scanBinRefetch: () => void;
    isScanBinLoading: boolean;
    scanBinQueryVariables: IScanBinQueryVariables | undefined;
    scanBinData: IScanBinQueryResult | null | undefined;
    sortedDisplayPicks: IDisplayPicksModel[];
    cartId: string;
}

interface ISearchParams {
    locationId: string;
    sku: string;
}

const ScanSku = (props: IProps) => {
    const actionBarContext = useActionBarContext();
    const sessionContext = useContext(SessionContext);
    const params = useParams<ISearchParams>();
    const history = useHistory();
    const { multiMatchSelectorModal, showMultiMatchSelectorModal } = useMultiMatchSelectorModal();
    const [numberOfSelectedItems, setNumberOfSelectedItems] = useState<number>(0);
    const { confirmModal, showConfirmModal } = useConfirm();
    const { selectModal, showSelectModal } = useSelectModal();
    const { lostSkuModal, showLostSkuModal } = useLostSkuModal();
    const [runMoveProductToBinMutation] = useMutation<IMoveProductsToBinMutationResult, IMoveProductsToBinMutationVariables>(
        moveProductToBinMutation
    );
    const [sortKey, setSortKey] = useState<string | undefined>();
    const [manualLoading, setManualLoading] = useState(false);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const locationsOnCart = props.scanBinData?.pickZone.locations ?? [];
    const urlSearchParams = new URLSearchParams(decodeURIComponent(history.location.search));
    //reverse lookup the SelectionType enum from the value in the url
    const fromLocationSelectionTypeValue = urlSearchParams.get("fromLocationSelectionType") ?? "";
    const fromLocationSelectionType: SelectionType = fromLocationSelectionTypeValue as SelectionType;
    const dpf = urlSearchParams.get("displayPicksFilters");
    const displayPicksFilters = JSON.parse(dpf ?? "") as IFilters;
    const {
        loading: locationLoading,
        data: locationData,
        refetch: locationRefetch,
        error: locationError,
    } = useQuery<ILocationQueryResult, ILocationQueryVariables>(locationQuery, {
        fetchPolicy: "no-cache",
        variables: {
            locationId: params.locationId,
        },
    });
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const [productSelectionType, setProductSelectionType] = useState<SelectionType>(SelectionType.None);
    const [showAddOrPrintBarcodeModal, setShowAddOrPrintBarcodeModal] = useState(false);
    const onScan = async (scannerValue: string) => {
        /**
         * Runs everytime a sku that is in the current bin is scanned.
         * "shouldIncrementCount" represents whether or not the "numberOfSelectedItems" (stateful variable) should be incremented or not.
         * @param scannedProduct
         * @param shouldIncrementCount
         */
        const runProductScan = async (scannedProduct: IDisplayPicksModel, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
            //first thing, we need to determine if the numberOfSelectedItems is > 0. This will decide whether we increment the count, or are scanning a new item
            if (numberOfSelectedItems > 0) {
                //numberOfSelectedItems > 0 and autoStow being on shouldn't be possible
                if (sessionContext.autoStow === true) {
                    return playNegativeTone();
                }
                //determine if the scannedProduct is the same as the item in the first position in the displayPicksInCurrentBin array. if it is, we need to increment the count
                if (displayPicksInCurrentBin[0].product.id === scannedProduct.product.id) {
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    incrementNumberOfSelectedItems();
                } else {
                    playNegativeTone();
                    await showConfirmModal(
                        `You already have a product in hand! Please only scan the same type of product or scan the cart!`,
                        "Scan Mismatch",
                        { confirm: "Ok" },
                        true
                    );
                }
            }
            //if the numberOfSelectedItems is 0
            else {
                if (sessionContext.autoStow === true) {
                    //**IF THERE ARE MULTIPLE LOCATIONS ON A CART, AND "AUTO STOW" IS ENABLED, RETURN RIGHT AWAY. THIS IS NOT ALLOWED FOR AUTO STOW.
                    if (doesCartHaveMultipleLocations) {
                        return playNegativeTone();
                    }
                    //run code to place item in the selected cart that you are using. the selected cart is only allowed to have one location (bin) for auto stow to work correctly
                    await moveProductToLocation(
                        scannedProduct,
                        1,
                        { id: locationsOnCart[0].id, name: locationsOnCart[0].name },
                        setLoading,
                        SelectionType.Scanned,
                        SelectionType.None
                    );
                } else {
                    //if autoStow is disabled, we need to increment the count of the scanned product, and if the scanned product is not in the first position in the displayPicksInCurrentBin array,
                    //we need to change the sort order of displayPicksInCurrentBin and also increment the count of the scanned product.
                    if (displayPicksInCurrentBin[0].product.id === scannedProduct.product.id) {
                        setProductSelectionType(SelectionType.Scanned);
                        playPositiveTone();
                        incrementNumberOfSelectedItems();
                    } else {
                        //reaching this means the scanned product is not in the first position
                        //reorder the displayPicksInCurrentBin and increment count
                        setProductSelectionType(SelectionType.Scanned);
                        playPositiveTone();
                        setSortKey(scannedProduct.product.id);
                        setNumberOfSelectedItems(1);
                    }
                }
            }
        };

        const runLocationOnCartScan = async (
            selectedLocation: { id: string; name: string },
            setLoading: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
            //when scanning a cart, we need to make sure there is something in the first position of the displayPicksInCurrentBin array.
            //that is the item that we are going to move to the cart.
            if (numberOfSelectedItems < 1) {
                playNegativeTone();
                return alert("There are no items in hand.");
            }
            const firstItem = displayPicksInCurrentBin.find((x) => x);
            if (!firstItem) {
                return playNegativeTone();
            }

            await moveProductToLocation(
                firstItem,
                numberOfSelectedItems,
                selectedLocation,
                setLoading,
                productSelectionType,
                SelectionType.Scanned
            );
        };

        if (!locationLoading || manualLoading) {
            try {
                setManualLoading(true);
                const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
                const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
                const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
                //check if there are matches for products and locations from the scanned value
                if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                    await showConfirmModal(
                        formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                        "Multiple Matches In Database",
                        { confirm: "Confirm" },
                        true
                    );
                } else if (barcodeProducts.length > 0) {
                    const matchingProductsInBin = barcodeProducts.flatMap((bp) =>
                        displayPicksInCurrentBin.filter((x) => x.product.id === bp.id)
                    );
                    if (matchingProductsInBin.length < 1) playNegativeTone();
                    else if (matchingProductsInBin.length === 1) await runProductScan(matchingProductsInBin[0], setManualLoading);
                    else {
                        const selected = await showMultiMatchSelectorModal(
                            "Select Correct Product SKU",
                            barcodeProducts.map((x) => x.id),
                            MultiMatchDataType.Product
                        );
                        if (!selected) return;
                        const scannedProduct = matchingProductsInBin.find((x) => x.product.id === selected.id);
                        if (scannedProduct) await runProductScan(scannedProduct, setManualLoading);
                        else playNegativeTone();
                    }
                } else if (barcodeLocations.length > 0) {
                    //check to see how many matches there are
                    if (barcodeLocations.length === 1) {
                        //only match for a location. get location object and continue like normal.
                        //verify that the location we scanned is on the cart that is currently selected
                        let scannedLocation = locationsOnCart.find((x) => x.id === barcodeLocations[0].id);
                        if (scannedLocation)
                            await runLocationOnCartScan({ id: scannedLocation.id, name: scannedLocation.name }, setManualLoading);
                        else playNegativeTone();
                    } else {
                        const selected = await showMultiMatchSelectorModal(
                            "Select Correct Location",
                            barcodeLocations.map((x) => x.id),
                            MultiMatchDataType.Location
                        );
                        if (!selected) return;
                        if (!locationsOnCart.find((x) => x.id === selected.id)) {
                            return alert("The location you have selected is not on the cart you are using.");
                        }
                        await runLocationOnCartScan({ id: selected.id, name: selected.name }, setManualLoading);
                    }
                } else {
                    playNegativeTone();
                }
            } catch (error: any) {
                playNegativeTone();
                alert((error as GraphQLError)?.message ?? "Unknown error");
            } finally {
                setManualLoading(false);
            }
        }
    };
    useScanner(onScan);

    if (locationError) return <ErrorDisplay onClick={locationRefetch}>{locationError.message}</ErrorDisplay>;

    if (!params.locationId) return <Redirect to={`/mobile/pick/scanbin/scanbin${urlSearchParams.toString()}`} />;

    let productsInBin = locationData?.location.products ?? [];
    const displayPicks = props.scanBinData?.mobile.displayPicks ?? [];

    let displayPicksInCurrentBin = displayPicks.filter((displayPick) => displayPick.location.id === params.locationId);
    if (sortKey)
        displayPicksInCurrentBin.sort(
            (a, b) => b.product.id.indexOf(sortKey) - a.product.id.indexOf(sortKey) || b.location.name.localeCompare(a.location.name)
        );
    else if (params.sku)
        displayPicksInCurrentBin.sort(
            (a, b) => b.product.sku.indexOf(params.sku) - a.product.sku.indexOf(params.sku) || b.product.sku.localeCompare(a.product.sku)
        );

    const doesCartHaveMultipleLocations = locationsOnCart.length > 1;

    //this is only used if there is one location available on the cart
    const locationOnCartName = doesCartHaveMultipleLocations ? "Any" : locationsOnCart.find((x) => x)?.name ?? "N/A";

    //if the "quantityToPick" of all "displayPicksInCurrentBin" is < 1, there are no more left to be picked in this bin,
    //so we need to go back to the scan bin page.
    if (
        !props.isScanBinLoading &&
        (displayPicksInCurrentBin.every((x) => x.pickSaleModels.reduce((a, b) => a + b.quantityToPick, 0) < 1) ||
            (!locationLoading && productsInBin.reduce((acc, val) => acc + val.stockOnHand, 0) < 1))
    ) {
        //get the next location name in the sortedDisplayPicks list, then put that in the search params.
        //this represents the next location we need to pick, if any
        const next = props.sortedDisplayPicks
            .filter((x) => x.location.name !== props.sortedDisplayPicks.find((x) => x)?.location.name)
            .find((x) => x);
        const nextSku = next?.product.sku;
        const nextLocationName = next?.location.name;
        urlSearchParams.set("sortSku", nextSku ?? "");
        urlSearchParams.set("sortLocationName", nextLocationName ?? "");
        return <Redirect to={`/mobile/pick/scanbin/scanbin?${urlSearchParams.toString()}`} />;
    }

    const firstProduct = displayPicksInCurrentBin.find((x) => x);
    const requiredAmount = firstProduct?.pickSaleModels.reduce((a, b) => a + b.quantityToPick, 0) ?? 0;
    const amountAvailableInBin = productsInBin.find((x) => x.product.id === firstProduct?.product.id)?.stockOnHand ?? 0;
    const maxItemCount = Math.min(requiredAmount, amountAvailableInBin);

    const cartFullHandler = () => history.push(`/mobile/pick/${props.cartId}/returncart`);

    const nextButtonHandler = () => {
        if (sessionContext.autoStow) stowButtonHandler();
        else {
            if (numberOfSelectedItems > 0) stowButtonHandler();
            else incrementNumberOfSelectedItems();
        }
    };

    const decrementNumberOfSelectedItems = () => {
        let value = numberOfSelectedItems - 1;
        if (numberOfSelectedItems < 1) {
            value = 0;
            return;
        }
        setNumberOfSelectedItems(value);
    };

    const incrementNumberOfSelectedItems = () => {
        let value = numberOfSelectedItems + 1;
        if (numberOfSelectedItems >= maxItemCount) {
            value = maxItemCount;
            return;
        }
        setNumberOfSelectedItems(value);
    };

    const moveProductToLocation = async (
        productToMove: IDisplayPicksModel,
        amountToMove: number,
        toLocation: { id: string; name: string },
        setLoading: React.Dispatch<React.SetStateAction<boolean>>,
        productSelectionType: SelectionType,
        locationSelectionType: SelectionType
    ) => {
        //sometimes, there will be multiple orders for the item we are picking.
        //we need to split the orders accordingly, based off of how many items are being picked.
        //to do that, we create an array of the sales numbers we need.
        //each sale id represents one item being picked.
        try {
            if (!props.scanBinData || !props.scanBinQueryVariables) {
                playNegativeTone();
                return alert("scanBinData or scanBinQueryVariables are null.");
            }
            let picks: string[] = [];
            productToMove.pickSaleModels.forEach((psm) => {
                for (let i = 0; i < psm.quantityToPick; i++) picks.push(psm.saleId);
            });

            //take the exploded picks array, and select only the amount of items to move.
            //note: splicing starts at the beginning of the array (0).
            picks.splice(amountToMove);

            //group the spliced items in the array. the key is the sale id.
            const picksGroups = GroupBy(picks, (p) => p);
            if (picksGroups.size < 1) {
                playNegativeTone();
                return alert("No pick groups found!");
            }

            let entries: IMoveProductToBin[] = [];
            for (let [saleId, values] of Array.from(picksGroups.entries())) {
                entries.push({
                    fromLocationId: productToMove.location.id,
                    productId: productToMove.product.id,
                    quantity: values.length,
                    saleId: saleId,
                    toLocationId: toLocation.id, //<----- locationId of the bin of the cart
                    fromLocationSelectionType: fromLocationSelectionType,
                    toLocationSelectionType: locationSelectionType,
                    productSelectionType: productSelectionType,
                });
            }
            const res = await runMoveProductToBinMutation({
                variables: {
                    moveProductToBin: entries,
                    displayPicks: props.scanBinQueryVariables.displayPicks,
                    pickZoneId: props.scanBinQueryVariables.pickZoneId,
                    warehouseId: props.scanBinQueryVariables.warehouseId,
                    locationId: params.locationId,
                    displayPicksFiltersInput: displayPicksFilters,
                },
            });
            const resData = res.data;
            if (!props.scanBinData) {
                playNegativeTone();
                return alert("Scan bin data is null");
            }
            if (!locationData?.location) {
                playNegativeTone();
                return alert("Location data is null");
            }
            props.scanBinData.mobile = resData.query.mobile;
            props.scanBinData.pickZone = resData.query.pickZone;
            locationData.location = resData.query.location;
            actionBarContext.setActionBarContent(`Transfered ${amountToMove} to ${toLocation.name}`);
            playPositiveTone();
            setNumberOfSelectedItems(0);
        } catch (error: any) {
            playNegativeTone();
            await props.scanBinRefetch();
            await locationRefetch();
            setNumberOfSelectedItems(0);
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setLoading(false);
        }
    };

    const stowButtonHandler = async () => {
        let firstItem = displayPicksInCurrentBin.find((x) => x);
        if (!firstItem) {
            playNegativeTone();
            return alert("First item is undefined");
        }

        if (firstItem.product.sku === undefined) {
            playNegativeTone();
            return alert("Sku is null");
        }

        let numberOfItemsToMove = sessionContext.autoStow
            ? numberOfSelectedItems === 0
                ? 1
                : numberOfSelectedItems
            : numberOfSelectedItems;
        if (numberOfItemsToMove < 1) {
            playNegativeTone();
            return alert(`Please select an amount of products to move to cart '${locationOnCartName}'`);
        }

        //the user needs to pick a specific bin to place a product(s), if a cart contains multiple locations
        let selectedLocation: { id: string; name: string } | undefined = undefined;
        if (doesCartHaveMultipleLocations) {
            const select = await showSelectModal(
                "Select a Location",
                locationsOnCart.map((l) => ({ id: l.id, description: l.name } as ISelect))
            );
            if (!select) return;
            selectedLocation = { id: select.id, name: select.description };
        } else {
            selectedLocation = { id: locationsOnCart[0].id, name: locationsOnCart[0].name };
        }

        if (!selectedLocation || manualLoading || locationLoading || props.isScanBinLoading) return;

        setManualLoading(true);

        await moveProductToLocation(
            firstItem,
            numberOfItemsToMove,
            selectedLocation,
            setManualLoading,
            productSelectionType,
            SelectionType.Selected
        );
    };

    const tableRowClickHandler = async (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        setManualLoading(true);
        const productId = e.currentTarget.id;
        if (numberOfSelectedItems > 0) {
            const currentSku = displayPicksInCurrentBin[0].product.sku;
            const currentBin = displayPicksInCurrentBin[0].location.name;
            const confirmed = await showConfirmModal(
                `Do you want the item (${currentSku}) in your hand to be stowed back in the pick bin (${currentBin})?`
            );
            if (confirmed) {
                setProductSelectionType(SelectionType.Selected);
                changeItemsSortOrder(productId);
            }
        } else {
            setProductSelectionType(SelectionType.Selected);
            changeItemsSortOrder(productId);
        }
        setManualLoading(false);
    };

    const changeItemsSortOrder = (clickedRowId: string) => {
        setSortKey(clickedRowId); //<----- productId
        setNumberOfSelectedItems(0);
    };

    const toggleAutoStow = async () => {
        setManualLoading(true);
        const currentSku = displayPicksInCurrentBin[0].product.sku;
        const currentBin = displayPicksInCurrentBin[0].location.name;
        if (numberOfSelectedItems > 0) {
            const confirmed = await showConfirmModal(
                `Do you want the item (${currentSku}) in your hand to be stowed back in the pick bin (${currentBin})?`
            );
            if (confirmed) {
                sessionContext.updateAutoStow(!sessionContext.autoStow);
                setNumberOfSelectedItems(0);
            }
        } else {
            sessionContext.updateAutoStow(!sessionContext.autoStow);
            setNumberOfSelectedItems(0);
        }
        setManualLoading(false);
    };

    const runLostSku = async () => {
        if (manualLoading || locationLoading || props.isScanBinLoading || !locationData) return;
        try {
            setManualLoading(true);
            if (numberOfSelectedItems > 0) return alert("Please put back any products in your hand first.");
            if (displayPicksInCurrentBin.length < 1) return alert("No items in current bin");
            const quantityInBin =
                locationData.location.products.find((x) => x.product.id === displayPicksInCurrentBin[0].product.id)?.stockOnHand ?? 0;
            if (quantityInBin < 1) return alert(`No items in current bin for SKU: ${displayPicksInCurrentBin[0].product.sku}`);
            if (!params.locationId) return alert("Location id is null.");
            const response = await showLostSkuModal<ILocationQueryResult>(
                {
                    locationId: displayPicksInCurrentBin[0].location.id,
                    locationName: displayPicksInCurrentBin[0].location.name,
                    locationSelectionType: SelectionType.None,
                    productId: displayPicksInCurrentBin[0].product.id,
                    productSelectionType: SelectionType.None,
                    productSku: displayPicksInCurrentBin[0].product.sku,
                    quantityInBin: quantityInBin,
                },
                `location(id: ${params.locationId}) {
                ${locationQueryFields}
            }`
            );
            if (!response) return;
            locationData.location = response.location;
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const openAddOrPrintBarcodeModal = (productId: string | undefined | null) => {
        if (!productId) return;
        setShowAddOrPrintBarcodeModal(true);
    };

    let binTextColor = "#CC0A3B";
    let handTextColor = "black";
    let bin2TextColor = "black";
    if (numberOfSelectedItems > 0) {
        binTextColor = "black";
        handTextColor = "#CC0A3B";
        bin2TextColor = "#CC0A3B";
    }

    const primaryTitle = displayPicksInCurrentBin.find((x) => x)?.location.name ?? "";
    let secondaryTitle = displayPicksInCurrentBin.find((x) => x)?.product.sku ?? "";
    if (numberOfSelectedItems > 0) secondaryTitle = props.cartName;

    const secondaryHeaderTitle = numberOfSelectedItems > 0 ? "║▌Stow to Bin" : "║▌Pick Sku";

    //used for showing how many products are in the users cart that match the item that is first in the displayPicks list
    const numberOfMatchingProductsByLocation =
        locationsOnCart.length > 0
            ? locationsOnCart
                  .map((x) => x.products)
                  .reduce((acc, curVal) => acc.concat(curVal), [])
                  .filter((x) => x.product.id === (displayPicksInCurrentBin.length > 0 ? displayPicksInCurrentBin[0].product.id : ""))
                  .reduce((acc, curVal) => acc + curVal.stockOnHand, 0)
            : 0;

    return (
        <>
            {manualLoading || locationLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={primaryTitle}
                IncludeBackButton
                IncludeHomeButton
                CustomBackButtonPath={`/mobile/pick/scanbin/scanbin?${urlSearchParams.toString()}`}
            />

            <SecondaryHeader Title={secondaryHeaderTitle} SecondaryTitle={secondaryTitle} NextButtonHandler={nextButtonHandler} />

            {displayPicksInCurrentBin.length < 1 ? (
                <></>
            ) : (
                <>
                    <MobileTable isActiveTable>
                        <thead>
                            <tr>
                                <td>Bin</td>
                                <td>Hand</td>
                                <td>Bin</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                id={JSON.stringify({
                                    sku: displayPicksInCurrentBin[0].product.sku,
                                    bin: displayPicksInCurrentBin[0].location.name,
                                    id: displayPicksInCurrentBin[0].product.id,
                                })}
                            >
                                <td style={{ color: binTextColor }}>{maxItemCount - numberOfSelectedItems}</td>
                                <td id="hand" style={{ color: handTextColor }}>
                                    {numberOfSelectedItems}
                                </td>
                                <td>{numberOfMatchingProductsByLocation}</td>
                            </tr>
                            <tr
                                id={JSON.stringify({
                                    sku: displayPicksInCurrentBin[0].product.sku,
                                    bin: displayPicksInCurrentBin[0].location.name,
                                    id: displayPicksInCurrentBin[0].product.id,
                                })}
                            >
                                <td>{displayPicksInCurrentBin[0].location.name}</td>
                                <td style={{ color: binTextColor }}>{displayPicksInCurrentBin[0].product.sku}</td>
                                <td style={{ color: bin2TextColor }}>{locationOnCartName}</td>
                            </tr>
                        </tbody>
                    </MobileTable>

                    <div style={{ padding: "10px 10px 0 10px" }}>
                        <ActiveProductImages sku={displayPicksInCurrentBin[0].product.sku} />
                    </div>

                    {/* category */}
                    <div style={{ fontSize: 12, margin: "5px 10px 0 10px" }}>
                        Category: <b>{displayPicksInCurrentBin[0].product.description}</b>
                    </div>
                </>
            )}

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled={sessionContext.autoStow} onClick={() => stowButtonHandler()}>
                            Stow ({numberOfSelectedItems}) to Bin {locationOnCartName}
                        </MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled={sessionContext.autoStow} onClick={() => decrementNumberOfSelectedItems()}>
                            -
                        </MobileButton>
                        <MobileButton disabled={sessionContext.autoStow} onClick={() => incrementNumberOfSelectedItems()}>
                            +
                        </MobileButton>
                        <MobileButton disabled={sessionContext.autoStow} onClick={() => setNumberOfSelectedItems(maxItemCount)}>
                            Max
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled={doesCartHaveMultipleLocations} onClick={toggleAutoStow}>
                            Auto Stow ({sessionContext.autoStow ? "On" : "Off"})
                        </MobileButton>
                        <MobileButton onClick={runLostSku}>Update Qty</MobileButton>
                        <MobileButton onClick={() => cartFullHandler()}>Cart Full</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton
                            disabled={!firstProduct?.product?.id}
                            onClick={() => openAddOrPrintBarcodeModal(firstProduct?.product?.id)}
                        >
                            Add or Print Barcode
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
            </MobileButtonContainer>

            <MobileTable>
                <tbody>
                    {/*this filters out all products in the current bin that do not have a quantity to pick, as that is not relevant to what the picker needs to see*/}
                    {displayPicksInCurrentBin
                        .filter((x) => x.pickSaleModels.reduce((a, b) => a + b.quantityToPick, 0) > 0)
                        .map((b, i) => {
                            if (i === 0) return <React.Fragment key={i.toString() + "a"}></React.Fragment>;

                            return (
                                <tr key={i.toString() + "a"} id={b.product.id} onClick={(e) => tableRowClickHandler(e)}>
                                    <td>{b.location.name}</td>
                                    <td>{b.product.sku}</td>
                                    <td>{locationOnCartName}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </MobileTable>

            <Modal show={showAddOrPrintBarcodeModal} fullscreen>
                <PrimaryHeader
                    Title={firstProduct?.product?.sku ?? "-"}
                    IncludeHomeButton
                    IncludeBackButton
                    BackButtonText="< Back"
                    CustomBackButtonFunction={() => setShowAddOrPrintBarcodeModal(false)}
                />

                <SecondaryHeader Title="Add Or Print Barcode" NextButtonHandler={nextButtonHandler} />

                <AddOrPrintBarcode
                    productId={firstProduct?.product?.id ?? "0"}
                    onBarcodeSaveAction={() => setShowAddOrPrintBarcodeModal(false)}
                />
            </Modal>

            {React.createElement(confirmModal)}

            {React.createElement(selectModal)}

            {React.createElement(multiMatchSelectorModal)}

            {lostSkuModal}
        </>
    );
};

//const ScanSku = () => <>Nothing</>;

export default ScanSku;
