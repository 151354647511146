import React, { createContext, useCallback, useContext, useState, ReactNode, useEffect } from "react";
import styles from "./ActionBarContext.module.scss";

interface IActionBarContextProps {
    isActionBarVisible: boolean;
    setIsActionBarVisible: (x: boolean) => void;
    setActionBarContent: (x: React.ReactNode) => void;
}

const ActionBarContext = createContext<IActionBarContextProps | undefined>(undefined);

export const useActionBarContext = () => {
    const context = useContext(ActionBarContext);
    if (!context) {
        throw new Error("useActionBarContext must be used within a ActionBarContextProvider");
    }
    return context;
};

interface IActionBarContextProviderProps {
    children: ReactNode;
}

export const ActionBarContextProvider: React.FC<IActionBarContextProviderProps> = ({ children }) => {
    const [isActionBarVisible, setIsActionBarVisibleContext] = React.useState<boolean>(false);
    const [actionBarContent, setActionBarContentContext] = React.useState<React.ReactNode>("");

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);
    const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
    const actionBarContainerRef = React.useRef<HTMLDivElement>(null);

    const firstLoad = React.useRef<boolean>(true);

    useEffect(() => {
        firstLoad.current = false;
    }, []);

    useEffect(() => {
        if (isActionBarVisible) {
            document.documentElement.style.overscrollBehaviorY = "contain"; // prevent overscrolling
        } else {
            document.documentElement.style.overscrollBehaviorY = "auto"; // enable overscrolling
        }

        return () => {
            document.documentElement.style.overscrollBehaviorY = "auto"; // enable overscrolling
        };
    }, [isActionBarVisible]);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 5;

    const setIsActionBarVisible = useCallback((x: boolean) => {
        setIsActionBarVisibleContext(x);
        clearTimeout(timeoutRef.current);
    }, []);

    const setActionBarContent = useCallback((x: React.ReactNode) => {
        setIsActionBarVisibleContext(true);
        console.log(typeof x);
        let content: React.ReactNode;
        switch (typeof x) {
            case "string":
                content = <ActionBarDefaultContentContainer>{x}</ActionBarDefaultContentContainer>;
                break;
            default:
                content = x;
                break;
        }
        setActionBarContentContext(content);
        startHideActionBarTimeout();
    }, []);

    const startHideActionBarTimeout = () => {
        timeoutRef.current = setTimeout(() => {
            setIsActionBarVisibleContext(false);
        }, 3000);
    };

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null);
        clearTimeout(timeoutRef.current);
        setTouchStart(e.targetTouches[0].clientY);
    };

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientY);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isUpSwipe = distance > minSwipeDistance;
        const isDownSwipe = distance < -minSwipeDistance;
        if (isDownSwipe) {
            setIsActionBarVisibleContext(false);
        }
        if (isUpSwipe) {
            // Add in ability to see previous action bar content
        }
    };

    return (
        <ActionBarContext.Provider value={{ isActionBarVisible, setIsActionBarVisible, setActionBarContent }}>
            {children}
            <div
                ref={actionBarContainerRef}
                className={`${styles.container} ${firstLoad.current ? "" : isActionBarVisible ? styles.slideIn : styles.slideOut}`}
            >
                <div
                    draggable
                    className={`${styles.actionBar}`}
                    onClick={() => clearTimeout(timeoutRef.current)}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    {actionBarContent}
                </div>
            </div>
        </ActionBarContext.Provider>
    );
};

interface IActionBarDefaultContentContainerProps {
    children: React.ReactNode;
}

const ActionBarDefaultContentContainer = ({ children }: IActionBarDefaultContentContainerProps) => {
    return <div className={styles.defaultContentContainer}>{children}</div>;
};
