enum ProductCondition {
    Unknown = "UNKNOWN",
    New = "NEW",
    CosmeticDamage = "COSMETIC_DAMAGE",
    BrokenVisibleDamage = "BROKEN_VISIBLE_DAMAGE",
    ManufacturingDefect = "MANUFACTURING_DEFECT",
    DesignFlaw = "DESIGN_FLAW",
    PartsMissing = "PARTS_MISSING",
    Used = "USED",
}

export default ProductCondition;
