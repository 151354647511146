/**
 * Parses a string of letter ranges and individual letters, and returns a sorted array of unique letters.
 *
 * This function handles:
 * - Single letters (e.g., 'A', 'B')
 * - Letter ranges (e.g., 'A-D', 'X-Z')
 * - Extended alphabet sequences (e.g., 'Z-AA', 'AY-BC')
 * - Both forward and backward ranges
 * - Out-of-order inputs
 * - Case-insensitive input (output is always uppercase)
 *
 * The input string should be comma-separated or space-separated (e.g., "B, C, D, G-I, A, S-X, Z-AB" or "B C D G-I A S-X Z-AB").
 * The output is a sorted array of unique letters and letter sequences based on an extended alphabet system,
 * where 'Z' is followed by 'AA', 'AZ' is followed by 'BA', etc.
 *
 * Empty inputs or inputs with only whitespace will return an empty array.
 *
 * @param input - A string of comma-separated or space-separated letter ranges and individual letters
 * @returns An array of unique letters and letter sequences, sorted in alphabetical order
 */
function parseLetterRanges(input: string): string[] {
    if (!input.trim()) return [];

    const substrings = input
        .split(/[ ,]+/) // Split by comma or space
        .filter((substring) => substring.length > 0); //  Remove empty substrings
    if (substrings.length === 0) return [];

    const result = new Set<string>();

    function letterToNumber(str: string): number {
        let num = 0;
        for (let i = 0; i < str.length; i++) {
            num = num * 26 + (str.charCodeAt(i) - 64);
        }
        return num;
    }

    function numberToLetter(num: number): string {
        let str = "";
        while (num > 0) {
            let remainder = num % 26;
            if (remainder === 0) {
                remainder = 26;
                num--;
            }
            str = String.fromCharCode(remainder + 64) + str;
            num = Math.floor(num / 26);
        }
        return str;
    }

    substrings.forEach((substring) => {
        if (substring.includes("-")) {
            const [start, end] = substring.split("-");
            const startNum = letterToNumber(start);
            const endNum = letterToNumber(end);
            const step = startNum <= endNum ? 1 : -1;

            for (let i = startNum; step > 0 ? i <= endNum : i >= endNum; i += step) {
                result.add(numberToLetter(i));
            }
        } else {
            result.add(substring);
        }
    });

    return Array.from(result).sort((a, b) => letterToNumber(a) - letterToNumber(b));
}

export default parseLetterRanges;
