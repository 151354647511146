import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import { Table } from "react-bootstrap";
import { useQuery } from "@shane32/graphql";
import Loading from "../../../components/loading/Loading";

const PurchaseOrderAssociatedOrders = () => {
    const { id } = useParams<{ id: string }>();
    const ret = useQuery<
        {
            sales: { items: Array<{ id: string; rootSaleId: string | null; parentSaleId: string | null; childSaleType: string | null }> };
            returns: { items: Array<{ id: string }> };
            purchaseOrder: { id: string; isReturn: boolean };
        },
        { id: string }
    >(
        `query ($id: ID!) { 
            sales(relatedPurchaseOrderId: $id) { items { id rootSaleId parentSaleId childSaleType } }
            returns(relatedPurchaseOrderId: $id) {items { id } }
            purchaseOrder(id: $id) { id isReturn }
        }`,
        {
            variables: { id },
        }
    );
    if (!ret.data) return <Loading />;

    return (
        <>
            <Row>
                <Col xs={6}>
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Sales Orders</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Order #</th>
                                        <th>Relationship</th>
                                        <th>Ref</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ret.data.sales.items.map((row) => (
                                        <tr key={row.id}>
                                            <td>
                                                <Link to={`/sales/${row.id}`}>SO-{row.id}</Link>
                                            </td>
                                            <td>{ret.data?.purchaseOrder.isReturn ? "Original Sales Order" : row.childSaleType}</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card className="mb-4 border-primary">
                        <Card.Header className="bg-primary text-white">Returns</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Ticket #</th>
                                        <th>Relationship</th>
                                        <th>Ref</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ret.data.returns.items.map((row) => (
                                        <tr key={row.id}>
                                            <td>
                                                <Link to={`/returns/${row.id}`}>RN-{row.id}</Link>
                                            </td>
                                            <td>Parent</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PurchaseOrderAssociatedOrders;
