import { GraphQLContext, useMutation, useQuery } from "@shane32/graphql";
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams, IRowNode } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import * as React from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";

import { VCheck, VControl, VForm, VLabel } from "@shane32/vform";

interface IAddResult {
    product: {
        add: ILocationMutation;
    };
}

interface IAddVariables {
    value: ILocationMutation;
}

interface IEditResult {
    location: {
        edit: ILocationMutation;
    };
}

interface IEditVariables {
    original: ILocationMutation;
    modified: ILocationMutation;
}

interface IDeleteResult {
    location: {
        delete: string;
    };
}

interface IPrintResult {
    api: {
        print: {
            locationLabels: {
                print: boolean;
            };
        };
    };
}

interface IPickZone {
    id: string;
    name: string;
    isCart: boolean;
    pickable: boolean;
}

interface IPrinter {
    id: string;
    description: string;
    paperWidth: number | null;
    paperHeight: number | null;
    sortOrder: number;
    type: string;
}

interface IPrinterResult {
    printers: {
        items: IPrinter[];
    };
}

interface IPickZoneQueryResult {
    pickZones: {
        items: Array<IPickZone>;
    };
}

interface ILocation {
    id: string;
    name: string;
    pickZoneId: string;
    pickZone: IPickZone;
    warehouseId: string;
    aisle: string | null;
    side: string | null;
    rack: string | null;
    shelf: string | null;
    bin: string | null;
    active: boolean;
    deleted: boolean;
    productCount: number;
}

interface ILocationMutation {
    id: string;
    name: string;
    pickZoneId: string;
    warehouseId: string;
    aisle: string | null;
    side: string | null;
    rack: string | null;
    shelf: string | null;
    bin: string | null;
    active: boolean;
}

interface ILocationQueryResult {
    locations: {
        totalCount: number;
        items: Array<ILocation>;
    };
}

interface IEditModal {
    show: boolean;
    original?: ILocation;
}

const PrinterQuery = `
{
  printers(first:1000) {
    items {
      id
      description
      paperWidth
      paperHeight
      sortOrder
      type
    }
  }
}`;

const PickZoneQuery = `
query ($warehouseId: ID) {
  pickZones (warehouseId: $warehouseId) {
    items {
      id
      name
      isCart
      pickable
    }
  }
}`;

const LocationQuery = `
query ($after: String, $warehouseId: ID!, $name: String, $aisle: String, $side: String, $rack: String, $shelf: String, $bin: String, $pickZoneId: ID, $pickable: Boolean, $sortOrder: LocationsSortOrder!) {
  locations(first: 100, after: $after, warehouseId: $warehouseId, name: $name, aisle: $aisle, side: $side, rack: $rack, shelf: $shelf, bin: $bin, pickZoneId: $pickZoneId, pickable: $pickable, sortOrder: $sortOrder) {
    totalCount
    items {
      id
      name
      pickZoneId
      pickZone {
        name
        pickable
        isCart
      }
      warehouseId
      aisle
      side
      rack
      shelf
      bin
      active
      deleted
      productCount
    }
  }
}`;

interface ILocationQueryVariables {
    after: string | null;
    warehouseId: string;
    name: string | null;
    aisle: string | null;
    side: string | null;
    rack: string | null;
    shelf: string | null;
    bin: string | null;
    pickZoneId: string | number | null;
    pickable: boolean | null;
    sortOrder: string;
}

const LocationMutation = `
mutation ($original: LocationInput!, $modified: LocationInput!) {
  location {
    edit(original: $original, modified: $modified) {
      id
      name
      pickZoneId
      warehouseId
      bin
      aisle
      side
      rack
      shelf
      active
      deleted
    }
  }
}`;

const AddLocationMutation = `
mutation ($value: LocationInput!) {
  location {
    add (value: $value) {
      id
    }
  }
}`;

const DeleteMutation = `
mutation ($ids: [ID!]!) {
    location {
        bulkDelete (ids: $ids)
    }
}`;

const PrintMutation = `
mutation($ids:[ID!]!, $printerId:ID!, $labelWidth:Decimal!, $labelHeight:Decimal!) {
  api {
    print {
      locationLabels(locationIds:$ids, labelWidthInches: $labelWidth, labelHeightInches: $labelHeight) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

interface IPrintMutationVariables {
    ids: string[];
    printerId: string;
    labelWidth: number | null;
    labelHeight: number | null;
}

const WarehouseLocations = () => {
    //// ADD location(s) ////
    const [showAddModal, setShowAddModal] = React.useState(false);
    const onCloseAddModal = () => {
        //reset form state
        setFormPickZoneId("");
        setFormIsCart(null);
        setFormIsCustom(false);
        setShowAddModal(false);
    };
    //modal form state
    const [formPickZoneId, setFormPickZoneId] = React.useState<string>("");
    const [formIsCart, setFormIsCart] = React.useState<boolean | null>(null);
    const [formIsCustom, setFormIsCustom] = React.useState(false);

    //// EDIT location ////
    const [editModal, setEditModal] = React.useState<IEditModal>({ show: false });
    //corresponds to a location's 'side' value: null -> custom, !null -> shelving, '#' -> 'cart'
    const [editFormType, setEditFormType] = React.useState<"CUSTOM" | "SHELVING" | "CART">("CUSTOM");
    const onCloseEditModal = () => {
        setEditModal({ show: false });
    };

    //// FILTER locations ////
    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const onCloseFilterModal = () => setShowFilterModal(false);

    const { id: warehouseId } = useParams<{ id: string }>();

    //filters
    const nameFilter = React.useRef<string>("");
    const aisleFilter = React.useRef<string>("");
    const sideFilter = React.useRef<string>("");
    const rackFilter = React.useRef<string>("");
    const shelfFilter = React.useRef<string>("");
    const binFilter = React.useRef<string>("");
    const pickZoneIdFilter = React.useRef<string | number>("");
    const pickableFilter = React.useRef<string>("");

    //user input to change pick zone
    const assignPickZoneId = React.useRef<string>("");

    //used when 'Assign pick zone:' is clicked and we need to wait on the server
    const [loading, setLoading] = React.useState(false);

    //add mutation
    const [runAdd] = useMutation<IAddResult, IAddVariables>(AddLocationMutation);
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(LocationMutation);
    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { ids: string[] }>(DeleteMutation);
    const [canBulkDelete, setCanBulkDelete] = React.useState(false);
    //print mutation
    const [runPrint] = useMutation<IPrintResult, IPrintMutationVariables>(PrintMutation);

    //AG Grid
    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

    //GraphQL handle
    const graphQLContext = React.useContext(GraphQLContext);

    //need to grab the pick zones for the dropdown in the top right
    const { data, error, refetch } = useQuery<IPickZoneQueryResult, { warehouseId: string }>(PickZoneQuery, {
        variables: { warehouseId: warehouseId },
        fetchPolicy: "cache-and-network",
    });
    //and stuff for printing
    const {
        data: dataPrinters,
        error: errorPrinters,
        refetch: refetchPrinters,
    } = useQuery<IPrinterResult>(PrinterQuery, { fetchPolicy: "cache-and-network" });
    const [showPrintModal, setShowPrintModal] = React.useState(false);
    const [selectedPrinterId, setSelectedPrinterId] = React.useState("");
    const [locationIdsToPrint, setLocationIdsToPrint] = React.useState<string[]>([]);

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (errorPrinters) return <ErrorDisplay onClick={refetchPrinters}>{errorPrinters.message}</ErrorDisplay>;
    if (!data || !dataPrinters) return <Loading />;

    //used for pick zone dropdowns
    const shelvingPickZones = data.pickZones.items.filter((pickZone: IPickZone) => !pickZone.isCart);
    const cartPickZones = data.pickZones.items.filter((pickZone: IPickZone) => pickZone.isCart);

    //AG Grid column structure
    const columns: ColDef<ILocation, any>[] = [
        {
            field: "name",
            flex: 1,
            cellRenderer: (params: ICellRendererParams<ILocation>) => {
                if (params.data) {
                    return (
                        <Button
                            variant="link"
                            onClick={() => {
                                setEditFormType(params.data!.side === "#" ? "CART" : params.data!.side ? "SHELVING" : "CUSTOM");
                                setEditModal({
                                    show: true,
                                    original: params.data,
                                });
                            }}
                        >
                            {params.data.name}
                        </Button>
                    );
                } else return <></>;
            },
        },
        {
            field: "aisle",
            flex: 1,
        },
        {
            field: "side",
            flex: 1,
        },
        {
            field: "rack",
            flex: 1,
        },
        {
            field: "shelf",
            flex: 1,
        },
        {
            field: "bin",
            flex: 1,
        },
        {
            field: "productCount",
            headerName: "Products",
            flex: 1,
        },
        {
            field: "pickZone.name",
            headerName: "Pick Zone",
            flex: 1,
        },
        {
            field: "pickZone.pickable",
            headerName: "Pickable",
            flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data) {
                    if (params.data.pickZone.pickable) {
                        return <>Yes</>;
                    } else {
                        return <>No</>;
                    }
                } else return <></>;
            },
        },
    ];

    //called when AG Grid renders
    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);

        //used by AG Grid as a server handle to lazy load data
        const dataSource: IDatasource = {
            //called whenever AG Grid needs more data
            getRows: (params: IGetRowsParams) => {
                //construct the sortOrder string so it matches an enum on the server
                let sortOrder = String(null);

                if (params.sortModel.length > 0) {
                    if (params.sortModel[0].sort === "asc") {
                        sortOrder = "_ASCENDING";
                    } else if (params.sortModel[0].sort === "desc") {
                        sortOrder = "_DESCENDING";
                    }

                    switch (params.sortModel[0].colId) {
                        case "name":
                            sortOrder = `NAME${sortOrder}`;
                            break;
                        case "aisle":
                            sortOrder = `AISLE${sortOrder}`;
                            break;
                        case "side":
                            sortOrder = `SIDE${sortOrder}`;
                            break;
                        case "rack":
                            sortOrder = `RACK${sortOrder}`;
                            break;
                        case "shelf":
                            sortOrder = `SHELF${sortOrder}`;
                            break;
                        case "bin":
                            sortOrder = `BIN${sortOrder}`;
                            break;
                        case "pickZone.name":
                            sortOrder = `PICK_ZONE${sortOrder}`;
                            break;
                        case "pickZone.pickable":
                            sortOrder = `PICKABLE${sortOrder}`;
                            break;
                        case "productCount":
                            sortOrder = `PRODUCT_COUNT${sortOrder}`;
                            break;
                    }
                }
                //default
                else {
                    sortOrder = "ID_ASCENDING";
                }

                //query the server for data
                const ret = graphQLContext.client.ExecuteQueryRaw<ILocationQueryResult, ILocationQueryVariables>({
                    query: LocationQuery,
                    variables: {
                        after: params.startRow === 0 ? null : (params.startRow - 1).toString(),
                        warehouseId: warehouseId,
                        name: nameFilter.current === "" ? null : nameFilter.current,
                        aisle: aisleFilter.current === "" ? null : aisleFilter.current,
                        side: sideFilter.current === "" ? null : sideFilter.current,
                        rack: rackFilter.current === "" ? null : rackFilter.current,
                        shelf: shelfFilter.current === "" ? null : shelfFilter.current,
                        bin: binFilter.current === "" ? null : binFilter.current,
                        pickZoneId: pickZoneIdFilter.current === "" ? null : pickZoneIdFilter.current,
                        pickable: pickableFilter.current === "" ? null : pickableFilter.current === "true",
                        sortOrder: sortOrder,
                    },
                });
                setCanBulkDelete(false);

                ret.result.then(
                    //success
                    (result) => {
                        //failure
                        if (result.errors || !result.data) {
                            console.log("Error fetching data: 1", result.errors);
                            params.failCallback();
                            return;
                        }

                        //feed data into AG Grid
                        params.successCallback(result.data.locations.items, result.data.locations.totalCount);

                        let newCanBulkDelete = result.data.locations.items.length > 0;
                        result.data.locations.items.forEach((row) => {
                            if (row.productCount !== 0) newCanBulkDelete = false;
                        });
                        setCanBulkDelete(newCanBulkDelete);
                    },
                    //failure
                    (result) => {
                        console.log("Error fetching data: 2", result);
                        params.failCallback();
                    }
                );
            },
        };

        //bind the server handle ('dataSource') to AG Grid
        params.api.setDatasource(dataSource);
    };

    ////////////////////////////////////////// FILTER //////////////////////////////////////////
    const setFilters = (filter: {
        name: string;
        aisle: string;
        side: string;
        rack: string;
        shelf: string;
        bin: string;
        pickZoneId: string;
        pickable: string;
    }) => {
        nameFilter.current = filter.name;
        aisleFilter.current = filter.aisle;
        sideFilter.current = filter.side;
        rackFilter.current = filter.rack;
        shelfFilter.current = filter.shelf;
        binFilter.current = filter.bin;
        pickZoneIdFilter.current = filter.pickZoneId;
        pickableFilter.current = filter.pickable;

        gridApi!.onFilterChanged();
    };

    const onFilter = (event: React.BaseSyntheticEvent) => {
        setFilters({
            name: event.currentTarget.querySelector("#nameFilter").value,
            aisle: event.currentTarget.querySelector("#aisleFilter").value,
            side: event.currentTarget.querySelector("#sideFilter").value,
            rack: event.currentTarget.querySelector("#rackFilter").value,
            shelf: event.currentTarget.querySelector("#shelfFilter").value,
            bin: event.currentTarget.querySelector("#binFilter").value,
            pickZoneId: event.currentTarget.querySelector("#pickZoneIdFilter").value,
            pickable: event.currentTarget.querySelector("#pickableFilter").value,
        });
    };

    const onClearFilters = () => {
        //set filters to their defaults
        nameFilter.current = "";
        aisleFilter.current = "";
        sideFilter.current = "";
        rackFilter.current = "";
        shelfFilter.current = "";
        binFilter.current = "";
        pickZoneIdFilter.current = "";
        pickableFilter.current = "";

        gridApi!.onFilterChanged();
    };
    ////////////////////////////////////////// FILTER //////////////////////////////////////////

    ////////////////////////////////////////// ADD //////////////////////////////////////////
    const onAdd = (value: ILocation) => {
        //grab these values before onCloseAddModal() resets them
        let isCart = formIsCart;
        let isCustom = formIsCustom;
        let pickZoneId = formPickZoneId;

        onCloseAddModal();

        //three types of locations: cart, shelving (bulk vs. non-bulk), custom
        if (!isCustom) {
            //Cart
            if (isCart) {
                //TS check
                if (value.aisle !== null && value.rack !== null) value.name = value.aisle + value.rack + "-" + value.shelf + value.bin;
                value.side = "#";
            }
            //Shelving
            else {
                //TS check
                if (value.rack !== null && value.shelf !== null) {
                    //bulk
                    if (value.rack.includes(",") || value.shelf.includes("-")) {
                        value.name =
                            value.aisle +
                            "-" +
                            value.side +
                            "-" +
                            value.rack.split(",")[0] +
                            "-" +
                            value.shelf.split("-")[0] +
                            "-" +
                            value.bin;
                    }
                    //non-bulk
                    else {
                        value.name = value.side + value.rack + "-" + value.shelf + value.bin;
                    }
                }
            }
        }
        //Custom
        else {
            value.aisle = null;
            value.side = null;
            value.rack = null;
            value.shelf = null;
            value.bin = null;
        }

        //GraphQL add mutation
        runAdd({
            variables: {
                value: {
                    id: "0",
                    warehouseId: warehouseId,
                    pickZoneId: pickZoneId,
                    name: value.name,
                    aisle: value.aisle,
                    side: value.side,
                    rack: value.rack,
                    shelf: value.shelf,
                    bin: value.bin,
                    active: value.active,
                },
            },
        }).then(
            //success
            (ret) => {
                console.log("Successfully added location(s).");
                //update UI data
                setFilters({
                    name: isCustom ? value.name || "" : "",
                    aisle: isCustom ? "" : value.aisle || "",
                    side: isCustom ? "" : value.side || "",
                    rack: isCustom ? "" : value.rack || "",
                    shelf: isCustom ? "" : value.shelf || "",
                    bin: isCustom ? "" : value.bin || "",
                    pickZoneId: "",
                    pickable: "",
                });
                //gridApi!.onFilterChanged(); // done within setFilters
            },
            //failure
            (err) => {
                //log the error
                console.error("Error adding location(s):", err);
                alert(err.message);
            }
        );
    };

    //used to initialize VForm
    const emptyLocationObj = {
        id: "",
        name: "",
        pickZoneId: "0",
        pickZone: { id: "", name: "", isCart: false, pickable: false },
        warehouseId: "0",
        aisle: "",
        side: "",
        rack: "",
        shelf: "",
        bin: "",
        active: true,
        deleted: false,
        productCount: 0,
    };
    ////////////////////////////////////////// ADD //////////////////////////////////////////

    ////////////////////////////////////////// EDIT //////////////////////////////////////////
    const onEdit = (value: ILocation) => {
        setEditModal({ show: false });

        let name = "";

        //Custom
        if (editFormType === "CUSTOM") {
            name = value.name;
            value.aisle = null;
            value.side = null; // will be section
            value.rack = null; // will be side
            value.shelf = null;
            value.bin = null;
        } else if (editFormType === "CART") {
            name = (value.aisle ?? "") + (value.rack ?? "") + "-" + value.shelf + value.bin;
        } else if (editFormType === "SHELVING") {
            // check if value.aisle contains only digits
            if (value.aisle !== null && /^\d+$/.test(value.aisle)) {
                name = (value.side && value.side !== "A" ? value.side : "") + value.aisle + "-" + value.shelf + value.bin;
            } else {
                name = (value.side ?? "") + (value.rack ?? "") + "-" + value.shelf + value.bin;
            }
        }

        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalLocationObj.id,
                    name: originalLocationObj.name,
                    bin: originalLocationObj.bin,
                    pickZoneId: originalLocationObj.pickZoneId,
                    warehouseId: warehouseId,
                    active: originalLocationObj.active,
                    aisle: originalLocationObj.aisle,
                    side: originalLocationObj.side,
                    rack: originalLocationObj.rack,
                    shelf: originalLocationObj.shelf,
                },
                //pass in modified data
                modified: {
                    id: value.id,
                    name: name,
                    bin: value.bin,
                    //what we mutate
                    pickZoneId: value.pickZoneId,
                    warehouseId: warehouseId,
                    active: value.active,
                    aisle: value.aisle,
                    side: value.side,
                    rack: value.rack,
                    shelf: value.shelf,
                },
            },
        }).then(
            //success
            (ret) => {
                console.log("Successfully edited location.");
                //update UI data
                gridApi!.onFilterChanged();
            },
            //failure
            (err) => {
                //log the error
                console.error("Error editing location:", err);
                alert(err.message);
            }
        );
    };

    //used to initialize VForm
    //editModal.original does not exist until a row is clicked,
    //so we need to initialize it to a default value (emptyLocationObj) otherwise
    const originalLocationObj: ILocation = editModal.original
        ? {
              id: editModal.original.id,
              name: editModal.original.name,
              pickZoneId: editModal.original.pickZoneId,
              pickZone: editModal.original.pickZone,
              warehouseId: editModal.original.warehouseId,
              aisle: editModal.original.aisle,
              side: editModal.original.side,
              rack: editModal.original.rack,
              shelf: editModal.original.shelf,
              bin: editModal.original.bin,
              active: editModal.original.active,
              deleted: editModal.original.deleted,
              productCount: editModal.original.productCount,
          }
        : emptyLocationObj;

    const onAssign = () => {
        //edge case: if the dropdown is empty and the 'OK' button is clicked
        if (!assignPickZoneId.current) return;

        if (!window.confirm("Are you sure you want to assign this pick zone to ALL these locations? TRIPLE CHECK")) return;

        setLoading(true);

        //is the 'assign' pick zone cart or shelf?
        const isAssignPickZoneCart = data.pickZones.items.find((pickZone) => pickZone.id === assignPickZoneId.current)?.isCart ?? false;

        let count = 0;

        //need to call onFilterChanged() after all runEdit() calls complete
        let promises: Promise<void>[] = [];

        gridApi!.forEachNode((rowNode: IRowNode<any>, index: number) => {
            const row = rowNode.data;

            //catch when a 'cart' pick zone is assigned to a 'shelf' location (and vice versa)
            if (isAssignPickZoneCart === row.pickZone.isCart) {
                const promise: Promise<void> = runEdit({
                    variables: {
                        //pass in original data
                        original: {
                            id: row.id,
                            name: row.name,
                            bin: row.bin,
                            pickZoneId: row.pickZoneId,
                            warehouseId: row.warehouseId,
                            active: row.active,
                            aisle: row.aisle,
                            side: row.side,
                            rack: row.rack,
                            shelf: row.shelf,
                        },
                        //pass in modified data
                        modified: {
                            id: row.id,
                            name: row.name,
                            bin: row.bin,
                            //what we mutate
                            pickZoneId: assignPickZoneId.current,
                            warehouseId: row.warehouseId,
                            active: row.active,
                            aisle: row.aisle,
                            side: row.side,
                            rack: row.rack,
                            shelf: row.shelf,
                        },
                    },
                }).then(
                    //success
                    (ret) => {
                        ++count;
                    },
                    //failure
                    (err) => {
                        console.error("Error editing pick zone:", err);
                        alert(err.message);
                    }
                );

                promises.push(promise);
            }
        });

        //run after all runEdit() calls complete
        Promise.all(promises).then((ret) => {
            //Edge Case:
            //if the pick zone filter is set, then we need to switch the pick zone filter to the
            //assigned pick zone so AG Grid is not blank once the relevant pick zones are edited
            //if no locations are affected, then there is no need to change the filter
            if (pickZoneIdFilter.current && count > 0) pickZoneIdFilter.current = assignPickZoneId.current;

            gridApi!.onFilterChanged();
            setLoading(false);
        });
    };
    ////////////////////////////////////////// EDIT //////////////////////////////////////////

    ////////////////////////////////////////// DELETE //////////////////////////////////////////
    const onDelete = (id: string) => {
        runDelete({ variables: { ids: [id] } }).then(
            //success
            (ret) => {
                console.log("Successfully deleted location.");
                //update UI data
                gridApi!.onFilterChanged();
            },
            //failure
            (err) => {
                //log the error
                console.error("Error deleting location:", err);
                alert(err.message);
            }
        );
    };

    const onBulkDelete = () => {
        if (!window.confirm("This will delete ALL locations currently displayed - are you sure?")) return;

        const ids: string[] = [];
        if (gridApi) {
            gridApi!.forEachNode((rowNode: IRowNode<ILocation>) => {
                const row = rowNode.data;
                if (row && row.productCount === 0) ids.push(row.id);
            });
        }

        runDelete({ variables: { ids: ids } }).then(
            //success
            (ret) => {
                console.log("Successfully deleted locations.");
                //update UI data
                gridApi!.onFilterChanged();
            },
            //failure
            (err) => {
                //log the error
                console.error("Error deleting location:", err);
                alert(err.message);
            }
        );
    };
    ////////////////////////////////////////// DELETE //////////////////////////////////////////

    ////////////////////////////////// PRINT ///////////////////////////////////////
    const onPrintLabels = () => {
        if (!window.confirm("This will print ALL locations currently displayed - are you sure?")) return;

        const ids: string[] = [];
        if (gridApi) {
            gridApi!.forEachNode((rowNode: IRowNode<ILocation>) => {
                const row = rowNode.data;
                if (row) ids.push(row.id);
            });
        }

        onStartPrint(ids);
    };

    const availablePrinters = dataPrinters.printers.items.filter((x) => x.paperWidth === 2 || x.paperWidth === 3);
    availablePrinters.sort(
        (a, b) => a.sortOrder - b.sortOrder || a.type.localeCompare(b.type) || a.description.localeCompare(b.description)
    );

    const onStartPrint = (ids: string[]) => {
        setLocationIdsToPrint(ids);
        if (selectedPrinterId === "" && dataPrinters.printers.items.filter((x) => x.paperWidth === 3).length > 0)
            setSelectedPrinterId(dataPrinters.printers.items.filter((x) => x.paperWidth === 3)[0].id);
        else if (selectedPrinterId === "" && dataPrinters.printers.items.length > 0)
            setSelectedPrinterId(dataPrinters.printers.items[0].id);
        setShowPrintModal(true);
    };

    const onFinishPrint = () => {
        const selectedPrinter = availablePrinters.filter((x) => x.id === selectedPrinterId)[0];
        if (!selectedPrinter) {
            alert("Please select a printer");
            return;
        }
        runPrint({
            variables: {
                ids: locationIdsToPrint,
                labelWidth: selectedPrinter.paperWidth || 3,
                labelHeight: selectedPrinter.paperHeight || 1.75,
                printerId: selectedPrinter.id,
            },
        }).then(
            //success
            (ret) => {
                alert("Successfully printed location labels.");
                setShowPrintModal(false);
            },
            //failure
            (err) => {
                //log the error
                console.error("Error printing location lables", err);
                alert(err.message);
            }
        );
    };

    return (
        <>
            <p>
                <Button variant="white" onClick={() => setShowAddModal(true)}>
                    Add new location
                </Button>
            </p>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Locations/Bins</Card.Header>
                <Card.Body>
                    <div className="d-flex justify-content-between mb-4">
                        <div className="d-flex align-items-center">
                            <Button variant="primary" className="me-2" onClick={() => setShowFilterModal(true)}>
                                <i className="bi bi-filter"></i>
                            </Button>
                            <Button variant="primary" className="me-2" onClick={onClearFilters}>
                                Clear
                            </Button>
                            <Button variant="white" className="me-2" onClick={onPrintLabels} disabled={loading}>
                                Print labels
                            </Button>
                            {canBulkDelete ? (
                                <Button variant="danger" className="me-2" onClick={onBulkDelete} disabled={!canBulkDelete}>
                                    Delete all shown
                                </Button>
                            ) : null}
                        </div>
                        <Form.Group className="d-flex align-items-center">
                            <Form.Label className="mb-0 me-3 text-nowrap">Assign pick zone: </Form.Label>
                            <Form.Select
                                className="me-2"
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    assignPickZoneId.current = event.target.value;
                                }}
                            >
                                <option value=""></option>
                                <optgroup label="Shelving">
                                    {shelvingPickZones.map((pickZone: IPickZone) => {
                                        return (
                                            <option key={pickZone.id} value={pickZone.id}>
                                                {pickZone.name}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                                <optgroup label="Cart">
                                    {cartPickZones.map((pickZone: IPickZone) => {
                                        return (
                                            <option key={pickZone.id} value={pickZone.id}>
                                                {pickZone.name}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                            </Form.Select>
                            {loading ? (
                                <Button variant="primary" disabled>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="visually-hidden">Loading...</span>
                                </Button>
                            ) : (
                                <Button variant="primary" onClick={onAssign}>
                                    OK
                                </Button>
                            )}
                        </Form.Group>
                    </div>
                    <div className="ag-theme-alpine mt-3">
                        <AgGridReact
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                            }}
                            onGridReady={onGridReady}
                            tooltipShowDelay={0}
                            columnDefs={[...columns]}
                            domLayout="autoHeight"
                            rowModelType={"infinite"}
                            paginationPageSize={100}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={2}
                            infiniteInitialRowCount={1}
                            maxBlocksInCache={2}
                            pagination={true}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                        ></AgGridReact>
                    </div>
                </Card.Body>
            </Card>

            <Modal show={editModal.show} onHide={onCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Location</Modal.Title>
                </Modal.Header>
                <VForm onSubmit={onEdit} initialValue={originalLocationObj}>
                    <Modal.Body>
                        <Form.Group as={Row} className="mb-3" controlId="formEdit_Name">
                            <VLabel title="Name" valueName="name" column sm={3} />
                            <Col sm={9}>
                                {/*@ts-ignore*/}
                                <VControl
                                    valueName="name"
                                    type="text"
                                    disabled={editFormType !== "CUSTOM"}
                                    required={editFormType === "CUSTOM"}
                                    dynamicValue={
                                        editFormType === "CUSTOM"
                                            ? undefined
                                            : (value: any) => {
                                                  let displayValue = "";

                                                  if (editFormType === "CART") {
                                                      //first half
                                                      displayValue = value.aisle + value.rack;
                                                      //second half
                                                      if (value.shelf !== "" || value.bin !== "") {
                                                          displayValue = displayValue + "-" + value.shelf + value.bin;
                                                      }
                                                  } else {
                                                      //first half
                                                      displayValue = value.side + value.rack;
                                                      //second half
                                                      if (value.shelf !== "" || value.bin !== "") {
                                                          displayValue = displayValue + "-" + value.shelf + value.bin;
                                                      }
                                                  }

                                                  return displayValue;
                                              }
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <div className={editFormType === "CUSTOM" ? "d-none" : ""}>
                            <Form.Group as={Row} className="mb-3" controlId="formEdit_Aisle">
                                <VLabel title={editFormType === "CART" ? "Cart Name" : "Aisle"} valueName="aisle" column sm={3} />
                                <Col sm={9}>
                                    <VControl
                                        valueName="aisle"
                                        type="text"
                                        required={editFormType !== "CUSTOM"}
                                        pattern={editFormType === "CART" ? undefined : "[-A-Za-z]+"}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className={editFormType === "CART" ? "d-none" : "mb-3"} controlId="formEdit_Side">
                                <VLabel title="Side / Section" valueName="side" column sm={3} />
                                <Col sm={9}>
                                    <VControl
                                        valueName="side"
                                        type="text"
                                        required={editFormType !== "CUSTOM"}
                                        pattern={editFormType === "CART" ? "\\#" : "[A-Za-z]*"}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formEdit_Rack">
                                <VLabel title="Rack / Side" valueName="rack" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="rack" type="text" required={editFormType !== "CUSTOM"} pattern="[0-9]+" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formEdit_Shelf">
                                <VLabel title="Shelf" valueName="shelf" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="shelf" type="text" required={editFormType !== "CUSTOM"} pattern="[A-Za-z]*" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formEdit_Bin">
                                <VLabel title="Bin" valueName="bin" column sm={3} />
                                <Col sm={9}>
                                    <VControl valueName="bin" type="text" required={editFormType !== "CUSTOM"} pattern="[0-9]+" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formEdit_Active">
                                <Col sm={{ span: 9, offset: 3 }}>
                                    <VCheck valueName="active" label="Active" />
                                </Col>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                        <Button
                            variant="white"
                            onClick={() => {
                                setEditModal({ show: false });
                                onStartPrint([originalLocationObj.id]);
                            }}
                        >
                            Print
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setEditModal({ show: false });
                                onDelete(originalLocationObj.id);
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="white"
                            onClick={() => {
                                setEditModal({ show: false });
                            }}
                            className="me-auto"
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>

            <Modal show={showAddModal} onHide={onCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>
                            Pick Zone
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Select
                                onChange={(event) => {
                                    const pickZoneId = event.target.value;
                                    const pickZone = data.pickZones.items.find((x) => x.id === pickZoneId);
                                    setFormPickZoneId(event.target.value);
                                    //if empty <option> clicked (TS check)
                                    if (pickZone === undefined) return;
                                    setFormIsCart(pickZone.isCart);
                                    setFormIsCustom(false);
                                }}
                            >
                                <option></option>
                                <optgroup label="Shelving">
                                    {shelvingPickZones.map((pickZone: IPickZone) => {
                                        return (
                                            <option key={pickZone.id} value={pickZone.id}>
                                                {pickZone.name}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                                <optgroup label="Cart">
                                    {cartPickZones.map((pickZone: IPickZone) => {
                                        return (
                                            <option key={pickZone.id} value={pickZone.id}>
                                                {pickZone.name}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <VForm onSubmit={onAdd} initialValue={emptyLocationObj} reloadKey={formPickZoneId + Number(formIsCustom)}>
                    <Modal.Body className={formPickZoneId === "" ? "d-none" : ""}>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Name">
                            <VLabel title="Name" valueName="name" column sm={3} />
                            <Col sm={9}>
                                {/*@ts-ignore*/}
                                <VControl
                                    valueName="name"
                                    type="text"
                                    disabled={!formIsCustom}
                                    required={formIsCustom}
                                    dynamicValue={
                                        formIsCustom
                                            ? undefined
                                            : (value: any) => {
                                                  let displayValue = "";

                                                  if (formIsCart) {
                                                      //first half
                                                      displayValue = value.aisle + value.rack;
                                                      //second half
                                                      if (value.shelf !== "" || value.bin !== "") {
                                                          displayValue = displayValue + "-" + value.shelf + value.bin;
                                                      }
                                                  } else {
                                                      let rack = value.rack;
                                                      let shelf = value.shelf;
                                                      let bin = value.bin;

                                                      //bulk rack
                                                      if (value.rack.includes(",") || value.rack.includes("-")) {
                                                          rack = "[" + value.rack + "]";
                                                      }
                                                      //bulk shelf
                                                      if (value.shelf.includes(",") || value.shelf.includes("-")) {
                                                          shelf = "[" + value.shelf + "]";
                                                      }
                                                      //bulk bin
                                                      if (value.bin.includes(",") || value.bin.includes("-")) {
                                                          bin = "[" + value.bin + "]";
                                                      }

                                                      //first half
                                                      displayValue = value.side + rack;
                                                      //second half
                                                      if (value.shelf !== "" || value.bin !== "") {
                                                          displayValue = displayValue + "-" + shelf + bin;
                                                      }
                                                  }

                                                  return displayValue;
                                              }
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Custom">
                            <Col sm={{ span: 9, offset: 3 }}>
                                {/*need onChange() due to React rules*/}
                                <Form.Check
                                    label="Custom"
                                    checked={formIsCustom}
                                    onClick={() => {
                                        setFormIsCustom(!formIsCustom);
                                    }}
                                    onChange={() => {}}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Aisle">
                            <VLabel title={formIsCart ? "Cart Name" : "Aisle"} valueName="aisle" column sm={3} />
                            <Col sm={9}>
                                <VControl valueName="aisle" type="text" required={!formIsCustom} disabled={formIsCustom} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={formIsCart ? "d-none" : "mb-3"} controlId="formAdd_Side">
                            <VLabel title="Side / Section" valueName="side" column sm={3} />
                            <Col sm={9}>
                                <VControl
                                    valueName="side"
                                    type="text"
                                    required={formIsCart ? false : !formIsCustom}
                                    disabled={formIsCustom}
                                    pattern={formIsCustom ? undefined : "[A-Za-z]+"}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Rack">
                            <VLabel title={formIsCart ? "Cart Number" : "Rack / Side"} valueName="rack" column sm={3} />
                            <Col sm={9}>
                                <VControl
                                    valueName="rack"
                                    type="text"
                                    required={!formIsCustom}
                                    disabled={formIsCustom}
                                    pattern={formIsCustom ? undefined : formIsCart ? "[0-9]+" : "[0-9,-]+"}
                                />
                                <Form.Text className={formIsCart ? "d-none" : "text-muted"}>
                                    Use commas ( , ) and hyphens ( - ) to bulk add (e.g. 2,7-9,11).
                                </Form.Text>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Shelf">
                            <VLabel title="Shelf" valueName="shelf" column sm={3} />
                            <Col sm={9}>
                                <VControl
                                    valueName="shelf"
                                    type="text"
                                    required={!formIsCustom}
                                    disabled={formIsCustom}
                                    pattern={formIsCustom ? undefined : "[A-Za-z,-]+"}
                                />
                                <Form.Text className="text-muted">Use commas ( , ) and hyphens ( - ) to bulk add (e.g. A,C-E,Y).</Form.Text>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Bin">
                            <VLabel title="Bin" valueName="bin" column sm={3} />
                            <Col sm={9}>
                                <VControl
                                    valueName="bin"
                                    type="text"
                                    required={!formIsCustom}
                                    disabled={formIsCustom}
                                    pattern={formIsCustom ? undefined : "[0-9,-]+"}
                                />
                                <Form.Text className="text-muted">
                                    Use commas ( , ) and hyphens ( - ) to bulk add (e.g. 2,7-9,11).
                                </Form.Text>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAdd_Active">
                            <Col sm={{ span: 9, offset: 3 }}>
                                <VCheck valueName="active" label="Active" />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" disabled={formPickZoneId === ""}>
                            Add
                        </Button>
                        <Button variant="white" onClick={onCloseAddModal} className="me-auto">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </VForm>
            </Modal>

            <Modal show={showFilterModal} onHide={onCloseFilterModal}>
                <Form
                    onSubmit={(event: React.BaseSyntheticEvent) => {
                        event.preventDefault();
                        onFilter(event);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control id="nameFilter" defaultValue={nameFilter.current} type="text" />
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Aisle</Form.Label>
                                            <Form.Control id="aisleFilter" defaultValue={aisleFilter.current} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Side</Form.Label>
                                            <Form.Control id="sideFilter" defaultValue={sideFilter.current} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Rack</Form.Label>
                                            <Form.Control id="rackFilter" defaultValue={rackFilter.current} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Shelf</Form.Label>
                                            <Form.Control id="shelfFilter" defaultValue={shelfFilter.current} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Bin</Form.Label>
                                            <Form.Control id="binFilter" defaultValue={binFilter.current} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Pick Zone</Form.Label>
                                            <Form.Select id="pickZoneIdFilter" defaultValue={pickZoneIdFilter.current}>
                                                <option value=""></option>
                                                <optgroup label="Shelving">
                                                    {shelvingPickZones.map((pickZone: IPickZone) => {
                                                        return (
                                                            <option key={pickZone.id} value={pickZone.id}>
                                                                {pickZone.name}
                                                            </option>
                                                        );
                                                    })}
                                                </optgroup>
                                                <optgroup label="Cart">
                                                    {cartPickZones.map((pickZone: IPickZone) => {
                                                        return (
                                                            <option key={pickZone.id} value={pickZone.id}>
                                                                {pickZone.name}
                                                            </option>
                                                        );
                                                    })}
                                                </optgroup>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Pickable</Form.Label>
                                            <Form.Select id="pickableFilter" defaultValue={pickableFilter.current}>
                                                <option value=""></option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" onClick={onCloseFilterModal}>
                            Apply
                        </Button>
                        <Button variant="white" onClick={onCloseFilterModal} className="me-auto">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showPrintModal}
                onHide={() => {
                    setShowPrintModal(false);
                }}
            >
                <Form
                    onSubmit={(event: React.BaseSyntheticEvent) => {
                        event.preventDefault();
                        onFinishPrint();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Print location labels ({locationIdsToPrint.length})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {availablePrinters.map((printer, index) => (
                                <Col sm={{ span: 10, offset: 1 }} key={printer.id}>
                                    <Form.Group className="mb-2">
                                        <Form.Check
                                            id={"printer" + index}
                                            type="radio"
                                            checked={printer.id === selectedPrinterId}
                                            onClick={() => {
                                                setSelectedPrinterId(printer.id);
                                            }}
                                            label={printer.description}
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Print
                        </Button>
                        <Button
                            variant="white"
                            onClick={() => {
                                setShowPrintModal(false);
                            }}
                            className="me-auto"
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default WarehouseLocations;
