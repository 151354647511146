import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../components/pageheader/PageHeader";
import { VButton, VCheck, VControl, VForm, VLabel } from "@shane32/vform";
import { useMutation, useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import Loading from "../../../components/loading/Loading";
import ZBreadcrumb from "../../../components/breadcrumb/ZBreadcrumb";
import SalesChannelSummary from "./SalesChannelSummary";

interface IQueryResult {
    salesChannel: ISalesChannel;
}

interface ISalesChannel {
    id: string;
    name: string;
    sortOrder: number;
    active: boolean;
}

interface ISalesChannelModel {
    id: string;
    name: string;
    sortOrder: string;
    active: boolean;
}

interface IEditResult {
    salesChannel: {
        edit: ISalesChannel;
    };
}

interface IEditVariables {
    original: ISalesChannel;
    modified: ISalesChannel;
}

interface IDeleteResult {
    shippingCarrier: {
        delete: string;
    };
}
interface IModal {
    show: boolean;
    original?: ISalesChannel;
}
const hiddenModal: IModal = {
    show: false,
};

const SalesChannelQuery = `
query ($id: ID!){
  salesChannel(id: $id){
    name
    id
    sortOrder
    active
  }
}
`;

const SalesChannelMutation = `
mutation ($original: SalesChannelInput!, $modified: SalesChannelInput!) {
    salesChannel {
        edit (original: $original, modified: $modified) {
                id 
                name 
                sortOrder 
                active
        }
    }
}`;
const SalesChannelDetails = () => {
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(SalesChannelQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const [runEdit] = useMutation<IEditResult, IEditVariables>(SalesChannelMutation);
    const history = useHistory();

    //delete mutation
    const [runDelete] = useMutation<IDeleteResult, { id: string }>("mutation ($id: ID!) { salesChannel { delete (id: $id) } }");

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    //display loading if waiting for data to load
    if (!data) return <Loading />;
    if (!data.salesChannel) return <ErrorDisplay onClick={refetch}>Shipping Carrier ID #{id}</ErrorDisplay>;

    const onShowModal = () => {
        setModal({
            show: true,
        });
    };

    //hide modal
    const onHideModal = () => {
        setModal({ ...hiddenModal });
    };
    // const history = useHistory();
    const originalModel: ISalesChannelModel = {
        id: data.salesChannel.id,
        name: data.salesChannel.name,
        sortOrder: data.salesChannel.sortOrder.toString(),
        active: data.salesChannel.active,
    };

    const onSaveChanges = (modified: ISalesChannelModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEdit({
            variables: {
                //pass in original data
                original: {
                    id: originalModel.id,
                    name: originalModel.name,
                    sortOrder: parseFloat(originalModel.sortOrder),
                    active: originalModel.active,
                },
                //pass in modified data
                modified: {
                    id: modified.id,
                    name: modified.name,
                    sortOrder: parseFloat(modified.sortOrder),
                    active: modified.active,
                },
            },
        }).then(
            (ret) => {
                data.salesChannel = ret.data.salesChannel.edit;
                setSaving(false);
                onHideModal();
            },
            (err) => {
                setSaving(false);
                console.error("Error editing salesChannel", err);
                alert(err.message);
            }
        );
    };
    const onDelete = () => {
        //=== DELETE ===
        const id = originalModel.id;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this sales channel?")) return;
        //disable form controls
        setSaving(true);
        //start delete operation via graphql mutation
        runDelete({ variables: { id: id } }).then(
            //success
            () => {
                //redirects to the main supplier page
                setSaving(false);
                let path = `/setup/saleschannels`;
                history.push(path);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error deleting sales Channel", err);
                //display the error message
                alert(err.message);
            }
        );
    };
    return (
        <>
            <ZBreadcrumb url={`/setup/saleschannels/`} name="SalesChannels">
                <PageHeader badge={originalModel.active ? "ACTIVE" : "INACTIVE"} onEdit={onShowModal}>
                    {data.salesChannel.name}
                </PageHeader>
            </ZBreadcrumb>
            <Switch>
                <Route path="/setup/saleschannels/:id" exact component={SalesChannelSummary} />
            </Switch>

            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit new Sales Channel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicCode">
                                    <VLabel title="Name" valueName="name" column sm={3} />
                                    <VControl valueName="name" type="text" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicName">
                                    <VLabel title="Sort Order" valueName="sortOrder" column sm={3} />
                                    <VControl valueName="sortOrder" type="text" pattern="^\d+$" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <VCheck label="Active" valueName="active" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        <VButton type="button" variant="danger" onClick={onDelete}>
                            Delete
                        </VButton>
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default SalesChannelDetails;
