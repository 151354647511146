import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <h2>Home page</h2>
            <Row>
                <Col>
                    <Link to="/mobile" style={{ fontSize: "20px" }}>
                        &#128241;
                    </Link>
                </Col>
                {/* <Col>2 of 2</Col> */}
            </Row>
        </>
    );
};

export default Home;
