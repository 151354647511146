import { useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useParams } from "react-router-dom";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import useAttachmentUploader from "../../../hooks/useAttachmentUploader";
import MobileLoading from "../../mobile/components/MobileLoading";
import Attachments, { IAttachment } from "../../../components/misc/attachments/Attachments";

interface IPurchaseOrderAttachmentsQueryResponse {
    purchaseOrder: {
        attachments: Array<IAttachment>;
    };
}

interface IPurchaseOrderAttachmentsQueryVariables {
    purchaseOrderId: string;
}

const deletePurchaseOrderAttachmentMutation = `
mutation ($attachmentId: ID!, $purchaseOrderId: ID) {
    attachments {
      deleteAttachment(
        attachmentId: $attachmentId
        purchaseOrderId: $purchaseOrderId
      )
    }
  }
`;

interface IDeletePurchaseOrderAttachmentMutationVariables {
    attachmentId: string;
    purchaseOrderId: string;
}

interface IDeletePurchaseOrderAttachmentMutationResponse {
    attachments: {
        deleteAttachment: boolean;
    };
}

interface IPurchaseOrdersAttachmentRouteParams {
    id: string;
}

const PurchaseOrderAttachments = () => {
    const routeParams = useParams<IPurchaseOrdersAttachmentRouteParams>();
    const purchaseOrderAttachmentsBaseQuery = `
        purchaseOrder(id: ${routeParams.id}) {
            attachments {
                id
                dateUploaded
                url
                data
                isLabel
                filename
            }
        }
    `;

    const purchaseOrderAttachmentsQuery = `
        query {
            ${purchaseOrderAttachmentsBaseQuery}
        }
    `;
    const attachmentUploader = useAttachmentUploader<IPurchaseOrderAttachmentsQueryResponse>(
        { purchaseOrderId: routeParams.id },
        purchaseOrderAttachmentsBaseQuery
    );
    const {
        data: purchaseOrderAttachmentsQueryData,
        error: purchaseOrderAttachmentsQueryError,
        refetch: purchaseOrderAttachmentsQueryRefetch,
        loading: purchaseOrderAttachmentsQueryLoading,
    } = useQuery<IPurchaseOrderAttachmentsQueryResponse, IPurchaseOrderAttachmentsQueryVariables>(purchaseOrderAttachmentsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            purchaseOrderId: routeParams.id,
        },
    });

    const [mutationLoading, setMutationLoading] = useState(false);
    const [runDeleteAttachmentMutation] = useMutation<
        IDeletePurchaseOrderAttachmentMutationResponse,
        IDeletePurchaseOrderAttachmentMutationVariables
    >(deletePurchaseOrderAttachmentMutation);

    if (purchaseOrderAttachmentsQueryError)
        return <ErrorDisplay onClick={purchaseOrderAttachmentsQueryRefetch}>{purchaseOrderAttachmentsQueryError.message}</ErrorDisplay>;

    const postUploadAttachmentsResult = attachmentUploader.postAttachmentUploadQueryResult?.purchaseOrder?.attachments ?? [];
    let baseAttachments = purchaseOrderAttachmentsQueryData?.purchaseOrder?.attachments ?? [];
    if (postUploadAttachmentsResult.length > 0) baseAttachments = postUploadAttachmentsResult;

    const attachments = [
        { title: "Labels", attachments: baseAttachments.filter((attachment) => attachment.isLabel) },
        { title: "Other Attachments", attachments: baseAttachments.filter((attachment) => !attachment.isLabel) },
    ];

    const isLoading = [purchaseOrderAttachmentsQueryLoading].some((loading) => loading);

    const deleteAttachmentAsync = async (attachmentId: string) => {
        if (!window.confirm("Are you sure you want to delete this attachment?")) return;
        if (!purchaseOrderAttachmentsQueryData) return;
        try {
            setMutationLoading(true);
            const res = await runDeleteAttachmentMutation({
                variables: {
                    attachmentId: attachmentId,
                    purchaseOrderId: routeParams.id,
                },
            });
            if (!res.data.attachments.deleteAttachment) return;
            purchaseOrderAttachmentsQueryData.purchaseOrder.attachments = baseAttachments.filter((x) => x.id !== attachmentId);
            if (attachmentUploader.postAttachmentUploadQueryResult) {
                attachmentUploader.postAttachmentUploadQueryResult.purchaseOrder.attachments =
                    attachmentUploader.postAttachmentUploadQueryResult.purchaseOrder.attachments.filter((x) => x.id !== attachmentId);
            }
        } catch (err: any) {
            alert((err as GraphQLError)?.message ?? (err as Error)?.message ?? "An unknown error occurred.");
        } finally {
            setMutationLoading(false);
        }
    };

    return (
        <>
            {attachmentUploader.attachmentUploaderElement}

            {mutationLoading && <MobileLoading fullscreen />}

            {isLoading ? (
                <div style={{ width: 100, height: 100 }}>
                    <MobileLoading />
                </div>
            ) : (
                <>
                    {!attachments.reduce((acc, val) => acc + val.attachments.length, 0) ? (
                        <></>
                    ) : (
                        <>
                            <h4>Attachments:</h4>
                            <hr />
                            <Attachments attachments={attachments} deleteAttachmentAsync={deleteAttachmentAsync} />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PurchaseOrderAttachments;
