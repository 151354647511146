import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { useState, useEffect, useRef } from "react";
import { Card, Modal, Table } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import PageHeader from "../../../components/pageheader/PageHeader";
import StrictOmit from "../../../types/StrictOmit";

const returnReasonQueryBase = `
id
deleted
description
sortOrder
`;

const addReturnReasonMutation = `
mutation ($value: ReturnReasonInput!) {
    returnReason {
      add (value: $value) {
        ${returnReasonQueryBase}
      }
    }
  }
`;

interface IAddReturnReasonMutationResult {
    returnReason: {
        add: IReturnReason;
    };
}

interface IAddReturnReasonMutationVariables {
    value: IReturnReasonInput;
}

const deleteReturnReasonMutation = `
mutation ($id: ID!) {
    returnReason {
      delete (id: $id)
    }
  }
`;

interface IDeleteReturnReasonMutationResult {
    returnReason: {
        delete: string;
    };
}

interface IDeleteReturnReasonMutationVariables {
    id: string;
}

const editReturnReasonMutation = `
mutation ($original: ReturnReasonInput!, $modified: ReturnReasonInput!) {
    returnReason {
      edit (original: $original, modified: $modified) {
        ${returnReasonQueryBase}
      }
    }
  }
`;

interface IEditReturnReasonMutationResult {
    returnReason: {
        edit: IReturnReason;
    };
}

interface IEditReturnReasonMutationVariables {
    original: IReturnReasonInput;
    modified: IReturnReasonInput;
}

const returnReasonsQuery = `
query {
    returnReasons {
      items {
        ${returnReasonQueryBase}
      }
    }
  }
`;

interface IReturnReason {
    id: string;
    deleted: boolean;
    description: string;
    sortOrder: number;
}

interface IReturnReasonInput extends StrictOmit<IReturnReason, "deleted"> {}

interface IReturnReasonQueryResult {
    returnReasons: {
        items: Array<IReturnReason>;
    };
}

const ReturnReasonsIndex = () => {
    const [activeEditReturnReason, setActiveEditReturnReason] = useState<IReturnReason | null>(null);
    const activeEditReturnReasonDescriptionRef = useRef<HTMLInputElement | null>(null);
    const activeAddReturnReasonDescriptionRef = useRef<HTMLInputElement | null>(null);
    const [mutationLoading, setMutationLoading] = useState(false);
    const [showAddReturnReasonModal, setShowAddReturnReasonModal] = useState(false);
    const {
        loading: returnReasonsLoading,
        data: returnReasonsData,
        error: returnReasonError,
        refetch: returnReasonRefetch,
    } = useQuery<IReturnReasonQueryResult>(returnReasonsQuery, {
        fetchPolicy: "no-cache",
    });
    const [runAddReturnReasonMutation] = useMutation<IAddReturnReasonMutationResult, IAddReturnReasonMutationVariables>(
        addReturnReasonMutation
    );
    const [runDeleteReturnReasonMutation] = useMutation<IDeleteReturnReasonMutationResult, IDeleteReturnReasonMutationVariables>(
        deleteReturnReasonMutation
    );
    const [runEditReturnReasonMutation] = useMutation<IEditReturnReasonMutationResult, IEditReturnReasonMutationVariables>(
        editReturnReasonMutation
    );

    useEffect(() => {}, []);

    if (returnReasonError) return <ErrorDisplay onClick={returnReasonRefetch}>{returnReasonError.message}</ErrorDisplay>;

    const loading = [returnReasonsLoading, mutationLoading].some((x) => x);

    if (loading) return <Loading />;

    const returnReasons = returnReasonsData?.returnReasons?.items ?? [];

    const addReturnReason = () => {
        if (loading || !returnReasonsData) return;
        const newReturnReasonDescription = activeAddReturnReasonDescriptionRef?.current?.value;
        if (!newReturnReasonDescription) return alert("Return reason description is not allowed to be null.");
        const newReturnReason: IReturnReasonInput = {
            description: newReturnReasonDescription,
            id: "0",
            sortOrder: 0,
        };
        setMutationLoading(true);
        runAddReturnReasonMutation({ variables: { value: newReturnReason } })
            .then(
                (res) => {
                    const copy = [...returnReasonsData.returnReasons.items];
                    copy.push(res.data.returnReason.add);
                    returnReasonsData.returnReasons.items = copy;
                },
                (error: GraphQLError) => {
                    return alert(error.message);
                }
            )
            .catch((error) => {
                return alert(error);
            })
            .finally(() => {
                setMutationLoading(false);
                setShowAddReturnReasonModal(false);
            });
    };

    const deleteReturnReason = (id: string) => {
        if (loading || !returnReasonsData || !id) return;
        setMutationLoading(true);
        runDeleteReturnReasonMutation({ variables: { id: id } })
            .then(
                (res) => {
                    const copy = [...returnReasonsData.returnReasons.items];
                    const match = copy.findIndex((x) => x.id === res.data.returnReason.delete);
                    copy.splice(match, 1);
                    returnReasonsData.returnReasons.items = copy;
                },
                (error: GraphQLError) => {
                    return alert(error.message);
                }
            )
            .catch((error) => {
                return alert(error);
            })
            .finally(() => {
                setMutationLoading(false);
                setActiveEditReturnReason(null);
            });
    };

    const editReturnReason = (activeEditReturnReason: IReturnReason) => {
        if (loading || !returnReasonsData) return;
        const returnReasonDescription = activeEditReturnReasonDescriptionRef?.current?.value;
        if (!returnReasonDescription) return alert("Return reason description is not allowed to be null.");
        const original: IReturnReasonInput = {
            description: activeEditReturnReason.description,
            id: activeEditReturnReason.id,
            sortOrder: activeEditReturnReason.sortOrder,
        };
        const modified: IReturnReasonInput = { ...original, description: returnReasonDescription };
        setMutationLoading(true);
        runEditReturnReasonMutation({ variables: { modified: modified, original: original } })
            .then(
                (res) => {
                    const match = returnReasonsData.returnReasons.items.findIndex((x) => x.id === res.data.returnReason.edit.id);
                    returnReasonsData.returnReasons.items[match] = res.data!.returnReason.edit;
                },
                (error: GraphQLError) => {
                    return alert(error.message);
                }
            )
            .catch((error) => {
                return alert(error);
            })
            .finally(() => {
                setMutationLoading(false);
                setActiveEditReturnReason(null);
            });
    };

    return (
        <>
            <PageHeader>Return Reasons</PageHeader>
            <div style={{ marginBottom: 20 }}>
                <button className="btn btn-primary" onClick={() => setShowAddReturnReasonModal(true)}>
                    Add Return Reason
                </button>
            </div>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Return Reasons</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {returnReasons
                                .filter((x) => !x.deleted)
                                .map((returnReason) => {
                                    return (
                                        <tr key={returnReason.id}>
                                            <td>{returnReason.description}</td>
                                            <td style={{ textAlign: "end" }}>
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() => setActiveEditReturnReason(returnReason)}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={showAddReturnReasonModal} size="lg" backdrop={true} onHide={() => setShowAddReturnReasonModal(false)}>
                <>
                    <Modal.Header closeButton>Add Return Reason</Modal.Header>
                    <Modal.Body>
                        <input ref={activeAddReturnReasonDescriptionRef} style={{ width: "100%" }} />
                    </Modal.Body>
                    <Modal.Footer style={{ display: "flex", gap: 10 }}>
                        <button className="btn btn-primary" onClick={() => addReturnReason()}>
                            Add
                        </button>
                        <button className="btn btn-secondary" onClick={() => setShowAddReturnReasonModal(false)}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </>
            </Modal>
            <Modal show={activeEditReturnReason !== null} size="lg" backdrop={true} onHide={() => setActiveEditReturnReason(null)}>
                {activeEditReturnReason && (
                    <>
                        <Modal.Header closeButton>Edit Return Reason</Modal.Header>
                        <Modal.Body>
                            <input
                                ref={activeEditReturnReasonDescriptionRef}
                                style={{ width: "100%" }}
                                defaultValue={activeEditReturnReason.description}
                            />
                        </Modal.Body>
                        <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", gap: 10 }}>
                                <button className="btn btn-primary" onClick={() => editReturnReason(activeEditReturnReason)}>
                                    Save
                                </button>
                                <button className="btn btn-secondary" onClick={() => setActiveEditReturnReason(null)}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-danger" onClick={() => deleteReturnReason(activeEditReturnReason.id)}>
                                    Delete
                                </button>
                            </div>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
};

export default ReturnReasonsIndex;
