import React from "react";

interface IProps {
    gap?: number;
    children: React.ReactNode;
}

const MobileButtonCol = ({ gap, children }: IProps) => {
    return <div style={{ display: "inline-flex", flex: 1, gap: gap ?? 10 }}>{children}</div>;
};

export default MobileButtonCol;
