import { Route, Switch } from "react-router-dom";
import SelectSupplier from "./SelectSupplier";
import IntakeProduct from "./IntakeProduct";
import AddOrPrintBarcodeContainer from "./AddOrPrintBarcodeContainer";

const BlindIntakeController = () => {
    return (
        <Switch>
            <Route exact path="/mobile/blindintake/selectsupplier">
                <SelectSupplier />
            </Route>
            <Route exact path="/mobile/blindintake/intakeproduct">
                <IntakeProduct />
            </Route>
            <Route exact path="/mobile/blindintake/addorprintbarcode">
                <AddOrPrintBarcodeContainer />
            </Route>
        </Switch>
    );
};

export default BlindIntakeController;
