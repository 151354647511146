import { useContext } from "react";
import { GraphQLError } from "@shane32/graphql";
import { AuthContext } from "@zboxglobal/zboxauth";

interface IAnchorElementBase extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    children: React.ReactNode;
}

interface IProps extends IAnchorElementBase {}

//additional props from "IProps" and the "children" props must be separate from the "rested" props, else the "rest" props ("...attributes" in this instance) will contain these props and the compiler will complain.
/** A custom component that mimics an anchor element, but adds a users access token to the request header. */
const AuthorizedAnchor = ({ children, ...attributes }: IProps) => {
    const auth = useContext(AuthContext);
    const onClickHandler = async (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        attributes: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
    ) => {
        e.preventDefault();
        try {
            await auth.client.UpdateToken();
            var token = auth.client.GetAccessToken(); //null if not logged in.
            if (token == null) throw new Error("You are not logged in.");
            if (!attributes.href) throw new Error("The href attribute is missing.");
            const response = await fetch(attributes.href, {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                var fileURL = URL.createObjectURL(await response.blob());
                if (attributes.target === "_blank") {
                    const newTab = window.open();
                    if (newTab) {
                        newTab.location.href = fileURL;
                    } else {
                        throw new Error("Failed to open new tab.");
                    }
                } else {
                    window.location.href = fileURL;
                }
            } else {
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error: any) {
            alert(
                (error as GraphQLError)?.message ?? (error as Error)?.message ?? "An error has occured while azuthorizing <a> link request."
            );
        } finally {
        }
    };

    return (
        <a onClick={async (e) => await onClickHandler(e, attributes)} {...attributes}>
            {children}
        </a>
    );
};

export default AuthorizedAnchor;
