import { createElement, CSSProperties, useRef, useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import MobileLoading from "../../../../components/MobileLoading";
import PrimaryHeader from "../../../../components/PrimaryHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import PrinterType from "../../../../../../enums/PrinterType";
import StationSetType from "../../../../../../enums/StationSetTypes";
import { useSettingsValue } from "../../../../../../hooks/useSettingsValue";
import printerSVG from "../../../../../../images/printer.svg";
import useConfirm from "../../../../hooks/useConfirm";
import useSelectModal, { ISelect } from "../../../../hooks/useSelectModal";

const generateManualShippingLabelMutation = `
mutation (
    $labelProviderId: ID,
    $shippingServiceId: ID!,
    $warehouseId: ID!,
    $shipToAddressId: ID!,
    $weight: Decimal!,
    $shippingLabelAdvancedOptionsInput: ShippingLabelAdvancedOptionsInput,
    $printerId: ID!
  ) {
    api {
      shippingLabel {
        generateManualShippingLabel(
          labelProviderId: $labelProviderId,
          shippingServiceId: $shippingServiceId,
          warehouseId: $warehouseId,
          shipToAddressId: $shipToAddressId,
          weight: $weight,
          shippingLabelAdvancedOptionsInputModel: $shippingLabelAdvancedOptionsInput
        ) {
          print (printerId: $printerId)
        }
      }
    }
  }
`;

interface IGenerateManualShippingLabelMutationVariables {
    labelProviderId: string | null;
    shippingServiceId: string;
    warehouseId: string;
    shipToAddressId: string;
    weight: number;
    shippingLabelAdvancedOptionsInput: {
        billToParty: string | null;
        billToAccount: string | null;
        billToPostalCode: string | null;
        billToCountryCode: string | null;
    } | null;
    printerId: string;
}

const printersQuery = `
query {
  printers {
    items {
      id
      description
      type
      stationSet {
        name
        type
      }
    }
  }
}
`;

interface IPrintersQueryVariables {}

interface IPrintersQueryResult {
    printers: {
        items: Array<{
            id: string;
            description: string;
            type: PrinterType;
            stationSet: {
                name: string;
                type: StationSetType;
            } | null;
        }>;
    };
}

const shippingServicesMutation = `
query {
    shippingServices {
      items {
        id
        name
        shippingCarrier {
          name
        }
      }
    }
  }
`;

interface IShippingServicesQueryResult {
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
}

const PrintMailInnovationsBagLabel = () => {
    const [manualLoading, setManualLoading] = useState(false);
    const { loading: printersLoading, data: printersData } = useQuery<IPrintersQueryResult, IPrintersQueryVariables>(printersQuery, {
        fetchPolicy: "no-cache",
    });
    const { loading: shippingServicesLoading, data: shippingServicesData } = useQuery<IShippingServicesQueryResult>(
        shippingServicesMutation,
        {
            fetchPolicy: "no-cache",
        }
    );
    const [runGenerateManualShippingLabelMutation] = useMutation<{}, IGenerateManualShippingLabelMutationVariables>(
        generateManualShippingLabelMutation
    );
    const { settingsValue: defaultWarehouseId, loading: defaultWarehouseIdLoading } = useSettingsValue("defaultWarehouseId");
    const { settingsValue: mailInnovationsAddressId, loading: mailInnovationsAddressIdLoading } = useSettingsValue(
        "mailinnovations.default.shipToAddressId"
    );
    const { settingsValue: mailInnovationsBillToParty, loading: mailInnovationsBillToPartyLoading } = useSettingsValue(
        "mailinnovations.default.billToParty"
    );
    const { settingsValue: mailInnovationsBillToAccount, loading: mailInnovationsBillToAccountLoading } = useSettingsValue(
        "mailinnovations.default.billToAccount"
    );
    const { settingsValue: mailInnovationsBillToPostalCode, loading: mailInnovationsBillToPostalCodeLoading } = useSettingsValue(
        "mailinnovations.default.billToPostalCode"
    );
    const { settingsValue: mailInnovationsBillToCountryCode, loading: mailInnovationsBillToCountryCodeLoading } = useSettingsValue(
        "mailinnovations.default.billToCountryCode"
    );
    const { confirmModal, showConfirmModal } = useConfirm();
    const { selectModal, showSelectModal } = useSelectModal();
    const shippingServiceRef = useRef<HTMLSelectElement>(null);
    const weightRef = useRef<HTMLInputElement>(null);

    const loading = [
        manualLoading,
        printersLoading,
        defaultWarehouseIdLoading,
        mailInnovationsAddressIdLoading,
        shippingServicesLoading,
        mailInnovationsBillToPartyLoading,
        mailInnovationsBillToAccountLoading,
        mailInnovationsBillToPostalCodeLoading,
        mailInnovationsBillToCountryCodeLoading,
    ].some((x) => x);

    const nextButtonHandler = () => {};

    const printAsync = async () => {
        const stringWeight = weightRef.current?.value?.trim() ?? "0";
        const weight = +stringWeight;
        if (loading) return;
        if (!printersData) {
            await showConfirmModal("Printer data is null or undefined", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!defaultWarehouseId) {
            await showConfirmModal("No 'defaultWarehouseId' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!mailInnovationsAddressId) {
            await showConfirmModal("No 'mailinnovations.default.shipToAddressId' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!mailInnovationsBillToParty) {
            await showConfirmModal("No 'mailinnovations.default.billToParty' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!mailInnovationsBillToAccount) {
            await showConfirmModal("No 'mailinnovations.default.billToAccount' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!mailInnovationsBillToPostalCode) {
            await showConfirmModal("No 'mailinnovations.default.billToPostalCode' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!mailInnovationsBillToCountryCode) {
            await showConfirmModal("No 'mailinnovations.default.billToCountryCode' setting found", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!shippingServiceRef.current?.value) {
            await showConfirmModal("No shipping service selected", "Error", { confirm: "Confirm" }, true);
            return;
        }
        if (!weight) {
            await showConfirmModal("Invalid weight", "Error", { confirm: "Confirm" }, true);
            return;
        }

        try {
            setManualLoading(true);
            const printers = printersData?.printers?.items ?? [];
            const packPrinters = printers.filter(
                (printer) => printer.type === PrinterType.Label && printer.stationSet?.type === StationSetType.Pack
            );
            if (packPrinters.length < 1) {
                await showConfirmModal("No printers were found at any pack station", "No Printers", { confirm: "Confirm" }, true);
                return;
            }
            const printerId =
                packPrinters.length === 1
                    ? packPrinters[0].id
                    : packPrinters.length < 1
                    ? null
                    : await (
                          await showSelectModal(
                              "Select Printer",
                              packPrinters.map((printer) => ({ id: printer.id, description: printer.description } as ISelect))
                          )
                      )?.id;
            if (!printerId) return;
            await runGenerateManualShippingLabelMutation({
                variables: {
                    labelProviderId: null,
                    shippingServiceId: shippingServiceRef.current.value,
                    warehouseId: defaultWarehouseId,
                    shipToAddressId: mailInnovationsAddressId,
                    weight: weight,
                    printerId: printerId,
                    shippingLabelAdvancedOptionsInput: {
                        billToParty: mailInnovationsBillToParty ?? null,
                        billToAccount: mailInnovationsBillToAccount ?? null,
                        billToPostalCode: mailInnovationsBillToPostalCode ?? null,
                        billToCountryCode: mailInnovationsBillToCountryCode ?? null,
                    },
                },
            });
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const printButtonStyle: CSSProperties = { color: "blue", textDecoration: "underline", border: "none", backgroundColor: "transparent" };
    const imgStyle: CSSProperties = { width: 100, height: 100 };

    return (
        <>
            {loading && <MobileLoading fullscreen />}

            <PrimaryHeader Title="Print" IncludeBackButton IncludeHomeButton BackButtonText="< Back" />

            <SecondaryHeader NextButtonHandler={nextButtonHandler} Title={"Print Mail Innovations Bag Label"} />

            <div style={{ margin: 10, display: "flex", alignItems: "center", flexDirection: "column", gap: 10 }}>
                <select ref={shippingServiceRef} className="form-control" id="shippingServiceSelect">
                    <option value={""}>Select Shipping Service</option>
                    {shippingServicesData?.shippingServices?.items?.map((shippingService) => {
                        return (
                            <option key={shippingService.id} value={shippingService.id}>
                                {shippingService.shippingCarrier.name} - {shippingService.name}
                            </option>
                        );
                    })}
                </select>

                <input ref={weightRef} id="weightInput" className="form-control" placeholder="Enter Weight" />

                <button style={printButtonStyle} onClick={async () => await printAsync()}>
                    <img src={printerSVG} style={imgStyle} alt="an svg of a printer" />
                </button>
            </div>

            {createElement(confirmModal)}

            {createElement(selectModal)}
        </>
    );
};

export default PrintMailInnovationsBagLabel;
