const scanBinQueryBase = `
mobile {
  displayPicks(
    warehouseId: $warehouseId
    displayPicksFiltersInputModel: $displayPicksFiltersInput
    displayPicks: $displayPicks
  ) {
    location {
      id
      name
      aisle
      rack
      side
      shelf
      bin
    }
    product {
      id
      sku
      description
      barcodes {
        barcode
      }
    }
    pickSaleModels {
      saleId
      quantityToPick
    }
  }
}
pickZone(id: $pickZoneId) {
  name
  locations {
    id
    name
    products {
      stockOnHand
      product {
        id
        sku
      }
    }
  }
}
`;
export default scanBinQueryBase;
