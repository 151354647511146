export interface IStationSet {
    id: number;
    name: string;
    stashPickZone: {
        locations: ILocation[];
    };
}

export interface IProduct {
    productId: string;
    stockOnHand: number;
    saleId: string;
}

export interface ILocation {
    id: string;
    name: string;
    products: IProduct[];
}

export interface ICart {
    /** PickZoneId */
    id: string;
    isCart: boolean;
    locations: ILocation[];
}

export interface IPackStation {
    id: string;
    /** All of the carts at the station. THESE ARE PICKZONES!!!!! */
    carts: ICart[];
    stationSet: IStationSet;
}

export interface IFindStationsQueryResult {
    mobile: {
        findStations: IPackStation[];
    };
}

const locationsBase = `
locations {
  id
  name
  products {
    productId
    saleId
    stockOnHand
  }
}
`;

export const findStationsQuery = `
query ($warehouseId: ID!, $stationSetType: StationSetType!, $stationType: StationType!) {
  mobile {
    findStations(warehouseId: $warehouseId, stationSetType: $stationSetType, stationType: $stationType) {
      id
      carts {
        id
        isCart
        ${locationsBase}
      }
      stationSet {
        id
        name
        stashPickZone {
          ${locationsBase}
        }
      }
    }
  }
}
`;
