import styles from "./MobileTable.module.scss";

interface IProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {
    isActiveTable?: boolean;
    ignoreSpacing?: boolean;
    children: React.ReactNode;
}

const MobileTable = ({ isActiveTable, ignoreSpacing, children, ...tableAttributes }: IProps) => {
    const { className, ...remainingAttributes } = tableAttributes;
    return (
        <table
            className={`${className ?? ""} ${styles.container} ${isActiveTable ? styles.activeTable : ""} ${
                ignoreSpacing ? styles.ignoreSpacing : ""
            }`}
            {...remainingAttributes}
        >
            {children}
        </table>
    );
};

export default MobileTable;
