export const zbdbInterchangeSearchQuery = `
query ($interchangePartNumber: String!) {
    mobile {
      searchZbDbByInterchange(interchangePartNumber: $interchangePartNumber) {
        productId
        sku
        matchedInterchanges
      }
    }
  }
`;

export interface IZbdbInterchangeSearchQueryVariables {
    interchangePartNumber: string;
}

export interface IZbdbInterchangeSearchQueryResult {
    mobile: {
        searchZbDbByInterchange: Array<{
            productId: string;
            sku: string;
            matchedInterchanges: Array<string>;
        }>;
    };
}
