import { useQuery } from "@shane32/graphql";
import { ZbDbGraphQLClient } from "../../../..";
import { IImagesQueryResult, IImagesQueryVariables, imagesQuery } from "../../graphs/queries/ImagesQuery";
import MobileLoading from "../MobileLoading";
import styles from "./ActiveProductImages.module.scss";

interface IProps {
    sku: string | null | undefined;
}

const ActiveProductImages = (props: IProps) => {
    const { loading, data } = useQuery<IImagesQueryResult, IImagesQueryVariables>(imagesQuery, {
        variables: !props.sku ? undefined : { sku: props.sku },
        client: ZbDbGraphQLClient,
        skip: !props.sku,
        fetchPolicy: "cache-and-network",
    });

    const images = data?.part?.medias
        ? data.part.medias
              .filter((x) => x.sortOrder)
              .sort((x, y) => x.sortOrder - y.sortOrder)
              .concat(data.part.medias.filter((x) => !x.sortOrder))
        : [];
    return (
        <div className={styles.imageSetContainer}>
            <div className={styles.image}>
                {loading ? <MobileLoading /> : <img src={images.find((x) => x)?.original} alt={`${props.sku ?? ""}`} />}
            </div>
            <div className={styles.image}>{/*Nothing here yet. Picture of product in package will go here.*/}</div>
        </div>
    );
};

export default ActiveProductImages;
