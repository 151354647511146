import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import useWarehouseMetrics from "../../hooks/useWarehouseMetrics";
import { useEffect, useMemo, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import UserScoreType from "../../enums/UserScoreType";
import MobileLoading from "../mobile/components/MobileLoading";
import caseFormatter from "../mobile/helpers/caseFormatter";
import styles from "./ReportsOld.module.scss";
import GroupBy from "../../helpers/GroupBy";

const exportActionsReportMutation = `
mutation ($startDate: DateOnly!, $endDate: DateOnly!) {
    api {
      importExport {
        export {
          userScoresReport(startDate: $startDate, endDate: $endDate)
        }
      }
    }
  }
`;

interface IExportActionsReportMutationVariables {
    startDate: string;
    endDate: string;
}

interface IExportActionsReportMutationResponse {
    api: {
        importExport: {
            export: {
                userScoresReport: string;
            };
        };
    };
}

const saleFulfillmentsQuery = `
query ($shipDateFrom: DateTimeOffset, $shipDateTo: DateTimeOffset, $shippingServiceIds: [ID], $first: Int, $after: String) {
    saleFulfillments(
      shipDateFrom: $shipDateFrom
      shipDateTo: $shipDateTo
      shippingServiceIds: $shippingServiceIds
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      items {
        shippingService {
          id
          name
          shippingCarrier {
            id
            name
          }
        }
        weight
      }
    }
  }
`;

interface ISaleFulfillment {
    shippingService: {
        id: string;
        name: string;
        shippingCarrier: {
            id: string;
            name: string;
        };
    } | null;
    weight: number | null;
}

interface ISaleFulfillmentsQueryResult {
    saleFulfillments: {
        pageInfo: {
            hasNextPage: boolean;
            endCursor: string | null;
        };
        items: Array<ISaleFulfillment>;
    };
}

interface ISaleFulfillmentsQueryVariables {
    shipDateFrom: string;
    shipDateTo: string;
    shippingServiceIds?: (string | null)[];
    first: number | null;
    after: string | null;
}

interface ISaleFulfillmentsState {
    items: Array<ISaleFulfillment>;
    loading: boolean;
    error: string | null;
}

enum TimeOfDayType {
    StartOfDay = "StartOfDay",
    EndOfDay = "EndOfDay",
}

const userScoresQuery = `
query ($from: DateOnly!, $to: DateOnly!) {
    users {
      userId
      firstName
      lastName
      scores(from: $from, to: $to) {
        date
        type
        score
      }
    }
  }
`;

interface IUserScoresQueryVariables {
    from: string;
    to: string;
}

interface IScore {
    date: string;
    type: UserScoreType;
    score: number;
}

interface IUserScoresQueryResult {
    users: Array<{
        userId: string;
        firstName: string | null;
        lastName: string | null;
        scores: Array<IScore>;
    }>;
}

interface IUserScoresInfo {
    usersName: string;
    scores: Array<IScore>;
}

interface IUserScoresInfoModalData extends IUserScoresInfo {
    isModalOpen: boolean;
}

interface IDailyFulfillmentReportModalData {
    isModalOpen: boolean;
    shippingCarrierName: string;
    saleFulfillments: Array<ISaleFulfillment>;
}

const ReportsOld = () => {
    const formatDate = (date: Date, timeOfDayType: TimeOfDayType) => {
        // get the timezone offset in minutes
        const timezoneOffset = date.getTimezoneOffset();
        // adjust the date to the next day in local time zone
        date.setDate(date.getDate());
        // adjust the hours based on the timezone offset.
        switch (timeOfDayType) {
            case TimeOfDayType.StartOfDay:
                // sets the time to 00:00:00 so that the date range starts at the beginning of the day
                date.setHours(0 - timezoneOffset / 60, 0, 0, 0);
                break;
            case TimeOfDayType.EndOfDay:
                // sets the time to 23:59:59 so that the date range is inclusive
                date.setHours(23 - timezoneOffset / 60, 59, 59, 999);
                break;
        }
        // convert the date to ISO string format (which represents the date and time in UTC)
        return date.toISOString();
    };
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = String(today.getMonth() + 1).padStart(2, "0");
    const todayDay = String(today.getDate()).padStart(2, "0");
    const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`;
    const shipDateFrom = useMemo(() => formatDate(new Date(), TimeOfDayType.StartOfDay), []);
    const shipDateTo = useMemo(() => formatDate(new Date(), TimeOfDayType.EndOfDay), []);
    const { data: warehouseMetricsData, error: warehouseMetricsError, loading: warehouseMetricsLoading } = useWarehouseMetrics();
    const {
        data: userScoresData,
        error: userScoresError,
        loading: userScoresLoading,
    } = useQuery<IUserScoresQueryResult, IUserScoresQueryVariables>(userScoresQuery, {
        fetchPolicy: "no-cache",
        variables: {
            from: todayFormatted,
            to: todayFormatted,
        },
    });

    const compareFunctions = {
        nameAsc: (a: IUserScoresInfo, b: IUserScoresInfo) => a.usersName.localeCompare(b.usersName),
        nameDesc: (a: IUserScoresInfo, b: IUserScoresInfo) => b.usersName.localeCompare(a.usersName),
        scoreAsc: (a: IUserScoresInfo, b: IUserScoresInfo) =>
            a.scores.reduce((acc, val) => acc + val.score, 0) - b.scores.reduce((acc, val) => acc + val.score, 0),
        scoreDesc: (a: IUserScoresInfo, b: IUserScoresInfo) =>
            b.scores.reduce((acc, val) => acc + val.score, 0) - a.scores.reduce((acc, val) => acc + val.score, 0),
    };
    const [scoreSortOrder, setScoreSortOrder] = useState<keyof typeof compareFunctions>("nameAsc");

    const userScores = (userScoresData?.users ?? [])
        .filter((user) => user.scores.length)
        .map((user) => {
            const name = caseFormatter(`${user.firstName ?? ""} ${user.lastName ?? ""}`.trim(), [" "]);
            return {
                usersName: !name ? "N/A" : name,
                scores: user.scores,
            };
        })
        .sort(compareFunctions[scoreSortOrder]);

    const [clickedUserScore, setClickedUserScore] = useState<IUserScoresInfoModalData>({
        isModalOpen: false,
        usersName: "",
        scores: [],
    });

    const [clickedDailyFulfillmentReport, setClickedDailyFulfillmentReport] = useState<IDailyFulfillmentReportModalData>({
        isModalOpen: false,
        shippingCarrierName: "",
        saleFulfillments: [],
    });

    const [runExportActionsReportMutation] = useMutation<IExportActionsReportMutationResponse, IExportActionsReportMutationVariables>(
        exportActionsReportMutation
    );
    const [isCurrentlyExportingUserScoresReport, setIsCurrentlyExportingUserScoresReport] = useState(false);
    const fromDateInputRef = useRef<HTMLInputElement>(null);
    const toDateInputRef = useRef<HTMLInputElement>(null);

    const [saleFulfillmentsState, setSaleFulfillmentsState] = useState<ISaleFulfillmentsState>({
        items: [],
        loading: false,
        error: null,
    });
    const [runSaleFulfillmentQuery] = useMutation<ISaleFulfillmentsQueryResult, ISaleFulfillmentsQueryVariables>(saleFulfillmentsQuery);
    useEffect(() => {
        (async () => {
            try {
                setSaleFulfillmentsState({ ...saleFulfillmentsState, loading: true });
                let hasNextPage = false;
                let endCursor: string | null = null;
                let items: ISaleFulfillment[] = [];
                do {
                    const result: ISaleFulfillmentsQueryResult | null | undefined = (
                        await runSaleFulfillmentQuery({
                            variables: {
                                shipDateFrom: shipDateFrom,
                                shipDateTo: shipDateTo,
                                first: 500,
                                after: endCursor,
                            },
                        })
                    ).data;
                    hasNextPage = result.saleFulfillments.pageInfo.hasNextPage;
                    endCursor = result.saleFulfillments.pageInfo.endCursor;
                    items = [...items, ...result.saleFulfillments.items];
                } while (hasNextPage);
                saleFulfillmentsState.items = items;
            } catch (error: any) {
                setSaleFulfillmentsState({
                    ...saleFulfillmentsState,
                    error: (error as GraphQLError)?.message ?? (error as Error)?.message ?? "An unknown error has occured",
                });
            } finally {
                setSaleFulfillmentsState({ ...saleFulfillmentsState, loading: false });
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const saleFulfillmentsGroup = GroupBy(saleFulfillmentsState.items, (item) => item.shippingService?.shippingCarrier?.name ?? "N/A");

    const exportUserScoresReport = () => {
        if (isCurrentlyExportingUserScoresReport) return;
        if (!toDateInputRef.current?.value) return alert("Please select a 'To' date");
        if (!fromDateInputRef.current?.value) return alert("Please select a 'From' date");
        const endDate = new Date(toDateInputRef.current.value).toISOString().split("T")[0];
        const startDate = new Date(fromDateInputRef.current.value).toISOString().split("T")[0];
        setIsCurrentlyExportingUserScoresReport(true);
        runExportActionsReportMutation({
            variables: {
                endDate: endDate,
                startDate: startDate,
            },
        }).then(
            (response) => {
                let returnedData = response.data.api.importExport.export.userScoresReport;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `ActionsReport.xlsx`;
                fileLink.click();
                setIsCurrentlyExportingUserScoresReport(false);
            },
            (error: GraphQLError) => {
                alert(error.message);
                setIsCurrentlyExportingUserScoresReport(false);
            }
        );
    };

    const onClickUserScoreHandler = async (userScoresInfo: IUserScoresInfo) => {
        setClickedUserScore({
            isModalOpen: true,
            usersName: userScoresInfo.usersName,
            scores: userScoresInfo.scores,
        });
    };

    const closeUserScoreModal = () => setClickedUserScore({ ...clickedUserScore, isModalOpen: false });

    const onClickDailyFulfillmentsReportHandler = async (key: string) => {
        const saleFulfillments = saleFulfillmentsGroup.get(key);
        if (!saleFulfillments) return alert(`Could not find values for shipping carrier name: '${key}'`);
        setClickedDailyFulfillmentReport({
            isModalOpen: true,
            shippingCarrierName: key,
            saleFulfillments: saleFulfillments,
        });
    };

    const closeDailyFulfillmentReportModal = () =>
        setClickedDailyFulfillmentReport({ ...clickedDailyFulfillmentReport, isModalOpen: false });

    return (
        <>
            <div className={styles.container}>
                <Card className={styles.cardStyle}>
                    <h3>User Scores Report</h3>
                    <div className={styles.userScoresReportCard}>
                        <div>
                            <h6>
                                <u>Daily Stats</u>
                            </h6>
                            {userScoresError && !userScoresLoading ? (
                                <div>Error loading "User Scores" data: {userScoresError.message}</div>
                            ) : (
                                <></>
                            )}
                            {userScoresLoading ? (
                                <div>
                                    <MobileLoading />
                                </div>
                            ) : (
                                <table className={`${styles.userScoresTable} ${styles.notModal}`}>
                                    <thead>
                                        <tr>
                                            <th onClick={() => setScoreSortOrder(scoreSortOrder === "nameAsc" ? "nameDesc" : "nameAsc")}>
                                                Name {scoreSortOrder === "nameAsc" && "▲"} {scoreSortOrder === "nameDesc" && "▼"}
                                            </th>
                                            <th onClick={() => setScoreSortOrder(scoreSortOrder === "scoreAsc" ? "scoreDesc" : "scoreAsc")}>
                                                Score {scoreSortOrder === "scoreAsc" && "▲"} {scoreSortOrder === "scoreDesc" && "▼"}
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userScores.map((userScoresInfo, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{userScoresInfo.usersName}</td>
                                                    <td>{userScoresInfo.scores.reduce((acc, val) => acc + val.score, 0)}</td>
                                                    <td>
                                                        <button onClick={() => onClickUserScoreHandler(userScoresInfo)}>ⓘ</button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div>
                            <h6>
                                <u>Custom Report</u>
                            </h6>
                            <label>From: </label>
                            <input
                                className="form-control"
                                style={{ maxWidth: 250 }}
                                disabled={isCurrentlyExportingUserScoresReport}
                                ref={fromDateInputRef}
                                type="date"
                            />
                            <label>To: </label>
                            <input
                                className="form-control"
                                style={{ maxWidth: 250 }}
                                disabled={isCurrentlyExportingUserScoresReport}
                                ref={toDateInputRef}
                                type="date"
                            />
                            <button
                                className="btn btn-primary mt-3"
                                disabled={isCurrentlyExportingUserScoresReport}
                                onClick={exportUserScoresReport}
                            >
                                Export User Scores Report
                            </button>
                        </div>
                    </div>
                </Card>
                <Card className={styles.cardStyle}>
                    <h3>Daily Fulfillment Reports</h3>
                    <div>
                        {saleFulfillmentsState.loading ? (
                            <div>Loading Sale Fulfillment data...</div>
                        ) : saleFulfillmentsState.error ? (
                            <div>Error loading "Sale Fulfillment" data: Sale Fulfillments Error: {saleFulfillmentsState.error ?? ""}</div>
                        ) : (
                            <table className={`${styles.userScoresTable} ${styles.notModal}`}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Count</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(saleFulfillmentsGroup)
                                        .filter((x) => x[1].length > 0)
                                        .map(([key, values]) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{values.length}</td>
                                                    <td>
                                                        <button onClick={() => onClickDailyFulfillmentsReportHandler(key)}>ⓘ</button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    <tr>
                                        <td>Total</td>
                                        <td>{saleFulfillmentsState.items.length}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </Card>
                <Card className={styles.cardStyle}>
                    <h3>Warehouse Metrics</h3>
                    {warehouseMetricsLoading ? (
                        <div>Loading Warehouse Metrics...</div>
                    ) : warehouseMetricsError ? (
                        <div>Error loading "Warehouse Metrics"</div>
                    ) : (
                        <div>
                            Estimated fulfillment: {warehouseMetricsData?.estFullfillmentTime}
                            <br />
                            SO's to pick and pack: {warehouseMetricsData?.needPickedCount}
                            <br />
                            SO's to pack: {warehouseMetricsData?.needPackedCount}
                            <br />
                            Minutes per SO pick: {warehouseMetricsData?.pickTime}
                            <br />
                            Minutes per SO pack: {warehouseMetricsData?.packTime}
                            <br />
                            Workers: {warehouseMetricsData?.workers}
                            <br />
                            Lunch Duration: {warehouseMetricsData?.lunchDuration}
                            <br />
                            Hour Lunch Starts: {warehouseMetricsData?.lunchStartHour}
                            <br />
                            Start time: {warehouseMetricsData?.startHour}
                            <br />
                            Last Updated: {today.toLocaleString()}
                            <br />
                        </div>
                    )}
                </Card>
            </div>

            <Modal show={clickedUserScore.isModalOpen} onHide={closeUserScoreModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{clickedUserScore.usersName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className={styles.userScoresTable}>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clickedUserScore.scores.map((scoreInfo, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{caseFormatter(scoreInfo.type, ["_"])}</td>
                                        <td>{scoreInfo.score}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={closeUserScoreModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={clickedDailyFulfillmentReport.isModalOpen} onHide={closeDailyFulfillmentReportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{clickedDailyFulfillmentReport.shippingCarrierName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className={styles.userScoresTable}>
                        <thead>
                            <tr>
                                <th>Shipping Service</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(
                                GroupBy(clickedDailyFulfillmentReport.saleFulfillments, (x) => x.shippingService?.name ?? "N/A")
                            ).map(([key, values]) => {
                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{values.length}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={closeDailyFulfillmentReportModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReportsOld;
