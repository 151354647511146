import Loading from "../../../../components/loading/Loading";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";

const authorizationUrlQuery = `
query($vendor: Boolean!) {
    integrations {
      amazon {
        authorizationUrl(vendor: $vendor)
      }
    }
  }
`;

interface IAuthorizationQuery {
    integrations: {
        amazon: {
            authorizationUrl: string;
        };
    };
}

const AmazonIndex = () => {
    const { data, error, refetch } = useQuery<IAuthorizationQuery, { vendor: boolean }>(authorizationUrlQuery, {
        variables: { vendor: false },
        fetchPolicy: "no-cache",
    });

    const {
        data: vendorData,
        error: vendorError,
        refetch: vendorRefetch,
    } = useQuery<IAuthorizationQuery, { vendor: boolean }>(authorizationUrlQuery, {
        variables: { vendor: true },
        fetchPolicy: "no-cache",
    });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (vendorError) return <ErrorDisplay onClick={vendorRefetch}>{vendorError.message}</ErrorDisplay>;

    if (!data || !vendorData) return <Loading />;

    return (
        <>
            <h5>Connect to Amazon</h5>
            <a target="_blank" rel="noopener noreferrer" href={data.integrations.amazon.authorizationUrl}>
                Connect to Amazon Seller Api
            </a>
            <a target="_blank" rel="noopener noreferrer" href={vendorData.integrations.amazon.authorizationUrl}>
                Connect to Amazon Vendor Api
            </a>
        </>
    );
};

export default AmazonIndex;
