import React, { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import StationSetType from "../../../../enums/StationSetTypes";
import StationType from "../../../../enums/StationTypes";
import { useMutation, useQuery } from "@shane32/graphql";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useScanner from "../../hooks/useScanner";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";

export enum ERole {
    Pack,
    Pick,
    StockTake,
    StockTransfer,
    Intake,
}

interface ICart {
    id: string;
}

interface IStationSet {
    name: string;
}

interface IFindStationForCartModel {
    id: string;
    stationSet: IStationSet;
    carts: ICart[];
}

interface IFindStationForCartQueryResult {
    mobile: {
        findStations: IFindStationForCartModel[];
    };
    pickZone: {
        name: string;
        locations: Array<{
            id: string;
            name: string;
            products: Array<{
                stockOnHand: number;
                product: {
                    id: string;
                    sku: string;
                };
            }>;
        }>;
    };
}

const findStationForCartQuery = `
    query ($warehouseId: ID!, $stationSetType: StationSetType!, $stationType: StationType!, $pickZoneId: ID!) {
      mobile {
        findStations(warehouseId: $warehouseId, stationSetType: $stationSetType, stationType: $stationType) {
          id
          stationSet {
            name
          }
          carts {
            id
          }
        }
      }
      pickZone(id: $pickZoneId) {
        name
        locations {
          id
          name
          products {
            stockOnHand
            product {
              id
              sku
            }
          }
        }
      }
    }
`;

export const moveCartMutation = `
    mutation ($id: ID!, $stationId: ID!) {
      pickZone {
        moveCart (id: $id, stationId: $stationId) {
          id,
          warehouseId,
          name,
          active,
          pickable,
          isCart,
          stationId,
          lastLocationId,
          priority
        }
      }
    }
`;

export interface IMoveCartMutationVariables {
    /** PickZone Id */
    id: string;
    stationId: string;
}

export interface IMoveCartMutationResult {
    id: string;
    warehouseId: string;
    name: string;
    active: boolean;
    pickable: true;
    isCart: boolean;
    stationId: string;
    lastLocationId: string;
    priority: number;
}

interface IFindStationForCartQueryVariables {
    warehouseId: string;
    stationSetType: StationSetType;
    stationType: StationType;
    pickZoneId: string;
}

interface IRoleAction {
    role: ERole;
    path: string;
    text: string;
}

interface IProps {
    stationSetType: StationSetType;
    stationType: StationType;
    role: ERole;
}

interface IRouteParams {
    cartId: string;
}

const Cart = (props: IProps) => {
    const [selectedStationId, setSelectedStationId] = useState<string>();
    const history = useHistory();
    const params = useParams<IRouteParams>();
    const [runMoveCartMutation] = useMutation<IMoveCartMutationResult, IMoveCartMutationVariables>(moveCartMutation);
    const [isMoveCartMutationLoading, setIsMoveCartMutationLoading] = useState(false);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");
    const {
        data: findStationForCartData,
        error: findStationForCartError,
        refetch: findStationForCartRefetch,
        loading: isFindStationForCartLoading,
    } = useQuery<IFindStationForCartQueryResult, IFindStationForCartQueryVariables>(findStationForCartQuery, {
        fetchPolicy: "no-cache",
        variables:
            !params.cartId || !defaultWarehouseId
                ? undefined
                : {
                      warehouseId: defaultWarehouseId,
                      stationSetType: props.stationSetType,
                      stationType: props.stationType,
                      pickZoneId: params.cartId,
                  },
        skip: !params.cartId,
    });

    const isLoading = [isMoveCartMutationLoading, defaultWarehouseIdLoading, isFindStationForCartLoading].some((x) => x === true);

    const onScan = (scannerValue: string) => {
        if (!isLoading) {
            setIsMoveCartMutationLoading(true);
            if (!findStationForCartData) {
                playNegativeTone();
                setIsMoveCartMutationLoading(false);
                return alert("No stations found");
            }

            if (!params.cartId) {
                playNegativeTone();
                setIsMoveCartMutationLoading(false);
                return alert("There is currently no cart selected");
            }

            const validStation = findStationForCartData.mobile.findStations.find((station) => station.stationSet.name === scannerValue);
            if (!validStation) {
                playNegativeTone();
                setIsMoveCartMutationLoading(false);
                return alert(`There are no valid stations with a name of '${scannerValue}'`);
            }

            const check = componentSpecificChecks();
            if (check) {
                playNegativeTone();
                setIsMoveCartMutationLoading(false);
                return alert(check);
            }

            moveCart(validStation.id);
        }
    };
    useScanner(onScan);

    if (!params.cartId) return <Redirect to="/mobile/selectrole" />;

    if (findStationForCartError) return <ErrorDisplay onClick={findStationForCartRefetch}>{findStationForCartError.message}</ErrorDisplay>;
    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    /**
     *  Several other routes eventually use this component to return the cart to the proper station.
     *  Each process varies slightly when returning the cart, so specific checks are needed to ensure proper cart return.
     * */
    const componentSpecificChecks = (): string | null => {
        switch (props.role) {
            case ERole.StockTransfer:
                if (findStationForCartData && findStationForCartData.pickZone.locations.some((l) => l.products.length > 0)) {
                    return "There are still products in the cart";
                }
        }
        return null;
    };

    const moveCart = (stationId: string) => {
        if (!params.cartId) return;
        runMoveCartMutation({
            variables: {
                id: params.cartId, //<-- this is a pickZoneId
                stationId: stationId,
            },
        })
            .then((res) => {
                setIsMoveCartMutationLoading(false);
                playPositiveTone();
                history.push("/mobile/selectrole");
            })
            .catch((error) => {
                playNegativeTone();
                setIsMoveCartMutationLoading(false);
                alert("There was an error with the move cart mutation.");
            });
    };

    const nextButtonHandler = () => {
        if (!findStationForCartData) return alert("No stations available for cart");
        if (selectedStationId === undefined) return alert("Please select a station");
        if (!params.cartId) return alert("There is currently no cart selected");

        const check = componentSpecificChecks();
        if (check) return alert(check);

        const validStation = findStationForCartData.mobile.findStations.find((station) => station.id === selectedStationId);
        if (!validStation) return alert(`Could not find a station with an id of '${selectedStationId}'`);

        setIsMoveCartMutationLoading(true);

        moveCart(validStation.id);
    };

    const selectStationHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => setSelectedStationId(e.currentTarget.id);

    const roleStartOverActions: IRoleAction[] = [
        { role: ERole.Intake, path: `/mobile/intake/${params.cartId}/selectintaketype`, text: "Select Intake Type" },
        //{ role: ERole.Pack, path: ``, text: "" },
        { role: ERole.Pick, path: `/mobile/pick/selectlist`, text: "Pick More" },
        //{ role: ERole.StockTake, path: ``, text: "" },
        { role: ERole.StockTransfer, path: `/mobile/stocktransfer/${params.cartId}/scanpickbin`, text: "Scan Another Pick Bin" },
    ];

    const startOverAction = roleStartOverActions.find((x) => x.role === props.role);

    return (
        <>
            {isLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader Title={`${findStationForCartData?.pickZone.name ?? "N/A"}`} />

            <SecondaryHeader
                Title="║▌ Scan Station"
                SecondaryTitle={findStationForCartData?.mobile.findStations?.find((x) => x.id === selectedStationId)?.stationSet.name}
                NextButtonHandler={nextButtonHandler}
            />

            <MobileTable>
                <thead>
                    <tr>
                        <td>Location</td>
                        <td>Active Member</td>
                        <td>Cart Qty</td>
                    </tr>
                </thead>
                <tbody>
                    {findStationForCartData?.mobile.findStations?.map((station) => {
                        const backgroundColor = selectedStationId === station.id ? "#CFE3FA" : "white";
                        return (
                            <tr
                                key={station.id}
                                id={station.id}
                                style={{ backgroundColor: backgroundColor }}
                                onClick={(e) => selectStationHandler(e)}
                            >
                                <td>{station.stationSet.name}</td>
                                <td>-</td>
                                <td>{station.carts.length}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>

            {startOverAction?.role === ERole.Intake ? (
                <></>
            ) : (
                <MobileButtonContainer>
                    <MobileButton
                        disabled={!startOverAction}
                        onClick={() => (startOverAction ? history.push(startOverAction.path) : void 0)}
                    >
                        {startOverAction?.text ?? "N/A"}
                    </MobileButton>
                </MobileButtonContainer>
            )}

            <div>
                <MobileTableTitle>Items On Cart</MobileTableTitle>

                <MobileTable>
                    <thead>
                        <tr>
                            <td>Bin</td>
                            <td>SKU</td>
                            <td>Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {!findStationForCartData ? (
                            <></>
                        ) : (
                            findStationForCartData.pickZone.locations.map((location) => {
                                const name = location.name;
                                return location.products.map((product) => {
                                    const sku = product.product.sku;
                                    const stock = product.stockOnHand;
                                    return (
                                        <tr key={`${name}${sku}${stock}`}>
                                            <td>{name}</td>
                                            <td>{sku}</td>
                                            <td>{stock}</td>
                                        </tr>
                                    );
                                });
                            })
                        )}
                    </tbody>
                </MobileTable>
            </div>
        </>
    );
};

export default Cart;
