import { useEffect, useRef } from "react";

/**
 * Regular expression breakdown:
 *
 * ^          : Anchors the match to the start of the string.
 *
 * 420        : Matches the literal characters "420".
 *
 * \d{5}      : Matches exactly five digits. These represent a ZIP code.
 *
 * .{20,}     : Matches any character (except a newline) at least 20 times.
 *
 * $          : Anchors the match to the end of the string.
 */
const uspsRegex = /^420\d{5}.{20,}$/;

/**
 * Regular expression breakdown:
 *
 * ^ : Anchors the match to the start of the string.
 *
 * | : This is an "or" operator. It allows us to match one of several patterns.
 *
 * [0-9]{12} : This pattern matches exactly 12 consecutive digits (0-9).
 *
 * 100\d{31} : This pattern matches the digits "100" followed by exactly 31 consecutive digits. This is used to match a specific format where "100" is followed by a long sequence of digits.
 *
 * \d{15} : This pattern matches exactly 15 consecutive digits (0-9).
 *
 * \d{18} : This pattern matches exactly 18 consecutive digits (0-9).
 *
 * 96\d{20} : This pattern matches the digits "96" followed by exactly 20 consecutive digits.
 *
 * 96\d{32} : This pattern matches the digits "96" followed by exactly 32 consecutive digits.
 *
 * $ : Anchors the match to the end of the string.
 */
const fedExRegex = /^([0-9]{12}|100\d{31}|\d{15}|\d{18}|96\d{20}|96\d{32})$/;

interface IScannerOptions {
    isPackage?: boolean;
}

function useScanner(callback: (value: string) => void, scannerOptions?: IScannerOptions) {
    // the set of characters entered
    const currentScanRef = useRef<string>("");

    // last time a key was pressed
    var lastTimeRef = useRef<Date>(new Date());

    // reference to the current callback
    var callbackRef = useRef(callback);
    callbackRef.current = callback; //updates with each state change (aka refresh/redraw)

    // only set up / clear the window.addEventListener upon component setup/teardown
    useEffect(() => {
        const barcodeScan = (e: KeyboardEvent) => {
            //if an element was clicked on at some point, before using the scanner, when the scanner finally presses the "Enter"
            //key, whatever element was last clicked and focused on will also trigger from the "Enter" button being "pressed".
            //so, this is used to remove focus from the current html element to prevent the "Enter" button from working on that element.
            const activeElement = document.activeElement as HTMLElement;
            //the only time we want to ignore this is if we are actively typing in an input element, so typing occurs as it should
            if (activeElement.tagName !== "INPUT") activeElement.blur();

            // reset string if longer than 100 ms since last keystroke
            var timeSinceLast = new Date().getTime() - lastTimeRef.current.getTime();
            if (timeSinceLast > 1000) currentScanRef.current = "";
            lastTimeRef.current = new Date();

            if (e.key === "Enter") {
                e.preventDefault();
                window.scrollTo(0, 0);
                if (scannerOptions?.isPackage && currentScanRef.current.match(uspsRegex)) {
                    //remove the first eight characters of the string if the barcode is identified as a USPS barcode
                    callbackRef.current(currentScanRef.current.substring(8));
                } else if (scannerOptions?.isPackage && currentScanRef.current.match(fedExRegex)) {
                    //keep the last 12 characters of the string if the barcode is identified as a FedEx barcode
                    callbackRef.current(currentScanRef.current.substring(currentScanRef.current.length - 12));
                } else if (currentScanRef.current !== "") callbackRef.current(currentScanRef.current);
                currentScanRef.current = "";
            } else {
                currentScanRef.current += e.key;
            }
        };
        window.addEventListener("keypress", barcodeScan);
        return () => window.removeEventListener("keypress", barcodeScan);
    }, [scannerOptions?.isPackage]);

    return;
}

export default useScanner;
