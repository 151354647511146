import { createElement, Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useScanner from "../../hooks/useScanner";
import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import SelectionType from "../../../../enums/SelectionType";
import typedParse from "../../helpers/typedParse";
import { IStockTransferSearchParams } from "./StockTransferController";
import { Modal } from "react-bootstrap";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import useLocationInputModal from "../../hooks/useLocationInputModal";
import { Filter } from "./ScanBinComponent";
import useLostSkuModal from "../../hooks/useLostSkuModal";
import { useActionBarContext } from "../../contexts/ActionBarContext/ActionBarContext";
import forgeURLSearchParams from "../../helpers/forgeURLSearchParameters";

const productQuery = `
query ($productId: ID!) {
    product(id: $productId) {
      id
      sku
      locationProducts {
        location {
          name
        }
        stockOnHand
      }
    }
  }
`;

interface ILocationProduct {
    location: {
        name: string;
    };
    stockOnHand: number;
}

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
        locationProducts: Array<ILocationProduct>;
    };
}

interface IProductQueryVariables {
    productId: string;
}

const locationQuery = `
query ($locationId: ID!) {
  location(id: $locationId) {
    id
    name
    warehouse {
      name
    }
  }
}
`;

interface ILocationQueryVariables {
    locationId: string;
}

interface ILocationQ {
    id: string;
    name?: string;
    warehouse?: {
        name?: string;
    };
}

interface ILocationQueryResult {
    location: ILocationQ;
}

const locationAndPickZoneQueryProperties = `
id
name
products {
    product {
        description
        id
        sku
        barcodes {
            barcode
        }
    }
    stockOnHand
}
`;

const locationAndPickZoneQuery = `
    location(id: $locationId) {
        ${locationAndPickZoneQueryProperties}
    }
`;

const query = `
    query ($locationId: ID!) {
      ${locationAndPickZoneQuery}
    }
`;

const moveProductToBinMutation = `
    mutation ($moveProductsToBin: [MoveProductToBin!]!, $locationId: ID!) {
      inventory {
        moveProductsToBin(moveProductsToBin: $moveProductsToBin)
      }
      query {
        ${locationAndPickZoneQuery}
      }
    }
`;

interface IMoveProductToBin {
    fromLocationId: string;
    toLocationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    fromLocationSelectionType: SelectionType;
    toLocationSelectionType: SelectionType;
}

interface IMoveProductToLocationProps extends IMoveProductToBin {
    sku: string;
}

interface IMoveProductsToBinMutationVariables {
    moveProductsToBin: IMoveProductToBin[];
    locationId: string;
}

interface IMoveProductsToBinMutationResult {
    query: IQueryResponse;
}

const stockTakeMutation = `
    mutation ($locationId: ID!, $productId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!) {
        inventory {
            stockTake(locationId: $locationId, productId: $productId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
        }
        query {
            ${locationAndPickZoneQuery}
        }
    }
`;

interface IStockTakeMutationResult {
    inventory: {
        stockTake: string;
    };
    query: IQueryResponse;
}

interface IStockTakeMutationVariables {
    locationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

interface IProduct {
    product: {
        description: string;
        id: string;
        sku: string;
        barcodes: Array<{
            barcode: string;
        }>;
    };
    stockOnHand: number;
}

interface ILocation {
    id: string;
    name: string;
    products: IProduct[];
}

interface IQueryResponse {
    location: ILocation;
}

interface IQueryVariables {
    locationId: string;
}

interface ILocationModalState {
    data: Array<ILocationProduct>;
    showLocationModal: boolean;
}

interface IRouteParams {
    locationId: string;
}

const PickBin = () => {
    const history = useHistory<Filter[]>();
    const actionBarContext = useActionBarContext();
    const location = useLocation();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IStockTransferSearchParams>(rawURLSearchParams.get("urlsp"));
    const params = useParams<IRouteParams>();
    const addProductInputRef = useRef<HTMLInputElement>(null);
    const mounted = useRef(true);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [sortKey, setSortKey] = useState("");
    const [count, setCount] = useState(0);
    const [showAddOrPrintBarcodeModal, setShowAddOrPrintBarcodeModal] = useState(false);
    const { locationInputModal, showLocationInputModal, open: isLocationInputModalOpen } = useLocationInputModal();
    const { selectModal, showSelectModal } = useSelectModal();
    const { confirmModal, showConfirmModal } = useConfirm();
    const { lostSkuModal, showLostSkuModal, isStockTakeLoading } = useLostSkuModal();
    const [runMoveProductToBinMutation] = useMutation<IMoveProductsToBinMutationResult, IMoveProductsToBinMutationVariables>(
        moveProductToBinMutation
    );
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runProductQuery] = useMutation<IProductQueryResult, IProductQueryVariables>(productQuery);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const [manualLoading, setManualLoading] = useState(false);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const firstLoad = useRef(true);
    const [productSelectionType, setProductSelectionType] = useState(SelectionType.None);
    const { multiMatchSelectorModal, showMultiMatchSelectorModal } = useMultiMatchSelectorModal();
    const [runStockTakeMutation] = useMutation<IStockTakeMutationResult, IStockTakeMutationVariables>(stockTakeMutation);
    const [locationModalState, setLocationModalState] = useState<ILocationModalState>({ data: [], showLocationModal: false });

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    const onScan = async (scannerValue: string) => {
        if (isLocationInputModalOpen) return;
        const runProductScan = async (scannedProduct: IProduct, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
            setLoading(true);
            //first thing, we need to determine if the numberOfSelectedItems is > 0. This will decide whether we increment the count, or are scanning a new item
            if (!firstProduct) return playNegativeTone();
            if (count > 0) {
                //numberOfSelectedItems > 0 and autoStow being on shouldn't be possible
                //if (sessionContext.autoStow === true) return playNegativeTone();
                //determine if the scannedProduct is the same as the item in the first position in the displayPicksInCurrentBin array. if it is, we need to increment the count
                if (firstProduct.product.id === scannedProduct.product.id) {
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    increment();
                } else {
                    playNegativeTone();
                    alert(`You already have a product in hand! Please only scan the same type of product or scan the cart!`);
                }
            }
            //if the numberOfSelectedItems is 0
            else {
                //if (sessionContext.autoStow === true) {
                //    //**IF THERE ARE MULTIPLE LOCATIONS ON A CART, AND "AUTO STOW" IS ENABLED, RETURN RIGHT AWAY. THIS IS NOT ALLOWED FOR AUTO STOW.
                //    if (doesCartHaveMultipleLocations) return playNegativeTone();

                //    const entry: IMoveProductToBin = {
                //        fromLocationId: params.locationId,
                //        productId: scannedProduct.product.id,
                //        quantity: 1,
                //        toLocationId: locations[0].id,
                //    };

                //    //run code to place item in the selected cart that you are using. the selected cart is only allowed to have one location (bin) for auto stow to work correctly
                //    await moveProductsToLocation([entry], setLoading);
                //} else {
                //if autoStow is disabled, we need to increment the count of the scanned product, and if the scanned product is not in the first position in the displayPicksInCurrentBin array,
                //we need to change the sort order of displayPicksInCurrentBin and also increment the count of the scanned product.
                if (firstProduct.product.id === scannedProduct.product.id) {
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    increment();
                } else {
                    //reaching this means the scanned product is not in the first position
                    //reorder the displayPicksInCurrentBin and increment count.
                    //first make sure the scanned product is in the products array
                    if (!products.find((x) => x.product.id === scannedProduct.product.id)) return playNegativeTone();
                    setProductSelectionType(SelectionType.Scanned);
                    playPositiveTone();
                    setSortKey(scannedProduct.product.id);
                    setCount(1);
                }
                //}
            }
        };

        const runLocationScan = async (
            scannedLocation: { locationId: string; locationName: string },
            setLoading: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
            //when scanning a cart, we need to make sure the firstProduct is not undefined. and count is not less than one.
            //that is the item that we are going to move to the cart.
            if (!firstProduct || count < 1) return playNegativeTone();
            if (firstProduct.product.sku === undefined) {
                playNegativeTone();
                return alert("Sku is null");
            }

            const entry: IMoveProductToLocationProps = {
                fromLocationId: params.locationId,
                productId: firstProduct.product.id,
                quantity: count,
                toLocationId: scannedLocation.locationId,
                fromLocationSelectionType: urlSearchParams.fromLocationSelectionType,
                productSelectionType: productSelectionType,
                toLocationSelectionType: SelectionType.Scanned,
                sku: firstProduct.product.sku,
            };

            await moveProductsToLocation([entry], setLoading, scannedLocation.locationName);
        };

        if (!loading && !manualLoading && !showAddProductModal) {
            try {
                setManualLoading(true);
                const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
                const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
                const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
                //check if there are matches for products and locations from the scanned value
                if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                    await showConfirmModal(
                        formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                        "Multiple Matches In Database",
                        { confirm: "Confirm" },
                        true
                    );
                } else if (barcodeProducts.length > 0) {
                    const allProductsInLocation = data?.location?.products ?? [];
                    if (barcodeProducts.length === 1) {
                        const scannedProduct = allProductsInLocation.find((x) => x.product.id === barcodeProducts[0].id);
                        if (scannedProduct) await runProductScan(scannedProduct, setManualLoading);
                        else playNegativeTone();
                    } else {
                        const matchingProductsInBin = barcodeProducts.flatMap((bp) =>
                            allProductsInLocation.filter((x) => x.product.id === bp.id)
                        );
                        if (matchingProductsInBin.length < 1) playNegativeTone();
                        else if (matchingProductsInBin.length === 1) await runProductScan(matchingProductsInBin[0], setManualLoading);
                        else {
                            const selectedValue = await showMultiMatchSelectorModal(
                                "Select Correct Product SKU",
                                barcodeProducts.map((x) => x.id),
                                MultiMatchDataType.Product
                            );
                            if (!selectedValue) return;
                            const scannedProduct = allProductsInLocation.find((x) => x.product.id === selectedValue.id);
                            if (scannedProduct) await runProductScan(scannedProduct, setManualLoading);
                            else playNegativeTone();
                        }
                    }
                } else if (barcodeLocations.length > 0) {
                    if (barcodeLocations.length === 1)
                        await runLocationScan({ locationId: barcodeLocations[0].id, locationName: scannerValue }, setManualLoading);
                    else {
                        const selectedValue = await showMultiMatchSelectorModal(
                            "Select Correct Location",
                            barcodeLocations.map((x) => x.id),
                            MultiMatchDataType.Location
                        );
                        if (!selectedValue) return;
                        await runLocationScan({ locationId: selectedValue.id, locationName: selectedValue.name }, setManualLoading);
                    }
                } else {
                    playNegativeTone();
                }
            } catch (error: any) {
                playNegativeTone();
                alert((error as GraphQLError)?.message ?? "Unknown error");
            } finally {
                setManualLoading(false);
            }
        }
    };
    useScanner(onScan);

    const { loading, refetch, data, error } = useQuery<IQueryResponse, IQueryVariables>(query, {
        variables: {
            locationId: params.locationId,
        },
        fetchPolicy: "no-cache",
    });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    if (!params.locationId || isNaN(+params.locationId)) {
        history.replace(`/mobile/stocktransfer/scanpickbin?${forgeURLSearchParams({ ...urlSearchParams })}`, history.location.state);
    }

    let products = data?.location?.products ?? [];
    if (urlSearchParams.matchingProductIds?.length) {
        products = products.filter((x) => urlSearchParams.matchingProductIds?.includes(x.product.id));
    }

    products.sort(
        (a, b) => (b.product.id === sortKey ? 1 : 0) - (a.product.id === sortKey ? 1 : 0) || a.product.sku.localeCompare(b.product.sku)
    );

    if (!loading) {
        if (products.length < 1) {
            if (firstLoad.current) {
                alert("Location contains no products! Please scan another bin to pick from!");
            }
            history.replace(`/mobile/stocktransfer/scanpickbin?${forgeURLSearchParams({ ...urlSearchParams })}`, history.location.state);
        } else {
            firstLoad.current = false;
        }
    }

    const maxItemCount = products.find((x) => x)?.stockOnHand ?? 0;

    let row1Bin1Color = "#CC0A3B";
    let row1HandColor = "black";
    let row2HandColor = "#CC0A3B";
    if (count > 0) {
        row1Bin1Color = "black";
        row1HandColor = "#CC0A3B";
        row2HandColor = "black";
    }

    const locationName = "Any";

    const firstProduct = products.find((x) => x);

    const decrement = () => {
        let value = count - 1;
        if (value > -1) setCount(value);
    };

    const increment = () => {
        let value = count + 1;
        if (value <= maxItemCount) setCount(value);
    };

    const nextButtonHandler = () => {};

    const tableRowOnClickHandler = (productId: string) => {
        window.scrollTo(0, 0);
        setCount(0);
        setSortKey(productId);
        setProductSelectionType(SelectionType.Selected);
    };

    const moveProductsToLocation = async (
        productsToMove: IMoveProductToLocationProps[],
        setLoading: React.Dispatch<React.SetStateAction<boolean>>,
        toLocationName: string
    ) => {
        try {
            const res = await runMoveProductToBinMutation({
                variables: {
                    moveProductsToBin: productsToMove.map((x) => {
                        return {
                            fromLocationId: x.fromLocationId,
                            productId: x.productId,
                            quantity: x.quantity,
                            toLocationId: x.toLocationId,
                            fromLocationSelectionType: x.fromLocationSelectionType,
                            productSelectionType: x.productSelectionType,
                            toLocationSelectionType: x.toLocationSelectionType,
                        } as IMoveProductToBin;
                    }),
                    locationId: params.locationId,
                },
            });
            const resData = res.data;
            let skusToRemoveCopy: string[] = [...(urlSearchParams.skusToRemove ?? [])];
            if (urlSearchParams.removeSkuAfterScan) {
                for (let product of productsToMove) {
                    if (!skusToRemoveCopy.includes(product.sku)) {
                        skusToRemoveCopy.push(product.sku);
                    }
                }
            }
            actionBarContext.setActionBarContent(
                `Transfered ${productsToMove.reduce((acc, val) => acc + val.quantity, 0)} product(s) to ${toLocationName}`
            );
            playPositiveTone();
            setCount(0);
            history.replace(
                `${history.location.pathname}?${forgeURLSearchParams({
                    ...urlSearchParams,
                    skusToRemove: skusToRemoveCopy,
                })}`,
                history.location.state
            );
            //update the items in the current bin and selected cart
            data!.location = resData.query.location;
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setLoading(false);
        }
    };

    const stowButtonHandler = async () => {
        if (loading || manualLoading) return;
        try {
            setManualLoading(true);
            if (!firstProduct) return alert("First item is undefined");
            if (firstProduct.product.sku === undefined) return alert("Sku is null");

            if (count < 1) return alert(`Please select an amount of products to move to cart`);

            //show modal where user types in name of location they want to stow product(s)
            let locationName = (
                await showLocationInputModal({
                    modalTitle: "Enter a Location Name",
                    locations: (
                        await runProductQuery({ variables: { productId: firstProduct.product.id } })
                    ).data.product.locationProducts.map((x) => {
                        return {
                            locationName: x.location.name,
                            stockOnHand: x.stockOnHand,
                        };
                    }),
                    sku: firstProduct.product.sku,
                })
            )?.trim();
            if (!locationName) return;

            let selectedLocation: { id: string; name: string } | undefined = undefined;
            const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: true } });
            const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length > 0) {
                if (barcodeLocations.length > 1) {
                    if (
                        !(await showConfirmModal(
                            `There are ${barcodeLocations.length} locations matching ${locationName}. Please click 'Continue' to load all of the locations data (The more matching locations, the longer the query will take).`,
                            "Multiple Locations Found!",
                            { confirm: "Continue", cancel: "Cancel" }
                        ))
                    )
                        return;

                    let locations: ILocationQ[] = [];
                    for (let bl of barcodeLocations) {
                        if (mounted.current) {
                            const location = (await runLocationQuery({ variables: { locationId: bl.id } })).data.location;
                            locations.push(location);
                        }
                    }

                    if (locations.length < 1) {
                        await showConfirmModal("No locations found. Please try again!", "No Locations Found!", undefined, true);
                        return;
                    }

                    const value = await showSelectModal(
                        "Select Location",
                        locations
                            .filter((x) => x.name && x.warehouse?.name)
                            .sort((a, b) => (b.name ?? "").localeCompare(a.name ?? ""))
                            .map((x) => ({ description: `${x.name ?? ""} (${x.warehouse?.name ?? ""})`, id: x.id } as ISelect))
                    );

                    if (!value) return;

                    selectedLocation = { id: value.id, name: value.description };
                } else selectedLocation = { id: barcodeLocations[0].id, name: locationName };
            } else {
                return playNegativeTone();
            }

            if (!selectedLocation) return playNegativeTone();

            const entry: IMoveProductToLocationProps = {
                fromLocationId: params.locationId,
                productId: firstProduct.product.id,
                quantity: count,
                toLocationId: selectedLocation.id,
                fromLocationSelectionType: urlSearchParams.fromLocationSelectionType,
                productSelectionType: productSelectionType,
                toLocationSelectionType: SelectionType.Searched,
                sku: firstProduct.product.sku,
            };

            await moveProductsToLocation([entry], setManualLoading, selectedLocation.name);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const moveAllProductsToLocation = async () => {
        if (loading || manualLoading || !data) return;
        try {
            setManualLoading(true);

            let locationName = await showLocationInputModal({
                modalTitle: "Enter Destination Bin Name",
            });
            if (!locationName) return;

            let selectedLocation: { id: string; name: string } | undefined = undefined;
            const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: true } });
            const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length > 0) {
                if (barcodeLocations.length > 1) {
                    if (
                        !(await showConfirmModal(
                            `There are ${barcodeLocations.length} locations matching ${locationName}. Please click 'Continue' to load all of the locations data (The more matching locations, the longer the query will take).`,
                            "Multiple Locations Found!",
                            { confirm: "Continue", cancel: "Cancel" }
                        ))
                    )
                        return;

                    let locations: ILocationQ[] = [];
                    for (let bl of barcodeLocations) {
                        if (mounted.current) {
                            const location = (await runLocationQuery({ variables: { locationId: bl.id } }))?.data?.location;
                            if (location) locations.push(location);
                        }
                    }

                    if (locations.length < 1) {
                        await showConfirmModal("No locations found. Please try again!", "No Locations Found!", undefined, true);
                        return;
                    }

                    const value = await showSelectModal(
                        "Select Location",
                        locations
                            .filter((x) => x.name && x.warehouse?.name)
                            .sort((a, b) => (b.name ?? "").localeCompare(a.name ?? ""))
                            .map((x) => ({ description: `${x.name ?? ""} (${x.warehouse?.name ?? ""})`, id: x.id } as ISelect))
                    );

                    if (!value) return;

                    selectedLocation = { id: value.id, name: value.description };
                } else selectedLocation = { id: barcodeLocations[0].id, name: locationName };
            } else {
                return playNegativeTone();
            }

            if (!selectedLocation) return playNegativeTone();

            let entries: IMoveProductToLocationProps[] = data.location.products.map((product) => {
                return {
                    fromLocationId: params.locationId,
                    productId: product.product.id,
                    quantity: product.stockOnHand,
                    toLocationId: selectedLocation!.id,
                    fromLocationSelectionType: urlSearchParams.fromLocationSelectionType,
                    productSelectionType: SelectionType.None, //special case where all products in the location are being moved at once
                    toLocationSelectionType: SelectionType.Searched,
                    sku: product.product.sku,
                } as IMoveProductToLocationProps;
            });

            await moveProductsToLocation(entries, setManualLoading, selectedLocation.name);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const openAddOrPrintBarcodeModal = (productId: string | undefined | null) => {
        if (!productId) return;
        setShowAddOrPrintBarcodeModal(true);
    };

    const runLostSku = async () => {
        if (manualLoading || loading) return;
        try {
            setManualLoading(true);
            if (count > 0) return alert("Please put any products in your hand back into the bin first.");
            if (!firstProduct) return alert("Could not determine first product in bin.");
            if (firstProduct.stockOnHand < 1) return alert(`No items in current bin for SKU: ${firstProduct?.product?.sku ?? "N/A"}`);
            if (!params.locationId) return alert("Location id is null.");
            if (!data?.location?.name) return alert("No location name found.");
            const response = await showLostSkuModal<IQueryResponse>(
                {
                    locationId: params.locationId,
                    locationName: data.location.name,
                    locationSelectionType: urlSearchParams.fromLocationSelectionType,
                    productId: firstProduct.product.id,
                    productSelectionType: productSelectionType,
                    productSku: firstProduct.product.sku,
                    quantityInBin: firstProduct.stockOnHand,
                },
                `location(id: ${params.locationId}) {
                    ${locationAndPickZoneQueryProperties}
                }`
            );
            if (!response) return;
            data.location = response.location;
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const addProduct = async () => {
        try {
            if (count !== 0) {
                await showConfirmModal(
                    "Please put any products in your hand back into the bin first.",
                    "Products In Hand",
                    undefined,
                    true
                );
                return;
            }
            const addProductInputValue = addProductInputRef?.current?.value?.trim();
            if (manualLoading || !addProductInputValue || !data) return;
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: addProductInputValue, userEntered: true } });
            const barcodes = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Product);
            if (barcodes.length < 1) {
                await showConfirmModal(`Found 0 products matching value '${addProductInputValue}'.`, "No Products Found", undefined, true);
                return;
            }
            let productId = barcodes[0].id;
            if (barcodes.length > 1) {
                const selected = await showMultiMatchSelectorModal(
                    "Select Correct Product SKU",
                    barcodes.map((x) => x.id),
                    MultiMatchDataType.Product
                );
                if (!selected) {
                    playNegativeTone();
                    return;
                }
                productId = selected.id;
            }

            //make sure the added product isn't already in the current location
            const existingProduct = (data?.location?.products ?? []).find((pbl) => pbl.product.id === productId);
            if (existingProduct) {
                await showConfirmModal(
                    `This location already contains product '${existingProduct.product.sku}'! Do you maybe have a transfer list filter applied?`,
                    "Product Already Exists",
                    { confirm: "Continue" },
                    true
                );
                return;
            }

            const stockTakeRes = await runStockTakeMutation({
                variables: {
                    locationId: params.locationId,
                    productId: productId,
                    quantity: 1,
                    locationSelectionType: SelectionType.None,
                    productSelectionType: SelectionType.None,
                },
            });

            data.location = stockTakeRes.data.query.location;

            setCount(0);
            playPositiveTone();
            setSortKey(productId);
            setProductSelectionType(SelectionType.None);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setShowAddProductModal(false);
            setManualLoading(false);
        }
    };

    const getProductLocations = async () => {
        if (!firstProduct || loading || manualLoading || isStockTakeLoading) return;
        try {
            setManualLoading(true);
            const result =
                (await runProductQuery({ variables: { productId: firstProduct.product.id } })).data?.product?.locationProducts ?? [];
            setLocationModalState({ data: result, showLocationModal: true });
        } catch (error: any) {
            await showConfirmModal(
                (error as GraphQLError)?.message ?? (error as Error)?.message ?? "Unknown error",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
        }
    };

    return (
        <>
            {loading || manualLoading || isStockTakeLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={data?.location.name ?? ""}
                IncludeHomeButton
                IncludeBackButton
                BackButtonText="< Scan Bin"
                CustomBackButtonFunction={() => {
                    history.push(
                        `/mobile/stocktransfer/scanpickbin?${forgeURLSearchParams({ ...urlSearchParams })}`,
                        history.location.state
                    );
                }}
            />

            <SecondaryHeader Title="Scan SKU" NextButtonHandler={nextButtonHandler} />

            <MobileTable isActiveTable>
                <thead>
                    <tr>
                        <td>Bin</td>
                        <td>Hand</td>
                        <td>Bin</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ color: row1Bin1Color }}>{!firstProduct ? "-" : firstProduct.stockOnHand - count}</td>
                        <td style={{ color: row1HandColor }}>{!firstProduct ? "-" : count}</td>
                        <td>{"-"}</td>
                    </tr>
                    <tr>
                        <td>{data?.location.name ?? ""}</td>
                        <td style={{ color: row2HandColor }}>
                            {!firstProduct ? "-" : firstProduct.product.sku ? firstProduct.product.sku : "N/A"}
                        </td>
                        <td style={{ paddingRight: 10 }}>
                            <button
                                style={{
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    fontSize: 16,
                                    margin: 0,
                                    padding: "0 10px 0 0",
                                    maxHeight: 40,
                                    textAlign: "right",
                                    border: "none",
                                }}
                                disabled={!firstProduct}
                                onClick={async () => await getProductLocations()}
                            >
                                Bins
                            </button>
                        </td>
                    </tr>
                </tbody>
            </MobileTable>

            {/* image set */}
            <div style={{ padding: "10px 10px 0 10px" }}>
                <ActiveProductImages sku={firstProduct?.product.sku} />
            </div>
            {/* category */}
            <div style={{ fontSize: 12, margin: "10px 10px 0 10px" }}>
                Description: <b>{!firstProduct ? "-" : firstProduct.product.description}</b>
            </div>

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton onClick={async () => await stowButtonHandler()}>
                            Stow ({count}) to Bin {locationName}
                        </MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled={!firstProduct} onClick={decrement}>
                            -
                        </MobileButton>
                        <MobileButton disabled={!firstProduct} onClick={increment}>
                            +
                        </MobileButton>
                        <MobileButton disabled={!firstProduct} onClick={() => setCount(maxItemCount)}>
                            Max
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton onClick={() => setShowAddProductModal(true)}>Add Product</MobileButton>
                    <MobileButton onClick={async () => await runLostSku()}>Update Qty</MobileButton>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton
                        disabled={!!urlSearchParams.matchingProductIds?.length}
                        onClick={async () => await moveAllProductsToLocation()}
                    >
                        <span /*style={{ color: "#CC0A3B" }}*/>{`Move all Products to ${locationName}`}</span>
                    </MobileButton>
                    <MobileButton
                        disabled={!firstProduct?.product?.id}
                        onClick={() => openAddOrPrintBarcodeModal(firstProduct?.product?.id)}
                    >
                        Add or Print Barcode
                    </MobileButton>
                </MobileButtonRow>
            </MobileButtonContainer>

            {/* Other items in the bin */}
            {products.length < 2 ? (
                <></>
            ) : (
                <MobileTable>
                    <tbody>
                        {products.map((product, i) => {
                            if (i < 1) return <Fragment key={product.product.id}></Fragment>;
                            return (
                                <tr key={product.product.id} onClick={() => tableRowOnClickHandler(product.product.id)}>
                                    <td>{data?.location.name ?? ""}</td>
                                    <td>{product.product.sku}</td>
                                    <td>{product.stockOnHand}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            )}

            <Modal show={showAddOrPrintBarcodeModal} fullscreen>
                <PrimaryHeader
                    Title={firstProduct?.product?.sku ?? "-"}
                    IncludeHomeButton
                    IncludeBackButton
                    BackButtonText="< Back"
                    CustomBackButtonFunction={() => setShowAddOrPrintBarcodeModal(false)}
                />

                <SecondaryHeader Title="Add Or Print Barcode" NextButtonHandler={nextButtonHandler} />

                <AddOrPrintBarcode productId={firstProduct?.product?.id ?? "0"} />
            </Modal>

            <Modal show={showAddProductModal} onHide={() => setShowAddProductModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        autoFocus
                        style={{ width: "100%", height: 38, borderRadius: 4 }}
                        ref={addProductInputRef}
                        placeholder="Enter product SKU or barcode"
                    />
                </Modal.Body>
                <Modal.Footer style={{ width: "100%" }}>
                    <MobileButtonRow>
                        <MobileButton onClick={async () => await addProduct()}>Add Product</MobileButton>
                        <MobileButton onClick={() => setShowAddProductModal(false)}>Close</MobileButton>
                    </MobileButtonRow>
                </Modal.Footer>
            </Modal>

            <Modal
                show={locationModalState.showLocationModal}
                onHide={() => setLocationModalState({ ...locationModalState, showLocationModal: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{firstProduct?.product?.sku ?? "N/A"} Locations</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ border: "collapse", width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Bin</th>
                                <th style={{ textAlign: "right" }}>Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {locationModalState.data.map((x) => {
                                return (
                                    <tr>
                                        <td style={{ padding: "10px 0 10px 0", textAlign: "left" }}>{x.location.name}</td>
                                        <td style={{ padding: "10px 0 10px 0", textAlign: "right" }}>{x.stockOnHand}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ width: "100%" }}>
                    <MobileButtonRow>
                        <MobileButton onClick={() => setLocationModalState({ ...locationModalState, showLocationModal: false })}>
                            Close
                        </MobileButton>
                    </MobileButtonRow>
                </Modal.Footer>
            </Modal>

            {createElement(locationInputModal)}

            {createElement(selectModal)}

            {createElement(confirmModal)}

            {createElement(multiMatchSelectorModal)}

            {lostSkuModal}
        </>
    );
};

export default PickBin;
