import { ForwardedRef, forwardRef } from "react";
import styles from "./MobileToggle.module.scss";

interface IProps {
    defaultChecked?: boolean;
    id: string;
    name: string;
    onClick?: () => void;
}

const MobileToggle = forwardRef<HTMLInputElement, IProps>((props: IProps, ref?: ForwardedRef<HTMLInputElement>) => {
    return (
        <div className={styles.container}>
            <input
                ref={ref}
                type="checkbox"
                onClick={props.onClick}
                defaultChecked={props.defaultChecked}
                name={props.name}
                id={props.id}
            />
            <label htmlFor={props.id}>&nbsp;</label>
        </div>
    );
});

export default MobileToggle;
