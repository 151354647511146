import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import typedParse from "../../helpers/typedParse";
import forgeURLSearchParams from "../../helpers/forgeURLSearchParameters";
import AddProductToPurchaseInvoiceButton from "../../components/buttons/AddProductToPurchaseInvoiceButton";

const purchaseInvoiceQuery = `
query ($purchaseInvoiceId: ID!) {
  purchaseInvoice(id: $purchaseInvoiceId) {
    invoiceNumber
  }
}
`;

interface IPurchaseInvoiceQueryResult {
    purchaseInvoice: {
        invoiceNumber: string;
    };
}

interface IPurchaseInvoiceQueryVariables {
    purchaseInvoiceId: string;
}

interface IRouteParams {
    purchaseOrderId: string;
    purchaseInvoiceId: string;
    productId: string;
}

export interface IAddOrPrintBarcodeReturnsURLSearchParams {
    activeProductInvoiceLineItemId?: string | null;
    addToHand?: boolean | null;
}

const AddOrPrintBarcodeContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<IRouteParams>();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IAddOrPrintBarcodeReturnsURLSearchParams>(rawURLSearchParams.get("urlsp"));
    const { loading, data, refetch, error } = useQuery<IPurchaseInvoiceQueryResult, IPurchaseInvoiceQueryVariables>(purchaseInvoiceQuery, {
        fetchPolicy: "no-cache",
        variables: { purchaseInvoiceId: params.purchaseInvoiceId },
    });

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for product failed"}</ErrorDisplay>;

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/returns/selectreturnnumber`} />;

    if (!params.purchaseInvoiceId) return <Redirect to={`/mobile/returns/${params.purchaseOrderId}/selecttrackingnumber`} />;

    if (!params.productId) return <Redirect to={`/mobile/returns/${params.purchaseOrderId}/${params.purchaseInvoiceId}/scanbarcode`} />;

    const isNewItem = params.productId === "0";

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                Title={`Invoice ${data?.purchaseInvoice?.invoiceNumber ?? ""}`}
                IncludeBackButton
                BackButtonText="< Scan Barcode"
                CustomBackButtonPath={`/mobile/returns/${params.purchaseOrderId}/${
                    params.purchaseInvoiceId
                }/scanbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>({
                    activeProductInvoiceLineItemId: urlSearchParams.activeProductInvoiceLineItemId,
                    addToHand: urlSearchParams.addToHand,
                })}`}
                IncludeHomeButton
            />

            <SecondaryHeader Title="Scan Barcode" NextButtonHandler={() => void 0} />

            {isNewItem ? (
                <AddProductToPurchaseInvoiceButton
                    purchaseInvoiceId={params.purchaseInvoiceId}
                    purchaseInvoiceNumber={data?.purchaseInvoice?.invoiceNumber ?? ""}
                    addOrPrintBarcodeRedirectFunc={(productId: string) =>
                        history.replace(
                            `/mobile/returns/${params.purchaseOrderId}/${
                                params.purchaseInvoiceId
                            }/${productId}/addorprintbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>({
                                activeProductInvoiceLineItemId: urlSearchParams.activeProductInvoiceLineItemId,
                                addToHand: urlSearchParams.addToHand,
                            })}`
                        )
                    }
                />
            ) : (
                <AddOrPrintBarcode
                    productId={params.productId}
                    onBarcodeSaveAction={() =>
                        history.replace(
                            `/mobile/returns/${params.purchaseOrderId}/${
                                params.purchaseInvoiceId
                            }/scanbarcode?${forgeURLSearchParams<IAddOrPrintBarcodeReturnsURLSearchParams>({
                                activeProductInvoiceLineItemId: urlSearchParams.activeProductInvoiceLineItemId,
                                addToHand: urlSearchParams.addToHand,
                            })}`
                        )
                    }
                />
            )}
        </>
    );
};

export default AddOrPrintBarcodeContainer;
