import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { ISelect } from "./useSelectModal";

type TReturn = {
    inputValue: string;
    selectValue: string;
};

type ResolveType = (value: TReturn | undefined | PromiseLike<TReturn | undefined>) => void;

const useSpecialModal = () => {
    const resolveRef = useRef<ResolveType>();
    const inputRef = useRef<HTMLInputElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [inputElementDetails, setInputElementDetails] = useState<{
        elementTitle: string;
        defaultValue?: string;
    }>({ elementTitle: "" });
    const [selectElementDetails, setSelectElementDetails] = useState<{
        elementTitle: string;
        values: ISelect[];
        defaultValue?: string;
    }>({ elementTitle: "", values: [] });

    const showSpecialModal = useCallback(
        (
            modalTitle: string,
            inputElementDetails: {
                elementTitle: string;
                defaultValue?: string;
            },
            selectElementDetails: {
                elementTitle: string;
                values: ISelect[];
                defaultValue?: string;
            }
        ) => {
            if (modalTitle) setModalTitle(modalTitle);
            setInputElementDetails(inputElementDetails);
            setSelectElementDetails(selectElementDetails);
            return new Promise<TReturn | undefined>((resolve) => {
                resolveRef.current = resolve;
                setOpen(true);
            });
        },
        []
    );

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = inputElementDetails?.defaultValue ?? "";
        if (selectRef.current) selectRef.current.value = selectElementDetails?.defaultValue ?? "";
    }, [open, inputElementDetails?.defaultValue, selectElementDetails?.defaultValue]);

    useEffect(() => {
        if (inputRef?.current && open) inputRef.current.focus();
    }, [open]);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef.current && inputRef.current && selectRef.current) {
            if (!inputRef.current.value) return alert("Please enter an input value!");
            if (!selectRef.current.value) return alert("Please enter a select value!");
            const value = {
                inputValue: inputRef.current.value,
                selectValue: selectRef.current.value,
            };
            resolveRef.current(value);
            setOpen(false);
        }
    };

    const specialModal = (
        <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>{modalTitle}</b>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <div>
                    <div>{inputElementDetails.elementTitle}</div>
                    <input
                        ref={inputRef}
                        style={{
                            padding: 10,
                            alignSelf: "stretch",
                            height: 40,
                            borderRadius: 4,
                            border: "1px solid #798394",
                            marginRight: 10,
                            width: "100%",
                        }}
                    />
                </div>
                <div>
                    <div>{selectElementDetails.elementTitle}</div>
                    <select
                        ref={selectRef}
                        style={{
                            padding: 10,
                            alignSelf: "stretch",
                            height: 40,
                            borderRadius: 4,
                            border: "1px solid #798394",
                            marginRight: 10,
                            width: "100%",
                            backgroundColor: "white",
                        }}
                        defaultValue={selectElementDetails.defaultValue}
                    >
                        {selectElementDetails.values.map((v) => {
                            return (
                                <option key={v.id} value={v.id}>
                                    {v.description}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </Modal.Body>

            <Modal.Footer className="flex-nowrap">
                <button className="btn btn-primary" style={{ width: "50%" }} onClick={() => confirm()}>
                    <b>Confirm</b>
                </button>
                <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                    <b>Cancel</b>
                </button>
            </Modal.Footer>
        </Modal>
    );

    return { specialModal, showSpecialModal, open };
};

export default useSpecialModal;
