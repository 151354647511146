import * as React from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { VButton, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { useMutation, useQuery } from "@shane32/graphql";

//define query and mutation models
interface IQueryResult {
    labelProvider: ILabelProvider;
}

interface ILabelProvider {
    id: string;
    name: string;
    services: Array<ILabelProviderShippingService>;
}

interface ILabelProviderShippingService {
    id: string;
    shippingServiceId: string;
    recordAsShippingServiceId: string;
    packageType: string | null;
    labelProviderCarrierCode: string;
    labelProviderServiceCode: string;
    weightLimit: number | null;
}

interface IShippingService {
    id: string;
    name: string;
    shippingCarrierId: string;
    shippingCarrier: {
        name: string;
    };
    active: boolean;
}

interface IShippingQueryResult {
    shippingServices: {
        items: Array<IShippingService>;
    };
}

interface ILabelProviderShippingServiceModel {
    id: string;
    shippingServiceId: string;
    recordAsShippingServiceId: string;
    packageType: string | null;
    labelProviderCarrierCode: string;
    labelProviderServiceCode: string;
    weightLimit: string | null;
}

interface IEditResult {
    labelProvider: {
        edit: ILabelProvider;
    };
}

interface IEditVariables {
    original: ILabelProvider;
    modified: ILabelProvider;
}

//define modal state & default state
interface IModal {
    show: boolean;
    original?: ILabelProviderShippingService;
}

const hiddenModal: IModal = {
    show: false,
};

const EditLabelProviderMutation = `
mutation ($original: LabelProviderInput!, $modified: LabelProviderInput!) {
  labelProvider {
    edit(original: $original, modified: $modified) {
      id
      name
      services {
        id
        shippingServiceId
        recordAsShippingServiceId
        packageType
        labelProviderCarrierCode
        labelProviderServiceCode
        weightLimit
      }
    }
  }
}
`;

const LabelProviderQuery = `
query ($id: ID!) {
  labelProvider(id: $id) {
    id
    name
    services {
      id
      shippingServiceId
      recordAsShippingServiceId
      packageType
      labelProviderCarrierCode
      labelProviderServiceCode
      weightLimit
    }
  }
}
`;

const ShippingServiceQuery = `
{
  shippingServices {
    items {
      id
      name
      shippingCarrierId
      active
      shippingCarrier {
        name
      }
    }
  }
}
`;

const LabelProviderServiceSummary = () => {
    //=== set up state variables ===
    const [modal, setModal] = React.useState<IModal>(hiddenModal);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    //=== set up queries and mutations ===
    const { data, error, refetch } = useQuery<IQueryResult, { id: string }>(LabelProviderQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: shippingServiceData,
        error: shippingServiceError,
        refetch: shippingServiceRefetch,
    } = useQuery<IShippingQueryResult, {}>(ShippingServiceQuery, { fetchPolicy: "no-cache" });
    //edit mutation
    const [runEdit] = useMutation<IEditResult, IEditVariables>(EditLabelProviderMutation);

    //Shipping Service list
    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (shippingServiceError) return <ErrorDisplay onClick={shippingServiceRefetch}>{shippingServiceError.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data || !shippingServiceData) return <Loading />;
    if (!data.labelProvider) return <ErrorDisplay onClick={refetch}>Label Provider ID #{id}</ErrorDisplay>;

    const shippingServices = shippingServiceData.shippingServices.items
        .filter((x) => x.active === true)
        .sort((a, b) => {
            // Sort by shipping carrier name
            const carrierNameComparison = a.shippingCarrier.name.localeCompare(b.shippingCarrier.name);
            if (carrierNameComparison !== 0) return carrierNameComparison;

            // If the shipping carrier name is equal, sort by shipping service name
            const serviceNameComparison = a.name.localeCompare(b.name);
            if (serviceNameComparison !== 0) return serviceNameComparison;

            // If the shipping service name is also equal, sort by shipping service id
            return a.id.localeCompare(b.id);
        });

    // Create a map for easy access to shipping service details using shipping service ID
    const shippingServiceMap = new Map(shippingServiceData.shippingServices.items.map((service) => [service.id, service]));

    // Now sort the services
    data.labelProvider.services.sort((a, b) => {
        // Get the shippingServiceDetails for a and b
        const aDetails = shippingServiceMap.get(a.shippingServiceId);
        const bDetails = shippingServiceMap.get(b.shippingServiceId);

        // Compare shipping carrier name
        const carrierNameCompare = (aDetails?.shippingCarrier?.name ?? "").localeCompare(bDetails?.shippingCarrier?.name ?? "");
        if (carrierNameCompare !== 0) return carrierNameCompare;

        // Compare shipping service name
        const serviceNameCompare = (aDetails?.name ?? "").localeCompare(bDetails?.name ?? "");
        if (serviceNameCompare !== 0) return serviceNameCompare;

        // Compare weight
        const weightCompare = (a.weightLimit ?? 99999999) - (b.weightLimit ?? 99999999);
        if (weightCompare !== 0) return weightCompare;

        // Finally, compare by id as a fallback
        return a.id.localeCompare(b.id);
    });

    //=== set up local functions ===
    //run when the edit button is pressed
    const onShowEditModal = (value: ILabelProviderShippingService) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the add button is pressed
    const onShowAddModal = () => {
        setModal({ ...hiddenModal, show: true });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    //original model must contain all members, with strings for text boxes or selects, and boolean for check boxes
    const originalModel: ILabelProviderShippingServiceModel = modal.original
        ? {
              id: modal.original.id,
              shippingServiceId: modal.original.shippingServiceId,
              recordAsShippingServiceId: modal.original.recordAsShippingServiceId,
              packageType: modal.original.packageType || null,
              labelProviderCarrierCode: modal.original.labelProviderCarrierCode,
              labelProviderServiceCode: modal.original.labelProviderServiceCode,
              weightLimit: modal.original.weightLimit?.toString() || null,
          }
        : {
              id: "",
              shippingServiceId: "",
              recordAsShippingServiceId: "",
              packageType: "",
              labelProviderCarrierCode: "",
              labelProviderServiceCode: "",
              weightLimit: "",
          };

    //filters

    const runSave = (original: ILabelProviderShippingServiceModel | null, modified: ILabelProviderShippingServiceModel | null) => {
        //setSaving(true);
        runEdit({
            variables: {
                original: {
                    id: id,
                    name: data.labelProvider.name,
                    services: original
                        ? [
                              {
                                  id: original.id,
                                  shippingServiceId: original.shippingServiceId,
                                  recordAsShippingServiceId: original.recordAsShippingServiceId,
                                  packageType: original.packageType,
                                  labelProviderCarrierCode: original.labelProviderCarrierCode,
                                  labelProviderServiceCode: original.labelProviderServiceCode,
                                  weightLimit: original.weightLimit ? parseFloat(original.weightLimit) : null,
                              },
                          ]
                        : [],
                },
                modified: {
                    id: id,
                    name: data.labelProvider.name,
                    services: modified
                        ? [
                              {
                                  id: modified.id,
                                  shippingServiceId: modified.shippingServiceId,
                                  recordAsShippingServiceId: modified.recordAsShippingServiceId,
                                  packageType: modified.packageType,
                                  labelProviderCarrierCode: modified.labelProviderCarrierCode,
                                  labelProviderServiceCode: modified.labelProviderServiceCode,
                                  weightLimit: modified.weightLimit ? parseFloat(modified.weightLimit) : null,
                              },
                          ]
                        : [],
                },
            },
        }).then(
            (ret) => {
                setSaving(false);
                data.labelProvider = ret.data.labelProvider.edit;
                setModal(hiddenModal);
            },
            (err) => {
                setSaving(false);
                console.error("Error updating label provider shipping service", err);
                alert(err.message);
            }
        );
    };

    //run when the save button is pressed
    const onSaveChanges = (modified: ILabelProviderShippingServiceModel) => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runSave(
            modal.original
                ? {
                      id: modal.original.id,
                      shippingServiceId: modal.original.shippingServiceId,
                      recordAsShippingServiceId: modal.original.recordAsShippingServiceId,
                      packageType: modal.original.packageType,
                      labelProviderCarrierCode: modal.original.labelProviderCarrierCode,
                      labelProviderServiceCode: modal.original.labelProviderServiceCode,
                      weightLimit: modal.original.weightLimit?.toString() || null,
                  }
                : null,
            {
                id: "0",
                shippingServiceId: modified.shippingServiceId,
                recordAsShippingServiceId: modified.recordAsShippingServiceId,
                packageType: modified.packageType,
                labelProviderCarrierCode: modified.labelProviderCarrierCode,
                labelProviderServiceCode: modified.labelProviderServiceCode,
                weightLimit: modified.weightLimit,
            }
        );
    };

    const onDelete = () => {
        //=== DELETE ===
        //ensure we are editing
        if (!modal.original) return;
        //verify the user wanted to delete this entry
        if (!window.confirm("Are you sure you want to delete this label provider service?")) return;
        //delete the shipping service
        runSave(
            //send original shipping service
            modal.original
                ? {
                      id: modal.original.id,
                      shippingServiceId: modal.original.shippingServiceId,
                      recordAsShippingServiceId: modal.original.recordAsShippingServiceId,
                      packageType: modal.original.packageType,
                      labelProviderCarrierCode: modal.original.labelProviderCarrierCode,
                      labelProviderServiceCode: modal.original.labelProviderServiceCode,
                      weightLimit: modal.original.weightLimit?.toString() || null,
                  }
                : null,
            //and null to delete it
            null
        );
    };

    //=== set up any other variables needed for page render ===
    //render
    return (
        <>
            <p>
                <Button variant="white" onClick={onShowAddModal}>
                    Add new service
                </Button>
            </p>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white">Label Provider Service</Card.Header>
                <Card.Body>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Shipping Service</th>
                                <th>Record As Shipping Service</th>
                                <th>Package Type</th>
                                <th>Carrier Code</th>
                                <th>Service Code</th>
                                <th>Weight Limit</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.labelProvider.services.map((service) => {
                                const shippingService = shippingServiceData.shippingServices.items.find(
                                    (x) => x.id === service.shippingServiceId
                                );

                                const recordAsShippingService = shippingServiceData.shippingServices.items.find(
                                    (x) => x.id === service.recordAsShippingServiceId
                                );

                                if (shippingService && recordAsShippingService) {
                                    return (
                                        <tr key={service.id}>
                                            <td>{`${shippingService.shippingCarrier.name} - ${shippingService.name}`}</td>
                                            <td>{`${recordAsShippingService.shippingCarrier.name} - ${recordAsShippingService.name}`}</td>
                                            <td>{service.packageType}</td>
                                            <td>{service.labelProviderCarrierCode}</td>
                                            <td>{service.labelProviderServiceCode}</td>
                                            <td>{service.weightLimit}</td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    variant="white"
                                                    className="ms-4"
                                                    style={{ padding: "0.125rem 0.4rem", float: "right" }}
                                                    onClick={() => onShowEditModal(service)}
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={modal.show} onHide={onHideModal}>
                <VForm onSubmit={onSaveChanges} initialValue={originalModel} saving={saving} key={originalModel.id}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!modal.original ? "Add" : "Edit"} label provider service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Group className="mb-4" controlId="formBasicShippingServiceId">
                                    <VLabel title="Shipping Service" valueName="shippingServiceId" column sm={5} />
                                    <VSelect valueName="shippingServiceId" disabled={modal.original ? true : false} required>
                                        <option value=""></option>
                                        {shippingServices.map((shippingService) => (
                                            <option key={`carrier-${shippingService.id}`} value={shippingService.id}>
                                                {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicRecordAsShippingServiceId">
                                    <VLabel title="Record as Shipping Service" valueName="recordAsShippingServiceId" column sm={5} />
                                    <VSelect valueName="recordAsShippingServiceId" required>
                                        <option value=""></option>
                                        {shippingServices.map((shippingService) => (
                                            <option key={`carrier-${shippingService.id}`} value={shippingService.id}>
                                                {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                            </option>
                                        ))}
                                    </VSelect>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicPackageType">
                                    <VLabel valueName="packageType">Package Type</VLabel>
                                    <VControl type="text" valueName="packageType" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicCarrierCode">
                                    <VLabel valueName="labelProviderCarrierCode">Carrier Code</VLabel>
                                    <VControl type="text" valueName="labelProviderCarrierCode" required />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicServiceCode">
                                    <VLabel valueName="labelProviderServiceCode">Service Code</VLabel>
                                    <VControl type="text" valueName="labelProviderServiceCode" required />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicWeightLimit">
                                    <VLabel valueName="weightLimit">Weight Limit</VLabel>
                                    <VControl type="text" valueName="weightLimit" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default LabelProviderServiceSummary;
