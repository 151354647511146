import { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

type ResolveType = (value: boolean | PromiseLike<boolean>) => void;

const useConfirm = () => {
    const [open, setOpen] = useState<boolean>(false);
    const resolveRef = useRef<ResolveType>();
    const [modalTitleText, setModalTitleText] = useState<string>("Confirm");
    const [modalBodyText, setModalBodyText] = useState<string>("");
    const [modalButtonsText, setModalButtonsText] = useState<{ confirm: string; cancel?: string }>({ confirm: "Yes", cancel: "No" });
    const [isSingleButton, setIsSingleButton] = useState<boolean>();

    /**
     * Default values:
     * titleText = "Confirm"
     * buttonsText = { confirm: "Yes", cancel: "No" }
     * *****SINGLE BUTTON MODE: If this is set to "true", only the "confirm" button will be rendered
     */
    const showConfirmModal = useCallback(
        (bodyText: string, titleText?: string, buttonsText?: { confirm: string; cancel?: string }, singleButtonMode?: boolean) => {
            setModalTitleText(titleText ?? "Confirm");
            setModalBodyText(bodyText);
            setModalButtonsText({
                confirm: buttonsText?.confirm ?? "Yes",
                cancel: buttonsText?.cancel ?? "No",
            });
            setIsSingleButton(singleButtonMode ?? false);
            return new Promise<boolean>((resolve) => {
                resolveRef.current = resolve;
                setOpen(true);
            });
        },
        []
    );

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(false);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(true);
            setOpen(false);
        }
    };

    let modalButtons = (
        <>
            <button className="btn btn-primary" style={{ width: "50%" }} onClick={() => confirm()}>
                <b>{modalButtonsText.confirm}</b>
            </button>
            <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                <b>{modalButtonsText.cancel}</b>
            </button>
        </>
    );

    if (isSingleButton) {
        modalButtons = (
            <button className="btn btn-primary" style={{ width: "100%" }} onClick={() => confirm()}>
                <b>{modalButtonsText.confirm}</b>
            </button>
        );
    }

    function confirmModal() {
        return (
            <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{modalTitleText}</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column">
                    <div className="mb-3 d-flex flex-column">{modalBodyText}</div>
                </Modal.Body>

                <Modal.Footer className="flex-nowrap">{modalButtons}</Modal.Footer>
            </Modal>
        );
    }

    return { confirmModal, showConfirmModal, open };
};

export default useConfirm;
