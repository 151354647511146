import styles from "./MobileRadio.module.scss";

interface IProps {
    key: string;
    onClick: () => void;
    id: string;
    value: string;
    name: string;
    label: string;
    defaultChecked?: boolean;
}

const MobileRadio = (props: IProps) => {
    return (
        <label onClick={props.onClick} className={styles.mobileRadio} key={props.id}>
            <input
                defaultChecked={props.defaultChecked}
                onClick={props.onClick}
                type="radio"
                id={props.id}
                name={props.name}
                value={props.id}
            />{" "}
            <div>{props.label}</div>
        </label>
    );
};

export default MobileRadio;
