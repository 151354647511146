import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

interface IProps {
    url: string;
    name: string;
    children: JSX.Element;
}

//temp
const ZBreadcrumb = (props: IProps) => {
    return (
        <div className={styles.container}>
            <h2 className={styles.crumb}>
                <Link className={styles.link} to={props.url}>
                    {props.name}
                </Link>
            </h2>
            <h2 className={styles.crumb}>{`>`}</h2>
            {props.children}
        </div>
    );
};

export default ZBreadcrumb;
