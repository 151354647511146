import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import MobileRadio from "../components/radios/MobileRadio";
import MobileRadioContainer from "../components/radios/MobileRadioContainer";

export interface ISelect {
    id: string;
    description: string;
}

interface IState {
    selects: ISelect[];
    modalTitle: string;
    selectedRadioId: string | undefined;
}

type ResolveType = (value: ISelect | undefined | PromiseLike<ISelect | undefined>) => void;

const useSelectModal = () => {
    const resolveRef = useRef<ResolveType>();
    const formRef = useRef<HTMLFormElement>(null);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState<IState>({
        selectedRadioId: undefined,
        modalTitle: "",
        selects: [],
    });

    const showSelectModal = useCallback(
        (title: string, selectsToSelect: ISelect[], defaultSelectedId?: string) => {
            setState({ ...state, modalTitle: title, selects: selectsToSelect, selectedRadioId: defaultSelectedId });
            return new Promise<ISelect | undefined>((resolve) => {
                resolveRef.current = resolve;
                setOpen(true);
            });
        },
        [state]
    );

    useEffect(() => {
        if (formRef?.current && open) {
        }
    }, [open]);

    const cancel = () => {
        if (resolveRef && resolveRef.current) {
            resolveRef.current(undefined);
            setOpen(false);
        }
    };

    const confirm = () => {
        if (resolveRef && resolveRef.current && formRef && formRef.current) {
            const selectRadios = formRef.current.getElementsByTagName("input");
            const selectId = Array.from(selectRadios).find((x) => x.checked)?.value;
            if (!selectId) return alert("Please select a value!");
            const value = state.selects.find((x) => x.id === selectId);
            if (!value) return alert("No matching values found!");
            resolveRef.current(value);
            setOpen(false);
        }
    };

    function selectModal() {
        return (
            <Modal show={open} onHide={() => cancel()} style={{ zIndex: 1000001 }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{state.modalTitle}</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column">
                    <form ref={formRef}>
                        <MobileRadioContainer>
                            {state.selects.map((l) => {
                                return (
                                    <MobileRadio
                                        defaultChecked={l.id === state.selectedRadioId}
                                        id={l.id}
                                        key={l.id}
                                        label={l.description}
                                        name={"selectValue"}
                                        onClick={() => void 0}
                                        value={l.id}
                                    />
                                );
                            })}
                        </MobileRadioContainer>
                    </form>
                </Modal.Body>

                <Modal.Footer className="flex-nowrap">
                    <button className="btn btn-primary" style={{ width: "50%" }} onClick={() => confirm()}>
                        <b>Confirm</b>
                    </button>
                    <button className="btn btn-secondary" style={{ width: "50%" }} onClick={() => cancel()}>
                        <b>Cancel</b>
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    return { selectModal, showSelectModal, open };
};

export default useSelectModal;
