import { useEffect } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import PrimaryHeader from "../../../../components/PrimaryHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import {
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../../../graphs/queries/SearchBarcodesQuery";
import { createElement, useState, useRef } from "react";
import ModelType from "../../../../../../enums/ModelType";
import useConfirm from "../../../../hooks/useConfirm";
import useScanner from "../../../../hooks/useScanner";
import MobileTable from "../../../../components/tables/MobileTable";
import useInputModal from "../../../../hooks/useInputModal";
import PrinterType from "../../../../../../enums/PrinterType";
import StationSetType from "../../../../../../enums/StationSetTypes";
import useSelectModal, { ISelect } from "../../../../hooks/useSelectModal";
import MobileLoading from "../../../../components/MobileLoading";
import { useLocation } from "react-router-dom";

interface ILocation {
    id: string;
    name: string;
}

const locationQuery = `
query ($locationId: ID!) {
    location(id: $locationId) {
      id
      name
    }
  }
`;

interface ILocationQueryVariables {
    locationId: string;
}

interface ILocationQueryResult {
    location: ILocation;
}

const locationLabelMutation = `
mutation($ids:[ID!]!, $printerId:ID!, $labelWidth: Decimal!, $labelHeight: Decimal!) {
  api {
    print {
      locationLabels(locationIds:$ids, labelWidthInches: $labelWidth, labelHeightInches: $labelHeight) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

interface ILocationLabelMutationVariables {
    ids: string[];
    printerId: string;
    labelWidth: number;
    labelHeight: number;
}

const printersQuery = `
query {
  printers {
    items {
      id
      description
      type
      stationSet {
        name
        type
      }
      paperWidth
      paperHeight
      sortOrder
    }
  }
}
`;

interface IPrintersQueryVariables {}

interface IPrintersQueryResult {
    printers: {
        items: Array<{
            id: string;
            description: string;
            type: PrinterType;
            stationSet: {
                name: string;
                type: StationSetType;
            } | null;
            paperWidth: number | null;
            paperHeight: number | null;
            sortOrder: number;
        }>;
    };
}

const PrintLocationBarcode = () => {
    const location = useLocation();
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const [manualLoading, setManualLoading] = useState(false);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const { confirmModal, showConfirmModal } = useConfirm();
    const locationSearchInputRef = useRef<HTMLInputElement>(null);
    const mounted = useRef(true);
    const { inputModal, showInputModal, open: isInputModalOpen } = useInputModal();
    const [runLocationLabelMutation] = useMutation<{}, ILocationLabelMutationVariables>(locationLabelMutation);
    const { selectModal, showSelectModal, open: isSelectModalOpen } = useSelectModal();
    const { loading: printersLoading, data: printersData } = useQuery<IPrintersQueryResult, IPrintersQueryVariables>(printersQuery, {
        fetchPolicy: "no-cache",
    });

    const loading = [manualLoading, printersLoading].some((x) => x);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    const searchForLocations = async (searchValue: string, userEntered: boolean) => {
        if (loading) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({
                variables: { search: searchValue, userEntered: userEntered },
            });
            const barcodes = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            if (barcodes.length < 1) {
                await showConfirmModal(
                    `No locations found in Hive matching value '${searchValue}'`,
                    "No Locations Found",
                    { confirm: "Ok" },
                    true
                );
                setLocations([]);
                return;
            } else {
                //search for products based off of the productIds array
                var searchedLocations: ILocation[] = [];
                for (let locationId of barcodes.map((x) => x.id)) {
                    if (mounted.current) {
                        const res = (await runLocationQuery({ variables: { locationId: locationId } })).data.location;
                        searchedLocations.push(res);
                    }
                }
                setLocations(searchedLocations);
            }
        } catch (e: any) {
            await showConfirmModal(
                (e as GraphQLError)?.message ??
                    (e as Error)?.message ??
                    "An unknown error occurred while searching database for locations.",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
        }
    };

    const onScan = async (scannerValue: string) => {
        if (isInputModalOpen || isSelectModalOpen) return;
        await searchForLocations(scannerValue, false);
    };
    useScanner(onScan);

    const onClickSearchButtonHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const searchValue = locationSearchInputRef.current?.value?.trim();
        if (!searchValue) {
            await showConfirmModal("Please enter a value to search for", "No Search Value", { confirm: "Ok" }, true);
            return;
        }
        await searchForLocations(searchValue, true);
    };

    const onClickLocationHandler = async (location: ILocation) => {
        const quantityToPrint = (await showInputModal(`${location.name}`, "1", "How many labels would you like to print?"))?.trim();
        if (!quantityToPrint) return;
        if (isNaN(+quantityToPrint)) {
            await showConfirmModal("Please enter a valid number", "Invalid Number", { confirm: "Ok" }, true);
            return;
        }
        const quantity = +quantityToPrint;
        if (quantity < 1) {
            await showConfirmModal("Please enter a number greater than 0", "Invalid Number", { confirm: "Ok" }, true);
            return;
        }
        await printBarcode([location.id], quantity);
    };

    const printBarcode = async (locationIds: string[], quantity?: number) => {
        try {
            if (loading || !locationIds || locationIds.length === 0) return;
            setManualLoading(true);
            const printers = printersData?.printers?.items ?? [];
            const intakePrinters = printers.filter(
                (printer) => printer.type === PrinterType.Label && (printer.paperWidth === 2 || printer.paperWidth === 3)
            );
            if (intakePrinters.length < 1) {
                await showConfirmModal('No 2" or 3" label printers were found', "No Printers", { confirm: "Ok" }, true);
                return;
            }
            // sort by sort order, then by description
            intakePrinters.sort((a, b) => a.sortOrder - b.sortOrder || a.description.localeCompare(b.description));
            const printerId =
                intakePrinters.length === 1
                    ? intakePrinters[0].id
                    : intakePrinters.length < 1
                    ? null
                    : await (
                          await showSelectModal(
                              "Select Printer",
                              intakePrinters.map((printer) => ({ id: printer.id, description: printer.description } as ISelect))
                          )
                      )?.id;
            if (!printerId) return;
            const printer = intakePrinters.filter((p) => p.id === printerId)[0];
            if (!printer.paperHeight || !printer.paperWidth) return;
            for (let i = 0; i < (quantity ?? 1); i++) {
                await runLocationLabelMutation({
                    variables: { ids: locationIds, labelWidth: printer.paperWidth, labelHeight: printer.paperHeight, printerId: printerId },
                });
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    return (
        <>
            {loading && <MobileLoading fullscreen />}

            <PrimaryHeader Title="Print Location Barcode" IncludeBackButton IncludeHomeButton BackButtonText="< Back" />

            <SecondaryHeader NextButtonHandler={() => void 0} Title={locations.length > 0 ? "Select Location" : "Search for Location"} />

            <form
                onSubmit={async (e) => await onClickSearchButtonHandler(e)}
                style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}
            >
                <input
                    ref={locationSearchInputRef}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search for location..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    type="submit"
                >
                    <b>Search</b>
                </button>
            </form>

            <MobileTable>
                <thead>
                    <tr>
                        <td>
                            <b>Name</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {locations.map((x, a) => {
                        return (
                            <tr key={a} onClick={async () => await onClickLocationHandler(x)}>
                                <td>{x.name}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>

            {createElement(confirmModal)}

            {createElement(inputModal)}

            {createElement(selectModal)}
        </>
    );
};

export default PrintLocationBarcode;
