import { useHistory } from "react-router-dom";
import PrimaryHeader from "../../components/PrimaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import { IAdminRoute } from "./AdminController";

interface IProps {
    adminRoutes: IAdminRoute[];
}

const AdminHome = (props: IProps) => {
    const history = useHistory();

    return (
        <>
            <PrimaryHeader
                Title={`Admin`}
                IncludeBackButton
                IncludeHomeButton
                BackButtonText="< Roles"
                CustomBackButtonPath="/mobile/selectrole"
            />

            <MobileTable className="table">
                <thead>
                    <tr>
                        <td>Role</td>
                    </tr>
                </thead>
                <tbody>
                    {props.adminRoutes.map((route, i) => {
                        return (
                            <tr key={i} onClick={() => history.push(`/mobile/admin/${route.pathName}`)}>
                                <td>{route.displayName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>
        </>
    );
};

export default AdminHome;
