export const ReportsQuery = `
query($first: Int!, $after: String) {
  reports(first: $first, after: $after) {
    totalCount
    items {
      id
      name
      description
      metadata
    }
  }
}
`;

export const ReportQuery = `
query ($id: ID!) {
  report (id: $id) {
    id
    name
    description
    metadata
  }
}
`;

export const ReportExecuteMutation = `
mutation ($id: ID!, $params: Complex) {
  api {
    importExport {
      export {
        report (id: $id, parameters: $params) {
          data
        }
      }
    }
  }
}`;

export type IDataRow = Record<string, string | number | boolean | Date | null>;
export type IDataTable = IDataRow[];
export type IDataSet = Record<string, IDataTable>;

export interface IReportExecuteResult {
    api: {
        importExport: {
            export: {
                report: {
                    data: IDataSet;
                };
            };
        };
    };
}

export interface IReportQueryResult {
    report: IReport;
}

export interface IReportsQueryResult {
    reports: {
        totalCount: number;
        items: IReport[];
    };
}

export interface IReportParameter {
    name?: string;
    description?: string;
    type?:
        | "string" // any string; "" coerces to undefined unless required is set
        | "number" // ###.### format
        | "boolean" // checkbox
        | "date" // ISO date format (YYYY-MM-DD)
        | "datetimeoffset" // ISO date/time format (YYYY-MM-DDTHH:mm:ss.sssZ)
        | "dateoffset"; // date picker but submits ISO date/time format based on midnight local time
    pattern?: string;
    required?: boolean;
    defaultValue?: string | number | boolean;
}

export interface IReport {
    id: string;
    name: string;
    description: string | null;
    metadata?: {
        // arbitrary data format
        parameters?: IReportParameter[];
    };
}

export interface IReportsVariables {
    after: string | null;
    first: number | null;
}

export interface IReportQueryVariables {
    id: string;
}

export interface IReportExecuteVariables {
    id: string;
    params: any;
}
