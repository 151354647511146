import { Table, Row, Col, Card, Dropdown, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { Info } from "../../../components/misc/Info";
import { useMutation, useQuery } from "@shane32/graphql";
import FormatDate from "../../../helpers/FormatDate";
import StringHelper from "../../../helpers/StringHelper";
import ProductImage from "../../../components/images/ProductImage";
import ProductType from "../../../enums/ProductType";
import SaleStatus from "../../../enums/SaleStatus";
import SaleFulfillmentStatus from "../../../enums/SaleFulfillmentStatus";
import { useState } from "react";
import { GraphQLError } from "graphql";
import useSpecialModal from "../../mobile/hooks/useSpecialModal";
import ChildSaleType from "../../../enums/ChildSaleType";

//interface for salequery
interface ISaleQueryResult {
    sale: ISale;
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
}

//interface for sale
interface ISale {
    id: string;
    salesChannelId: string | null;
    status: SaleStatus;
    orderDate: string | null;
    shipBy: string | null;
    salesChannelReference: string | null;
    email: string | null;
    subtotal: number;
    paidTax: number;
    paidSubtotal: number;
    tax: number;
    customerReference: string | null;
    total: number;
    customerNotes: string | null;
    internalNotes: string | null;
    dropShip: boolean;
    childSaleType: ChildSaleType | null;
    shippingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        country: {
            isoCode: string;
        } | null;
    };
    billingAddress: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        phone: string | null;
        country: {
            isoCode: string;
        } | null;
    };
    salesChannel: {
        id: string;
        name: string;
    };
    shippingService: {
        name: string;
        id: string;
        shippingCarrier: {
            name: string;
        };
    } | null;
    lineItems: Array<{
        id: string;
        saleId: string;
        customerReference: string | null;
        quantityBackordered: number;
        quantityPicked: number;
        quantityShipped: number;
        quantityPreviouslyShipped: number;
        quantityShippedSeparately: number;
        quantity: number;
        unitPrice: number;
        total: number;
        tax: number;
        description: string;
        sku: string | null;
        partNumber: string | null;
        isAdditionalCharge: boolean;
        product: {
            id: string;
            type: ProductType;
        };
    }>;
    fulfillments: Array<{
        id: string;
        saleId: string;
        trackingNumber: string | null;
        hasInsurance: boolean;
        insuranceAmount: number | null;
        status: SaleFulfillmentStatus;
        packageNumber: string;
        labelAttachmentId: string | null;
        lineItems: Array<{
            saleLineItemId: string;
            packageQuantity: number;
        }>;
        weight: number | null;
        shippingServiceId: string | null;
        shipDate: string | null;
    }>;
    lineItemTotals: {
        quantity: number;
        quantityPicked: number;
        quantityShipped: number;
        quantityPreviouslyShipped: number;
        quantityBackordered: number;
        quantityShippedSeparately: number;
    };
}

interface IPrinterQueryResult {
    printers: {
        items: Array<IPrinter>;
    };
}

interface IPrinter {
    id: string;
    description: string;
    type: string;
    active: boolean;
}

interface IDownloadResult {
    api: {
        print: {
            sale: {
                download: string;
            };
        };
    };
}

interface IDownloadLabelResult {
    api: {
        print: {
            attachment: {
                download: string;
            };
        };
    };
}

interface IPrintResult {
    api: {
        print: {
            sale: {
                print: boolean;
            };
        };
    };
}

interface IPrintVariables {
    saleId: string;
    printerId: string;
}

interface IDownloadVariables {
    saleId: string;
}

interface IPrintLabelResult {
    api: {
        print: {
            attachment: {
                print: boolean;
            };
        };
    };
}

interface IPrintLabelVariables {
    attachmentId: string;
    printerId: string;
}

interface IDownloadLabelVariables {
    attachmentId: string;
}

interface IEditSaleMutationResult {
    sale: {
        edit: {
            id: string;
        };
    };
}

interface IEditSaleMutationVariables {
    original: ISaleInputModel;
    modified: ISaleInputModel;
}

interface ISaleInputModel {
    id: string;
    dropShip: boolean;
    status: SaleStatus;
    subtotal: number;
    tax: number;
    total: number;
    saleFulfillments: Array<ISaleFulfillmentInputModel> | null;
}

interface ISaleFulfillmentInputModel {
    id: string;
    status: SaleFulfillmentStatus;
    trackingNumber: string | null;
    packageNumber: string | null;
    hasInsurance: boolean;
    weight: number | null;
    shipDate: string | null;
    shippingServiceId: string | null;
}

const PrintMutation = `
mutation ($saleId: ID!, $printerId: ID!) {
  api {
    print {
      sale(saleId: $saleId) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

const PrintLabelMutation = `
mutation ($attachmentId: ID!, $printerId: ID!) {
    api {
      print {
        attachment(attachmentId: $attachmentId) {
          print(printerId: $printerId)
        }
      }
    }
  }
`;

const DownloadMutation = `
mutation ($saleId: ID!) {
  api {
    print {
      sale(saleId: $saleId) {
        download
      }
    }
  }
}
`;

const DownloadLabelMutation = `
mutation ($attachmentId: ID!) {
  api {
    print {
      attachment(attachmentId: $attachmentId) {
        download
      }
    }
  }
}
`;

const PrinterQuery = `
{
  printers {
    items {
      id
      description
      type
      active
    }
  }
}
`;

const saleInnerQuery = `
id
lineItemTotals {
  quantity
  quantityPicked
  quantityShipped
  quantityPreviouslyShipped
  quantityBackordered
  quantityShippedSeparately
}
salesChannelId
salesChannelReference
email
customerReference
shipBy
orderDate
status
subtotal
tax
paidSubtotal
paidTax
internalNotes
customerNotes
childSaleType
total
dropShip
shippingAddress {
  id
  firstName
  lastName
  company
  address1
  address2
  city
  state
  zip
  phone
  country {
    isoCode
  }
}
billingAddress {
  id
  firstName
  lastName
  company
  address1
  address2
  city
  state
  zip
  phone
  country {
    isoCode
  }
}
salesChannel {
  id
  name
}
shippingService {
  id
  name
  shippingCarrier {
    name
  }
}
lineItems {
  id
  saleId
  customerReference
  quantityPicked
  quantityPreviouslyShipped
  quantityShipped
  quantityBackordered
  quantityShippedSeparately
  quantity
  unitPrice
  tax
  total
  description
  sku
  partNumber
  isAdditionalCharge
  product {
    id
    type
  }
}
fulfillments {
  id
  saleId
  trackingNumber
  hasInsurance
  insuranceAmount
  status
  packageNumber
  labelAttachmentId
  lineItems {
    saleLineItemId
    packageQuantity
  }
  weight
  shippingServiceId
  shipDate
}`;

//Query for all fields from "sale"
const SaleQuery = `
query ($id: ID!) {
  sale(id: $id) {
    ${saleInnerQuery}
  }
  shippingServices {
    items {
        id
        name
        active
        shippingCarrierId
        shippingCarrier {
            name
        }
    }
}
}
`;

const EditSaleMutation = `
mutation($original: SaleInput!, $modified: SaleInput!) {
    sale{
        edit (original: $original, modified: $modified)  {
            ${saleInnerQuery}
        }
    }
}
`;

const SalesSummary = () => {
    const { id } = useParams<{ id: string }>();
    const { data, error, refetch } = useQuery<ISaleQueryResult, { id: string }>(SaleQuery, {
        variables: { id: id },
        fetchPolicy: "no-cache",
    });
    const {
        data: printerData,
        error: printerError,
        refetch: printerRefetch,
    } = useQuery<IPrinterQueryResult, {}>(PrinterQuery, { fetchPolicy: "no-cache" });
    const [runPrint] = useMutation<IPrintResult, IPrintVariables>(PrintMutation);
    const [runLabelPrint] = useMutation<IPrintLabelResult, IPrintLabelVariables>(PrintLabelMutation);
    const [runDownload] = useMutation<IDownloadResult, IDownloadVariables>(DownloadMutation);
    const [runLabelDownload] = useMutation<IDownloadLabelResult, IDownloadLabelVariables>(DownloadLabelMutation);
    const [runEditSaleMutation] = useMutation<IEditSaleMutationResult, IEditSaleMutationVariables>(EditSaleMutation);
    const [mutationLoading, setMutationLoading] = useState(false);
    const { specialModal, showSpecialModal } = useSpecialModal();

    //display message if failed to retrieve data
    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (printerError) return <ErrorDisplay onClick={printerRefetch}>{printerError.message}</ErrorDisplay>;
    //display loading if waiting for data to load
    if (!data || !printerData) return <Loading />;
    if (!data.sale) return <ErrorDisplay onClick={refetch}>Sale ID #{id}</ErrorDisplay>;

    //set data coming in to variables
    const salesChannel = data.sale.salesChannel;
    const shippingAddress = data.sale.shippingAddress;
    const billingAddress = data.sale.billingAddress;
    const fulfillments = data.sale.fulfillments;

    const totalPicked = data.sale.lineItemTotals.quantityPicked + data.sale.lineItemTotals.quantityShipped; // data.sale.lineItems.reduce((a, b) => a + b.quantityPicked, 0);
    const totalShipped = data.sale.lineItemTotals.quantityShipped; // data.sale.lineItems.reduce((a, b) => a + b.quantityShipped, 0);
    const lineItemTotal =
        data.sale.lineItemTotals.quantity -
        data.sale.lineItemTotals.quantityPreviouslyShipped -
        data.sale.lineItemTotals.quantityBackordered -
        data.sale.lineItemTotals.quantityShippedSeparately; // data.sale.lineItems.reduce((a, b) => a + b.quantity, 0);

    //set dropship to variable
    let dropShip = "";
    switch (data.sale.dropShip) {
        case true:
            dropShip = "YES";
            break;
        case false:
            dropShip = "NO";
            break;
    }

    //remove the comma if field is null or undefined

    //set variables for the summary card
    let subTotal = data.sale.subtotal;
    let tax = data.sale.tax;
    let total = data.sale.total;
    let paid = data.sale.paidSubtotal + data.sale.paidTax;
    let balanceDue = total - paid;

    //filter out IsAdditonalCharge by true;
    const additionalCharges = data.sale.lineItems.filter((x) => x.isAdditionalCharge) || "";
    const saleLineItems = data.sale.lineItems.filter((x) => !x.isAdditionalCharge) || "";

    const onPrint = (modified: IPrinter) => {
        runPrint({
            variables: {
                saleId: id,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print sales`, err);
                alert(err.message);
            }
        );
    };

    const onPrintLabel = (modified: IPrinter, attachmentId: string) => {
        runLabelPrint({
            variables: {
                attachmentId: attachmentId,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print attachment`, err);
                alert(err.message);
            }
        );
    };

    const onDownload = () => {
        runDownload({
            variables: {
                saleId: id,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.print.sale.download;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `SO-${id} Receipt`;
                fileLink.click(); // download to Downloads folder, AND
                window.open(fileURL); // open PDF in new tab
            },
            (err) => {
                console.error(`Error trying to print sale`, err);
                alert(err.message);
            }
        );
    };

    const onAddTrackingNumber = async (saleFulfillmentId: string) => {
        if (mutationLoading) return;
        try {
            setMutationLoading(true);
            //get sale
            const r = await showSpecialModal(
                "Add Tracking Number",
                { elementTitle: "Tracking Number" },
                {
                    elementTitle: "Shipping Service (override Sale)",
                    values: data.shippingServices.items
                        .filter((x) => x.active)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => ({ description: `${x.shippingCarrier.name} - ${x.name}`, id: x.id })),
                    defaultValue: data.sale.shippingService?.id ?? undefined,
                }
            );
            if (!r) return;
            if (!r.inputValue) {
                alert("Tracking number is required");
                return;
            }
            if (!r.selectValue) {
                alert("Shipping Service is required");
                return;
            }
            //create original input model
            const originalFulfillment = data.sale.fulfillments.find((x) => x.id === saleFulfillmentId);
            if (!originalFulfillment) {
                alert(`Cannot find saleFulfillment with id: ${saleFulfillmentId} from sale fulfillments list`);
                return;
            }

            if (originalFulfillment.trackingNumber) {
                alert("Tracking Number already exists, cannot add a new one");
                return;
            }

            if (
                originalFulfillment.status !== SaleFulfillmentStatus.InProgress &&
                originalFulfillment.status !== SaleFulfillmentStatus.Shipped
            ) {
                alert("Sale Fulfillment must be in progress or shipped to add a tracking number");
                return;
            }

            const originalFulfillmentModel: ISaleFulfillmentInputModel[] = [
                {
                    id: originalFulfillment.id,
                    status: originalFulfillment.status,
                    trackingNumber: originalFulfillment.trackingNumber,
                    hasInsurance: originalFulfillment.hasInsurance,
                    packageNumber: originalFulfillment.packageNumber,
                    weight: originalFulfillment.weight,
                    shipDate: originalFulfillment.shipDate,
                    shippingServiceId: originalFulfillment.shippingServiceId,
                },
            ];
            const original: ISaleInputModel = {
                id: data.sale.id,
                status: data.sale.status,
                dropShip: data.sale.dropShip,
                subtotal: data.sale.subtotal,
                tax: data.sale.tax,
                total: data.sale.total,
                saleFulfillments: originalFulfillmentModel,
            };
            let modifiedFulfillmentModel: ISaleFulfillmentInputModel[] = [
                {
                    id: originalFulfillment.id,
                    status: SaleFulfillmentStatus.Shipped,
                    trackingNumber: r.inputValue,
                    hasInsurance: originalFulfillment.hasInsurance,
                    packageNumber: originalFulfillment.packageNumber,
                    weight: originalFulfillment.weight,
                    shipDate: new Date().toISOString(),
                    shippingServiceId: r.selectValue,
                },
            ];
            //create modified input model
            const modified: ISaleInputModel = {
                id: data.sale.id,
                dropShip: data.sale.dropShip,
                status: data.sale.status,
                subtotal: data.sale.subtotal,
                tax: data.sale.tax,
                total: data.sale.total,
                saleFulfillments: modifiedFulfillmentModel,
            };

            //run mutation
            await runEditSaleMutation({ variables: { original, modified } });
            originalFulfillment.trackingNumber = r.inputValue;

            //leave here in case we want it
            // if (!data.mobile.getOpenSaleFulfillmentState.doItemsRemain) {
            //     //this is run if no more items remain to be packed for an order
            //     await runChangeSaleStatusMutation({
            //         variables: {
            //             newSaleStatus: SaleStatus.Shipped,
            //             oldSaleStatus: data.saleFulfillment.sale.status,
            //             saleId: data.saleFulfillment.sale.id,
            //         },
            //     });
            // }
        } catch (error: any) {
            const e = error as GraphQLError;
            alert(`SO-${data.sale.id}: ${e?.message ?? "Unknown Error"}`);
        } finally {
            setMutationLoading(false);
        }
    };

    const onDownloadLabel = (attachmentId: string) => {
        runLabelDownload({
            variables: {
                attachmentId: attachmentId,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.print.attachment.download;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `Label ${attachmentId}`;
                fileLink.click();
                window.open(fileURL);
            },
            (err) => {
                console.error(`Error trying to print label`, err);
                alert(err.message);
            }
        );
    };

    const printers = printerData.printers.items.filter((x) => x.active === true && (x.type === "GENERIC" || x.type === "COLOR"));
    const labelPrinters = printerData.printers.items.filter((x) => x.active === true && x.type === "LABEL");
    return (
        <>
            <Dropdown className="mb-3">
                <Dropdown.Toggle variant="primary">Print</Dropdown.Toggle>
                <Dropdown.Menu variant="secondary">
                    {printers.map((printer) => (
                        <Dropdown.Item onClick={() => onPrint(printer)} key={printer.id} value={printer.id}>
                            {printer.description}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onDownload}>Download</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Row>
                <Col lg={7} xxl={8}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">General</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Info label="Sales Channel">{salesChannel?.name}</Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Sales Channel Ref">
                                        {data.sale.salesChannelReference || ""}
                                        {data.sale.childSaleType === ChildSaleType.Reship ? (
                                            <span className="bi bi-bootstrap-reboot ms-2"></span>
                                        ) : data.sale.childSaleType === ChildSaleType.Incomplete ? (
                                            <span className="bi bi-truck ms-2"></span>
                                        ) : (
                                            ""
                                        )}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Info label="Order Date">
                                        {StringHelper.IsNullOrWhitespace(data.sale.orderDate)
                                            ? ""
                                            : FormatDate.ConvertDateTimeTo12HrLocalDateTime(data.sale.orderDate)}
                                    </Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Ship By">
                                        {StringHelper.IsNullOrWhitespace(data.sale.shipBy)
                                            ? ""
                                            : FormatDate.ConvertDateToLocalDateMMDDYYYY(data.sale.shipBy)}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Info label="Shipping Method">
                                        {data.sale.shippingService != null
                                            ? `${data.sale.shippingService.shippingCarrier.name} - ${data.sale.shippingService.name}`
                                            : ""}
                                    </Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Drop Ship">{dropShip}</Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Info label="Shipping Address">
                                        {shippingAddress?.firstName || shippingAddress?.lastName ? (
                                            <>
                                                {shippingAddress?.firstName || ""} {shippingAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.company ? (
                                            <>
                                                {shippingAddress?.company || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address1 ? (
                                            <>
                                                {shippingAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address2 ? (
                                            <>
                                                {shippingAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.city ? (
                                            <>
                                                {shippingAddress.city || ""}
                                                {shippingAddress?.state && shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.state ? (
                                            <>
                                                {shippingAddress?.state || ""}
                                                {shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.zip ? (
                                            <>
                                                {shippingAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.phone ? (
                                            <>
                                                {shippingAddress?.phone || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.country?.isoCode ? <>{shippingAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Billing Address">
                                        {billingAddress?.firstName || billingAddress?.lastName ? (
                                            <>
                                                {billingAddress?.firstName || ""} {billingAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.company ? (
                                            <>
                                                {billingAddress?.company || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.address1 ? (
                                            <>
                                                {billingAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.address2 ? (
                                            <>
                                                {billingAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.city ? (
                                            <>
                                                {billingAddress.city || ""}
                                                {billingAddress?.state && billingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.state ? (
                                            <>
                                                {billingAddress?.state || ""}
                                                {billingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.zip ? (
                                            <>
                                                {billingAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.phone ? (
                                            <>
                                                {billingAddress?.phone || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.country?.isoCode ? <>{billingAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Info label="Email">{data.sale.email || ""}</Info>
                                </Col>
                                <Col md={6}>
                                    <Info label="Customer Ref">{data.sale.customerReference || ""}</Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Customer Notes">{data.sale.customerNotes || ""}</Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Notes">{data.sale.internalNotes || ""}</Info>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={5} xxl={4}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">Summary</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>{subTotal ? `$${subTotal.toFixed(2)}` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td>{tax ? `$${tax.toFixed(2)}` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Total</b>
                                        </td>
                                        <td>{total ? `$${total.toFixed(2)}` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Paid</td>
                                        <td>{paid ? `$${paid.toFixed(2)}` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Balance Due</td>
                                        {/*//if exist then show as $ else if total is more then 0.01 then show $0.00 else empty*/}
                                        <td>{balanceDue ? `$${balanceDue.toFixed(2)}` : total > 0.01 ? "$0.00" : ""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white bg-gradient">Fulfillments</Card.Header>
                        <Card.Body>
                            <span className="me-4">
                                <b>
                                    {totalPicked} / {lineItemTotal}
                                </b>{" "}
                                Picked
                            </span>
                            <span>
                                <b>
                                    {totalShipped} / {lineItemTotal}
                                </b>{" "}
                                Shipped
                            </span>
                        </Card.Body>
                    </Card>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white bg-gradient">Packages</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Status</th>
                                        <th>Qty / Products</th>
                                        <th>Tracking Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fulfillments.map((fulfillment) => {
                                        return (
                                            <tr key={fulfillment.id}>
                                                <td>{fulfillment.packageNumber}</td>
                                                <td>{fulfillment.status}</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            {fulfillment.lineItems.map((saleLineItem) => {
                                                                return (
                                                                    <tr key={saleLineItem.saleLineItemId}>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            {saleLineItem.packageQuantity} -&nbsp;
                                                                        </td>
                                                                        <td>
                                                                            {saleLineItems
                                                                                .filter((x) => x.id === saleLineItem.saleLineItemId)
                                                                                .map((number) => number.partNumber)}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    {fulfillment.trackingNumber === null &&
                                                    (fulfillment.status === SaleFulfillmentStatus.InProgress ||
                                                        fulfillment.status === SaleFulfillmentStatus.Shipped) ? (
                                                        <Button onClick={async () => await onAddTrackingNumber(fulfillment.id)}>Add</Button>
                                                    ) : (
                                                        fulfillment.trackingNumber
                                                    )}
                                                </td>
                                                <td>
                                                    {fulfillment.labelAttachmentId !== null ? (
                                                        <Dropdown className="mb-3">
                                                            <Dropdown.Toggle variant="primary">Print</Dropdown.Toggle>
                                                            <Dropdown.Menu variant="secondary">
                                                                {labelPrinters.map((printer) => (
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            onPrintLabel(printer, fulfillment.labelAttachmentId!)
                                                                        }
                                                                        key={printer.id}
                                                                        value={printer.id}
                                                                    >
                                                                        {printer.description}
                                                                    </Dropdown.Item>
                                                                ))}
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item
                                                                    onClick={() => onDownloadLabel(fulfillment.labelAttachmentId!)}
                                                                >
                                                                    Download
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card className="border-primary">
                <Card.Header className="bg-primary text-white bg-gradient">Products & Services</Card.Header>
                <Card.Body>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Product</th>
                                <th>Customer Ref</th>
                                <th>
                                    Quantity
                                    <br />
                                    Ordered
                                </th>
                                <th>
                                    Quantity
                                    <br />
                                    Picked
                                </th>
                                <th>
                                    Quantity
                                    <br />
                                    Shipped
                                </th>
                                <th>
                                    Quantity
                                    <br />
                                    Backordered
                                </th>
                                <th>
                                    Quantity
                                    <br />
                                    Shipped
                                    <br />
                                    Separately
                                </th>
                                <th>Price</th>
                                <th>Tax</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {saleLineItems.map((saleLineItem) => {
                                return (
                                    <tr
                                        key={saleLineItem.id}
                                        style={saleLineItem.product.type === ProductType.Kit ? { border: "2px solid #ffe81a" } : {}}
                                    >
                                        <td style={{ width: 120, height: 100 }}>
                                            <ProductImage sku={saleLineItem.sku} size={100} />
                                        </td>
                                        <td>
                                            <Link to={`/products/${saleLineItem.product.id}`}>{saleLineItem.sku}</Link>
                                            <br />
                                            {saleLineItem.partNumber}
                                            <br />
                                            {saleLineItem.description}
                                        </td>
                                        <td>{saleLineItem.customerReference}</td>
                                        <td>{saleLineItem.quantity}</td>
                                        <td>{saleLineItem.quantityPicked}</td>
                                        <td>{saleLineItem.quantityShipped + saleLineItem.quantityPreviouslyShipped}</td>
                                        <td>{saleLineItem.quantityBackordered}</td>
                                        <td>{saleLineItem.quantityShippedSeparately}</td>
                                        <td>{saleLineItem.unitPrice ? `$${saleLineItem.unitPrice.toFixed(2)}` : ""}</td>
                                        <td>{saleLineItem.tax ? `$${saleLineItem.tax.toFixed(2)}` : ""}</td>
                                        <td>{saleLineItem.total ? `$${saleLineItem.total.toFixed(2)}` : ""}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={10}>
                                    <br />
                                    Additional Charges
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {additionalCharges.map((additionalCharge) => {
                                return (
                                    <tr key={additionalCharge.id}>
                                        <td></td>
                                        <td>{additionalCharge.description ? additionalCharge.description : "-"}</td>
                                        <td></td>
                                        <td>{additionalCharge.quantity}</td>
                                        <td>{additionalCharge.quantityPicked}</td>
                                        <td>{additionalCharge.quantityShipped}</td>
                                        <td>{additionalCharge.quantityBackordered}</td>
                                        <td>{additionalCharge.quantityShippedSeparately}</td>
                                        <td>{additionalCharge.unitPrice ? `$${additionalCharge.unitPrice.toFixed(2)}` : ""}</td>
                                        <td>{additionalCharge.tax ? `$${additionalCharge.tax.toFixed(2)}` : ""}</td>
                                        <td>{additionalCharge.total ? `$${additionalCharge.total.toFixed(2)}` : ""}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {specialModal}
        </>
    );
};

export default SalesSummary;
