import { useMutation, useQuery } from "@shane32/graphql";
import { Button, Card, Col, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ProductImage from "../../../components/images/ProductImage";
import Loading from "../../../components/loading/Loading";
import ErrorDisplay from "../../../components/misc/ErrorDisplay";
import { Info } from "../../../components/misc/Info";
import ProductType from "../../../enums/ProductType";
import ProductCondition from "../../../enums/ProductCondition";
import FormatDate from "../../../helpers/FormatDate";
import Rounding from "../../../helpers/Rounding";
import StringHelper from "../../../helpers/StringHelper";
import ReturnFault from "../../../enums/ReturnFault";
import ReturnStatus from "../../../enums/ReturnStatus";
import PackageType from "../../../enums/PackageType";
import PackingType from "../../../enums/PackingType";
import { useState } from "react";
import { VButton, VControl, VForm, VLabel, VSelect } from "@shane32/vform";
import ReturnTo from "../../../enums/ReturnTo";

const printerQuery = `
{
  printers {
    items {
      id
      description
      type
      active
    }
  }
}
`;

interface IPrinterQueryResult {
    printers: {
        items: Array<IPrinter>;
    };
}

interface IPrinter {
    id: string;
    description: string;
    type: string;
    active: boolean;
}

const baseReturnQuery = `
    id
    returnReason {
    id
    description
    }
    atFault
    status
    internalNotes
    createdAt
    refundedAt
    attemptedMachineMake
    attemptedMachineModel
    packageType
    packingType
    conditionDetails
    totalRefund
    returnSalesChannelReference
    lineItems {
    id
    quantityReship
    quantityReturn
    returnTo
    product {
        id
        sku
        type
        partNumbers {
        partNumber
        }
        description
    }
    condition
    isAdditionalCharge
    refundUnitPrice
    refundTax
    refundTotal
    returnPurchaseOrderLineItem {
        id
        product {
        id
        sku
        description
        type
        }
    }
    }
    originalSale {
    id
    salesChannel {
        name
    }
    customerReference
    salesChannelReference
    subtotal
    tax
    orderDate
    total
    billingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        country {
        id
        isoCode
        }
    }
    shippingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        country {
        id
        isoCode
        }
    }
    }
    reshipSale {
    attachments {
        id
    }
    id
    lineItems {
        id
        product {
        id
        sku
        description
        type
        }
        quantityShipped
    }
    }
    returnPurchaseOrder {
    attachments {
        id
    }
    id
    billingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        country {
            id
            isoCode
        }
        }
        shippingAddress {
        id
        firstName
        lastName
        company
        address1
        address2
        city
        state
        zip
        phone
        country {
            id
            isoCode
        }
        }
    invoices {
        id
        invoiceNumber
        status
        lineItems {
        purchaseOrderLineItem {
            id
            product {
                type
                partNumbers {
                    partNumber
                    sortOrder
                }
            }
        }
        id
        actualCondition
        expectedQuantity
        actualQuantity
        }
        trackingNumbers {
        purchaseInvoiceId
        trackingNumber
        sortOrder
        shippingServiceId
        }
    }
    }`;

const returnQuery = `
query ($id: ID!) {
    return(id: $id) {
      ${baseReturnQuery}
    }
  }
`;

interface IReturnQueryVariables {
    id: string;
}

interface IReturnQueryResult {
    return: IReturn;
}

interface IReturn {
    id: string;
    status: ReturnStatus;
    returnReason: {
        id: string;
        description: string;
    };
    atFault: ReturnFault;
    attemptedMachineMake: string | null;
    attemptedMachineModel: string | null;
    conditionDetails: string | null;
    packageType: PackageType;
    packingType: PackingType;
    internalNotes: string | null;
    createdAt: string | null;
    refundedAt: string | null;
    totalRefund: number | null;
    returnSalesChannelReference: string | null;
    lineItems: Array<IReturnLineItem>;
    originalSale: {
        id: string;
        salesChannel: {
            name: string;
        } | null;
        customerReference: string | null;
        salesChannelReference: string | null;
        subtotal: number;
        tax: number;
        orderDate: string | null;
        total: number;
        billingAddress: IAddress | null;
        shippingAddress: IAddress | null;
    };
    reshipSale: {
        id: string;
        attachments: Array<IAttachment>;
        lineItems: Array<{
            id: string;
            product: {
                id: string;
                sku: string;
                description: string;
                type: ProductType;
            };
            quantityShipped: number;
        }>;
    } | null;
    returnPurchaseOrder: {
        id: string;
        attachments: Array<IAttachment>;
        billingAddress: IAddress | null;
        shippingAddress: IAddress | null;
        invoices: Array<{
            id: string;
            invoiceNumber: string;
            status: string;
            lineItems: Array<{
                purchaseOrderLineItem: {
                    id: string;
                    product: {
                        id: string;
                        type: ProductType;
                        partNumbers: [
                            {
                                partNumber: string;
                                sortOrder: number;
                            }
                        ];
                    } | null;
                };
                id: string;
                actualCondition: ProductCondition | null;
                expectedQuantity: number;
                actualQuantity: number;
            }>;
            trackingNumbers: Array<{
                purchaseInvoiceId: string;
                trackingNumber: string;
                sortOrder: number;
                shippingServiceId: string;
            }>;
        }>;
    } | null;
}

interface IAttachment {
    id: string;
}

interface IAddress {
    id: string;
    firstName: string | null;
    lastName: string | null;
    company: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    phone: string | null;
    country: {
        id: string;
        isoCode: string;
    } | null;
}

interface IReturnLineItem {
    id: string;
    quantityReship: number;
    quantityReturn: number;
    product: {
        id: string;
        sku: string;
        type: ProductType;
        partNumbers: Array<{
            partNumber: string;
        }>;
        description: string;
    };
    returnTo: ReturnTo;
    condition: ProductCondition;
    isAdditionalCharge: boolean;
    refundUnitPrice: number;
    returnPurchaseOrderLineItem: {
        id: string;
        product: {
            id: string;
            sku: string;
            description: string;
            type: ProductType;
        } | null;
    } | null;
}

interface IDownloadLabelResult {
    api: {
        print: {
            attachment: {
                download: string;
            };
        };
    };
}

interface IDownloadLabelVariables {
    attachmentId: string;
}

const downloadLabelMutation = `
mutation ($attachmentId: ID!) {
  api {
    print {
      attachment(attachmentId: $attachmentId) {
        download
      }
    }
  }
}
`;

interface IPrintReturnStatementVariables {
    returnId: string;
    isRefund: boolean;
    printerId: string;
}

interface IPrintReturnStatementResult {
    api: {
        print: {
            returnStatement: {
                print: boolean;
            };
        };
    };
}

interface IDownloadReturnStatementVariables {
    returnId: string;
    isRefund: boolean;
}

interface IDownloadReturnStatementResult {
    api: {
        print: {
            returnStatement: {
                download: string;
            };
        };
    };
}

const DownloadReturnStatementMutation = `
mutation ($returnId: ID!, $isRefund: Boolean!) {
    api {
      print {
        returnStatement(returnId: $returnId, isRefund: $isRefund) {
          download
        }
      }
    }
  }
`;

const PrintReturnStatementMutation = `
mutation ($returnId: ID!, $isRefund: Boolean!, $printerId: ID!) {
  api {
    print {
      returnStatement(returnId: $returnId, isRefund: $isRefund) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

interface IRouteParams {
    id: string;
}

interface IModal {
    show: boolean;
    original: IPurchaseInvoiceTrackingNumberModal;
}

const hiddenModal: IModal = {
    show: false,
    original: {
        purchaseInvoiceId: "",
        trackingNumber: "",
        sortOrder: "0",
        shippingServiceId: "",
    },
};

const EditTrackingNumberMutation = `
mutation($original: ReturnPurchaseInvoiceTrackingNumberInput!, $modified: ReturnPurchaseInvoiceTrackingNumberInput!) {
  return {
    editTrackingNumber(original: $original, modified: $modified) {
      ${baseReturnQuery}
    }
  }
}
`;

const ShippingServicesQuery = `
query {
    shippingServices {
        items {
            id
            name
            active
            shippingCarrierId
            shippingCarrier {
                name
            }
            isReturnService
        }
    }
}
`;

//Query for sales
interface IShippingServicesQueryResult {
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
            isReturnService: boolean;
        }>;
    };
}

interface IEditResult {
    return: {
        editTrackingNumber: IReturn;
    };
}

interface IEditVariables {
    original: IPurchaseInvoice;
    modified: IPurchaseInvoice;
}

interface IPurchaseInvoice {
    id: string;
    returnId: string;
    trackingNumbers: Array<IPurchaseInvoiceTrackingNumber> | null;
}

interface IPurchaseInvoiceTrackingNumberModal {
    purchaseInvoiceId: string;
    trackingNumber: string;
    sortOrder: string | null;
    shippingServiceId: string | null;
}

interface IPurchaseInvoiceTrackingNumber {
    trackingNumber: string;
    sortOrder: number;
    shippingServiceId: string | null;
}

const ReturnSummary = () => {
    const params = useParams<IRouteParams>();
    const {
        data: printerData,
        error: printerError,
        refetch: printerRefetch,
    } = useQuery<IPrinterQueryResult, {}>(printerQuery, { fetchPolicy: "no-cache" });
    const {
        data: shippingServicesData,
        error: shippingServicesError,
        refetch: shippingServicesRefetch,
    } = useQuery<IShippingServicesQueryResult, {}>(ShippingServicesQuery);
    const {
        data: returnData,
        error: returnError,
        refetch: returnRefetch,
    } = useQuery<IReturnQueryResult, IReturnQueryVariables>(returnQuery, {
        variables: { id: params.id },
        fetchPolicy: "no-cache",
    });
    //=== set up state variables ===
    const [modal, setModal] = useState<IModal>(hiddenModal);
    const [saving, setSaving] = useState(false);
    //edit mutation
    const [runEditTrackingNumber] = useMutation<IEditResult, IEditVariables>(EditTrackingNumberMutation);

    const [runLabelDownload] = useMutation<IDownloadLabelResult, IDownloadLabelVariables>(downloadLabelMutation);
    const [runPrintReturnStatement] = useMutation<IPrintReturnStatementResult, IPrintReturnStatementVariables>(
        PrintReturnStatementMutation
    );
    const [runDownloadReturnStatement] = useMutation<IDownloadReturnStatementResult, IDownloadReturnStatementVariables>(
        DownloadReturnStatementMutation
    );

    if (printerError) return <ErrorDisplay onClick={printerRefetch}>{printerError.message}</ErrorDisplay>;
    if (shippingServicesError) return <ErrorDisplay onClick={shippingServicesRefetch}>{shippingServicesError.message}</ErrorDisplay>;
    if (returnError) return <ErrorDisplay onClick={returnRefetch}>{returnError.message}</ErrorDisplay>;
    if (!returnData || !printerData || !shippingServicesData) return <Loading />;
    if (!returnData.return) return <ErrorDisplay onClick={returnRefetch}>Cannot find return id {params.id}</ErrorDisplay>;

    const additionalCharges = (returnData?.return.lineItems ?? []).filter((x) => x.isAdditionalCharge) || "";
    const billingAddress = returnData?.return.originalSale.billingAddress;
    const shippingAddress = returnData?.return.originalSale.shippingAddress;
    const lineItems = (returnData?.return.lineItems ?? []).filter((x) => !x.isAdditionalCharge);
    const printers = (printerData?.printers.items ?? []).filter((x) => x.active === true && (x.type === "GENERIC" || x.type === "COLOR"));

    const returnPurchaseOrderInvoices = returnData?.return.returnPurchaseOrder?.invoices ?? [];

    const onPrintReturnStatement = (modified: IPrinter) => {
        runPrintReturnStatement({
            variables: {
                returnId: params.id,
                isRefund: false,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print return statement`, err);
                alert(err.message);
            }
        );
    };

    const onDownloadReturnStatement = () => {
        runDownloadReturnStatement({
            variables: {
                returnId: params.id,
                isRefund: false,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.print.returnStatement.download;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                const customerName = `${returnData.return.originalSale.shippingAddress?.firstName ?? ""} ${
                    returnData.return.originalSale.shippingAddress?.lastName ?? ""
                }`;
                fileLink.download = `Return Statement RN-${params.id} for ${customerName}`;
                fileLink.click();
                window.open(fileURL);
            },
            (err) => {
                console.error(`Error trying to download return statement`, err);
                alert(err.message);
            }
        );
    };

    const onPrintRefundStatement = (modified: IPrinter) => {
        runPrintReturnStatement({
            variables: {
                returnId: params.id,
                isRefund: true,
                printerId: modified.id,
            },
        }).then(
            (ret) => {
                alert("Print Success!");
            },
            (err) => {
                console.error(`Error trying to print refund statement`, err);
                alert(err.message);
            }
        );
    };

    const onDownloadRefundStatement = () => {
        runDownloadReturnStatement({
            variables: {
                returnId: params.id,
                isRefund: true,
            },
        }).then(
            (ret) => {
                let returnedData = ret?.data?.api.print.returnStatement.download;
                if (returnedData) {
                    var byteCharacters = atob(returnedData);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/pdf;base64" });
                    var fileURL = URL.createObjectURL(file);
                    var fileLink = document.createElement(`a`);
                    fileLink.href = fileURL;
                    const customerName = `${returnData.return.originalSale.shippingAddress?.firstName ?? ""} ${
                        returnData.return.originalSale.shippingAddress?.lastName ?? ""
                    }`;
                    fileLink.download = `Refund Statement RN-${params.id} for ${customerName}`;
                    fileLink.click();
                    window.open(fileURL);
                }
            },
            (err) => {
                console.error(`Error trying to download refund statement`, err);
                alert(err.message);
            }
        );
    };

    const onClickShippingLabelDownload = (attachmentId: string) => {
        runLabelDownload({
            variables: {
                attachmentId: attachmentId,
            },
        }).then(
            (ret) => {
                let returnedData = ret.data.api.print.attachment.download;
                var byteCharacters = atob(returnedData);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `Label ${attachmentId}`;
                fileLink.click();
                window.open(fileURL);
            },
            (err) => {
                console.error(`Error trying to print label`, err);
                alert(err.message);
            }
        );
    };

    //run when the edit button is pressed
    const onShowEditModal = (value: IPurchaseInvoiceTrackingNumberModal) => {
        setModal({
            show: true,
            original: value,
        });
    };

    //run when the cancel button is pressed, or any other attempts to hide the modal
    const onHideModal = () => {
        if (!saving) setModal(hiddenModal);
    };

    const onSaveChanges = (modified: IPurchaseInvoiceTrackingNumberModal) => {
        //=== EDIT ===
        if (!modal.original) {
            return;
        }
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        runEditTrackingNumber({
            variables: {
                //pass in original data
                original: {
                    id: modal.original.purchaseInvoiceId,
                    returnId: params.id,
                    trackingNumbers: modal.original.trackingNumber
                        ? [
                              {
                                  shippingServiceId: modal.original.shippingServiceId,
                                  trackingNumber: modal.original.trackingNumber,
                                  sortOrder: parseFloat(modal.original.sortOrder ?? "0"),
                              },
                          ]
                        : [],
                },
                //pass in modified data
                modified: {
                    id: modal.original.purchaseInvoiceId,
                    returnId: params.id,
                    trackingNumbers: [
                        {
                            shippingServiceId: modified.shippingServiceId,
                            trackingNumber: modified.trackingNumber,
                            sortOrder: parseFloat(modal.original.sortOrder ? modal.original.sortOrder : "0"),
                        },
                    ],
                },
            },
        }).then(
            //success
            (ret) => {
                //enable form controls and hide the modal
                returnData.return = ret.data.return.editTrackingNumber;
                setSaving(false);
                setModal(hiddenModal);
            },
            //failure
            (err) => {
                //enable form controls
                setSaving(false);
                //log the error to the console including all details
                console.error("Error editing Purchase Invoice Tracking Number", err);
                //display the error message
                alert(err.message);
            }
        );
    };

    const onDelete = () => {
        //=== EDIT ===
        //disable form controls
        setSaving(true);
        //start edit operation via graphql mutation
        if (modal.original) {
            runEditTrackingNumber({
                variables: {
                    //pass in original data
                    original: {
                        id: modal.original.purchaseInvoiceId,
                        returnId: params.id,
                        trackingNumbers: [
                            {
                                shippingServiceId: modal.original.shippingServiceId,
                                trackingNumber: modal.original.trackingNumber,
                                sortOrder: parseFloat(modal.original.sortOrder ?? "0"),
                            },
                        ],
                    },
                    //pass in modified data
                    modified: {
                        id: modal.original.purchaseInvoiceId,
                        returnId: params.id,
                        trackingNumbers: [],
                    },
                },
            }).then(
                //success
                (ret) => {
                    //enable form controls and hide the modal
                    returnData.return = ret.data.return.editTrackingNumber;
                    setSaving(false);
                    setModal(hiddenModal);
                },
                //failure
                (err) => {
                    //enable form controls
                    setSaving(false);
                    //log the error to the console including all details
                    console.error("Error deleting purchase invoice tracking number", err);
                    //display the error message
                    alert(err.message);
                }
            );
        }
    };

    return (
        <>
            <div className="d-flex">
                <Dropdown className="mb-3 me-4">
                    <Dropdown.Toggle variant="primary">Print Return Shipping Label</Dropdown.Toggle>
                    <Dropdown.Menu variant="secondary">
                        {printers.map((printer) => (
                            <Dropdown.Item onClick={() => /*onIntakePrint(printer)*/ void 0} key={printer.id} value={printer.id}>
                                {printer.description}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        {returnData?.return.reshipSale?.attachments?.map((attachment, i) => {
                            return (
                                <Dropdown.Item key={attachment.id} onClick={() => onClickShippingLabelDownload(attachment.id)}>
                                    Download Reship Sale Label {i + 1}
                                </Dropdown.Item>
                            );
                        })}
                        <Dropdown.Divider />
                        {returnData?.return.returnPurchaseOrder?.attachments?.map((attachment, i) => {
                            return (
                                <Dropdown.Item key={attachment.id} onClick={() => onClickShippingLabelDownload(attachment.id)}>
                                    Download Return Purchase Order Label {i + 1}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mb-3 me-4">
                    <Dropdown.Toggle variant="primary">Print Return Statement</Dropdown.Toggle>
                    <Dropdown.Menu variant="secondary">
                        {printers.map((printer) => (
                            <Dropdown.Item onClick={() => onPrintReturnStatement(printer)} key={printer.id} value={printer.id}>
                                {printer.description}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onDownloadReturnStatement}>Download</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mb-3 me-4">
                    <Dropdown.Toggle variant="primary">Print Refund Statement</Dropdown.Toggle>
                    <Dropdown.Menu variant="secondary">
                        {printers.map((printer) => (
                            <Dropdown.Item onClick={() => onPrintRefundStatement(printer)} key={printer.id} value={printer.id}>
                                {printer.description}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onDownloadRefundStatement}>Download</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Row>
                <Col lg={7} xxl={8}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">General</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Info label="Sales Channel">
                                        {returnData?.return.originalSale.salesChannel?.name ?? "No associated sales channel name"}
                                    </Info>
                                </Col>
                                <Col>
                                    <Info label="Sales Channel Reference">
                                        {returnData?.return.originalSale.salesChannelReference || ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Return Reference">{returnData?.return.returnSalesChannelReference || ""}</Info>
                                </Col>
                                <Col>
                                    <Info label="Original Sales Order">
                                        {!returnData?.return.originalSale.id ? (
                                            <></>
                                        ) : (
                                            <Link to={`/sales/${returnData.return.originalSale.id}`}>
                                                SO-{returnData.return.originalSale.id}
                                            </Link>
                                        )}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Creation Date">
                                        {StringHelper.IsNullOrWhitespace(returnData?.return.createdAt)
                                            ? ""
                                            : FormatDate.ConvertDateTimeTo12HrLocalDateTime(returnData?.return.createdAt)}
                                    </Info>
                                </Col>
                                <Col>
                                    <Info label="Refunded Date">
                                        {StringHelper.IsNullOrWhitespace(returnData?.return.refundedAt)
                                            ? ""
                                            : FormatDate.ConvertDateTimeTo12HrLocalDateTime(returnData?.return.refundedAt)}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Return Reason">{returnData?.return.returnReason.description ?? ""}</Info>
                                </Col>
                                <Col>
                                    <Info label="At Fault">
                                        {returnData?.return.atFault ? StringHelper.SnakeCaseToTitleCase(returnData?.return.atFault) : ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Shipping Address">
                                        {shippingAddress?.firstName || shippingAddress?.lastName ? (
                                            <>
                                                {shippingAddress?.firstName || ""} {shippingAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.company ? (
                                            <>
                                                {shippingAddress?.company || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address1 ? (
                                            <>
                                                {shippingAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.address2 ? (
                                            <>
                                                {shippingAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.city ? (
                                            <>
                                                {shippingAddress.city || ""}
                                                {shippingAddress?.state && shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.state ? (
                                            <>
                                                {shippingAddress?.state || ""}
                                                {shippingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.zip ? (
                                            <>
                                                {shippingAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.phone ? (
                                            <>
                                                {shippingAddress?.phone || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {shippingAddress?.country?.isoCode ? <>{shippingAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                                <Col>
                                    <Info label="Billing Address">
                                        {billingAddress?.firstName || billingAddress?.lastName ? (
                                            <>
                                                {billingAddress?.firstName || ""} {billingAddress?.lastName || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.company ? (
                                            <>
                                                {billingAddress?.company || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.address1 ? (
                                            <>
                                                {billingAddress?.address1}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.address2 ? (
                                            <>
                                                {billingAddress?.address2}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.city ? (
                                            <>
                                                {billingAddress?.city || ""}
                                                {billingAddress?.state && billingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.state ? (
                                            <>
                                                {billingAddress?.state || ""}
                                                {billingAddress?.zip ? "" : <br />}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.zip ? (
                                            <>
                                                {billingAddress?.zip || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.phone ? (
                                            <>
                                                {billingAddress?.phone || ""}
                                                <br />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {billingAddress?.country?.isoCode ? <>{billingAddress?.country?.isoCode || ""}</> : ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Att. Machine Make">{returnData?.return?.attemptedMachineMake || ""}</Info>
                                </Col>
                                <Col>
                                    <Info label="Att. Machine Model">{returnData?.return?.attemptedMachineModel || ""}</Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Package Type">
                                        {returnData?.return?.packageType
                                            ? StringHelper.SnakeCaseToTitleCase(returnData?.return?.packageType)
                                            : ""}
                                    </Info>
                                </Col>
                                <Col>
                                    <Info label="Packing Type">
                                        {returnData?.return?.packingType
                                            ? StringHelper.SnakeCaseToTitleCase(returnData?.return?.packingType)
                                            : ""}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Defect Type">{returnData?.return?.conditionDetails || ""}</Info>
                                </Col>
                                <Col>
                                    <Info label="Purchase Order">
                                        {!returnData?.return?.returnPurchaseOrder?.id ? (
                                            <></>
                                        ) : (
                                            <Link to={`/purchaseOrders/${returnData.return.returnPurchaseOrder.id}`}>
                                                ZPO-{returnData.return.returnPurchaseOrder.id}
                                            </Link>
                                        )}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Reship Sales Order">
                                        {!returnData?.return.reshipSale?.id ? (
                                            <></>
                                        ) : (
                                            <Link to={`/sales/${returnData.return.reshipSale.id}`}>
                                                SO-{returnData.return.reshipSale.id}
                                            </Link>
                                        )}
                                    </Info>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Info label="Notes">{returnData?.return.internalNotes || ""}</Info>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={5} xxl={4}>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white">Summary</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>Not available</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td>Not available</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Total</b>
                                        </td>
                                        <td>${Rounding.EvenRound(returnData?.return.totalRefund ?? 0).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Paid</td>
                                        <td>${Rounding.EvenRound(0).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Balance Due</td>
                                        <td>${Rounding.EvenRound(0).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Amount Refunded</b>
                                        </td>
                                        <td>${Rounding.EvenRound(returnData?.return.totalRefund ?? 0).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white bg-gradient">Fulfillments</Card.Header>
                        <Card.Body style={{ display: "flex", gap: 5 }}>
                            <span>
                                <b>
                                    {(returnData?.return?.returnPurchaseOrder?.invoices ?? [])
                                        .flatMap((x) => x.lineItems)
                                        .filter((x) => x.purchaseOrderLineItem.product?.type === ProductType.Product)
                                        .reduce((acc, val) => acc + val.actualQuantity, 0)}
                                </b>
                            </span>
                            <span>
                                <b>/</b>
                            </span>
                            <span>
                                <b>
                                    {(returnData?.return?.returnPurchaseOrder?.invoices ?? [])
                                        .flatMap((x) => x.lineItems)
                                        .filter((x) => x.purchaseOrderLineItem.product?.type === ProductType.Product)
                                        .reduce((acc, val) => acc + val.expectedQuantity, 0)}
                                </b>
                            </span>
                            <span>Expected items received</span>
                        </Card.Body>
                    </Card>
                    <Card className="border-primary">
                        <Card.Header className="bg-primary text-white bg-gradient">Packages</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Status</th>
                                        <th>Qty / Products</th>
                                        <th>Tracking Number(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(returnData?.return?.returnPurchaseOrder?.invoices ?? []).map((invoice) => {
                                        return (
                                            <tr key={invoice.id}>
                                                <td>{invoice.invoiceNumber}</td>
                                                <td>{invoice.status}</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            {invoice.lineItems
                                                                .filter(
                                                                    (x) => x.purchaseOrderLineItem.product?.type === ProductType.Product
                                                                )
                                                                .map((invoiceLineItem) => {
                                                                    return (
                                                                        <tr key={invoiceLineItem.id}>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                {invoiceLineItem.expectedQuantity} -&nbsp;
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    invoiceLineItem.purchaseOrderLineItem.product?.partNumbers.sort(
                                                                                        (a, b) => a.sortOrder - b.sortOrder
                                                                                    )[0].partNumber
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    {invoice.trackingNumbers.length > 0 ? (
                                                        invoice.trackingNumbers
                                                            .sort((a, b) => a.trackingNumber.localeCompare(b.trackingNumber))
                                                            .map((x, index) => (
                                                                <div key={x.trackingNumber} className="d-flex align-items-center">
                                                                    {x.trackingNumber}
                                                                    <Button
                                                                        className="ms-2"
                                                                        onClick={() =>
                                                                            onShowEditModal({
                                                                                purchaseInvoiceId: invoice.id,
                                                                                shippingServiceId: x.shippingServiceId,
                                                                                sortOrder: x.sortOrder.toString(),
                                                                                trackingNumber: x.trackingNumber,
                                                                            })
                                                                        }
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </div>
                                                            ))
                                                    ) : (
                                                        <Button
                                                            onClick={() =>
                                                                onShowEditModal({
                                                                    purchaseInvoiceId: invoice.id,
                                                                    shippingServiceId: "",
                                                                    sortOrder: "",
                                                                    trackingNumber: "",
                                                                })
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Card className="border-primary">
                <Card.Header className="bg-primary text-white bg-gradient">Returned Products</Card.Header>
                <Card.Body>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Total</th>
                                <th>Return To</th>
                                <th>Expected Condition</th>
                                <th>Actual Condition</th>
                                <th>Expected Quantity</th>
                                <th>Actual Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((lineItem) => {
                                const purchaseInvoiceLineItemsForPurchaseOrderLineItem = returnPurchaseOrderInvoices
                                    .flatMap((x) => x.lineItems)
                                    .filter((x) => x.purchaseOrderLineItem.id === lineItem.returnPurchaseOrderLineItem?.id);
                                const receivedQuantity = purchaseInvoiceLineItemsForPurchaseOrderLineItem.reduce(
                                    (a, b) => a + b.actualQuantity,
                                    0
                                );
                                const actualCondition = purchaseInvoiceLineItemsForPurchaseOrderLineItem.find(
                                    (x) => x.purchaseOrderLineItem.id === lineItem.returnPurchaseOrderLineItem?.id
                                )?.actualCondition;
                                return (
                                    <tr key={lineItem.id}>
                                        <td style={{ width: 120, height: 100 }}>
                                            <ProductImage sku={lineItem.product.sku} size={100} />
                                        </td>
                                        <td>
                                            {lineItem.product.sku}
                                            <br />
                                            {lineItem.product.partNumbers.map((x) => x.partNumber).join(", ")}
                                            <br />
                                            {lineItem.product.description}
                                        </td>
                                        <td>{`$${Rounding.EvenRound(lineItem.refundUnitPrice).toFixed(2)}`}</td>
                                        <td>
                                            {Rounding.EvenRound(
                                                lineItem.refundUnitPrice *
                                                    (lineItem.quantityReship !== 0 ? lineItem.quantityReship : lineItem.quantityReturn)
                                            ).toFixed(2)}
                                        </td>
                                        <td>{StringHelper.SnakeCaseToTitleCase(lineItem.returnTo)}</td>
                                        <td>{StringHelper.SnakeCaseToTitleCase(lineItem.condition)}</td>
                                        <td>{actualCondition ? StringHelper.SnakeCaseToTitleCase(actualCondition) : null}</td>
                                        <td>{lineItem.quantityReturn}</td>
                                        <td>{receivedQuantity}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th style={{ width: 200 }}>Additional Charges</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {additionalCharges.map((additionalCharge) => (
                                <tr key={additionalCharge.id}>
                                    <td style={{ width: 120, height: 100 }}>
                                        <ProductImage sku={additionalCharge.product.sku} size={100} />
                                    </td>
                                    <td>
                                        <div>
                                            {additionalCharge.product.sku}
                                            <br />
                                            {additionalCharge.product.partNumbers.join(", ")}
                                            <br />
                                            {additionalCharge.product.description}
                                        </div>
                                    </td>
                                    <td>{`$${Rounding.EvenRound(additionalCharge.refundUnitPrice).toFixed(2)}`}</td>
                                    <td></td>
                                    <td>
                                        {Rounding.EvenRound(
                                            additionalCharge.refundUnitPrice *
                                                (additionalCharge.quantityReship !== 0
                                                    ? additionalCharge.quantityReship
                                                    : additionalCharge.quantityReturn)
                                        ).toFixed(2)}
                                    </td>
                                    <td>{additionalCharge.condition}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {lineItems.some((lineItem) => lineItem.quantityReship > 0) && (
                <Card className="border-primary">
                    <Card.Header className="bg-primary text-white bg-gradient">Reshipped Products</Card.Header>
                    <Card.Body>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lineItems
                                    .filter((x) => x.quantityReship > 0)
                                    .map((lineItem, i) => {
                                        return (
                                            <tr key={lineItem.id}>
                                                <td style={{ width: 120, height: 100 }}>
                                                    <ProductImage sku={lineItem.product.sku} size={100} />
                                                </td>
                                                <td>
                                                    <div>
                                                        {lineItem.product.sku}
                                                        <br />
                                                        {lineItem.product.partNumbers.map((x) => x.partNumber).join(", ")}
                                                        <br />
                                                        {lineItem.product.description}
                                                    </div>
                                                </td>
                                                <td>{lineItem.quantityReship}</td>
                                                <td>{`$${Rounding.EvenRound(lineItem.refundUnitPrice).toFixed(2)}`}</td>
                                                <td>
                                                    {Rounding.EvenRound(
                                                        lineItem.refundUnitPrice *
                                                            (lineItem.quantityReship !== 0
                                                                ? lineItem.quantityReship
                                                                : lineItem.quantityReturn)
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            )}
            <Modal show={modal.show} onHide={onHideModal}>
                {/* ensure that form encompasses both form elements and buttons */}
                <VForm onSubmit={onSaveChanges} initialValue={modal.original} saving={saving}>
                    <Modal.Header closeButton>
                        {/* set popup title appropriately */}
                        <Modal.Title>{modal.original.trackingNumber === "" ? "Add" : "Edit"} Tracking Number</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-4" controlId="formTrackingNumber">
                            <VLabel valueName="trackingNumber">Tracking Number</VLabel>
                            <VControl type="text" required valueName="trackingNumber" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="shippingServiceId">
                            <VLabel valueName="shippingServiceId">Shipping Service</VLabel>
                            <VSelect valueName="shippingServiceId" required>
                                {modal.original.trackingNumber === "" ? <option value=""></option> : null}
                                {shippingServicesData.shippingServices.items
                                    .filter((x) => x.active && x.isReturnService)
                                    .map((shippingService) => (
                                        <option key={`sales-${shippingService.id}`} value={shippingService.id}>
                                            {`${shippingService.shippingCarrier.name} - ${shippingService.name}`}
                                        </option>
                                    ))}
                            </VSelect>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* set 'disabled' while saving or when there are no changes */}
                        <VButton type="submit" variant="primary">
                            Save Changes
                        </VButton>
                        <VButton type="button" variant="white" onClick={onHideModal} className="me-auto">
                            Cancel
                        </VButton>
                        {
                            /* only show delete button when editing */
                            modal.original ? (
                                <VButton type="button" variant="danger" onClick={onDelete}>
                                    Delete
                                </VButton>
                            ) : null
                        }
                    </Modal.Footer>
                </VForm>
            </Modal>
        </>
    );
};

export default ReturnSummary;
